import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { currentDateInUTC, scheduledDateInUTC } from '../helpers/datetimeHandlers';
import { apiSelectedDeviceIdsHandler } from '../helpers/apiSelectedIdsHandler';

export const biosFwUpdateAPI = async (deviceId, devices, selectedDevicesIds, md5, fileName, filePath, executeType, datetime, commandText, t, successfulText) => {

    let formData = new FormData();
    deviceId ? formData.append('device_id', deviceId) : apiSelectedDeviceIdsHandler(formData, devices, selectedDevicesIds); //single device or multiple devices
    formData.append('execute_type', executeType);
    formData.append('execute_datetime', datetime === 'immediately' ? currentDateInUTC() : scheduledDateInUTC(datetime));
    formData.append('file_name', fileName);
    formData.append('file_path', filePath);
    formData.append('close_application_command', commandText);
    formData.append('md5', md5);

    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/command/bios-firmware-update-send`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
