import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const getDriveStorageFilesAPI = async (
    orderByColumn,
    orderByDesc,
    page,
    record,
    parent_id
) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");

    try {
        let response = await axios({
            url: `${url}/api/v2/drive/file/drive-storage-files`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id: companyId,
                order_by_column: orderByColumn,
                order_by_desc: orderByDesc,
                page: page,
                record: record,
                parent_id,
            },
        });

        return response.data;
    } catch (error) {
        errorHandling(error, true);
    }
};
