import React, { useEffect, useState } from "react";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import RestoreIcon from "@material-ui/icons/Restore";
import DetectBtn from "./DetectBtn";
import { utcFormatToLocalDateTimeWithSecond } from "../../helpers/datetimeHandlers";

const RefreshBtn = (props) => {
  const { t } = useTranslation("common");
  const {
    statusSet,
    getList,
    open,
    refresh,
    setRefresh,
    detectApi,
    deviceId,
    disableDetectBtn,
    setDisableDetectBtn,
    setClickAlready,
    clickAlready,
    icon,
  } = props;

  const [times, setTimes] = useState(0);

  const autoRefresh = async () => {
    setRefresh(true);
    await getList();
  };

  const detect = async () => {
    setDisableDetectBtn(true);
    let data = await detectApi([deviceId]);
    if (data) {
      setClickAlready(true);
      autoRefresh();
    }
  };

  const textColor = (status) => {
    if (status === "failed") {
      return "#f98746";
    } else if (status === "completed") {
      return "#04b7d2";
    } else {
      return "#9e9e9e";
    }
  };

  useEffect(() => {
    let id = setInterval(async () => {
      setTimes(times + 1);
      await getList();
    }, 5000);
    if (times === 10) {
      setTimes(0);
      setRefresh(false);
      clearInterval(id);
      return;
    }
    if (!refresh || !open) {
      setTimes(0);
      setRefresh(false);
      clearInterval(id);
      return;
    }
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, refresh, open]);

  const stopRefresh = () => {
    setRefresh(false);
  };

  return (
    <>
      <div className="detect-left">
        {detectApi && (
          <DetectBtn
            onClick={() => detect()}
            disableDetectBtn={disableDetectBtn}
            btnText={t("refresh.dialogDetectText")}
            icon={icon}
          />
        )}
        {!detectApi ? (
          <div className="ml-1 mr-1">{t("refresh.commandStatus")}</div>
        ) : (
          <div className="mr-1"> {t("refresh.detectionStatus")}</div>
        )}
        {clickAlready ||
        (!clickAlready &&
          statusSet.status !== "failed" &&
          statusSet.status !== "completed") ? (
          <div style={{ color: textColor(statusSet.status) }}>
            {t(`refresh.${statusSet.status}`)}
          </div>
        ) : (
          "-"
        )}
        {!refresh ? (
          <div className="flex_center">
            {(statusSet.status === "queued" ||
              statusSet.status === "relayed" ||
              statusSet.status === "scheduled") && (
              <Tooltip title={t("refresh.autoRefresh")} placement="bottom">
                <div
                  className="flex_center pointer ml-1"
                  style={{transform:'scale(0.85) translateY(-1.5px)'}}
                >
                  <RestoreIcon onClick={() => autoRefresh()}/>
                </div>
              </Tooltip>
            )}
          </div>
        ) : (
          <div className="flex_center">
            <Tooltip
              title={`${t("refresh.autoRefreshRemain")} ${10 - times} ${t(
                "refresh.times"
              )}`}
              placement="bottom"
            >
              <div
                className="flex_column_center_center pointer ml-1"
                style={{transform:'scale(0.85) translateY(0.3px)'}}
                onClick={stopRefresh}
              >
                <svg
                  style={{ animation: "loading 3s infinite" }}
                  className="MuiSvgIcon-root makeStyles-reload_btn-41"
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12 6v1.79c0 .45.54.67.85.35l2.79-2.79c.2-.2.2-.51 0-.71l-2.79-2.79c-.31-.31-.85-.09-.85.36V4c-4.42 0-8 3.58-8 8 0 1.04.2 2.04.57 2.95.27.67 1.13.85 1.64.34.27-.27.38-.68.23-1.04C6.15 13.56 6 12.79 6 12c0-3.31 2.69-6 6-6zm5.79 2.71c-.27.27-.38.69-.23 1.04.28.7.44 1.46.44 2.25 0 3.31-2.69 6-6 6v-1.79c0-.45-.54-.67-.85-.35l-2.79 2.79c-.2.2-.2.51 0 .71l2.79 2.79c.31.31.85.09.85-.35V20c4.42 0 8-3.58 8-8 0-1.04-.2-2.04-.57-2.95-.27-.67-1.13-.85-1.64-.34z"></path>
                </svg>
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      <div className="detect-right">
        <div className="mr-1">{t("refresh.uploadDatetime")}</div>
        {statusSet.updated_at
          ? utcFormatToLocalDateTimeWithSecond(statusSet.updated_at)
          : "-"}
      </div>
    </>
  );
};

export default RefreshBtn;
