import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { apiSelectedIdsHandler } from '../helpers/apiSelectedIdsHandler';

export const updateFirmwareAPI = async (fwId, file, filePath, note, type, companyData, modelData, t, successfulText, { setPercentCompleted }) => {

    let formData = new FormData();
    formData.append('id', fwId);
    formData.append('note', note);
    apiSelectedIdsHandler(formData, companyData, 'company_ids[]');
    apiSelectedIdsHandler(formData, modelData, 'device_model_ids[]');
    if (file) formData.append('file', file);
    formData.append('file_path', filePath);
    formData.append('peripheral_type_id', type);
    formData.append('_method', 'PUT');

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/admin-utility/firmware/update`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
                'Content-type': 'multipart/form-data'
            },
            data: formData,
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                setPercentCompleted(percentCompleted);
            },
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
