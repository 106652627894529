export const apiSelectedIdsHandler = (formData, data, formDataKeyName) => {
    data.forEach(o => {
        formData.append(formDataKeyName, o.id);
    })
};

export const apiSelectedGroupsHandler = (formData, filterDevicesBy, selectedGroups) => {
    if (filterDevicesBy === 'filter_by_tag') {
        apiSelectedIdsHandler(formData, selectedGroups, 'tag_ids[]');
    };

    if (filterDevicesBy === 'filter_by_group') {
        apiSelectedIdsHandler(formData, selectedGroups, 'company_group_ids[]');
    };

    if (filterDevicesBy === 'excel_import') {
        formData.append('spreadsheet', selectedGroups);
    };
};

export const apiSelectedDeviceIdsHandler = (formData, filterDevicesBy, selectedDevicesIds) => {
    if (filterDevicesBy === 'excel_import') {
        formData.append('spreadsheet', selectedDevicesIds);
    }
    if (filterDevicesBy !== 'excel_import') {
        selectedDevicesIds.forEach(selectedDeviceId => {
            formData.append('device_ids[]', selectedDeviceId);
        })
    }
};
