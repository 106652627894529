import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";
import { currentDateInUTC } from "../helpers/datetimeHandlers";

export const setHeartBeatAPI = async (
    device_ids,
    sending_time,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();
    formData.append("execute_type", "set_heartbeat_time");
    formData.append("device_ids[]", device_ids);
    formData.append("sending_time", sending_time);
    try {
        let res = await axios({
            url: `${url}/api/v2/command/setting/heartbeat-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const screenshotsSendApi = async (device_id) => {
    const company_id = await localforage.getItem('companyId');
    const token = await localforage.getItem("token");
    let formData = new FormData();
    formData.append("execute_datetime", currentDateInUTC());
    formData.append("device_ids[]", device_id);
    formData.append("company_id", company_id);
    formData.append("execute_type", "screenshot");
    formData.append("type", "company");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/screenshot-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getScreenshotsApi = async (task_id) => {
    const token = await localforage.getItem("token");
    const user = await localforage.getItem("user");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/screenshots`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                user_id: user.id,
                task_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const screenshotDownloadUrlAPI = async (device_id, screenshot_id) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();

    formData.append("device_id", device_id);
    formData.append("screenshot_id", screenshot_id);
    try {
        let res = await axios({
            url: `${url}/api/v2/drive/file/download-screenshot`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const deleteAppApi = async (device_ids, name, t, successfulText) => {
    const token = await localforage.getItem("token");

    try {
        let res = await axios({
            url: `${url}/api/v2/command/delete-software-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                name,
                execute_type: "delete_software",
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getOperationApi = async (device_id) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/realtime-monitor/operation`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
