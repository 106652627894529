import React, { useState } from 'react';
import { Typography, Paper, Grid, FormControl, Box } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectDevices from '../device/SelectDevices';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import * as localforage from 'localforage';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { handleClose } from '../../helpers/commandConfirmationDialogAction';
import { ExcelFileRequiredMsg, DevicesRequiredText } from '../commons/ValidationText';
import { newVncPwdInitialValues, newVncPwdValuesSchema } from '../../config/multiDeviceTaskValuesSchema';
import DeviceDatatable from '../device/DeviceDatatableForCommand';
import { MutipleDevicesNewVncPasswordTextField  } from '../commons/Textfields';
import TwoFactorSwitch from './TwoFactorSwitch';
import { updateDevicesVncSecurityAPI } from '../../apis/updateDevicesVncSecurityAPI';
import { twoFactorTitle, confirmNewPasswordShouldBeTheSame } from '../../config/i18nText';

const VncSettingForm = props => {
    const classes = useStyles();
    const { dispatch, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName,
              storedDeviceValueToLocalforageName, storedFileValueToLocalforageName, 
              resetAllStoredValueInLocalforage, executeType
            } = props;
    const { t } = useTranslation('task');
    const [ open, setOpen] = useState(false);
    const [ selectedIds, setSelectedIds ] = useState(true);
    const [ isExecuting, setIsExecuting ] = useState(false);
    const [ isValidating, setIsValidating ] = useState(false); 
    const [ filterDevicesBy, setFilterDevicesBy ] = useState();
    const [ newPasswordsNotSame, setNewPasswordsNotSame ] = useState(false);
    const [ twoFactor, setTwoFactor ] = useState(false);
    const [ newPwd, setNewPwd ] = useState();
    
    const handleExecuteAction = async() => {
        setIsExecuting(true);
        await updateDevicesVncSecurityAPI(t, filterDevicesBy, selectedIds, twoFactor, newPwd, setOpen, resetAllStoredValueInLocalforage);
        dispatch({type: 'stopLoading'});
    };

    const handleTwoFactorChange = () => setTwoFactor(!twoFactor);

    return(
            <Formik
                initialValues={newVncPwdInitialValues}
                validationSchema={newVncPwdValuesSchema}
                onSubmit={async(values) => {

                    dispatch({type: 'showLoading'});

                    let selectedIds = await localforage.getItem(storedDeviceValueToLocalforageName); 
                    const ifNewPwdsSame = values.newPassword === values.confirmNewPassword;
                    setNewPasswordsNotSame(!ifNewPwdsSame);
                    setSelectedIds(selectedIds);
                    setFilterDevicesBy(values.devices);
                   
                    if (selectedIds && ifNewPwdsSame) {
                        //check if selectedIDs is an empty array
                        if (Array.isArray(selectedIds) && selectedIds.length < 1) {
                            return dispatch({type: 'stopLoading'}); 
                        }
                        setOpen(true);
                        setNewPwd(values.newPassword);
                        
                    } else {
                        dispatch({type: 'stopLoading'}); 
                    }
                }}
            >
            {({
                values, errors, touched, handleChange, handleSubmit, isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}1. {t('task.selectDevices')}</Typography>
                                    <SelectDevices 
                                        taskType={executeType}
                                        formikName="devices" 
                                        isSubmitting={isSubmitting}
                                        setSelectedIds={setSelectedIds}
                                        formikOnChange={e => {
                                            setFilterDevicesBy(e.target.value);
                                            return handleChange(e)}}
                                        formikValue={values.devices} 
                                        formikError={(errors.devices && touched.devices && errors.devices)}
                                        filterDevicesBy={filterDevicesBy} 
                                        storedGroupIdsAndTagIds={true}
                                        storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName} 
                                        storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                                        storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                                    />
                                    {filterDevicesBy !== 'excel_import' && 
                                        <DeviceDatatable
                                            taskType={executeType}
                                            filterDevicesBy={filterDevicesBy} 
                                            setSelectedIds={setSelectedIds}
                                            storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName} 
                                            storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                                        />
                                    }
                                    { filterDevicesBy !== 'excel_import' && !selectedIds ? <DevicesRequiredText />  : ''}
                                    { filterDevicesBy === 'excel_import' && !selectedIds ? <ExcelFileRequiredMsg /> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}2. {t('task.setNewPwdForDevices')}</Typography>
                                    <Box className="flex">
                                        <Box mt={2} mr={2}>
                                            <MutipleDevicesNewVncPasswordTextField 
                                                formikName="newPassword"
                                                formikOnChange={handleChange}
                                                formikValue={values.newPassword}
                                                label={t('task.newPassword')}
                                                error={errors.newPassword}
                                                isValidating={isValidating}
                                                errorText={errors.newPassword}
                                            />
                                        </Box>   
                                        <Box mt={2}> 
                                            <MutipleDevicesNewVncPasswordTextField 
                                                formikName="confirmNewPassword"
                                                formikOnChange={handleChange}
                                                formikValue={values.confirmNewPassword}
                                                label={t('task.confirmNewPassword')}
                                                error={newPasswordsNotSame || errors.confirmNewPassword}
                                                isValidating={isValidating}
                                                errorText={newPasswordsNotSame ? confirmNewPasswordShouldBeTheSame(t) : errors.confirmNewPassword}
                                            />
                                        </Box>
                                    </Box>
                                    <TwoFactorSwitch 
                                        title={twoFactorTitle(t)}
                                        checked={twoFactor}
                                        onChange={handleTwoFactorChange}
                                        disabled={isExecuting}
                                        name="twoFactor"
                                    />
                                </Paper>
                                <div className="submit-back-btn-wrap"> 
                                    <StyledSubmitBtn 
                                        startIcon={<SendRoundedIcon />} 
                                        btnText={t('btn.confirmBtn')} 
                                        type="submit" 
                                        onClick={()=>{
                                            setIsValidating(true);
                                            handleSubmit();
                                        }}
                                    />
                                    <BackBtn />
                                    <CommandConfirmDialog
                                        isExecuting={isExecuting}
                                        okBtnOnClick={handleExecuteAction}
                                        backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                        open={open}
                                        dialogTitle={t('task.vncSetting')}
                                        dialogContent={t('modal.vncSettingDescription')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        </FormControl>
                </Form>
            )}
        </Formik>
    )
}

export default VncSettingForm;