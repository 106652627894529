import localforage from 'localforage';
import { sortArrByName, buildArrWithNameAndIdAndCheckBox } from './arrayHandlers';
import { supportWindowsAutoLaunchFileType, supportAndroidAutoLaunchFileType } from './fileExtentionArrayList';

export const getAllGroups = async (filterDevicesBy, setGroups, setLoading) => {

    if (filterDevicesBy === 'filter_by_group') {
        const companyGroupsArr = await localforage.getItem('companyGroups');
        buildArrWithNameAndIdAndCheckBox(companyGroupsArr, setGroups);
        setLoading(false);
    }

    if (filterDevicesBy === 'filter_by_tag') {
        const allTagsData = await localforage.getItem('allTagsData');
        const sortedTagArr = sortArrByName(allTagsData);
        buildArrWithNameAndIdAndCheckBox(sortedTagArr, setGroups);
        setLoading(false);
    }
}


export const handleDeviceCheckStatusChange = async (deviceId, checkedStatus, localforageKeyName, setSelectedIds) => {

    const currentDeviceIdsArr = await localforage.getItem(localforageKeyName);
    const currentDeviceIdsSet = [...new Set(currentDeviceIdsArr)];

    checkedStatus ? currentDeviceIdsSet.push(deviceId) : currentDeviceIdsSet.splice(currentDeviceIdsSet.indexOf(deviceId), 1);

    if (currentDeviceIdsSet.length > 0) {
        await localforage.setItem(localforageKeyName, [...currentDeviceIdsSet]);
        if (setSelectedIds) setSelectedIds([...currentDeviceIdsSet]);
    }
    if (currentDeviceIdsSet.length < 1) {
        await localforage.removeItem(localforageKeyName);
        if (setSelectedIds) setSelectedIds(false); // set false rather than [], since this is a user behaviour after a checkbox status is changed by user's click, which needs validation here
    }
};


export const checkDeviceAvailability = (deviceOSType, file) => {

    let fileExtension = file.name.split('.').pop().toLowerCase();

    if ((deviceOSType === 'android' && supportAndroidAutoLaunchFileType.includes(fileExtension)) ||
        (deviceOSType === 'windows' && supportWindowsAutoLaunchFileType.includes(fileExtension))) {
        return true;
    }

    return false;

}

export const storeDeviceAndSetSelectedDeviceIds = async (availableDeviceIds, localforageKeyName, setSelectedIds) => {

    if (availableDeviceIds.length > 0) {
        await localforage.setItem(localforageKeyName, availableDeviceIds);
        setSelectedIds(availableDeviceIds);
    }
    if (availableDeviceIds.length < 1) {
        await localforage.removeItem(localforageKeyName);
        setSelectedIds([]); // set false rather than [], since this is the initial render of datatable, which doesn't need valiadation here
    }
}
