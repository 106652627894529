import React from 'react';
import { Typography, TextField } from '@material-ui/core';
import useStyles from '../../styles/fileDeploymentStyle';
import { useTranslation } from "../../langs/useTranslation";

const FileStoredLocationTextField = (props) => {
    const { t } = useTranslation('task');
    const classes = useStyles();
    const { formikName, FileStoredLocationTextFieldPlaceHolder, formikOnChange, formikOnBlur, formikValue} = props;
    
    return(
        <>
            <Typography variant="h6">{t('task.fileStoredLocationOptional')}</Typography>
            <TextField fullWidth name={formikName} placeholder={FileStoredLocationTextFieldPlaceHolder} onChange={formikOnChange} onBlur={formikOnBlur} value={formikValue} className={classes.inputField}></TextField>
            <Typography variant="caption" color="secondary">{t('task.fileStoredLocationDefaultExample')}</Typography>
        </>
    );
};

export default FileStoredLocationTextField;