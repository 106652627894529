import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import { language } from "./datatableLanguage";
import * as localforage from "localforage";
import * as $ from "jquery";
import "../css/dataTable.css";
import "datatables.net";
import "datetime-moment";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { datatableErrorHandling } from "./datatableErrorHandling";
import { scheduledDateInLocal } from "./datetimeHandlers";
import { Checkbox } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import HasPermission from "../components/commons/HasPermission";

export const table = async (t, managementHandler) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";
    $("#app-list-table").DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        pageLength: 25,
        ordering: true,
        select: {
            style: "multi",
        },
        ajax: {
            url: `${url}/api/v2/application/datatable`,
            type: "GET",
            data: {
                company_id: companyId,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                datatableErrorHandling(
                    error.responseJSON ? error.responseJSON.message : ""
                );
            },
            complete: async (data) => {
                await localforage.removeItem("dtDeviceIds");
                $("#app-list-table input[type=checkbox]").each(function () {
                    $(this).prop("checked", false);
                });
            },
        },
        columnDefs: [
            {
                targets: 0,
                searchable: false,
                checkboxes: {
                    selectRow: true,
                },
            },
        ],
        columns: [
            {
                data: "id",
                className: "checkbox",
                name: "id",
                searchable: false,
                createdCell: (td, data, row) => {
                    ReactDOM.render(
                        <HasPermission permission="app_update_service_delete_app">
                            {row.application_version !== null ? (
                                <Tooltip title={t("noty.needToUnpublish")} placement="top">
                                    <div className="disabledCheckboxOuter">
                                        <div className="disabledCheckbox"></div>
                                    </div>
                                </Tooltip>
                            ) : (
                                <Checkbox value={data} />
                            )}
                        </HasPermission>,
                        td
                    );
                },
            },
            {
                data: "name",
                className: "name",
                title: `${t("common.appName")}`,
                searchable: true,
                createdCell: (td, data) => {
                    ReactDOM.render(data || "-", td);
                },
            },
            {
                data: "application_version",
                className: "publishVersion",
                title: `${t("common.publishVersion")}`,
                searchable: false,
                orderable: false,
                createdCell: (td, data) => {
                    ReactDOM.render((data && data.version) || "-", td);
                },
            },
            {
                data: "updated_at",
                className: "updated_at",
                title: `${t("common.uploadedDatetime")}`,
                searchable: false,
                createdCell: (td, data) => {
                    ReactDOM.render(data ? scheduledDateInLocal(data) : "-", td);
                },
            },
            {
                data: "updated_at",
                name: "",
                className: "appVersionManagement",
                title: `${t("common.appDetail")}`,
                searchable: false,
                orderable: false,
                createdCell: (td, data, row) => {
                    ReactDOM.render(
                        <HasPermission permission="app_update_service_app_version_management">
                            <div
                                className="appVersionManagementBtn"
                                onClick={() => managementHandler(row)}
                            >
                                {t("common.appVersionManagement")}
                            </div>
                        </HasPermission>,
                        td
                    );
                },
            },
        ],
    });
    searchStyleRender();
};
