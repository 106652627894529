import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  AppBar,
  Tabs,
  Tab,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import { getDeviceKeyName } from "./../../helpers/commonHelper";
import { useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@material-ui/core/styles";
import LoadingIndicator from "../commons/LoadingIndicator";
import {
  windowsDeviceHardwareCPUInfoMap,
  windowsDeviceHardwareMotherboardInfoMap,
  windowsDeviceHardwareStorageInfoMap,
  windowsDeviceHardwareRAMInfoMap,
  androidDeviceHardwareGeneralInfoMap,
  androidDeviceHardwareSOCInfoMap,
} from "../../helpers/deviceHardwareInfoMap";
import { a11yProps, TabPanel } from "../../helpers/tabPanelHandler";

const HardwareInfo = (props) => {
  const theme = useTheme();
  const { device_id, device_os_type } = useParams();
  const { t } = useTranslation("device");
  const { hardware } = props;
  const [graphicInfo, setGraphicInfo] = useState([]);
  const [value, setValue] = useState(0);
  const [tab1InfoMap, setTab1InfoMap] = useState([]);
  const [tab2InfoMap, setTab2InfoMap] = useState([]);
  const [tab3InfoMap, setTab3InfoMap] = useState([]);
  const [tab4InfoMap, setTab4InfoMap] = useState([]);
  const [tab1Info, setTab1Info] = useState([]);
  const [tab2Info, setTab2Info] = useState([]);
  const [tab3Info, setTab3Info] = useState([]);
  const [tab4Info, setTab4Info] = useState([]);
  const [loading, setLoading] = useState(true);
  const deviceIdFromQueryString = device_id;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const getStoredInfo = async () => {
    const gotInfo = await getDeviceKeyName(
      "hardwareInfo",
      deviceIdFromQueryString
    );
    if (hardware) {
      setStates(hardware);
    } else {
      setStates(gotInfo);
    }
  };

  const setStates = (gotInfo) => {
    if (gotInfo && gotInfo.cpu) {
      const cpuJsonInfo = gotInfo.cpu.json;
      setTab1Info(cpuJsonInfo);
      setLoading(false);
    }

    if (gotInfo && gotInfo.motherboard) {
      const motherboardJsonInfo = gotInfo.motherboard.json;
      setTab2Info(motherboardJsonInfo);
      setLoading(false);
    }

    if (gotInfo && gotInfo.storages) {
      const storagesJsonArrInfo = gotInfo.storages.json;
      setTab3Info(storagesJsonArrInfo);
      setLoading(false);
    }

    if (gotInfo && gotInfo.graphic) {
      const graphicJsonInfo = gotInfo.graphic.json;
      setGraphicInfo(graphicJsonInfo);
      setLoading(false);
    }

    if (gotInfo && gotInfo.ram) {
      const ramJsonInfo = gotInfo.ram.json;
      setTab4Info(ramJsonInfo);
      setLoading(false);
    }

    if (gotInfo && gotInfo.general) {
      const generalJsonInfo = gotInfo.general.json;
      setTab1Info(generalJsonInfo);
      setLoading(false);
    }

    if (gotInfo && gotInfo.soc) {
      const socJsonInfo = gotInfo.soc.json;
      setTab2Info(socJsonInfo);
      setLoading(false);
    }
  };

  const handleIfOsType = () => {
    if (device_os_type === "windows") {
      setTab1InfoMap(windowsDeviceHardwareCPUInfoMap);
      setTab2InfoMap(windowsDeviceHardwareMotherboardInfoMap);
      setTab3InfoMap(windowsDeviceHardwareStorageInfoMap);
      setTab4InfoMap(windowsDeviceHardwareRAMInfoMap);
    }
    if (device_os_type === "android") {
      setTab1InfoMap(androidDeviceHardwareGeneralInfoMap);
      setTab2InfoMap(androidDeviceHardwareSOCInfoMap);
    }
  };

  useEffect(() => {
    getStoredInfo();
    handleIfOsType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hardware]);

  useEffect(() => {
    handleIfOsType();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_os_type]);

  return (
    <div>
      <AppBar position="static" color="default">
        {device_os_type === "windows" ? (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={t("deviceInformation.hardwareCPUTitle")}
              {...a11yProps(0)}
            />
            <Tab
              label={t("deviceInformation.hardwareMotherbardTitle")}
              {...a11yProps(1)}
            />
            <Tab
              label={t("deviceInformation.hardwareStorageTitle")}
              {...a11yProps(2)}
            />
            <Tab
              label={t("deviceInformation.hardwareRAMTitle")}
              {...a11yProps(3)}
            />
          </Tabs>
        ) : (
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
          >
            <Tab
              label={t("deviceInformation.hardwareGeneralTitle")}
              {...a11yProps(0)}
            />
            <Tab
              label={t("deviceInformation.hardwareSOCTitle")}
              {...a11yProps(1)}
            />
          </Tabs>
        )}
      </AppBar>
      {loading ? (
        <div className="smaller-loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <SwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={value}
          onChangeIndex={handleChangeIndex}
        >
          <TabPanel
            value={value}
            index={0}
            dir={theme.direction}
            boxSpacing={3}
          >
            <TableContainer>
              <Table>
                <TableBody>
                  {tab1InfoMap.map((info, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {t(`hardwareInformation.${info}`)}
                        </TableCell>
                        <TableCell align="right">
                          {tab1Info[info] || "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {device_os_type === "windows"
                    ? graphicInfo.map((obj, ArrayIndex) => {
                        return (
                          <TableRow key={ArrayIndex}>
                            <TableCell component="th" scope="row">
                              {t("hardwareInformation.processorGraphicName")}
                            </TableCell>
                            <TableCell align="right">
                              {obj.processor_graphic_name || "-"}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    : ""}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel
            value={value}
            index={1}
            dir={theme.direction}
            boxSpacing={3}
          >
            <TableContainer>
              <Table>
                <TableBody>
                  {tab2InfoMap.map((info, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell component="th" scope="row">
                          {t(`hardwareInformation.${info}`)}
                        </TableCell>
                        <TableCell align="right">
                          {tab2Info[info] || "-"}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel
            value={value}
            index={2}
            dir={theme.direction}
            boxSpacing={3}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tab3InfoMap.map((info, index) => {
                      return (
                        <TableCell key={index} component="th" scope="row">
                          {t(`hardwareInformation.${info}`)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tab3Info.map((obj, ArrayIndex) => {
                    return (
                      <TableRow key={ArrayIndex}>
                        {tab3InfoMap.map((info, index) => {
                          return (
                            <TableCell key={index}>
                              {obj[info] || "-"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
          <TabPanel
            value={value}
            index={3}
            dir={theme.direction}
            boxSpacing={3}
          >
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {tab4InfoMap.map((info, index) => {
                      return (
                        <TableCell key={index}>
                          {t(`hardwareInformation.${info}`)}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tab4Info.map((obj, ArrayIndex) => {
                    return (
                      <TableRow key={ArrayIndex}>
                        {tab4InfoMap.map((info, index) => {
                          return (
                            <TableCell key={index}>
                              {obj[info] || "-"}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </TabPanel>
        </SwipeableViews>
      )}
    </div>
  );
};

export default HardwareInfo;
