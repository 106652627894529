import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import TaskSchdule from "../groups/TaskSchdule"

const DeleteDialog = (props) => {
    const classes = useStyles();
    const {
        open,
        onClose,
        onConfirm,
        loading,
        content,
        header,
        taskSchedule,
        deleteData
    } = props;
    const { t } = useTranslation("groups");
    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">{header}</DialogTitle>
            <>
                <DialogContent>
                    {content} {deleteData && deleteData.name && `${deleteData.name} ?`}
                    <TaskSchdule open={open} taskSchedule={taskSchedule} type='remove' />
                </DialogContent>
                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        disabled={loading}
                        type="button"
                        btnText={t("dialog.confirmBtn")}
                        onClick={() => onConfirm()}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default DeleteDialog;
