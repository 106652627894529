import * as Yup from 'yup';
import { pwdLengthError, thisIsRequired, invalidVncPwdChar } from '../config/i18nText';
import { vncPwdRegex } from '../helpers/regex';

export const deployFileInitialValues = {
    files: '',
    date: '',
    autoLaunch: [],
    location: '',
    command: ''
};

export const deployFileValuesSchema = Yup.object().shape({
    files: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
    location: Yup.string(),
    command: Yup.string(),
    autoLaunch: Yup.array(),
});

export const restartSystemInitialValues = {
    date: '',
    command: '',
    autoLaunch: []
}

export const kioskModeValuesSchema = Yup.object().shape({
    date: Yup.string().required('This is required!'),
    mode: Yup.string().required('This is required!'),
    command: Yup.string(),
    enableApplication: Yup.string(),
});

export const kioskModeInitialValues = {
    date: '',
    mode: '',
    command: '',
    enableApplication: ''
};

export const restartSystemValuesSchema = Yup.object().shape({
    date: Yup.string().required('This is required!'),
    command: Yup.string(),
});

export const biosFwInitialValues = {
    file: '',
    date: '',
    command: ''
};

export const biosFwValuesSchema = Yup.object().shape({
    file: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
    command: Yup.string(),
});

export const ioInitialValues = {
    port: '',
    voltage: '',
    date: '',
}

export const ioValuesSchema = Yup.object().shape({
    port: Yup.string().required('This is required for loading device table.'),
    voltage: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
});

export const oraUpdatAndScreenShotInitialValues = {
    date: '',
};

export const oraUpdatAndScreenShotValuesSchema = Yup.object().shape({
    date: Yup.string().required('This is required!'),
});

export const rotationValuesSchema = Yup.object().shape({
    orientation: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
});

export const rotationInitialValues = {
    orientation: '',
    date: '',
};


export const vncPwdValuesSchema = (t, hasOldPwd) => {
    if (hasOldPwd) {
        return Yup.object({
            oldPassword: Yup.string().required(thisIsRequired(t)).matches(vncPwdRegex, invalidVncPwdChar(t)),
            newPassword: Yup.string().min(8, pwdLengthError(t)).max(8, pwdLengthError(t)).required(thisIsRequired(t)).matches(vncPwdRegex, invalidVncPwdChar(t)),
            confirmNewPassword: Yup.string().min(8, pwdLengthError(t)).max(8, pwdLengthError(t)).required(thisIsRequired(t)).matches(vncPwdRegex, invalidVncPwdChar(t)),
        })
    } else {
        return Yup.object({
            newPassword: Yup.string().min(8, pwdLengthError(t)).max(8, pwdLengthError(t)).required(thisIsRequired(t)).matches(vncPwdRegex, invalidVncPwdChar(t)),
            confirmNewPassword: Yup.string().min(8, pwdLengthError(t)).max(8, pwdLengthError(t)).required(thisIsRequired(t)).matches(vncPwdRegex, invalidVncPwdChar(t)),
        })
    }
};

export const vncPwdInitialValues = hasOldPwd => {
    if (hasOldPwd) {
        return {
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: ''
        }
    } else {
        return {
            newPassword: '',
            confirmNewPassword: ''
        }
    }
};
