import React, { useEffect, useRef } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dashboardStyle";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { getOraVersionsAPI } from "../../apis/getOraVersionsAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import * as $ from "jquery";
import { NoDataText } from "../commons/ValidationText";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { Bar, getElementAtEvent } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { useHistory } from "react-router-dom";
import { chartColors } from "../../helpers/commonHelper";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const OraVersionDistribution = (props) => {
  const { companyId } = props;
  let history = useHistory();
  const chartRef = useRef(null);
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
  const cardSubtitleTypographyVariant = props.cardSubtitleTypographyVariant;
  const [oraVersions, setOraVersions] = useStateOnMounted({});
  const [reload, setReload] = useStateOnMounted(true);

  const options = {
    indexAxis: "y",
    // no show side label plugins: {
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        display: false,
      },
      x: {
        ticks: {
          beginAtZero: true,
          callback: (value) => (value % 1 === 0 ? value : null),
        },
      },
    },
    onHover: function(e, item) {
      $(".pieChart canvas").css("cursor", item[0] ? "pointer" : "default");
    },
  };
  const clickHandler = (event) => {
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);
    if (element) {
      const itemLabel = oraVersions?.labels[element[0]?.index]?.split(" ");
      if (itemLabel) {
        history.push(
          `/device-list?company=${companyId}&os_type=${itemLabel[0]?.toLowerCase()}&ora_version=${
            itemLabel[1]
          }`
        );
      }
    }
  };

  const clickLable = (e) => {
    const itemLabel = e.split(" ");
    history.push(
      `/device-list?company=${companyId}&os_type=${itemLabel[0]?.toLowerCase()}&ora_version=${
        itemLabel[1]
      }`
    );
  };

  const getOraVersions = async () => {
    setReload(true);
    const data = await getOraVersionsAPI();
    let isDataEmpty;
    if (data) {
      isDataEmpty = data && Object.values(data).every((value) => value === 0);
    }

    if (!isDataEmpty) {
      let formatData = {
        labels: [],
        datasets: [
          {
            label: "",
            data: [],
            backgroundColor: [],
            borderColor: [],
          },
        ],
      };
      data?.forEach((e, i) => {
        formatData.labels.push(
          `${e?.os_type[0].toUpperCase() + e?.os_type?.slice(1)} ${
            e?.ora_version
          }`
        );
        formatData.datasets[0].data.push(e?.total);
        formatData.datasets[0].backgroundColor.push(chartColors[i]);
        formatData.datasets[0].borderColor.push(chartColors[i]);
      });
      setOraVersions(formatData);
    } else {
      setOraVersions(false);
    }
    setReload(false);
  };

  useEffect(() => {
    getOraVersions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper>
      <div>
        <Typography
          variant={cardTitleTypographyVariant}
          className={classes.cardTitleWrapWithReloadIcon}
        >
          {t("dashboard.oraVersionDistribution")}
          <AutorenewRoundedIcon
            onClick={getOraVersions}
            size="small"
            className={classes.reload_btn}
          />
        </Typography>
        {!reload ? (
          oraVersions ? (
            <>
              <div className="pieChart" style={{ margin: "30px 0 20px" }}>
                <Bar
                  ref={chartRef}
                  data={oraVersions}
                  options={options}
                  onClick={clickHandler}
                />
              </div>
              <Grid
                container
                className={`${classes.clientDistrubutionTabs} ${classes.scrollModelLabels}`}
              >
                {oraVersions?.labels.map((e, i) => (
                  <Grid
                    key={e}
                    item
                    xs={6}
                    sm={6}
                    md={6}
                    lg={6}
                    className={`${classes.clientDistrubutionTab} pointer_label`}
                    onClick={() => clickLable(e)}
                  >
                    <div
                      className="colorCube"
                      style={{
                        background:
                          oraVersions?.datasets[0]?.backgroundColor[i],
                      }}
                    ></div>
                    <Typography
                      className={classes.clientDistrubutionTabText}
                      variant={cardSubtitleTypographyVariant}
                    >
                      {e}
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </>
          ) : (
            <NoDataText />
          )
        ) : (
          <SmallerLoadingIndicator />
        )}
      </div>
    </Paper>
  );
};

export default OraVersionDistribution;
