import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
    root:{
        position: 'relative',
        marginBottom: '48px !important',
        marginRight: '10px !important',
        width: '150px !important',
        '& .MuiButton-root': {
            marginBottom: '0 !important',
            position: 'relative',
            zIndex: '1'
        }
    },
    btn: {
        width: '100% !important',
        '&:hover': {
            color: '#fff',
        }
    },
    buttonProgress: {
        position: 'absolute',
        color: '#fff !important',
        zIndex: 2,
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    }
})

export default useStyles;