import React, { useState } from 'react';
import { InputBase, IconButton } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import useStyles from '../../styles/appBarRightStyle';
import { useTranslation } from "../../langs/useTranslation";

const SearchBar = () => {
    const classes = useStyles();
    const { t } = useTranslation('common'); 
    const [ searchValue, setSearchValue ] = useState('');

    const handleSearchValue = (e) => {
      setSearchValue(e.target.value)
    }

    const handleSearchValueSubmit = () => {
      window.location.replace(`/device-list?search=${searchValue}`)
    }

    return(
      <div className={classes.search}>
            <IconButton 
              aria-label="Search" 
              color="inherit"
              onClick={handleSearchValueSubmit}
              className={classes.searchIcon}>
              <SearchIcon />
            </IconButton>
            <InputBase
              onKeyPress={(e)=>{
                if (e.key === 'Enter') {
                  handleSearchValueSubmit()
                }
              }
              } 
              placeholder={t('appbar.searchFieldText')}
              onChange={(e)=>handleSearchValue(e)}
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
        </div>
    )
}

export default SearchBar;