import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/styles";
import useStyles from "../styles/hocForFullWidthTableStyle";

class HOCforFullWidthTable extends React.Component {
    render() {
        const { classes, noMargin, loading = false } = this.props;
        return (
            <div
                className={`${classes.table} ${classes.tableMoveTop}`}
                style={{
                    marginRight: noMargin ? "0" : "unset",
                    marginLeft: noMargin ? 0 : "unset",
                    display: loading ? "none" : "block",
                }}
            >
                {this.props.children}
            </div>
        );
    }
}

HOCforFullWidthTable.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(useStyles)(HOCforFullWidthTable);
