import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import { Paper } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import MultipleTaskBtnListDialog from "./MultipleTaskBtnListDialog";
import SearchFieldAndButton from "../commons/SearchFieldAndButton";
import * as $ from "jquery";
import { useEffect } from "react";

const DeviceListTable = (props) => {
  const classes = useStyles();
  const [resetSearchField, setResetSearchField] = useState(true);
  const { selectKey, query, reloadTableWithGroupId } = props;
  const { t } = useTranslation("device");
  const [open, setOpen] = useState(false);

  const searchDatatable = (searchValue) =>
    $("#device-list-table")
      .DataTable()
      .search(searchValue)
      .draw();

  const reloadTable = () => {
    setResetSearchField(true);
    reloadTableWithGroupId(t, query, selectKey);
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    reloadTable();
  }, [selectKey])

  return (
    <>
      <Paper className={`${classes.tableMoveBottomReset} no-input`}>
        {/* <HasPermission permission="multiple_task">
          <Button
            onClick={handleDialogOpen}
            variant="contained"
            className={`${classes.listBtn} ${classes.marginRight_16}`}
            startIcon={<CallToActionRoundedIcon />}
          >
            {t("btn.multipleTasks")}
          </Button>
        </HasPermission> */}

        <div className={classes.iconButtonGroup}>
          <SearchFieldAndButton
            resetSearchField={resetSearchField}
            setResetSearchField={setResetSearchField}
            searchDatatableForTable={searchDatatable}
          />
        </div>
          <AutorenewRoundedIcon
            onClick={() => reloadTable()}
            size="small"
            className={`${classes.listBtn} ${classes.reload_btn}`}
          />
        <table
          id="device-list-table"
          style={{ marginTop: "5px" }}
          className="mdl-data-table"
        >
          <thead>
            <tr>
              <th className="status">{t("category.status")}</th>
              <th className="serial_number">{t("category.serialNumber")}</th>
              <th className="ora_version">{t("category.nubisVersion")}</th>
              <th className="nubis_version">{t("category.oraVersion")}</th>
              <th className="owners">{t("category.owners")}</th>
              <th className="power">{t("category.power")}</th>
              <th className="security">{t("category.security")}</th>
              <th>{t("category.tag")}</th>
            </tr>
          </thead>
        </table>
      </Paper>
      <MultipleTaskBtnListDialog open={open} onClose={handleDialogClose} />
    </>
  );
};

export default DeviceListTable;
