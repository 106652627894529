import { getRealTimeDataAPI } from "../apis/getRealTimeDataAPI";
import { formatToLocalTime } from "../helpers/datetimeHandlers";
import { timestampCherryPick } from "./timestampCherryPick";

export const getRealTimeData = async (device_id, type, take, mins) => {
    let realTimeData = await getRealTimeDataAPI(device_id, type, mins, 1);
    /* ==== Ad-hoc Solution for datapoint reduce.(this should process at backend) ===== */
    realTimeData = timestampCherryPick(mins, realTimeData);
    /* ========== */
    if (realTimeData) {
        const valueArr = await realTimeData.map((item) => {
            if (type === "connection") {
                if (item.value === "0") {
                    return "Offline";
                } else {
                    return "Online";
                }
            } else {
                return item.value;
            }
        });
        const labelArr = await realTimeData.map((item) => {
            const time = formatToLocalTime(item.created_at);
            return time; // this is to break the date and time in seperate line
        });
        const date = await realTimeData.map((item) => {
            const date = formatToLocalTime(item.created_at); // utc to local time zone
            return date; // this is to break the date and time in seperate line
        });
        const unit = await realTimeData.map((item) => {
            return item.unit || "%"; // this is to break the date and time in seperate line
        });

        const data = {
            valueArr: valueArr.reverse(),
            labelArr: labelArr.reverse(),
            date: date,
            unit: unit,
        };
        return data;
    }
};
