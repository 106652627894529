import React from 'react';
import ReactDOM from 'react-dom';
import { url } from '../apis/apiSetup';
import * as localforage from 'localforage';
import '../css/dataTable.css';
import * as $ from 'jquery';
import 'datatables.net';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { DefaultCheckedCheckBox, UncheckedCheckBox } from '../components/commons/CheckBoxes';
import { language } from './datatableLanguage';
import { handleDeviceCheckStatusChange, checkDeviceAvailability, storeDeviceAndSetSelectedDeviceIds } from './taskDatatableHandlers';

export const deployFileDeviceTable = async (t, filterDevicesBy, selectedGroups, selectedTags, localforageKeyName, file, setSelectedIds, isAutoLaunch) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');
    const selectedGroupIds = filterDevicesBy === 'filter_by_group' && selectedGroups ? selectedGroups.map(o => o.id) : undefined;
    const selectedTagIds = filterDevicesBy === 'filter_by_tag' && selectedTags ? selectedTags.map(o => o.id) : undefined;
    $.fn.dataTable.ext.errMode = 'throw';

    $('#deploy-file-device-table').DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        order: [[0, 'asc']],
        filter: false,
        deferRender: true,
        info: true,
        autoWidth: false,
        stateSave: false,
        searching: false,
        destroy: true,
        scrollCollapse: true,
        scrollY: '40vh',
        paging: false,
        ordering: true,
        ajax: {
            url: `${url}/api/v2/devices/device-list-command`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                company_id: companyId,
                company_group_ids: selectedGroupIds,
                tag_ids: selectedTagIds,
            },
            beforeSend: (request) => {
                request.setRequestHeader('Authorization', 'Bearer ' + token);
                request.setRequestHeader('Accept', 'application/json');
            },
            complete: async (response) => {

                $('#deploy-file-device-table').DataTable().rows({ page: 'all' }).select();

                if (response.responseText && file && file.name) {

                    const jsonResponseText = JSON.parse(response.responseText);
                    let deviceMap = jsonResponseText.data;
                    let nubisProDeviceIds = deviceMap.filter(o => o.nubis_version);

                    if (!isAutoLaunch) {
                        let availableDeviceIds = nubisProDeviceIds.map(o => o.id);
                        storeDeviceAndSetSelectedDeviceIds(availableDeviceIds, localforageKeyName, setSelectedIds);
                    }

                    if (isAutoLaunch) {
                        let availableDeviceIds = await nubisProDeviceIds.filter(o => checkDeviceAvailability(o.os_type, file)).map(o => o.id);
                        storeDeviceAndSetSelectedDeviceIds(availableDeviceIds, localforageKeyName, setSelectedIds);
                    }
                }
            },
        },
        select: {
            style: 'multi',
        },
        columns: [
            {
                data: 'id',
                orderable: false,
                searchable: false,
                defaultContent: '',
                className: 'select-checkbox',
                createdCell: (td, data, rowData) => {

                    const uncheckedCheckBox = <UncheckedCheckBox data={data} localforageKeyName={localforageKeyName} handleDeviceCheckStatusChange={handleDeviceCheckStatusChange} />;
                    const defaultCheckedCheckBox = <DefaultCheckedCheckBox data={data} localforageKeyName={localforageKeyName} handleDeviceCheckStatusChange={handleDeviceCheckStatusChange} />;

                    if (file && file.name) {
                        deviceCheckbox(td, rowData, defaultCheckedCheckBox, uncheckedCheckBox, isAutoLaunch, file);
                    } else {
                        ReactDOM.render(uncheckedCheckBox, td);
                    }
                }
            },
            {
                data: 'serial_number',
                title: `${t('task.serialNumber')}`,
                className: 'serial-number',
                render: data => {
                    return data;
                },
            },
            {
                data: 'nubis_version',
                title: `${t('task.nubisVersion')}`,
                className: 'serial-number',
                render: data => {
                    return `${t(`task.${data}`)}`;
                },
            },
            {
                data: 'os_type',
                title: `${t('task.osType')}`,
                className: 'os-type',
                render: data => {
                    return data;
                },
            },
            {
                data: 'os_type',
                title: `${t('task.autoLaunched')}`,
                className: 'egilibility',
                createdCell: async (td, data, rowData) => {

                    const checkedIcon = <CheckRoundedIcon color="primary" />;
                    const clearIcon = <ClearRoundedIcon color="secondary" />;

                    if (file && file.name) {
                        deviceEligibitlity(td, data, rowData, checkedIcon, clearIcon, file);
                    } else {
                        ReactDOM.render('-', td);
                    }
                },
            },
        ]
    })
}

const deviceCheckbox = (td, rowData, defaultCheckedCheckBox, uncheckedCheckBox, isAutoLaunch, file) => {

    let availableNubisVersion = rowData.nubis_version === 'nubis_pro';

    if (!isAutoLaunch && availableNubisVersion) {
        ReactDOM.render(defaultCheckedCheckBox, td);
    } else {
        ReactDOM.render(uncheckedCheckBox, td);
    }

    if (isAutoLaunch && availableNubisVersion && file) {

        let deviceOSType = rowData.os_type;
        let canAutoLaunch = checkDeviceAvailability(deviceOSType, file);

        if (canAutoLaunch) ReactDOM.render(defaultCheckedCheckBox, td);

    }

}

const deviceEligibitlity = async (td, deviceOSType, rowData, checkedIcon, clearIcon, file) => {

    let availableNubisVersion = rowData.nubis_version === 'nubis_pro';

    if (file && availableNubisVersion) {

        let canAutoLaunch = checkDeviceAvailability(deviceOSType, file);

        if (canAutoLaunch) {
            ReactDOM.render(checkedIcon, td);
        } else {
            ReactDOM.render(clearIcon, td);
        }

    } else {
        ReactDOM.render(clearIcon, td);
    }


}
