import React from 'react';
import { CodeRounded, PollOutlined, ImageOutlined, GestureOutlined, MovieRounded, PictureAsPdfRounded, DescriptionRounded, ArchiveRounded, LineWeightRounded, InsertDriveFileRounded, Folder, PermMedia } from '@material-ui/icons';
import { imageExtensionArray, videoExtensionArray, pdfExtensionArray, applicationExtensionArray, compressedFileExtensionArray, wordExtensionArray, excelExtensionArray, webFileExtensionArray, designFileExtensionArray } from './fileExtentionArrayList';
import { iconColors } from './commonHelper';

export const fileTypeIconDeterminator = (extension) => {

    let extensionInLowerCase = extension.toLowerCase();

    if (imageExtensionArray.includes(extensionInLowerCase)) {
        return <ImageOutlined fontSize="small" style={{ color: iconColors[0] }} />;
    }
    if (videoExtensionArray.includes(extensionInLowerCase)) {
        return <MovieRounded fontSize="small" style={{ color: iconColors[1] }} />;
    }
    if (pdfExtensionArray.includes(extensionInLowerCase)) {
        return <PictureAsPdfRounded fontSize="small" style={{ color: iconColors[2] }} />;
    }
    if (applicationExtensionArray.includes(extensionInLowerCase)) {
        return <ArchiveRounded fontSize="small" style={{ color: iconColors[4] }} />;
    }
    if (compressedFileExtensionArray.includes(extensionInLowerCase)) {
        return <LineWeightRounded fontSize="small" style={{ color: iconColors[3] }} />;
    }
    if (wordExtensionArray.includes(extensionInLowerCase)) {
        return <DescriptionRounded fontSize="small" style={{ color: iconColors[5] }} />;
    }
    if (excelExtensionArray.includes(extensionInLowerCase)) {
        return <PollOutlined fontSize="small" style={{ color: iconColors[7] }} />;
    }
    if (webFileExtensionArray.includes(extensionInLowerCase)) {
        return <CodeRounded fontSize="small" style={{ color: iconColors[9] }} className="file-icon" />;
    }
    if (designFileExtensionArray.includes(extensionInLowerCase)) {
        return <GestureOutlined fontSize="small" style={{ color: iconColors[8] }} className="file-icon" />;
    }
    if (extension === 'folder') {
        return <Folder fontSize="small" style={{ color: iconColors[10] }} />;
    }
    if (extension === 'screenshotFolder') {
        return <PermMedia fontSize="small" style={{ color: iconColors[10] }} />;
    }
    return <InsertDriveFileRounded fontSize="small" style={{ color: iconColors[6] }} />;
}
