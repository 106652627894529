import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/validationTextStyle';
import InsertDriveFileRoundedIcon from '@material-ui/icons/InsertDriveFileRounded';

export const RequiredText = () => {
    const { t } = useTranslation('task')
    return(
        <Typography variant="caption" color="error" display="block">
            {t('validation.required')}
        </Typography>
    )
};

export const ValidationErrorText = props => {
    const { text } = props;
    
    return(
        <Typography variant="caption" color="error" display="block">
            {text}
        </Typography>
    )
};


export const DateTimeComparisonText = () => {
    const { t } = useTranslation('task')
    return(
        <Typography variant="caption" color="error">
            {t('task.scheduledDatetimeNotGreaterThanCurrentDatetime')}
        </Typography>
    )
};

export const GroupOrTagRequiredText = () => {
    const { t } = useTranslation('task');
    return(
        <Typography variant="caption" color="error">{t('task.groupOrTagRequired')}</Typography>
    )
}

export const NumberRequiredText = () => {
    const { t } = useTranslation('common');
    return(
        <Typography variant="caption" color="error">{t('validation.numberRequiredText')}</Typography>
    )
}

export const EmailInvalidText = () => {
    const { t } = useTranslation('common')
    return(
        <Typography color="error" variant="caption">
            {t('validation.emailInvalid')}
        </Typography>
    )
}

export const EmailAlreadyExistText = () => {
    const { t } = useTranslation('common')
    return(
        <Typography color="error" variant="caption">
            {t('validation.emailAlreadtExist')}
        </Typography>
    )
}

export const NoDataText = () => {
    const { t } = useTranslation('dashboard');
    const classes = useStyles();
    
    return(
        <Typography variant="caption" color="secondary" className={classes.noDataText}>
            {t('dashboard.noData')}
        </Typography>
    )
}

export const FileNameText = (props) => {
    const classes = useStyles();
    const { fileName } = props;
    
    return(
        <Typography variant="caption" color="primary" display="block" className={`${classes.showFileUploadStatus} file-name`}>
            <InsertDriveFileRoundedIcon fontSize="small" />{fileName}
        </Typography>
    )
}

export const ExcelFileErrorMsg = (props) => {
    const { t } = useTranslation('task');
    const classes = useStyles();
    const { errorMsg } = props;

    return(
        <Typography variant="caption" color="error" display="block" className={classes.showFileUploadStatus}>
            {t(`task.${errorMsg}`)}
        </Typography>
    )
}

export const ExcelFileRequiredMsg = () => {
    const { t } = useTranslation('task');

    return(
        <Typography variant="caption" color="error">
            {t('task.pleaseUploadExcelFile')}
        </Typography>
    )
}

export const DevicesRequiredText = () => {
    const { t } = useTranslation('task')
    return(
        <Typography variant="caption" color="error" display="block">
            {t('validation.noDeviceHasBeenSelected')}
        </Typography>
    )
};

export const NoItemSelectedText = () => {
    const classes = useStyles();
    const { t } = useTranslation('task');

    return(
        <Typography variant="caption" display="block" className={classes.noItemSelectedText}>
            {t('validation.noItemSelected')}
        </Typography>
    )
};
    