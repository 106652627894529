import React, { useEffect, useState } from "react";
import { List, ListItemIcon, ListItemText, Divider } from "@material-ui/core";
import DashboardRoundedIcon from "@material-ui/icons/DashboardRounded";
import DevicesRoundedIcon from "@material-ui/icons/DevicesRounded";
// import AlarmRoundedIcon from "@material-ui/icons/AlarmRounded";
import StorageRoundedIcon from "@material-ui/icons/StorageRounded";
import LockRoundedIcon from "@material-ui/icons/LockRounded";
import DrawerListItems from "./DrawerListItems";
import PowerManagementIcon from "@material-ui/icons/PowerSettingsNew";
import SettingsRemoteIcon from "@material-ui/icons/SettingsRemote";
import useStyles from "../../styles/drawerStyle";
import ListItemLink from "../commons/ListItemLink";
import { useTranslation } from "../../langs/useTranslation";
import HasPermission from "../commons/HasPermission";
import localforage from "localforage";
import {
  /*drawerNubisDocListItems,*/ drawerNubisDriveListItems,
  drawerGroupsListItems,
} from "../../config/drawerListItems";
import GroupIcon from "@material-ui/icons/Group";
// import WidgetsIcon from "@material-ui/icons/Widgets";
const DrawerList = (props) => {
  const { t } = useTranslation("common");
  const classes = useStyles();
  const { isListExpanded } = props;
  const [isShowExpandedList, setIsShowExpandedList] = useState(isListExpanded);
  // const [isShowNubisDocList, setIsShowNubisDocList ] = useState(false);
  const [isShowNubisDriveList, setIsShowNubisDriveList] = useState(false);
  // let nubisDocListPermissions = drawerNubisDocListItems.map( o => o.permission);
  let NubisDriveListPermissions = drawerNubisDriveListItems.map(
    (o) => o.permission
  );

  const checkIsShowNubisDocList = async () => {
    const userPermissions = await localforage.getItem("userPermissions");
    if (userPermissions) {
      // const hasNubisDocPermission = userPermissions.filter(element => nubisDocListPermissions.includes(element))
      const hasNubisDrivePermission = userPermissions.filter((element) =>
        NubisDriveListPermissions.includes(element)
      );
      // setIsShowNubisDocList(hasNubisDocPermission.length>0)
      setIsShowNubisDriveList(hasNubisDrivePermission.length > 0);
    }
  };

  useEffect(() => {
    setIsShowExpandedList(isListExpanded);
    checkIsShowNubisDocList();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isListExpanded]);

  return (
    <>
      <List className={classes.listWrappper}>
        <HasPermission permission="dashboard_page">
          <ListItemLink to="/dashboard">
            <ListItemIcon>
              <DashboardRoundedIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.dashboard")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission>
        <HasPermission permission="groups_page">
          <ListItemLink
            to="/groups"
            disabled
            className={classes.drawerListCategoryTitle}
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.groups-devices")}</ListItemText>
          </ListItemLink>
          {isShowExpandedList && (
            <DrawerListItems listItems={drawerGroupsListItems} />
          )}
          <Divider />
        </HasPermission>
        <HasPermission permission="device_list_page">
          <ListItemLink to="/device-list">
            <ListItemIcon>
              <DevicesRoundedIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.deviceMonitoring")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission>
        <HasPermission permission="multiple_page">
          <ListItemLink to="/device-control">
            <ListItemIcon>
              <SettingsRemoteIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.deviceControl")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission>
        <HasPermission permission="power_management_page">
          <ListItemLink to="/power-management">
            <ListItemIcon>
              <PowerManagementIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.powerManagement")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission>
        {/* <HasPermission permission="alarm_notification_page">
          <ListItemLink to="/alarm-notification">
            <ListItemIcon>
              <AlarmRoundedIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.alarmNotification")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission> */}
        {/* { isShowNubisDocList &&
                    <>
                        <ListItemLink to="/nubis-docs" disabled>
                            <ListItemIcon><InsertDriveFileRoundedIcon /></ListItemIcon>
                            <ListItemText>{t('drawer.nubisDocs')}</ListItemText>
                        </ListItemLink>
                        {isShowExpandedList && (<DrawerListItems listItems={drawerNubisDocListItems} />)}
                        <Divider />
                    </>
                }  */}
        {isShowNubisDriveList && (
          <>
            <ListItemLink
              to="/nubis-drive"
              disabled
              className={classes.drawerListCategoryTitle}
            >
              <ListItemIcon>
                <StorageRoundedIcon />
              </ListItemIcon>
              <ListItemText>{t("drawer.nubisDrive")}</ListItemText>
            </ListItemLink>
            {isShowExpandedList && (
              <DrawerListItems listItems={drawerNubisDriveListItems} />
            )}
            <Divider />
          </>
        )}
        <HasPermission permission="permissions_page">
          <ListItemLink to="/permission" disabled>
            <ListItemIcon>
              <LockRoundedIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.permission")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission>
        {/* <HasPermission permission="app_update_service_page">
          <ListItemLink to="/app-update-service">
            <ListItemIcon>
              <WidgetsIcon />
            </ListItemIcon>
            <ListItemText>{t("drawer.appUpdateService")}</ListItemText>
          </ListItemLink>
          <Divider />
        </HasPermission> */}
      </List>
    </>
  );
};

export default DrawerList;
