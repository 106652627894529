import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";

export const blockWindowsNotifyApi = async (
    device_ids,
    spreadsheet,
    status,
    execute_type,
    execute_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");

    try {
        await axios({
            url: `${url}/api/v2/command/disable-windows-notify-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                type: "company",
                device_ids,
                spreadsheet,
                status,
                execute_type,
                execute_datetime,
                previous_status: !status,
            },
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const usbLockApi = async (
    device_ids,
    spreadsheet,
    is_usb_lock,
    execute_type,
    execute_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");

    try {
        await axios({
            url: `${url}/api/v2/command/usb-lock-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                type: "company",
                device_ids,
                spreadsheet,
                is_usb_lock,
                execute_type,
                execute_datetime,
                previous_status: !is_usb_lock,
            },
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const lockCombinationKeyApi = async (
    device_ids,
    spreadsheet,
    status,
    execute_type,
    execute_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");

    try {
        await axios({
            url: `${url}/api/v2/command/combination-key-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                type: "company",
                device_ids,
                spreadsheet,
                status,
                execute_type,
                execute_datetime,
                previous_status: !status,
            },
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const powerApi = async (
    device_ids,
    spreadsheet,
    device_status,
    system_booting_type,
    execute_type,
    execute_datetime,
    os_type,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");

    try {
        await axios({
            url: `${url}/api/v2/command/device-power-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                type: "company",
                device_ids,
                spreadsheet,
                device_status,
                system_booting_type,
                execute_type,
                execute_datetime,
                os_type,
            },
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};
