import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    multipleSelect: {
        '& .MuiChip-root': {
            margin: '0 5px 5px 0 !important',
            background: '#eee'
        },
        '& .Mui-disabled': {
            '& .MuiChip-root': {
                opacity: 0.5
            }
        },
        '& .MuiFormControlLabel-root': {
            height: 30,
            paddingLeft: 10,
        },
        '& .MuiChip-icon': {
            color: '#aaa',
            width: '17px',
            marginRight: '-9px'
        },
        '& .MuiChip-label': {
            fontWeight: 500,
        },
        '& .loading-wrapper': {
            transform: 'scale(0.5) !important',
            width: 'fit-content !important'
        }
    },
    lazyLoadWrap: {
        height: 115,
        overflowY: 'scroll',
        position: 'relative',
        marginTop: '10px !important',
        paddingTop: '10px !important',
        border: '1px solid #ddd',
        borderRadius: '8px !important'
    },
    loading: {
        height: '30px !important',
        lineHeight: '30px !important',
        color: '#ddd'
    },
    ruleAndEmailWrap: {
        height: '200px !important',
        paddingTop: '15px !important',
        '&>p': {
            marginLeft: '20px !important',
        },
        '& .lazyload-wrapper': {
            borderBottom: '1px dotted #eee',
            marginBottom: '10px !important'
        },
        '& .MuiFormControlLabel-root': {
            height: 'initial !important',
            alignItems: 'flex-start !important',
            marginBottom: '10px !important',
            '& span.MuiButtonBase-root': {
                marginTop: '-10px !important'
            },
        },
        '& .MuiFormControlLabel-label': {
            display: 'none !important'
        },
        '& .emails': {
            borderRight: '1px solid #ddd !important',
            marginRight: '10px !important',
            paddingRight: '10px !important',
            color: '#888 !important'
        }
    }
}))

export default useStyles;