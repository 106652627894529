export const fileNameWithoutExtensionGenerator = (fileNameWithExtension, extension) => {

    let fileNameWithExtenstionArray = fileNameWithExtension.split('.');

    // for initial filenames shown without extension
    if (!fileNameWithExtenstionArray.includes(extension)) {
        return fileNameWithExtension;
    }

    let extensionIndex = fileNameWithExtenstionArray.length - 1;
    let fileNameWithoutExtension = fileNameWithExtenstionArray.slice(0, extensionIndex).join('.');
    return fileNameWithoutExtension;
}
