import React, { Suspense, useReducer } from "react";
import Redirecting from "./containers/Redirecting";
import DeviceList from "./containers/DeviceList";
import DeviceDetail from "./containers/DeviceDetail";
import DeviceControl from "./containers/DeviceControl";
import DeployFile from "./containers/DeployFile";
import BIOSUpdate from "./containers/BIOSUpdate";
import FWUpdate from "./containers/FWUpdate";
import KioskMode from "./containers/KioskMode";
import OraUpdate from "./containers/OraUpdate";
import Screenshot from "./containers/Screenshot";
import IOSetUp from "./containers/IOSetUp";
import Rotation from "./containers/Rotation";
import Dashboard from "./containers/Dashboard";
import RestartSystem from "./containers/RestartSystem";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import PrivateRoute from "./hoc/PrivateRoute";
import Auth from "./auths/Auth";
import theme from "./theme/muiTheme";
import MomentUtils from "@date-io/moment";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/core/styles";
import PowerManagement from "./containers/PowerManagement";
import AdminUtility from "./containers/AdminUtility";
import AlarmNotification from "./containers/AlarmNotification";
import NoPermission from "./containers/NoPermission";
import {
    styledSubmitBtnState,
    styledSubmitBtnReducer,
} from "./reducers/styledSubmitBtnReducer";
import FileBrowser from "./containers/FileBrowser";
import SharedWithMe from "./containers/SharedWithMe";
import VNCSetting from "./containers/VNCSetting";
import { LanguageProvider } from "./langs/LanguageProvider";
import GroupsDevices from "./containers/GroupsDevices";
import AppUpdateService from "./containers/AppUpdateService";
import Tags from "./containers/Tags";
import CASLogin from './containers/CASLogin';
import LoginError from './containers/LoginError';
// use for recognize the node server and kill it, rather than kill all the node server.
process.title = "Nubis";

export const SubmitBtnContext = React.createContext({}); // context for btn loading animation
function App() {
    // eslint-disable-next-line no-unused-vars
    const [state, dispatch] = useReducer(
        styledSubmitBtnReducer,
        styledSubmitBtnState
    );
    return (
        <ThemeProvider theme={theme}>
            <LanguageProvider>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <Suspense fallback="loading">
                        <Auth>
                            {/* Auth get the value sent from the AuthContext.Provider */}
                            <SubmitBtnContext.Provider
                                value={{
                                    loading: styledSubmitBtnState.loading,
                                    success: styledSubmitBtnState.success,
                                    dispatch,
                                }}
                            >
                                <BrowserRouter forceRefresh={true}>
                                    <Switch>
                                        <PrivateRoute
                                            permissionName="multiple_vnc_setting"
                                            path="/multiple-devices-task/vnc-setting"
                                            component={VNCSetting}
                                        />
                                        <PrivateRoute
                                            permissionName="nubis_storage_file_browser_page"
                                            path="/nubis-drive/shared-with-me/:id/:name"
                                            component={SharedWithMe}
                                        />
                                        <PrivateRoute
                                            permissionName="nubis_storage_file_browser_page"
                                            path="/nubis-drive/shared-with-me"
                                            component={SharedWithMe}
                                        />
                                        <PrivateRoute
                                            permissionName="nubis_storage_file_browser_page"
                                            path="/nubis-drive/file-browser/:id/:name"
                                            component={FileBrowser}
                                        />
                                        <PrivateRoute
                                            permissionName="nubis_storage_file_browser_page"
                                            path="/nubis-drive/file-browser"
                                            component={FileBrowser}
                                        />
                                        <PrivateRoute
                                            permissionName="alarm_notification_page"
                                            path="/alarm-notification"
                                            component={AlarmNotification}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_page"
                                            path="/device-control"
                                            component={DeviceControl}
                                        />
                                        <PrivateRoute
                                            permissionName="groups_page"
                                            path="/groups/groups-devices"
                                            component={GroupsDevices}
                                        />
                                        <PrivateRoute
                                            permissionName="tag_page"
                                            path="/groups/tags"
                                            component={Tags}
                                        />
                                        <PrivateRoute
                                            permissionName="power_management_page"
                                            path="/power-management"
                                            component={PowerManagement}
                                        />
                                        <PrivateRoute
                                            permissionName="app_update_service_page"
                                            path="/app-update-service"
                                            component={AppUpdateService}
                                        />
                                        <PrivateRoute
                                            permissionName="No Permission"
                                            path="/no-permission"
                                            component={NoPermission}
                                        />
                                        <PrivateRoute
                                            permissionName="nubis_storage_admin_utility_page"
                                            path="/nubis-drive/admin-utility"
                                            component={AdminUtility}
                                        />
                                        <PrivateRoute
                                            permissionName="device_detail_page"
                                            path="/device-list/device-detail/:device_id/:device_os_type"
                                            component={DeviceDetail}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_deploy_file"
                                            path="/multiple-devices-task/deploy-file"
                                            component={DeployFile}
                                        />
                                        <PrivateRoute
                                            permissionName="single_deploy_file"
                                            path="/deploy-file/:device_id/:device_os_type"
                                            component={DeployFile}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_restart_system"
                                            path="/multiple-devices-task/restart-system"
                                            component={RestartSystem}
                                        />
                                        <PrivateRoute
                                            permissionName="single_restart_system"
                                            path="/restart-system/:device_id/:device_os_type"
                                            component={RestartSystem}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_bios_update"
                                            path="/multiple-devices-task/bios-update"
                                            component={BIOSUpdate}
                                        />
                                        <PrivateRoute
                                            permissionName="single_bios_update"
                                            path="/bios-update/:device_id/:device_os_type"
                                            component={BIOSUpdate}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_peripherals_fw_update"
                                            path="/multiple-devices-task/fw-update"
                                            component={FWUpdate}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_kiosk_mode"
                                            path="/multiple-devices-task/kiosk-mode"
                                            component={KioskMode}
                                        />
                                        <PrivateRoute
                                            permissionName="single_kiosk_mode"
                                            path="/kiosk-mode/:device_id/:device_os_type"
                                            component={KioskMode}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_update_ora"
                                            path="/multiple-devices-task/ora-update"
                                            component={OraUpdate}
                                        />
                                        <PrivateRoute
                                            permissionName="single_update_ora"
                                            path="/ora-update/:device_id/:device_os_type"
                                            component={OraUpdate}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_screenshot"
                                            path="/multiple-devices-task/screenshot"
                                            component={Screenshot}
                                        />
                                        <PrivateRoute
                                            permissionName="single_screenshot"
                                            path="/screenshot/:device_id/:device_os_type"
                                            component={Screenshot}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_io_set_up"
                                            path="/multiple-devices-task/io-setup"
                                            component={IOSetUp}
                                        />
                                        <PrivateRoute
                                            permissionName="single_io_set_up"
                                            path="/io-setup/:device_id/:device_os_type"
                                            component={IOSetUp}
                                        />
                                        <PrivateRoute
                                            permissionName="multiple_rotation"
                                            path="/multiple-devices-task/rotation"
                                            component={Rotation}
                                        />
                                        <PrivateRoute
                                            permissionName="single_rotation"
                                            path="/rotation/:device_id/:device_os_type"
                                            component={Rotation}
                                        />
                                        
                                        <PrivateRoute
                                            permissionName="device_list_page"
                                            path="/device-list"
                                            component={DeviceList}
                                        />
                                        <PrivateRoute
                                            permissionName="dashboard_page"
                                            path="/dashboard"
                                            component={Dashboard}
                                        />
                                        {/* <PrivateRoute path='/device-list' component={DeviceList}/>  */}
                                        <Route path="/login" component={CASLogin}/>
                                        <Route path='/login-error' component={LoginError} />
                                        <Route path='/no-permission' component={NoPermission} />
                                        <PrivateRoute
                                            permissionName="Home"
                                            path="/"
                                            component={Dashboard}
                                            exact
                                        />
                                    </Switch>
                                </BrowserRouter>
                            </SubmitBtnContext.Provider>
                        </Auth>
                    </Suspense>
                </MuiPickersUtilsProvider>
            </LanguageProvider>
        </ThemeProvider>
    );
}

export default App;
