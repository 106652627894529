import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import "../css/dataTable.css";
import * as localforage from "localforage";
import * as $ from "jquery";
import "datatables.net";
import StatusChip from "../components/commons/StatusChip";
import { searchStyleRender } from "./datatableSearchStyleRender";
// import HasPermission from "../components/commons/HasPermission";
import { language } from "./datatableLanguage";
import { utcStringToLocalDatetimeConverter } from "./datetimeHandlers";
import TaskStatusBar from "../components/commons/TaskStatusBar";

export const table = async (t, device_id, handleResendDialogOpen) => {
    const token = await localforage.getItem("token");
    let searchValue = $(".dataTables_filter input").val();
    $.fn.dataTable.ext.errMode = "throw";
    let onlineOffline = '';

    $("#device-list-table").DataTable({
        processing: true,
        language: language(t),
        searching: true,
        order: [[2, "desc"]],
        filter: false,
        deferRender: true,
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        pageLength: 5,
        ordering: true,
        ajax: {
            url: `${url}/api/v2/device/tasks`,
            type: "GET",
            dataSrc: function(d){
                onlineOffline = d.online_offline_status;
                return d.data
            },
            data: {
                device_id: device_id,
                search: searchValue,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                console.log(error);
            },
        },
        columns: [
            {
                data: "execute_type",
                title: `${t("category.taskName")}`,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <p className="task_name">
                            {data === "device_power" &&
                                rowData.json &&
                                rowData.json.system_booting_type ? (
                                <>
                                    {t(`tasks.${data}`)} - [{" "}
                                    {t(`tasks.${rowData.json.system_booting_type}`)} ]
                                </>
                            ) : (data === "lock_usb" ||
                                data === "disable_combination_key" ||
                                data === "disable_windows_notify") &&
                                rowData.json ? (
                                t(`tasks.${data}_${rowData.json.status}`)
                            ) : (
                                t(`tasks.${data}`)
                            )}
                        </p>,
                        td
                    );
                },
            },
            {
                data: "user",
                name: "user.name",
                title: `${t("category.user")}`,
                render: (data) => {
                    return data.name;
                },
            },
            {
                data: "executed_at",
                className: "date",
                title: `${t("category.date")}`,
                searchable: true,
                render: (data) => {
                    return utcStringToLocalDatetimeConverter(data);
                },
            },
            {
                data: "status",
                title: `${t("category.status")}`,
                className: "status",
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <TaskStatusBar
                            status={data}
                            message={(rowData.response === '') ? null : rowData.response}
                            isOffline={onlineOffline !== 'online'}
                        />
                        /* <StatusChip
                            statusLabel={data}
                            id={rowData.id}
                            ariaDescribeBy={rowData.id}
                            popoverText={data === "failed" ? rowData.response : undefined}
                        />*/,
                        td
                    );
                },
            },
            // {
            //   data: "status",
            //   title: `${t("category.action")}`,
            //   searchable: false,
            //   className: "resend-btn-td",
            //   orderDataType: "dom-text",
            //   createdCell: (td, data, rowData) => {
            //     const task_id = rowData.id;
            //     const task_name = rowData.execute_type;
            //     if (data === "failed") {
            //       ReactDOM.render(
            //         <HasPermission permission="single_task_resend">
            //           <span
            //             onClick={() => handleResendDialogOpen(task_id, task_name)}
            //           >
            //             {t("btn.resend")}
            //           </span>
            //         </HasPermission>,
            //         td
            //       );
            //     } else {
            //       ReactDOM.render("-", td);
            //     }
            //   },
            // },
        ],
    });
    searchStyleRender();
};
