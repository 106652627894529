import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const sendVncPasswordAPI = async (deviceId) => {

    const token = await localforage.getItem('token');

    try {

        await axios({
            url: `${url}/api/v2/vnc/security/send-new-password`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                device_id: deviceId
            }
        })

    } catch (error) {
        errorHandling(error, false);
    }

}
