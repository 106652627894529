import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import { useTranslation } from "../langs/useTranslation";
import { useParams } from "react-router-dom";
import useStyles from '../styles/fileBrowserStyle';
import * as localforage from 'localforage';
import YourCapacity from '../components/fileBrowser/YourCapacity';
import UploadedFileTable from '../components/fileBrowser/UploadedFileTable';
import { NestedBreadCrumbs } from '../components/device/BreadCrumbs';
import AddOrEditFileDialog from '../components/fileBrowser/AddOrEditFileDialog';
import ShareFileDialog from '../components/fileBrowser/ShareFileDialog';
import RemoveFileDialog from '../components/fileBrowser/RemoveFileDialog';
import CreateFolderPopover from '../components/fileBrowser/CreateFolderPopover';
import { table } from '../helpers/uploadedFileTable';
import { nubisDriveCapacityAPI } from '../apis/nubisDriveCapacityAPI';
import { transformedFormatBytes } from '../helpers/formatBytesTransformer';
import { TableTitleWithFolderActions } from '../components/commons/Titles';
import { removeClickedLinkFromBreadcrumbs, updateBreadcrumbsWhenBackButtonClicked, storeLinkArray } from '../helpers/handleNubisDrivePageUpdate';

const FileBrowser = () => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('nubisDrive');
    let { id } = useParams();
    const [uploadFileDialigOpen, setUploadFileDialogOpen] = useState(false);
    const [shareFileDialogOpen, setShareFileDialogOpen] = useState(false);
    const [removeDialigOpen, setRemoveDialogOpen] = useState(false);
    const [createFolderPopoverOpen, setCreateFolderPopoverOpen] = useState(null);
    const [data, setData] = useState();
    const [refreshCapacity, setRefreshCapacity] = useState(false);
    const [barValue, setBarValue] = useState(0);
    const [usageValue, setUsageValue] = useState('-');
    const [storageValue, setStorageValue] = useState('-');
    const [isFECHQ, setIsFECHQ] = useState(false);
    const [capacity, setCapacity] = useState({});
    const [companyId, setCompanyId] = useState();
    const [availableStorage, setAvailableStorage] = useState('');
    const [parentId, setParentId] = useState(''); // used when 1. generating url after clicking breadcrumbs; 2. reload table; 3. creating/removing file/folder
    const [linkArray, setLinkArray] = useState([{ name: `${t('fileBrowser.pageTitle')}`, parent_id: '' }]);
    const [toPreviousPage, setToPreviousPage] = useState(false);
    const rootFolderLinkName = 'file-browser';
    const breadcrumbsLocalforageKeyname = 'fileBrowserBreadcrumbs';

    const handleReloadDataTable = id => {
        table(t, handleActionDialogOpen, id, setParentId, linkArray, setLinkArray, breadcrumbsLocalforageKeyname);
    }

    const handleActionDialogOpen = (type, data) => {
        if (type === 'create') {
            setData();
            setUploadFileDialogOpen(true);
        }
        if (type === 'share') {
            setData(data);
            setShareFileDialogOpen(true);
        }
        if (type === 'remove') {
            setData(data);
            setRemoveDialogOpen(true);
        }
    }

    const handleActionDialogClose = type => {
        if (type === 'create') {
            setUploadFileDialogOpen(false);
        }
        if (type === 'share') {
            setData();
            setShareFileDialogOpen(false);
        }
        if (type === 'remove') {
            setData();
            setRemoveDialogOpen(false);
        }
    }

    const handleCreateFolderPopoverOpen = e => setCreateFolderPopoverOpen(e.currentTarget);

    const handleCreateFolderPopoverClose = () => setCreateFolderPopoverOpen(false);

    const getCapacityData = async () => {
        setRefreshCapacity(false);

        let capacityObj = await nubisDriveCapacityAPI();
        let userData = await localforage.getItem('user');
        let companyId = await localforage.getItem('companyId');
        setCompanyId(companyId);
        setCapacity(capacityObj);
        if (capacityObj && userData) {
            let used = transformedFormatBytes(capacityObj.used);
            let total = transformedFormatBytes(capacityObj.total);
            let isParentCompanyNull = userData.company.parent_company_id === null;
            let availableStorage = capacityObj.total - capacityObj.used;

            setAvailableStorage(availableStorage)
            setUsageValue(used);

            if (isParentCompanyNull) {
                setIsFECHQ(true);
                setBarValue(Number(capacityObj.used));
                setStorageValue('unlimited');
            } else {
                let percentage = capacityObj.used / capacityObj.total * 100;
                setBarValue(percentage);
                setStorageValue(total);
            }

            // stored the available storage for file uploading validation
            await localforage.setItem(`${companyId}-availableStorage`, availableStorage);
        }
    }

    const getAvailableStorageData = async () => {
        if (!isFECHQ) {
            let availableStorage = await localforage.getItem(`${companyId}-availableStorage`);
            setAvailableStorage(availableStorage);
        } else {
            setAvailableStorage('unlimited');
        }
    }

    const handleNestedBreadcrumbsClick = (e, i) => {
        removeClickedLinkFromBreadcrumbs(e, i, parentId, setParentId, setLinkArray, rootFolderLinkName, breadcrumbsLocalforageKeyname);
    }

    const getStoredLinkArray = async () => {
        // for window reload, check if the link array with right breadcrumbs path is stored
        let storedLinkArray = await localforage.getItem(breadcrumbsLocalforageKeyname);
        if (!id) {
            setLinkArray(linkArray);
            storeLinkArray(breadcrumbsLocalforageKeyname, linkArray);
        }
        setLinkArray(storedLinkArray);
    }


    useEffect(() => {
        if (!mounted.current) {
            getCapacityData();
            getAvailableStorageData();
            getStoredLinkArray();
            mounted.current = true;
        }
    })

    useEffect(() => {
        if (refreshCapacity) getCapacityData();
    }, [refreshCapacity])

    useEffect(() => {
        handleReloadDataTable(parentId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId])

    useEffect(() => {
        if (toPreviousPage && linkArray.length >= 1) {
            let idForTable = linkArray.length > 2 ? linkArray[linkArray.length - 2].parent_id : '';
            handleReloadDataTable(idForTable);
        }
        setToPreviousPage(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toPreviousPage])

    // when refreshing the window, set the id parameter from url to parentId
    window.onload = function () {
        handleReloadDataTable(id ? id : '');
    };

    // when clicking the back button on browser
    window.onpopstate = () => {
        setToPreviousPage(true);
        updateBreadcrumbsWhenBackButtonClicked(setLinkArray, parentId, id, setParentId, rootFolderLinkName, breadcrumbsLocalforageKeyname);
    }

    return (
        <div className={classes.root}>
            <NestedBreadCrumbs
                onClick={(e, i) => handleNestedBreadcrumbsClick(e, i)}
                linkArray={linkArray}
                pageName='fileBrowser' />
            <Typography variant="h4" className={classes.pageTitle}>{t('fileBrowser.pageTitle')}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <YourCapacity
                        capacity={capacity}
                        barValue={barValue}
                        usageValue={usageValue}
                        storageValue={storageValue}
                        getCapacityData={getCapacityData}
                        refreshCapacity={refreshCapacity} />
                </Grid>
                <Grid item xs={12}>
                    <Paper className={`${classes.tableMoveTopReset} ${classes.tableMoveBottomReset}`}>
                        <TableTitleWithFolderActions
                            title='uploadedFiles'
                            capacity={capacity}
                            tooltipText={t('table.overSizeContact')}
                            storageValue={storageValue}
                            barValue={barValue}
                            handleReloadDataTable={() => handleReloadDataTable(parentId)}
                            handleCreateDialogOpen={() => handleActionDialogOpen('create')}
                            handleCreateFolderPopoverOpen={handleCreateFolderPopoverOpen}
                            createFolderPopoverOpen={createFolderPopoverOpen} />
                        <UploadedFileTable />
                    </Paper>
                </Grid>
            </Grid>
            <RemoveFileDialog
                open={removeDialigOpen}
                data={data}
                reloadTable={() => handleReloadDataTable(parentId)}
                setRefreshCapacity={setRefreshCapacity}
                onClose={() => handleActionDialogClose('remove')} />
            <AddOrEditFileDialog
                companyId={companyId}
                availableStorage={availableStorage}
                getAvailableStorageData={getAvailableStorageData}
                isFECHQ={isFECHQ}
                open={uploadFileDialigOpen}
                data={data}
                setRefreshCapacity={setRefreshCapacity}
                parentId={parentId}
                reloadTable={() => handleReloadDataTable(parentId)}
                onClose={() => handleActionDialogClose('create')} />
            <ShareFileDialog
                open={shareFileDialogOpen}
                data={data}
                reloadTable={() => handleReloadDataTable(parentId)}
                onClose={() => handleActionDialogClose('share')} />
            <CreateFolderPopover
                anchorEl={createFolderPopoverOpen}
                parentId={parentId}
                reloadTable={() => handleReloadDataTable(parentId)}
                open={Boolean(createFolderPopoverOpen)}
                onClose={handleCreateFolderPopoverClose} />
        </div>
    )
}

export default FileBrowser;
