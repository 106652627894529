import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { currentDateInUTC } from "../helpers/datetimeHandlers";

export const getEventLogApi = async (device_ids) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/get-event-log`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                execute_datetime: currentDateInUTC(),
                device_ids: device_ids,
                company_id,
                execute_type: "event_log",
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getEventLogsettingApi = async (device_id) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/event-log-setting`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id,
                company_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const setEventLogStatusApi = async (device_ids, is_disable) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/event-log-set`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                company_id,
                execute_type: "set_event_log_time",
                is_disable,
                execute_datetime: currentDateInUTC(),
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getDatatableApi = async (device_id, type) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/event-log-datatable`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id,
                company_id,
                event_log_type: type,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getEventLogFileApi = async (device_ids) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/get-event-log-file`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids: [device_ids],
                company_id,
                execute_type: "event_log_file",
                execute_datetime: currentDateInUTC(),
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const downloadEventLogFileApi = async (device_id) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/download-event-log-file`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
