import React, { useState, useContext, useEffect, useRef } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { SubmitBtnContext } from '../../App';
import SelectionWithSelectAllCheckbox from '../commons/SelectionWithSelectAllCheckbox';
import ConfirmAndCancelBtn from '../commons/ConfirmAndCancelBtn';
import { createUtilityAPI } from '../../apis/createUtilityAPI';
import { updateUtilityAPI } from '../../apis/updateUtilityAPI';
import { getDeviceModelsAPI } from '../../apis/getDeviceModelsAPI';
import { getCompaniesAPI } from '../../apis/getCompaniesAPI';
import { getAllFirmwaresAPI } from '../../apis/getAllFirmwaresAPI';
import NoteTextfield from '../commons/NoteTextfield';
import { RequiredText } from '../commons/ValidationText';
import { MultipleSelectWithChipTitle } from '../commons/Titles';

// Manage localforage names based on the task name
const taskName = "utilityManagement";
const storedUtilitiesToLocalforageName = `${taskName}Utilities`;
const storedCompaniesToLocalforageName = `${taskName}Companies`;
const storedModelsToLocalforageName = `${taskName}ModelNames`;

const AddOrEditUtilityManagementForm = (props) => {
    const mounted = useRef()
    const classes = useStyles()
    const { t } = useTranslation('adminUtility')
    const { onClose, modelIdArr, companyIdArr, utilityIdArr, fwId, createUtility } = props
    const { dispatch } = useContext(SubmitBtnContext)
    const [note, setNote] = useState('')
    const [utilities, setUtilities] = useState()
    const [companies, setCompanies] = useState()
    const [models, setModels] = useState()
    const [tableDisabled, setTableDisabled] = useState(false)
    const [validated, setValidated] = useState(false)

    const handleNoteChange = (event) => {
        setNote(event.target.value)
    };

    const handleUpdateOrCreateUtiltiy = async () => {
        dispatch({ type: 'showLoading' })
        const utilityData = await localforage.getItem(storedUtilitiesToLocalforageName)
        const modelData = await localforage.getItem(storedModelsToLocalforageName)
        const companyData = await localforage.getItem(storedCompaniesToLocalforageName)
        const modelIds = modelData ? modelData.map(o => o.id) : undefined
        const utilityIds = utilityData ? utilityData.map(o => o.id) : undefined
        const companyIds = companyData ? companyData.map(o => o.id) : undefined
        setUtilities(utilityIds)
        setModels(modelIds)
        setCompanies(companyIds)

        if (!!utilityData && !!utilityData.length && !!modelData && !!companyData) {
            setTableDisabled(true)
            // //update FW utility
            if (!createUtility) {
                const successfulText = `${t('dialog.successfullyUpdateUtilityManagement')}`
                const updateResult = await updateUtilityAPI(fwId, utilityIds, companyIds, modelIds, note, t, successfulText)
                if (updateResult) onClose()
            }
            // //create FW utility
            if (createUtility) {
                const successfulText = `${t('dialog.successfullyCreateUtilityManagement')}`
                const createResult = await createUtilityAPI(utilityIds, companyIds, modelIds, note, t, successfulText)
                if (createResult) onClose()
            }
        }
        setTableDisabled(false)
        setValidated(true)
        dispatch({ type: 'stopLoading' });
    }

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedUtilitiesToLocalforageName);
        await localforage.removeItem(storedCompaniesToLocalforageName);
        await localforage.removeItem(storedModelsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    }

    useEffect(() => {
        if (!mounted.current) {
            resetAllStoredValueInLocalforage()
            mounted.current = true;
        }
    })

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.marginBottom16px}>
                    <MultipleSelectWithChipTitle
                        title={t('dialog.modelName')}
                    />
                    <SelectionWithSelectAllCheckbox
                        currentArr={modelIdArr}
                        tableDisabled={tableDisabled}
                        getDataFromAPI={getDeviceModelsAPI}
                        localforageKeyName={storedModelsToLocalforageName}
                    />
                    {validated && !models ? <RequiredText /> : ''}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8} className={classes.marginBottom16px}>
                    <MultipleSelectWithChipTitle
                        title={t('dialog.utilityRequired')}
                    />
                    <SelectionWithSelectAllCheckbox
                        currentArr={utilityIdArr}
                        tableDisabled={tableDisabled}
                        getDataFromAPI={getAllFirmwaresAPI}
                        localforageKeyName={storedUtilitiesToLocalforageName}
                    />
                    {validated && !utilities ? <RequiredText /> : ''}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.marginBottom16px}>
                    <SelectionWithSelectAllCheckbox
                        hasSelectAllOption={true}
                        currentArr={companyIdArr}
                        setValidated={setValidated}
                        tableDisabled={tableDisabled}
                        getDataFromAPI={getCompaniesAPI}
                        localforageKeyName={storedCompaniesToLocalforageName}
                    />
                    {validated && !companies ? <RequiredText /> : ''}
                </Grid>
                <Grid item xs={12}>
                    <NoteTextfield
                        onChange={handleNoteChange}
                        disabled={tableDisabled}
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
            <ConfirmAndCancelBtn
                styledSubmitBtnOnClick={handleUpdateOrCreateUtiltiy}
                cancelBtnDisabled={tableDisabled}
                cancelBtnOnClick={onClose} />
        </>
    )
}

export default AddOrEditUtilityManagementForm;
