import React from 'react';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { MultipleSelectWithChipTitle } from '../commons/Titles';

const SelectAllCheckbox = (props) => {
    const classes = useStyles();
    const { handleChange, checked, disabled } = props;
    const { t } = useTranslation('common');

    return(
        <Box display="flex" flexDirection="row" alignItems="center">
            <Box mr={1}>
                <MultipleSelectWithChipTitle title={t('checkbox.company')} />
            </Box>
            <FormControlLabel
                className={classes.selectAllCheckbox}
                control={(
                    <Checkbox 
                        id="select-all-company"
                        checked={checked} 
                        disabled={disabled}
                        onChange={(e)=>handleChange(e)}
                        name="select all" 
                    />
                )}
                label={t('checkbox.selectAll')}
            />
        </Box>
    )
}

export default SelectAllCheckbox;