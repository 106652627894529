import axios from 'axios';
import * as localforage from 'localforage';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const createFolderAPI = async (name, parentId, t, onClose, setIsFolderExisted, setErrorText, reloadTable) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/drive/file/create-folder`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                name: name,
                parent_id: parentId,
            }
        })
        const successfulText = `${t('dialog.successfullyCreateFolder')}`;
        successHandling(t, successfulText);
        onClose();
        reloadTable();
        return true;
    } catch (error) {
        setIsFolderExisted(true);
        setErrorText(`${t(`dialog.${error.response.data.errors.name[0]}`)}`);
        errorHandling(error, false, false);
    }

}
