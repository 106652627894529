import localforage from "localforage";
import Noty from "noty";
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/relax.css";

export const errorHandling = (
    error,
    shouldRedirect = true,
    showNotyForWrongData = true
) => {
    if (error.response) {
        let errorObject = error.response.data;
        errorObject.message = "Already logout on other page";
        if (errorObject) {
            let status = error.response.status;
            let httpCodes = [400, 401, 403, 422];
            if (status === httpCodes[3] && showNotyForWrongData) {
                if (errorObject?.message?.errors) {
                    for (let objs in errorObject.message.errors) {
                        errorObject.message.errors[objs].forEach((errMsg) => {
                            new Noty({
                                theme: "relax",
                                type: "error",
                                closeWith: ["click", "button"],
                                layout: "topRight",
                                timeout: process.env.REACT_APP_NOTY_TIMEOUT,
                                text: errMsg,
                            }).show();
                        });
                    }
                }
            } else if (status === httpCodes[1]) {
                if (shouldRedirect === true) {
                    localforage.clear();
                    window.location.replace('/login');
                }
                if (shouldRedirect === false) {
                    new Noty({
                        theme: "relax",
                        type: "error",
                        closeWith: ["click", "button"],
                        layout: "topRight",
                        timeout: process.env.REACT_APP_NOTY_TIMEOUT,
                        text: errorObject.message,
                        callbacks: {
                            afterClose: function () {
                                window.location.replace(
                                    process.env.REACT_APP_UNAUTH_REDIRECT_URL
                                );
                            },
                        },
                    }).show();
                }
            }
        } else {
            return error;
        }
    }
};
