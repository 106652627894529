import React from 'react';
import { Typography, Breadcrumbs, Box } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from "../../langs/useTranslation";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

export const BreadCrumbs = props => {
    const { deviceId, deviceOsType, pageName, showDeviceList, noTranslate } = props;
    const { t } = useTranslation('common');

    return(
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard">Nubis</Link>
            { showDeviceList && 
                <Link color="inherit" to="/device-list">{t('breadcrumb.deviceMonitoring')}</Link>
            }
            { deviceId && deviceOsType && 
                <Link color="inherit" to={`/device-list/device-detail/${deviceId}/${deviceOsType}`}>
                    {t('breadcrumb.deviceDetail')}
                </Link>
            }
            <Typography color="textPrimary">{ noTranslate ? pageName || '-' : t(`breadcrumb.${pageName}`) }</Typography>
        </Breadcrumbs>
    )
} 

export const NestedBreadCrumbs = props => {
    const { linkArray, onClick } = props;

    const handleLinkClick = (e, i) => {
        onClick(e, i);
    }

    return(
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb">
            <Link color="inherit" to="/dashboard">Nubis</Link>
                {linkArray && linkArray.map( (o, i) => {
                    let ifLastItemAndNotMainPage = (i === linkArray.length - 1) && o.parent_id;
                    return(
                        <Box key={i} 
                            id={o.parent_id} 
                            color="inherit" 
                            className={!ifLastItemAndNotMainPage ? 'pointer' : ''} 
                            onClick={!ifLastItemAndNotMainPage ? (e)=>handleLinkClick(e, i) : null}>
                            {o.name}
                        </Box>
                    )
                }
            )}
        </Breadcrumbs>
    )
}