export const statusText = (status) => {
    switch (status) {
        case "failed":
            return <div style={{ color: "#ff9e40" }}>Failed</div>;
        case "completed":
            return <div style={{ color: "#1DBBD4" }}>Completed</div>;
        case "queued":
            return <div style={{ color: "#9e9e9e" }}>Queued</div>;
        case "scheduled":
            return <div style={{ color: "#9e9e9e" }}>Scheduled</div>;
        case "relayed":
            return <div style={{ color: "#9e9e9e" }}>Relayed</div>;
        default:
            return "-";
    }
};
export const disabledText = (status, row) => {
    switch (status) {
        case "failed":
            return (
                <div style={{ color: "#ff9e40", display: "flex" }}>
                    <div style={{ marginRight: "5px" }}>{row === false ? 'Set Disabled' : 'Set Enabled'}</div>- Failed
                </div>
            );
        case "completed":
            return (
                <div style={{ color: "#1DBBD4", display: "flex" }}>
                    <div style={{ marginRight: "5px" }}>{row === false ? 'Set Disabled' : 'Set Enabled'}</div>- Completed
                </div>
            );
        case "queued":
            return (
                <div style={{ color: "#9e9e9e", display: "flex" }}>
                    <div style={{ marginRight: "5px" }}>{row === false ? 'Set Disabled' : 'Set Enabled'}</div>- Queued
                </div>
            );
        case "scheduled":
            return (
                <div style={{ color: "#9e9e9e", display: "flex" }}>
                    <div style={{ marginRight: "5px" }}>{row === false ? 'Set Disabled' : 'Set Enabled'}</div>- Scheduled
                </div>
            );
        case "relayed":
            return (
                <div style={{ color: "#9e9e9e", display: "flex" }}>
                    <div style={{ marginRight: "5px" }}>{row === false ? 'Set Disabled' : 'Set Enabled'}</div>- Relayed
                </div>
            );
        default:
            return "-";
    }
};
