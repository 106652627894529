import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const getMonitorListApi = async (device_id) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/monitors`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const detectMonitorApi = async (device_ids) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/detect-monitor-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                execute_type: "detect_device_monitors",
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
