import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    dropdownLazy: {
        position: "absolute !important",
        width: "200px !important",
        [theme.breakpoints.down("sm")]: {
            width: "360px !important",
        },
    },
    taskWarning: {
        color: '#ff9e40',
        margin: '10px 0'
    },
    taskBtns: {
        display: 'flex',
        "& .taskBtnAdd": {
            background: "#fff",
            border: '1px solid rgba(0, 0, 0, 0.22)',
            borderRight: '0',
            borderBottom: '0',
            padding: '5px 20px',
            borderRadius: '5px 0 0 0',
            cursor: 'pointer',
            "&.alone": {
                borderRadius: '5px 5px 0 0',
            },
            "&:hover": {
                background: "#f0f0f0"
            },
            "&.active": {
                color: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.01)',
                background: "#1DBBD4",
                "&:hover": {
                    background: "#0DaBc4"
                },
            }
        },
        "& .taskBtnRemove": {
            background: "#fff",
            border: '1px solid rgba(0, 0, 0, 0.22)',
            borderBottom: '0',
            padding: '5px 20px',
            borderRadius: '0 5px 0 0',
            cursor: 'pointer',
            "&.alone": {
                borderRadius: '5px 5px 0 0',
            },
            "&:hover": {
                background: "#f0f0f0"
            },
            "&.active": {
                color: '#fff',
                border: '1px solid rgba(0, 0, 0, 0.01)',
                background: "#1DBBD4",
                "&:hover": {
                    background: "#0DaBc4"
                },
            }
        }
    },
    taskSchedule: {
        border: '1px solid rgba(0, 0, 0, 0.22)',
        borderRadius: '0 5px 5px 5px',
        padding: '15px 20px',
        display: 'flex',
        flexDirection: "column",
        "& .taskOuter": {
            margin: '0 0 10px',
            "& .taskMain": {
                display: 'flex',
                flexDirection: 'column',
                "& .taskTypeOuter": {
                    display: 'flex',
                    alignItems: 'center',
                    "& .taskType": {
                        display: 'flex',
                        fontSize: '16px'
                    }
                },
                "& .taskContent": {
                    display: 'flex',
                    flexDirection: 'column',
                    "& .weekOuter": {
                        "& .weeks": {
                            display: 'flex',
                            margin: '2px 0',
                            "& .week": {
                                background: "#c0c0c0",
                                padding: '0 6px',
                                borderRadius: "5px",
                                color: '#fff',
                                marginRight: '8px'
                            }
                        }
                    }
                },
            },
            "& .datetime": {
                display: 'flex'
            }
        }
    },
    dot: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
    },
    importResult: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .resultIcon": {
            marginBottom: "10px",
            "& svg": {
                fontSize: "32px",
            },
        },
        "& .resultTitle": {
            fontSize: "20px",
            fontWeight: "600",
        },
        "& .resultItems": {
            width: "80%",
            margin: "30px 0 10px",
            padding: "20px 40px",
            border: "1px solid rgba(0, 0, 0, 0.22)",
            borderRadius: "10px",
            "& .MuiDivider-root": {
                margin: "15px 0 !important",
            },
            "& .resultItem": {
                display: "flex",
                padding: "7.5px 0",
                alignItems: "center",
                justifyContent: "space-between",
            },
        },
    },
    treeOuter: {
        cursor: "grab",
        maxHeight: "280px",
        minHeight: "280px",
        width: "100%",
        overflowY: "hidden",
        overflowX: "hidden",
        "&::-webkit-scrollbar": {
            width: "5px !important",
        },
    },
    dropdownLazyOuter: {
        height: "110px",
    },
    hasPaper: {
        overflow: "hidden"
    },
    dialog: {
        "& .MuiDialogTitle-root": {
            padding: "16px 24px 0 !important",
        },
        "& .MuiDialog-paperWidthLg": {
            width: "65%",
        },
        "& .MuiDialogContent-root": {
            padding: "24px !important",
        },
        "& .MuiDialog-paperWidthSm": {
            "& .MuiDialogActions-spacing > :not(:first-child)": {
                marginLeft: "0 !important",
            },
        },
        "& .MuiDialog-paperWidthXs": {
            "& .MuiDialogActions-root": {
                margin: "0 !important",
            },
        },
        "& .MuiDialogActions-root": {
            display: "flex !important",
            justifyContent: "center !important",
            margin: "30px 0 !important",
            "&>div": {
                marginBottom: "0 !important",
            },
            "& .MuiButton-root": {
                marginBottom: "0 !important",
                padding: "6px 8px",
                width: "150px !important",
            },
        },
        "& .MuiDivider-root": {
            margin: "24px 0 !important",
            "&.margin-less": {
                margin: "12px 0 8px !important",
            },
        },
        "& #device-eligibility-table_wrapper": {
            height: "500px !important",
            "& table.dataTable tbody tr td": {
                paddingTop: "0 !important",
                paddingBottom: "0 !important",
            },
        },
        "& .MuiTypography-subtitle1": {
            fontWeight: "bold !important",
        },
        "& .MuiTypography-colorPrimary": {
            color: "#1DBBD4 !important",
        },
        "& .uploadFileOnlyDialog": {
            "& label.MuiButton-outlined": {
                width: "100% !important",
            },
            "& .MuiDialogActions-root": {
                marginBottom: "0 !important",
            },
        },
        "& .nameArea": {
            padding: "0px 0 10px",
            display: "flex",
            alignItems: "center",
            fontSize: "16px",
            fontWeight: "600",
            "& .ostype": {
                marginLeft: "10px",
                "& img": {
                    width: "16px",
                },
            },
        },
    },
    uploadFileWrap: {
        "& .MuiButton-outlined": {
            width: "200px !important",
            padding: "5px 15px !important",
        },
    },
    multipleSelectTitle: {
        marginBottom: "16px !important",
    },
    borderBottomContainer: {
        marginBottom: "10px !important",
    },
    ruleSelectGroup: {
        "& .MuiSelect-outlined": {
            width: "100% !important",
            border: "1px solid #ddd !important",
        },
        "& .MuiTypography-colorError": {
            paddingTop: "10px !important",
            display: "block !important",
        },
    },
    btnInTextField: {
        width: "200px !important",
        marginBottom: "0px !important",
        padding: "6px 8px !important",
        "&:hover": {
            background: "none !important",
        },
    },
    marginBottomReset: {
        marginBottom: "0 !important",
    },
    emailListPaper: {
        maxHeight: "100px !important",
        overflowY: "scroll !important",
        "& .MuiTypography-displayBlock": {
            marginBottom: "6px !important",
        },
        "& .MuiTypography-body2": {
            lineHeight: "1.5em !important",
        },
    },
    smallDialogBtnWrap: {
        margin: "0 !important",
    },
    marginBottom8px: {
        "& .MuiTypography-colorError": {
            marginTop: "8px !important",
        },
    },
    marginBottom16px: {
        "& .MuiTypography-colorError": {
            marginTop: "16px !important",
        },
    },
    modalDeviceTableGrid: {
        "& #device-eligibility-table_wrapper": {
            height: "initial !important",
        },
    },
    textFieldOutlined: {
        "& .MuiOutlinedInput-root": {
            border: "1px solid #ddd !important",
        },
        "& input": {
            padding: "11px 18px !important",
        },
    },
    emailSelection: {
        "& .MuiTypography-displayBlock": {
            marginTop: "10px !important",
        },
    },
    ruleValueUnit: {
        position: "absolute !important",
        top: "9px !important",
        right: "20px !important",
        color: "#666 !important",
    },
    emailContainer: {
        "& .MuiTypography-colorError": {
            paddingTop: "10px !important",
            display: "block !important",
        },
    },
    localFile: {
        "& .MuiPaper-root": {
            padding: "0",
        },
        "& .tableHeaderDialog": {
            padding: "30px 25px 0",
        },
        "& .labelWithInput": {
            alignItems: "center",
            display: "flex",
            marginBottom: "20px",
            "& .errorMsg": {
                color: "#ff9e40",
                position: "absolute",
                right: "0",
                bottom: "-20px",
            },
            "& .label": {
                whiteSpace: "nowrap",
                marginRight: "20px",
                minWidth: "150px",
                "& .MuiButtonBase-root": {
                    margin: "0 30px 0 0",
                },
            },
        },
        "& .switchWithText": {
            display: "flex",
            fontWeight: "600",
            alignItems: "center",
            "& div": {
                marginRight: "10px",
            },
        },
        "& .sentence": {
            margin: "10px 0",
        },
        "& .MuiDialogActions-root": {
            marginTop: "0 !important",
        },
        "& .bottomSwitch": {
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            color: "#c0c0c0",
            fontWeight: "600",
            "& .switchWithText": {
                marginTop: "0",
                padding: "15px 30px",
            },
        },
        "& .downloadLinkSet": {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            "& a": {
                fontSize: "16px",
                color: "#1DBBD4 !important",
                textDecoration: "underline",
                marginRight: "12.5px",
            },
        },
    },
    titleWithEditIcon: {
        display: "flex",
        fontWeight: "bold",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "6px 16px 0px !important",
        "& p": {
            display: "flex",
            "& svg": {
                marginLeft: 8,
            },
        },
    },
    searchInput: {
        position: "relative",
        "& .MuiFormControl-root": {
            width: "100% !important",
        },
        "& .MuiFormLabel-root": {
            left: 4,
        },
        "& input": {
            padding: "15px 20px 20px",
        },
        "& .searchIcon": {
            position: "absolute",
            top: 15,
            right: 0,
        },
    },
    deviceControlDialogOuter: {
        "& .deviceControlDialogBlock": {
            marginBottom: "25px",
        },
        "& .title": {
            fontSize: "15px",
            marginBottom: "5px",
        },
        "& .MuiAutocomplete-root": {
            "& .Mui-disabled ": {
                cursor: "not-allowed",
            },
            "& .MuiInputBase-root": {
                padding: 0,
                "& input": {
                    borderBottom: "1px solid rgba(0,0,0,0.42)",
                },
            },
        },
    },
    orientationSelect: {
        "& .MuiFormControlLabel-root": {
            alignItems: "baseline",
        },
    },
    labelText: {
        marginTop: 0,
        position: "relative",
        top: -6,
    },
    oneDropDown: {
        marginTop: "10px !important",
        "& p": {
            marginBottom: "8px !important",
        },
    },
    radioSelectGroup: {
        "& .MuiFormControl-root": {
            width: "100%",
        },
        "& h6": {
            marginBottom: theme.spacing(2),
        },
    },
    usbDetectTextAndBtn: {
        color: "#9e9e9e",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        padding: "20px 0 10px",
        fontWeight: "500",
        "& .MuiButton-contained": {
            marginBottom: "0 !important",
            marginLeft: "8px !important",
            height: "30px !important",
            marginRight: "10px !important",
        },
        "& .detect-left": {
            display: "flex",
            alignItems: "center",
            zIndex: "99999999999",
        },
        "& .detect-right": {
            display: "flex",
            alignItems: "center",
        },
    },
    eventLog: {
        "& .MuiPaper-root": {
            padding: "0",
        },
        "& .MuiDialogContent-root": {
            padding: "0 !important",
        },
        "& .disabledBlock": {
            padding: "30px 25px",
            "& .switchWithText": {
                display: "flex",
                alignItems: "center",
                marginTop: "0",
                padding: "20px 0px 15px",
                fontSize: "14px",
                "& h6": {
                    marginRight: "10px",
                },
            },
        },
        "& .MuiAppBar-root": {
            boxShadow: "none",
            position: "relative",
            zIndex: "10",
            borderBottom: "1px solid #e0e0e0",
            "& .MuiTab-root": {
                background: "#fff",
                padding: "0",
            },
        },
        "& .MuiTabs-indicator": {
            backgroundColor: "#1DBBD4 !important",
        },
        "& .tableHeaderDialog": {
            padding: "30px 25px 0",
        },
        "& .getFileArea": {
            display: "flex",
            alignItems: "center",
            "& button": {
                margin: "0",
            },
            "& .fileBtnArea": {
                display: "flex",
                flexDirection: "column",
                marginLeft: "5px",
            },
            "& .generateArea": {
                display: "flex",
                alignItems: "center",
            },
            "& .downloadArea": {
                display: "flex",
                alignItems: "center",
                marginTop: "10px",
            },
        },
        "& .bottomSwitch": {
            borderTop: "1px solid #e0e0e0",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            color: "#c0c0c0",
            fontWeight: "600",
            marginRight: "15px",
            "& .switchWithText": {
                display: "flex",
                alignItems: "center",
                marginTop: "0",
                padding: "15px 30px",
                "& div": {
                    marginRight: "10px",
                },
            },
        },
    },
    selectAllCheckbox: {
        height: "22px !important",
    },
    radioWithInput: {
        marginLeft: "35px",
        display: "flex",
        flexDirection: "column",
        "& .MuiFormControlLabel-label": {
            width: "80px",
        },
        "@media screen and (max-width: 1024px)": {
            marginLeft: "0",
            width: "100%",
        },
    },
    inputArea: {
        margin: "30px 0 0",
        display: "flex",
        "@media screen and (max-width: 1024px)": {
            flexDirection: "column",
        },
        "& .weeklyEvery": {
            display: "flex",
            alignItems: "center",
        },
        "&:first-child": {
            marginTop: "10px",
        },
        "& .dateGroup": {
            paddingLeft: "25px",
            "& .ml-2.MuiFormControl-root": {
                marginLeft: "10px !important",
            },
        },
        "& .fixWidth": {
            minWidth: "120px",
            whiteSpace: "nowrap",
        },
        "& .loopTypeSelect": {
            "& .MuiGrid-item": {
                paddingLeft: "35px",
            },
            "@media screen and (max-width: 1024px)": {
                "& .MuiGrid-item": {
                    paddingLeft: "0",
                },
            },
        },
        "& .loopType": {
            borderLeft: "1px solid #aaa",
            width: "100%",
            "& .MuiGrid-item": {
                paddingLeft: "30px",
            },
        },
    },
    warnText: {
        marginTop: "5px",
    },
    radioArea: {
        display: "flex",
        "@media screen and (max-width: 1524px)": {
            flexWrap: "wrap",
        },
        "& .MuiFormControl-root": {
            marginRight: "18px",
        },
    },
    radioWithOS: {
        display: "flex",
        alignItems: "center",
        whiteSpace: "nowrap",
    },
    radioWithOSText: {
        marginRight: "10px",
    },
    datePicker: {
        width: "100%",
        marginLeft: "35px",
        display: "flex",
        "@media screen and (max-width: 1024px)": {
            marginLeft: "0",
            marginTop: "10px",
        },
    },
    select: {
        marginLeft: "35px",
        "& .MuiSelect-root": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            maxWidth: "160px",
            padding: "10px 35px 10px 20px !important",
            minWidth: "180px",
        },
        "& .MuiSelect-selectMenu": {
            border: "0px solid #b5b5b5 !important",
            borderRadius: "24px !important",
            boxShadow: "0px 0px 0px 1.5px rgba(0,0,0,0.3)",
        },
        "& .MuiSelect-outlined.MuiSelect-outlined:focus": {
            border: "0px solid #b5b5b5 !important",
        },
        "& .MuiSelect-outlined.MuiSelect-outlined:hover": {
            border: "0px solid #b5b5b5 !important",
        },
        "@media screen and (max-width: 1024px)": {
            marginLeft: "0",
            marginTop: "10px",
        },
    },
    noMarginBtn: {
        "&.MuiDialogActions-root": {
            marginTop: "0 !important",
        },
    },
    to: {
        fontSize: "20px",
        color: "#aaa",
        margin: "0 15px 0 10px",
    },
    eventOuter: {
        display: "flex",
        alignItems: "center",
    },
    dot: {
        width: "10px",
        height: "10px",
        borderRadius: "50%",
    },
    errorMessage: {
        marginLeft: "120px",
    },
    tableTitle: {
        display: "flex !important",
        alignItems: "center !important",
        position: "relative",
        marginBottom: "10px",
        zIndex: "4 !important",
        "& svg": {
            marginBottom: "0 !important",
            top: "0 !important",
            marginLeft: "10px !important",
            cursor: "pointer",
        },
    },
    createGroupInput: {
        display: "flex",
        width: "100%",
        alignItems: "center",
        "& .MuiFormControl-root": {
            minWidth: "65% !important",
        },
        "& .MuiButton-containedPrimary": {
            width: "170px !important",
            borderRadius: "5px !important",
            margin: "0 0 0 15px",
        },
    },
    list: {
        padding: "0",
        "& .MuiButtonBase-root": {
            alignItems: "center",
            display: "flex",
            height: "60px",
            "&:hover": {
                cursor: "unset",
            },
        },
        "& .MuiListItem-container": {
            borderTop: "1px solid #e0e0e0",
            "&:nth-child(odd)": {
                backgroundColor: "#f3f3f3",
            },
            "&:last-child": {
                borderBottom: "1px solid #e0e0e0",
            },
            "&:hover": {
                backgroundColor: "#e0e0e0",
            },
        },
    },
    listIcon: {
        width: "60%",
        "& p": {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        },
    },
    createGroupHeader: {
        margin: "50px 20px 15px",
        display: "flex",
    },
    createGroupHeaderItem: {
        width: "50%",
        display: "flex",
        justifyContent: "center",
    },
    renameGroup: {
        display: "flex",
        alignItems: "flex-end",
        cursor: "pointer",
        "& svg": {
            marginRight: "10px",
        },
    },
    nodata: {
        display: "flex",
        justifyContent: "center",
    },
    listDelete: {
        cursor: "pointer !important",
        width: "60px",
    },
    listOuter: {
        maxHeight: "400px",
        overflowY: "scroll",
        margin: "10px 0",
    },
    editIconArea: {
        display: "flex",
        justifyContent: "center",
    },
    inputFieldEdit: {
        width: "80%",
    },
    screenshotDialogArea: {
        minHeight: "220px",
        "& .screenShotDownload": {
            width: "100%",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontSize: "16px",
            marginBottom: "10px",
            "&:hover": {
                color: "#17acc2",
            },
            "& svg": {
                marginRight: "5px",
            },
            "&.disabled": {
                color: "#aaa",
                cursor: "not-allow",
                "&:hover": {
                    color: "#aaa",
                },
            },
        },
    },
    screenshotDialogOuter: {
        padding: "10px",
    },
    screenshotDialogBlock: {
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "10px",
        border: "1px solid #e0e0e0",
        padding: "15px 0 20px 0",
        "&:hover .screenshotDialogInner": {
            opacity: "1",
        },
    },
    screenshotDialogName: {
        fontSize: "14px",
        margin: "0 0 10px",
        width: "85%",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        overflow: "hidden",
    },
    screenshotDialogTitle: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        "& .MuiFormControl-fullWidth": {
            width: "200px",
        },
    },
    screenshotDialogImg: {
        position: "relative",
        width: "85%",
        height: "120px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        "& .screenshotDialogInner": {
            backgroundColor: "rgba(255,255,255,0.9)",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            height: "40px",
            width: "60%",
            position: "absolute",
            top: "0",
            bottom: "0",
            right: "0",
            left: "0",
            margin: "auto",
            borderRadius: "50px",
            opacity: "0",
            transition: "0.3s",
            "& svg": {
                fontSize: "30px",
                cursor: "pointer",
                color: "#707070",
                "&:hover": {
                    color: "#000",
                },
            },
        },
        "& img": {
            width: "100%",
            height: "100%",
        },
    },
    boldHeader: {
        fontWeight: "600",
        fontSize: "20px",
    },
    uploadArea: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        "& label": {
            width: "250px",
            marginRight: "10px",
        },
    },
    errorText: {
        color: "#ff9e40",
    },
    textfieldArea: {
        display: "flex",
        alignItems: "center",
        margin: "10px 0",
        "& .textLabel": {
            width: "135px",
        },
        "& .selectArea": {
            width: "100%",
            "& .MuiSelect-selectMenu": {
                padding: "5px",
            },
            "& .lableSelect": {
                marginLeft: "10px",
                width: "180px",
            },
        },
        "& .lableInputMultiline": {
            width: "100%",
            border: "1px solid #aaa",
            borderRadius: "10px",
            marginLeft: "10px",
            "& .MuiInputBase-multiline": {
                padding: "10px",
            },
        },
        "& .lableInput": {
            borderBottom: "1px solid #aaa",
            marginLeft: "10px",
            width: "100%",
            "& input": {
                padding: "5px",
            },
        },
    },
    groupsInnerArray: {
        display: "flex",
        alignItems: "center",
        marginBottom: "5px",
        "&:last-child": {
            marginBottom: "0",
        },
        "& .arrow": {
            color: "#1DBBD4",
            marginRight: "5px",
        },
    },
    groupsArray: {
        "& .groupItem": {
            marginBottom: "5px",
            "&:last-child": {
                marginBottom: "0",
            },
        },
        "& .more": {
            cursor: "pointer",
            color: "#aaa",
            textDecoration: "underline",
        },
    },
    tableTitleCategory: {
        display: "inline-flex !important",
        alignItems: "center !important",
        "& svg": {
            marginLeft: "10px !important",
        },
    },
    icon_btn: {
        cursor: "pointer",
    },
    iconWText: {
        margin: "0 3px",
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
    },
    iconText: {
        margin: "2px 0 0 5px",
        fontWeight: 600,
    },
    publishDialog: {
        "& .publishNameVersion": {
            display: "flex",
            margin: "0 0 10px",
            paddingBottom: "10px",
            borderBottom: "1px solid #e0e0e0",
            fontSize: "14px",
            "& .set": {
                display: "flex",
                "& .value": {
                    fontWeight: "600",
                    margin: "0 10px 0 5px",
                    maxWidth: "150px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                },
            },
        },
    },
    noPaddingDialog: {
        "& .MuiDialogTitle-root": {
            padding: "20px !important",
        },
        "& .MuiDialog-paper": {
            padding: "0",
            "& .nameArea": {
                padding: "0px 20px 10px",
                display: "flex",
                alignItems: "center",
                fontSize: "16px",
                fontWeight: "600",
                "& .ostype": {
                    marginLeft: "10px",
                    "& img": {
                        width: "16px",
                    },
                },
            },
            "& .MuiDialogContent-root": {
                padding: "0 !important",
                "& .noData": {
                    width: "100%",
                    padding: "20px 15px",
                    backgroundColor: "#f1f1f1",
                    borderBottom: "1px solid #ddd",
                    color: "#999",
                },
                "& .table-title-withIcon": {
                    padding: "15px 5px",
                    width: "100%",
                    display: "inline-flex !important",
                    alignItems: "center !important",
                    position: "relative",
                    zIndex: "4 !important",
                    "& svg": {
                        marginBottom: "0 !important",
                        top: "0 !important",
                        marginLeft: "10px !important",
                    },
                    "& .icon-btn": {
                        cursor: "pointer",
                    },
                    "& .icon-text": {
                        margin: "2px 0 0 5px",
                        fontWeight: 600,
                    },
                    "& .icon-with-text": {
                        margin: "0 3px",
                        display: "flex",
                        alignItems: "center",
                        cursor: "pointer",
                        "&.disabled": {
                            color: "#aaa",
                        },
                    },
                },
                "& .disabledCheckboxOuter": {
                    padding: "9px 10px",
                    "& .disabledCheckbox": {
                        width: "15px",
                        height: "15px",
                        border: "2px solid #ddd",
                        margin: "0",
                        backgroundColor: "#fbfbfb",
                        borderRadius: "3px",
                        cursor: "not-allowed",
                    },
                },
                "& .MuiTableRow-head": {
                    borderTop: "1px solid #e0e0e0",
                    padding: "0px",
                },
                "& .tableBottom": {
                    padding: "20px 15px",
                },
                "& .unpublishBtn": {
                    color: "#777",
                    textAlign: "center",
                    border: "1px solid #777",
                    borderRadius: "50px",
                    width: "80px",
                    padding: "3px 5px",
                    background: "#fff",
                    cursor: "pointer",
                },
                "& .publishBtn": {
                    color: "#1DBBD4",
                    textAlign: "center",
                    border: "1px solid #1DBBD4",
                    borderRadius: "50px",
                    width: "80px",
                    padding: "3px 5px",
                    background: "#fff",
                    cursor: "pointer",
                },
            },
        },
    },
}));

export default useStyles;
