import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import { language } from "./datatableLanguage";
import * as localforage from "localforage";
import { Tooltip } from "@material-ui/core";
import * as $ from "jquery";
import "../css/dataTable.css";
import "datatables.net";
import "datetime-moment";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { datatableErrorHandling } from "./datatableErrorHandling";
import { kbToMb } from "./formatBytesTransformer";

export const table = async (
    t,
    device_id,
    headerData,
    stopAppHandler,
    setLoading,
    disableDetectBtn,
    data
) => {
    const cpu = headerData.cpu;
    const ram = headerData.ram;

    const disableHandler = (row) => {
        if (row && row.task === null) {
            return (
                <div className="stopApplicationOuter">
                    <div className={`stopBtn`} onClick={() => stopAppHandler(row)}>
                        <div className="square"></div>
                    </div>
                </div>
            );
        } else if (
            row?.task?.status === "completed" ||
            row?.task?.status === "failed"
        ) {
            return (
                <div className="stopApplicationOuter">
                    <div className={`stopBtn`} onClick={() => stopAppHandler(row)}>
                        <div className="square"></div>
                    </div>
                </div>
            );
        } else {
            return (
                <Tooltip title={t("appRunningStatus.getAppNow")} placement="left">
                    <div className="stopApplicationOuter">
                        <div className={`stopBtn disabled`}>
                            <div className="square"></div>
                        </div>
                    </div>
                </Tooltip>
            );
        }
    };

    const token = await localforage.getItem("token");
    $.fn.dataTable.ext.errMode = "throw";
    if (disableDetectBtn) {
        setLoading(true);
    } else {
        $("#app-runnung-list-table").DataTable({
            serverSide: true,
            processing: true,
            language: language(t),
            searching: true,
            order: [[0, "desc"]],
            info: true,
            autoWidth: false,
            stateSave: false,
            destroy: true,
            pageLength: 10,
            ordering: true,
            ajax: {
                url: `${url}/api/v2/device/running-application-datatable`,
                type: "GET",
                data: {
                    device_id,
                },
                beforeSend: (request) => {
                    request.setRequestHeader("Authorization", "Bearer " + token);
                    request.setRequestHeader("Accept", "application/json");
                },
                error: (error) => {
                    datatableErrorHandling(
                        error.responseJSON ? error.responseJSON.message : ""
                    );
                },
                complete: () => {
                    setLoading(false);
                },
            },
            columns: [
                {
                    data: "name",
                    className: "name",
                    title: `${t("appRunningStatus.appName")}`,
                    createdCell: (td, data) => {
                        ReactDOM.render(data || "-", td);
                    },
                },
                {
                    data: "cpu_usage",
                    className: "cpu_usage",
                    searchable: false,
                    title: `${t("appRunningStatus.cpu_usage")} ( ${cpu && cpu} % )`,
                    createdCell: (td, data) => {
                        ReactDOM.render(data || data === 0 ? <div>{data} %</div> : "-", td);
                    },
                },
                {
                    data: "ram_usage",
                    className: "ram_usage",
                    searchable: false,
                    title: `${t("appRunningStatus.ram_usage")} ( ${ram && ram} )`,
                    createdCell: (td, data) => {
                        ReactDOM.render(
                            data || data === 0 ? <div>{kbToMb(data)} MB</div> : "-",
                            td
                        );
                    },
                },
                {
                    data: "updated_at",
                    name: "",
                    searchable: false,
                    className: "stopApplication",
                    title: `${t("appRunningStatus.stopApplication")}`,
                    orderable: false,
                    createdCell: (td, data, row) => {
                        ReactDOM.render(disableHandler(row), td);
                    },
                },
            ],
        });
        searchStyleRender();
    }
};
