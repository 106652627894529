import React from 'react';
import { List, ListItemIcon, ListItemText } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';
// import CropRoundedIcon from '@material-ui/icons/CropRounded';
import ListItemLink from '../commons/ListItemLink';
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import ImportantDevicesRoundedIcon from '@material-ui/icons/ImportantDevicesRounded';
import SyncAltRoundedIcon from '@material-ui/icons/SyncAltRounded';
// import MemoryRoundedIcon from '@material-ui/icons/MemoryRounded';
// import SystemUpdateAltRoundedIcon from '@material-ui/icons/SystemUpdateAltRounded';
import FileDeploymentIcon from '../../assets/images/filedeployment-icon.svg';
import ScreenRotationRoundedIcon from '@material-ui/icons/ScreenRotationRounded';
import ORALogo from '../../assets/images/ora-logo.svg';
import { useTranslation } from "../../langs/useTranslation";
import HasPermission from '../commons/HasPermission';
import VNCIcon from '../../assets/images/vnc-icon.svg';

const MultipleTaskBtnList = () => {
    const classes = useStyles();
    const { t } = useTranslation('device')
    return(
        <List className={classes.deviceGroupManagmentBtnList}>
            <HasPermission permission="multiple_deploy_file">
                <ListItemLink to="/multiple-devices-task/deploy-file">
                    <ListItemIcon><img src={FileDeploymentIcon} alt="file deployment icon" /></ListItemIcon>
                    <ListItemText>{t('tasks.deploy_file')}</ListItemText>
                </ListItemLink>
            </HasPermission>
            <HasPermission permission="multiple_restart_system">
                <ListItemLink to="/multiple-devices-task/restart-system">
                    <ListItemIcon><PowerSettingsNewRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.restart_system')}</ListItemText>
                </ListItemLink>
            </HasPermission>
            {/* <HasPermission permission="multiple_bios_update">
                <ListItemLink to="/multiple-devices-task/bios-update">
                    <ListItemIcon><SystemUpdateAltRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.bios_update')}</ListItemText>
                </ListItemLink>
            </HasPermission> */}
            {/* <HasPermission permission="multiple_peripherals_fw_update">
                <ListItemLink to="/multiple-devices-task/fw-update">
                    <ListItemIcon><MemoryRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.firmware_update')}</ListItemText>
                </ListItemLink>
            </HasPermission> */}
            <HasPermission permission="multiple_kiosk_mode">
                <ListItemLink to="/multiple-devices-task/kiosk-mode">
                    <ListItemIcon><ImportantDevicesRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.kiosk_mode')}</ListItemText>
                </ListItemLink>
            </HasPermission>
            <HasPermission permission="multiple_update_ora">
                <ListItemLink to="/multiple-devices-task/ora-update">
                    <ListItemIcon><img src={ORALogo} alt="ora logo" /></ListItemIcon>
                    <ListItemText>{t('tasks.update_ora')}</ListItemText>
                </ListItemLink>
            </HasPermission>
            {/* <HasPermission permission="multiple_screenshot">
                <ListItemLink to="/multiple-devices-task/screenshot">
                    <ListItemIcon><CropRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.screenshot')}</ListItemText>
                </ListItemLink>
            </HasPermission> */}
            <HasPermission permission="multiple_io_set_up">
                <ListItemLink to="/multiple-devices-task/io-setup">
                    <ListItemIcon><SyncAltRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.io_setup')}</ListItemText>
                </ListItemLink>
            </HasPermission>
            <HasPermission permission="multiple_rotation">
                <ListItemLink to="/multiple-devices-task/rotation">
                    <ListItemIcon><ScreenRotationRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.rotation')}</ListItemText>
                </ListItemLink>
            </HasPermission>
            <HasPermission permission="multiple_vnc_setting">
                <ListItemLink to="/multiple-devices-task/vnc-setting">
                    <ListItemIcon>
                        <img src={VNCIcon} alt="vnc icon" />
                    </ListItemIcon>
                    <ListItemText>{t('tasks.vncSetting')}</ListItemText>
                </ListItemLink>
            </HasPermission>
        </List>
    )
}

export default MultipleTaskBtnList;