import * as localforage from 'localforage';

export const checkIfGroupsOrTagsSelected = async (setSelectedGroupIds, setSelectedTagIds, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName) => {

    let selectedGroupIds = await localforage.getItem(storedGroupIdsToLocalforageName);
    let selectedTagIds = await localforage.getItem(storedTagIdsToLocalforageName);

    setSelectedGroupIds(selectedGroupIds && selectedGroupIds.length > 0 ? selectedGroupIds : false);
    setSelectedTagIds(selectedTagIds && selectedTagIds.length > 0 ? selectedTagIds : false);

}

export const getSelectedDevicesByTableOrExcel = async (filterDevicesBy, storedDeviceIdsFromDatatableToLocalforageName, storedDeviceExcelFileToLocalforageName) => {

    let selectedDevices;

    if (filterDevicesBy === ('filter_by_group' || 'filter_by_tag')) {
        selectedDevices = await localforage.getItem(storedDeviceIdsFromDatatableToLocalforageName);
        return selectedDevices && selectedDevices.length > 0 ? selectedDevices : false;
    }
    if (filterDevicesBy === 'excel_import') {
        selectedDevices = await localforage.getItem(storedDeviceExcelFileToLocalforageName);
        return selectedDevices;
    }

}

export const taskExcuteDateTime = async (date, storedDatePickerValueToLocalforageName) => {

    let datetime;

    if (date === 'immediately') {
        datetime = 'immediately';
    } else {
        datetime = await localforage.getItem(storedDatePickerValueToLocalforageName);
    }

    return datetime;
}
