import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import SelectGroups from "./SelectGroups";

const PublishDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    onConfirm,
    loading,
    publishData,
    managementData,
    setSelectedIds,
    selectIds,
    noGroup,
    setNoGroup,
  } = props;
  const { t } = useTranslation("appUpdateService");
  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle id="add-edit-alarm-notification">
        {t("dialog.publishHeader")}
      </DialogTitle>
      <>
        <DialogContent className={classes.publishDialog}>
          <div className="publishNameVersion">
            <div className="set">
              {t("dialog.publishAppName")} :{" "}
              <div className="value">{managementData.name}</div>
            </div>
            <div className="set">
              {t("dialog.appVersion")} :{" "}
              <div className="value">{publishData.version}</div>
            </div>
          </div>
          <div>
            <SelectGroups
              publishData={publishData}
              setSelectedIds={setSelectedIds}
              selectIds={selectIds}
              tableDisabled={loading}
              setNoGroup={setNoGroup}
            />
          </div>
          {noGroup && (
            <div style={{ color: "#ff9e40", marginTop: "5px" }}>
              {t("dialog.noGroup")}
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            btnColor="primary"
            disabled={loading}
            type="button"
            btnText={t("dialog.confirmBtn")}
            onClick={() => onConfirm()}
          />
          <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default PublishDialog;
