import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

const generateRecursiveList = (json = []) => {
    let treeData = [];
    if (json) {
        json.map((res, index) => {
            if (Array.isArray(res.children) && res.children.length > 0) {
                treeData.push({
                    title: res.name,
                    num: res.devices,
                    key: res.id,
                    parent_id: res.parent_company_id,
                    children: generateRecursiveList(res.children),
                });
            } else {
                treeData.push({
                    title: res.name,
                    num: res.devices,
                    key: res.id,
                    parent_id: res.parent_company_id,
                    isLeaf: true, // means the last layer
                });
            }
            return treeData;
        });
    }

    return treeData;
};

export const getCompanyTreeApi = async () => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");

    try {
        let response = await axios({
            url: `${url}/api/v2/companies/devices`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id: companyId,
            },
        });

        return generateRecursiveList(response.data.companies);
    } catch (error) {
        errorHandling(error, false);
    }
};
