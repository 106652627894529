import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateDevicePeripheralAPI = async (rowData, t, successfulText) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/command/device-peripheral-update-send`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                id: rowData.id,
                device_id: rowData.device_id,
                execute_type: 'device_peripheral_update',
                peripheral_type: rowData.firmware.peripheral_type.name,
                file_name: rowData.firmware.name,
                file_path: rowData.firmware.file,
                md5: rowData.firmware.md5
            }
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
