import React, { useState, useEffect, useContext } from 'react';
import { Popover, Box, TextField } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { createFolderAPI } from '../../apis/createFolderAPI';
import { ValidationErrorText } from '../commons/ValidationText';
import { CreateNewFolderOutlined } from '@material-ui/icons';
import { StyledSubmitBtn } from '../commons/FormBtns';
import { SubmitBtnContext } from '../../App';

const CreateFolderPopover = props => {
    const { t } = useTranslation('nubisDrive');
    const { onClose, open, parentId, anchorEl, reloadTable } = props;
    const [ isExecuting, setIsExecuting ] = useState(false);
    const [ name, setName ] = useState('');
    const [ isFolderExisted, setIsFolderExisted ] = useState(false);
    const [ errorText, setErrorText ] = useState('');
    const { dispatch } = useContext(SubmitBtnContext);

    const id = Boolean(anchorEl) ? 'create-folder-popover' : undefined;
    
    const handleNameChange = e => {
        setName(e.target.value);
    }

    const handleCreateFolder = async() => {
        dispatch({type: 'showLoading'});
        await createFolderAPI(name, parentId, t, onClose, setIsFolderExisted, setErrorText, reloadTable);
        dispatch({type: 'stopLoading'});
    }

    useEffect(()=>{
        setIsExecuting(false);
        setName('');
        setIsFolderExisted(false);
        setErrorText('');
    },[open]);
    
    return(
        <Popover 
            id={id}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={onClose}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}>
            <Box 
                m={2} 
                className="create-folder-wrap"
                display="flex" 
                alignItems="row" 
                justifyContent="center">
                <Box mr={1}>
                    <TextField 
                        size="small"
                        autoFocus
                        value={name}
                        onChange={e => handleNameChange(e)}
                        label={t('dialog.newFolderName')} 
                        onKeyPress={e=>{if(e.key === 'Enter') handleCreateFolder()}}
                        helperText={ isFolderExisted && <ValidationErrorText text={errorText} /> } 
                    />
                </Box>
                <StyledSubmitBtn 
                    btnColor="primary" 
                    type="button" 
                    disabled={isExecuting}
                    onClick={handleCreateFolder} 
                    startIcon={<CreateNewFolderOutlined />} 
                    btnText={t('dialog.createBtn')} />
            </Box>
        </Popover>
    )
}

export default CreateFolderPopover;