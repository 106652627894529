import React from "react";
import {
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  Grid,
} from "@material-ui/core";
import useStyles from "../../styles/deviceManagementFormStyle";
import { useTranslation } from "../../langs/useTranslation";
import { bytesConverter } from "../../helpers/formatBytesTransformer";
import SelectFileFromNubisDrive from "../device/SelectFileFromNubisDrive";
import { RequiredText } from "../commons/ValidationText";
import FileUpload from "../device/FileUpload";
import LinearWithValueLabel from "../commons/LinearProgress";
import { s3upload } from "../../helpers/s3upload";

const SelectFile = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("task");
  const {
    formikName,
    formikValue,
    percentCompleted,
    setPercentCompleted,
    setIsUploading,
    isUploading,
    formikOnChange,
    formikError,
    storedFileValueToLocalforageName,
    setFile,
    setDriveStorageId,
    ifDeployFile,
    file,
    disabled,
    setDisabled,
    setReturnValue
  } = props;

  const handleUploadFile = async () => {
    setIsUploading(true);
    if (file) {
      setDisabled(true);
      let successfulText = `${t("noty.successfullyUploadFile")}`;
      await s3upload(
        document.getElementById("fileUpload"),
        "",
        t,
        successfulText,
        percentCompleted,
        setPercentCompleted,
        undefined,
        undefined,
        undefined,
        true,
        setReturnValue,
        "ora/deploy-files",
        true,
        false,
        true
      );
    } else {
      setIsUploading(false);
    }
  };

  return (
    <div className={`${classes.radioSelectGroup} mb_16`}>
      <FormControl component="div" fullWidth={true} required>
        <RadioGroup
          aria-label="files"
          name={formikName}
          value={formikValue}
          onChange={formikOnChange}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl>
                <FormControlLabel
                  value="file_from_local_pc"
                  label={`${t("task.fileFromLocalPC")}`}
                  control={<Radio color="primary" />}
                />
                <FileUpload
                  uploadFileType={"file"}
                  setFile={setFile}
                  ifNubisDrive={ifDeployFile}
                  changeHanlder={handleUploadFile}
                  tableDisabled={
                    formikValue !== "file_from_local_pc" || disabled
                      ? true
                      : false
                  }
                  availableStorage={bytesConverter(1024, "MB")}
                  storedFileToLocalforageName={storedFileValueToLocalforageName}
                />
                {isUploading && (
                  <LinearWithValueLabel percentCompleted={percentCompleted} />
                )}
                <Typography variant="caption" color="textSecondary">
                  {t("task.maximumFileSize")}
                </Typography>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <FormControl className="dropdown-lazyloading-wrap">
                <FormControlLabel
                  value="file_from_nubis_drive"
                  label={`${t("task.fileFromNubisDrive")}`}
                  control={<Radio color="primary" />}
                />
                <SelectFileFromNubisDrive
                  setFile={setFile}
                  setDriveStorageId={setDriveStorageId}
                  tableDisabled={
                    formikValue === "file_from_nubis_drive" ? false : true
                  }
                  storedFileToLocalforageName={storedFileValueToLocalforageName}
                />
              </FormControl>
            </Grid>
          </Grid>
        </RadioGroup>
        {!file || formikError ? <RequiredText /> : ""}
      </FormControl>
    </div>
  );
};

export default SelectFile;
