import React, { useState, useEffect } from 'react'
import LazyLoad from 'react-lazyload';
import { FormControlLabel, Checkbox, Chip, Typography } from '@material-ui/core';
import useStyles from '../../styles/multipleSelectStyle';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { handleFilterChange } from '../../helpers/handleFilterChangeWithDatatable';
import { useTranslation } from "../../langs/useTranslation";
import { useParams } from 'react-router-dom';
import LoadingIndicator from '../commons/LoadingIndicator';
import { NoDataText } from '../commons/ValidationText';
import { getAllGroups } from '../../helpers/taskDatatableHandlers';
import { table } from '../../helpers/taskDeviceTable';
import { vncTable } from '../../helpers/vncSecuritySettingDeviceTable';
import { deployFileDeviceTable } from '../../helpers/deployFileDeviceTable';
import * as localforage from 'localforage';

const FilterSelectionForTask = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('task');
    const {   
                tableDisabled, 
                isSubmitting, 
                filterDevicesBy, // options: 'filter_by_group', 'filter_by_tag'
                localforageKeyName, // localforage keyname of selectedGroups ('filterDevicesBy' = 'filter_by_group') / selectedTags ('filterDevicesBy' = 'filter_by_tag')
                storedDeviceIdsToLocalforageName, 
                setSelectedIds, // updating state of selectedIds and decide if showing 'required' text after datatable rendered completely
                relationName, // options: 'ioVoltages', 'deviceModel', 'alarmDeployment'
                deviceModelIds, // required if relationName = 'deviceModel'
                port, // required if relationName = 'ioVoltages'
                taskType, // determine which table to render
                file, // required if taskType = 'deploy_file', file for checking availability of auto-launch
                isAutoLaunch, // can be used if taskType = 'deploy_file'
            } = props;
    const [ groups, setGroups ] = useState([]);
    const { device_id } = useParams();
    const [ selectedGroups, setSelectedGroups ] = useState([]);
    const [ loading, setLoading] = useState(true);

    const handleChange = async(event, chipItem) => {
        
        const selectedItemsForTable = await handleFilterChange(event, chipItem, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, t, filterDevicesBy);
        const selectedGroupsForTable = selectedItemsForTable.Groups;
        const selectedTagsForTable = selectedItemsForTable.Tags;
        updateTable(selectedGroupsForTable, selectedTagsForTable);

    };
    
    const updateTable = (selectedGroupsForTable, selectedTagsForTable) => {
        
        if (!taskType) {
            table(t, filterDevicesBy, selectedGroupsForTable, selectedTagsForTable, relationName, port, deviceModelIds, storedDeviceIdsToLocalforageName, setSelectedIds);
        }
        
        if (taskType === "deploy_file"){
            deployFileDeviceTable(t, filterDevicesBy, selectedGroupsForTable, selectedTagsForTable, storedDeviceIdsToLocalforageName, file, setSelectedIds, isAutoLaunch);
        }
        if (taskType === "vnc_setting") { 
            vncTable(t, filterDevicesBy, selectedGroupsForTable, selectedTagsForTable, storedDeviceIdsToLocalforageName, setSelectedIds);
        }

    }

    const reloadTableByFilter = async() => {
        
        const groups = await localforage.getItem(localforageKeyName);

        if (filterDevicesBy === 'filter_by_group') {
            updateTable(groups);
        }

        if (filterDevicesBy === 'filter_by_tag') {
            updateTable(undefined, groups);
        }

    }


    // '!device_id' is to avoids running if the task is for single device
    useEffect(() => {
        if(!device_id) getAllGroups(filterDevicesBy, setGroups, setLoading); 
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(() => {
        if(!device_id && !tableDisabled && !isSubmitting) reloadTableByFilter();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableDisabled, file, isAutoLaunch])


    return(
        <div className={classes.multipleSelect}>
            {selectedGroups.length > 0 &&
                selectedGroups.map(item => (
                <Chip
                    key={item.id}
                    className={classes.chip}
                    label={item.name}
                    icon={<CloseRoundedIcon />}
                    onClick={(e)=>handleChange(e, item)}
                    disabled={tableDisabled}
                />
            ))}
            <div className={classes.lazyLoadWrap}>
                { loading ? 
                    <div className={classes.loadingIndicatorWrapper}>
                        <LoadingIndicator />
                    </div>
                    :
                    (groups.length > 0 ? 
                        groups.map((o) => {
                            return (
                                <LazyLoad 
                                    key={o.id} 
                                    once={o.once} 
                                    overflow
                                    offset={10000} // to preload the component below the viewport, set the value of offset
                                    height={115} // the height for the first render
                                    placeholder={
                                        <Typography className={classes.loading} variant="caption">loading...</Typography>
                                    }
                                >
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                            id={o.id} 
                                            checked={o.checked} 
                                            onChange={(e)=>handleChange(e)} 
                                            name={o.name} 
                                            />
                                        }
                                        label={o.name}
                                        disabled={tableDisabled}
                                    />
                                </LazyLoad>
                            )
                        })
                        :
                        <NoDataText />
                    )
                }
                
            </div>    
        </div>
    )
}

export default FilterSelectionForTask;

