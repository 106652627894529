export const handleClose = (setOpen, dispatch, setIsExecuting) => {
    setOpen(false);
    dispatch({ type: 'stopLoading' });
    setIsExecuting(false);
}

export const handleHasResponseStatus = (setOpen, resetAllStoredValueInLocalforage) => {
    setOpen(false);
    resetAllStoredValueInLocalforage();
    //window.history.back();
}
