import * as localforage from 'localforage';

export const handelSelectionChange = async (event, chipItem, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, setChecked) => {
    // clarify if the event target is checkbox or chip
    const checkBox = event.target;
    const itemId = chipItem ? chipItem.id : checkBox.id;
    const itemName = chipItem ? chipItem.name : checkBox.name;

    // select all
    if (itemName === 'select all') {

        let checkedStatus = checkBox.checked;
        let allGroups = groups.map(o => ({ name: o.name, id: o.id, checked: checkedStatus ? true : false }));

        setChecked(checkedStatus);
        setGroups(allGroups);
        setSelectedGroups(checkedStatus ? allGroups : []);
        storeGroupsToLocalforage(localforageKeyName, checkedStatus ? allGroups : []);
        return;
    }

    // update the groups array checked status
    const changedGroupIndex = groups.map((o) => { return o.id }).indexOf(itemId);
    const changedGroup = chipItem ? { name: itemName, id: chipItem.id, checked: false } : { name: itemName, id: checkBox.id, checked: checkBox.checked };
    groups.splice(changedGroupIndex, 1, changedGroup);
    setGroups([...groups]);

    // update selected group status
    const selectedGroupIndex = selectedGroups.map((o) => { return o.id }).indexOf(itemId);
    if (selectedGroupIndex > -1) {
        selectedGroups.splice(selectedGroupIndex, 1);
        setSelectedGroups(selectedGroups);
    } else {
        let selectedGroupObj = {};
        selectedGroupObj['name'] = itemName;
        selectedGroupObj['id'] = itemId;
        selectedGroups.push(selectedGroupObj);
        setSelectedGroups(selectedGroups);
    }

    // update select-all checkbox state
    if (selectedGroups.length === groups.length) {
        setChecked(true);
    } else {
        setChecked(false);
    }

    storeGroupsToLocalforage(localforageKeyName, selectedGroups);

}

const storeGroupsToLocalforage = async (localforageKeyName, selectedGroups) => {

    // stored selected groups and devices to Localforage
    if (selectedGroups.length > 0) await localforage.setItem(localforageKeyName, selectedGroups);

    // remove item from Localforage if the array is empty
    if (selectedGroups.length < 1) await localforage.removeItem(localforageKeyName);

}
