import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";

export const getAppRunningListApi = async (device_id) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/running-application-datatable`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const getDetectStatusApi = async (device_id) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/detect-application-status`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const detectAppRunningApi = async (device_ids) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/detect-usb-port-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                execute_type: "detect_running_applications",
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const executeAppRunningApi = async (
    ids,
    name,
    running_application_id,
    path,
    script,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    const device_ids = [ids];
    try {
        let res = await axios({
            url: `${url}/api/v2/command/execute-running-app-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                execute_type: "execute_application",
                running_application_id,
                name,
                path,
                script,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const stopAppRunningApi = async (
    ids,
    running_application_id,
    stop_app,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    const device_ids = [ids];
    try {
        let res = await axios({
            url: `${url}/api/v2/command/stop-running-app-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                stop_app,
                running_application_id,
                execute_type: "stop_running_application",
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
