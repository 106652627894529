import React, { useState, forwardRef } from 'react';
import { Typography, Paper, Grid, FormControl } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectFileFromNubisDrive from '../device/SelectBIOSFileFromNubisDrive';
import SelectDate from '../device/SelectDate';
import CommandTextField from '../device/CommandTextField';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { biosFwUpdateAPI } from '../../apis/biosFwUpdateAPI';
import { shutDown } from '../../helpers/commandPlaceholders';
import { handleClose, handleHasResponseStatus } from '../../helpers/commandConfirmationDialogAction';
import { biosFwValuesSchema, biosFwInitialValues } from '../../config/singleDeviceTaskValuesSchema';
import { taskExcuteDateTime } from '../../helpers/formValidationForMultiDeviceTask';

const SingleForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const { executeType, deviceId, devices, storedDatePickerValueToLocalforageName, resetAllStoredValueInLocalforage, successfulText, dispatch } = props;
    const { t } = useTranslation('task');
    const [open, setOpen] = useState(false);
    const [datetime, setDatetime] = useState(true);
    const [md5, setMd5] = useState();
    const [fileName, setFileName] = useState();
    const [filePath, setFilePath] = useState();
    const [isExecuting, setIsExecuting ] = useState(false);
    const [commandText, setCommandText] = useState('');
    
    const handleExecuteAction = async() => {
       
        setIsExecuting(true);

        const responseStatus = await biosFwUpdateAPI(deviceId, devices, undefined, md5, fileName, filePath, executeType, datetime, commandText, t, successfulText);
        if(responseStatus) handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        
        dispatch({type: 'stopLoading'});

    };

    const updateDeviceEligibility = async(deviceInfo) => {
        // update table when file is selected ,and device is not selected by being imported
        setMd5(deviceInfo.md5);
        setFileName(deviceInfo.file_name);
        setFilePath(deviceInfo.file_path);
    };

    return(
            <Formik
                initialValues={biosFwInitialValues}
                validationSchema={biosFwValuesSchema}
                onSubmit={ async(values) => {
                    
                    dispatch({type: 'showLoading'});
                    
                    // setup the data from values for task execution
                    let datetime = await taskExcuteDateTime(values.date, storedDatePickerValueToLocalforageName);
                    setDatetime(datetime);
                    setCommandText(values.command);
                    
                    if( datetime ){
                        setOpen(true);
                    } else {
                        dispatch({type: 'stopLoading'}); 
                    }
                }}
            >
            {({
                values, errors, touched, handleChange, handleBlur, handleSubmit
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true} ref={ref}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}1. {t('task.selectAFileFromNubisDrive')}</Typography>
                                    <SelectFileFromNubisDrive 
                                        filterCondition="="
                                        formikName="file" 
                                        formikOnChange={(e) => {
                                            updateDeviceEligibility(e.target.value)
                                            return handleChange(e)}
                                        }
                                        deviceId={deviceId}
                                        formikValue={values.file}
                                        formikError={(errors.file && touched.file && errors.file)}
                                    />
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}2. {t('task.selectDate')}</Typography>
                                    <SelectDate 
                                        storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} 
                                        formikName="date" 
                                        formikOnChange={handleChange} 
                                        formikValue={values.date} 
                                        formikError={(errors.date && touched.date && errors.date)}
                                    />
                                    {!datetime ? <Typography variant="caption" color="error">{t('task.scheduledDatetimeNotGreaterThanCurrentDatetime')}</Typography> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                <CommandTextField 
                                        CommandTextFieldPlaceHolder={shutDown} 
                                        formikName="command"
                                        formikOnChange={handleChange}
                                        formikOnBlur={handleBlur}
                                        formikValue={values.command}
                                    />
                                </Paper>
                                <div className="submit-back-btn-wrap"> 
                                <StyledSubmitBtn startIcon={<SendRoundedIcon />} btnText={t('btn.confirmBtn')} type="submit" />
                                    <BackBtn />
                                    <CommandConfirmDialog
                                        isExecuting={isExecuting}
                                        okBtnOnClick={handleExecuteAction}
                                        backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                        open={open}
                                        dialogTitle={t('task.biosUpdateName')}
                                        dialogContent={t('modal.biosUpdateDescription')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Form>
            )}
        </Formik>
    )
})

export default SingleForm;