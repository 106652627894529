import React, { useState, useEffect, useRef } from "react";
import { FormControlLabel, Checkbox, Chip } from "@material-ui/core";
import useStyles from "../../styles/multipleSelectStyle";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../commons/LoadingIndicator";
import { NoDataText } from "../commons/ValidationText";
import { getGroupListApi } from "../../apis/appListApi";

const SelectGroups = (props) => {
  const mounted = useRef();
  const classes = useStyles();
  const {
    tableDisabled,
    publishData,
    setNoGroup,
    selectIds,
    setSelectedIds, // updating state of selectedIds and decide if showing 'required' text after datatable rendered completely
  } = props;
  const [groups, setGroups] = useState([]);
  const { device_id } = useParams();
  const [loading, setLoading] = useState(true);

  const handleChange = async (event, chipItem) => {
    const checkBox = event.target;
    const itemId = chipItem ? chipItem.id : checkBox.id;
    const itemName = chipItem ? chipItem.name : checkBox.name;
    const changedGroupIndex = groups
      .map((o) => {
        return o.id;
      })
      .indexOf(itemId);
    const changedGroup = chipItem
      ? { name: itemName, id: chipItem.id, checked: false }
      : {
          name: itemName,
          id: checkBox.id,
          checked: checkBox.checked,
        };
    groups.splice(changedGroupIndex, 1, changedGroup);
    setGroups([...groups]);

    const selectedGroupIndex = selectIds
      .map((o) => {
        return o.id;
      })
      .indexOf(itemId);
    if (selectedGroupIndex > -1) {
      selectIds.splice(selectedGroupIndex, 1);
      setSelectedIds(selectIds);
    } else {
      let selectedGroupObj = {};
      selectedGroupObj["name"] = itemName;
      selectedGroupObj["id"] = itemId;
      selectIds.push(selectedGroupObj);
      setSelectedIds(selectIds);
    }
  };

  const getGroup = async () => {
    setSelectedIds([]);
    setGroups([]);
    let data = await getGroupListApi();
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        data[i].checked = false;
      }
      if (publishData && publishData.groups && publishData.groups.length > 0) {
        for (let i = 0; i < data.length; i++) {
          for (let k = 0; k < publishData.groups.length; k++) {
            if (data[i].id === publishData.groups[k].id) {
              data[i].checked = true;
              setSelectedIds((prev) => {
                prev.push({ name: data[i].name, id: data[i].id });
                return prev;
              });
            }
          }
        }
      }
      setGroups(data);
      setLoading(false);
    } else {
      setLoading(false);
      setNoGroup(true);
    }
  };
  // '!device_id' is to avoids running if the task is for single device
  useEffect(() => {
    if (!mounted.current) {
      if (!device_id) getGroup();
      mounted.current = true;
    }
  });

  return (
    <div className={classes.multipleSelect}>
      {selectIds.length > 0 &&
        selectIds.map((item) => (
          <Chip
            key={item.id}
            className={classes.chip}
            label={item.name}
            icon={<CloseRoundedIcon />}
            onClick={(e) => handleChange(e, item)}
            disabled={tableDisabled}
          />
        ))}
      <div className={classes.lazyLoadWrap}>
        {loading ? (
          <div className={classes.loadingIndicatorWrapper}>
            <LoadingIndicator />
          </div>
        ) : groups.length > 0 ? (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {groups.map((o) => {
              return (
                <FormControlLabel
                  key={o.id}
                  control={
                    <Checkbox
                      id={o.id}
                      checked={o.checked}
                      onChange={(e) => handleChange(e)}
                      name={o.name}
                    />
                  }
                  label={o.name}
                  disabled={tableDisabled}
                />
              );
            })}
          </div>
        ) : (
          <NoDataText />
        )}
      </div>
    </div>
  );
};

export default SelectGroups;
