import Noty from 'noty';

const TextNoty = (successfulText, type = 'error') => {

    new Noty({
        theme: 'relax',
        type: type,
        closeWith: ['click', 'button'],
        layout: 'topRight',
        timeout: process.env.REACT_APP_NOTY_TIMEOUT,
        text: successfulText,
    }).show();
}

export default TextNoty;
