import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { useTranslation } from "../../langs/useTranslation";
import DetectBtn from "../commons/DetectBtn";
import AutoReloadTableBtn from "../commons/AutoReloadTableBtn";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import { utcFormatToLocalDateTimeWithSecond } from "../../helpers/datetimeHandlers";
import {
  getLocalFileSettingApi,
  enableLocalFileApi,
  setLocalFileLocationApi,
  backupFileLocationApi,
} from "../../apis/localFileUploadAPI";
import AntSwitch from "../deviceControl/AntSwitch";
import BackupIcon from "@material-ui/icons/Backup";
import textNoty from "../../helpers/textNoty";
import { numEnglishRegex, windowsPathRegex } from "../../helpers/regex";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import AssignmentTurnedInIcon from "@material-ui/icons/AssignmentTurnedIn";

const LocalFileUpload = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("device");
  const {
    onClose,
    open,
    deviceId,
    loading,
    setLoading,
    device_os_type,
  } = props;
  const [ifEnable, setIfEnable] = useState(false);
  const [ifEnableTemp, setIfEnableTemp] = useState(false);
  const [editIconShow, setEditIconShow] = useState(false);
  const [uploadLocationTemp, setUploadLocationTemp] = useState("");
  const [loadingRefesh, setLoadingRefesh] = useState(false);
  const [uploadLocation, setUploadLocation] = useState("");
  const [errorMessage, setError] = useState("");
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [information, setInformation] = useState({});
  const [task, setTask] = useState({});

  const handleClose = () => {
    onClose(false);
    setIfEnableTemp(false);
    setEditIconShow(false);
    setUploadLocation("");
    setUploadLocationTemp("");
  };

  const handleSwitchOn = (e) => {
    let status = e.target.checked;
    setIfEnableTemp(status);
    setError("");
  };

  const handleSwitchOff = async () => {
    setLoading(true);
    setError("");
    let json = { is_enable: false };
    let data = await enableLocalFileApi(deviceId, JSON.stringify(json));
    if (data && data.status === "success") {
      setEditIconShow(false);
      setUploadLocation("");
      setIfEnable(false);
      setIfEnableTemp(false);
      setLoading(false);
      textNoty(t("dialog.successText"), "success");
    }
  };

  const confirmEnable = async () => {
    let json = { is_enable: true };
    if (ifEnable) {
      if (!uploadLocation) {
        setError(t("localFileUpload.noLocation"));
        return;
      }
      if (
        device_os_type === "windows" &&
        !windowsPathRegex.test(uploadLocation)
      ) {
        setError(t("localFileUpload.windowsPathValid"));
        return;
      }
      if (
        device_os_type === "android" &&
        !numEnglishRegex.test(uploadLocation)
      ) {
        setError(t("localFileUpload.androidPathValid"));
        return;
      }
      setError("");
      setLoading(true);
      let res = await setLocalFileLocationApi(deviceId, uploadLocation);
      if (res && res.status === "success") {
        await getSetting();
        setEditIconShow(false);
        textNoty(t("dialog.successText"), "success");
      }
    } else {
      if (!uploadLocationTemp) {
        setError(t("localFileUpload.noLocation"));
        return;
      }
      if (
        device_os_type === "windows" &&
        !windowsPathRegex.test(uploadLocationTemp)
      ) {
        setError(t("localFileUpload.windowsPathValid"));
        return;
      }
      if (
        device_os_type === "android" &&
        !numEnglishRegex.test(uploadLocationTemp)
      ) {
        setError(t("localFileUpload.androidPathValid"));
        return;
      }
      setError("");
      setLoading(true);
      let data = await enableLocalFileApi(deviceId, JSON.stringify(json));
      if (data && data.status === "success") {
        let res = await setLocalFileLocationApi(deviceId, uploadLocationTemp);
        if (res && res.status === "success") {
          await getSetting();
          setUploadLocationTemp("");
          textNoty(t("dialog.successText"), "success");
        }
      }
    }
  };

  const editIconHandler = () => {
    if (editIconShow) {
      confirmEnable();
    } else {
      setEditIconShow(true);
    }
  };

  const statusHandler = (link) => {
    const status = task?.status;
    if (!link && !task?.status) {
      return (
        <Typography variant="body2" color="error">
          {t("localFileUpload.backupAgain")}
        </Typography>
      );
    }
    switch (status) {
      case "failed":
        return (
          <Typography variant="body2" color="error">
            Failed
          </Typography>
        );
      case "scheduled":
        return (
          <Typography variant="body2" color="secondary">
            Scheduled
          </Typography>
        );
      case "relayed":
        return (
          <Typography variant="body2" color="secondary">
            Relayed
          </Typography>
        );
      case "queued":
        return (
          <Typography variant="body2" color="secondary">
            Queued
          </Typography>
        );
      case "completed":
        return (
          <Typography variant="body2" color="primary">
            Completed
          </Typography>
        );

      default:
        return "-";
    }
  };
  const linkHandler = (status) => {
    switch (status) {
      case "failed":
        return (
          <Typography variant="body2" color="secondary">
            -
          </Typography>
        );

      case "scheduled":
        return (
          <Typography variant="body2" color="secondary">
            {t("localFileUpload.uploadNow")}
          </Typography>
        );
      case "relayed":
        return (
          <Typography variant="body2" color="secondary">
            {t("localFileUpload.uploadNow")}
          </Typography>
        );
      case "queued":
        return (
          <Typography variant="body2" color="secondary">
            {t("localFileUpload.uploadNow")}
          </Typography>
        );
      case "completed":
        return "-";

      default:
        return "-";
    }
  };
  const openUrl = (data) => {
    window.open(data);
  };
  const backup = async () => {
    setLoading(true);
    let data = await backupFileLocationApi(deviceId);
    if (data && data.status === "success") {
      getSetting();
      textNoty(t("localFileUpload.backupSuccess"), "success");
      setLoading(false);
    }
  };

  const getSetting = useCallback(
    async (loadingNeed = false) => {
      if (loadingNeed) setLoadingFirst(true);
      else setLoadingRefesh(true);
      setError("");
      let data = await getLocalFileSettingApi(deviceId);

      if (data) {
        setInformation(data.local_file_upload_information);
        if (data.local_file_setting === null) {
          setIfEnable(false);
          setIfEnableTemp(false);
          setLoadingFirst(false);
          setLoadingRefesh(false);
        } else {
          if (
            data.local_file_setting &&
            data.local_file_setting.json &&
            JSON.parse(data.local_file_setting.json) &&
            JSON.parse(data.local_file_setting.json).is_enable === false
          ) {
            setIfEnable(false);
            setIfEnableTemp(false);
            setLoadingFirst(false);
            setLoadingRefesh(false);
          } else {
            setIfEnable(true);
            setIfEnableTemp(true);
            setLoadingFirst(false);
            setLoadingRefesh(false);
          }
          if (
            data.local_file_upload_information &&
            data.local_file_upload_information.files_store_location
          ) {
            setUploadLocation(
              data.local_file_upload_information.files_store_location
            );
          }
          if (
            data.local_file_upload_information &&
            data.local_file_upload_information.task
          ) {
            setTask(data.local_file_upload_information.task);
          } else {
            setTask({});
          }
        }
      }
      setLoading(false);
    },
    [deviceId, setLoading]
  );

  useEffect(() => {
    if (open) {
      getSetting();
    }
  }, [getSetting, open]);

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="lg"
      className={`${classes.dialog} ${classes.localFile}`}
    >
      <div className="tableHeaderDialog">
        <Typography variant="h5">{t("localFileUpload.title")}</Typography>
      </div>
      {loadingFirst ? (
        <SmallerLoadingIndicator />
      ) : (
        <>
          <DialogContent>
            {!ifEnable ? (
              <>
                <div className="switchWithText">
                  <div>{t("localFileUpload.turnOnThisFunction")}</div>
                  <AntSwitch
                    checked={ifEnableTemp}
                    disabled={loading}
                    onChange={handleSwitchOn}
                    color="primary"
                  />
                </div>
                <div className="sentence">
                  <div>{t("localFileUpload.sentence1")}</div>
                  <div>{t("localFileUpload.sentence2")}</div>
                </div>
                {ifEnableTemp && (
                  <div
                    className="labelWithInput"
                    style={{ position: "relative" }}
                  >
                    <div className="label">
                      <Typography variant="h6">
                        {t("localFileUpload.setUploadLocation")}
                      </Typography>
                    </div>
                    <TextField
                      fullWidth
                      value={uploadLocationTemp}
                      onChange={(e) => setUploadLocationTemp(e.target.value)}
                      placeholder={`${
                        device_os_type === "android" ? "File Name" : "C:\\xxx\\xxx"
                      }`}
                    />
                    <div className="errorMsg">
                      {errorMessage && errorMessage}
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div>
                <div
                  className="labelWithInput"
                  style={{ position: "relative" }}
                >
                  <div className="label">
                    <Typography variant="h6">
                      {t("localFileUpload.setUploadLocation")}
                    </Typography>
                  </div>
                  <TextField
                    fullWidth
                    disabled={!editIconShow}
                    placeholder={`${
                      device_os_type === "android"
                        ? "File Name"
                        : "C:\\xxx\\xxx"
                    }`}
                    value={uploadLocation}
                    onChange={(e) => setUploadLocation(e.target.value)}
                  />
                  <IconButton
                    color="primary"
                    className={classes.iconButton}
                    aria-label="directions"
                    disabled={
                      loading ||
                      (task && task.status && (task.status !== "completed" && task.status !== "failed"))
                    }
                    onClick={editIconHandler}
                  >
                    {editIconShow ? <AssignmentTurnedInIcon /> : <EditIcon />}
                  </IconButton>
                  <div className="errorMsg">{errorMessage && errorMessage}</div>
                </div>
                <div className="labelWithInput">
                  <div className="label">
                    <DetectBtn
                      disableDetectBtn={
                        loading ||
                        (task &&
                          (task.status === "relayed" ||
                            task.status === "scheduled" ||
                            task.status === "queued"))
                      }
                      btnText={t("localFileUpload.backup")}
                      onClick={backup}
                      icon={<BackupIcon />}
                    />
                  </div>
                  <div>
                    <div>{t("localFileUpload.sentence3")}</div>
                    <div>{t("localFileUpload.sentence4")}</div>
                  </div>
                </div>
                <div className="labelWithInput">
                  <div className="label">
                    <Typography variant="h6">
                      {t("localFileUpload.backupStatus")}
                    </Typography>
                  </div>
                  <div>
                    {loadingRefesh ? (
                      <Typography variant="body2" color="secondary">
                        Loading...
                      </Typography>
                    ) : (
                      statusHandler(information?.s3_download_link)
                    )}
                  </div>
                </div>
                <div className="labelWithInput">
                  <div className="label">
                    <Typography
                      variant="h6"
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <div style={{ marginRight: "10px" }}>
                        {t("localFileUpload.downloadLink")}
                      </div>
                      <AutoReloadTableBtn onClick={() => getSetting(false)} />
                    </Typography>
                  </div>
                  <div>
                    {loadingRefesh ? (
                      <Typography variant="body2" color="secondary">
                        {t("localFileUpload.refreshDownloadLink")}
                      </Typography>
                    ) : (
                      <>
                        {information?.s3_download_link ? (
                          <div className="downloadLinkSet">
                            <Tooltip
                              title={information?.file_name}
                              placement="bottom"
                            >
                              <div
                                onClick={() =>
                                  openUrl(information?.s3_download_link)
                                }
                                style={{
                                  color: "#04b7d2",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  marginRight: "15px",
                                  fontWeight: "600",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  maxWidth: "500px",
                                }}
                                rel="noreferrer"
                              >
                                {information?.file_name}
                              </div>
                            </Tooltip>
                            <Typography variant="body2" color="secondary">
                              {t("localFileUpload.uploadDatetime")}{" "}
                              {task?.updated_at
                                ? utcFormatToLocalDateTimeWithSecond(
                                    task.updated_at
                                  )
                                : "-"}
                            </Typography>
                          </div>
                        ) : (
                          linkHandler(task?.status)
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            )}
          </DialogContent>
          {ifEnableTemp && !ifEnable && (
            <>
              <DialogActions>
                <StyledSubmitBtn
                  btnColor="primary"
                  disabled={loading}
                  onClick={confirmEnable}
                  type="button"
                  btnText={t("dialog.confirmBtn")}
                />
                <BackBtn
                  onClick={handleClose}
                  btnText={t("dialog.cancelBtn")}
                />
              </DialogActions>
            </>
          )}
          {ifEnable && (
            <div className="bottomSwitch">
              <div className="switchWithText">
                <div>{t("localFileUpload.turnOffThisFunction")}</div>
                <AntSwitch
                  checked={ifEnable}
                  disabled={loading || (task && task.status && (task.status !== "completed" && task.status !== "failed"))}
                  onChange={handleSwitchOff}
                  color="primary"
                />
              </div>
            </div>
          )}
        </>
      )}
    </Dialog>
  );
};

export default LocalFileUpload;
