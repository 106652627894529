import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiBreadcrumbs-root': {
            marginBottom: theme.spacing(3),
        },
        '& .MuiSvgIcon-root': {
            cursor: 'pointer !important'
        }
    },
    pageTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    reload_btn: {
        top: 10,
    },
    tableMoveTopReset: {
        paddingTop: theme.spacing(2),
        marginBottom: '0 !important',
        '& table tbody td .MuiSvgIcon-root': {
            marginRight: '8px !important',
        }
    },
    tableMoveBottomReset: {
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            padding: '12px 20px 0 !important',
        },
    },
    tableTitle: {
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        zIndex: '9 !important',
        width: 'max-content !important',
        '& svg': {
            marginBottom: '0 !important',
            top: '0 !important',
            marginLeft: '10px !important',
        }
    },
}));

export default useStyles;