import React, { useState, useContext, useEffect, useRef } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from "localforage";
import SelectionWithSelectAllCheckbox from "../commons/SelectionWithSelectAllCheckbox";
import { SubmitBtnContext } from "../../App";
import ConfirmAndCancelBtn from "../commons/ConfirmAndCancelBtn";
import { getSharedCompaniesAPI } from "../../apis/getSharedCompaniesAPI";
import { updateShareFileToCompaniesAPI } from "../../apis/updateShareFileToCompaniesAPI";

const ShareFileForm = (props) => {
  const mounted = useRef();
  const classes = useStyles();
  const { t } = useTranslation("nubisDrive");
  const {
    onClose,
    data,
    driveStorageId,
    localforageKeyName,
    reloadTable,
  } = props;
  const { dispatch } = useContext(SubmitBtnContext);
  const [tableDisabled, setTableDisabled] = useState(false);
  const [currentArr] = useState([]);

  const resetAllStoredValueInLocalforage = async () => {
    await localforage.removeItem(localforageKeyName);
    dispatch({ type: "stopLoading" });
  };

  const handleShareFileToCompanies = async () => {
    let companies = await localforage.getItem(localforageKeyName);
    if (!companies) {
      return;
    }
    dispatch({ type: "showLoading" });
    setTableDisabled(true);
    let companyIds = companies ? companies.map((o) => o.id) : [];
    let successfulText = `${t("dialog.successfullyShareFile")}`;
    await updateShareFileToCompaniesAPI(
      driveStorageId,
      companyIds,
      t,
      successfulText,
      reloadTable,
      onClose
    );
    setTableDisabled(false);
  };

  useEffect(() => {
    if (!mounted.current) {
      resetAllStoredValueInLocalforage();
      mounted.current = true;
    }
  });

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.uploadFileWrap}
        >
          <SelectionWithSelectAllCheckbox
            getDataFromAPI={getSharedCompaniesAPI}
            currentArr={currentArr}
            hasSelectAllOption={true}
            localforageKeyName={localforageKeyName}
            selectedCompaniesProvided={data}
            driveStorageId={driveStorageId}
          />
        </Grid>
      </Grid>
      <ConfirmAndCancelBtn
        styledSubmitBtnOnClick={handleShareFileToCompanies}
        cancelBtnDisabled={tableDisabled}
        cancelBtnOnClick={onClose}
      />
    </>
  );
};

export default ShareFileForm;
