import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

const getDevicesControlLogAPI = async (page = 1, record = 10) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');

    try {
        let response = await axios({
            url: `${url}/api/v2/command/task-logs`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                company_id: companyId,
                page,
                record
            }
        })
        const logs = response.data.data;

        return logs;

    } catch (error) {
        errorHandling(error, false);
    }
}

export default getDevicesControlLogAPI;
