import React, { useContext, useEffect, useRef } from "react";
import { Paper, Typography } from "@material-ui/core";
import ReactDOM from "react-dom";
import useStyles from "../../styles/dashboardStyle";
import { url } from "../../apis/apiSetup";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import "../../css/dataTable.css";
import * as localforage from "localforage";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-autofill";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-colreorder";
import "datatables.net-fixedcolumns";
import "datatables.net-fixedheader";
import "datatables.net-keytable";
import "datatables.net-responsive";
import "datatables.net-rowgroup";
import "datatables.net-rowreorder";
import "datatables.net-scroller";
import "datatables.net-searchpanes";
import "datatables.net-select";
import "jquery-datatables-checkboxes";
import "datetime-moment";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { searchStyleRender } from "../../helpers/datatableSearchStyleRender";
import { language } from "../../helpers/datatableLanguage";
import { utcStringToLocalDatetimeConverter } from "../../helpers/datetimeHandlers";
import StatusChip from "../commons/StatusChip";
import TaskStatusBar from "../commons/TaskStatusBar";

const TaskFailedDatatable = (props) => {
  const mounted = useRef();
  const classes = useStyles();
  const { t } = useTranslation("device");
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
  let onlineOffline = '';

  const table = async () => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    try {
    $("#task-failed-table").DataTable({
      serverSide: true,
      processing: true,
      language: language(t),
      searching: true,
      order: [[3, "desc"]],
      filter: false,
      info: true,
      autoWidth: false,
      stateSave: false,
      destroy: true,
      pageLength: 3,
      ordering: true,
      responsive: {
        details: {
          renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes(),
        },
      },
      ajax: {
        url: `${url}/api/v2/statistic/failed-task-datatable`,
        type: "GET",
        dataSrc: function(d){
            onlineOffline = d.online_offline_status;
            return d.data;
        },
        data: {
          company_id: companyId,
        },
        beforeSend: (request) => {
          request.setRequestHeader("Authorization", "Bearer " + token);
          request.setRequestHeader("Accept", "application/json");
        },
        error: (error) => {
          console.log(error);
        },
      },
      columns: [
        {
          data: "execute_type",
          className: "task_name_task",
          title: `${t("category.taskName")}`,
          createdCell: (td, data, rowData) => {
            ReactDOM.render(
              <>
                {data === "device_power" &&
                rowData.json &&
                rowData.json.system_booting_type ? (
                  <>
                    {t(`tasks.${data}`)} - [{" "}
                    {t(`tasks.${rowData.json.system_booting_type}`)} ]
                  </>
                ) : (data === "lock_usb" ||
                    data === "disable_combination_key" ||
                    data === "disable_windows_notify") &&
                  rowData.json ? (
                  t(`tasks.${data}_${rowData.json.status}`)
                ) : (
                  t(`tasks.${data}`)
                )}
              </>,
              td
                );
            },
            },
            {
            data: "device",
            name: "device.serial_number",
            title: `${t("category.serialNumber")}`,
            createdCell: (td, data, rowData) => {
                ReactDOM.render(
                <a className="serial_number_task" href={`device-list/device-detail/${data?.id}/${data?.os_type}`} rel="noopener noreferrer">
                    {data.serial_number}
                </a>,
                td
                );
            },
            },
            {
            data: "user",
            name: "user.name",
            title: `${t("category.user")}`,
            render: function(data, type, row, meta) {
                return data.name;
            },
            },
            {
            data: "executed_at",
            className: "date_task",
            title: `${t("category.date")}`,
            searchable: true,
            render: (data) => {
                return utcStringToLocalDatetimeConverter(data);
            },
            },
            {
            data: "status",
            title: `${t("category.status")}`,
            className: "status",
            createdCell: (td, data, rowData) => {
                ReactDOM.render(
                    <TaskStatusBar
                        status={data}
                        message={(rowData.response === '') ? null : rowData.response}
                        isOffline={onlineOffline !== 'online'}
                    />
                    ,td
                );
                },
            },
            ]
        });
    } catch (err) {
        throw new Error("Datatable have some problem.")
    }
    searchStyleRender();
  };

  useEffect(() => {
    if (!mounted.current) {
      table();
      mounted.current = true;
    }
  });

  return (
    <Paper className={classes.VNCAddressTable}>
      <Typography
        variant={cardTitleTypographyVariant}
        className={classes.cardTitleWrapWithReloadIcon}
      >
        {t("deviceDetail.taskFialedList")}
        <AutorenewRoundedIcon
          onClick={table}
          size="small"
          className={classes.reload_btn}
        />
      </Typography>
      <HOCforFullWidthTable>
        <table
          id="task-failed-table"
          className="mdl-data-table dataTable"
        ></table>
      </HOCforFullWidthTable>
    </Paper>
  );
};

export default TaskFailedDatatable;
