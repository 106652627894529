import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getSharedCompaniesAPI = async (driveStorageId) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');

    try {
        let response = await axios({
            url: `${url}/api/v2/drive/file/companies-available-share`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                company_id: companyId,
                drive_storage_id: driveStorageId
            }
        })

        return response.data.shared_file_to_companies;

    } catch (error) {
        errorHandling(error, true);
    }

}
