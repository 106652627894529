import * as localforage from 'localforage';

// get localforage key name
// it utilized any local db that related to device(s) pages
export const getDeviceKeyName = async (keyName, deviceId) => {
    return await localforage.getItem(`${keyName}-${deviceId}`);
}

export const setDeviceKeyName = async (keyName, deviceId, data) => {
    return await localforage.setItem(`${keyName}-${deviceId}`, data);
}

// color sets
export const chartColors = [
    '#1DBBD4',
    '#f3d067',
    '#E87A90',
    '#ff7d4e',
    '#7489ff',
    '#3b93ff',
    '#005CAF',
    '#c39584',
    '#eccb65',
    '#1cb3b3',
    '#5780dc',
    '#ac6eec',
    '#b899ef',
    '#60128b',
    '#ff8787',
    '#69ccd1',
    '#607d8b',
    '#ff9e40',
    '#4953c3',
    '#fb7ca6',
    '#859ef9',
    '#98827b',
    '#e9ec27',
    '#ff9db4',
    '#ff6ed9',
    '#93ded8',
];

// color sets
export const iconColors = [
    '#1DBBD4',
    '#f3d067',
    '#ff7d4e',
    '#6f7071',
    '#9e9e9e',
    '#aaa',
    '#23a1b5',
    '#40ca46',
    '#f95b91',
    '#2196F3',
    '#f7bd11',
];

