import React, { useEffect, useState } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Grid,
    FormControl,
    Input,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import Lightbox from "react-awesome-lightbox";
import "react-awesome-lightbox/build/style.css";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import GetAppIcon from "@material-ui/icons/GetApp";
import {
    screenshotsSendApi,
    getScreenshotsApi,
} from "../../apis/deviceControlApi";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { utcFormatToLocalDateTimeWithSecond } from "../../helpers/datetimeHandlers";
import fuzzysort from "fuzzysort";
import SearchIcon from "@material-ui/icons/Search";
import HasPermission from "../commons/HasPermission";
const zip = require("jszip")();

const ScreenshotDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const {
        open,
        onClose,
        selectDevices,
        selectDevice,
        numType,
        groupId,
        tagId,
        selectCompany,
        selectType,
        checkAll,
    } = props;
    const [viewer, setViewer] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [successCount, setSuccessCount] = useState(0);
    const [viewerUrl, setViewerUrl] = useState("");
    const [viewerTitle, setViewerTitle] = useState("");
    const [getFailed, setGetFailed] = useState(false);
    const [downLoadLoading, setDownLoadLoading] = useState(false);
    const [list, setList] = useState([]);
    const [searchList, setSearchList] = useState([]);
    const [device, setDevice] = useState({});
    const [refresh, setRefresh] = useState(false);
    const [times, setTimes] = useState(0);
    const [type, setType] = useState("");
    const [selectData, setSelectData] = useState();

    useEffect(() => {
        let id = setInterval(async () => {
            setTimes(times + 1, selectData);
            await getImageList(type);
        }, 3000);
        if (times === 10) {
            setTimes(0);
            setLoading(false);
            setRefresh(false);
            setSelectData();
            setType("");
            setGetFailed(true);
            clearInterval(id);
            return;
        }
        if (!refresh || !open) {
            setTimes(0);
            setRefresh(false);
            setSelectData();
            setType("");
            clearInterval(id);
            return;
        }
        return () => clearInterval(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [times, refresh, open]);

    const viewerHandler = (data) => {
        setViewer(true);
        setViewerTitle(data?.device?.serial_number);
        setViewerUrl(data.viewerUrl);
    };

    const closeViewer = () => {
        setViewer(false);
    };

    const getImageList = async () => {
        if (type === "mutiple") {
            let data = await getScreenshotsApi(selectData, undefined);
            setLoading(false);
            if (data && data.screenshots) {
                setSuccessCount(data.screenshots.filter((e) => e.viewerUrl).length);
                setList(data.screenshots);
                if (data.screenshots.filter((e) => !e.viewerUrl).length === 0) {
                    setRefresh(false);
                }
            }
        } else {
            let data = await getScreenshotsApi(undefined, selectData);
            setLoading(false);
            if (data && data.screenshots) {
                setDevice(data.screenshots);
            }
            if (data.screenshots.viewerUrl) {
                setRefresh(false);
                setSuccessCount(1);
            }
        }
    };

    const sendCommandScreenshot = async () => {
        setGetFailed(false);
        setLoading(true);
        setSuccessCount(0);
        setDevice({});
        setList([]);
        let data = await screenshotsSendApi(
            selectType,
            selectCompany,
            groupId,
            tagId,
            checkAll
                ? undefined
                : numType === "single"
                    ? [selectDevice]
                    : selectDevices
        );
        if (data && data.message === "success" && data.identity_code) {
            setSelectData(data.identity_code);
            setType("mutiple");
            setRefresh(true);
        } else if (data && data.message === "success" && !data.identity_code) {
            setSelectData(selectDevice?.id);
            setType("single");
            setRefresh(true);
        } else {
            setType("error");
            setLoading(false);
        }
    };

    const handlerSearch = (e) => {
        const result = fuzzysort.go(e, list, { key: "device.serial_number" });
        setSearchValue(e);
        setSearchList(result);
        setSuccessCount(list.filter((a) => a.viewerUrl).length);
    };

    const toDataURL = async (data) => {
        let dateTime = utcFormatToLocalDateTimeWithSecond(new Date());
        zip.remove("screenshots");
        for (let i = 0; i < data.length; i++) {
            let response = await fetch(data[i].viewerUrl);
            let blob = await response.blob();
            zip
                .folder("screenshots")
                .file(`${data[i]?.device?.serial_number}.png`, blob);
        }
        zip.generateAsync({ type: "blob" }).then((content) => {
            const aLink = document.createElement("a");
            aLink.download = `screenshots-${dateTime}.zip`;
            aLink.href = URL.createObjectURL(content);
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
            setDownLoadLoading(false);
        });
    };

    const download = async (data) => {
        if (downLoadLoading) {
            return;
        }
        setDownLoadLoading(true);
        if (data?.viewerUrl) {
            let dateTime = utcFormatToLocalDateTimeWithSecond(new Date());
            setDownLoadLoading(true);
            let response = await fetch(data.viewerUrl);
            let blob = await response.blob();
            const aLink = document.createElement("a");
            aLink.download = `${data?.device?.serial_number}-${dateTime}.png`;
            aLink.href = URL.createObjectURL(blob);
            document.body.appendChild(aLink);
            aLink.click();
            document.body.removeChild(aLink);
            setDownLoadLoading(false);
        }
    };

    const downloadAll = async () => {
        if (successCount === 0 || downLoadLoading) {
            return;
        }
        setDownLoadLoading(true);
        if (device && device.viewerUrl) {
            download(device);
        } else {
            let a = [];
            list.filter((e) => e.viewerUrl && a.push(e));
            toDataURL(a);
        }
    };

    useEffect(() => {
        if (open) {
            setList([]);
            setSelectData();
            setType("");
            setSearchList([]);
            setSearchValue("");
            setDownLoadLoading(false);
            setSuccessCount(0);
            setDevice({});
            sendCommandScreenshot();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <>
            <Dialog
                maxWidth="lg"
                className={classes.dialog}
                open={open}
                onClose={onClose}
                aria-labelledby="screenshot-dialog"
                aria-describedby="screenshot-dialog"
            >
                <DialogTitle id="screenshot-dialog">
                    <div className={classes.screenshotDialogTitle}>
                        {t(`common.screenshot`)}
                        {selectDevices &&
                            (selectDevices.length > 1 || selectDevices[0] === "all") ? (
                            <FormControl
                                fullWidth
                                className={classes.margin}
                                onChange={(e) => handlerSearch(e.target.value)}
                                variant="outlined"
                                disabled={loading}
                                value={searchValue}
                            >
                                <Input startAdornment={<SearchIcon />} />
                            </FormControl>
                        ) : (
                            ""
                        )}
                    </div>
                </DialogTitle>
                <>
                    <DialogContent className={classes.screenshotDialogArea}>
                        {loading ? (
                            <SmallerLoadingIndicator />
                        ) : (
                            <>
                                <HasPermission permission="multiple_screenshot_download">
                                    <div
                                        className={`screenShotDownload ${(downLoadLoading ||
                                            successCount === 0) &&
                                            "disabled"}`}
                                        onClick={downloadAll}
                                    >
                                        <GetAppIcon />
                                        {downLoadLoading
                                            ? "Downloading..."
                                            : `${t(
                                                "common.downloadScreenshots"
                                            )} ( ${successCount} )`}
                                    </div>
                                </HasPermission>
                                <Grid container>
                                    {searchValue
                                        ? searchList &&
                                        searchList.length > 0 &&
                                        searchList.map((e) => (
                                            <Grid
                                                item
                                                key={e?.obj?.id}
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                className={classes.screenshotDialogOuter}
                                            >
                                                <div className={classes.screenshotDialogBlock}>
                                                    <div className={classes.screenshotDialogName}>
                                                        {e?.obj.device && e?.obj?.device?.serial_number}
                                                    </div>
                                                    <div className={classes.screenshotDialogImg}>
                                                        {e?.obj?.viewerUrl ? (
                                                            <>
                                                                <img src={e.obj.viewerUrl} alt="pic" />

                                                                <div className="screenshotDialogInner">
                                                                    <ZoomInIcon
                                                                        onClick={() => viewerHandler(e?.obj)}
                                                                    />
                                                                    <HasPermission permission="multiple_screenshot_download">
                                                                        <GetAppIcon
                                                                            onClick={() => download(e?.obj)}
                                                                        />
                                                                    </HasPermission>
                                                                </div>
                                                            </>
                                                        ) : getFailed ? (
                                                            <i style={{ color: "#aaa" }}>
                                                                {t("dialog.screenshotFailed")}
                                                            </i>
                                                        ) : (
                                                            <SmallerLoadingIndicator />
                                                        )}
                                                    </div>
                                                </div>
                                            </Grid>
                                        ))
                                        : list &&
                                        list.length > 0 &&
                                        list.map((e) => (
                                            <Grid
                                                item
                                                key={e.id}
                                                xs={12}
                                                sm={6}
                                                md={4}
                                                lg={3}
                                                className={classes.screenshotDialogOuter}
                                            >
                                                <div className={classes.screenshotDialogBlock}>
                                                    <div className={classes.screenshotDialogName}>
                                                        {e.device && e.device.serial_number}
                                                    </div>
                                                    <div className={classes.screenshotDialogImg}>
                                                        {e.viewerUrl ? (
                                                            <>
                                                                <img src={e.viewerUrl} alt="pic" />

                                                                <div className="screenshotDialogInner">
                                                                    <ZoomInIcon
                                                                        onClick={() => viewerHandler(e)}
                                                                    />
                                                                    <HasPermission permission="multiple_screenshot_download">
                                                                        <GetAppIcon onClick={() => download(e)} />
                                                                    </HasPermission>
                                                                </div>
                                                            </>
                                                        ) : getFailed ? (
                                                            <i style={{ color: "#aaa" }}>
                                                                {t("dialog.screenshotFailed")}
                                                            </i>
                                                        ) : (
                                                            <SmallerLoadingIndicator />
                                                        )}
                                                    </div>
                                                </div>
                                            </Grid>
                                        ))}
                                </Grid>
                                {searchValue && !searchList.length > 0 && (
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "50px",
                                        }}
                                    >
                                        {t("dialog.noData")}
                                    </div>
                                )}
                                {device && device.id && (
                                    <Grid
                                        item
                                        xs={12}
                                        sm={6}
                                        md={4}
                                        lg={3}
                                        className={classes.screenshotDialogOuter}
                                    >
                                        <div className={classes.screenshotDialogBlock}>
                                            <div className={classes.screenshotDialogName}>
                                                {device && device.device && device.device.serial_number}
                                            </div>
                                            <div className={classes.screenshotDialogImg}>
                                                {device.viewerUrl ? (
                                                    <>
                                                        <img src={device.viewerUrl} alt="pic" />
                                                        <div className="screenshotDialogInner">
                                                            <ZoomInIcon
                                                                onClick={() => viewerHandler(device)}
                                                            />
                                                            <HasPermission permission="multiple_screenshot_download">
                                                                <GetAppIcon onClick={() => download(device)} />
                                                            </HasPermission>
                                                        </div>
                                                    </>
                                                ) : getFailed ? (
                                                    <i style={{ color: "#aaa" }}>
                                                        {" "}
                                                        {t("dialog.screenshotFailed")}
                                                    </i>
                                                ) : (
                                                    <SmallerLoadingIndicator />
                                                )}
                                            </div>
                                        </div>
                                    </Grid>
                                )}
                            </>
                        )}
                    </DialogContent>
                </>
            </Dialog>
            {viewer && (
                <Lightbox image={viewerUrl} title={viewerTitle} onClose={closeViewer} />
            )}
        </>
    );
};

export default ScreenshotDialog;
