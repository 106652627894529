import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import AddOrEditUtilityManagementForm from './AddOrEditUtilityManagementForm';
import { filterdArr } from '../../helpers/arrayHandlers';

const UtilityManagementDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('adminUtility');
    const { onClose, open, fwData } = props;
    const [fwId, setFwId] = useState();
    const [modelIdArr, setModelIdArr] = useState([]);
    const [companyIdArr, setCompanyIdArr] = useState([]);
    const [utilityIdArr, setUtilityIdArr] = useState([]);
    const [createUtility, setCreateUtility] = useState(false);

    useEffect(() => {
        // update FW utility
        if (fwData && fwData.utility_company_model_firmware_maps) {
            const dataArray = fwData.utility_company_model_firmware_maps
            const fwId = fwData.id
            setFwId(fwId)
            const allModelIds = dataArray.map(o => {
                if (o.device_model) return o.device_model.id;
                return false;
            })
            const uniqueModelIdsArr = filterdArr(allModelIds)
            setModelIdArr(uniqueModelIdsArr)
            const allCompanyIds = dataArray.map(o => {
                if (o.company) return o.company.id;
                return false;
            })
            const uniqueCompanyIdsArr = filterdArr(allCompanyIds)
            setCompanyIdArr(uniqueCompanyIdsArr)
            const allUilityIds = dataArray.map(o => {
                if (o.firmware) return o.firmware.id;
                return false;
            })
            const uniqueUtilitiesArr = filterdArr(allUilityIds)
            setUtilityIdArr(uniqueUtilitiesArr)
            setCreateUtility(false)
        }
        // create FW utility
        if (!fwData) {
            setModelIdArr([])
            setCompanyIdArr([])
            setUtilityIdArr([])
            setCreateUtility(true)
        }
    }, [fwData])

    return (
        <Dialog
            maxWidth="lg"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-utility-management"
            aria-describedby="add-edit-utility-management-confirmation"
        >
            <DialogTitle id="add-edit-utility-management">
                {fwData ?
                    `${t('dialog.editUtilityManagement')}`
                    :
                    `${t('dialog.addUtilityManagement')}`
                }
            </DialogTitle>
            <DialogContent>
                <AddOrEditUtilityManagementForm
                    open={open}
                    modelIdArr={modelIdArr}
                    companyIdArr={companyIdArr}
                    utilityIdArr={utilityIdArr}
                    onClose={onClose}
                    fwId={createUtility ? undefined : fwId}
                    createUtility={createUtility} />
            </DialogContent>
        </Dialog>
    )
}

export default UtilityManagementDialog;
