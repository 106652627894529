import React, { forwardRef, useState, useEffect, useRef } from 'react';
import useStyles from '../../styles/deviceManagementFormStyle';
import { MenuItem, FormControl, Select } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { getPeripheralTypesAPI } from '../../apis/getPeripheralTypesAPI';
import useStateOnMounted from '../../helpers/useStateOnMounted';

const PeripheralTypeSelect = forwardRef((props, ref) => {
    const mounted = useRef();
    const { t } = useTranslation('adminUtility');
    const { storedPeripheralTypeToLocalforageName, tableDisabled, fwTypeId } = props;
    const [allTypes, setAllTypes] = useStateOnMounted([])
    const [type, setType] = useState(fwTypeId ? fwTypeId : '');
    const classes = useStyles();
    const handleChange = async (event) => {
        const value = event.target.value
        setType(value);
        storedPeripheralTypeToLocalforage(value)
    };

    const getPeripheralTypes = async () => {
        const peripheralTypesData = await getPeripheralTypesAPI()
        if (peripheralTypesData) {
            const peripheralTypesIdAndName = peripheralTypesData.map(o => {
                return { id: o.id, name: o.name }
            })
            setAllTypes(peripheralTypesIdAndName)
        }
        await storedPeripheralTypeToLocalforage(fwTypeId)
    }

    const storedPeripheralTypeToLocalforage = async (value) => {
        await localforage.setItem(storedPeripheralTypeToLocalforageName, value)
    }

    useEffect(() => {
        if (!mounted.current) {
            getPeripheralTypes()
            mounted.current = true;
        }
    })


    return (
        <FormControl variant="outlined" ref={ref} className={classes.fileSelectDropDown}>
            <Select
                ref={ref}
                value={type}
                onChange={handleChange}
                label="Type"
                displayEmpty
                disabled={tableDisabled}
                MenuProps={{ disableScrollLock: true }}
            >
                <MenuItem value="">{t('dialog.selectPeripheralType')}</MenuItem>
                {allTypes.map(o => {
                    return <MenuItem key={o.id} value={o.id}>{o.name}</MenuItem>
                })}
            </Select>
        </FormControl>
    )
})

export default PeripheralTypeSelect;
