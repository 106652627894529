import React, { useState, useEffect } from 'react';
import { MenuItem, Select, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import { useTranslation } from "../../langs/useTranslation";
import { getIOPortsAPI } from '../../apis/getIOPortsAPI';

const SelectStatusOrVoltage = (props) => {
    const classes = useStyles();
    const { currentPort, formikName, formikValue, formikOnChange, formikOnBlur, formikError, singleDevice, allIOPortsArrFromAPI } = props;
    const { t } = useTranslation('task');
    const [IOVoltageArr, setIOVoltageArr] = useState([]);

    // for multi-device task
    // get voltage values for current port from API
    const getIOVoltagesValue = async() => {
        const allIOportsArr = await getIOPortsAPI();
        if(allIOportsArr){
            handleIOVoltageArrForSelector(allIOportsArr);
        } 
    };

    // for multi-device task
    // set up the voltage selection menu from all IO ports array, 'allIOportsArr'
    const handleIOVoltageArrForSelector = (allIOportsArr) => {
        const currentIOPortsArr = allIOportsArr.filter((port) => port.name === currentPort);
        if(currentIOPortsArr){
            const currentVoltageJsonArr = currentIOPortsArr[0] ? currentIOPortsArr[0].json : [];
            setIOVoltageArr(currentVoltageJsonArr);
        }
    }

    useEffect(()=>{
        if(singleDevice) {
            setIOVoltageArr(allIOPortsArrFromAPI);
        }
        if(!singleDevice) {
            if(currentPort) getIOVoltagesValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentPort]);
   
    return(
        <> 
             <Select
                variant="outlined"
                defaultValue={singleDevice ? '' : null} // default value is only for single-device task usage
                value={ singleDevice ? formikValue : (IOVoltageArr.includes(formikValue) ? formikValue : '' )} // check if the voltage value send from 'Multi-device Task' exists in the voltage array based on the selected port 
                onChange={formikOnChange}
                onBlur={formikOnBlur}
                displayEmpty
                className={classes.fileSelectDropDown}
                name={formikName}
                MenuProps={{ disableScrollLock: true }}
            >
                <MenuItem value="">{t('task.selectStatusDefaultValue')}</MenuItem>
                {IOVoltageArr.map((voltage, index)=>{
                    if(voltage !== '') return <MenuItem key={index} value={voltage}>{t(`ioVoltages.${voltage}`)}</MenuItem>
                    return false;
                })}
            </Select> 
            <FormHelperText hidden={!formikError} error={!!formikError}>
                {formikError}
            </FormHelperText>
        </>    
    )
}

export default SelectStatusOrVoltage;