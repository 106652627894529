import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { sortArrByName } from '../helpers/arrayHandlers';

export const authLogin = async (ticket) => {

    try {
        let response = await axios({
            url: `${url}/api/v2/member/verify`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
            },
            params: {
                ticket
            }
        })

        let loginData = response.data;
        console.log(loginData)

        if(loginData){
            await localforage.setItem('user', response.data.user);
            await localforage.setItem('token', response.data.token);
            await localforage.setItem('expiredAt', response.data.expires_at);
            await localforage.setItem('companyId', response.data.user.company_id);
            const companyArr = response.data.user.company.company_groups;
            const companyGroupsArr = companyArr.map(o => ({ 'id': o.id, 'name': o.name }));
            const sortedCompanyGroupsArr = sortArrByName(companyGroupsArr);
            await localforage.setItem('companyGroups', sortedCompanyGroupsArr);
            //store user permissions name array
            const userPermissionsFromResponse = response.data.user.roles[0].permissions
            if (userPermissionsFromResponse) {
                const userPermissionsNameArray = userPermissionsFromResponse.map((userPermissionFromResponse) => {
                    return userPermissionFromResponse.name
                })
                await localforage.setItem('userPermissions', userPermissionsNameArray);
            }
            //window.location.replace('/');
            return true;
        } else {
            return false;
        }
    } catch (error) {
        errorHandling(error, false);
    }
}
