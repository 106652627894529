import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";

export const createAppApi = async (
    os_type,
    name,
    version,
    s3_path,
    md5,
    description,
    install_script,
    uninstall_script,
    t,
    successText,
    setLoading
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/application/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                os_type,
                name,
                version,
                s3_path,
                md5,
                description,
                install_script,
                uninstall_script,
            },
        });
        successHandling(t, successText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
        setLoading(false);
    }
};

export const deleteAppApi = async (application_ids, t, successfulText) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/application/delete`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                application_ids,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const deleteVersionApi = async (
    application_id,
    application_version_ids,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/application/delete-version`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                application_id,
                application_version_ids,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const appVersionDetailApi = async (application_id) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/application/versions`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                application_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const createAppVersionApi = async (
    application_id,
    version,
    s3_path,
    md5,
    description,
    install_script,
    uninstall_script,
    t,
    successText,
    setLoading
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/application/version/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                application_id,
                version,
                s3_path,
                md5,
                description,
                install_script,
                uninstall_script,
            },
        });
        successHandling(t, successText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
        setLoading(false);
    }
};

export const setVersionApi = async (
    application_version_id,
    is_publish,
    group_ids,
    t,
    successText
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/application/set-version-publish`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                execute_type: "install_application",
                application_version_id,
                is_publish,
                group_ids,
            },
        });
        if (t && successText) {
            successHandling(t, successText);
        }
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getGroupListApi = async () => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                group_category_name: "app_update_service",
            },
        });
        return res.data.groups;
    } catch (error) {
        errorHandling(error, false);
    }
};
