import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";

const StopAppDialog = (props) => {
  const classes = useStyles();
  const { open, onClose, onConfirm, loading, stopDetail } = props;
  const { t } = useTranslation("device");
  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle id="add-edit-alarm-notification">
        {t("appRunningStatus.stopApplication")}
      </DialogTitle>
      <>
        <DialogContent>
          <Typography variant="body1">
            {t("appRunningStatus.confirmStopApp")}{" "}
            {stopDetail && stopDetail.name ? stopDetail.name : " - "} ?
          </Typography>
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            btnColor="primary"
            disabled={loading}
            type="button"
            btnText={t("dialog.confirmBtn")}
            onClick={() => onConfirm()}
          />
          <BackBtn onClick={onClose} btnText={t("dialog.cancelBtn")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default StopAppDialog;
