export const drawerNubisDocListItems = [
    {
        permission: "nubis_docs_user_manual_page",
        link: "/nubis-docs/users-manual",
        text: 'usersManual',
        disabled: true
    },
    {
        permission: "nubis_docs_release_note_page",
        link: "/nubis-docs/release-note",
        text: 'releaseNote',
        disabled: true
    },
    {
        permission: "nubis_docs_qa_page",
        link: "/nubis-docs/q-a",
        text: 'qa',
        disabled: true
    },

]

export const drawerNubisDriveListItems = [
    {
        permission: "nubis_storage_admin_utility_page",
        link: "/nubis-drive/admin-utility",
        text: 'adminUtility',
        disabled: false
    },
    {
        permission: "nubis_storage_file_browser_page",
        link: "/nubis-drive/file-browser",
        text: 'fileBrowser',
        disabled: false
    },
    {
        permission: "nubis_storage_file_browser_page",
        link: "/nubis-drive/shared-with-me",
        text: 'sharedWithMe',
        disabled: false
    },

]
export const drawerGroupsListItems = [
    {
        permission: "groups_page",
        link: "/groups/groups-devices",
        text: 'groups',
        disabled: false
    },
    {
        permission: "tag_page",
        link: "/groups/tags",
        text: 'tags',
        disabled: false
    },

]
