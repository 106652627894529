import { alpha, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grow: {
      flexGrow: 1,
      width: '100%',
      '& header': {
          zIndex: '999',
      },
      '& #user-appbar-button': {
        borderRadius: 13,
      }
    },
    rightSection:{
      display: 'flex',
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: '180px',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
      },
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      right: 10,
      cursor: 'pointer',
      zIndex: '1000'
    },
    inputRoot: {
      color: 'inherit',
      position: 'absolute',
      width: '90% !important',
      '& .MuiInputBase-input': {
        paddingRight: '50px !important',
        width: '110px !important'
      }
    },
    inputInput: {
      padding: theme.spacing(1, 1, 1, 0),
      paddingLeft: '1em',
      transition: theme.transitions.create('width'),
      border: '1px solid #ddd',
      borderRadius: 4,
      top: 6,
      position: 'absolute',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        width: '20ch',
      },
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    menuList: {
      top: '40px !important',
      overflow: 'initial',
    },
    langSelect: {
      position: 'relative',
      width: '48px !important',
      overflow: 'hidden',
      '& .MuiSelect-select': {
        position: 'relative',
        width: '50px !important',
        padding: 0,
        overflow: 'hidden',
        minHeight: '0 !important',
        height: '0 !important',
      },
      '& .MuiSelect-icon': {
        top: '-27px !important',
        transform: 'scale(0.8)',
      },
      '& .MuiInput-underline:before': {
        display: 'none',
      },
      '& .MuiInput-underline:after': {
        display: 'none',
      },
    },
    langBtn: {
      position: 'absolute',
      width: 48,
      '& .MuiIconButton-label': {
        marginLeft: -3
      }
    },
    langBtnText: {
      width: 25,
      position: 'relative',
      left: -4,
      '& svg': {
        position: 'absolute',
        top: 2,
      },
    },
    failedChip: {
      background: '#ff9e4014',
      color: '#ff9e40',
    },
    scheduledChip: {
      background: '#2196f312',
      color: '#2196F3',
    },
    initialHeightPopOver: {
      height: 'initial !important',
    },
    usernameText: {
      paddingLeft: '10px !important',
      textTransform: 'uppercase',
    }
}))

export default useStyles;