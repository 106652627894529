import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Box,
} from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import { getComponentsInfoAPI } from "../../apis/getDeviceInfoAPI";
import { updateComponentsAPI } from "../../apis/updateComponentsAPI";
import { useParams } from "react-router-dom";
import { StyledSubmitBtn } from "../commons/FormBtns";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import { SubmitBtnContext } from "../../App";
import { successHandling } from "../../helpers/successHandling";
import LoadingIndicator from "../commons/LoadingIndicator";
import { NoDataText } from "../commons/ValidationText";
import HasPermission from "../commons/HasPermission";

const ComponentsInfo = (props) => {
  const mounted = useRef();
  const classes = useStyles();
  const { device_id } = useParams();
  const { t } = useTranslation("device");
  const { dispatch } = useContext(SubmitBtnContext);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const [componentIDsAndRemarks, setComponentIDsAndRemarks] = useState([]);

  const getStoredInfo = async () => {
    const gotInfo = await getComponentsInfoAPI(device_id);
    setInfo(gotInfo);
    const componentIDsAndRemarks = gotInfo.map((info) => {
      return { id: info.id, remark: info.remark ? info.remark : "" };
    });
    setComponentIDsAndRemarks(componentIDsAndRemarks);
    setLoading(false);
  };

  const handleChange = (e) => {
    const newUpdatedComponent = { id: e.target.id, remark: e.target.value };
    const updatedIndex = componentIDsAndRemarks
      .map((component) => {
        return component.id;
      })
      .indexOf(e.target.id);
    if (updatedIndex || updatedIndex === 0) {
      componentIDsAndRemarks.splice(updatedIndex, 1, newUpdatedComponent);
      setComponentIDsAndRemarks(componentIDsAndRemarks);
    }
  };

  const handleUpdateComponents = async () => {
    dispatch({ type: "showLoading" });
    const updateComponentsResult = await updateComponentsAPI(
      device_id,
      componentIDsAndRemarks
    );
    if (updateComponentsResult) {
      const successfulText = `${t(
        "componentsInformation.successfullyUpdateComponent"
      )}`;
      successHandling(t, successfulText);
    }
    dispatch({ type: "stopLoading" });
  };

  useEffect(() => {
    if (!mounted.current) {
      getStoredInfo();
      mounted.current = true;
    }
  });

  return (
    <div>
      {loading ? (
        <div className="smaller-loading-indicator">
          <LoadingIndicator />
        </div>
      ) : info.length > 0 ? (
        <>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell component="th" scope="row">
                    {t("componentsInformation.name")}
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {t("componentsInformation.serialNumber")}
                  </TableCell>
                  <HasPermission permission="single_components_swap">
                    <TableCell component="th" scope="row">
                      {t("componentsInformation.componentSwap")}
                    </TableCell>
                  </HasPermission>
                </TableRow>
              </TableHead>
              <TableBody>
                {info.map((obj, ArrayIndex) => {
                  return (
                    <TableRow key={ArrayIndex}>
                      <TableCell>{obj.name || "-"}</TableCell>
                      <TableCell>{obj.serial_number || "-"}</TableCell>
                      <HasPermission permission="single_components_swap">
                        <TableCell>
                          <TextField
                            id={obj.id}
                            placeholder={obj.remark}
                            onChange={handleChange}
                          />
                        </TableCell>
                      </HasPermission>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <HasPermission permission="single_components_swap">
            <div className={classes.centerButtonWrap}>
              <StyledSubmitBtn
                startIcon={<SendRoundedIcon />}
                onClick={handleUpdateComponents}
                btnText={t("btn.confirmBtn")}
                type="submit"
              />
            </div>
          </HasPermission>
        </>
      ) : (
        <Box className="noDataTextBox">
          <NoDataText />
        </Box>
      )}
    </div>
  );
};

export default ComponentsInfo;
