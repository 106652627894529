import React, { useEffect, useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { getDriveStorageFilesAPI } from "../../apis/getDriveStorageFilesAPI";
import reject from "lodash/reject";
import { ReactComponent as FolderImg } from "../../assets/images/folder.svg";
import { ReactComponent as FileImg } from "../../assets/images/file.svg";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { grab } from "../../helpers/grab";

const SelectFileFromNubisDrive = (props) => {
    const { t } = useTranslation("task");
    const {
        open,
        tableDisabled,
        setDriveStorageId,
        setNubisDriveFile,
        driveStorageId,
    } = props;
    const [loading, setLoading] = useState(false);
    const [pages, setPages] = useState(1);
    const [parentId, setParentId] = useState();
    const [options, setOptions] = useState([]);
    const [breadcrumbs, setBreadcrumbs] = useState([]);
    const record = 20;

    const addOptions = () => {
        setTimeout(() => {
            if (options.some((e) => e.id === "nomore")) {
                return;
            }
            getOptions(pages, parentId);
        }, 500);
    };

    useEffect(() => {
        if (open) {
            setBreadcrumbs([]);
            setLoading(true);
            setPages(1);
            setOptions([]);
            setParentId();
            getOptions(pages);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const clickBread = (id, i) => {
        setLoading(true);
        const result = breadcrumbs.slice(0, i + 1);
        setPages(1);
        setNubisDriveFile({});
        setDriveStorageId(false);
        if (!id) {
            setBreadcrumbs([]);
            setParentId();
            getOptions(1, undefined, true);
        } else {
            setBreadcrumbs(result);
            setParentId(id);
            getOptions(1, id, true);
        }
    };

    const handleChange = async (e, { value }) => {
        // Prevents React from resetting its properties:
        // ref: https://reactjs.org/docs/legacy-event-pooling.html
        e.persist();
        if (e) {
            if (options.find((a) => a.id === value).type === "folder") {
                // folder
                setLoading(true);
                setNubisDriveFile({});
                setDriveStorageId(false);
                setBreadcrumbs((prev) => [
                    ...prev,
                    { parent: value, name: options.find((a) => a.id === value).name },
                ]);
                setParentId(value);
                await getOptions(1, value, true);
                grab("select-breadcrumbs");
            } else {
                // file
                setNubisDriveFile({ id: value, name: e.target.innerText });
                setDriveStorageId(value);
            }
        }
    };

    const handleScroll = () => {
        let element = document.querySelector(".visible.menu.transition");

        if (element) {
            const scrollTop = element.scrollTop;
            const elementHeight = element.clientHeight;
            const heightOfList = element.scrollHeight;
            const ifReachListBottom =
                heightOfList - Math.ceil(scrollTop) <= elementHeight;
            if (ifReachListBottom) {
                addOptions();
            }
        }
    };

    const getOptions = async (page, parent, folder = false) => {
        const response = await getDriveStorageFilesAPI(
            "sort",
            "asc",
            page,
            record,
            parent
        );
        const files = response.files;
        if (files) {
            let allOptions;
            if (folder) {
                setPages(1);
                setOptions([]);
                allOptions = reject([].concat(files), ["id", "loading"]);
            } else {
                allOptions = reject(options.concat(files), ["id", "loading"]);
            }
            if (!response.should_scroll) {
                if (options.some((e) => e.id === "nomore")) {
                    setOptions([...allOptions]);
                    setLoading(false);
                    return;
                } else {
                    const p = page + 1;
                    setPages(p);
                    setOptions([
                        ...allOptions,
                        { id: "nomore", name: "No More Options", disabled: true },
                    ]);
                    setLoading(false);
                }
            } else {
                const p = page + 1;
                setPages(p);
                setOptions([
                    ...allOptions,
                    { id: "loading", name: "Loading...", disabled: true },
                ]);
                setLoading(false);
            }
        } else {
            setOptions([]);
            setLoading(false);
        }
    };

    return (
        <div className="needDefault">
            {breadcrumbs.length > 0 && (
                <div id="select-breadcrumbs" className="select-breadcrumbs">
                    <div
                        className="select-breadcrumb-root"
                        onClick={() => clickBread(undefined, 0)}
                    >
                        /
                    </div>
                    {breadcrumbs.map((e, i) => (
                        <div className="select-breadcrumb-inner" key={i}>
                            <NavigateNextIcon />
                            {e.parent === parentId ? (
                                <div className="select-breadcrumb-active">{e.name}</div>
                            ) : (
                                <div
                                    className="select-breadcrumb"
                                    onClick={() => clickBread(e.parent, i)}
                                >
                                    {e.name}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            )}
            <Dropdown
                placeholder={t("task.selectFileDefaultValue")}
                fluid
                search
                selection
                value={driveStorageId}
                lazyLoad
                selectOnBlur={false}
                disabled={tableDisabled || loading}
                onChange={handleChange}
                onScroll={handleScroll}
                options={options.map((o, i) => {
                    return {
                        key: i,
                        value: o.id,
                        text: o.name,
                        label:
                            o.type === "folder" ? (
                                <FolderImg />
                            ) : o.type === "file" ? (
                                <FileImg />
                            ) : (
                                undefined
                            ),
                        disabled: o.disabled,
                    };
                })}
            />
        </div>
    );
};

export default SelectFileFromNubisDrive;
