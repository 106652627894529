import React from 'react';
import ReactDOM from 'react-dom';
import { url } from '../apis/apiSetup';
import '../css/dataTable.css';
import * as $ from 'jquery';
import 'datatables.net';
import * as localforage from 'localforage';
import { language } from './datatableLanguage';
import StatusChip from '../components/commons/StatusChip';
import { searchStyleRender } from './datatableSearchStyleRender';
import { resendAlarmPolicyAPI } from '../apis/resendAlarmPolicyAPI';

export const table = async (id, t) => {

    const token = await localforage.getItem('token');
    $.fn.dataTable.ext.errMode = 'throw';

    $('#alarm-policy-deploy-table').DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[0, 'desc']],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: true,
        paging: true,
        ajax: {
            url: `${url}/api/v2/alarm/policy/devices`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                id: id
            },
            beforeSend: request => {
                request.setRequestHeader('Authorization', 'Bearer ' + token);
                request.setRequestHeader('Accept', 'application/json');
            },
        },
        columns: [
            {
                data: 'device_id',
                title: `${t('table.serialNumber')}`,
                render: (data, type, rowData) => {
                    return data ? rowData.device.serial_number : '-';
                }
            },
            {
                data: 'device_id',
                title: `${t('table.onlineStatus')}`,
                createdCell: (td, data, rowData) => {
                    const status = rowData.device.online_offline_status;
                    ReactDOM.render(<StatusChip statusLabel={status.toLowerCase()} />, td);
                }
            },
            {
                data: 'status',
                title: `${t('table.status')}`,
                createdCell: (td, data) => {
                    ReactDOM.render(<StatusChip statusLabel={data} />, td);
                }
            },
            {
                data: 'status',
                title: `${t('table.action')}`,
                className: 'resend-btn-td',
                orderDataType: 'dom-text',
                createdCell: (td, data, rowData) => {

                    const alarmPolicyId = rowData.alarm_policy_id;
                    const deviceId = rowData.device_id;

                    if (data.toLowerCase() === 'failed') {
                        ReactDOM.render(
                            <span onClick={(e) => handleResendDialogOpen(e, alarmPolicyId, deviceId, id, t)}>{t('btn.resend')}</span>
                            , td)
                    } else {
                        ReactDOM.render('-', td);
                    }

                }
            }
        ]
    })
    searchStyleRender();
}

const handleResendDialogOpen = async (e, alarmPolicyId, deviceId, id, t) => {
    if (!e.detail || e.detail === 1) {
        let result = await resendAlarmPolicyAPI(alarmPolicyId, deviceId, t);
        if (result) table(id, t);
    }
    return;
}
