import React, { useState } from 'react';
import { Popover, Chip } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

const ChipWithHoverPopover = props => {
    const classes = useStyles();
    const { item, children, onClick } = props;
    const [ anchorEl, setAnchorEl ] = useState(null);
    const open = Boolean(anchorEl);

    const handlePopoverOpen = event => {
        setAnchorEl(event.currentTarget);
    };
    
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };
  
    return (
      <>
          <Chip
              className={classes.chip}
              label={item.name}
              icon={<CloseRoundedIcon />}
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
              aria-owns={open ? item.id : undefined}
              aria-haspopup="true"
              onClick={onClick}
          />
          <Popover
              id={item.id}
              className={classes.chipWithHoverPopover}
              classes={{
                paper: classes.chipWithHoverPopoverPaper,
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
              }}
              transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
          >
            {children}
          </Popover>
      </>
    )
  }

  export default ChipWithHoverPopover;
  