import React, { useState, useEffect } from 'react';
import { Chip, Popover, Typography } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/commonStyle';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

const StatusChip = (props) => {
    const classes = useStyles();
    const { statusLabel, ariaDescribeBy, popoverText, id } = props;
    const { t } = useTranslation('common');
    const [ statusChipLabel, setStatusChipLabel ] = useState(statusLabel);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ ifHasPopover, setIfHasPopover ] = useState(false);
    const open = Boolean(anchorEl);
    let chipHasErrorIcon = ['failed'];

    const handlePopoverClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        setStatusChipLabel(statusLabel);
        setIfHasPopover(chipHasErrorIcon.includes(statusLabel) ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[statusLabel])

    return (
        <div className={classes.chipWrap}>
            <Chip
                id={id}
                label={t(`status.${statusChipLabel}`)}
                className={t(`status.${statusChipLabel}`)}
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onClick={ifHasPopover ? handlePopoverClick : undefined}
                aria-describedby={ifHasPopover ? ariaDescribeBy : undefined}
                icon={ifHasPopover ?  <ErrorOutlineRoundedIcon size="small" /> : undefined}
            />
            {ifHasPopover ?
                (<Popover
                    id="mouse-over-popover"
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handlePopoverClose}
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                    }}
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                    }}
                    className={classes.popoverWrap}
                    disableRestoreFocus
                >
                    <Typography variant="caption">{popoverText}</Typography>
                </Popover>)
                :
                ''
            }
        </div>
    )
}

export default StatusChip;
