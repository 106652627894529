import { flexibleCompare } from '@fullcalendar/react';
import { makeStyles } from '@material-ui/core';
import { BorderBottom } from '@material-ui/icons';

const useStyles = makeStyles( theme => ({
        infoWindow: {
            textAlign: "center",
            width: "100%",
            minHeight: "4em",
            padding: "2px",
            fontWeight: "bold",
            "& .header": {
                position: "sticky",
                top: "0px",
                color: "darkblue",
                backgroundColor: "white",
                fontWeight: "bold",
                padding: "3px",
                marginBottom: "5px",
                fontSize: "1.05em",
                borderBottom: "1px solid darkblue"
            },
            "& .item": {
                fontSize: "1.05em",
                padding: "3px 6px",
                backgroundColor: "white",
                borderRadius: "10px 10px 10px 10px",
                transition: "font-size 150ms ease-out",
                width: "100%",
                margin: "3px 0px",
                "&:hover": {
                    backgroundColor: "lightgray",
                    fontSize: "1.10em",
                    "& a": {
                        color: "white"
                    }
                }
            }
        },
        tabInfoWindow: {
            textAlign: "center",
            width: "200px",
            minHeight: "4em",
            padding: "2px",
            fontWeight: "400",
            "& .header": {
                position: "sticky",
                top: "0px",
                color: "#dddddd",
                backgroundColor: "white",
                fontWeight: "bold",
                padding: "3px",
                marginBottom: "5px",
                fontSize: "1.05em",
                display: "flex",
                justifyContent: "space-around",
                alignItem: "center"
            },
            "& .item": {
                fontSize: "1.05em",
                textAlign: "left",
                padding: "3px 6px",
                backgroundColor: "white",
                //borderRadius: "10px 10px 10px 10px",
                //transition: "font-size 150ms ease-out",
                width: "100%",
                margin: "3px 0px",
                "&:hover": {
                    backgroundColor: "#eaeaea",
                    //fontSize: "1.10em",
                    "& a": {
                        color: "white"
                    }
                }
            },
            "& .iw-tab-button": {
                position: "absolute",
                left: "-1000vw"
            },
            "& .tab-panel": {
                display: "none"
            },
            "& .tabset > input:first-child:checked ~ .tab-panels > .tab-panel:first-child,\
            .tabset input:nth-child(2):checked ~ .tab-panels > .tab-panel:nth-child(2)"
            : {
              display: "block"
            },
            "& .tabset label": {
                display: "flex",
                gap: "3px",
                justifyContent: "center",
                alignItem: "center",
                width: "100%",
                padding: "0px 0px 5px",
                border: "0px",
                cursor: "pointer"
            },
            
            "& .tabset > input:first-child:checked ~ .header > label.online": {
                color: "#57cc5b",
                borderBottom: "2px solid #57cc5b",
                "& .iw-tab-count": {
                    width: "30%",
                    color: "white",
                    backgroundColor: "#57cc5b",
                    borderRadius: "5px 5px 5px 5px",
                    textAlign: "center"
                }
            },
            "& .tabset > input:nth-child(2):checked ~ .header > label.offline": {
                color: '#ff4038',
                borderBottom: "2px solid #ff4038",
                "& .iw-tab-count": {
                    width: "30%",
                    color: "white",
                    backgroundColor: "#ff4038",
                    borderRadius: "5px 5px 5px 5px",
                    textAlign: "center"
                }
            },
        },
    })
)

export default useStyles;