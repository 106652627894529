import React, { useState, useCallback, useEffect } from "react";
import VNCIcon from "../../assets/images/vnc-icon.svg";
import { useTranslation } from "../../langs/useTranslation";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Box
} from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import ChangePasswordDialog from "./ChangePasswordDialog";
import SecuritySettingDialog from "./SecuritySettingDialog";
import HasPermission from "../commons/HasPermission";
import { oraVersionNum } from "../../helpers/vncLinkHandler";
import {
  androidOraVersion,
  windowsOraVersion
} from "../../config/oraVersionForVncLink";

const SingleDeviceVncMenu = props => {
  const { t } = useTranslation("device");
  const { vncIConOnClick, deviceId, deviceData, getDeviceDetail } = props;
  const [expand, setExpand] = useState(false);
  const [openChangePwdDialog, setOpenChangePwdDialog] = useState(false);
  const [openSecuritySettingDialog, setOpenSecuritySettingDialog] = useState(
    false
  );
  const [ifSettingAvailable, setIfSettingAvailable] = useState(false);

  const handleExpandClick = () => setExpand(!expand);

  const handleOpenChangePasswordDialog = () => {
    setOpenChangePwdDialog(true);
  };

  const handleCloseChangePasswordDialog = () => {
    setOpenChangePwdDialog(false);
  };

  const handleOpenSecuritySettingDialog = () => {
    setOpenSecuritySettingDialog(true);
  };

  const handleCloseSecuritySettingDialog = () => {
    setOpenSecuritySettingDialog(false);
  };

  const checkSettingAvailabilty = useCallback(() => {
    let oraVersion = deviceData?.ora_version;
    let osType = deviceData?.os_type;
    if (deviceData && oraVersion) {
      if (osType === "windows")
        setIfSettingAvailable(
          oraVersionNum(oraVersion, osType) >= windowsOraVersion.production
        );
      if (osType === "android")
        setIfSettingAvailable(
          oraVersionNum(oraVersion, osType) >= androidOraVersion
        );
    }
  }, [deviceData]);

  useEffect(() => {
    checkSettingAvailabilty();
  }, [checkSettingAvailabilty]);

  return (
    <>
      <ListItem className="flex flex-direction-column align-items-baseline">
        <Box width="100%" display="flex" alignItems="center">
          <ListItemIcon>
            <img src={VNCIcon} alt="vnc icon" />
          </ListItemIcon>
          <ListItemText primary={t("tasks.vnc")} onClick={vncIConOnClick} />
          {ifSettingAvailable && (
            <HasPermission permission="single_vnc_setting">
              {expand ? (
                <ExpandLess onClick={handleExpandClick} />
              ) : (
                <ExpandMore onClick={handleExpandClick} />
              )}
            </HasPermission>
          )}
        </Box>
        {ifSettingAvailable && (
          <HasPermission permission="single_vnc_setting">
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <List>
                <ListItem button onClick={handleOpenChangePasswordDialog}>
                  <ListItemText secondary={t("tasks.changePassword")} />
                </ListItem>
                <ListItem button onClick={handleOpenSecuritySettingDialog}>
                  <ListItemText secondary={t("tasks.securitySetting")} />
                </ListItem>
              </List>
            </Collapse>
          </HasPermission>
        )}
      </ListItem>
      <HasPermission permission="single_vnc_setting">
        <ChangePasswordDialog
          deviceId={deviceId}
          getDeviceDetail={getDeviceDetail}
          hasOldPwd={deviceData?.is_vnc_password_set}
          open={openChangePwdDialog}
          onClose={handleCloseChangePasswordDialog}
        />
        <SecuritySettingDialog
          deviceId={deviceId}
          vncSecurityChecked={deviceData?.vnc_2fa_status}
          open={openSecuritySettingDialog}
          onClose={handleCloseSecuritySettingDialog}
        />
      </HasPermission>
    </>
  );
};

export default SingleDeviceVncMenu;
