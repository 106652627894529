import React, { useState, useEffect } from "react";
import { Popover, Box, TextField } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import { ValidationErrorText } from "../commons/ValidationText";
import { CreateNewFolderOutlined } from "@material-ui/icons";
import { StyledSubmitBtn } from "../commons/FormBtns";
import TextNoty from "../../helpers/textNoty";

const CreatePopover = (props) => {
  const { t } = useTranslation("common");
  const { onClose, open, anchorEl, createHandler, getHandler, list } = props;
  const [isExecuting, setIsExecuting] = useState(false);
  const [name, setName] = useState("");
  const [isFolderExisted, setIsFolderExisted] = useState(false);
  const [errorText, setErrorText] = useState("");

  const handleNameChange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    setIsExecuting(false);
    setName("");
    setIsFolderExisted(false);
    setErrorText("");
  }, [open]);

  const confirm = async () => {
    if (!name) {
      TextNoty(t("error.noName"));
      return;
    }
    let data = await createHandler(name, t, t("common.successAdd"));
    if (data) {
      onClose();
      getHandler();
    }
  };
  return (
    <Popover
      id="CreatePopover"
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
    >
      <Box
        m={2}
        className="create-folder-wrap"
        display="flex"
        alignItems="row"
        justifyContent="center"
      >
        <Box mr={1}>
          <TextField
            size="small"
            autoFocus
            value={name}
            onChange={(e) => handleNameChange(e)}
            label={t("common.nameText")}
            onKeyPress={(e) => {
              if (e.key === "Enter") confirm();
            }}
            helperText={
              isFolderExisted && <ValidationErrorText text={errorText} />
            }
          />
        </Box>
        <StyledSubmitBtn
          btnColor="primary"
          type="button"
          disabled={isExecuting}
          onClick={confirm}
          startIcon={<CreateNewFolderOutlined />}
          btnText={t("common.createText")}
        />
      </Box>
    </Popover>
  );
};

export default CreatePopover;
