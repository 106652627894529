import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import * as localforage from "localforage";
import { getDeviceKeyName, setDeviceKeyName } from "./../helpers/commonHelper";

export const getSystemInfoAPI = async (deviceId) => {
    const systemInfo = await getDeviceKeyName("systemInfo", deviceId);
    if (!systemInfo) {
        const token = await localforage.getItem("token");
        try {
            let response = await axios({
                url: `${url}/api/v2/device/information/system`,
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                params: {
                    device_id: deviceId,
                },
            });

            await localforage.setItem(`systemInfo-${deviceId}`, response.data.system);
            return response.data.system;
        } catch (error) {
            errorHandling(error, true);
        }
    }

    return systemInfo;
};

export const getHardwareInfoAPI = async (deviceId) => {
    const hardwareInfo = await getDeviceKeyName("hardwareInfo", deviceId);
    if (!hardwareInfo) {
        const token = await localforage.getItem("token");
        try {
            let response = await axios({
                url: `${url}/api/v2/device/information/hardware`,
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                params: {
                    device_id: deviceId,
                },
            });

            await setDeviceKeyName("hardwareInfo", deviceId, response.data.hardware);

            return response.data.hardware;
        } catch (error) {
            errorHandling(error, false);
        }
    }

    return hardwareInfo;
};

export const getSoftwareInfoAPI = async (deviceId) => {
    const token = await localforage.getItem("token");
    try {
        let response = await axios({
            url: `${url}/api/v2/device/information/software`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id: deviceId,
            },
        });
        return response.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getNetworkInfoAPI = async (deviceId) => {
    const networkInfo = await getDeviceKeyName("networkInfo", deviceId);
    if (!networkInfo) {
        const token = await localforage.getItem("token");
        try {
            let response = await axios({
                url: `${url}/api/v2/device/information/network`,
                method: "GET",
                headers: {
                    Accept: "application/json",
                    Authorization: "Bearer " + token,
                },
                params: {
                    device_id: deviceId,
                },
            });
            await localforage.setItem(
                `networkInfo-${deviceId}`,
                response.data.network
            );
            return response.data.network;
        } catch (error) {
            errorHandling(error, false);
        }
    }

    return networkInfo;
};

export const getComponentsInfoAPI = async (device_id) => {
    const token = await localforage.getItem("token");
    try {
        let response = await axios({
            url: `${url}/api/v2/device/information/component`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                device_id: device_id,
            },
        });
        const componentsInfo = response.data.components;
        return componentsInfo;
    } catch (error) {
        errorHandling(error, false);
    }
};
