import React, { useContext } from 'react';
import clsx from 'clsx';
import { SubmitBtnContext } from '../../App';
import { Button } from '@material-ui/core';
import useStyles from '../../styles/submitBtnStyle';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from "../../langs/useTranslation";

export const StyledSubmitBtn = props => {
    const classes = useStyles();
    const { loading, success } = useContext(SubmitBtnContext);
    const { type, btnColor, linkpath, onClick, startIcon, btnText, disabled } = props;

    const buttonClassname = clsx({
        [classes.buttonSuccess]: success,
    });

    return(
        <>
            <Button 
                variant="contained" 
                type={type} 
                color={btnColor} 
                className={`${classes.btn} ${buttonClassname}`} 
                href={linkpath} 
                disabled={loading || disabled}
                onClick={onClick}
                startIcon={startIcon}
            >
                {btnText}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
        </>
    )
}


export const BackBtn = props => {
    const { t } = useTranslation('common');
    const { onClick, disabled ,btnText} = props;

    const handleBack = () => {
        if (onClick) {
            onClick();
        } else {
            window.history.back();
        }
    }

    return(
        <Button 
            variant="contained" 
            color="secondary" 
            type="button" 
            disabled={disabled}
            onClick={handleBack}>
            { btnText || t('btn.backBtn') }
        </Button>
    )
}

