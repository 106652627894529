import React, { useEffect } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
// import { useParams } from "react-router-dom";
import DeviceOwners from "../commons/DeviceOwners";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { scheduledDateInLocal } from "../../helpers/datetimeHandlers";

const DeviceDetailBasicData = (props) => {
  // const { device_id } = useParams();
  const {
    deviceData,
    companies,
    tagValue,
    nubisVersion,
    serialNumber,
    system,
    openMore,
    onlineStatus
  } = props;
  const { t } = useTranslation("device");
  const classes = useStyles();
  const [deviceDetail, setDeviceDetail] = useStateOnMounted({});
  const net =
    deviceDetail &&
    deviceDetail.networks &&
    deviceDetail.networks[0] &&
    deviceDetail.networks[0].json &&
    deviceDetail.networks[0].json[0];

  useEffect(() => {
    setDeviceDetail(deviceData ? deviceData : {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData, companies, tagValue, nubisVersion, serialNumber]);

  return (
    <div>
      <Paper style={{ padding: "20px 30px" }}>
        <div className={classes.deviceDetail_title}>
          <Typography variant="h5">{t("category.information")}</Typography>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.more}
            onClick={openMore}
          >
            More
          </Typography>
        </div>
        <Grid
          container
          spacing={4}
          justifyContent="center"
          alignItems="center"
          className={classes.deviceDetail_BasicData}
        >
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.deviceSN")}
              </Typography>
              <Typography
                variant="body2"
                className={classes.deviceDetail_value}
              >
                {serialNumber ? serialNumber : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.belongTo")}
              </Typography>
              <div className={classes.deviceDetail_value}>
                {companies ? <DeviceOwners owners={companies} /> : "-"}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.location")}
              </Typography>
              <Typography
                variant="body2"
                className={classes.deviceDetail_value}
              >
                {system && system.json ? system?.json.location : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.status")}
              </Typography>
              <div className={classes.deviceDetail_value}>
                {onlineStatus ? (
                  <div className={classes[onlineStatus]}>
                    {onlineStatus[0].toUpperCase() +
                      onlineStatus.slice(1)}
                  </div>
                ) : (
                  "-"
                )}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.oraVersion")}
              </Typography>
              <Typography
                variant="body2"
                className={classes.deviceDetail_value}
              >
                {deviceDetail?.ora_version ? deviceDetail?.ora_version : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <div className={classes.deviceDetail_text}>
                {t("category.nubisVersion")}
              </div>
              <div className={classes.deviceDetail_value}>
                {deviceDetail?.nubis_version
                  ? deviceDetail?.nubis_version === "nubis_pro"
                    ? "Nubis Pro"
                    : "Nubis Free"
                  : "-"}
              </div>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.activatedDate")}
              </Typography>
              <Typography
                variant="body2"
                className={classes.deviceDetail_value}
              >
                {deviceDetail?.created_at
                  ? scheduledDateInLocal(deviceDetail?.created_at)
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.lastestOnline")}
              </Typography>
              <Typography
                variant="body2"
                className={classes.deviceDetail_value}
              >
                {deviceDetail?.heart_beated_at
                  ? scheduledDateInLocal(deviceDetail?.heart_beated_at)
                  : "-"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4}>
            <div className={classes.deviceDetail_DateSet}>
              <Typography variant="body2" className={classes.deviceDetail_text}>
                {t("category.MAC")}
              </Typography>
              <Typography
                variant="body2"
                className={classes.deviceDetail_value}
              >
                {net && net.mac_address ? net.mac_address : "-"}
              </Typography>
            </div>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default DeviceDetailBasicData;
