import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const removeFolderAPI = async (id, name, t, successText, setRefreshCapacity, onClose, reloadTable) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/drive/file/delete-folder`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                id: id,
                name: name
            }
        })
        successHandling(t, successText);
        setRefreshCapacity(true);
        reloadTable();
        onClose();
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }

}
