import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import "../css/dataTable.css";
import "datatables.net";
import * as $ from "jquery";
import * as localforage from "localforage";
import { language } from "./datatableLanguage";
import { GetAppRounded, DeleteRounded, ReplyRounded } from "@material-ui/icons";
import { utcStringToLocalDatetimeConverter } from "../helpers/datetimeHandlers";
import { getDownloadLinkAPI } from "../apis/getDownloadLinkAPI";
import EditableFileName from "../components/fileBrowser/EditableFileName";
import { transformedFormatBytes } from "./formatBytesTransformer";
import { datatableErrorHandling } from "../helpers/datatableErrorHandling";
import { searchStyleRender } from "./datatableSearchStyleRender";
import HasPermission from "../components/commons/HasPermission";

export const table = async (
    t,
    handleActionDialogOpen,
    parentId,
    setParentId,
    linkArray,
    setLinkArray,
    localforageKeyName
) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";

    $("#uploaded-file-table").DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[2, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: true,
        pageLength: 25,
        paging: true,
        ajax: {
            url: `${url}/api/v2/drive/file/datatable`,
            type: "GET",
            data: {
                company_id: companyId,
                parent_id: parentId,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                datatableErrorHandling(error, true);
            },
        },
        columns: [
            {
                data: "name",
                title: `${t("table.fileName")}`,
                className: "file-name",
                createdCell: (td, data, rowData) => {
                    let extension = rowData.extension;
                    let id = rowData.id;
                    let ifFolder = rowData.type === "folder";
                    let ifScreenshotFolder = ifFolder && data === "screenshot";

                    if (ifFolder) {
                        ReactDOM.render(
                            <EditableFileName
                                id={id}
                                data={data}
                                extension={ifScreenshotFolder ? "screenshotFolder" : "folder"}
                                className={
                                    ifScreenshotFolder ? "folder-link no-border" : "folder-link"
                                }
                                linkArray={linkArray}
                                setLinkArray={setLinkArray}
                                parentId={parentId}
                                setParentId={setParentId}
                                disabled={ifScreenshotFolder}
                                localforageKeyName={localforageKeyName}
                                ifFolder={ifFolder || ifScreenshotFolder}
                            />,
                            td
                        );
                    } else {
                        ReactDOM.render(
                            <EditableFileName id={id} data={data} extension={extension} />,
                            td
                        );
                    }
                },
            },
            {
                data: "extension",
                title: `${t("table.type")}`,
                render: (data) => {
                    return data ? data : `${t("table.folder")}`;
                },
            },
            {
                data: "uploaded_at",
                title: `${t("table.uploadedDate")}`,
                render: (data) => {
                    return utcStringToLocalDatetimeConverter(data);
                },
            },
            {
                data: "size",
                title: `${t("table.size")}`,
                render: (data, type, rowData) => {
                    return rowData.type === "folder" ? "-" : transformedFormatBytes(data);
                },
            },
            {
                data: "id",
                title: `${t("table.action")}`,
                className: "action",
                orderable: false,
                createdCell: (td, data, rowData) => {
                    if (rowData.type === "folder" && rowData.name === "screenshot") {
                        ReactDOM.render("-", td);
                    }
                    if (rowData.type === "folder" && rowData.name !== "screenshot") {
                        ReactDOM.render(
                            <>
                                <HasPermission permission="nubis_drive_file_broswer_share_file">
                                    <ReplyRounded
                                        onClick={() => handleActionDialogOpen("share", rowData)}
                                        className="shareIcon"
                                    />
                                </HasPermission>
                                <HasPermission permission="nubis_drive_file_broswer_delete_file">
                                    <DeleteRounded
                                        onClick={() => handleActionDialogOpen("remove", rowData)}
                                    />
                                </HasPermission>
                            </>,
                            td
                        );
                    }
                    if (rowData.type !== "folder") {
                        ReactDOM.render(
                            <>
                                <HasPermission permission="nubis_drive_file_broswer_share_file">
                                    <ReplyRounded
                                        onClick={() => handleActionDialogOpen("share", rowData)}
                                        className="shareIcon"
                                    />
                                </HasPermission>
                                <HasPermission permission="nubis_drive_file_broswer_download_file">
                                    <GetAppRounded onClick={() => getDownloadLinkAPI(data)} />
                                </HasPermission>
                                <HasPermission permission="nubis_drive_file_broswer_delete_file">
                                    <DeleteRounded
                                        onClick={() => handleActionDialogOpen("remove", rowData)}
                                    />
                                </HasPermission>
                            </>,
                            td
                        );
                    }
                },
            },
        ],
    });
    searchStyleRender();
};
