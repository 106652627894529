import React from 'react';
import PropTypes from 'prop-types';
import { ListItem, ListItemText } from '@material-ui/core';
// import breadcrumbNameMap from '../../helpers/breadcrumbNameMap';
import { Link as RouterLink } from 'react-router-dom';

const ListItemLink = (props) => {
    const { to,  ...other } = props;
    return(
        <ListItem button component={RouterLink} to={to} {...other} >
            {props.children}
            <ListItemText />
        </ListItem>
    )
}

ListItemLink.propTypes = {
    to: PropTypes.string.isRequired,
};

export default ListItemLink;