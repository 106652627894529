import React from 'react';
import ReactDOMServer from 'react-dom/server';

function BasicInfoWindowTemplate(items, classes){
    return (
        <div className={classes}>
            <div className="header"><b>Device Counts: {items.length}</b></div>
            <List list={items} />
        </div>
    )

}

function OnlineOfflineInfoWindowTemplate(items, classes){
    const map = new Map([['online', []], ['offline', []]]);
    const key = 'online_offline_status';
    for(let item of items){
        map.set( item[key], [...map.get(item[key]), item] );
    }
    return (
        <div className={classes}>
            <div className='tabset'>
                {
                    Array.from(map.keys()).map( (key, idx) => (
                        <input key={key} type='radio' id={key} name="tab" className='iw-tab-button' checked={idx === 0 ? true : false}></input>
                    ))
                }
                <div className='header'>
                    {
                        Array.from(map.keys()).map( (key, idx) => (
                            <label htmlFor={key} key={key} className={key}>
                                {key.charAt(0).toUpperCase() + key.slice(1)}
                                <div className='iw-tab-count'>{map.get(key).length}</div>
                            </label>
                        ))
                    }    
                </div>
                <div className='tab-panels'>
                    {
                        Array.from(map.keys()).map( key => (
                            <React.Fragment key={key}>
                                <div className='tab-panel'>
                                    <List list={map.get(key)}/>
                                </div>
                            </React.Fragment>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

function List({ list }){
    return (
        list.map( item => (
            <div key={item["serial_number"]} className="item">
                <a href={`/device-list/device-detail/${item["device_uuid"]}/${item["os_type"]}`} target="blank">
                    {item["serial_number"]}
                </a>
            </div>
        ))
    )
}

export const BasicInfoWindow = (items, classes) => ReactDOMServer.renderToString( BasicInfoWindowTemplate(items, classes) )
export const OnlineOfflineInfoWindow = (items, classes) => ReactDOMServer.renderToString( OnlineOfflineInfoWindowTemplate(items, classes) );