import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "@material-ui/core";

const BackArrow = (props) => {
  const { t } = useTranslation("common");
  const { click } = props;
  return (
    <div
      onClick={click}
      style={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        marginBottom: "10px",
      }}
    >
      <ArrowBackIcon />
      <Typography variant="body1" style={{ marginLeft: "10px" }}>
        {t("btn.backBtn")}
      </Typography>
    </div>
  );
};

export default BackArrow;
