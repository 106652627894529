import React from 'react';
import { List, ListItemText } from '@material-ui/core';
import useStyles from '../../styles/drawerStyle';
import ListItemLink from './ListItemLink';
import { useTranslation } from "../../langs/useTranslation";
import HasPermission from './HasPermission';

const DrawerListItems = (props) => {
    const { t } = useTranslation('common')
    const classes = useStyles();
    const { listItems } = props;

    return(
        <List component="div" disablePadding className={classes.nestedListWrapper}>
            { listItems.map( (o, i) => {
                return(
                    <HasPermission key={i} permission={o.permission}>
                        <ListItemLink button to={o.link} disabled={o.disabled}>
                            <ListItemText>{t(`drawer.${o.text}`)}</ListItemText>
                        </ListItemLink>
                    </HasPermission>
                )
            })}
        </List>
    )
}

export default DrawerListItems;