import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";
import textNoty from "../helpers/textNoty";

export const getTagsApi = async (
    search,
    order_by_field,
    order_by_type,
    page,
    paginate
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/tag/table`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                search,
                order_by_field,
                order_by_type,
                page,
                paginate,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const createTagApi = async (name, t, successfulText) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/tag/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                name,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        if (error?.response?.data?.errors?.name[0] === "name_already_exist") {
            textNoty(t("error.alreadyExist"));
        } else {
            errorHandling(error, false);
        }
    }
};

export const deleteTagApi = async (tag_ids, t, successfulText) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/tags/delete`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                tag_ids,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const updateTagApi = async (tag_id, name, t, successfulText) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/tag/update`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                tag_id,
                name,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        if (
            error?.response?.data?.errors?.name[0] ===
            "The name has already been taken."
        ) {
            textNoty(t("error.alreadyExist"));
        } else {
            errorHandling(error, false);
        }
    }
};
