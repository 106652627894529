import React from 'react';
import { url } from '../apis/apiSetup';

const CASLogin = () => {
    window.location.href = `${url}/cas/login`;

    return null;
}

export default CASLogin;
