const useStyles = (theme) => ({
    table: {
        marginLeft: -theme.spacing(4),
        marginRight: -theme.spacing(4),
        position: 'relative',
        '& .MuiPaper-root': {
            boxShadow: 'none',
            padding: 0,
            overflow: 'hidden',
            '& .MuiCheckbox-root': {
                marginLeft: `${theme.spacing(4)}px !important`,
            },
            '& .MuiFormControl-root': {
                marginRight: theme.spacing(4),
            },
            '& .MuiTablePagination-toolbar': {
                paddingRight: theme.spacing(4),
            },
            '& .MuiToolbar-root': {
                color: 'none !important',
                backgroundColor: 'none !important',
            },
            '& .serialNumberLink': {
                color: '#000',
                fontWeight: 'bold', 
                textDecoration: 'none',
            },
            '& .taskHistoryActionResendBtn': {
                color: 'rgb(4, 183, 210)',
                border: '1px solid rgb(4, 183, 210)',
                padding: '0 30px',
                textAlign: 'center',
                height: theme.spacing(4),
                lineHeight: '32px',
                borderRadius: 24,
                display: 'inline-block',
                background: '#fff',
                textDecoration: 'none',
                transition: theme.transitions.create(['background'], {
                    easing: theme.transitions.easing.sharp,
                }),
                '&:hover': {
                    background: 'rgba(4, 183, 210, 0.1)'
                },
            },
        },
    },
    tableMoveTop: {
        marginTop: -theme.spacing(6),
        zIndex: 1,
        '& th:first-child': {
            paddingLeft: 40,
        },
        '& td:first-child': {
            paddingLeft: 40,
        },
        '& th:last-child': {
            paddingRight: '40px !important',
        },
        '& td:last-child': {
            paddingRight: '40px !important',
        },
        '& td.action': {
            paddingBottom: '0 !important',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: '20px !important',
        },
    },
});

export default useStyles;