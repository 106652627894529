let newFlattenArray = [];

export const flatArrList = array => {
    // eslint-disable-next-line array-callback-return
    array.map(o => {
        if (Array.isArray(o.children) && o.children.length > 0) flatArrList(o.children);
        const found = newFlattenArray.some(el => el.name === o.name);
        if (!found) newFlattenArray.push({ id: o.id, name: o.name });
    })
    return newFlattenArray;
}

export const sortArrByName = array => array.sort((a, b) => {
    let nameA = a.name ? a.name.toUpperCase() : a.toUpperCase();
    let nameB = b.name ? b.name.toUpperCase() : b.toUpperCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
})

export const uniqueNames = array => {
    let newArray = [];
    // eslint-disable-next-line array-callback-return
    array.map(o => {
        const found = newArray.some(el => el.name === o.name);
        if (!found) newArray.push({ id: o.id, name: o.name });
    })
    return newArray;
}

export const filterdArr = array => {
    let newArray = [];
    // eslint-disable-next-line array-callback-return
    array.map(o => {
        const found = newArray.some(el => el === o);
        if (!found) newArray.push(o);
    })
    return newArray;
}

export const combinedCurrentAndAPIArrWithCheckBoxStatus = (arrayWithIdAndName, currentArrayWithIdAndName) => {
    const newArray = arrayWithIdAndName.map(o => {
        o.checked = false;
        return o;
    })
    return sortArrByName(newArray);
}

export const buildArrWithNameAndIdAndCheckBox = (array, setGroups) => {
    const newArray = array.map((o) => {
        return { name: o.name, id: o.id, checked: false };
    })
    setGroups(newArray);
    return newArray;
}
