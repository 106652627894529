import React, { useState, useContext, useEffect, useRef } from 'react';
import { Typography, Grid, Divider } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import FileUpload from '../device/FileUpload';
import PeripheralTypeSelect from './PeripheralTypeSelect';
import { SubmitBtnContext } from '../../App';
import FilterSelectionForUtility from '../commons/SelectionWithSelectAllCheckbox';
import ConfirmAndCancelBtn from '../commons/ConfirmAndCancelBtn';
import { createFirmwareAPI } from '../../apis/createFirmwareAPI';
import { updateFirmwareAPI } from '../../apis/updateFirmwareAPI';
import { getDeviceModelsAPI } from '../../apis/getDeviceModelsAPI';
import { getCompaniesAPI } from '../../apis/getCompaniesAPI';
import LinearWithValueLabel from '../commons/LinearProgress';
import NoteTextfield from '../commons/NoteTextfield';
import RequiredText from '../commons/RequiredText';
import { MultipleSelectWithChipTitle } from '../commons/Titles';

// Manage localforage names based on the task name
const taskName = "fwUtility";
const storedFileToLocalforageName = `${taskName}File`;
const storedPeripheralTypeToLocalforageName = `${taskName}PeripheralType`;
const storedCompaniesToLocalforageName = `${taskName}Companies`;
const storedModelsToLocalforageName = `${taskName}ModelNames`;

const AddOrEditFWUtilityForm = (props) => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('adminUtility');
    const { onClose, modelIdArr, companyIdArr, fwId, filePath, fwTypeId, createUtility } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    const [note, setNote] = useState('');
    const [storedFile, setStoredFile] = useState();
    const [typeId, setTypeId] = useState();
    const [companies, setCompanies] = useState();
    const [models, setModels] = useState();
    const [tableDisabled, setTableDisabled] = useState(false);
    const [validated, setValidated] = useState(false);
    const [fileUploading, setFileUploading] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);

    const handleNoteChange = (event) => {
        setNote(event.target.value);
    };

    const handleUpdateOrCreateUtiltiy = async () => {
        dispatch({ type: 'showLoading' })

        const file = await localforage.getItem(storedFileToLocalforageName)
        const type = await localforage.getItem(storedPeripheralTypeToLocalforageName)
        const modelData = await localforage.getItem(storedModelsToLocalforageName)
        const companyData = await localforage.getItem(storedCompaniesToLocalforageName)
        const modelIds = modelData ? modelData.map(o => o.id) : undefined
        const companyIds = companyData ? companyData.map(o => o.id) : undefined
        setStoredFile(file)
        setTypeId(type)
        setModels(modelIds)
        setCompanies(companyIds)

        // //update FW utility
        if (!createUtility && !!type && !!modelData && !!companyData) {
            setTableDisabled(true)
            setFileUploading(!!file)
            const successfulText = `${t('dialog.successfullyUpdateFWUtility')}`
            const updateResult = await updateFirmwareAPI(fwId, file, filePath, note, type, companyData, modelData, t, successfulText, { setPercentCompleted })
            if (updateResult) onClose()
        }
        // //create FW utility
        if (createUtility && !!file && !!type && !!modelData && !!companyData) {
            setTableDisabled(true)
            setFileUploading(true)
            const successfulText = `${t('dialog.successfullyCreateFWUtility')}`
            const createResult = await createFirmwareAPI(file, note, type, companyData, modelData, t, successfulText, { setPercentCompleted })
            if (createResult) onClose()
        }
        setTableDisabled(false)
        setFileUploading(false)
        setValidated(true)
        dispatch({ type: 'stopLoading' });
    }

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedFileToLocalforageName);
        await localforage.removeItem(storedPeripheralTypeToLocalforageName);
        await localforage.removeItem(storedCompaniesToLocalforageName);
        await localforage.removeItem(storedModelsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    }

    useEffect(() => {
        if (!mounted.current) {
            resetAllStoredValueInLocalforage()
            mounted.current = true;
        }
    })

    return (
        <>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.uploadFileWrap}>
                    <Typography variant="body1">
                        {t('dialog.uploadAFile')}
                        {createUtility ? ' *' : ''}
                    </Typography>
                    <FileUpload
                        uploadFileType={'file'}
                        tableDisabled={tableDisabled}
                        storedFileToLocalforageName={storedFileToLocalforageName}
                    />
                    {createUtility && validated && !storedFile ? <RequiredText /> : ''}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className={classes.marginBottom8px}>
                    <Typography variant="body1">{t('dialog.peripheralType')}</Typography>
                    <PeripheralTypeSelect
                        fwTypeId={fwTypeId}
                        tableDisabled={tableDisabled}
                        storedPeripheralTypeToLocalforageName={storedPeripheralTypeToLocalforageName}
                    />
                    {validated && !typeId ? <RequiredText /> : ''}
                </Grid>
            </Grid>
            <Divider />
            <Grid container spacing={4} className={classes.marginBottom16px}>
                <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
                    <FilterSelectionForUtility
                        hasSelectAllOption={true}
                        currentArr={companyIdArr}
                        tableDisabled={tableDisabled}
                        getDataFromAPI={getCompaniesAPI}
                        localforageKeyName={storedCompaniesToLocalforageName}
                    />
                    {validated && !companies ? <RequiredText /> : ''}
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={4} xl={4} className={classes.marginBottom16px}>
                    <MultipleSelectWithChipTitle title={t('dialog.modelName')} />
                    <FilterSelectionForUtility
                        currentArr={modelIdArr}
                        tableDisabled={tableDisabled}
                        getDataFromAPI={getDeviceModelsAPI}
                        localforageKeyName={storedModelsToLocalforageName}
                    />
                    {validated && !models ? <RequiredText /> : ''}
                </Grid>
                <Grid item xs={12}>
                    <NoteTextfield
                        onChange={handleNoteChange}
                        disabled={tableDisabled}
                        fullWidth={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    {fileUploading && storedFile ?
                        <LinearWithValueLabel percentCompleted={percentCompleted} />
                        :
                        ''
                    }
                </Grid>
            </Grid>
            <ConfirmAndCancelBtn
                styledSubmitBtnOnClick={handleUpdateOrCreateUtiltiy}
                cancelBtnDisabled={tableDisabled}
                cancelBtnOnClick={onClose} />

        </>
    )
}

export default AddOrEditFWUtilityForm;
