import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  DialogTitle,
  TextField,
  ListItemText,
  List,
  ListItem,
  ListItemSecondaryAction,
  IconButton,
  ListItemIcon,
} from "@material-ui/core";
import {
  updateGroupApi,
  deleteGroupApi,
  createGroupApi,
} from "../../apis/groupsApi";
import TickIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import useStyles from "../../styles/dialogStyle";
import { BackBtn, StyledSubmitBtn } from "../commons/FormBtns";
import DeleteDialog from "./DeleteDialog";
import TextNoty from "../../helpers/textNoty";
import HasPermission from "../commons/HasPermission";
import { deviceModifyAPI } from "../../apis/deviceModifyAPI"

const GroupUpdateDialog = (props) => {
  const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [editName, setEditName] = useState("");
  const [oldName, setOldName] = useState("");
  const [editData, setEditData] = useState({});
  const [deleteData, setDeleteData] = useState({});
  const [taskSchedule, setTaskSchedule] = useState([]);
  const classes = useStyles();
  const {
    open,
    onClose,
    createGroupName,
    setCreateGroupName,
    getGroup,
    list,
  } = props;
  const { t } = useTranslation("groups");

  const confirmDelete = async () => {
    setLoadingDialog(true);
    await deleteGroupApi(deleteData.id, t, t("noty.delete"))
      .then((res) => {
        getGroup();
        setOpenDeleteDialog(false);
      })
      .finally(() => {
        setLoadingDialog(false);
      });
  };

  const onConfirmCreate = async () => {
    if (!createGroupName) {
      TextNoty(t("error.noName"));
      return;
    }
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === createGroupName) {
        TextNoty(t("error.alreadyExist"));
        return;
      }
    }
    setLoadingDialog(true);
    await createGroupApi(createGroupName, t, t("noty.create"))
      .then((res) => {
        setCreateGroupName("");
        getGroup();
      })
      .finally(() => {
        setLoadingDialog(false);
      });
  };
  const deleteHandler = async (value) => {
    let res = await deviceModifyAPI('group', undefined, [value.id])
    if (res) {
        setDeleteData(value);
        setTaskSchedule(res)
        setOpenDeleteDialog(true);
    }
  };
  const editHandler = (value) => {
    setEditData(value);
    setEditName(value.name);
    setOldName(value.name);
  };
  const cancelEdit = () => {
    if (!loadingDialog) {
      setEditData({});
      setEditName("");
      setOldName("");
    }
  };
  const confirmEdit = async () => {
    if (!editName) {
      TextNoty(t("error.noName"));
      return;
    }
    for (let i = 0; i < list.length; i++) {
      if (list[i].name === editName && editName !== oldName) {
        TextNoty(t("error.alreadyExist"));
        return;
      }
    }
    setLoadingDialog(true);
    await updateGroupApi(
      editData.id,
      editName,
      t,
      t("noty.update")
    )
      .then((res) => {
        getGroup();
        setEditData({});
        setEditName("");
        setOldName("");
      })
      .finally(() => {
        setLoadingDialog(false);
      });
  };
  return (
    <>
      <Dialog
        maxWidth="md"
        onClose={onClose}
        className={classes.dialog}
        open={open}
        aria-labelledby="add-event-dialog"
        aria-describedby="add-event-dialog"
      >
        <>
          <DialogTitle>
            <div>{t("dialog.editGroup")}</div>
          </DialogTitle>
          <DialogContent>
            <HasPermission permission="group_add">
              <div className={classes.createGroupInput}>
                <TextField
                  placeholder={t("dialog.groupName")}
                  onChange={(e) => setCreateGroupName(e.target.value)}
                  value={createGroupName}
                  className={classes.inputField}
                />
                <StyledSubmitBtn
                  btnColor="primary"
                  disabled={loadingDialog}
                  type="button"
                  btnText={t("dialog.createGroup")}
                  onClick={() => onConfirmCreate()}
                />
              </div>
            </HasPermission>
            <div className={classes.createGroupHeader}>
              <Typography
                variant="body2"
                color="secondary"
                className={classes.createGroupHeaderItem}
              >
                {t("dialog.groupName")}
              </Typography>
              <Typography
                variant="body2"
                color="secondary"
                className={classes.createGroupHeaderItem}
              >
                {t("dialog.action")}
              </Typography>
            </div>
            <div className={classes.listOuter}>
              <List dense className={classes.list}>
                {list && list.length > 0 ? (
                  list.map((value) => {
                    return (
                      <ListItem
                        key={value.id}
                        dense
                        button
                        className={classes.listItem}
                      >
                        <ListItemIcon className={classes.listIcon}>
                          {editData && editData.id === value.id ? (
                            <TextField
                              placeholder={t("dialog.groupName")}
                              onChange={(e) => setEditName(e.target.value)}
                              value={editName}
                              className={classes.inputFieldEdit}
                            />
                          ) : (
                            <Typography
                              variant="body2"
                              style={{ width: "100%" }}
                            >
                              {value.name}
                            </Typography>
                          )}
                        </ListItemIcon>
                        <ListItemText
                          id={value.id}
                          primary={
                            editData && editData.id === value.id ? (
                              <div className={classes.editIconArea}>
                                <TickIcon
                                  style={{
                                    color: "#1DBBD4",
                                    margin: "0 10px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => confirmEdit()}
                                />
                                <CloseIcon
                                  style={{
                                    color: "#999999",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => cancelEdit()}
                                />
                              </div>
                            ) : (
                              <div
                                className={classes.renameGroup}
                                onClick={() => editHandler(value)}
                              >
                                <EditIcon /> {t("dialog.renameGroup")}
                              </div>
                            )
                          }
                        />
                        <ListItemSecondaryAction>
                          <HasPermission permission="groups_delete">
                            <IconButton
                              edge="end"
                              aria-label="delete"
                              className={classes.listDelete}
                              onClick={() => deleteHandler(value)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </HasPermission>
                        </ListItemSecondaryAction>
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography
                    variant="body2"
                    color="secondary"
                    className={classes.nodata}
                  >
                    {t("common.noData")}
                  </Typography>
                )}
              </List>
            </div>
          </DialogContent>

          <DialogActions className={classes.noMarginBtn}>
            <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
          </DialogActions>
        </>
      </Dialog>
      <DeleteDialog
        open={OpenDeleteDialog}
        loading={loadingDialog}
        onConfirm={confirmDelete}
        taskSchedule={taskSchedule}
        onClose={() => setOpenDeleteDialog(false)}
        content={t("dialog.deleteGroupContent")}
        header={t("dialog.deleteGroup")}
        deleteData={deleteData}
      />
    </>
  );
};

export default GroupUpdateDialog;
