import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import AddOrEditAlarmPolicyForm from './AddOrEditAlarmPolicyForm';

const AddOrEditAlarmPolicyDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { onClose, open, createOrUpdate, policyToBeUpdated, storedRuleValueToLocalforageName, storedEmailToLocalforageName, resetAllStoredValueInLocalforage, storedOriginalEmailsToLocalforageName } = props;

    return (
        <Dialog
            maxWidth="lg"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-alarm-notification"
            aria-describedby="add-edit-alarm-notification-confirmation"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {createOrUpdate === 'create' ?
                    `${t('modal.createAlarmPolicy')}`
                    :
                    `${t('modal.updateAlarmPolicy')}`
                }
            </DialogTitle>
            <DialogContent>
                <AddOrEditAlarmPolicyForm
                    onClose={onClose}
                    policyToBeUpdated={policyToBeUpdated}
                    createOrUpdate={createOrUpdate}
                    storedRuleValueToLocalforageName={storedRuleValueToLocalforageName}
                    storedOriginalEmailsToLocalforageName={storedOriginalEmailsToLocalforageName}
                    storedEmailToLocalforageName={storedEmailToLocalforageName}
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                />
            </DialogContent>
        </Dialog>
    )
}

export default AddOrEditAlarmPolicyDialog;
