import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  TextField,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";

const DeleteAppDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    onConfirm,
    loading,
    os_type,
    deleteData,
    deleteCommand,
    setDeleteCommand,
    noCommand,
  } = props;
  const { t } = useTranslation("device");
  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle id="add-edit-alarm-notification">
        {t("dialog.deleteAppHeader")}
      </DialogTitle>
      <>
        <DialogContent>
          <Typography variant="body1">
            {t("dialog.deleteContent")}{" "}
            {deleteData && deleteData.name && `${deleteData.name} ?`}
          </Typography>
          {os_type === "windows" && (
            <div>
              <div style={{ margin: "15px 0 5px" }}>
                {t("dialog.silentUnInstall")}
              </div>
              <div style={{ display: "flex", alignItems: "flex-end" }}>
                <div style={{ marginRight: "10px" }}>Command : </div>
                <TextField
                  onChange={(e) => setDeleteCommand(e.target.value)}
                  value={deleteCommand}
                  style={{ width: "75%" }}
                />
              </div>
              {noCommand && (
                <div style={{ color: "#f98746", marginTop: "10px" }}>
                  {t("dialog.noCommand")}
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            btnColor="primary"
            disabled={loading}
            type="button"
            btnText={t("dialog.confirmBtn")}
            onClick={() => onConfirm()}
          />
          <BackBtn onClick={onClose} btnText={t("dialog.cancelBtn")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default DeleteAppDialog;
