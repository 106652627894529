import React, { useEffect, useState, forwardRef } from 'react';
import { FormControl, MenuItem, Select, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import { useTranslation } from "../../langs/useTranslation";
import { getIOPortsAPI } from '../../apis/getIOPortsAPI';
import { sortArrByName } from '../../helpers/arrayHandlers';

const SelectPort = forwardRef((props, ref) => {
    const classes = useStyles();
    const { formikValue, formikName, formikOnBlur, formikOnChange, formikError } = props;
    const { t } = useTranslation('task');
    const [allIOPorts, setAllIOPorts] = useState([])

    //get I/O port values
    const getIOPortsValue = async() => {
        const allIOportsArr = await getIOPortsAPI();
        if(allIOportsArr){
            const allIOPorts = allIOportsArr.map(port => port.name);
            const sortedIOPortNames = sortArrByName(allIOPorts);
            setAllIOPorts(sortedIOPortNames);
        }
    };

    useEffect(()=>{
        getIOPortsValue();
    },[]);

    return(
        <FormControl>
            <Select
                variant="outlined"
                value={formikValue}
                onChange={formikOnChange}
                onBlur={formikOnBlur}
                displayEmpty
                className={classes.fileSelectDropDown}
                name={formikName}
                MenuProps={{ disableScrollLock: true }}
            >
                <MenuItem value="">{t('task.selectCOMDefaultValue')}</MenuItem>
                {allIOPorts.map((port, index)=>{
                    return  <MenuItem key={index} value={port}>{t(`ioPortsType.${port}`)}</MenuItem>
                })}
            </Select>
            <FormHelperText hidden={!formikError} error={!!formikError}>
                {formikError}
            </FormHelperText>
        </FormControl>
    )
})

export default SelectPort;