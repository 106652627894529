import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    root: {
      '& .MuiBreadcrumbs-root': {
          marginBottom: theme.spacing(3),
      },
    },
    pageTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    tableSection: {
        marginTop: '20px !important',
        '& .MuiTypography-h6': {
            marginBottom: '24px !important'
        },
        '& .mdl-data-table': {
            textAlign: 'center',
            '& tr th': {
              paddingLeft: '80px !important',
              paddingRight: '80px !important',
              textAlign: 'center !important',
            },
            '& tr td': {
              paddingLeft: '60px !important',
              paddingRight: '60px !important',
              textAlign: 'center !important',
            },
            '& .name-rename-first-column': {
              paddingRight: '0 !important'
            },
            '& tbody tr td a':{
              marginTop: '0 !important'
            },
            '& .MuiButton-root': {
              color: '#666 !important',
              padding: '8px 16px !important'
            },
            '& .switch-column': {
              alignItems: 'center',
              justifyContent: 'center'
            },
            '& .MuiInput-underline.Mui-disabled:before': {
              borderBottom: 'none !important'
            },
            '& .permission-remove-column' :{
              paddingLeft: '0 !important',
          }
        },
        '& .reset-margin-top-table': {
            marginTop: '0 !important'
        },  
        '& .table-button-wrap': {
            position: 'relative',
            marginLeft: '24px !important'
        },
        '& .MuiFormControl-root': {
            width: '100% !important'
        },
        '& .MuiInputBase-input': {
            width: '100% !important',
            textAlign: 'center',
            color: '#000',
            fontSize: '16px !important'
        }
    },
    flex: {
      display: 'flex !important'
    },
    styledSubmitBtnWrap: {
      width: 300
    },
    renameInput: {
      '& .MuiInput-underline:after': {
        borderBottom: '2px solid #2c3ff3 !important'
      }
    },
    removeBtn: {
      '& .MuiButton-containedPrimary': {
        width: '140px !important',
        lineHeight: '2em !important'
      }
    },
    noPermissionTitleSection: {
      marginBottom: '24px !important',
      '& h4': {
        marginBottom: '16px !important'
      }
    }
}));

export default useStyles;