import React from "react";
import { Button } from "@material-ui/core";
import { GpsNotFixedRounded } from "@material-ui/icons";

const DetectBtn = (props) => {
  const { disableDetectBtn, onClick, btnText, icon, noIcon } = props;

  return (
    <>
      {noIcon ? (
        <Button
          variant="contained"
          style={{ cursor: disableDetectBtn ? "not-allowed" : "pointer" }}
          onClick={onClick}
          disabled={disableDetectBtn}
          color={disableDetectBtn ? "secondary" : "primary"}
        >
          {btnText}
        </Button>
      ) : (
        <Button
          variant="contained"
          style={{ cursor: disableDetectBtn ? "not-allowed" : "pointer" }}
          onClick={onClick}
          startIcon={icon ? icon : <GpsNotFixedRounded />}
          disabled={disableDetectBtn}
          color={disableDetectBtn ? "secondary" : "primary"}
        >
          {btnText}
        </Button>
      )}
    </>
  );
};

export default DetectBtn;
