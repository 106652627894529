import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  menuPaper: {
    maxHeight: 250,
  },
  haveSubTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
    "& .pageSubTitle": {
      color: "#888",
      transform: "scale(0.9) translateX(-5px)",
      [theme.breakpoints.down("md")]: {
        transform: "scale(0.85) translateX(-40px)",
        "&:first-child": {
          marginTop: "5px",
        },
      },
    },
  },
  root: {
    "& .MuiDivider-root": {
      marginBottom: "30px !important",
    },
  },
  needClearDisabledSelect: {
    "& .disabled": {
      display: "none !important",
    },
  },
  hiddenInput: {
    display: "none",
  },
  selectCompanyBtn: {
    background: "#fff",
    textAlign: "left",
    display: "flex",
    maxWidth: "220px",
    width: "100%",
    margin: "0",
    "& .MuiButton-label": {
      justifyContent: "space-between",
      padding: `0 ${theme.spacing(2)}px`,
    },
  },
  downloadTemplateBtn: {
    textAlign: "left",
    width: 210,
    boxShadow: "none",
    justifyContent: "left",
    color: "#999 !important",
    margin: "0 0 0 5px",
  },
  deviceControlLogTemplateBtn: {
    textAlign: "center",
    width: 180,
    boxShadow: "none",
    justifyContent: "center",
    margin: "0 0 0 5px",
    marginTop: "32px",
    [theme.breakpoints.down("md")]: {
      marginTop: "0px",
    },
  },
  uploadArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    "& label": {
      width: "250px",
      marginRight: "10px",
    },
  },
  selectTypeArea: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    "& .MuiSelect-outlined": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
  },
  selectType: {
    marginRight: "15px",
  },
  selectDeviceArea: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    marginBottom: "20px",
    "& .MuiSelect-outlined": {
      border: "1px solid rgba(0, 0, 0, 0.12)",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
    },
  },
  dropdown: {
    "& .MuiAutocomplete-root": {
      "& .tagsArea": {
        cursor: "default",
      },
      "& span.MuiAutocomplete-tag": {
        display: "none",
        width: "0",
        height: "0",
      },
      "& .MuiChip-root": {
        height: "0",
        "& .MuiChip-label": {
          padding: "0 5px",
          transform: "scale(0.95)",
        },
        "& svg": {
          width: "15px",
        },
      },
      "& .MuiAutocomplete-endAdornment": {
        marginTop: "2px",
      },
      "& .MuiAutocomplete-inputRoot": {
        width: "300px",
        padding: "0",
        paddingLeft: "10px",
        borderRadius: "24px",
        border: "1px solid rgba(0, 0, 0, 0.12)",
        backgroundColor: "#fff",
        "& input": {
          padding: "10px",
          height: "100%",
        },
      },
    },
  },
  selectSm: {
    width: "130px !important",
  },
  select: {
    width: "230px !important",
  },
  selectDevice: {
    marginRight: "15px",
    [theme.breakpoints.down("md")]: {
      margin: "0 0 20px",
    },
    "& .noTitle": {
      marginTop: "32px",
      [theme.breakpoints.down("md")]: {
        marginTop: "0px",
      },
    },
  },
  selectText: {
    marginBottom: "13px",
    width: "150px",
    whiteSpace: "nowrap",
  },
  selectExcelText: {
    width: "150px",
    whiteSpace: "nowrap",
  },
  selectGroupText: {
    [theme.breakpoints.down("sm")]: {
      marginTop: "10px !important",
    },
  },
  controlPaper: {
    minHeight: "90px",
    flexDirection: "column",
    padding: "26px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
  },
  controlTop: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  controlLeft: {
    display: "flex",
    alignItems: "center",
  },
  notAllow: {
    cursor: "not-allowed",
    color: "#b6b6b6",
  },
  controlTextSet: {
    marginLeft: "26px",
    display: "flex",
    flexDirection: "column",
  },
  icon: {
    width: "26px",
    height: "26px",
  },
  divider: {
    marginBottom: "30px !important",
  },
  slider: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
  },
  sliderText: {
    margin: "0 13px 0 10px",
    fontWeight: 600,
  },
  drawerBottom: {
    "& .MuiDrawer-paper": {
      height: "40%",
    },
    "& iframe": {
      height: "100%",
    },
  },
  wholePageLoading: {
    position: "fixed !important",
    width: "100vw !important",
    height: "100vh  !important",
    top: "0 !important",
    left: "0 !important",
    background: "#fff !important",
    zIndex: "10000 !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    flexDirection: "column !important",
  },
  iframeOuter: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
  },
  iframeHeader: {
    height: "0px",
    width: "100%",
    backgroundColor: "",
  },
  deviceControlLogOpener: {
    fontSize: "bold",
  },
}));

export default useStyles;
