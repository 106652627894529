import React from "react";
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import { StyledSubmitBtn } from '../commons/FormBtns';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/deviceStyle';

const TaskResendDialog = (props) => {
    const classes = useStyles();
    const { openResendDialog, handleResendTask, handleResendDialogClose } = props;
    const { t } = useTranslation('device');

    return(
        <Dialog open={openResendDialog} onClose={handleResendDialogClose}>
            <DialogTitle>{t('resendTask.resendTaskCommand')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('resendTask.theTaskWillBeResentAndExecutedImmediatedly')}
                </DialogContentText>
            </DialogContent>
            <DialogActions className={classes.taskConfirmBtnWrap}>
                <StyledSubmitBtn btnText={t('btn.confirmBtn')} onClick={handleResendTask} btnColor="primary" btnType="button" />
                <Button color="secondary" variant="contained" onClick={handleResendDialogClose}>{t('btn.backBtn')}</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TaskResendDialog;