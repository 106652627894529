import React, { useState, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';

const TriggeredValueChip = (props) => {
    const classes = useStyles();
    const { statusLabel, id } = props;
    const [ statusChipLabel, setStatusChipLabel ] = useState(statusLabel);
    
    useEffect(()=>{
        setStatusChipLabel(statusLabel);
    },[statusLabel])

    return (
        <div className={classes.chipWrap}>
            <Chip 
                id={id}
                label={statusChipLabel}
            />
        </div>
    )
}

export default TriggeredValueChip;