import React, { useState } from "react";
import textNoty from "../../helpers/textNoty";
import { submitTask } from "../../helpers/taskShortcut";
import useStyles from "../../styles/dialogStyle";
import TasksDialog from "./TasksDialog";
import TaskShortcutIcon from "./TaskShortcutIcon";

const DeviceFunctionBlock = (props) => {
  const { t } = props;
  const classes = useStyles();
  const { rowData, os_type, block, reloadTableWithGroupId } = props;
  const [dialog, setDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dialogType, setDialogType] = useState("");

  const openFunction = (key) => {
    setDialogType(key);
    setDialog(true);
  };
  const close = () => {
    setDialog(false);
    setDialogType("");
  };
  return (
    <>
      <TasksDialog
        t={t}
        classes={classes}
        dialog={dialog}
        dialogType={dialogType}
        loading={loading}
        submitHandler={() =>
          submitTask(
            dialogType,
            rowData,
            t,
            reloadTableWithGroupId,
            setDialog,
            setDialogType,
            setLoading
          )
        }
        close={close}
      />
      <TaskShortcutIcon
        block={block}
        t={t}
        rowData={rowData}
        os_type={os_type}
        textNoty={textNoty}
        openFunction={openFunction}
      />
    </>
  );
};

export default DeviceFunctionBlock;
