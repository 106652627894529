import React, { useState, forwardRef } from "react";
import { Typography, Paper, Grid, FormControl } from "@material-ui/core";
import useStyles from "../../styles/deviceTaskStyle";
import SelectDate from "../device/SelectDate";
import SelectMode from "./SelectMode";
import { CommandTextField, EnableTextfield } from "../commons/Textfields";
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from "formik";
import CommandConfirmDialog from "../commons/CommandConfirmDialog";
import SendRoundedIcon from "@material-ui/icons/SendRounded";
import * as localforage from "localforage";
import { kioskModeAPI } from "../../apis/deviceControlApi";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import {
  handleClose,
  handleHasResponseStatus,
} from "../../helpers/commandConfirmationDialogAction";
import {
  kioskModeValuesSchema,
  kioskModeInitialValues,
} from "../../config/singleDeviceTaskValuesSchema";
import { DateTimeComparisonText } from "../commons/ValidationText";
import { useParams } from "react-router-dom";

const KioskModeSingleForm = forwardRef((props, ref) => {
  const classes = useStyles();
  const { device_os_type } = useParams();
  const {
    executeType,
    devices,
    deviceId,
    storedDatePickerValueToLocalforageName,
    resetAllStoredValueInLocalforage,
    successfulText,
    dispatch,
  } = props;
  const { t } = useTranslation("task");
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState({});
  const [datetime, setDatetime] = useState(true);
  const [isExecuting, setIsExecuting] = useState(false);

  const handleExecuteAction = async () => {
    setIsExecuting(true);
    // Execute the task after modal opened and the confirm button clicked
    //const command = values.command;
    const enableApplication = values.enableApplication
    const enableKioskMode = values.mode === "on" ? true : false;
    const selectedIds = [];
    const company_id = await localforage.getItem('companyId');
    const responseStatus = await kioskModeAPI(
      'company',
      company_id,
      [deviceId],
      undefined, //group_id
      undefined, //tagId
      executeType,
      enableKioskMode,
      undefined, //enableApplicationAndroid
      undefined, //enableApplicationWindows
      false, //checkedWindows
      false, //checkedAndroid
      datetime,
      enableApplication,
      undefined, //command
      t,
      successfulText,
      true //kioskModeSwitchOff
    );
    console.log(responseStatus)
    if (responseStatus) {
      handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
      setIsExecuting(false);
    }
    dispatch({ type: "stopLoading" });
  };

  return (
    <Formik
      initialValues={kioskModeInitialValues}
      validationSchema={kioskModeValuesSchema}
      onSubmit={async (values) => {
        dispatch({ type: "showLoading" });
        setValues(values);
        const datetime =
          values.date === "immediately"
            ? "immediately"
            : await localforage.getItem(storedDatePickerValueToLocalforageName);
        setDatetime(datetime);
        if (datetime) {
          setOpen(true);
        } else {
          dispatch({ type: "stopLoading" });
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl fullWidth={true} ref={ref}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Paper className={classes.fullWidthPaper}>
                  <Typography variant="h6">
                    {t("task.step")}1. {t("task.selectDate")}
                  </Typography>
                  <SelectDate
                    storedDatePickerValueToLocalforageName={
                      storedDatePickerValueToLocalforageName
                    }
                    formikName="date"
                    formikOnChange={handleChange}
                    formikValue={values.date}
                    formikError={errors.date && touched.date && errors.date}
                  />
                  {!datetime ? <DateTimeComparisonText /> : ""}
                </Paper>
                <Paper className={classes.fullWidthPaper}>
                  <Typography variant="h6">
                    {t("task.step")}2. {t("task.kioskModeSwitch")}
                  </Typography>
                  <SelectMode
                    formikName="mode"
                    formikOnChange={handleChange}
                    formikValue={values.mode}
                    formikError={errors.mode && touched.mode && errors.mode}
                  />
                </Paper>
                {/* <Paper className={classes.fullWidthPaper}>
                  <CommandTextField
                    CommandTextFieldPlaceHolder="e.g. -shut down 'xxxx.exe"
                    formikName="command"
                    formikOnChange={handleChange}
                    formikOnBlur={handleBlur}
                    formikValue={values.command}
                  />
                </Paper> */}
                <Paper className={classes.fullWidthPaper}>
                  <EnableTextfield
                    CommandTextFieldPlaceHolder={
                      device_os_type === "android"
                        ? t('task.setByPackageName')
                        : t('task.setByPath')
                    }
                    formikName="enableApplication"
                    formikOnChange={handleChange}
                    formikOnBlur={handleBlur}
                    formikValue={values.enableApplication}
                  />
                </Paper>
                <div className="submit-back-btn-wrap">
                  <StyledSubmitBtn
                    startIcon={<SendRoundedIcon />}
                    btnText={t("btn.confirmBtn")}
                    type="submit"
                  />
                  <BackBtn />
                  <CommandConfirmDialog
                    isExecuting={isExecuting}
                    okBtnOnClick={handleExecuteAction}
                    backBtnOnClick={() =>
                      handleClose(setOpen, dispatch, setIsExecuting)
                    }
                    open={open}
                    dialogTitle={t("task.kioskModeName")}
                    dialogContent={t("modal.kioskModeDescription")}
                  />
                </div>
              </Grid>
            </Grid>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
});

export default KioskModeSingleForm;
