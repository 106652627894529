import React, { useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import AttachFileRoundedIcon from "@material-ui/icons/AttachFileRounded";
import useStyles from "../../styles/deviceExcelFileUploadStyle";
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from "localforage";
import { FileNameText, ExcelFileErrorMsg } from "../commons/ValidationText";
import { excelExtensionArray } from "../../helpers/fileExtentionArrayList";

const FileUpload = (props) => {
  const { t } = useTranslation("task");
  const {
    tableDisabled,
    uploadFileType,
    availableStorage,
    storedFileToLocalforageName,
    setFile,
    ifDeployFile,
    ifNubisDrive,
    setSelectedIds,
    changeHanlder,
    id,
  } = props;
  const classes = useStyles();
  const [fileName, setFileName] = useState("");
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileUploadErrorMsg, setFileUploadErrorMsg] = useState("");
  const [excelFile, setExcelFile] = useState();

  const handleFileUpload = (event) => {
    const file = event.target.files;

    // reset the file name: in case, user clicks input again without a file chosen
    resetStoredValueToLocalforage();
    setFileName();

    if (file && file[0]) {
      let fileName = file[0].name;
      let fileExtension = fileName.split(".").pop();
      let fileSize = file[0].size;

      if (uploadFileType !== "file") {
        uploadExcelFile(fileExtension, file[0], fileName);
      }
      if (
        uploadFileType === "file" &&
        availableStorage &&
        availableStorage !== "unlimited"
      ) {
        uploadFileWithSizeCheck(fileSize, file[0], fileName);
      }
      if (
        uploadFileType === "file" &&
        (!availableStorage || availableStorage === "unlimited")
      ) {
        uploadFile(file[0], fileName);
      }
    }
  };

  const uploadExcelFile = (fileExtension, file, fileName) => {
    if (excelExtensionArray.includes(fileExtension)) {
      uploadFile(file, fileName);
      if (setSelectedIds) setSelectedIds(file);
    } else {
      setFileUploadError(true);
      setFileUploadErrorMsg("uploadExcelFileErrorMsg");
    }
  };

  const uploadFileWithSizeCheck = (fileSize, file, fileName) => {
    if (availableStorage >= fileSize) {
      uploadFile(file, fileName);
    } else {
      setFileUploadError(true);
      setFileUploadErrorMsg("uploadFileSizeErrorMsg");
    }
  };

  const uploadFile = (file, fileName) => {
    setFileUploadError(false);
    setExcelFile(file);
    setFileName(fileName);
    // for deploy file to set file state -> check if it can be auto-launched
    if (ifDeployFile || ifNubisDrive) {
      setFile(file);
    }
    if (changeHanlder) {
      changeHanlder();
    }
  };

  const storedValueToLocalforage = async (excelFile) => {
    await localforage.setItem(storedFileToLocalforageName, excelFile);
  };

  const resetStoredValueToLocalforage = async () => {
    await localforage.removeItem(storedFileToLocalforageName);
  };

  useEffect(() => {
    setFileName("");
    setExcelFile();
    resetStoredValueToLocalforage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (tableDisabled === true) {
      setFileUploadError(false);
    } else {
      storedValueToLocalforage(excelFile);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableDisabled]);

  useEffect(() => {
    if (excelFile) storedValueToLocalforage(excelFile);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [excelFile]);

  return (
    <div className={classes.root}>
      <Button
        variant="outlined"
        component="label"
        disabled={tableDisabled}
        className={classes.deviceExcelFileUploadMainInput}
      >
        {fileName ? (
          <FileNameText fileName={fileName} />
        ) : (
          `${t("task.uploadFile")}`
        )}
        <AttachFileRoundedIcon />
        <input
          type="file"
          id={id ? id : "fileUpload"}
          onChange={handleFileUpload}
          className={classes.hiddenInput}
        />
      </Button>
      {fileUploadError ? (
        <ExcelFileErrorMsg errorMsg={fileUploadErrorMsg} />
      ) : (
        ""
      )}
    </div>
  );
};

export default FileUpload;
