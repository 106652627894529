import React, { useState, useEffect, useRef } from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import useStyles from '../styles/fileBrowserStyle';
import * as localforage from 'localforage';
import { useParams } from "react-router-dom";
import { useTranslation } from "../langs/useTranslation";
import SharedFileTable from '../components/sharedWithMe/SharedFileTable';
import { NestedBreadCrumbs } from '../components/device/BreadCrumbs';
import { table } from '../helpers/sharedWithMeTable';
import { addNewLinkToBreadcrumbs, removeClickedLinkFromBreadcrumbs, storeLinkArray, updateBreadcrumbsWhenBackButtonClicked } from '../helpers/handleNubisDrivePageUpdate';
import { preventDoubleClickFunction } from '../helpers/preventDoubleClickFunction';
import { TableTitleWithRenewBtn } from '../components/commons/Titles';

const SharedWithMe = () => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('nubisDrive');
    let { id } = useParams();
    const [linkArray, setLinkArray] = useState([{ name: `${t('sharedWithMe.pageTitle')}`, parent_id: '' }]);
    const [parentId, setParentId] = useState('');
    const [toPreviousPage, setToPreviousPage] = useState(false);
    const rootFolderLinkName = 'shared-with-me';
    const breadcrumbsLocalforageKeyname = 'sharedWithMeBreadcrumbs';

    const handleNestedBreadcrumbsClick = async (e, i) => {
        removeClickedLinkFromBreadcrumbs(e, i, parentId, setParentId, setLinkArray, rootFolderLinkName, breadcrumbsLocalforageKeyname);
    }

    const handleReloadDataTable = id => {
        setParentId(id);
        table(t, id, linkArray, handleFolderClick);
    }

    const handleFolderClick = (e, value, id) => {
        preventDoubleClickFunction(e, addNewLinkToBreadcrumbs(value, id, parentId, setParentId, setLinkArray, rootFolderLinkName, breadcrumbsLocalforageKeyname));
    }

    const getStoredLinkArray = async () => {
        // for window reload, check if the link array with right breadcrumbs path is stored
        if (!id) {
            setLinkArray(linkArray);
            storeLinkArray(breadcrumbsLocalforageKeyname, linkArray);
        } else {
            let storedLinkArray = await localforage.getItem(breadcrumbsLocalforageKeyname);
            setLinkArray(storedLinkArray);
        }
    }


    useEffect(() => {
        if (!mounted.current) {
            getStoredLinkArray();
            mounted.current = true;
        }
    })

    useEffect(() => {
        handleReloadDataTable(parentId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parentId])

    useEffect(() => {
        if (toPreviousPage && linkArray.length >= 1) {
            let idForTable = linkArray.length > 2 ? linkArray[linkArray.length - 2].parent_id : '';
            handleReloadDataTable(idForTable);
        }
        setToPreviousPage(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [toPreviousPage])

    // when refreshing the window, set the id parameter from url to parentId
    window.onload = function () {
        handleReloadDataTable(id ? id : '');
    };

    // when clicking the back button on browser
    window.onpopstate = () => {
        setToPreviousPage(true);
        updateBreadcrumbsWhenBackButtonClicked(setLinkArray, parentId, id, setParentId, rootFolderLinkName, breadcrumbsLocalforageKeyname);
    }

    return (
        <div className={classes.root}>
            <NestedBreadCrumbs
                onClick={(e, i) => handleNestedBreadcrumbsClick(e, i)}
                linkArray={linkArray}
                pageName='sharedWithMe' />
            <Typography variant="h4" className={classes.pageTitle}>{t('sharedWithMe.pageTitle')}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Paper className={`${classes.tableMoveTopReset} ${classes.tableMoveBottomReset}`}>
                        <TableTitleWithRenewBtn
                            title='sharedFiles'
                            handleReloadDataTable={() => handleReloadDataTable(parentId)}
                        />
                        <SharedFileTable />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default SharedWithMe;
