import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import AddOrEditFileForm from './AddOrEditFileForm';
import AvailableStorage from './AvailableStorage';

const AddOrEditFileDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('nubisDrive');
    const { onClose, open, isFECHQ, companyId, availableStorage, getAvailableStorageData, parentId, setRefreshCapacity, reloadTable } = props;
    const [ isUploading, setIsUploading ] = useState(false);

    useEffect(()=>{
        setIsUploading(false);
    },[]);
    
    return(
        <Dialog
            maxWidth="sm"
            className={classes.dialog}
            open={open}
            onClose={isUploading ? null : onClose}
            aria-labelledby="add-file"
            aria-describedby="add-file-confirmation"
        >
            <DialogTitle id="add-file">
            {t('dialog.uploadFileToNubisDrive')}
            </DialogTitle>
            <DialogContent className='uploadFileOnlyDialog'>
                <AvailableStorage 
                    availableStorage={availableStorage} />
                <AddOrEditFileForm 
                    open={open}
                    parentId={parentId}
                    availableStorage={availableStorage}
                    companyId={companyId}
                    isFECHQ={isFECHQ}
                    onClose={onClose} 
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    reloadTable={reloadTable}
                    getAvailableStorageData={getAvailableStorageData}
                    setRefreshCapacity={setRefreshCapacity}
                />
            </DialogContent>
        </Dialog>
    )
}

export default AddOrEditFileDialog;