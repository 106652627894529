import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../langs/useTranslation";
import { getDeviceKeyName } from "./../../helpers/commonHelper";
import LoadingIndicator from "../commons/LoadingIndicator";

function NetworkInfo(props) {
  const { t } = useTranslation("device");
  const classes = useStyles();
  const { device_id } = useParams();
  const { network } = props;
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(true);
  const deviceIdFromQueryString = device_id;

  const getStoredInfo = async () => {
    const gotInfo = await getDeviceKeyName(
      "networkInfo",
      deviceIdFromQueryString
    );
    if (gotInfo) {
      const jsonInfo = gotInfo.json;
      setInfo(jsonInfo);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (network && network.json) {
      setInfo(network.json);
      setLoading(false);
    } else {
      getStoredInfo();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network]);

  return (
    <div>
      {loading ? (
        <div className="smaller-loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <TableContainer className={classes.softwareTable}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("networkInformation.modelName")}</TableCell>
                <TableCell>{t("networkInformation.type")}</TableCell>
                <TableCell>{t("networkInformation.ipAddress")}</TableCell>
                <TableCell>{t("networkInformation.macAddress")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {info ? (
                info.map((obj, ArrayIndex) => {
                  return (
                    <TableRow key={ArrayIndex}>
                      <TableCell className={classes.softwareNameCell}>
                        {obj.model_name || "-"}
                      </TableCell>
                      <TableCell>{obj.type || "-"}</TableCell>
                      <TableCell>{obj.private_ip_address || "-"}</TableCell>
                      <TableCell>{obj.mac_address || "-"}</TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow></TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
}

export default NetworkInfo;
