import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBreadcrumbs-root": {
      marginBottom: theme.spacing(3),
    },
    "& .MuiPaper-rounded": {
      marginBottom: "0",
    },
    "& .MuiGrid-spacing-xs-3 > .MuiGrid-item": {
      maxHeight: "550px",
      [theme.breakpoints.down("lg")]: {
        maxHeight: "400px",
      },
      [theme.breakpoints.down("md")]: {
        maxHeight: "480px",
      },
      [theme.breakpoints.down("sm")]: {
        maxHeight: "500px",
      },
    },
    "& .MuiGrid-item>.MuiPaper-rounded": {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    "& .colorCube": {
      display: "inline-block !important",
      marginRight: "5px !important",
      width: "10px",
      height: "10px",
    },
    "& .graphOrange": {
      background: "#ff9e40",
    },
    "& .graphGreen": {
      background: "#1DBBD4",
    },
    "& .graphYellow": {
      background: "#f3d067",
    },
    "& .graphBlue": {
      background: "#4f9eff",
    },
    "& .graphPurple": {
      background: "#ac6eec",
    },
    "& .graphGray": {
      background: "#aaa",
    },
    "& .graphBrown": {
      background: "#c39584",
    },
    "& .graphError": {
      background: "#ec2121",
    },
    "& .MuiTypography-subtitle2": {
      display: "flex !important",
      alignItems: "center !important",
    },
    "& .pieChart": {
      margin: "auto !important",
      minHeight: "180px !important",
      display: "flex",
      alignItems: "center",
      "& canvas": {
        height: "100%",
        maxHeight: "210px !important",
        margin: "initial !important",
      },
    },
    "& .loading-wrapper": {
      height: "100%",
      display: "flex",
      alignItems: "center",
    },
    "& .barChart": {
      margin: "24px 0 20px !important",
      position: "relative",
      height: "calc( 100% - 50px ) !important",
      [theme.breakpoints.down("lg")]: {
        minHeight: "200px",
      },
      [theme.breakpoints.down("md")]: {
        minHeight: "300px",
      },
      "& canvas": {
        height: "100% !important",
        position: "absolute",
      },
    },
  },
  clientDistrubutionBarProgress: {
    display: "flex",
    overflow: "hidden",
    background: "#e9ecef",
    width: "100%",
    borderRadius: "3px",
    height: "10px",
  },
  clientDistrubutionBarProgressSon: {
    display: "flex",
    background: "#aaa",
    whiteSpace: "nowrap",
    overflow: "hidden",
    height: "10px",
    width: "10%",
  },
  clientDistrubutionMain: {
    maxHeight: "300px",
    overflowY: "scroll",
  },
  clientDistrubutionBar: {
    margin: "10px 5px 0 0",
    display: "flex",
    flexDirection: "column",
  },
  clientDistrubutionBarHeader: {
    display: "flex",
    justifyContent: "space-between",
  },
  clientDistrubutionBarHeaderLeft: {
    display: "flex",
    alignItems: "center",
    height: "13px",
  },
  tooltipTab: {
    display: "flex",
    alignItems: "center",
    height: "10px",
  },
  tooltipBox: {
    width: "10px",
    height: "10px",
    marginRight: "5px",
  },
  clientDistrubutionBarHeaderIcon: {
    height: "13px",
    width: "13px",
    marginRight: "5px",
    "& img": {
      height: "13px",
      width: "13px",
    },
  },
  clientDistrubutionTab: {
    display: "flex",
    alignItems: "center",
    margin: "0.5px 0",
  },
  clientDistrubutionTabText: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  clientDistrubutionTabColor: {
    width: "10px",
    height: "10px",
    marginRight: "3px",
  },
  maxHeight_300: {
    maxHeight: "300px",
  },
  marginBottom_24: {
    marginBottom: "24px !important",
  },
  heightInitial: {
    height: "initial !important",
  },
  allHeightGrid: {
    height: "100% !important",
  },
  nubsiDevicesAmountWrap: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    height: "100% !important",
    "&>div": {
      height: "50%",
      display: "flex",
      justifyContent: "space-between",
      flexDirection: "column",
    },
    "& a": {
      color: "#fff !important",
      textDecoration: "none !important",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
  },
  devicesAmountBox: {
    color: "#fff !important",
    marginBottom: "0 !important",
    padding: "10px 14px !important",
    height: "calc( 100% - 6px ) !important",
    "&:hover": {
      boxShadow: "0 0 8px #ccc",
    },
    [theme.breakpoints.down("md")]: {
      "& h4": {
        fontSize: "50px !important",
      },
    },
    "& .smaller-loading-indicator": {
      maxHeight: 30,
      width: "100%",
      height: "60px",
      "& .loadingio-spinner-ellipsis-0e9htm6lpb7": {
        background: "none",
        position: "relative",
        overflow: "initial",
        "& .ldio-tk8dyrglbc div": {
          background: "#fff !important",
        },
      },
    },
  },
  devicesOnlineBox: {
    background: "#1DBBD4",
  },
  devicesOfflineBox: {
    background: "#ccc",
  },
  cardTitleWrapWithReloadIcon: {
    display: "flex !important",
    justifyContent: "space-between !important",
    marginBottom: "8px !important",
    fontWeight: "bold !important",
  },
  osTypeTitleBox: {
    padding: "10px 14px 5px !important",
    cursor: "pointer",
    "&:hover .flex_align_center": {
      background: "#f0f0f0",
    },
    [theme.breakpoints.down("md")]: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 14px !important",
      flexDirection: "row !important",
    },
  },
  hardwareModelTitleBox: {
    padding: "5px 8px 0px 14px !important",
    '&.hover':{
      cursor: "pointer"
    },
    "&:hover .flex_align_center": {
      background: "#f0f0f0",
    },
    [theme.breakpoints.down("lg")]: {
      padding: "0px 8px 0px 14px !important",
    },
  },
  pieWithDayText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  dayTextArea: {
    display: "flex",
    flexDirection: "column",
    width: "70%",
  },
  dayTextOuter: {
    display: "flex",
    justifyContent: "space-between",
  },
  hardwareModelLabels: {
    marginBottom: "10px !important",
    "& > .MuiGrid-item": {
      padding: "0 8px",
    },
  },
  scrollModelLabels: {
    maxHeight: "80px !important",
    overflowY: "scroll !important",
    marginBottom: "10px !important",
    "& .pointer_label": {
      cursor: "pointer",
      "&:hover .MuiTypography-root": {
        background: "#f0f0f0",
      },
    },
    "& > .MuiGrid-item": {
      padding: "0 8px",
    },
  },
  scrollModelLabelsBig: {
    maxHeight: "190px !important",
    overflowY: "scroll !important",
    marginBottom: "10px !important",
    transform: "translateX(-15px)",
    "& .pointer_label": {
      cursor: "pointer",
      "&:hover .MuiTypography-root": {
        background: "#f0f0f0",
      },
    },
    "& > .MuiGrid-item": {
      padding: "0 8px",
    },
  },
  deviceGroupsPaper: {
    justifyContent: "initial !important",
    "&>h6": {
      marginBottom: "16px !important",
    },
    "&>.MuiGrid-container": {
      minHeight: "180px !important",
      overflow: "auto",
      marginBottom: "10px !important",
      alignItems: "stretch !important",
      justifyContent: "flex-start",
    },
    "& a": {
      textDecoration: "none !important",
    },
  },
  deviceGroupsAmountBox: {
    color: "#000",
    padding: "8px 16px 8px 8px !important",
    borderRadius: "0",
    borderBottom: "1px solid #000",
    transition: "0.2s",
    "&:hover": {
      boxShadow: "0 0 8px #ccc",
    },
  },
  deviceHealthStatusGrid: {
    maxHeight: "550px",
    [theme.breakpoints.down("lg")]: {
      maxHeight: "400px",
    },
    [theme.breakpoints.down("md")]: {
      maxHeight: "400px",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "500px",
    },
  },
  deviceHealthStatusWrap: {
    height: "calc( 100% - 80px ) !important",
    "&>.MuiGrid-item": {
      padding: "5%",
      marginBottom: "24px !important",
      [theme.breakpoints.down("md")]: {
        padding: "1% 5% 2%",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "48px !important",
      },
    },
    "& .pieChart": {
      height: "calc( 100% - 40px ) !important",
      minHeight: "200px !important",
      margin: "initial !important",
      display: "flex",
      alignItems: "center",
      "& canvas": {
        height: "100%",
        margin: "initial !important",
      },
    },
    "& .MuiTypography-alignCenter": {
      justifyContent: "center !important",
      marginBottom: "10px !important",
    },
  },
  deviceHealthStatusLabels: {
    display: "flex",
    alignItems: "center !important",
    justifyContent: "center !important",
  },
  VNCAddressTable: {
    justifyContent: "flex-start !important",
    "& table a": {
      textDecoration: "underline !important",
    },
    "& h6": {
      width: "max-content !important",
      zIndex: "2 !important",
      "& svg": {
        marginLeft: "10px !important",
      },
    },
  },
  vncSubtitle: {
    fontSize: "12px !important",
    marginLeft: "10px !important",
    lineHeight: "32px",
  },
  deviceAmountTextSection: {
    textAlign: "right !important",
  },
  reload_btn: {
    cursor: "pointer !important",
  },
  graphUnit: {
    position: "relative",
    top: "20px",
    color: "#666",
    left: "20px",
  },
}));

export default useStyles;
