import React from 'react';
import { TextField, Typography, Box } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';
import { useTranslation } from "../../langs/useTranslation";
import { PasswordTextField } from './PasswordTextField';

export const NoteTextfield = props => {
    const { t } = useTranslation('adminUtility')
    const { disabled, onChange, fullWidth } = props;
    const classes = useStyles();
    
    return (
        <TextField
            id="note"
            label={t('dialog.note')}
            multiline
            fullWidth={fullWidth}
            rows={4}
            onChange={onChange}
            variant="outlined"
            className={classes.noteTextField}
            disabled={disabled}
        />
    )
}

export const EnableTextfield = props => {
    const { t } = useTranslation('task')
    const classes = useStyles();
    const { CommandTextFieldPlaceHolder, formikOnChange, formikOnBlur, formikValue, formikName } = props;
   
    return(
        <>
            <Typography variant="h6">{t('task.enableApplication')}</Typography>
            <TextField 
                placeholder={CommandTextFieldPlaceHolder} 
                onChange={formikOnChange} onBlur={formikOnBlur} 
                value={formikValue} 
                name={formikName} 
                fullWidth
                className={classes.inputField}
            />
        </>
    )
}
export const CommandTextField = props => {
    const { t } = useTranslation('task')
    const classes = useStyles();
    const { CommandTextFieldPlaceHolder, formikOnChange, formikOnBlur, formikValue, formikName } = props;
   
    return(
        <>
            <Typography variant="h6">{t('task.typeCommandForClosingYourApplicationOptional')}</Typography>
            <TextField 
                placeholder={CommandTextFieldPlaceHolder} 
                onChange={formikOnChange} onBlur={formikOnBlur} 
                value={formikValue} 
                name={formikName} 
                fullWidth
                className={classes.inputField}
            />
        </>
    )
}

export const CommandTextFieldForDeployFile = props => {
    const { t } = useTranslation('task')
    const classes = useStyles();
    const { CommandTextFieldPlaceHolder, formikOnChange, formikOnBlur, formikValue, formikName } = props;
   
    return(
        <>
            <Typography variant="h6">{t('task.typeCommandForFileDeployment')}</Typography>
            <TextField 
                placeholder={CommandTextFieldPlaceHolder} 
                onChange={formikOnChange} onBlur={formikOnBlur} 
                value={formikValue} 
                name={formikName} 
                fullWidth
                className={classes.inputField}
            />
        </>
    )
}

export const MutipleDevicesNewVncPasswordTextField = props => {
    const classes = useStyles();
    const { placeholder, label, formikOnChange, formikValue, formikName, error, errorText, isValidating } = props;
   
    return(
        <Box width="250px">
            <PasswordTextField 
                placeholder={placeholder} 
                onChange={formikOnChange}
                value={formikValue} 
                name={formikName} 
                className={classes.inputField}
                label={label}
                error={error} 
                errorText={errorText}
                isValidating={isValidating}
            />
        </Box>
    )
}