import React from "react";
import ResponsiveDrawer from "../../components/commons/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import useStyles from "../../styles/commonLayoutStyle";


const CommonLayout = (props) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <CssBaseline />
            <ResponsiveDrawer />
            <main className={classes.content}>{props.children}</main>
        </div>
    );
};

export default CommonLayout;
