import React from 'react';
import LoadingIndicator from './LoadingIndicator';

const SmallerLoadingIndicator = () => {
    return(
        <div className="smaller-loading-indicator">
            <LoadingIndicator />
        </div>
    )
}

export default SmallerLoadingIndicator;