import React, { useState, useEffect } from "react";
import {
  MenuItem,
  FormControl,
  Select,
  Typography,
  Paper,
} from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import DeviceDetailSingleGraph from "./DeviceDetailSingleGraph";
import DeviceDetailConnectionGraph from "./DeviceDetailConnectionGraph";
import DeviceCpuTemperature from "./DeviceCpuTemperature";
import { useTranslation } from "../../langs/useTranslation";
import { Dialog, DialogTitle } from "@material-ui/core";
import expandIcon from "../../assets/images/expand.svg";
import { getOperationApi } from "../../apis/deviceDetailAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import Tooltip from "@material-ui/core/Tooltip";

const DeviceDetailGraphs = (props) => {
  const { t } = useTranslation("device");
  const classes = useStyles();
  const { hardware, onlineStatus, deviceData } = props;
  const [mins, setMins] = useState(30);
  const [graphTitle, setGraphTitle] = useState("");
  const [type, setType] = useState("CPULoad");
  const [operationData, setOperationData] = useState({});
  const [open, setOpen] = useState(false);

  const roundToTwo = (num) => {
    return +(Math.round(num + "e+2") + "e-2");
  };

  const expandHandler = (e) => {
    setGraphTitle(e);
    setType(e);
    setOpen(true);
  };

  const switchGraphTitle = (e) => {
    switch (e) {
      case "connection":
        return t("deviceInformation.connection");
      case "CPULoad":
        return t("deviceInformation.CPULoading");
      case "CPUTemperature":
        return t("deviceInformation.CPUTemperature");
      case "RAMLoading":
        return t("deviceInformation.RAMLoading");
      case "storageUsage":
        return t("deviceInformation.storageUsage");
      default:
        break;
    }
  };

  const switchData = () => {
    switch (type) {
      case "connection":
        return (
          <DeviceDetailConnectionGraph
            onlineStatus={onlineStatus}
            mins={mins}
            graphType="connection"
          />
        );
      case "CPULoad":
        return (
          <DeviceDetailSingleGraph
            onlineStatus={onlineStatus}
            mins={mins}
            graphType="cpu_usage"
          />
        );
      case "CPUTemperature":
        return (
          <DeviceCpuTemperature
            onlineStatus={onlineStatus}
            hardware={hardware}
            mins={mins}
            graphType="cpu_temperature"
          />
        );
      case "RAMLoading":
        return (
          <DeviceDetailSingleGraph
            onlineStatus={onlineStatus}
            mins={mins}
            graphType="ram_usage"
          />
        );
      case "storageUsage":
        return (
          <DeviceDetailSingleGraph
            onlineStatus={onlineStatus}
            mins={mins}
            graphType="storage_usage"
          />
        );

      default:
        break;
    }
  };

  const handleClose = () => {
    setMins(30);
    setOpen(false);
  };

  const storagesUseValue = () => {
    const availableCapacity = operationData?.storages?.available_capacity;
    const totalCapacity = operationData?.storages?.total_capacity;
    if (
      availableCapacity &&
      totalCapacity &&
      availableCapacity.split(" ")[0] &&
      totalCapacity.split(" ")[0]
    ) {
      const usedCapacity =
        parseFloat(totalCapacity.split(" ")[0]) -
        parseFloat(availableCapacity.split(" ")[0]);
      return roundToTwo(usedCapacity);
    } else {
      return 0;
    }
  };
  const cpuTemperatureArea = () => {
    let num = 4;
    if (onlineStatus === "offline") {
      return (
        <>
          <div
            className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
          ></div>
          <div
            className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
          ></div>
          <div
            className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
          ></div>
          <div
            className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
          ></div>
        </>
      );
    } else {
      if (operationData?.cpuTemperature) {
        num = 4 - operationData?.cpuTemperature.length;
        return (
          <>
            {operationData?.cpuTemperature?.map((e, i) => (
              <div
                className={`${classes.cpuTemperatureOuter} ${
                  e <= 50 ? classes.normalTemperature : classes.warnTemperature
                }`}
                key={i}
              >
                <Typography
                  variant="h5"
                  className={`${
                    e <= 50
                      ? classes.normalTemperature
                      : classes.warnTemperature
                  }`}
                >
                  {e}℃
                </Typography>
              </div>
            ))}
            {num >= 1 && (
              <div
                className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
              ></div>
            )}
            {num >= 2 && (
              <div
                className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
              ></div>
            )}
            {num >= 3 && (
              <div
                className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
              ></div>
            )}
            {num >= 4 && (
              <div
                className={`${classes.cpuTemperatureOuter} ${classes.noMoreCpu}`}
              ></div>
            )}
          </>
        );
      } else {
        return (
          <div className="flex_center w-100">
            <SmallerLoadingIndicator />
          </div>
        );
      }
    }
  };
  const storagesUsePercent = () => {
    const availableCapacity = operationData?.storages?.available_capacity;
    const totalCapacity = operationData?.storages?.total_capacity;
    if (
      availableCapacity &&
      totalCapacity &&
      availableCapacity.split(" ")[0] &&
      totalCapacity.split(" ")[0]
    ) {
      const usedCapacity =
        100 *
        ((parseInt(totalCapacity.split(" ")[0]) -
          parseInt(availableCapacity.split(" ")[0])) /
          parseInt(totalCapacity.split(" ")[0]));
      return Math.round(usedCapacity);
    } else {
      return 0;
    }
  };

  const getOperation = async () => {
    let data = await getOperationApi(deviceData?.id);
    if (data) {
      setOperationData(data);
    }
  };

  useEffect(() => {
    if (deviceData) {
      getOperation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceData]);

  return (
    <div className={classes.deviceDetailGraphs}>
      <div className={classes.operationArea}>
        <div className={classes.operationAreaFirstLine}>
          <Paper className={classes.operationSm}>
            <Typography variant="body1" color="secondary">
              {t("deviceInformation.connection")}
            </Typography>
            <Typography
              variant="h4"
              className={`${classes.onlineOfflineText} ${
                onlineStatus === "online"
                  ? classes.online
                  : classes.offline
              }`}
            >
              {onlineStatus ? (
                <div className={classes[onlineStatus]}>
                  {onlineStatus[0].toUpperCase() +
                    onlineStatus.slice(1)}
                </div>
              ) : (
                <SmallerLoadingIndicator />
              )}
            </Typography>
            <div
              className={classes.expandIcon}
              onClick={() => expandHandler("connection")}
            >
              <img src={expandIcon} alt="expand_more" />
            </div>
          </Paper>
          <Paper className={classes.operationSm}>
            <Typography variant="body1" color="secondary">
              {t("deviceInformation.CPULoading")}
            </Typography>
            {onlineStatus === "offline" ? (
              <Typography
                variant="h4"
                className={`${classes.warnOrNotText} ${classes.notWarn}`}
              >
                -
              </Typography>
            ) : (
              <Typography
                variant="h4"
                className={`${classes.warnOrNotText} ${
                  operationData?.cpuLoading <= 50
                    ? classes.notWarn
                    : classes.warn
                }`}
              >
                {operationData?.cpuLoading ||
                operationData?.cpuLoading === 0 ? (
                  `${operationData?.cpuLoading}%`
                ) : (
                  <SmallerLoadingIndicator />
                )}
              </Typography>
            )}
            <div
              className={classes.expandIcon}
              onClick={() => expandHandler("CPULoad")}
            >
              <img src={expandIcon} alt="expand_more" />
            </div>
          </Paper>
          <Paper className={classes.operationSm}>
            <Typography variant="body1" color="secondary">
              {t("deviceInformation.RAMLoading")}
            </Typography>
            {onlineStatus === "offline" ? (
              <Typography
                variant="h4"
                className={`${classes.warnOrNotText} ${classes.notWarn}`}
              >
                -
              </Typography>
            ) : (
              <Typography
                variant="h4"
                className={`${classes.warnOrNotText} ${
                  operationData?.ramLoading <= 50
                    ? classes.notWarn
                    : classes.warn
                }`}
              >
                {operationData?.ramLoading ||
                operationData?.ramLoading === 0 ? (
                  `${operationData?.ramLoading}%`
                ) : (
                  <SmallerLoadingIndicator />
                )}
              </Typography>
            )}
            <div
              className={classes.expandIcon}
              onClick={() => expandHandler("RAMLoading")}
            >
              <img src={expandIcon} alt="expand_more" />
            </div>
          </Paper>
        </div>
        <div className={classes.operationAreaSecondLine}>
          <Paper className={classes.operationLg}>
            <Typography variant="body1" color="secondary">
              {t("deviceInformation.CPUTemperature")}
            </Typography>
            <div className={classes.cpuTemperatureArea}>
              {cpuTemperatureArea()}
            </div>
            <div
              className={classes.expandIcon}
              onClick={() => expandHandler("CPUTemperature")}
            >
              <img src={expandIcon} alt="expand_more" />
            </div>
          </Paper>
          <Paper className={classes.operationMd}>
            <Typography variant="body1" color="secondary">
              {t("deviceInformation.hardwareStorageTitle")}
            </Typography>
            {(operationData?.storages?.available_capacity ||
              operationData?.storages?.available_capacity === 0) &&
            (operationData?.storages?.total_capacity ||
              operationData?.storages?.total_capacity === 0) ? (
              <Tooltip title={`${storagesUsePercent()}%`} placement="bottom">
                <div>
                  <div style={{ display: "flex" }}>
                    <Typography
                      variant="h4"
                      className={`${classes.warnOrNotText} ${
                        storagesUsePercent() > 50
                          ? classes.warn
                          : classes.notWarn
                      }`}
                    >
                      {storagesUseValue() ? storagesUseValue() + " GB" : "- GB"}
                    </Typography>
                    <Typography
                      variant="h4"
                      style={{ margin: "0 5px" }}
                      className={`${classes.warnOrNotText} ${classes.notWarn}`}
                    >
                      /
                    </Typography>
                    <Typography
                      variant="h4"
                      className={`${classes.warnOrNotText} ${classes.notWarn}`}
                    >
                      {operationData?.storages?.total_capacity || "- GB"}
                    </Typography>
                  </div>
                  <div className={classes.storageLine}>
                    <div
                      style={{
                        width: `${storagesUsePercent()}%`,
                        background:
                          storagesUsePercent() > 50 ? "#f98746" : "#4BC0C0",
                      }}
                      className={classes.storageLineColor}
                    ></div>
                  </div>
                </div>
              </Tooltip>
            ) : (
              <SmallerLoadingIndicator />
            )}
            <div
              className={classes.expandIcon}
              onClick={() => expandHandler("storageUsage")}
            >
              <img src={expandIcon} alt="expand_more" />
            </div>
          </Paper>
        </div>
      </div>
      <Dialog
        maxWidth={"lg"}
        onClose={handleClose}
        open={open}
        className={`${classes.modal}`}
      >
        <DialogTitle align="center">
          {t("deviceInformation.systemTitle")}
        </DialogTitle>
        <div className={classes.graphTitleWithSelect}>
          <Typography
            variant="h6"
            className={classes.deviceDetail_SectionTitle}
          >
            {switchGraphTitle(graphTitle)}
          </Typography>
          <div className="flex_center">
            <Typography variant="body2">
              {t("deviceDetail.inThePast")}
            </Typography>
            <FormControl className={classes.getTimeSelect}>
              <Select
                variant="outlined"
                value={mins}
                onChange={(event) => setMins(event.target.value)}
                MenuProps={{ disableScrollLock: true }}
              >
                <MenuItem value={30}>0.5</MenuItem>
                <MenuItem value={60}>1</MenuItem>
                <MenuItem value={360}>6</MenuItem>
                <MenuItem value={720}>12</MenuItem>
                <MenuItem value={1440}>24</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="body2">{t("deviceDetail.hours")}</Typography>
          </div>
        </div>
        {switchData()}
      </Dialog>
    </div>
  );
};

export default DeviceDetailGraphs;
