import React from 'react';
import { Box } from '@material-ui/core';
import EditableFieldForDatatable from '../commons/EditableFieldForDatatable';
import { fileTypeIconDeterminator } from '../../helpers/fileTypeIconDeterminator';

const EditableFileName = props => {
    const {
        extension, data, id, className, 
        parentId, ifFolder,
        disabled, linkArray, setLinkArray, setParentId, localforageKeyName /* file browser: for updating breadcrumbs after renamed */
    } = props;

    return(
        <Box 
            className={className} 
            display="flex" 
            alignItems="center">
            {fileTypeIconDeterminator(extension)}
            <EditableFieldForDatatable 
                id={id} 
                rename="fileName" 
                inputValue={data} 
                linkArray={linkArray}
                setLinkArray={setLinkArray}
                extension={extension}
                ifFolder={ifFolder}
                parentId={parentId}
                setParentId={setParentId}
                disabled={disabled}
                localforageKeyName={localforageKeyName}  />
        </Box>
    )

}

export default EditableFileName;