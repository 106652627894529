import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { currentDateInUTC, scheduledDateInUTC } from '../helpers/datetimeHandlers';
import { successHandling } from '../helpers/successHandling';
import { apiSelectedDeviceIdsHandler } from '../helpers/apiSelectedIdsHandler';

export const deployFileAPI = async (deviceId, filterDevicesBy, selectedDevicesIds, file, driveStorageId, executeType, autoLaunch, datetime, location, command, t, successfulText, values) => {

    let formData = new FormData();

    if (deviceId) {
        formData.append('device_id', deviceId);
    }

    apiSelectedDeviceIdsHandler(formData, filterDevicesBy, selectedDevicesIds);
    formData.append('execute_type', executeType);
    formData.append('should_launch_file', autoLaunch);
    formData.append('execute_datetime', datetime === 'immediately' ? currentDateInUTC() : scheduledDateInUTC(datetime));
    formData.append('file_store_location', location);
    formData.append('close_application_command', command);
    if (values.files === 'file_from_nubis_drive') {
        formData.append('drive_storage_id', driveStorageId)
    } else {
        formData.append('s3_path', file.filePath)
        formData.append('file_md5', file.md5)
    };
    formData.append('auto_launch_command', "");

    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/command/deploy-file`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
