import React, { useRef, useEffect, useState, useContext } from 'react';
import { MapContext } from './MapWrapper';

/**
 * @todo (data: Array<{serailNumber: string, latitude: number, longtitude: number}>) => Markers on the map
 * @todo? use different region setting to initialize js-api-loader
 */

export default function GoogleMap({
    center,
    zoom,
    height
}){
    
    //const [ map, setMap ] = useState(null);
    const { map, setMap } = useContext(MapContext);
    const ref = useRef(null);

    useEffect(() => {
        //console.log("Rerender:", map)
        if(ref.current){
            setMap(new window.google.maps.Map(ref.current, {
                center,
                zoom,
                fullscreenControl: false,
                mapTypeControl: false,
                streetViewControl: false
            }))
        }
    }, []);

    return <div ref={ref} style={{height: height}} />
}