import React from "react";
import Switch from "@material-ui/core/Switch";
import { withStyles } from "@material-ui/core/styles";

const AntSwitch = (props) => {
    const { disabled, checked, onChange } = props;
    const StyleSwitch = withStyles((theme) => ({
        root: {
            width: 28,
            height: 14,
            padding: 0,
            display: "flex",
            alignItems: "center",
        },
        switchBase: {
            padding: 2,
            color: theme.palette.grey[500],
            "&$checked": {
                transform: "translateX(12px)",
                color: theme.palette.common.white,
                "& + $track": {
                    opacity: 1,
                    backgroundColor: theme.palette.primary.main,
                    borderColor: theme.palette.primary.main,
                },
            },
        },
        thumb: {
            width: 10,
            height: 10,
            marginLeft: "1px",
            color: "#fff",
            boxShadow: "none",
        },
        track: {
            border: `1px solid #b6b6b6`,
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor: "#b6b6b6",
        },
        checked: {},
    }))(Switch);
    return <StyleSwitch disabled={disabled} checked={checked} onChange={onChange} />;
};

export default AntSwitch;
