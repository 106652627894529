import React, { useState, useEffect, useCallback } from "react";
import {
  AppBar,
  Tabs,
  Tab,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import LoadingIndicator from "../commons/LoadingIndicator";
import useStyles from "../../styles/deviceStyle";
import { useParams } from "react-router-dom";
import SwipeableViews from "react-swipeable-views";
import HasPermission from "../commons/HasPermission";
import { useTheme } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  windowsDeviceSoftwareApplicationInfoMap,
  windowsDeviceSoftwareUpdateInfoMap,
  androidDeviceSoftwareInfoMap,
} from "../../helpers/deviceSoftwareInfoMap";
import { deleteAppApi } from "../../apis/deviceDetailAPI";
import { a11yProps, TabPanel } from "../../helpers/tabPanelHandler";
import DeleteAppDialog from "./DeleteAppDialog";
import RefreshBtn from "../commons/RefreshBtn";
import { getSoftwareInfoAPI } from "../../apis/getDeviceInfoAPI";

const SoftwareInfo = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation("device");
  const { device_id, device_os_type } = useParams();
  const { open } = props;
  const [appInfo, setAppInfo] = useState([]);
  const [qfesInfo, setQfesInfo] = useState([]);
  const [deleteData, setDeleteData] = useState({});
  const [deleteCommand, setDeleteCommand] = useState("");
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [noCommand, setNoCommand] = useState(false);
  const [value, setValue] = useState(0);
  const [tab1InfoMap, setTab1InfoMap] = useState([]);
  const [tab2InfoMap, setTab2InfoMap] = useState([]);
  const [loading, setLoading] = useState(true);
  const [refresh, setRefresh] = useState(false);
  const [disableDetectBtn, setDisableDetectBtn] = useState(true);
  const [clickAlready, setClickAlready] = useState(false);
  const [statusSet, setStatusSet] = useState({
    status: "empty",
    updated_at: "",
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const setStates = (gotInfo) => {
    if (gotInfo) {
      setAppInfo([""]);
      setQfesInfo([""]);
      gotInfo.map((item) => {
        if (item.type === "qfes") {
          setQfesInfo(item.json);
        }
        if (item.type === "application") {
          setAppInfo(item.json);
        }
        return false;
      });
      setLoading(false);
    }
  };

  const handleIfOsType = () => {
    if (device_os_type === "windows") {
      setTab1InfoMap(windowsDeviceSoftwareApplicationInfoMap);
      setTab2InfoMap(windowsDeviceSoftwareUpdateInfoMap);
    }
    if (device_os_type === "android") {
      setTab1InfoMap(androidDeviceSoftwareInfoMap);
    }
  };

  const deleteApp = (e) => {
    setNoCommand(false);
    setDeleteCommand("");
    setDeleteData(e);
    setShowDeleteDialog(true);
  };

  const confirmDelete = async () => {
    if (device_os_type === "windows" && !deleteCommand) {
      setNoCommand(true);
      return;
    }
    setDialogLoading(true);
    setNoCommand(false);
    let data = await deleteAppApi(
      [device_id],
      device_os_type === "windows" ? deleteCommand : deleteData.name,
      t,
      "dialog.successDelete"
    );
    if (data && data.message === "success") {
      getList();
      setClickAlready(true);
      setRefresh(true);
      setShowDeleteDialog(false);
      setDialogLoading(false);
    }
  };

  const getList = useCallback(async () => {
    let data = await getSoftwareInfoAPI(device_id);
    if (data && data.status) {
      if (data.status === "completed" || data.status === "failed") {
        setDisableDetectBtn(false);
        setRefresh(false);
      } else {
        setDisableDetectBtn(true);
      }
      setStatusSet({
        status: data.status ? data.status : "empty",
        updated_at: data.updated_at ? data.updated_at : "",
      });
    } else {
      setDisableDetectBtn(false);
    }
    if (data?.software) {
      setStates(data.software);
    }
  }, [device_id]);

  useEffect(() => {
    handleIfOsType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_os_type]);

  useEffect(() => {
    setRefresh(false);
  }, [value]);

  useEffect(() => {
    if (open) {
      setClickAlready(false);
      getList(device_id);
    }
  }, [device_id, getList, open]);

  return (
    <div>
      {loading ? (
        <div className="smaller-loading-indicator">
          <LoadingIndicator />
        </div>
      ) : device_os_type === "windows" ? (
        <>
          {value === 0 && (
            <div className={classes.usbDetectTextAndBtn}>
              <RefreshBtn
                getList={getList}
                statusSet={statusSet}
                open={open}
                refresh={refresh}
                setRefresh={setRefresh}
                disableDetectBtn={disableDetectBtn}
                setDisableDetectBtn={setDisableDetectBtn}
                deviceId={device_id}
                setClickAlready={setClickAlready}
                clickAlready={clickAlready}
              />
            </div>
          )}
          <AppBar position="static" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
            >
              <Tab
                label={t("deviceInformation.softwareApplicationTitle")}
                {...a11yProps(0)}
              />
              <Tab
                label={t("deviceInformation.softwareQFESTitle")}
                {...a11yProps(1)}
              />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tab1InfoMap.map((info, index) => {
                        return (
                          <TableCell key={index} component="th" scope="row">
                            {t(`softwareInformation.${info}`)}
                          </TableCell>
                        );
                      })}
                      <HasPermission permission="single_delete_application">
                        <TableCell key="action" component="th" scope="row">
                          Action
                        </TableCell>
                      </HasPermission>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {appInfo.map((obj, ArrayIndex) => {
                      return (
                        <TableRow key={ArrayIndex}>
                          {tab1InfoMap.map((info, index) => {
                            return (
                              <TableCell key={index}>
                                {obj[info] || "-"}
                              </TableCell>
                            );
                          })}
                          <HasPermission permission="single_delete_application">
                            <TableCell key="actionBtn">
                              {disableDetectBtn ? (
                                <Tooltip
                                  title={t("usbConnections.sendingCommand")}
                                  placement="top"
                                >
                                  <div className="deleteBtnDisabled">
                                    <DeleteIcon />
                                  </div>
                                </Tooltip>
                              ) : (
                                <Tooltip title="Delete" placement="top">
                                  <div
                                    className="deleteBtn"
                                    onClick={() => deleteApp(obj)}
                                  >
                                    <DeleteIcon />
                                  </div>
                                </Tooltip>
                              )}
                            </TableCell>
                          </HasPermission>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      {tab2InfoMap.map((info, index) => {
                        return (
                          <TableCell key={index} component="th" scope="row">
                            {t(`softwareInformation.${info}`)}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {qfesInfo.map((obj, ArrayIndex) => {
                      return (
                        <TableRow key={ArrayIndex}>
                          {tab2InfoMap.map((info, index) => {
                            return (
                              <TableCell key={index}>
                                {obj[info] || "-"}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </TabPanel>
          </SwipeableViews>
        </>
      ) : (
        <TableContainer>
          {value === 0 && (
            <div className={classes.usbDetectTextAndBtn}>
              <RefreshBtn
                getList={getList}
                statusSet={statusSet}
                open={open}
                refresh={refresh}
                setRefresh={setRefresh}
                disableDetectBtn={disableDetectBtn}
                setDisableDetectBtn={setDisableDetectBtn}
                deviceId={device_id}
                setClickAlready={setClickAlready}
                clickAlready={clickAlready}
              />
            </div>
          )}
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                {tab1InfoMap.map((info, index) => {
                  return (
                    <TableCell key={index} component="th" scope="row">
                      {t(`softwareInformation.apk_${info}`)}
                    </TableCell>
                  );
                })}
                <HasPermission permission="single_delete_application">
                  <TableCell key="action" component="th" scope="row">
                    Action
                  </TableCell>
                </HasPermission>
              </TableRow>
            </TableHead>
            <TableBody>
              {appInfo.map((obj, ArrayIndex) => {
                return (
                  <TableRow key={ArrayIndex}>
                    {tab1InfoMap.map((info, index) => {
                      return (
                        <TableCell key={index}>{obj[info] || "-"}</TableCell>
                      );
                    })}
                    <HasPermission permission="single_delete_application">
                      <TableCell key="actionBtn">
                        {disableDetectBtn ? (
                          <Tooltip
                            title={t("usbConnections.sendingCommand")}
                            placement="top"
                          >
                            <div className="deleteBtnDisabled">
                              <DeleteIcon />
                            </div>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Delete" placement="top">
                            <div
                              className="deleteBtn"
                              onClick={() => deleteApp(obj)}
                            >
                              <DeleteIcon />
                            </div>
                          </Tooltip>
                        )}
                      </TableCell>
                    </HasPermission>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <DeleteAppDialog
        open={showDeleteDialog}
        loading={dialogLoading}
        deleteData={deleteData}
        setDeleteCommand={setDeleteCommand}
        deleteCommand={deleteCommand}
        noCommand={noCommand}
        onConfirm={() => confirmDelete()}
        os_type={device_os_type}
        onClose={() => setShowDeleteDialog(false)}
      />
    </div>
  );
};

export default SoftwareInfo;
