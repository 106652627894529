import React from 'react';
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import ConfirmationOkAndBackBtn from './CommandOkAndBackBtn';
import CircularProgress from '@material-ui/core/CircularProgress';

const CommandConfirmDialog = (props) => {
    const classes = useStyles();
    const { open, dialogTitle, dialogContent, dialogContentImportant, okBtnOnClick, backBtnOnClick, isExecuting } = props;

    return(
    <>
        <Dialog
            maxWidth="xs"
            className={isExecuting ? `${classes.dialog} ${classes.dialogTaskExecuting}` : `${classes.dialog} `}
            open={open}
            onClose={isExecuting ? null : backBtnOnClick}
            aria-labelledby="task"
            aria-describedby="task-confirmation"
        >
            { isExecuting &&
                <div className="circular-wrapper">
                    <CircularProgress size={24} className={classes.buttonProgress} />
                </div>
            }
            <DialogTitle id="task">
                {dialogTitle}
            </DialogTitle>
            <DialogContent>
                <Typography color="inherit">
                    {dialogContent}
                </Typography>
                <Typography color="error">
                    {dialogContentImportant}
                </Typography>
            </DialogContent>
            <DialogActions>
                <ConfirmationOkAndBackBtn
                    isExecuting={isExecuting}
                    okBtnOnClick={okBtnOnClick}
                    backBtnOnClick={backBtnOnClick}
                />
            </DialogActions>
        </Dialog>


    </>
    )
}

export default CommandConfirmDialog;
