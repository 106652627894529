import React, { useState, useContext, useEffect } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn } from '../commons/FormBtns';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import { SubmitBtnContext } from '../../App';
import { removeFirmwareAPI } from '../../apis/removeFirmwareAPI';
import { filterdArr } from '../../helpers/arrayHandlers';

const RemoveFWUtilityDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('adminUtility');
    const { onClose, open, fwData } = props;
    const [modelNameArr, setModelNameArr] = useState([]);
    const [companyArr, setCompanyArr] = useState([]);
    const { dispatch } = useContext(SubmitBtnContext);

    const handleRemoveUtility = async () => {
        dispatch({ type: 'showLoading' });
        const successText = `${t('dialog.successfullyRemoveUtility')}`;
        const removeResult = await removeFirmwareAPI(fwData.id, fwData.file, t, successText);
        dispatch({ type: 'stopLoading' });
        if (removeResult) onClose();
    };

    useEffect(() => {
        if (fwData && fwData.firmware_company_model_maps) {
            const dataArray = fwData.firmware_company_model_maps
            const modelNameArr = dataArray.map(o => {
                if (o.device_model) return o.device_model.name;
                return false;
            })
            const uniqueModelNamesArr = filterdArr(modelNameArr);
            setModelNameArr(uniqueModelNamesArr.join(', '))
            const companyArr = dataArray.map(o => {
                if (o.company) return o.company.name;
                return false;
            })
            const uniqueCompanyNamesArr = filterdArr(companyArr)
            setCompanyArr(uniqueCompanyNamesArr.join(', '))
        }
    }, [open, fwData]);

    return (
        <Dialog
            maxWidth='sm'
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-fw-utility"
            aria-describedby="add-edit-fw-utility-confirmation"
        >
            <DialogTitle id="add-edit-fw-utility">
                {t('dialog.removeFWUtility')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t('dialog.removeFWUtilityDescription')}</DialogContentText>
                <Divider />
                <Typography variant="caption">{t('table.peripheralType')}</Typography>
                <Typography variant="body2" color="primary">{fwData && fwData.peripheral_type ? fwData.peripheral_type.name : '-'}</Typography>
                <Typography variant="caption">{t('table.utility')}</Typography>
                <Typography variant="body2" color="primary">{fwData ? fwData.name : '-'}</Typography>
                <Typography variant="caption">{t('table.modelName')}</Typography>
                <Typography variant="body2" color="primary">{fwData ? modelNameArr : '-'}</Typography>
                <Typography variant="caption">{t('table.company')}</Typography>
                <Typography variant="body2" color="primary">{fwData ? companyArr : '-'}</Typography>
            </DialogContent>
            <DialogActions>
                <StyledSubmitBtn
                    btnColor="primary"
                    type="button"
                    onClick={handleRemoveUtility}
                    startIcon={<DeleteRounded />}
                    btnText={t('dialog.removeBtn')} />
                <Button
                    onClick={onClose}
                    color="secondary"
                    type="button">
                    {t('dialog.cancelBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveFWUtilityDialog;
