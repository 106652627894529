import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { BackBtn } from "../commons/FormBtns";
import ArrowRightIcon from "@material-ui/icons/ArrowRight";

const ShowMoreDialog = (props) => {
  const classes = useStyles();
  const { open, onClose, groupsArray } = props;
  const { t } = useTranslation("appUpdateService");
  return (
    <Dialog
      maxWidth="sm"
      className={classes.dialog}
      onClose={onClose}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <>
        <DialogTitle id="add-edit-alarm-notification">
          {t("common.publishedGroups")}
        </DialogTitle>
        <DialogContent>
          {groupsArray &&
            groupsArray.length > 0 &&
            groupsArray.map((e) => (
              <div key={e.id} className={classes.groupsInnerArray}>
                <ArrowRightIcon className="arrow" /> {e.name}
              </div>
            ))}
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default ShowMoreDialog;
