import React, { useEffect, useRef } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dashboardStyle";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { getDeviceOnlineStatusAPI } from "../../apis/getDeviceOnlineStatusAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import useStateOnMounted from "../../helpers/useStateOnMounted";

const NubisDevicesAmount = (props) => {
  const { companyId } = props;
  const mounted = useRef();
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
  const cardSubtitleTypographyVariant = props.cardSubtitleTypographyVariant;
  const cardHighlightTypographyVariant = props.cardHighlightTypographyVariant;
  const devicesOnlineTitle = `${t("dashboard.devicesOnlineTitle")}`;
  const devicesOfflineTitle = `${t("dashboard.devicesOfflineTitle")}`;
  const [freeDeviceOnline, setFreeDeviceOnline] = useStateOnMounted("-");
  const [freeDeviceOffline, setFreeDeviceOffline] = useStateOnMounted("-");
  const [proDeviceOnline, setProDeviceOnline] = useStateOnMounted("-");
  const [proDeviceOffline, setProDeviceOffline] = useStateOnMounted("-");
  const [freeReload, setFreeReload] = useStateOnMounted(true);
  const [proReload, setProReload] = useStateOnMounted(true);

  const getFreeDeviceOnlineStatus = async () => {
    setFreeReload(true);
    const data = await getDeviceOnlineStatusAPI("nubis_free");
    if (data) {
      setFreeDeviceOnline(data.online);
      setFreeDeviceOffline(data.offline);
    }
    setFreeReload(false);
  };

  const getProDeviceOnlineStatus = async () => {
    setProReload(true);
    const data = await getDeviceOnlineStatusAPI("nubis_pro");
    if (data) {
      setProDeviceOffline(data.offline);
      setProDeviceOnline(data.online);
    }
    setProReload(false);
  };

  useEffect(() => {
    if (!mounted.current) {
      getFreeDeviceOnlineStatus();
      getProDeviceOnlineStatus();
      mounted.current = true;
    }
  });

  return (
    <div className={classes.nubsiDevicesAmountWrap}>
      <Paper className={classes.marginBottom_24}>
        <Typography
          variant={cardTitleTypographyVariant}
          className={classes.cardTitleWrapWithReloadIcon}
        >
          {t("dashboard.freeDeviceAmountTitle")}
          <AutorenewRoundedIcon
            onClick={getFreeDeviceOnlineStatus}
            size="small"
            className={classes.reload_btn}
          />
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          className={classes.allHeightGrid}
        >
          <Grid item xs={6}>
            <Paper
              elevation={0}
              className={`${classes.devicesOnlineBox} ${classes.devicesAmountBox} ${classes.heightInitial}`}
            >
              <Link to={`/device-list?company=${companyId}&nubis_version=nubis_free&online_status=online`}>
                <Typography
                  variant={cardSubtitleTypographyVariant}
                  style={{ color: "#fff" }}
                >
                  {devicesOnlineTitle}
                </Typography>
                <Typography
                  variant={cardHighlightTypographyVariant}
                  style={{ color: "#fff" }}
                >
                  {!freeReload ? freeDeviceOnline : <SmallerLoadingIndicator />}
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              className={`${classes.devicesOfflineBox} ${classes.devicesAmountBox} ${classes.heightInitial}`}
            >
              <Link to={`/device-list?company=${companyId}&nubis_version=nubis_free&online_status=offline`}>
                <Typography
                  variant={cardSubtitleTypographyVariant}
                  style={{ color: "#fff" }}
                >
                  {devicesOfflineTitle}
                </Typography>
                <Typography
                  variant={cardHighlightTypographyVariant}
                  style={{ color: "#fff" }}
                >
                  {!freeReload ? (
                    freeDeviceOffline
                  ) : (
                    <SmallerLoadingIndicator />
                  )}
                </Typography>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Typography
          variant={cardTitleTypographyVariant}
          className={classes.cardTitleWrapWithReloadIcon}
        >
          {t("dashboard.proDeviceAmountTitle")}
          <AutorenewRoundedIcon
            onClick={getProDeviceOnlineStatus}
            size="small"
            className={classes.reload_btn}
          />
        </Typography>
        <Grid
          container
          spacing={2}
          alignItems="stretch"
          className={classes.allHeightGrid}
        >
          <Grid item xs={6}>
            <Paper
              elevation={0}
              className={`${classes.devicesOnlineBox} ${classes.devicesAmountBox} ${classes.heightInitial}`}
            >
              <Link to={`/device-list?company=${companyId}&nubis_version=nubis_pro&online_status=online`}>
                <Typography variant={cardSubtitleTypographyVariant} style={{color:'#fff'}}>
                  {devicesOnlineTitle}
                </Typography>
                <Typography variant={cardHighlightTypographyVariant} style={{color:'#fff'}}>
                  {!proReload ? proDeviceOnline : <SmallerLoadingIndicator />}
                </Typography>
              </Link>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              elevation={0}
              className={`${classes.devicesOfflineBox} ${classes.devicesAmountBox} ${classes.heightInitial}`}
            >
              <Link to={`/device-list?company=${companyId}&nubis_version=nubis_pro&online_status=offline`}>
                <Typography variant={cardSubtitleTypographyVariant} style={{color:'#fff'}}>
                  {devicesOfflineTitle}
                </Typography>
                <Typography variant={cardHighlightTypographyVariant} style={{color:'#fff'}}>
                  {!proReload ? proDeviceOffline : <SmallerLoadingIndicator />}
                </Typography>
              </Link>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default NubisDevicesAmount;
