import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../styles/deviceTaskStyle';
import { useTranslation } from "../langs/useTranslation";
import Form from '../components/fwUpdate/Form';
import { SubmitBtnContext } from '../App';
import * as localforage from 'localforage';
import { BreadCrumbs } from '../components/device/BreadCrumbs';

const FWUpdate = () => {
    const classes = useStyles();
    const { t } = useTranslation('task');
    const { dispatch } = useContext(SubmitBtnContext);
    const executeType = 'firmware_update';
    const successfulText = 'task.successfullyUpdateFW';

    // Manage localforage names based on the task name (no need for i18n)
    const taskName = "fwUpdate";
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;
    const storedDatePickerValueToLocalforageName = `${taskName}Date`;
    const storedFileToLocalforageName = `${taskName}File`;
    const storedDeviceIdsToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedDeviceValueToLocalforageName = `${taskName}SelectedDeviceExcelFile`;

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        await localforage.removeItem(storedDatePickerValueToLocalforageName);
        await localforage.removeItem(storedFileToLocalforageName);
        await localforage.removeItem(storedDeviceIdsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    };

    return (
        <div className={classes.root}>
            <BreadCrumbs showDeviceList={true} pageName="FWUpdateName" />
            <Typography variant="h4" className={classes.pageTitle}>{t('task.FWUpdateName')}</Typography>
            <Form
                storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                storedFileToLocalforageName={storedFileToLocalforageName}
                storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                executeType={executeType}
                dispatch={dispatch}
                successfulText={successfulText}
            />
        </div>
    )
}

export default FWUpdate;
