import React, { useEffect, useRef } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dashboardStyle";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { getDeviceOSTypeAPI } from "../../apis/getDeviceOSTypeAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import * as $ from "jquery";
import { NoDataText } from "../commons/ValidationText";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { useHistory } from "react-router-dom";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, getElementAtEvent } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

const OperationSystemVersion = (props) => {
  const { companyId } = props;
  const mounted = useRef();
  const chartRef = useRef(null);
  let history = useHistory();
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
  const cardSubtitleTypographyVariant = props.cardSubtitleTypographyVariant;
  const osTypeWindowsTitle = `${t("dashboard.osTypeWindowsTitle")}`;
  const osTypeAndroidTitle = `${t("dashboard.osTypeAndroidTitle")}`;
  const graphGreen = "#1DBBD4";
  const graphOrange = "#ff9e40";
  const [deviceOSType, setDeviceOSType] = useStateOnMounted();
  const [reload, setReload] = useStateOnMounted(true);

  const data = {
    labels: ["Windows", "Android"],
    datasets: [
      {
        data: [deviceOSType?.windows, deviceOSType?.android],
        backgroundColor: [graphGreen, graphOrange],
        hoverBackgroundColor: [graphGreen, graphOrange],
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    onHover: function(e, item) {
      $(".pieChart canvas").css("cursor", item[0] ? "pointer" : "default");
    },
  };

  const clickHandler = (event) => {
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);
    if (element) {
      const itemLabel = data?.labels[element[0]?.index];
      history.push(`/device-list?company=${companyId}&os_type=${itemLabel.toLowerCase()}`);
    }
  };

  const clickLable = (e) => {
    history.push(`/device-list?company=${companyId}&os_type=${e.toLowerCase()}`);
  };

  const getDeviceOSType = async () => {
    setReload(true);
    const data = await getDeviceOSTypeAPI();
    if (data) {
      if (data.android === 0 && data.windows === 0) {
        setDeviceOSType(false);
      } else {
        setDeviceOSType(data);
      }
    } else {
      setDeviceOSType(false);
    }
    setReload(false);
  };

  useEffect(() => {
    if (!mounted.current) {
      getDeviceOSType();
      mounted.current = true;
    }
  });

  return (
    <Paper style={{ justifyContent: "flex-start" }}>
      <Typography
        variant={cardTitleTypographyVariant}
        className={classes.cardTitleWrapWithReloadIcon}
      >
        {t("dashboard.operationSystemVersionTitle")}
        <AutorenewRoundedIcon
          onClick={getDeviceOSType}
          size="small"
          className={classes.reload_btn}
        />
      </Typography>
      {!reload ? (
        deviceOSType ? (
          <Grid
            container
            spacing={2}
            alignItems="stretch"
            style={{ margin: "auto" }}
          >
            <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
              <div className="pieChart">
                <Pie
                  ref={chartRef}
                  data={data}
                  options={options}
                  onClick={clickHandler}
                />
              </div>
            </Grid>
            <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                className={classes.hardwareModelLabels}
                style={{ marginTop: "20px" }}
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  onClick={() => clickLable("windows")}
                >
                  <Paper
                    elevation={0}
                    className={`${classes.osTypeTitleBox} ${classes.heightInitial}`}
                  >
                    <div className="flex_align_center">
                      <Typography variant={cardSubtitleTypographyVariant}>
                        <span className="graphGreen colorCube"></span>
                        {osTypeWindowsTitle}
                      </Typography>
                      <Typography variant="h6">
                        <div className="ml-1">{deviceOSType.windows}</div>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  onClick={() => clickLable("android")}
                >
                  <Paper
                    elevation={0}
                    className={`${classes.osTypeTitleBox} ${classes.heightInitial}`}
                  >
                    <div className="flex_align_center">
                      <Typography variant={cardSubtitleTypographyVariant}>
                        <span className="graphOrange colorCube"></span>
                        {osTypeAndroidTitle}
                      </Typography>
                      <Typography variant="h6">
                        <div className="ml-1">{deviceOSType.android}</div>
                      </Typography>
                    </div>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <NoDataText />
        )
      ) : (
        <SmallerLoadingIndicator />
      )}
    </Paper>
  );
};

export default OperationSystemVersion;
