import React, { useState } from 'react';
import { Typography, Dialog, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';
import AlarmPolicyDeviceTable from './AlarmPolicyDeviceTable';

const DevicesCount = props => {
    const classes = useStyles();
    const { devicesCount, id } = props;
    const [open, setOpen] = useState(false);

    const handleDialogOpen = () => setOpen(true);

    const handleDialogClose = () => setOpen(false);

    return (
        <>
            <Typography component="a" variant="body2" onClick={handleDialogOpen}>
                {devicesCount}
            </Typography>
            <Dialog
                className={`${classes.singleDeviceDialog} ${classes.singleDeviceTableWrapDialog}`}
                open={open}
                onClose={handleDialogClose}
                maxWidth="lg"
            >
                <DialogContent className="full-width-table-wrap">
                    <AlarmPolicyDeviceTable
                        id={id}
                        open={open}
                    />
                </DialogContent>
            </Dialog>
        </>
    )
}

export default DevicesCount;
