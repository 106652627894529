export const windowsDeviceSystemInfoMap = [
    'uuid',
    'location',
    'device_model_name',
    'internet_ip_address',
    'device_serial_number',
    'latest_upload_datetime',
    'operation_system_ver'
]

export const androidDeviceSystemInfoMap = [
    'build_id',
    'api_level',
    'manufacturer',
    'Kernel_version',
    'operation_system',
    'bootloader_version',
    'internet_ip_address',
    'location',
    'latest_upload_datetime',
]
