import React, { useEffect, useState } from 'react';
import useStyles from '../../styles/deviceStyle';
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from '../../hoc/FullWidthTableWrapper';
import { Typography } from '@material-ui/core';
import { updateDevicePeripheralAPI } from '../../apis/updateDevicePeripheralAPI';
import { detectDevicePeripheralAPI } from '../../apis/detectDevicePeripheralAPI';
import { table } from '../../helpers/peripheralsDatatable';
import localforage from 'localforage';
import DetectBtn from '../commons/DetectBtn';
import AutoReloadTableBtn from '../commons/AutoReloadTableBtn';

const PeripheralsTable = (props) => {

    const classes = useStyles();
    const { t } = useTranslation('device');
    const { deviceId, serialNumber } = props;
    const [ disableDetectBtn, setDisableDetectBtn ] = useState(true);

    const handleDetectBtnDisable = async() => {
        const deviceInfo = await localforage.getItem(deviceId);
        setDisableDetectBtn(deviceInfo && deviceInfo.device_model_id ? false : true);
    }

    const handleUpdatePeripherals = async(rowData, e) => {
        if(!e.detail || e.detail === 1) {
            const successfulText =  `${t('peripherals.successfullyUpdatePeripherals')}`;
            const updateResult = await updateDevicePeripheralAPI(rowData, t, successfulText);
            if(updateResult) handleReloadTable();
        }
    }
    
    const handleDetectPeripherals = async(e) => {
        if(!e.detail || e.detail === 1) {
            setDisableDetectBtn(true);
            const successfulText =  `${t('peripherals.successfullyDetectPeripherals')}`;
            const detectResult = await detectDevicePeripheralAPI(deviceId, serialNumber, t, successfulText);
            if(detectResult) handleReloadTable();
        }
    }
    
    const handleReloadTable = () => {
        table(t, deviceId, handleUpdatePeripherals);
    }

    useEffect(()=>{
        table(t, deviceId, handleUpdatePeripherals);
        handleDetectBtnDisable();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deviceId]);
    
    return(
        <>
            <Typography variant="h5">
                {t('peripherals.dialogTitle')}
            </Typography>
            <Typography variant="body1" className={classes.detectTextAndBtn}>
                {t('peripherals.dialogDetectText')}
                <DetectBtn 
                    onClick={handleDetectPeripherals} 
                    disableDetectBtn={disableDetectBtn}
                    btnText={t('peripherals.detectBtn')}
                />
                <AutoReloadTableBtn 
                    onClick={handleReloadTable}
                />
            </Typography>
            <HOCforFullWidthTable>
                <table id="peripherals-table" className="mdl-data-table dataTable">
                    <thead>
                        <tr>
                            <th>{t('peripherals.peripheralsType')}</th>
                            <th>{t('peripherals.status')}</th>
                            <th>{t('peripherals.fwVersion')}</th>
                            <th>{t('peripherals.lastDate')}</th>
                            <th>{t('peripherals.action')}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
        </>
    )
}

export default PeripheralsTable;