import React, { useState, useEffect, useCallback } from "react";
import {
  Typography,
  Dialog,
  DialogContent,
  Tabs,
  Tab,
  AppBar,
  Box,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import useStyles from "../../styles/dialogStyle";
import { useTranslation } from "../../langs/useTranslation";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import {
  getEventLogApi,
  getEventLogsettingApi,
  setEventLogStatusApi,
  getDatatableApi,
  getEventLogFileApi,
  downloadEventLogFileApi,
} from "../../apis/eventLogAPI";
import AntSwitch from "../deviceControl/AntSwitch";
import AutoReloadTableBtn from "../commons/AutoReloadTableBtn";
import textNoty from "../../helpers/textNoty";
import { table } from "../../helpers/eventLogDatatable";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import DetectBtn from "../commons/DetectBtn";
import { utcFormatToLocalDateTimeWithSecond } from "../../helpers/datetimeHandlers";
import InsertDriveFileIcon from "@material-ui/icons/InsertDriveFile";
import UpdateIcon from "@material-ui/icons/Update";
import DetailDialog from "./DetailDialog";
import GetAppIcon from "@material-ui/icons/GetApp";
import { statusSetHelper } from "../../helpers/statusSetHelper";

const EventLog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("device");
  const [disableDetectBtn, setDisableDetectBtn] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const {
    onClose,
    open,
    deviceId,
    loading,
    setLoading,
    device_os_type,
  } = props;
  const [ifEnable, setIfEnable] = useState(false);
  const [clickAlready, setClickAlready] = useState(false);
  const [clickFileAlready, setClickFileAlready] = useState(false);
  const [loadingFirst, setLoadingFirst] = useState(true);
  const [disableFileBtn, setDisableFileBtn] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [noData, setNoData] = useState(false);
  const [detailData, setDetailData] = useState({});
  const [statusSet, setStatusSet] = useState({
    status: "empty",
    updated_at: "",
  });
  const [fileStatusSet, setFileStatusSet] = useState({
    status: "empty",
    download_link: "",
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={0}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  const textColor = (status) => {
    if (status === "failed") {
      return "#f98746";
    } else if (status === "completed") {
      return "#04b7d2";
    } else {
      return "#9e9e9e";
    }
  };

  const handleSwitchOn = async (e) => {
    setLoading(true);
    let data = await setEventLogStatusApi([deviceId], "false");
    if (data && data.message === "success") {
      getSetting();
      textNoty(t("dialog.successText"), "success");
    }
  };
  const handleSwitchOff = async () => {
    setLoading(true);
    let data = await setEventLogStatusApi([deviceId], "true");
    if (data && data.message === "success") {
      getSetting();
      textNoty(t("dialog.successText"), "success");
    }
  };

  const getFile = async () => {
    let data = await getEventLogFileApi(deviceId);
    if (data) {
      setClickFileAlready(true);
      setDisableFileBtn(true);
      getFileStatus();
    }
  };

  const downloadFile = () => {
    if (fileStatusSet && fileStatusSet.download_link) {
      window.open(fileStatusSet.download_link);
    }
  };

  const getFileStatus = async () => {
    setLoadingFile(true);
    if (noData) {
      setDisableFileBtn(true);
      setLoadingFile(false);
    } else {
      let data = await downloadEventLogFileApi(deviceId);
      if (data) {
        if (
          data.status === "completed" ||
          data.status === "failed" ||
          data.status === null
        ) {
          setDisableFileBtn(false);
        } else {
          setDisableFileBtn(true);
        }
        setFileStatusSet({
          status: data.status,
          download_link: data.download_link ? data.download_link : "",
        });
        setLoadingFile(false);
      }
    }
  };

  const getSetting = useCallback(async () => {
    let data = await getEventLogsettingApi(deviceId);
    if (data) {
      if (data.event_log_setting === null) {
        setIfEnable(false);
        setLoadingFirst(false);
      } else {
        if (
          data.event_log_setting &&
          data.event_log_setting.json &&
          data.event_log_setting.json.is_disable === "true"
        ) {
          setIfEnable(false);
          setLoadingFirst(false);
        } else {
          reloadTable();
          setIfEnable(true);
        }
      }
      setLoading(false);
    }
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceId, setLoading]);

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const detect = async () => {
    let data = await getEventLogApi([deviceId]);
    if (data) {
      setClickAlready(true);
      setDisableDetectBtn(true);
      getSetting();
    }
  };

  const handleTabChange = async (event, newValue) => {
    setTabValue(newValue);
  };

  const reloadTable = async () => {
    setLoadingFirst(true);
    let res = await getDatatableApi(deviceId, "system");
    if (res?.status && res?.updated_at) {
      setStatusSet({
        status: res?.status ? res?.status : "empty",
        updated_at: res?.updated_at ? res?.updated_at : "",
      });
      if (res.status === "completed" || res.status === "failed") {
        setDisableDetectBtn(false);
        getFileStatus();
      } else {
        setDisableDetectBtn(true);
      }
      if (device_os_type === "windows") {
        table(t, deviceId, "#eventLog-system-table", "system", detailHandler);
        table(
          t,
          deviceId,
          "#eventLog-application-table",
          "application",
          detailHandler
        );
      } else {
        table(t, deviceId, "#eventLog-android-table", "", detailHandler);
      }
      setLoadingFirst(false);
    } else {
      setDisableFileBtn(true);
      setNoData(true);
      setLoadingFirst(false);
    }
  };

  const detailHandler = (data) => {
    setDetailData(data);
    setShowDetail(true);
  };

  useEffect(() => {
    if (open) {
      setLoadingFirst(true);
      setClickAlready(false);
      setClickFileAlready(false);
      setTabValue(0);
      getSetting();
    }
  }, [getSetting, open]);

  return (
    <>
      <Dialog
        onClose={() => onClose(false)}
        open={open}
        maxWidth="lg"
        className={`${classes.dialog} ${classes.eventLog}`}
      >
        {loadingFirst ? (
          <SmallerLoadingIndicator />
        ) : (
          <>
            <DialogContent>
              {!ifEnable ? (
                <div className="disabledBlock">
                  <Typography variant="h5">{t("eventLog.title")}</Typography>
                  <div className="switchWithText">
                    <Typography variant="h6">
                      {t("eventLog.turnOnThisFunction")}
                    </Typography>
                    <AntSwitch
                      checked={ifEnable}
                      disabled={loading}
                      onChange={handleSwitchOn}
                      color="primary"
                    />
                  </div>
                  <div className="sentence">
                    <div>{t("eventLog.sentence1")}</div>
                    <div>{t("eventLog.sentence2")}</div>
                  </div>
                </div>
              ) : (
                <>
                  <div className="tableHeaderDialog">
                    <Typography variant="h5">{t("eventLog.title")}</Typography>
                    <div
                      className={classes.usbDetectTextAndBtn}
                      style={{ alignItems: "flex-start" }}
                    >
                      <div className="detect-left">
                        <Tooltip
                          title={t("eventLog.reloadStatus")}
                          placement="top"
                        >
                          <div className="flex_align_center">
                            <AutoReloadTableBtn onClick={() => reloadTable()} />
                          </div>
                        </Tooltip>
                        <DetectBtn
                          onClick={() => detect()}
                          disableDetectBtn={disableDetectBtn}
                          btnText={t("eventLog.updateLogList")}
                          icon={<UpdateIcon />}
                        />
                        <div>
                          <div className="flex">
                            <div className="mr-1">
                              {t("eventLog.updateStatus")}{" "}
                            </div>
                            {(clickAlready && !loading) ||
                            (!clickAlready &&
                              statusSet.status !== "failed" &&
                              statusSet.status !== "completed") ? (
                              <div
                                style={{ color: textColor(statusSet.status) }}
                              >
                                {t(`refresh.${statusSet.status}`)}
                              </div>
                            ) : (
                              "-"
                            )}
                          </div>
                          <div className="flex">
                            {t("eventLog.updateDatetime")}{" "}
                            {statusSet.updated_at
                              ? utcFormatToLocalDateTimeWithSecond(
                                  statusSet.updated_at
                                )
                              : "-"}
                          </div>
                        </div>
                      </div>
                      <div className="detect-right">
                        {statusSetHelper(statusSet.status, "notEqualAnd") && !noData && (
                          <div className="getFileArea">
                            <>
                              <Tooltip
                                title={t("eventLog.reloadStatus")}
                                placement="top"
                              >
                                <div>
                                  <AutoReloadTableBtn
                                    onClick={() => getFileStatus()}
                                  />
                                </div>
                              </Tooltip>
                              <div className="fileBtnArea">
                                <div className="generateArea">
                                  <Tooltip
                                    title={t("eventLog.generateFile")}
                                    placement="top"
                                  >
                                    <div>
                                      <DetectBtn
                                        noIcon
                                        onClick={() => getFile()}
                                        disableDetectBtn={
                                          disableFileBtn || loadingFile
                                        }
                                        btnText={<InsertDriveFileIcon />}
                                      />
                                    </div>
                                  </Tooltip>
                                  <div className="flex">
                                    <div className="mr-1">
                                      {t("eventLog.generateStatus")}
                                    </div>
                                    {(clickFileAlready && !loadingFile) ||
                                    (!clickFileAlready &&
                                      fileStatusSet.status !== "failed" &&
                                      fileStatusSet.status !== "completed") ? (
                                      <div
                                        style={{
                                          color: textColor(
                                            fileStatusSet.status
                                          ),
                                        }}
                                      >
                                        {fileStatusSet.status
                                          ? t(`refresh.${fileStatusSet.status}`)
                                          : "-"}
                                      </div>
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                </div>
                                <div className="downloadArea">
                                  <Tooltip
                                    title={t("eventLog.downloadFile")}
                                    placement="top"
                                  >
                                    <div>
                                      <DetectBtn
                                        noIcon
                                        onClick={() => downloadFile()}
                                        disableDetectBtn={
                                          disableFileBtn || loadingFile || !fileStatusSet.status ||fileStatusSet.status === 'failed'
                                        }
                                        btnText={<GetAppIcon />}
                                      />
                                    </div>
                                  </Tooltip>
                                  <div className="flex">
                                    <div className="mr-1">
                                      {t("eventLog.generateDatetime")}
                                    </div>
                                    {fileStatusSet.download_link && !loadingFile
                                      ? utcFormatToLocalDateTimeWithSecond(
                                          fileStatusSet.updated_at
                                        )
                                      : "-"}
                                  </div>
                                </div>
                              </div>
                            </>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  {device_os_type === "windows" &&
                    statusSetHelper(statusSet.status, "notEqualAnd") &&
                    !noData && (
                      <AppBar position="static" color="default">
                        <Tabs
                          indicatorColor="primary"
                          textColor="primary"
                          variant="fullWidth"
                          value={tabValue}
                          onChange={handleTabChange}
                        >
                          <Tab
                            label={t("eventLog.tab_system")}
                            {...a11yProps(0)}
                          />
                          <Tab
                            label={t("eventLog.tab_application")}
                            {...a11yProps(1)}
                          />
                        </Tabs>
                      </AppBar>
                    )}
                  {!noData &&
                    statusSetHelper(statusSet.status, "notEqualAnd") &&
                    (device_os_type === "windows" ? (
                      <>
                        <HOCforFullWidthTable
                          loading={tabValue === 0 ? false : true}
                        >
                          <table
                            id="eventLog-system-table"
                            className="mdl-data-table "
                          >
                            <thead>
                              <tr>
                                <th>{t("eventLog.table_level")}</th>
                                <th>{t("eventLog.table_datetime")}</th>
                                <th>{t("eventLog.table_source")}</th>
                                <th>{t("eventLog.table_code")}</th>
                                <th>{t("eventLog.table_category")}</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                          </table>
                        </HOCforFullWidthTable>
                        <HOCforFullWidthTable
                          loading={tabValue === 1 ? false : true}
                        >
                          <table
                            id="eventLog-application-table"
                            className="mdl-data-table "
                          >
                            <thead>
                              <tr>
                                <th>{t("eventLog.table_level")}</th>
                                <th>{t("eventLog.table_datetime")}</th>
                                <th>{t("eventLog.table_source")}</th>
                                <th>{t("eventLog.table_code")}</th>
                                <th>{t("eventLog.table_category")}</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                          </table>
                        </HOCforFullWidthTable>
                      </>
                    ) : (
                      <HOCforFullWidthTable>
                        <table
                          id="eventLog-android-table"
                          className="mdl-data-table "
                        >
                          <thead>
                            <tr>
                              <th>{t("eventLog.table_level")}</th>
                              <th>{t("eventLog.table_datetime")}</th>
                              <th>{t("eventLog.table_source")}</th>
                              <th>{t("eventLog.table_code")}</th>
                              <th>{t("eventLog.table_category")}</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                        </table>
                      </HOCforFullWidthTable>
                    ))}
                </>
              )}
            </DialogContent>
            {ifEnable && (
              <div className="bottomSwitch">
                <div></div>
                <div className="switchWithText">
                  <div>{t("eventLog.turnOffThisFunction")}</div>
                  <AntSwitch
                    checked={ifEnable}
                    disabled={loading}
                    onChange={handleSwitchOff}
                    color="primary"
                  />
                </div>
              </div>
            )}
          </>
        )}
      </Dialog>
      <DetailDialog
        open={showDetail}
        onClose={() => setShowDetail(false)}
        detailData={detailData}
      />
    </>
  );
};

export default EventLog;
