import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Typography, Paper, Grid, FormControl } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectFile from './SelectFile';
import SelectDate from '../device/SelectDate';
import AutoLaunchAfterDeploy from './AutoLaunchAfterDeploy';
import { CommandTextFieldForDeployFile  } from '../commons/Textfields';
import FileStoredLocationTextField from './FileStoredLocationTextField';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import * as localforage from 'localforage';
import { deployFileAPI } from '../../apis/deployFileAPI';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { handleClose, handleHasResponseStatus } from '../../helpers/commandConfirmationDialogAction';
import { deployFileValuesSchema, deployFileInitialValues } from '../../config/singleDeviceTaskValuesSchema';
import { DateTimeComparisonText } from '../commons/ValidationText';

const SingleDeviceDeployFileForm = forwardRef((props, ref) => {
    const mounted = useRef()
    const classes = useStyles();
    const { executeType, devices, deviceId, storedFileValueToLocalforageName, storedDatePickerValueToLocalforageName, successfulText, dispatch, resetAllStoredValueInLocalforage } = props;
    const { t } = useTranslation('task');
    const [ open, setOpen ] = useState(false);   
    const [ values, setValues ] = useState({});
    const [ datetime, setDatetime ] = useState(true);
    const [ percentCompleted, setPercentCompleted] = useState(0);
    const [ isUploading, setIsUploading] = useState(false);
    const [ file, setFile ] = useState({});
    const [ returnValue, setReturnValue] = useState({});
    const [ disabledUpload, setDisabledUpload ] = useState(false);
    const [ driveStorageId, setDriveStorageId ] = useState(true);
    const [ isExecuting, setIsExecuting ] = useState(false);
    const [ osType, setOsType ] = useState(); 
    
    const getOsTypeFromLocalforage = async() => {
        let deviceData = await localforage.getItem(deviceId);
        if (deviceData && deviceData.os_type) {
            setOsType(deviceData.os_type);
        }
    }
    
    const handleExecuteAction = async() => {
        setIsExecuting(true);
        // Execute the task after modal opened and the confirm button clicked
        const command = values.command;
        const location = values.location;
        const autoLaunch = values.autoLaunch.length === 1 ? true : false;
        const selectedIds = [];
        const responseStatus = await deployFileAPI(deviceId, devices, selectedIds, returnValue, driveStorageId, executeType, autoLaunch, datetime, location, command, t, successfulText,values);
        if(responseStatus){
            handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        }; 
        dispatch({type: 'stopLoading'});
    };



    useEffect(()=>{
        if (!mounted.current) {
            getOsTypeFromLocalforage();
            mounted.current = true;
        }
    })
    return(
            <Formik
                initialValues={deployFileInitialValues}
                validationSchema={deployFileValuesSchema}
                onSubmit={async(values) => {
                    
                    setValues(values);
                    if(values.files === "file_from_nubis_drive" && typeof(driveStorageId) !=='string'){
                        return
                    }
                    const file = await localforage.getItem(storedFileValueToLocalforageName);
                    
                    if(values.files === "file_from_local_pc" && !file) {
                        return
                    }
                    dispatch({type: 'showLoading'});
                    const datetime = values.date === 'immediately' ? 'immediately' : await localforage.getItem(storedDatePickerValueToLocalforageName);
                       
                    if(file instanceof File){
                        setFile(file); 
                        setDriveStorageId(false);
                    } else {
                        setFile(false); 
                        setDriveStorageId(file);
                    }
                    setDatetime(datetime)
                    if(file && datetime){
                        setOpen(true);
                        dispatch({type: 'stopLoading'}); 
                    } else {
                        dispatch({type: 'stopLoading'}); 
                    }
                }}
            >
            {({
                values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true} ref={ref}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={`${classes.fullWidthPaper} overflow-visible`}>
                                    <Typography variant="h6">{t('task.step')}1. {t('task.selectAFileFromNubisDrive')}</Typography>
                                    <SelectFile 
                                        file={file}
                                        ifDeployFile={true}
                                        setFile={setFile}
                                        disabled={disabledUpload}
                                        setDisabled={setDisabledUpload}
                                        setReturnValue={setReturnValue}
                                        setPercentCompleted={setPercentCompleted}
                                        percentCompleted={percentCompleted}
                                        isUploading={isUploading}
                                        setIsUploading={setIsUploading}
                                        setDriveStorageId={setDriveStorageId}
                                        storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                                        formikName="files" 
                                        formikOnChange={handleChange}
                                        formikValue={values.files} 
                                        formikError={(errors.files && touched.files && errors.files)}
                                    />
                                        <AutoLaunchAfterDeploy 
                                            singleDevice={true}
                                            osType={osType}
                                            file={file}
                                            formikName="autoLaunch"
                                            formikChecked={values.autoLaunch.includes("autoLaunch")}
                                            formikOnChange={() => {
                                                
                                                let checkedValue = "autoLaunch";

                                                // switch the checkbox value by checking if the existing array includes checked value
                                                if (values.autoLaunch.includes(checkedValue)) {
                                                    const nextValues = [];
                                                    setFieldValue("autoLaunch", nextValues);
                                                } else {
                                                    const nextValues = [checkedValue];
                                                    setFieldValue("autoLaunch", nextValues);
                                                }

                                            }}
                                        />
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}2. {t('task.selectDate')}</Typography>
                                    <SelectDate 
                                        storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} 
                                        formikName="date" 
                                        formikOnChange={handleChange} 
                                        formikValue={values.date} 
                                        formikError={(errors.date && touched.date && errors.date)}
                                    />
                                    {!datetime ? <DateTimeComparisonText /> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <CommandTextFieldForDeployFile 
                                        CommandTextFieldForDeployFilePlaceHolder="" 
                                        formikName="command"
                                        formikOnChange={handleChange}
                                        formikOnBlur={handleBlur}
                                        formikValue={values.command}
                                    />
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <FileStoredLocationTextField 
                                        FileStoredLocationTextFieldPlaceHolder="C:/users/public/downloads" 
                                        formikName="location" 
                                        formikOnChange={handleChange} 
                                        formikOnBlur={handleBlur}
                                        formikValue={values.location} 
                                    />
                                </Paper>
                                <div className="submit-back-btn-wrap"> 
                                    <StyledSubmitBtn disabled={(values.files === 'file_from_local_pc' && isUploading && percentCompleted !== 100)} startIcon={<SendRoundedIcon />} btnText={t('btn.confirmBtn')} type="submit" />
                                    <BackBtn />
                                    <CommandConfirmDialog
                                        isExecuting={isExecuting}
                                        okBtnOnClick={handleExecuteAction}
                                        backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                        open={open}
                                        dialogTitle={t('task.fileDeploymentName')}
                                        dialogContent={t('modal.fileDeploymentDescription')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                    </FormControl>
                </Form>
            )}
        </Formik>
    )
})

export default SingleDeviceDeployFileForm;