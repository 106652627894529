import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const detectDevicePeripheralAPI = async (deviceId, serialNumber, t, successfulText) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');
    const deviceInfo = await localforage.getItem(deviceId);

    try {
        await axios({
            url: `${url}/api/v2/command/device-peripheral-detect-send`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                company_id: companyId,
                device_id: deviceId,
                device_model_id: deviceInfo.device_model_id,
                execute_type: 'device_peripheral_detect',
                serial_number: serialNumber
            }
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
