import React, { useState, useEffect,useCallback } from 'react'
import LazyLoad from 'react-lazyload';
import { FormControlLabel, Checkbox, Typography, Box } from '@material-ui/core';
import useStyles from '../../styles/multipleSelectStyle';
import { handleAlarmRuleAndEmailChange } from '../../helpers/handleAlarmRuleAndEmailChange';
import { useTranslation } from "../../langs/useTranslation";
import LoadingIndicator from '../commons/LoadingIndicator';
import { getAllPolicyRulesAndEmailsAPI } from '../../apis/getAllPolicyRulesAndEmailsAPI';
import { alarmPolicyRuleGenerator } from '../../helpers/alarmPolicyRuleGenerator';
import { RequiredText, NoItemSelectedText } from '../commons/ValidationText';
import * as localforage from 'localforage';
import { sortArrByName } from '../../helpers/arrayHandlers';
import ChipWithHoverPopover from '../commons/ChipWithHoverPopper';
import { MultipleSelectWithChipTitle } from '../commons/Titles';

const RuleAndEmailSelection = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { createOrUpdate, localforageKeyName, selectedRulesAndEmailsProvided, updatedDeviceData, storedOriginalDeployedRulesAndEmails } = props;
    const [ groups, setGroups ] = useState([]);
    const [ selectedGroups, setSelectedGroups ] = useState([]);
    const [ loading, setLoading ] = useState(true);
    const [ hasSelectedRulesAndEmails, setHasSelectedRulesAndEmails ] = useState(selectedRulesAndEmailsProvided);

    const getPolicyRulesAndEmails = useCallback (async () => {
        const allPolicyRulesAndEmails = await getAllPolicyRulesAndEmailsAPI();
        if(allPolicyRulesAndEmails){
            const ruleAndEmails = allPolicyRulesAndEmails.map( o => {
                let rule = alarmPolicyRuleGenerator(o.alarm_policy_option, o.value, o.operator);
                return {
                    id: o.id,
                    name: rule,
                    emails: o.emails,
                    checked: updatedDeviceData && updatedDeviceData.ruleIds ? updatedDeviceData.ruleIds.includes(o.id) : false
                }
            })
            setGroups(sortArrByName(ruleAndEmails));
            
            if(createOrUpdate === 'update' && updatedDeviceData){
                let selectedRules = ruleAndEmails.filter( o => o.checked);
                setSelectedGroups(selectedRules);
                setHasSelectedRulesAndEmails(!!selectedRules.length > 0);
                // copy the rule and email set into two localforage key value for comparison when submitting
                await localforage.setItem(storedOriginalDeployedRulesAndEmails, ruleAndEmails);
                await localforage.setItem(localforageKeyName, ruleAndEmails);
            }
        }
        setLoading(false);
    },[createOrUpdate, localforageKeyName, storedOriginalDeployedRulesAndEmails,  updatedDeviceData])

    const handleChange = async(event, originalItem, chipItem) => {
        handleAlarmRuleAndEmailChange(event, originalItem, chipItem, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, t, createOrUpdate);
        setHasSelectedRulesAndEmails(!!selectedGroups.length > 0);
    };


    useEffect(()=>{
        getPolicyRulesAndEmails(createOrUpdate);
    },[createOrUpdate, getPolicyRulesAndEmails]);

    useEffect(()=>{
        setHasSelectedRulesAndEmails(selectedRulesAndEmailsProvided);
    },[selectedRulesAndEmailsProvided])

    return(
        <>
            <MultipleSelectWithChipTitle 
                title={t('modal.ruleAndEmailList')}
            />
            <div className={classes.multipleSelect}>
               <div className="chipsArea">
                    {selectedGroups.length > 0 ?
                        selectedGroups.map(item => {
                            const emails = item.emails.map( o => <Box p={1} key={item.id}>{o}</Box>)
                            return (
                                <ChipWithHoverPopover
                                    item={item}
                                    key={item.id}
                                    children={emails}
                                    onClick={(e)=>handleChange(e, undefined, item)}
                                />
                            )
                        })
                    :
                    <NoItemSelectedText />
                    }
                </div>
                <div className={`${classes.lazyLoadWrap} ${classes.ruleAndEmailWrap}`}>
                {loading ? 
                    <div className={classes.loadingIndicatorWrapper}>
                        <LoadingIndicator />
                    </div>
                    :
                    (groups.length > 0 &&  
                        (groups.map((o) => {
                            return (
                                <LazyLoad key={o.id} once={o.once} height={30} overflow throttle={10} placeholder={<Typography className={classes.loading} variant="caption">loading...</Typography>}>
                                    <FormControlLabel
                                        control={
                                            <>
                                                <Checkbox 
                                                id={o.id} 
                                                checked={o.checked} 
                                                onChange={(e)=>handleChange(e, o)} 
                                                />
                                                <Box display="flex" flexDirection="column" width="100%">
                                                    <Box>
                                                        <Typography variant="body1">{o.name}</Typography>
                                                    </Box>
                                                    <Box>
                                                        {o.emails.map((o, i) => {
                                                            return (
                                                                <Typography variant="caption" className="emails" key={i}>{o}</Typography>
                                                            )
                                                        })}
                                                    </Box>
                                                </Box>
                                            </>
                                        }
                                        label=""
                                    />
                                </LazyLoad>
                            )
                        }))
                    ) 
                }
            </div>    
            </div>
            { createOrUpdate ==='create' && !hasSelectedRulesAndEmails ? <RequiredText /> : '' }
        </>
    )
}

export default RuleAndEmailSelection;

