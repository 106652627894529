import React, { useState, forwardRef } from 'react';
import { Typography, Paper, Grid, FormControl } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectDate from '../device/SelectDate';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import * as localforage from 'localforage';
import { oraUpdateAPI } from '../../apis/oraUpdateAPI';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { handleClose, handleHasResponseStatus } from '../../helpers/commandConfirmationDialogAction';
import { oraUpdatAndScreenShotInitialValues, oraUpdatAndScreenShotValuesSchema } from '../../config/singleDeviceTaskValuesSchema';
import { DateTimeComparisonText } from '../commons/ValidationText';

const OraUpdateSingleForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const { executeType, devices, deviceId, storedDatePickerValueToLocalforageName, resetAllStoredValueInLocalforage, successfulText, dispatch } = props;
    const { t } = useTranslation('task');
    const [open, setOpen] = useState(false);
    const [datetime, setDatetime] = useState(true);
    const [isExecuting, setIsExecuting ] = useState(false);
   
    const handleExecuteAction = async() => {
        setIsExecuting(true);
        // Execute the task after modal opened and the confirm button clicked
        const selectedIds = [];
        const responseStatus = await oraUpdateAPI(deviceId, devices, selectedIds, executeType, datetime, t, successfulText);
        if(responseStatus){
            handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        }
        dispatch({type: 'stopLoading'});
    };
    
    return(
            <Formik
                initialValues={oraUpdatAndScreenShotInitialValues}
                validationSchema={oraUpdatAndScreenShotValuesSchema}
                onSubmit={async(values) => {
                    dispatch({type: 'showLoading'});
                    const datetime = values.date === 'immediately' ? 'immediately' : await localforage.getItem(storedDatePickerValueToLocalforageName);
                    setDatetime(datetime)
                    if(datetime){
                        setOpen(true);
                    } else {
                        dispatch({type: 'stopLoading'}); 
                    }
                }}
            >
            {({
                values, errors, touched, handleChange, handleSubmit,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true} ref={ref}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.selectDate')}</Typography>
                                    <SelectDate 
                                        storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} 
                                        formikName="date" 
                                        formikOnChange={handleChange} 
                                        formikValue={values.date} 
                                        formikError={(errors.date && touched.date && errors.date)}
                                    />
                                    {!datetime ? <DateTimeComparisonText /> : ''}
                                </Paper>
                                <div className="submit-back-btn-wrap"> 
                                    <StyledSubmitBtn startIcon={<SendRoundedIcon />} btnText={t('btn.confirmBtn')} type="submit" />
                                    <BackBtn />
                                    <CommandConfirmDialog
                                        isExecuting={isExecuting}
                                        okBtnOnClick={handleExecuteAction}
                                        backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                        open={open}
                                        dialogTitle={t('task.oraUpdateName')}
                                        dialogContent={t('modal.oraUpdateDescription')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        </FormControl>
                </Form>
            )}
        </Formik>
    )
})

export default OraUpdateSingleForm;