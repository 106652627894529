import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiButton-root': {
            boxShadow: 'none',
            marginBottom: theme.spacing(2),
        },
    },
    deviceExcelFileUploadMainInput: {
        marginTop: theme.spacing(1),
        textAlign: 'left',
        display: 'flex',
        width: '80%',
        '& .MuiButton-label': {
            justifyContent: 'space-between',
            padding: `0 ${theme.spacing(2)}px`,
        },
    },
    hiddenInput: {
        display: 'none',
    },
    showFileUploadStatus: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiSvgIcon-root': {
            marginRight: '3px',
        },
        '&.file-name': {
            width: 'calc( 100% - 20px )',
            overflowX: 'scroll',
            lineHeight: '1.3em',
            '&::-webkit-scrollbar': {
                display: 'none !important',
            },
        }
    },
    noDataText: {
        textAlign: 'center !important',
        width: '100% !important',
        height: '100% !important',
        display: 'flex !important',
        alignItems: 'center !important',
        justifyContent: 'center !important'
    },
    noItemSelectedText: {
        color: '#ccc',
        fontStyle: 'italic'
    }
}));

export default useStyles;