import dayjs from 'dayjs';
import * as utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const currentDateInUTC = () => dayjs.utc().format('YYYY-MM-DD HH:mm');
export const dateInLocal = datetime => dayjs(datetime).local().format('YYYY-MM-DD');
export const timeInLocal = datetime => dayjs(datetime).local().format('HH:mm');
export const dateInUTC = datetime => dayjs.utc(datetime).format('YYYY-MM-DD');
export const timeInUTC = datetime => dayjs.utc(datetime).format('HH:mm');
export const dateInNormal = datetime => dayjs(datetime).format('YYYY-MM-DD');
export const formatToLocalTime = datetime => dayjs.utc(datetime).local().format('HH:mm');
export const timeInNormal = datetime => dayjs(datetime).format('HH:mm');
export const scheduledDateInUTC = datetime => dayjs.utc(datetime).format('YYYY-MM-DD HH:mm');
export const scheduledDateInLocal = datetime => dayjs(datetime).local().format('YYYY-MM-DD HH:mm');
export const utcFormatToLocalDateTimeWithSecond = datetime => dayjs(datetime).local().format('YYYY-MM-DD HH:mm:ss');
export const dateTimeUtc = datetime => dayjs.utc(datetime).format('YYYY-MM-DD HH:mm:ss');
export const utcStringToLocalDatetimeConverter = (utcDatetimeString) => dayjs.utc(utcDatetimeString).local().format('YYYY-MM-DD HH:mm');
export const utcStringToLocalDatetimeMinConverter = (utcDatetimeString) => dayjs.utc(utcDatetimeString).local().format('YYYY-MM-DD HH:mm:ss');

export const timeDiff = (target, comparator, isCompareWithCurrentDate) => {

    const vncExpiredDate = dayjs().format(target);
    const expiredDateForDiff = dayjs(vncExpiredDate);
    const comparatorDateForDiff = dayjs(isCompareWithCurrentDate ? currentDateInUTC() : comparator);

    let timeValid = expiredDateForDiff.diff(comparatorDateForDiff) > 0 ? true : false;

    return timeValid;
};

export const ifOffline = (date, heartbeat_interval) => {
    if (date === null) return true;
    const currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const currentDateForDiff = dayjs(currentDate);
    const deviceDateForDiff = dayjs(utcStringToLocalDatetimeMinConverter(date));
    const timeDiff = currentDateForDiff.diff(
        utcStringToLocalDatetimeMinConverter(deviceDateForDiff),
        "second"
    );
    return timeDiff > heartbeat_interval;
};
