import React, { useState, useEffect } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import { useParams } from "react-router-dom";
import LoadingIndicator from "../commons/LoadingIndicator";
import { getDeviceKeyName } from "./../../helpers/commonHelper";
import {
  windowsDeviceSystemInfoMap,
  androidDeviceSystemInfoMap,
} from "../../helpers/deviceSystemInfoMap";

const SystemInfo = (props) => {
  const { device_id, device_os_type } = useParams();
  const { t } = useTranslation("device");
  const { system } = props;
  const [info, setInfo] = useState("");
  const [SystemInfoMap, setSystemInfoMap] = useState([]);
  const [loading, setLoading] = useState(true);
  const deviceIdFromQueryString = device_id;

  const getStoredInfo = async () => {
    const gotInfo = await getDeviceKeyName(
      "systemInfo",
      deviceIdFromQueryString
    );
    if (gotInfo) {
      const jsonInfo = gotInfo.json;
      setInfo(jsonInfo);
      setLoading(false);
    }
  };

  const handleIfOsType = () => {
    if (device_os_type === "windows") {
      setSystemInfoMap(windowsDeviceSystemInfoMap);
    }
    if (device_os_type === "android") {
      setSystemInfoMap(androidDeviceSystemInfoMap);
    }
  };

  useEffect(() => {
    if (system && system.json) {
      setInfo(system.json);
      setLoading(false);
    } else {
      getStoredInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [system]);

  useEffect(() => {
    handleIfOsType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [device_os_type]);

  return (
    <div>
      {loading ? (
        <div className="smaller-loading-indicator">
          <LoadingIndicator />
        </div>
      ) : (
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              {SystemInfoMap.map((systemInfo, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell component="th" scope="row">
                      {t(`systemInformation.${systemInfo}`)}
                    </TableCell>
                    <TableCell align="right">
                      {info[systemInfo] || "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};


export default SystemInfo;
