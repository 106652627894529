import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateShareFileToCompaniesAPI = async (driveStorageId, companyIds, t, successfulText, onClose, reloadTable) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');

    try {
        await axios({
            url: `${url}/api/v2/drive/file/update-share-file-companies`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                company_id: companyId,
                drive_storage_id: driveStorageId,
                share_file_to_company_ids: companyIds
            },
        });
        successHandling(t, successfulText);
        onClose();
        reloadTable();
        return true;

    } catch (error) {
        errorHandling(error, false);
        return false;
    }
}
