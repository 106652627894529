import React from "react";
import Tooltip from "@material-ui/core/Tooltip";
import {
  powerArea,
  securityArea,
  rebootArea,
} from "../../helpers/taskShortcut";
import { taskShortIcons } from "../../helpers/taskShortIcons";

const TaskShortcutIcon = (props) => {
  const { block, rowData, os_type, textNoty, openFunction, t } = props;
  const {
    LockUsbPortGray,
    UnlockUsbPortGray,
    LockCombinationKeyGray,
    UnlockCombinationKeyGray,
    BlockWindowsNotifyGray,
    EnableWindowsNotifyGray,
    PowerOnGray,
    PowerOffGray,
    RebootGray,
    WakeGray,
    SleepGray,
    LockUsbPortBlue,
    UnlockUsbPortBlue,
    LockCombinationKeyBlue,
    UnlockCombinationKeyBlue,
    BlockWindowsNotifyBlue,
    EnableWindowsNotifyBlue,
    PowerOnBlue,
    PowerOffBlue,
    RebootBlue,
    WakeBlue,
    SleepBlue,
    LockUsbPortRed,
    UnlockUsbPortRed,
    LockCombinationKeyRed,
    UnlockCombinationKeyRed,
    BlockWindowsNotifyRed,
    EnableWindowsNotifyRed,
    PowerOnRed,
    PowerOffRed,
    RebootRed,
    WakeRed,
    SleepRed,
  } = taskShortIcons;

  const oraVersionNum = (versionString, osType) => {
    if (!versionString) return true;
    if (osType === "android") {
      return (
        parseInt(
          versionString
            .split("-")[0]
            .split(".")
            .join("")
        ) < 250
      );
    } else {
      return (
        parseInt(
          versionString
            .split(".")
            .join("")
            .substr(0, 3)
        ) < 212
      );
    }
  };
  //**  Power Area **//
  if (block === "power") {
    if (oraVersionNum(rowData.ora_version, rowData.os_type)) {
      return "-";
    } else {
      // Power Area "Windows"
      if (os_type === "windows") {
        // Online
        return (
          <div className="flexAlignCenter">
            {powerArea(
              rowData,
              "powerOn",
              "powerOff",
              { Gray: PowerOffGray, Blue: PowerOffBlue, Red: PowerOffRed },
              { Gray: PowerOnGray, Blue: PowerOnBlue, Red: PowerOnRed },
              openFunction,
              t,
              textNoty
            )}
            {rebootArea(
              rowData,
              { Gray: RebootGray, Blue: RebootBlue, Red: RebootRed },
              openFunction,
              t,
              textNoty
            )}
          </div>
        );
      }
      // Power Area "Android"
      else {
        return (
          <div className="flexAlignCenter">
            {powerArea(
              rowData,
              "wakeUp",
              "sleep",
              { Gray: SleepGray, Blue: SleepBlue, Red: SleepRed },
              { Gray: WakeGray, Blue: WakeBlue, Red: WakeRed },
              openFunction,
              t,
              textNoty
            )}
            {rebootArea(
              rowData,
              { Gray: RebootGray, Blue: RebootBlue, Red: RebootRed },
              openFunction,
              t,
              textNoty
            )}
          </div>
        );
      }
    }
  }
  //** Security Area **//
  else {
    let { security } = rowData;
    if (oraVersionNum(rowData.ora_version, rowData.os_type)) {
      return "-";
    } else {
      // Security Area "Windows"
      if (rowData.online_offline_status === "online") {
        if (os_type === "windows") {
          return (
            <div className="flexAlignCenter">
              {/* Disable Windows Notify */}
              {securityArea(
                security.disable_windows_notify,
                security.disable_windows_notify
                  ? JSON.parse(security.disable_windows_notify.json) &&
                      JSON.parse(security.disable_windows_notify.json).status
                  : null,
                "blockWindowsNotify",
                "openWindowsNotify",
                {
                  Gray: BlockWindowsNotifyGray,
                  Blue: BlockWindowsNotifyBlue,
                  Red: BlockWindowsNotifyRed,
                },
                {
                  Gray: EnableWindowsNotifyGray,
                  Blue: EnableWindowsNotifyBlue,
                  Red: EnableWindowsNotifyRed,
                },
                openFunction,
                t,
                textNoty
              )}
              {/* Lock USB Drives */}
              {securityArea(
                security.lock_usb,
                security.lock_usb
                  ? JSON.parse(security.lock_usb.json) &&
                      JSON.parse(security.lock_usb.json).status
                  : null,
                "lockUsbPort",
                "unlockUsbPort",
                {
                  Gray: LockUsbPortGray,
                  Blue: LockUsbPortBlue,
                  Red: LockUsbPortRed,
                },
                {
                  Gray: UnlockUsbPortGray,
                  Blue: UnlockUsbPortBlue,
                  Red: UnlockUsbPortRed,
                },
                openFunction,
                t,
                textNoty
              )}
              {/* Lock Combination Key */}

              {securityArea(
                security.disable_combination_key,
                security.disable_combination_key
                  ? JSON.parse(security.disable_combination_key.json) &&
                      JSON.parse(security.disable_combination_key.json).status
                  : null,
                "lockCombinationKey",
                "unlockCombinationKey",
                {
                  Gray: LockCombinationKeyGray,
                  Blue: LockCombinationKeyBlue,
                  Red: LockCombinationKeyRed,
                },
                {
                  Gray: UnlockCombinationKeyGray,
                  Blue: UnlockCombinationKeyBlue,
                  Red: UnlockCombinationKeyRed,
                },
                openFunction,
                t,
                textNoty
              )}
            </div>
          );
        } else {
          // Security Area "Andorid"
          return <div className="flexAlignCenter">-</div>;
        }
      } else {
        // Security offline
        if (os_type === "windows") {
          return (
            <div className="flexAlignCenter">
              {/* Disable Windows Notify */}
              <Tooltip
                title={t("iconArea.blockWindowsNotify")}
                placement="right"
              >
                <div
                  className="tableIcon disabled"
                  onClick={() => textNoty(t("error.powerOff"))}
                >
                  <BlockWindowsNotifyGray />
                </div>
              </Tooltip>
              {/* Lock USB Drives */}
              <Tooltip title={t("iconArea.lockUsbPort")} placement="right">
                <div
                  className="tableIcon disabled"
                  onClick={() => textNoty(t("error.powerOff"))}
                >
                  <LockUsbPortGray />
                </div>
              </Tooltip>
              {/* Lock Combination Key */}
              <Tooltip
                title={t("iconArea.lockCombinationKey")}
                placement="right"
              >
                <div
                  className="tableIcon disabled"
                  onClick={() => textNoty(t("error.powerOff"))}
                >
                  <LockCombinationKeyGray />
                </div>
              </Tooltip>
            </div>
          );
        } else {
          return <div className="flexAlignCenter">-</div>;
        }
      }
    }
  }
};

export default TaskShortcutIcon;
