import React from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/commonStyle";
import { Tooltip } from "@material-ui/core";
import {
  AddBoxRounded,
  AutorenewRounded,
  CreateNewFolderOutlined,
} from "@material-ui/icons";
import HasPermission from "./HasPermission";

export const TableTitleWithRenewAndAddBtn = (props) => {
  const { title, handleReloadDataTable, handleCreateDialogOpen } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Typography variant="h5" className={classes.tableTitle}>
      {t(`table.${title}`)}
      <AutorenewRounded
        onClick={handleReloadDataTable}
        size="small"
        className={classes.reload_btn}
      />
      <AddBoxRounded
        onClick={handleCreateDialogOpen}
        size="small"
        className={classes.listBtn}
      />
    </Typography>
  );
};

export const TableTitleWithFolderActions = (props) => {
  const {
    title,
    handleReloadDataTable,
    handleCreateDialogOpen,
    handleCreateFolderPopoverOpen,
    createFolderPopoverOpen,
    capacity,
    storageValue,
    tooltipText,
  } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  const ifHaveStorage = () => {
    if (storageValue === "unlimited") return true;
    return capacity?.total - capacity?.used >= 0;
  };

  return title === "uploadedFiles" ? (
    <Typography variant="h5" className={classes.tableTitle}>
      {t(`table.${title}`)}
      <AutorenewRounded
        onClick={handleReloadDataTable}
        size="small"
        className={classes.reload_btn}
      />
      <HasPermission permission="nubis_drive_file_broswer_add_file">
        {ifHaveStorage() ? (
          <AddBoxRounded
            onClick={handleCreateDialogOpen}
            size="small"
            className={classes.listBtn}
          />
        ) : (
          <Tooltip title={tooltipText} placement="top">
            <AddBoxRounded size="small" className={classes.listBtn_disabled} />
          </Tooltip>
        )}
      </HasPermission>
      <HasPermission permission="nubis_drive_file_broswer_add_folder">
        <CreateNewFolderOutlined
          onClick={handleCreateFolderPopoverOpen}
          size="small"
          color={createFolderPopoverOpen ? "primary" : "inherit"}
          className={classes.listBtn}
        />
      </HasPermission>
    </Typography>
  ) : (
    <Typography variant="h5" className={classes.tableTitle}>
      {t(`table.${title}`)}
      <AutorenewRounded
        onClick={handleReloadDataTable}
        size="small"
        className={classes.reload_btn}
      />
      <AddBoxRounded
        onClick={handleCreateDialogOpen}
        size="small"
        className={classes.listBtn}
      />
      <CreateNewFolderOutlined
        onClick={handleCreateFolderPopoverOpen}
        size="small"
        color={createFolderPopoverOpen ? "primary" : "inherit"}
        className={classes.listBtn}
      />
    </Typography>
  );
};

export const TableTitleWithRenewBtn = (props) => {
  const { title, handleReloadDataTable } = props;
  const classes = useStyles();
  const { t } = useTranslation("common");

  return (
    <Typography variant="h5" className={classes.tableTitle}>
      {t(`table.${title}`)}
      <AutorenewRounded
        onClick={handleReloadDataTable}
        size="small"
        className={classes.reload_btn}
      />
    </Typography>
  );
};

export const MultipleSelectWithChipTitle = (props) => {
  const { title } = props;
  const classes = useStyles();

  return (
    <Typography variant="body1" className={classes.multipleSelectTitle}>
      {title}
    </Typography>
  );
};
