import React, { useState, forwardRef } from 'react';
import { Typography, Paper, Grid, FormControl, Divider } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectDevices from './SelectDevicesForDeployFile';
import SelectFile from './SelectFile';
import SelectDate from '../device/SelectDate';
import AutoLaunchAfterDeploy from './AutoLaunchAfterDeploy';
import { CommandTextFieldForDeployFile  } from '../commons/Textfields';
import FileStoredLocationTextField from './FileStoredLocationTextField';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import * as localforage from 'localforage';
import { deployFileAPI } from '../../apis/deployFileAPI';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { handleClose, handleHasResponseStatus } from '../../helpers/commandConfirmationDialogAction';
import { ExcelFileRequiredMsg, DateTimeComparisonText, DevicesRequiredText } from '../commons/ValidationText';
import { deployFileValuesSchema, deployFileInitialValues } from '../../config/multiDeviceTaskValuesSchema';
import { taskExcuteDateTime } from '../../helpers/formValidationForMultiDeviceTask';
import DeviceDatatable from '../device/DeviceDatatableForCommand';

const FileDeployment = forwardRef((props, ref) => {
    const classes = useStyles();
    const { executeType, deviceId, storedDeviceValueToLocalforageName, storedFileValueToLocalforageName, storedDatePickerValueToLocalforageName, successfulText, dispatch, resetAllStoredValueInLocalforage, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName } = props;
    const { t } = useTranslation('task');
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState(true);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [ values, setValues ] = useState({});
    const [datetime, setDatetime] = useState(true);
    const [returnValue, setReturnValue] = useState({});
    const [ disabledUpload, setDisabledUpload ] = useState(false);
    const [file, setFile] = useState(true);
    const [driveStorageId, setDriveStorageId] = useState(true);
    const [isExecuting, setIsExecuting ] = useState(false);
    const [filterDevicesBy, setFilterDevicesBy] = useState();
    const [command, setCommand] = useState();
    const [location, setLocation] = useState();
    const [autoLaunch, setAutoLaunch] = useState();

    const handleExecuteAction = async() => {

        setIsExecuting(true);

        const responseStatus = await deployFileAPI(deviceId, filterDevicesBy, selectedIds, returnValue, driveStorageId, executeType, autoLaunch, datetime, location, command, t, successfulText,values);
        if(responseStatus) handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        
        handleClose(setOpen, dispatch, setIsExecuting);
    };

    return(
            <Formik
                initialValues={deployFileInitialValues}
                validationSchema={deployFileValuesSchema}
                onSubmit={ async(values) => {
                    setValues(values);
                    
                    if(values.files === "file_from_nubis_drive" && typeof(driveStorageId) !=='string'){
                        return
                    }
                    let file = await localforage.getItem(storedFileValueToLocalforageName);
                    if(values.files === "file_from_local_pc" && !file) {
                        return
                    }
                    dispatch({type: 'showLoading'});
                    let selectedIds = await localforage.getItem(storedDeviceValueToLocalforageName); 
                    let datetime = await taskExcuteDateTime(values.date, storedDatePickerValueToLocalforageName);
                    
                    if(file instanceof File){
                        setFile(file); 
                        setDriveStorageId(false);
                    } else {
                        setFile(false); 
                        setDriveStorageId(file);
                    }
                    setSelectedIds(selectedIds);
                    setDatetime(datetime);
                    setFilterDevicesBy(values.devices);
                    setLocation(values.location);
                    setAutoLaunch(values.autoLaunch.length === 1 ? true : false);
                    setCommand(values.command);
                    if(file && datetime && selectedIds){
                        //check if selectedIDs is an empty array
                        if(Array.isArray(selectedIds) && selectedIds.length < 1){
                            dispatch({type: 'stopLoading'}); 
                            return
                        } 
                        setOpen(true);
                    } else {
                        dispatch({type: 'stopLoading'}); 
                    }
                }}
            >
            {({
                values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, setFieldValue
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true} ref={ref}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={`${classes.fullWidthPaper} overflow-visible`}>
                                    <Typography variant="h6">{t('task.step')}1. {t('task.selectAFileFromNubisDrive')}</Typography>
                                    <SelectFile 
                                        file={file}
                                        ifDeployFile={true}
                                        setFile={setFile}
                                        setPercentCompleted={setPercentCompleted}
                                        percentCompleted={percentCompleted}
                                        isUploading={isUploading}
                                        setIsUploading={setIsUploading}
                                        disabled={disabledUpload}
                                        setDisabled={setDisabledUpload}
                                        setReturnValue={setReturnValue}
                                        setDriveStorageId={setDriveStorageId}
                                        storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                                        formikName="files" 
                                        formikOnChange={handleChange}
                                        formikValue={values.files} 
                                        formikError={(errors.files && touched.files && errors.files)}
                                    />
                                    <Divider />
                                    <AutoLaunchAfterDeploy 
                                        multipleDevices={true}
                                        file={file}
                                        formikName="autoLaunch"
                                        formikChecked={values.autoLaunch.includes("autoLaunch")}
                                        formikOnChange={() => {
                                            let checkedValue = "autoLaunch";
                                            // switch the checkbox value by checking if the existing array includes checked value
                                            if (values.autoLaunch.includes(checkedValue)) {
                                                const nextValues = [];
                                                setFieldValue("autoLaunch", nextValues);
                                                setAutoLaunch(false);
                                            } else {
                                                const nextValues = [checkedValue];
                                                setFieldValue("autoLaunch", nextValues);
                                                setAutoLaunch(true);
                                            }
                                        }}
                                    />
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}2. {t('task.selectDevices')}</Typography>
                                    <SelectDevices 
                                        file={file}
                                        taskType={executeType}
                                        isSubmitting={isSubmitting}
                                        setSelectedIds={setSelectedIds}
                                        isAutoLaunch={autoLaunch}
                                        formikName="devices" 
                                        formikOnChange={(e) => {
                                            setFilterDevicesBy(e.target.value);
                                            return handleChange(e)}} 
                                        formikValue={values.devices} 
                                        formikError={(errors.devices && touched.devices && errors.devices)}
                                        storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName} 
                                        storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                                        storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                                    />
                                    {filterDevicesBy !== 'excel_import' && 
                                        <DeviceDatatable
                                            taskType={executeType}
                                            filterDevicesBy={values.devices} 
                                            storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName} 
                                            storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                                        />
                                    }
                                    { filterDevicesBy !== 'excel_import' && !selectedIds ? <DevicesRequiredText />  : ''}
                                    { filterDevicesBy === 'excel_import' && !selectedIds ? <ExcelFileRequiredMsg /> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}3. {t('task.selectDate')}</Typography>
                                    <SelectDate 
                                        storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} 
                                        formikName="date" 
                                        formikOnChange={handleChange} 
                                        formikValue={values.date} 
                                        formikError={(errors.date && touched.date && errors.date)}
                                    />
                                    {!datetime ? <DateTimeComparisonText /> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <CommandTextFieldForDeployFile 
                                        CommandTextFieldForDeployFilePlaceHolder="" 
                                        formikName="command"
                                        formikOnChange={handleChange}
                                        formikOnBlur={handleBlur}
                                        formikValue={values.command}
                                    />
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <FileStoredLocationTextField 
                                        FileStoredLocationTextFieldPlaceHolder="C:/users/public/downloads" 
                                        formikName="location" 
                                        formikOnChange={handleChange} 
                                        formikOnBlur={handleBlur}
                                        formikValue={values.location}
                                    />
                                </Paper>
                                <div className="submit-back-btn-wrap"> 
                                    <StyledSubmitBtn disabled={(values.files === 'file_from_local_pc' && isUploading && percentCompleted !== 100) } 
                                    startIcon={<SendRoundedIcon />} btnText={t('btn.confirmBtn')} type="submit" />
                                    <BackBtn />
                                    <CommandConfirmDialog
                                        isExecuting={isExecuting}
                                        okBtnOnClick={handleExecuteAction}
                                        backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                        open={open}
                                        dialogTitle={t('task.fileDeploymentName')}
                                        dialogContent={t('modal.fileDeploymentDescription')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        </FormControl>
                </Form>
            )}
        </Formik>
    )
})

export default FileDeployment;