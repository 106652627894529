import React from "react";
import { Dialog, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/alarmNotificationStyle';
import AlarmTriggeredHistoryTable from "./AlarmTriggeredHistoryTable";

const AlarmTriggeredHistoryDialog = (props) => {
    const classes = useStyles();
    const { openResetDialog, handleResetAlarm, handleResetDialogClose, deviceId, shouldDisbleResetBtn } = props;

    return (
        <Dialog open={openResetDialog} onClose={handleResetDialogClose} maxWidth="lg" className={classes.singleDeviceDialog}>
            <DialogContent>
                <AlarmTriggeredHistoryTable
                    deviceId={deviceId}
                    shouldDisbleResetBtn={shouldDisbleResetBtn}
                    handleResetAlarm={handleResetAlarm} />
            </DialogContent>
        </Dialog>
    )
}

export default AlarmTriggeredHistoryDialog;
