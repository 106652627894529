import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import * as localforage from "localforage";
import "../css/dataTable.css";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-autofill";
import "datatables.net-buttons";
import "datatables.net-colreorder";
import "datatables.net-fixedcolumns";
import "datatables.net-fixedheader";
import "datatables.net-scroller";
import "datatables.net-searchpanes";
import { searchStyleRender } from "./datatableSearchStyleRender";
import StatusChip from "../components/commons/StatusChip";
import { language } from "./datatableLanguage";
import { alarmPolicyRuleGenerator } from "./alarmPolicyRuleGenerator";
import { DeleteRounded, EditRounded } from "@material-ui/icons";
import DevicesCount from "../components/alarmNotification/DevicesCount";
import HasPermission from "../components/commons/HasPermission";

export const table = async (
    t,
    handleEditDialogOpen,
    handleRemoveDialogOpen
) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";

    $("#alarm-table").DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: true,
        paging: true,
        pageLength: 25,
        ajax: {
            url: `${url}/api/v2/alarm/policy/datatable`,
            type: "GET",
            data: {
                company_id: companyId,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
        },
        columns: [
            {
                data: "alarm_policy_option",
                name: "alarmPolicyOption.name",
                title: `${t("table.ruleDescription")}`,
                searchable: true,
                render: (data, type, row) => {
                    return alarmPolicyRuleGenerator(data, row.value, row.operator);
                },
            },
            {
                data: "emails",
                title: `${t("table.email")}`,
                render: (data) => {
                    return data.join(", ");
                },
            },
            {
                data: "status",
                title: `${t("table.status")}`,
                searchable: true,
                createdCell: (td, data) => {
                    if (data) {
                        ReactDOM.render(<StatusChip statusLabel={data} />, td);
                    } else {
                        ReactDOM.render(<StatusChip statusLabel="-" />, td);
                    }
                },
            },
            {
                data: "devices_count",
                title: `${t("table.devices")}`,
                searchable: false,
                className: "td-center",
                createdCell: (td, data, rowData) => {
                    if (data) {
                        ReactDOM.render(
                            <DevicesCount devicesCount={data} id={rowData.id} />,
                            td
                        );
                    } else {
                        ReactDOM.render("-", td);
                    }
                },
            },
            { data: "note", title: `${t("table.note")}`, searchable: true },
            {
                data: "id",
                title: `${t("table.action")}`,
                searchable: false,
                orderable: false,
                className: "action",
                createdCell: (td, data, rowData) => {
                    let policyToBeUpdated = rowData;
                    ReactDOM.render(
                        <>
                            <HasPermission permission="alarm_edit_alarm_policy">
                                <EditRounded
                                    onClick={() =>
                                        handleEditDialogOpen("update", policyToBeUpdated)
                                    }
                                />
                            </HasPermission>
                            <HasPermission permission="alarm_delete_alarm_policy">
                                <DeleteRounded
                                    onClick={() =>
                                        handleRemoveDialogOpen(data, policyToBeUpdated)
                                    }
                                />
                            </HasPermission>
                        </>,
                        td
                    );
                },
            },
        ],
    });
    // render Material UI Search Input
    searchStyleRender();
};
