import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import { language } from "./datatableLanguage";
import * as localforage from "localforage";
import * as $ from "jquery";
import "../css/dataTable.css";
import "datatables.net";
import "datetime-moment";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { datatableErrorHandling } from "./datatableErrorHandling";
import { dateTimeUtc } from "./datetimeHandlers";

export const table = async (t, device_id, id, type, detailHandler) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";
    $(id).DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: false,
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        pageLength: 10,
        ordering: true,
        ajax: {
            url: `${url}/api/v2/device/event-log-datatable`,
            type: "GET",
            data: {
                company_id,
                device_id,
                event_log_type: type,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                datatableErrorHandling(
                    error.responseJSON ? error.responseJSON.message : ""
                );
            },
        },
        columns: [
            {
                data: "level",
                className: "level",
                title: `${t("eventLog.table_level")}`,
                createdCell: (td, data) => {
                    ReactDOM.render(
                        <div
                            style={{
                                fontWeight: "600",
                                color:
                                    data === "Error"
                                        ? "#ee0000"
                                        : data === "Warning"
                                            ? "#ff9e40"
                                            : "#333",
                            }}
                        >
                            {data}
                        </div>,
                        td
                    );
                },
            },
            {
                data: "datetime",
                className: "datetime",
                title: `${t("eventLog.table_datetime")}`,
                createdCell: (td, data) => {
                    ReactDOM.render(
                        <div>{data && dateTimeUtc(data)}</div>,
                        td
                    );
                },
            },
            {
                data: "source",
                className: "source",
                title: `${t("eventLog.table_source")}`,
                createdCell: (td, data) => {
                    ReactDOM.render(<div>{data}</div>, td);
                },
            },
            {
                data: "code",
                className: "code",
                title: `${t("eventLog.table_code")}`,
                createdCell: (td, data) => {
                    ReactDOM.render(<div>{data}</div>, td);
                },
            },
            {
                data: "categories",
                className: "categories",
                title: `${t("eventLog.table_category")}`,
                createdCell: (td, data) => {
                    ReactDOM.render(<div>{data}</div>, td);
                },
            },
            {
                data: "content",
                className: "Action",
                title: "Action",
                sortable: false,
                createdCell: (td, data, row) => {
                    ReactDOM.render(
                        data && (
                            <div
                                className="table_detail_btn"
                                onClick={() => detailHandler(row)}
                            >
                                Detail
                            </div>
                        ),
                        td
                    );
                },
            },
        ],
    });
    searchStyleRender();
};
