import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";

const DetailDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("device");
  const { open, onClose ,detailData} = props;

  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      onClose={onClose}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle id="add-edit-alarm-notification">
        {t('eventLog.title')} - {t('eventLog.table_detail')}
      </DialogTitle>
      <>
        <DialogContent>{detailData?.content ? detailData?.content : 'No Detail Data'}</DialogContent>
      </>
    </Dialog>
  );
};

export default DetailDialog;
