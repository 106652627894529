// email validation
// eslint-disable-next-line no-useless-escape
export const emailRegex = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
export const checkIfEmailValid = (email) =>
    emailRegex.test(String(email).toLowerCase());

// degree regex
export const dataUnit = "&#8451;";
export const unicode = dataUnit.match(/\d+/);
export const degreeRegex = String.fromCharCode(unicode);

// operator entity
export const operatorDeterminator = (operatorEntity) => {
    switch (operatorEntity) {
        case "&gt;":
        case ">":
            return ">";
        case "&lt;":
        case "<":
            return "<";
        case "&gt;=":
        case ">=":
            return ">=";
        case "&lt;=":
        case "<=":
            return "<=";
        default:
            return;
    }
};

//vnc pwd validation: only accepts 1-8 or special characters or a-z or A-Z
export const vncPwdRegex = /^([\d!@#$%^&*a-zA-Z]{0,8})$/;

export const numEnglishRegex = /^[A-Za-z0-9]+$/;

export const windowsPathRegex = /^[a-zA-Z]:\\\w+/;

export const numRegex = /^[0-9].?[0-9]*/;
