import React, { useEffect, useMemo } from "react";
import useStyles from "../styles/deviceStyle";
import DeviceListTableSection from "../components/device/DeviceListTableSection";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import { useLocation, useParams } from 'react-router-dom';

const DeviceList = () => {
    const classes = useStyles();
    let location = useLocation();

    const query = useMemo(() => new URLSearchParams(location.search), [
        location.search,
    ]);

    const type = query.keys().next().value;
    const id = query.get(type);
    console.log(type, id);

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="deviceMonitoring" />
            <DeviceListTableSection params={{type, id}}/>
        </div>
    );
};

export default DeviceList;
