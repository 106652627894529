import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Typography, Paper, Grid, FormControl } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectDevices from '../device/SelectDevices';
import SelectFileFromNubisDrive from '../device/SelectBIOSFileFromNubisDrive';
import SelectDate from '../device/SelectDate';
import CommandTextField from '../device/CommandTextField';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import 'datetime-moment';
import DeviceDatatable from '../device/DeviceDatatableForCommand';
import { table } from '../../helpers/taskDeviceTable';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { biosFwUpdateAPI } from '../../apis/biosFwUpdateAPI';
import { shutDown } from '../../helpers/commandPlaceholders';
import { handleClose, handleHasResponseStatus } from '../../helpers/commandConfirmationDialogAction';
import { GroupOrTagRequiredText, ExcelFileRequiredMsg, RequiredText, DateTimeComparisonText } from '../commons/ValidationText';
import { checkIfGroupsOrTagsSelected, getSelectedDevicesByTableOrExcel, taskExcuteDateTime } from '../../helpers/formValidationForMultiDeviceTask';
import { biosFwValuesSchema, biosFwInitialValues } from '../../config/multiDeviceTaskValuesSchema';

const FWUpdateForm = forwardRef((props, ref) => {
    const mounted = useRef()
    const classes = useStyles();
    const { executeType, deviceId, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName, storedDatePickerValueToLocalforageName, resetAllStoredValueInLocalforage, storedDeviceIdsToLocalforageName, storedDeviceValueToLocalforageName, successfulText, dispatch } = props;
    const { t } = useTranslation('task');
    const [open, setOpen] = useState(false);
    const [selectedIds, setSelectedIds] = useState(true);
    const [datetime, setDatetime] = useState(true);
    const [filterDevicesBy, setFilterDevicesBy] = useState('');
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [md5, setMd5] = useState();
    const [fileName, setFileName] = useState();
    const [filePath, setFilePath] = useState();
    const [deviceModelIds, setDeviceModelIds] = useState([]);
    const [isExecuting, setIsExecuting ] = useState(false);
    const [devices, setDevices] = useState();
    const [commandText, setCommandText] = useState('');
    const relationName= "deviceModel";

    const handleExecuteAction = async() => {
        setIsExecuting(true);
        
        const responseStatus = await biosFwUpdateAPI(deviceId, devices, selectedIds, md5, fileName, filePath, executeType, datetime, commandText, t, successfulText);
        if(responseStatus) handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        
        dispatch({type: 'stopLoading'});
    };

    const updateDeviceEligibility = async(deviceInfo) => {
        // update table when file is selected ,and device is not selected by being imported
        if(filterDevicesBy !== 'excel_import'){
            const selectedGroupIds = await localforage.getItem(storedGroupIdsToLocalforageName);
            const selectedTagIds = await localforage.getItem(storedTagIdsToLocalforageName);
            const selectedDeviceIds = await localforage.getItem(storedDeviceIdsToLocalforageName);
            let deviceModelIdArr = deviceInfo.device_model_id;
            setDeviceModelIds(deviceModelIdArr);
            setMd5(deviceInfo.md5);
            setFileName(deviceInfo.file_name);
            setFilePath(deviceInfo.file_path);
            setSelectedGroupIds(selectedGroupIds ? selectedGroupIds : []);
            setSelectedTagIds(selectedTagIds ? selectedTagIds : []);
            if(selectedDeviceIds) setSelectedIds(selectedDeviceIds);
            table(t, filterDevicesBy, selectedGroupIds, selectedTagIds, relationName, undefined, deviceModelIdArr, storedDeviceIdsToLocalforageName);
        }
    };
    

    useEffect(()=>{
        if (!mounted.current) {
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })
    return(
            <Formik
                initialValues={biosFwInitialValues}
                validationSchema={biosFwValuesSchema}
                onSubmit={ async(values) => {
                    
                    dispatch({type: 'showLoading'});
                    
                    await checkIfGroupsOrTagsSelected(setSelectedGroupIds, setSelectedTagIds, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName);
                    
                    // setup the data from values for task execution
                    let selectedDeviceIds = await getSelectedDevicesByTableOrExcel(values.devices, storedDeviceIdsToLocalforageName, storedDeviceValueToLocalforageName);
                    let datetime = await taskExcuteDateTime(values.date, storedDatePickerValueToLocalforageName);
                    setDevices(values.devices);
                    setDatetime(datetime);
                    setSelectedIds(selectedDeviceIds);
                    setFilterDevicesBy(values.devices);
                    setMd5(values.file.md5);
                    setFileName(values.file.file_name);
                    setFilePath(values.file.file_path);
                    setCommandText(values.command);
                   
                    if( selectedDeviceIds && datetime ){
                        setOpen(true);
                    } else {
                        dispatch({type: 'stopLoading'}); 
                    }
                }}
            >
            {({
                values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true} ref={ref}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}1. {t('task.selectDevices')}</Typography>
                                    <SelectDevices 
                                        storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                                        storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName} 
                                        storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                                        storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                                        storedGroupIdsAndTagIds={true}
                                        formikName="devices" 
                                        isSubmitting={isSubmitting}
                                        formikOnChange={e => {
                                            setFilterDevicesBy(e.target.value)
                                            return handleChange(e)}} 
                                        formikValue={values.devices} 
                                        formikError={(errors.devices && touched.devices && errors.devices)}
                                        relationName={relationName} 
                                        filterDevicesBy={filterDevicesBy} 
                                        selectedGroupIds={selectedGroupIds}
                                        selectedTagIds={selectedTagIds}
                                        deviceModelIds={deviceModelIds}
                                    />
                                    {(filterDevicesBy !== 'excel_import') && !selectedGroupIds && !selectedTagIds ? <GroupOrTagRequiredText /> : ''}
                                    { filterDevicesBy === 'excel_import' && !selectedIds ? <ExcelFileRequiredMsg /> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <Typography variant="h6">{t('task.step')}2. {t('task.selectAFileFromNubisDrive')}</Typography>
                                    <div className={classes.radioSelectGroup}> 
                                    <FormControl component="div" fullWidth={true} required>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.oneDropDown}>
                                                <SelectFileFromNubisDrive 
                                                    filterCondition="!="
                                                    formikName="file" 
                                                    formikOnChange={e => {
                                                        updateDeviceEligibility(e.target.value)
                                                        return handleChange(e)}
                                                    }
                                                    formikValue={values.file}
                                                    formikError={(errors.file && touched.file && errors.file)}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                                                {filterDevicesBy !== 'excel_import' &&
                                                <>
                                                    <DeviceDatatable
                                                        selectedGroupIds={selectedGroupIds}
                                                        selectedTagIds={selectedTagIds}
                                                        relationName={relationName} 
                                                        filterDevicesBy={filterDevicesBy} 
                                                        storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName} 
                                                    />
                                                    {!selectedIds ? <RequiredText /> : '' }
                                                </>
                                                }
                                            </Grid>
                                            </Grid>
                                        </FormControl>
                                    </div>
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                <Typography variant="h6">{t('task.step')}3. {t('task.selectDate')}</Typography>
                                    <SelectDate 
                                        storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} 
                                        formikName="date" 
                                        formikOnChange={handleChange} 
                                        formikValue={values.date} 
                                        formikError={(errors.date && touched.date && errors.date)}
                                    />
                                    {!datetime ? <DateTimeComparisonText /> : ''}
                                </Paper>
                                <Paper className={classes.fullWidthPaper}>
                                    <CommandTextField 
                                        CommandTextFieldPlaceHolder={shutDown}
                                        formikName="command"
                                        formikOnChange={handleChange}
                                        formikOnBlur={handleBlur}
                                        formikValue={values.command}
                                    />
                                </Paper>
                                <div className="submit-back-btn-wrap"> 
                                    <StyledSubmitBtn startIcon={<SendRoundedIcon />} btnText={t('btn.confirmBtn')} type="submit" />
                                    <BackBtn />
                                    <CommandConfirmDialog
                                        isExecuting={isExecuting}
                                        okBtnOnClick={handleExecuteAction}
                                        backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                        open={open}
                                        dialogTitle={t('task.FWUpdateName')}
                                        dialogContent={t('modal.FWUpdateDescription')}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        </FormControl>
                </Form>
            )}
        </Formik>
    )
})

export default FWUpdateForm;