import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/relax.css";

export const datatableErrorHandling = (error, shouldRedirect = true) => {
    if (error) {
        let status = error.status;
        let httpCodes = [400, 401, 403, 422];
        if (status === httpCodes[1]) {
            if (shouldRedirect === true) {
                window.location.replace('/');
            }
            if (shouldRedirect === false) {
                new Noty({
                    theme: 'relax',
                    type: 'error',
                    closeWith: ['click', 'button'],
                    layout: 'topRight',
                    text: error.message,
                    callbacks: {
                        afterClose: function () {
                            window.location.replace(process.env.REACT_APP_UNAUTH_REDIRECT_URL);
                        }
                    }
                }).show();
            }
        } else {
            return error;
        }
    }
}
