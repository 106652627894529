export const statusSetHelper = (status, type) => {
    switch (type) {
        case "notEqualAnd":
            return (
                status !== "queued" && status !== "relayed" && status !== "scheduled"
            );
        case "equalOr":
            return (
                status === "queued" || status === "relayed" || status === "scheduled"
            );
        default:
            break;
    }
};
