import React, { useContext, useEffect, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext } from '../../App';
import * as localforage from 'localforage';
import AddOrEditDeploymentForm from './AddOrEditDeploymentForm';

const AddOrEditDeploymentDialog = (props) => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { onClose, open, createOrUpdate, updatedDeviceData } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    let relationName = "alarmDeployment";

    // Manage localforage names based on the task name
    const taskName = "alarmPolicy";
    const storedRuleValueToLocalforageName = `${taskName}RuleValue`;
    const storedEmailToLocalforageName = `${taskName}Email`;
    const storedDeviceIdsToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedFileToLocalforageName = `${taskName}File`;
    const storedTriggerValueToLocalforageName = `${taskName}TriggerValue`;
    const storedRuleWithEmailsToLocalforageName = `${taskName}RuleAndEmails`;
    const storedOriginalDeployedRulesAndEmails = `${taskName}OriginalRulesAndEmailsCheckedStatus`;
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedDeviceIdsToLocalforageName);
        await localforage.removeItem(storedRuleValueToLocalforageName);
        await localforage.removeItem(storedEmailToLocalforageName);
        await localforage.removeItem(storedTriggerValueToLocalforageName);
        await localforage.removeItem(storedRuleWithEmailsToLocalforageName);
        await localforage.removeItem(storedOriginalDeployedRulesAndEmails);
        await localforage.removeItem(storedFileToLocalforageName);
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    }


    useEffect(() => {
        if (!mounted.current) {
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })

    return (
        <Dialog
            maxWidth="lg"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-alarm-notification"
            aria-describedby="add-edit-alarm-notification-confirmation"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t(`modal.${createOrUpdate}Deployment`)}
            </DialogTitle>
            <DialogContent>
                <AddOrEditDeploymentForm
                    relationName={relationName}
                    updatedDeviceData={updatedDeviceData}
                    createOrUpdate={createOrUpdate}
                    onClose={onClose}
                    storedFileToLocalforageName={storedFileToLocalforageName}
                    storedRuleValueToLocalforageName={storedRuleValueToLocalforageName}
                    storedEmailToLocalforageName={storedEmailToLocalforageName}
                    storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                    storedTriggerValueToLocalforageName={storedTriggerValueToLocalforageName}
                    storedRuleWithEmailsToLocalforageName={storedRuleWithEmailsToLocalforageName}
                    storedOriginalDeployedRulesAndEmails={storedOriginalDeployedRulesAndEmails}
                    storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                    storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                />
            </DialogContent>
        </Dialog>
    )
}

export default AddOrEditDeploymentDialog;
