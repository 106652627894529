// switch event color
export const dotColor = (title) => {
    switch (title) {
        case "Reboot":
            return "#ff9e40";
        case "Power Off":
            return "#6d7278";
        case "Power On":
            return "#1DBBD4";
        case "Wake Up":
            return "#1DBBD4";
        case "Sleep":
            return "#6d7278";
        default:
            break;
    }
};// switch event color
export const dotColorType = (title) => {
    switch (title) {
        case "reboot":
            return "#ff9e40";
        case "shutdown":
            return "#6d7278";
        case "start":
            return "#1DBBD4";
        case "wake_up":
            return "#1DBBD4";
        case "sleep":
            return "#6d7278";
        default:
            break;
    }
};
export const switchTitle = (e) => {
    switch (e) {
        case "start":
            return "Power On";
        case "shutdown":
            return "Power Off";
        case "reboot":
            return "Reboot";
        case "sleep":
            return "Sleep";
        case "wake_up":
            return "Wake Up";
        default:
            break;
    }
};
export const switchBackTitle = (e) => {
    switch (e) {
        case "Power On":
            return "start";
        case "Power Off":
            return "shutdown";
        case "Reboot":
            return "reboot";
        case "Sleep":
            return "sleep";
        case "Wake Up":
            return "wake_up";
        default:
            break;
    }
};
