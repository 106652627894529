import React from 'react';
import { TextField } from '@material-ui/core';
import useStyles from '../../styles/commonStyle';
import { useTranslation } from "../../langs/useTranslation";

const NoteTextfield = (props) => {
    const { t } = useTranslation('adminUtility')
    const { disabled, onChange, fullWidth, defaultValue } = props;
    const classes = useStyles();
    
    return (
        <TextField
            id="note"
            label={t('dialog.note')}
            multiline
            fullWidth={fullWidth}
            rows={4}
            onChange={onChange}
            variant="outlined"
            className={classes.noteTextField}
            disabled={disabled}
            defaultValue={defaultValue ? defaultValue : ''}
        />
    )
}

export default NoteTextfield;