import * as localforage from 'localforage';

export const handleAlarmRuleAndEmailChange = async (event, originalItem, chipItem, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, t, createOrUpdate) => {
    // clarify if the event target is checkbox or chip
    const checkBox = event.target;
    const itemId = chipItem ? chipItem.id : checkBox.id;
    const itemRule = chipItem ? chipItem.name : originalItem.name;

    // update the groups array checked status
    const changedGroupIndex = groups.map((o) => { return o.id }).indexOf(itemId);
    const changedGroup = chipItem ? { name: itemRule, id: itemId, emails: chipItem.emails, checked: false } : { name: itemRule, id: itemId, emails: originalItem.emails, checked: checkBox.checked };
    groups.splice(changedGroupIndex, 1, changedGroup);
    setGroups([...groups]);

    // update selected group status
    const selectedGroupIndex = selectedGroups.map((o) => { return o.id }).indexOf(itemId);
    if (selectedGroupIndex > -1) {
        selectedGroups.splice(selectedGroupIndex, 1);
        setSelectedGroups(selectedGroups);
    } else {
        let selectedGroupObj = {};
        selectedGroupObj['id'] = itemId;
        selectedGroupObj['name'] = itemRule;
        selectedGroupObj['emails'] = chipItem ? chipItem.emails : originalItem.emails;
        selectedGroups.push(selectedGroupObj);
        setSelectedGroups(selectedGroups);
    }

    // stored selected groups and devices to Localforage
    // for 'UPDATE' rules and emails, we compare the original status and final status of the entire object array,
    // so store the entire object array instead of the selected items
    await localforage.setItem(localforageKeyName, createOrUpdate === 'update' ? groups : selectedGroups);

}
