export const windowsDeviceHardwareCPUInfoMap = [
    'processor_name',
    'frequency',
    'cores',
]

export const windowsDeviceHardwareMotherboardInfoMap = [
    'bios_version',
    'bios_release_date',
    'manufacturer',
    'uuid',
    'mac_address',
]

export const windowsDeviceHardwareStorageInfoMap = [
    // 'index',
    'model_name',
    'serial_number',
    'firmware_version',
    'partitions',
    'total_capacity',
    'available_capacity',
    'type',
    'form_factor',
    'ata_version',
    'sata_version',
    'health',
]

export const windowsDeviceHardwareRAMInfoMap = [
    'model_name',
    'total_size',
    'type',
]

export const androidDeviceHardwareGeneralInfoMap = [
    'uuid',
    'total_ram',
    'available_ram',
    'screen_density',
    'internal_storage',
    'available_storage',
    'device_model_name',
    'screen_resolution',
    'main_board_serial_number',
]

export const androidDeviceHardwareSOCInfoMap = [
    'cores',
    'som_vendor',
    'clock_speed',
    'som_model_name',
]
