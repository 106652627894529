import React from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import DeviceDetailInformation from "./DeviceDetailInformation";

const DeviceBtnSets = (props) => {
  const { hardware, system, network, open, setOpen } = props;
  const classes = useStyles();
  const { t } = useTranslation("device");

  const handleClose = (value) => {
    setOpen(false);
  };

  return (
    <Dialog onClose={handleClose} open={open} className={classes.modal}>
      <DialogTitle align="center">
        {t("deviceInformation.systemTitle")}
      </DialogTitle>
      <DeviceDetailInformation
        hardware={hardware}
        system={system}
        network={network}
      />
    </Dialog>
  );
};

export default DeviceBtnSets;
