import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  TextField,
  Grid,
  Checkbox,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../../components/commons/FormBtns";
import DeleteIcon from "@material-ui/icons/Delete";
import { DatePicker, TimePicker } from "@material-ui/pickers";
import windowsIcon from "../../assets/images/windows.svg";
import androidIcon from "../../assets/images/android.svg";
import Tooltip from "@material-ui/core/Tooltip";
import HasPermission from "../commons/HasPermission";

const AddEventDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    time,
    setTime,
    onClose,
    onConfirmAdd,
    onConfirmEdit,
    task,
    setTask,
    deleteHandler,
    timeInLocal,
    dotColor,
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    loading,
    endType,
    setEndType,
    times,
    setTimes,
    loopType,
    editData,
    setLoopType,
    dailyType,
    setDailyType,
    dailyTimes,
    setDailyTimes,
    weeklyTimes,
    setWeeklyTimes,
    weeklySelect,
    setWeeklySelect,
  } = props;
  const { t } = useTranslation("powerManagement");

  const handleChange = (event) => {
    setWeeklySelect({
      ...weeklySelect,
      [event.target.name]: event.target.checked,
    });
  };
  const taskMap = [
    {
      value: "start",
      text: t("dialog.powerOn"),
      type: "windows",
    },
    {
      value: "shutdown",
      text: t("dialog.powerOff"),
      type: "windows",
    },
    {
      value: "wake_up",
      text: t("dialog.wakeUp"),
      type: "android",
    },
    {
      value: "sleep",
      text: t("dialog.sleep"),
      type: "android",
    },
    {
      value: "reboot",
      text: t("dialog.reboot"),
      type: "both",
    },
  ];
  return (
    <Dialog
      maxWidth="lg"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle id="add-edit-alarm-notification">
        {editData ? (
          <div className={classes.eventOuter}>
            <div
              className={classes.dot}
              style={{
                marginRight: "5px",
                background:
                  editData && editData.event && dotColor(editData.event.title),
              }}
            ></div>
            <div className={classes.eventTime}>
              {timeInLocal(
                editData && editData.event && editData.event.borderColor
              )}{" "}
              {editData &&
                editData.event &&
                t(`common.${editData.event.title}`)}
            </div>
          </div>
        ) : (
          t("dialog.addTitle")
        )}
      </DialogTitle>
      <>
        <DialogContent>
          <div className={classes.inputArea}>
            <Typography
              variant="h6"
              style={{ marginTop: "7px" }}
              className="fixWidth"
            >
              1. {t("dialog.selectTask")}
            </Typography>
            <div className={classes.radioWithInput}>
              <RadioGroup
                aria-label="selectTask"
                value={task}
                onChange={(event) => setTask(event.target.value)}
              >
                <div className={classes.radioArea}>
                  {taskMap.map((e) => (
                    <FormControl key={e.value} disabled={loading}>
                      <FormControlLabel
                        value={e.value}
                        control={<Radio color="primary" />}
                        label={
                          <div className={classes.radioWithOS}>
                            <div className={classes.radioWithOSText}>
                              {e.text}
                            </div>
                            {e.type === "windows" && (
                              <Tooltip title="For Windows" placement="top">
                                <img
                                  width="20"
                                  height="20"
                                  src={windowsIcon}
                                  alt="windowsIcon"
                                />
                              </Tooltip>
                            )}
                            {e.type === "android" && (
                              <Tooltip title="For Android" placement="top">
                                <img
                                  width="20"
                                  height="20"
                                  src={androidIcon}
                                  alt="androidIcon"
                                />
                              </Tooltip>
                            )}
                            {e.type === "both" && (
                              <>
                                <Tooltip title="For Windows" placement="top">
                                  <img
                                    style={{ marginRight: "10px" }}
                                    width="20"
                                    height="20"
                                    src={windowsIcon}
                                    alt="windowsIcon"
                                  />
                                </Tooltip>
                                <Tooltip title="For Android" placement="top">
                                  <img
                                    width="20"
                                    height="20"
                                    src={androidIcon}
                                    alt="androidIcon"
                                  />
                                </Tooltip>
                              </>
                            )}
                          </div>
                        }
                      />
                    </FormControl>
                  ))}
                </div>
              </RadioGroup>
            </div>
          </div>
          <Typography
            variant="body2"
            color="error"
            className={classes.warnText}
          >
            {t("dialog.osWarn")}
          </Typography>
          <div className={classes.inputArea}>
            <Typography className="fixWidth" variant="h6">
              2. {t("dialog.loopType")}
            </Typography>
            <div className={classes.datePicker} style={{ marginLeft: "0" }}>
              <RadioGroup
                className="loopTypeSelect"
                aria-label="date"
                value={loopType}
                onChange={(e) => setLoopType(e.target.value)}
              >
                <Grid container>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl>
                      <FormControlLabel
                        value="daily"
                        style={{ width: "50px" }}
                        control={<Radio color="primary" />}
                        label={<div>{t("dialog.dailyText")}</div>}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl>
                      <FormControlLabel
                        value="weekly"
                        style={{ width: "50px" }}
                        control={<Radio color="primary" />}
                        label={<div>{t("dialog.weeklyText")}</div>}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </RadioGroup>
              <div className="loopType">
                {loopType === "daily" ? (
                  <RadioGroup
                    aria-label="date"
                    value={dailyType}
                    onChange={(e) => setDailyType(e.target.value)}
                  >
                    <Grid container>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl>
                          <FormControlLabel
                            value="every"
                            control={<Radio color="primary" />}
                            label={
                              <div className="flex_center">
                                <div className="mr-2">
                                  {t("dialog.everyText")}
                                </div>
                                <div>
                                  <TextField
                                    value={dailyTimes}
                                    type="number"
                                    onChange={(e) =>
                                      setDailyTimes(e.target.value)
                                    }
                                    style={{ width: "60px" }}
                                    inputProps={{ min: 1 }}
                                  />
                                </div>
                                <div className="ml-2">
                                  {t("dialog.daysText")}
                                </div>
                              </div>
                            }
                          />
                        </FormControl>
                      </Grid>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        <FormControl>
                          <FormControlLabel
                            value="every_workdays"
                            control={<Radio color="primary" />}
                            label={<div>{t("dialog.everyWorkDays")}</div>}
                          />
                        </FormControl>
                      </Grid>
                    </Grid>
                  </RadioGroup>
                ) : (
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="weeklyEvery ml-1">
                        <div className="mr-2">{t("dialog.everyText")}</div>
                        <div>
                          <TextField
                            value={weeklyTimes}
                            type="number"
                            onChange={(e) => setWeeklyTimes(e.target.value)}
                            style={{ width: "60px" }}
                            inputProps={{ min: 1 }}
                          />
                        </div>
                        <div className="ml-2">{t("dialog.weeksText")}</div>
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <div className="ml-1">
                        {["mon", "tue", "wed", "thu", "fri", "sat", "sun"].map(
                          (e) => (
                            <FormControlLabel
                              key={e}
                              control={
                                <Checkbox
                                  onChange={handleChange}
                                  name={e}
                                  checked={weeklySelect[e] || false}
                                />
                              }
                              label={t(`dialog.${e}Text`)}
                            />
                          )
                        )}
                      </div>
                    </Grid>
                  </Grid>
                )}
              </div>
            </div>
          </div>
          <div className={classes.inputArea}>
            <Typography className="fixWidth" variant="h6">
              3. {t("dialog.selectTime")}
            </Typography>
            <div className={classes.datePicker}>
              <TimePicker
                ampm={false}
                disabled={loading}
                value={time}
                onChange={(time) => setTime(time)}
              />
            </div>
          </div>
          <div className={classes.inputArea}>
            <Typography className="fixWidth" variant="h6">
              4. {t("dialog.selectDate")}
            </Typography>
            <div className={classes.datePicker}>
              <DatePicker
                value={startDate}
                disabled={loading}
                placeholder={t("dialog.selectDate")}
                onChange={(date) => setStartDate(date)}
                format="yyyy/MM/DD"
                minDate={!editData ? new Date() : undefined}
                minDateMessage={t("dialog.minDateMessage")}
              />
              <RadioGroup
                aria-label="date"
                className="dateGroup"
                value={endType}
                onChange={(e) => setEndType(e.target.value)}
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="ml-2">
                      <FormControlLabel
                        value="datetime"
                        control={<Radio color="primary" />}
                        label={
                          <div className="flex_center">
                            <div className="mr-2">
                              {t("dialog.endDateText")}
                            </div>
                            <DatePicker
                              value={endDate}
                              disabled={loading}
                              placeholder={t("dialog.selectDate")}
                              onChange={(date) => setEndDate(date)}
                              format="yyyy/MM/DD"
                              minDate={new Date()}
                              minDateMessage={t("dialog.minDateMessage")}
                            />
                          </div>
                        }
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FormControl className="ml-2">
                      <FormControlLabel
                        value="times"
                        control={<Radio color="primary" />}
                        label={
                          <div className="flex_center">
                            <TextField
                              value={times}
                              type="number"
                              onChange={(e) => setTimes(e.target.value)}
                              style={{ width: "60px" }}
                              inputProps={{ min: 1 }}
                            />
                            <div className="ml-2">{t("dialog.timesText")}</div>
                          </div>
                        }
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </RadioGroup>
            </div>
          </div>
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          {editData ? (
            <StyledSubmitBtn
              btnColor="primary"
              type="button"
              disabled={loading}
              btnText={t("dialog.confirmBtn")}
              onClick={() => onConfirmEdit()}
            />
          ) : (
            <StyledSubmitBtn
              btnColor="primary"
              type="button"
              disabled={loading}
              btnText={t("dialog.confirmBtn")}
              onClick={() => onConfirmAdd()}
            />
          )}
          <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
          {editData && (
            <HasPermission permission="power_management_delete_task">
              <Tooltip title={t("dialog.delete")} placement="top">
                <DeleteIcon
                  className="fc-delete"
                  onClick={() => deleteHandler()}
                />
              </Tooltip>
            </HasPermission>
          )}
        </DialogActions>
      </>
    </Dialog>
  );
};

export default AddEventDialog;
