import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import { Typography, Paper, Grid } from "@material-ui/core";
import useStyles from "../../styles/deviceControlStyle";

const DeviceControlSet = (props) => {
    const classes = useStyles();
    const {
        type,
        selectDevices,
        selectDevice,
        notAllowGroup,
        imgSrc,
        notAllowAndroid,
        numType,
        clickHandler,
    } = props;
    const { t } = useTranslation("deviceControl");

    const selectValidation = () => {
        if (numType === "single") {
            let androidValid;
            if (notAllowAndroid) {
                if (selectDevice) {
                    androidValid = selectDevice.os_type === "android";
                }
            }
            return androidValid || !selectDevice;
        } else {
            let androidValid;
            if (notAllowAndroid) {
                if (selectDevices.length > 0) {
                    androidValid = !!selectDevices.find((a) => a.os_type === "android");
                }
            }
            return (
                androidValid ||
                selectDevices.length === 0 ||
                (notAllowGroup && selectDevices.length > 1)
            );
        }
    };
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            className={classes.deviceControlSet}
        >
            <Paper
                onClick={clickHandler}
                className={`${classes.controlPaper} ${selectValidation() ? classes.notAllow : ""
                    }`}
            >
                <div className={classes.controlTop}>
                    <div className={classes.controlLeft}>
                        {selectValidation() ? (
                            <imgSrc.Gray className={classes.icon} />
                        ) : (
                            <imgSrc.Black className={classes.icon} />
                        )}
                        <div className={classes.controlTextSet}>
                            <Typography variant="body1">{t(`common.${type}`)}</Typography>
                            {notAllowGroup && (
                                <Typography variant="body2" color="secondary">
                                    {t("common.onlyForSingle")}
                                </Typography>
                            )}
                            {notAllowAndroid && (
                                <Typography variant="body2" color="secondary">
                                    {t("common.onlyForWindows")}
                                </Typography>
                            )}
                        </div>
                    </div>
                </div>
            </Paper>
        </Grid>
    );
};

export default DeviceControlSet;
