import React, { useEffect, useState,useCallback } from 'react';
import localforage from 'localforage';

const HasPermission = (props) => {
    const [hasPermission, setHasPermission] = useState(false)

    const checkHasPermission = useCallback(async() => {
        // get the user role permission
        const userPermissions = await localforage.getItem('userPermissions')
        if(userPermissions){
            const isPermission = userPermissions.includes(props.permission)
            setHasPermission(!!isPermission)
        }
    },[props.permission])
    
    useEffect(()=>{
        checkHasPermission()
    }, [checkHasPermission])

    return(
        (hasPermission ? 
            <>{props.children}</>
            :
            ''
        )
    )
}

export default HasPermission;