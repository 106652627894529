import React, { useEffect, useState } from "react";
import { Typography } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import { transformedFormatBytes } from "../../helpers/formatBytesTransformer";

const AvailableStorage = (props) => {
  const { t } = useTranslation("nubisDrive");
  const { availableStorage } = props;
  const [value, setValue] = useState("-");

  useEffect(() => {
    setValue(availableStorage);
  }, [availableStorage]);
  return (
    <Typography variant="caption" color="textPrimary">
      {t("fileBrowser.availableStorage")}:{" "}
      {availableStorage === "unlimited"
        ? `${t("fileBrowser.unlimited")}`
        : value < 0
        ? 0
        : transformedFormatBytes(value)}
    </Typography>
  );
};

export default AvailableStorage;
