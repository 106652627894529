import * as Yup from 'yup';

export const deployFileInitialValues = {
    devices: '',
    files: '',
    date: '',
    autoLaunch: [],
    location: '',
    command: ''
};

export const deployFileValuesSchema = Yup.object().shape({
    devices: Yup.string(),
    files: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
    location: Yup.string(),
    command: Yup.string(),
    autoLaunch: Yup.array(),
});

export const restartSystemInitialValues = {
    devices: '',
    date: '',
    command: '',
    autoLaunch: []
}

export const kioskModeValuesSchema = Yup.object().shape({
    devices: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
    mode: Yup.string().required('This is required!'),
    command: Yup.string(),
});

export const kioskModeInitialValues = {
    devices: '',
    date: '',
    mode: '',
    command: ''
};

export const restartSystemValuesSchema = Yup.object().shape({
    devices: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
    command: Yup.string(),
});

export const biosFwInitialValues = {
    devices: '',
    file: '',
    date: '',
    command: ''
};

export const biosFwValuesSchema = Yup.object().shape({
    devices: Yup.string().required('This is required!'),
    file: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
    command: Yup.string(),
});

export const ioInitialValues = {
    devices: '',
    port: '',
    voltage: '',
    date: '',
}

export const ioValuesSchema = Yup.object().shape({
    port: Yup.string().required('This is required for loading device table.'),
    devices: Yup.string().required('This is required!'),
    voltage: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
});

export const oraUpdatAndScreenShotInitialValues = {
    devices: '',
    date: '',
};

export const oraUpdatAndScreenShotValuesSchema = Yup.object().shape({
    devices: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
});

export const rotationValuesSchema = Yup.object().shape({
    devices: Yup.string().required('This is required!'),
    orientation: Yup.string().required('This is required!'),
    date: Yup.string().required('This is required!'),
});

export const rotationInitialValues = {
    devices: '',
    orientation: '',
    date: '',
};

export const alarmDeploymentValuesSchema = Yup.object().shape({
    filter: Yup.string(),
});

export const alarmDeploymentInitialValues = {
    filter: '',
}

export const newVncPwdInitialValues = {
    devices: 'filter_by_group',
    newPassword: '',
    confirmNewPassword: ''
};

export const newVncPwdValuesSchema = Yup.object().shape({
    devices: Yup.string().required('This is required!'),
    newPassword: Yup.string().required('This is required!'),
    confirmNewPassword: Yup.string().required('This is required!'),
});
