import React from "react";
import ReactDOM from "react-dom";
import { Badge } from "@material-ui/core";
import { url } from "../apis/apiSetup";
import * as localforage from "localforage";
import * as $ from "jquery";
import "../css/dataTable.css";
import "datatables.net";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { language } from "./datatableLanguage";
import { ErrorOutline } from "@material-ui/icons";
import DeviceOwners from "../components/commons/DeviceOwners";
import HasPermission from "../components/commons/HasPermission";

export const table = async (t, handleResetDialogOpen) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";

    $("#triggered-device-table").DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: true,
        paging: true,
        ajax: {
            url: `${url}/api/v2/alarm/trigger/datatable`,
            type: "GET",
            dataSrc: "data",
            data: {
                company_id: companyId,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
        },
        columns: [
            {
                data: "device",
                name: "device.serial_number",
                title: `${t("table.serialNumber")}`,
                className: "serial_number",
                searchable: true,
                render: (data) => {
                    return data.serial_number;
                },
            },
            {
                data: "device",
                name: "device.companies.name",
                title: `${t("table.owners")}`,
                searchable: true,
                orderable: false,
                className: "owners",
                createdCell: (td, data) => {
                    let companies = data.companies.map((o) => {
                        return o.name;
                    });
                    ReactDOM.render(<DeviceOwners owners={companies} />, td);
                },
            },
            {
                data: "device",
                name: "device.companyGroups.name",
                title: `${t("table.groups")}`,
                searchable: true,
                orderable: false,
                className: "groups",
                createdCell: (td, data) => {
                    let groups = data.company_groups.map((o) => {
                        return o.name;
                    });
                    ReactDOM.render(<p>{groups.join(", ")}</p>, td);
                },
            },
            {
                data: "device",
                name: "device.tags.name",
                title: `${t("table.tag")}`,
                searchable: true,
                className: "tag",
                render: (data) => {
                    return data && data.tags.length > 0 ? data.tags[0].name : "";
                },
            },
            {
                data: "id",
                title: `${t("table.action")}`,
                searchable: false,
                orderable: false,
                createdCell: (td, data, rowData) => {
                    let shouldDisableResetBtn =
                        rowData.alarm_triggered_device_histories.length < 0;
                    let hasResetFailedStatus =
                        rowData.alarm_triggered_device_histories.filter(
                            (o) => o.status === "failed"
                        ).length > 0;
                    ReactDOM.render(
                        <HasPermission permission="alarm_action_triggered_device_list">
                            <Badge
                                color="error"
                                variant="dot"
                                invisible={!hasResetFailedStatus}
                                overlap="rectangular"
                            >
                                <ErrorOutline
                                    onClick={() =>
                                        handleResetDialogOpen(data, shouldDisableResetBtn)
                                    }
                                />
                            </Badge>
                        </HasPermission>,
                        td
                    );
                },
            },
        ],
    });
    // render Material UI Search Input
    searchStyleRender();
};
