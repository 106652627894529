import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    "& .MuiSelect-root": {
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      maxWidth: "160px",
      padding: "5px 35px 5px 20px !important",
      minWidth: "80px",
    },
    "& .MuiSelect-selectMenu": {
      border: "0px solid #b5b5b5 !important",
      borderRadius: "24px !important",
      boxShadow: "0px 0px 0px 1.5px rgba(0,0,0,0.3)",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined:focus": {
      border: "0px solid #b5b5b5 !important",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined:hover": {
      border: "0px solid #b5b5b5 !important",
    },
  },
  pageTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  reload_btn: {
    top: 10,
    cursor: "pointer",
  },
  tableMoveTopReset: {
    paddingTop: theme.spacing(2),
    marginBottom: "0 !important",
    "& table tbody td .MuiSvgIcon-root": {
      marginRight: "8px !important",
    },
  },
  tableMoveBottomReset: {
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 20px 0 !important",
    },
  },
  tableTitle: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    zIndex: "4 !important",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  singleDeviceDialog: {
    "& .MuiPaper-rounded": {
      padding: "16px 0 !important",
      borderRadius: "13px !important",
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "0 !important",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 32px !important",
    },
    "& div.dataTables_wrapper": {
      paddingBottom: "80px !important",
    },
  },
  dialogTitleWithIconBtn: {
    display: "flex !important",
    alignItems: "center !important",
    position: "relative !important",
    zIndex: "999",
    marginBottom: "16px !important",
    "& .MuiSvgIcon-root": {
      cursor: "pointer !important",
      marginLeft: "10px !important",
    },
  },
  resetTextAndBtn: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0 !important",
    fontWeight: "500",
    "& .MuiButton-contained": {
      marginBottom: "0 !important",
      marginLeft: "8px !important",
      height: "30px !important",
    },
  },
  ruleDescriptionItem: {
    "&>div": {
      display: "inline-block !important",
      margin: "3px 8px",
    },
  },
  deviceCountModal: {
    "& .MuiPaper-rounded": {
      borderRadius: "13px !important",
    },
    "& .MuiListItem-root": {
      padding: "0 !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
    "& .MuiButton-textPrimary": {
      color: "#00adc7 !important",
    },
  },
  paperNopadding: {
    position: "relatived",
    overflow: "visible",
    padding: 0,
  },
  eventOuter: {
    display: "flex",
    alignItems: "center",
  },
  dot: {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
  },
  eventTime: {
    margin: "0 5px",
  },
  disabledCalendar: {
    "& .fc-daygrid-day": {
      background: "#f7f7f7 !important",
      cursor: "not-allowed !important",
    },
    "& .Mui-disabled": {
      cursor: "not-allowed !important",
    },
    "& .fc-event-main": {
      cursor: "not-allowed !important",
    },
    "& .fc-daygrid-event": {
      backgroundColor: "#ddd !important",
    },
  },
  noGroupText:{
    margin:'10px 0'
  }
}));

export default useStyles;
