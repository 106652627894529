import axios from 'axios';
import * as localforage from 'localforage';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { apiSelectedDeviceIdsHandler } from '../helpers/apiSelectedIdsHandler';

export const createAlarmPolicyDeploymentAPI = async (filterDevicesBy, selectedDevicesIds, alarmPolicyIds, t, successfulText) => {

    let formData = new FormData();
    apiSelectedDeviceIdsHandler(formData, filterDevicesBy, selectedDevicesIds);
    alarmPolicyIds.forEach(i => {
        formData.append('alarm_policy_ids[]', i);
    })

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/alarm/deploy/assign-alarm-policies-to-devices`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
