import React, { useState, useEffect } from "react";
import {
  Dialog,
  Typography,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/deviceStyle";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";

const DeviceTags = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { tags } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const [ifHasDialog, setIfHasDialog] = useState(false);
  const open = Boolean(anchorEl);

  const handleDialogOpen = () => {
    setAnchorEl(true);
  };

  const handleDialogClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setIfHasDialog(tags.length > 3 ? true : false);
  }, [tags]);

  return (
    <div className={classes.deviceOwners}>
      {tags ? (
        <>
          {tags.map((tag, index) => {
            if (index < 3) {
              return (
                <Typography variant="body2" key={index}>
                  {tag?.name}
                </Typography>
              );
            }
            return false;
          })}
          {ifHasDialog ? (
            <span
              className={classes.deviceOwnersBtn}
              onClick={handleDialogOpen}
            >
              more
            </span>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      {ifHasDialog ? (
        <Dialog
          className={classes.deviceOwnersModal}
          open={open}
          onClose={handleDialogClose}
        >
          <DialogTitle>{t("drawer.tags")}</DialogTitle>
          <DialogContent>
            <List>
              {tags.map((tag, index) => {
                return (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <ArrowRightRoundedIcon />
                    </ListItemIcon>
                    <ListItemText>
                      <Typography variant="body2">{tag.name}</Typography>
                    </ListItemText>
                  </ListItem>
                );
              })}
            </List>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose} color="primary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      ) : (
        ""
      )}
    </div>
  );
};

export default DeviceTags;
