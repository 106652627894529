import React, { useContext, useEffect, useRef } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../styles/deviceTaskStyle';
import { useTranslation } from "../langs/useTranslation";
import SingleForm from '../components/biosUpdate/SingleForm';
import { useParams } from 'react-router-dom';
import Form from '../components/biosUpdate/Form';
import { SubmitBtnContext } from '../App';
import * as localforage from 'localforage';
import { BreadCrumbs } from '../components/device/BreadCrumbs';

const BIOSUpdate = () => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('task');
    const { device_id, device_os_type } = useParams();
    const { dispatch } = useContext(SubmitBtnContext);
    const executeType = 'bios_update';
    const successfulText = 'task.successfullyUpdateBIOS';

    // Manage localforage names based on the task name (no need for i18n)
    const taskName = "biosUpdate";
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;
    const storedDatePickerValueToLocalforageName = `${taskName}Date`;
    const storedFileToLocalforageName = `${taskName}File`;
    const storedDeviceIdsToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedDeviceValueToLocalforageName = `${taskName}SelectedDeviceExcelFile`;

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        await localforage.removeItem(storedDatePickerValueToLocalforageName);
        await localforage.removeItem(storedFileToLocalforageName);
        await localforage.removeItem(storedDeviceIdsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    };


    useEffect(() => {
        if (!mounted.current) {
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })
    return (
        <div className={classes.root}>
            <BreadCrumbs showDeviceList={true} deviceId={device_id} deviceOsType={device_os_type} pageName="biosUpdateName" />
            <Typography variant="h4" className={classes.pageTitle}>{t('task.biosUpdateName')}</Typography>
            {!!device_id ?
                <SingleForm
                    storedFileToLocalforageName={storedFileToLocalforageName}
                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                    executeType={executeType}
                    dispatch={dispatch}
                    devices=""
                    deviceId={device_id}
                    successfulText={successfulText} />
                :
                <Form
                    storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                    storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                    storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                    storedFileToLocalforageName={storedFileToLocalforageName}
                    storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                    executeType={executeType}
                    dispatch={dispatch}
                    successfulText={successfulText}
                    deviceId="" />
            }
        </div>
    )
}

export default BIOSUpdate;
