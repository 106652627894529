import { makeStyles } from "@material-ui/core/styles";

let primaryBgColor = "#1DBBD417"; // blue green
let primaryTextColor = "#1DBBD4";
let processBgColor = "#2196f312"; // blue
let processTextColor = "#2196F3";
let pauseBgColor = "#3f51b512"; // purple
let pauseTextColor = "#7786da";
let alertBgColor = "#ff9e401c"; // orange
let alertTextColor = "#ff9e40";
let grayBgColor = "#b7b6ad1c"; // gray
let grayTextColor = "#9e9e9e";

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
    width: "100%",
    "& header": {
      zIndex: "999",
    },
  },
  autoSelect:{
    '& .MuiAutocomplete-input':{
      padding:'0 !important'
    },
    '& .MuiAutocomplete-inputRoot':{
      padding:'0'
    },
    "& .tagsArea": {
      cursor: "default",
      display:'flex',
      alignItems:'center'
    },
    "& .tagInner": {
      marginRight:'3px',
      display:'inline-block',
      width: "50px",
      overflow: "hidden",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
    },
    '& .tagPlus':{
      margin:'0'
    }
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  listBtn_disabled: {
    color: "#ccc",
    cursor: "not-allowed !important",
  },
  title: {
    flexGrow: 1,
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
    "& span": {
      paddingLeft: 10,
      letterSpacing: "0.2em",
      textTransform: "uppercase",
      position: "relative",
      top: 5,
    },
  },
  logo: {
    width: 70,
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
    },
  },
  langBtn: {
    position: "absolute",
    width: 48,
    left: -2,
    "& .MuiIconButton-label": {
      marginLeft: -3,
    },
  },
  langBtnText: {
    width: 25,
    position: "relative",
    left: -4,
    "& svg": {
      position: "absolute",
      top: 2,
    },
  },
  chipWrap: {
    "& .Reset, & .Detect": {
      "& .MuiChip-label": {
        paddingRight: "12px !important",
      },
    },
    "& .MuiChip-root": {
      border: "1px solid #fff",
      backgroundColor: "#f3f3f3",
      minWidth: "70px",
    },
    "& .Offline, & .Disabled, & .Queued, & .Disconntected": {
      background: grayBgColor,
      color: grayTextColor,
    },
    "& .Online, & .Completed, & .Enabled, & .Connected": {
      background: primaryBgColor,
      color: primaryTextColor,
    },
    "& .Failed, & .Abnormal": {
      background: alertBgColor,
      color: alertTextColor,
      flexDirection: "row-reverse",
      "& svg": {
        color: alertTextColor,
        fontSize: "1em",
        marginLeft: "5px !important",
        marginRight: "12px !important",
      },
    },
    // '& .Deleting': {
    //   background: alertBgColor,
    //   color: alertTextColor,
    // },
    "& .Scheduled, & .Deploying, & .Deleting, & .Resetting": {
      background: processBgColor,
      color: processTextColor,
    },
    "& .Relayed, & .Detecting, & .Updating, & .Triggered": {
      background: pauseBgColor,
      color: pauseTextColor,
    },
  },
  popoverWrap: {
    "& .MuiPopover-paper": {
      padding: "8px !important",
      fontSize: "12px !important",
      color: "#666",
    },
  },
  noteTextField: {
    "& .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #ddd !important",
      borderRadius: "10px !important",
    },
    "& .MuiOutlinedInput-multiline": {
      height: "470px !important",
    },
    "& .MuiOutlinedInput-multiline.MuiInputBase-fullWidth": {
      height: "initial !important",
    },
  },
  chipWithHoverPopover: {
    pointerEvents: "none",
  },
  chipWithHoverPopoverPaper: {
    padding: theme.spacing(1),
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    zIndex: "9 !important",
    width: "max-content !important",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  reload_btn: {
    top: 10,
  },
}));

export default useStyles;
