import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getRealTimeDataAPI = async (device_id, type, take, min) => {
    const token = await localforage.getItem('token');
    try {
        let response = await axios({
            url: `${url}/api/v2/device/realtime-monitor/operationDetail`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
            params: {
                device_id: device_id,
                type: type,
                take: take,
                min: min
            }
        })
        if (response.data && response.data.data) {
            return response.data.data
        }
    } catch (error) {
        errorHandling(error, true);
        return
    }
}
