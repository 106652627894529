import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const tagBindDeviceAPI = async (tags, device_id) => {
    const token = await localforage.getItem("token");
    let tag_ids = tags.map((e) => e.id);
    try {
        let response = await axios({
            url: `${url}/api/v2/tag/bind-device`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                tag_ids,
                device_id,
            },
        });
        return response.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
