import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  FormControl,
  TextField,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import FileUpload from "../device/FileUpload";
import LinearWithValueLabel from "../commons/LinearProgress";
import { createAppVersionApi } from "../../apis/appListApi";
import { s3upload } from "../../helpers/s3upload";
import OSType from "../commons/OSType";

const AddNewVersion = (props) => {
  const classes = useStyles();
  const [appVersion, setAppVersion] = useState("");
  const [description, setDescription] = useState("");
  const [installScript, setInstallScript] = useState("");
  const [uninstallScript, setUninstallScript] = useState("");
  const [file, setFile] = useState({});
  const [isUploading, setIsUploading] = useState(false);
  const [disabledUpload, setDisabledUpload] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [returnValue, setReturnValue] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  const {
    open,
    setOpenAddDialog,
    loading,
    setLoading,
    reloadTable,
    managementData,
  } = props;
  const { t } = useTranslation("appUpdateService");

  const clear = () => {
    setIsUploading(false);
    setDisabledUpload(false);
    setPercentCompleted(0);
    setLoading(false);
    setFile({});
    setInstallScript("");
    setUninstallScript("");
    setAppVersion("");
    setDescription("");
    setErrorMessage();
    setReturnValue({});
  };
  const onClose = () => {
    setOpenAddDialog(false);
    clear();
  };

  const handleUploadFile = async () => {
    setIsUploading(true);
    if (file) {
      setDisabledUpload(true);
      let successfulText = `${t("noty.successfullyUploadFile")}`;
      await s3upload(
        document.getElementById("fileUpload"),
        "",
        t,
        successfulText,
        percentCompleted,
        setPercentCompleted,
        undefined,
        undefined,
        undefined,
        true,
        setReturnValue,
        "app-update-services",
        false,
        managementData?.os_type === "android" ? true : false
      );
    } else {
      setIsUploading(false);
    }
  };
  const confirmAdd = async () => {
    if (!returnValue.filePath) {
      setErrorMessage(t("error.noFile"));
      return;
    }
    if (!appVersion) {
      setErrorMessage(t("error.noAppVersion"));
      return;
    }
    if (managementData?.os_type !== "android") {
      if (!installScript) {
        setErrorMessage(t("error.noInstallScript"));
        return;
      }
      if (!uninstallScript) {
        setErrorMessage(t("error.noUnInstallScript"));
        return;
      }
    }
    setErrorMessage();
    const successText = "noty.successCreate";
    setLoading(true);
    let data = await createAppVersionApi(
      managementData.id,
      appVersion,
      returnValue.filePath,
      returnValue.md5,
      description,
      installScript,
      uninstallScript,
      t,
      successText,
      setLoading
    );
    if (data) {
      if (data.status === "success") {
        onClose();
        reloadTable();
      } else {
        setLoading(false);
      }
    }
  };
  return (
    <Dialog
      maxWidth="lg"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle>
        <div className={classes.boldHeader}>{t("dialog.addHeader")}</div>
      </DialogTitle>
      <>
        <DialogContent>
          <div className="nameArea">
            <div>{managementData && managementData.name}</div>
            <div className="ostype">
              {managementData && managementData.os_type && (
                <OSType os={managementData.os_type} />
              )}
            </div>
          </div>
          <div className={classes.uploadArea}>
            <FileUpload
              uploadFileType={"file"}
              setFile={setFile}
              ifNubisDrive={true}
              changeHanlder={handleUploadFile}
              tableDisabled={disabledUpload}
              availableStorage="unlimited"
            />
            {isUploading && (
              <LinearWithValueLabel percentCompleted={percentCompleted} />
            )}
          </div>
          <div className={classes.errorText}>{errorMessage}</div>
          <FormControl fullWidth={true}>
            <div className={classes.textfieldArea}>
              <Typography variant="body1" className="textLabel required">
                {t("common.appVersion")}
              </Typography>
              <TextField
                variant="outlined"
                placeholder={t("dialog.appVersionPlaceholder")}
                value={appVersion}
                onChange={(e) => setAppVersion(e.target.value)}
                className="lableInput"
              />
            </div>
            <div className={classes.textfieldArea}>
              <Typography variant="body1" className="textLabel">
                {t("common.description")}
              </Typography>
              <TextField
                variant="outlined"
                multiline
                rows={4}
                value={description}
                placeholder={t("dialog.descriptionPlaceholder")}
                onChange={(e) => setDescription(e.target.value)}
                className="lableInputMultiline"
              />
            </div>
            {managementData && managementData.os_type !== "android" && (
              <>
                <div className={classes.textfieldArea}>
                  <Typography variant="body1" className="textLabel required">
                    {t("common.installScript")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={4}
                    value={installScript}
                    placeholder={t("dialog.installScriptPlaceholder")}
                    onChange={(e) => setInstallScript(e.target.value)}
                    className="lableInputMultiline"
                  />
                </div>
                <div className={classes.textfieldArea}>
                  <Typography variant="body1" className="textLabel required">
                    {t("common.uninstallScript")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={4}
                    value={uninstallScript}
                    placeholder={t("dialog.uninstallScriptPlaceholder")}
                    onChange={(e) => setUninstallScript(e.target.value)}
                    className="lableInputMultiline"
                  />
                </div>
              </>
            )}
          </FormControl>
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            btnColor="primary"
            disabled={loading || (isUploading && percentCompleted !== 100)}
            type="button"
            btnText={t("dialog.confirmBtn")}
            onClick={() => confirmAdd()}
          />
          <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default AddNewVersion;
