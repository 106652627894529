import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 230;
const miniDrawerWidth = 73;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& .MuiList-root": {
      width: "100%",
      marginLeft: "0%",
      paddingTop: 0,
      paddingBottom: 0,
      "& .MuiListItem-root": {
        paddingTop: 11,
        paddingBottom: 11,
      },
      "& .MuiSvgIcon-root": {
        fill: "#000",
        fontSize: "18px",
      },
      "& .MuiTypography-displayBlock": {
        fontSize: "12px",
      },
    },
  },
  siteLogoLink: {
    height: 64,
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    textDecoration: "none",
    position: "relative",
    left: "-3px !important",
  },
  siteLogoLink_img: {
    marginRight: theme.spacing(3),
    width: 40,
    marginLeft: "-3px !important",
    transition: theme.transitions.create(["margin-left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  siteLogoLink_text: {
    position: "relative",
    textDecoration: "none",
    fontSize: "14px !important",
    color: "#999 !important",
    top: "1px !important",
  },
  image_marginRight_none: {
    marginRight: 0,
    marginLeft: -3,
  },
  appBar: {
    background: "#fff",
    paddingLeft: miniDrawerWidth,
    zIndex: theme.zIndex.drawer - 1,
    "& .MuiToolbar-root": {
      justifyContent: "flex-end !important",
    },
  },
  appBarShift: {
    paddingLeft: 0,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin", "padding"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(1),
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create(["width", "padding-left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    "& a": {
      textDecoration: "none !important",
      paddingLeft: "24px !important",
      transition: theme.transitions.create(["width", "padding-left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    "& .releaseVersionTextRwd": {
      display: "none",
    },
  },
  drawerClose: {
    transition: theme.transitions.create(["width", "padding-left"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(9) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    "& a": {
      textDecoration: "none !important",
      paddingLeft: 22,
      transition: theme.transitions.create(["width", "padding-left"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  nestedListWrapper: {
    marginBottom: `${theme.spacing(2)}px !important`,
    "& .MuiListItem-root": {
      paddingLeft: "80px !important",
      paddingTop: "3px !important",
      paddingBottom: "3px !important",
      color: theme.palette.text.secondary,
      "& .MuiTypography-body1": {
        fontSize: 12,
      },
    },
  },
  goToFecLogoLink: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textDecoration: "none",
    "& .MuiSvgIcon-root": {
      fill: "#888",
      marginRight: theme.spacing(1),
    },
    "& img": {
      width: 40,
      marginRight: 10,
    },
    "& p": {
      color: "#888",
      letterSpacing: "0.2em",
      textTransform: "uppercase",
      fontSize: 12,
    },
  },
  drawerListCategoryTitle: {
    "&.MuiListItem-root.Mui-disabled": {
      opacity: "1 !important",
    },
  },
  releaseVersionText: {
    textAlign: "center !important",
    color: "#ccc",
    width: "100px !important",
    fontSize: "8.5px !important",
  },
  releaseVersionTextV: {
    textAlign: "center !important",
    color: "#ccc",
    fontSize: "6.5px",
    margin: "0 1px 0 6px",
  },
  releaseVersionTextRwd: {
    textAlign: "center !important",
    marginTop: "16px !important",
    color: "#ccc",
    position: "absolute !important",
    bottom: "15px !important",
    right: "50% !important",
    width: "100px !important",
    marginRight: "-50px !important",
    fontSize: "11px",
  },
}));

export default useStyles;
