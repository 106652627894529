import React, { useState, useEffect, useCallback } from "react";
import { Typography, Dialog, DialogContent } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getMonitorListApi, detectMonitorApi } from "../../apis/twoMonitorAPI";
import RefreshBtn from "../commons/RefreshBtn";
import { statusSetHelper } from "../../helpers/statusSetHelper";

const TwoMonitor = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("device");
    const { onClose, open, deviceId } = props;
    const [disableDetectBtn, setDisableDetectBtn] = useState(true);
    const [clickAlready, setClickAlready] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [statusSet, setStatusSet] = useState({
        status: "empty",
        updated_at: "",
    });
    const [list, setList] = useState([]);

    const getList = useCallback(async () => {
        setList([]);
        let data = await getMonitorListApi(deviceId);
        if (data && data.status) {
            if (data.status === "completed" || data.status === "failed") {
                setDisableDetectBtn(false);
                setRefresh(false);
            } else {
                setDisableDetectBtn(true);
            }
            setStatusSet({
                status: data.status ? data.status : "empty",
                updated_at: data.updated_at ? data.updated_at : "",
            });
        } else {
            setDisableDetectBtn(false);
        }
        if (data && data.monitors && data.monitors.length > 0) {
            setList(data.monitors);
        }
    }, [deviceId]);

    useEffect(() => {
        if (open) {
            setClickAlready(false);
            getList(deviceId);
        }
    }, [deviceId, getList, open]);

    return (
        <Dialog
            onClose={onClose}
            open={open}
            maxWidth="lg"
            className={`${classes.usbConnectionsDialog}`}
        >
            <DialogContent>
                <div className={classes.tableHeaderDialog}>
                    <Typography variant="h5">{t("twoMonitor.title")}</Typography>
                    <div className={classes.usbDetectTextAndBtn}>
                        <RefreshBtn
                            detectApi={detectMonitorApi}
                            getList={getList}
                            statusSet={statusSet}
                            open={open}
                            refresh={refresh}
                            setRefresh={setRefresh}
                            disableDetectBtn={disableDetectBtn}
                            setDisableDetectBtn={setDisableDetectBtn}
                            deviceId={deviceId}
                            setClickAlready={setClickAlready}
                            clickAlready={clickAlready}
                        />
                    </div>
                </div>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">{t("twoMonitor.display")}</TableCell>
                            <TableCell align="left">{t("twoMonitor.resolution")}</TableCell>
                        </TableRow>
                    </TableHead>
                    {statusSetHelper(statusSet.status, "notEqualAnd") &&
                        list &&
                        list.length > 0 && (
                            <TableBody>
                                {list.map((row) => (
                                    <TableRow key={row.display}>
                                        <TableCell align="left">
                                            {row.display ? row.display : "-"}
                                        </TableCell>
                                        <TableCell align="left">
                                            {row.resolution ? row.resolution : "-"}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        )}
                </Table>
                {statusSetHelper(statusSet.status, "equalOr") ||
                    (list && !list.length > 0) ? (
                    <div className={classes.nodata}>{t("twoMonitor.noData")}</div>
                ) : (
                    ""
                )}
            </DialogContent>
        </Dialog>
    );
};

export default TwoMonitor;
