import React from 'react';
import { Typography, Grid, Paper } from '@material-ui/core';
import useStyles from '../styles/adminUtilityStyle';
import { useTranslation } from "../langs/useTranslation";
import FWUtilityList from '../components/adminUtility/FWUtilityList';
import UtilityManagementList from '../components/adminUtility/UtilityManagementList';
import { BreadCrumbs } from '../components/device/BreadCrumbs';

const AdminUtility = () => {
    const classes = useStyles();
    const { t } = useTranslation('adminUtility');

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="adminUtility" />
            <Typography variant="h4" className={classes.pageTitle}>{t('adminUtilty.pageTitle')}</Typography>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <Paper className={`${classes.tableMoveTopReset} ${classes.tableMoveBottomReset}`}>
                        <FWUtilityList />
                    </Paper>
                </Grid>
                <Grid item xs={12}>
                    <Paper className={`${classes.tableMoveTopReset} ${classes.tableMoveBottomReset}`}>
                        <UtilityManagementList />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    )
}

export default AdminUtility;
