import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Grid,
} from "@material-ui/core";
import {
    oraUpdateAPI
} from "../../apis/deviceControlApi";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import SelectDate from "./SelectDatePicker";
import moment from "moment";

const OraUpdate = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const [selectDateType, setSelectDateType] = useState("immediately");
    const [selectDate, setSelectDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const {
        open,
        setOpen,
        selectType,
        selectDevices,
        selectDevice,
        numType,
        groupId,
        tagId,
        selectCompany,
        checkAll
    } = props;

    const onClose = () => {
        setOpen(false);
    };

    const onConfirm = async () => {
        setLoading(true);
        const successText = "dialog.successText";
        await oraUpdateAPI(
            selectType,
            selectCompany,
            checkAll
                ? undefined
                : numType === "single"
                    ? [selectDevice]
                    : selectDevices,
            groupId,
            tagId,
            "ora_update",
            selectDate,
            t,
            successText
        ).then((res) => {
            if (res) {
                setLoading(false);
                setOpen(false);
            }
        });

    };

    const selectDateHandler = async (date) => {
        const dateValue = date._d;
        const dateMomentInUTC = moment(dateValue);
        setSelectDate(dateMomentInUTC);
    };

    const selectDateTypeHandler = (e) => {
        setSelectDateType(e.target.value);
    };

    useEffect(() => {
        setSelectDateType("immediately");
        setSelectDate(new Date());
    }, [open]);

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            onClose={onClose}
            open={open}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t("common.oraUpdate")}
            </DialogTitle>
            <>
                <DialogContent>
                    <div className={classes.deviceControlDialogOuter}>
                        <div className="deviceControlDialogBlock">
                            <div className="title">1. {t("common.selectDate")}</div>
                            <div className="selectdateArea">
                                <FormControl component="div" fullWidth={true}>
                                    <RadioGroup
                                        aria-label="date"
                                        value={selectDateType}
                                        onChange={selectDateTypeHandler}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="immediately"
                                                        label={`${t("common.immediateText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="scheduling"
                                                        label={`${t("common.schedulingText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                    <SelectDate
                                                        selectDateHandler={selectDateHandler}
                                                        selectDate={selectDate}
                                                        disabled={
                                                            selectDateType !== "scheduling" ? true : false
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading}
                        btnText={t("dialog.confirmBtn")}
                        onClick={onConfirm}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default OraUpdate;
