import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { utcStringToLocalDatetimeConverter } from "../../helpers/datetimeHandlers";
import {
    dotColorType, switchTitle
} from "../../helpers/powerManagerHelper";

const TaskSchdule = (props) => {
    const classes = useStyles();
    const { taskSchedule, type, defaultTab } = props;
    const [selectType, setSelectType] = useState(type !== 'both' ? type : defaultTab ? defaultTab : 'add')
    const { t } = useTranslation("powerManagement");

    const inner = (e) => {
        return <div key={e.id} className="taskOuter">
            <div className="taskMain">
                <div className="taskTypeOuter">
                    <div
                        className={classes.dot}
                        style={{
                            marginRight: "5px",
                            background: dotColorType(e?.task_type),
                        }}
                    ></div>
                    <div className="taskType">{t(`common.${switchTitle(e?.task_type)}`)}</div>
                </div>
                <div className="taskContent">
                    {
                        e?.schedule_type === 'daily' ?
                            <>
                                {e?.json?.cycle_type === 'every' ?
                                    <div>{t('dialog.everyText')} {e?.json?.cycle_value} {t('dialog.daysText')}</div>
                                    :
                                    <div>{t('dialog.everyWorkDays')}</div>
                                }
                            </>
                            :
                            <div className="weekOuter">
                                <div className="weeks">
                                    {e?.json?.cycle_days?.map(c => (
                                        <div key={c} className="week">{t(`dialog.${c}Text`)}</div>
                                    ))}
                                </div>
                                <div>{t('dialog.everyText')} {e?.json?.cycle_value} {t('dialog.weeksText')}</div>
                            </div>
                    }
                </div>
            </div>
            <>
                {e?.json?.end_type === 'datetime' ?
                    <div className="datetime">
                        <div>{e?.json?.start_date && utcStringToLocalDatetimeConverter(e.json.start_date)}</div>
                        <div className="mr-1 ml-1">-</div>
                        <div>{e?.json?.end_date && utcStringToLocalDatetimeConverter(e.json.end_date)}</div>
                    </div>
                    :
                    <div className="datetime">
                        <div>{e?.json?.start_date && utcStringToLocalDatetimeConverter(e.json.start_date)}</div>
                        <div className="ml-3 mr-1">{e?.json?.run_times}</div>
                        <div>{t('dialog.timesText')}</div>
                    </div>
                }
            </>
        </div>
    }

    return (
        <>
            {
                (taskSchedule?.remove?.length || taskSchedule?.add?.length) &&
                <>
                    <div className={classes.taskWarning}>
                        {t('dialog.taskWaringText')}
                    </div>
                    <div className={classes.taskBtns}>
                        {type === 'add' && <div onClick={() => setSelectType('add')} className={`taskBtnAdd alone ${selectType === 'add' ? 'active' : ''}`}>
                            {t('dialog.add')}
                        </div>}
                        {type === 'remove' && <div onClick={() => setSelectType('remove')} className={`taskBtnRemove alone ${selectType === 'remove' ? 'active' : ''}`}>
                            {t('dialog.remove')}
                        </div>}
                        {type === 'both' &&
                            <>
                                <div onClick={() => setSelectType('add')} className={`taskBtnAdd ${selectType === 'add' ? 'active' : ''}`}>
                                    {t('dialog.add')}
                                </div>
                                <div onClick={() => setSelectType('remove')} className={`taskBtnRemove ${selectType === 'remove' ? 'active' : ''}`}>
                                    {t('dialog.remove')}
                                </div>
                            </>
                        }
                    </div>
                    <div className={classes.taskSchedule}>
                        {selectType === 'add' && taskSchedule?.add?.map(e => (inner(e)))}
                        {selectType === 'remove' && taskSchedule?.remove?.map(e => (inner(e)))}
                        {
                            (selectType === 'add' && !taskSchedule?.add?.length) || (selectType === 'remove' && !taskSchedule?.remove?.length) ?
                                <div>{t('dialog.noData')}</div> : ''
                        }
                    </div>
                </>
            }
        </>
    );
};

export default TaskSchdule;
