import React, { useState, useContext, useEffect } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn } from '../commons/FormBtns';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import { SubmitBtnContext } from '../../App';
import { removeUtilityAPI } from '../../apis/removeUtilityAPI';
import { filterdArr } from '../../helpers/arrayHandlers';

const RemoveUtilityManagementDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('adminUtility');
    const { onClose, open, fwData } = props;
    const [utilityArr, setUtiltyArr] = useState([]);
    const [modelNameArr, setModelNameArr] = useState([]);
    const [companyArr, setCompanyArr] = useState([]);
    const { dispatch } = useContext(SubmitBtnContext);

    const handleRemoveUtility = async () => {
        dispatch({ type: 'showLoading' });
        const successText = `${t('dialog.successfullyRemoveUtilityManagement')}`;
        const removeResult = await removeUtilityAPI(fwData.id, t, successText);
        dispatch({ type: 'stopLoading' });
        if (removeResult) onClose();
    };

    useEffect(() => {
        if (fwData && fwData.utility_company_model_firmware_maps) {
            const dataArray = fwData.utility_company_model_firmware_maps;
            const modelNameArr = dataArray.map(o => {
                if (o.device_model) return o.device_model.name;
                return false;
            });
            const uniqueModelNamesArr = filterdArr(modelNameArr);
            setModelNameArr(uniqueModelNamesArr.join(', '));
            const companyArr = dataArray.map(o => {
                if (o.company) return o.company.name;
                return false;
            });
            const uniqueCompanyNamesArr = filterdArr(companyArr);
            setCompanyArr(uniqueCompanyNamesArr.join(', '));
            const utilityArr = dataArray.map(o => {
                if (o.firmware) return o.firmware.name;
                return false;
            });
            const uniqueUtilityNamesArr = filterdArr(utilityArr);
            setUtiltyArr(uniqueUtilityNamesArr.join(', '));
        };
    }, [open, fwData]);

    return (
        <Dialog
            maxWidth='sm'
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-fw-utility"
            aria-describedby="add-edit-fw-utility-confirmation"
        >
            <DialogTitle id="add-edit-fw-utility">
                {t('dialog.removeUtilityManagement')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>{t('dialog.removeUtilityManagementDescription')}</DialogContentText>
                <Divider />
                <Typography variant="caption">{t('table.utility')}</Typography>
                <Typography variant="body2" color="primary">{fwData ? utilityArr : '-'}</Typography>
                <Typography variant="caption">{t('table.modelName')}</Typography>
                <Typography variant="body2" color="primary">{fwData ? modelNameArr : '-'}</Typography>
                <Typography variant="caption">{t('table.company')}</Typography>
                <Typography variant="body2" color="primary">{fwData ? companyArr : '-'}</Typography>
            </DialogContent>
            <DialogActions>
                <StyledSubmitBtn
                    btnColor="primary"
                    type="button"
                    onClick={handleRemoveUtility}
                    startIcon={<DeleteRounded />}
                    btnText={t('dialog.removeBtn')} />
                <Button
                    onClick={onClose}
                    color="secondary"
                    type="button">
                    {t('dialog.cancelBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveUtilityManagementDialog;
