import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const deviceModifyAPI = async (type, addIds, removeIds) => {
    const token = await localforage.getItem("token");
    let result = []
    if (addIds) {
        result.push({
            assign: "add",
            type: type,
            tag_ids: type === 'tag' ? addIds : undefined,
            company_ids: type === 'company' ? addIds : undefined,
            group_ids: type === 'group' ? addIds : undefined
        })
    }
    if (removeIds) {
        result.push({
            assign: "remove",
            type: type,
            tag_ids: type === 'tag' ? removeIds : undefined,
            company_ids: type === 'company' ? removeIds : undefined,
            group_ids: type === 'group' ? removeIds : undefined
        })
    }
    try {
        let res = await axios({
            url: `${url}/api/v2/schedule/device-modify`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: { modify: result }
        });
        return res.data.taskSchedule;
    } catch (error) {
        errorHandling(error, false);
    }
};
