import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";

export const getUsbPortListApi = async (device_id) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/usb/ports`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const detectUsbPortApi = async (device_ids) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/detect-usb-port-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                execute_type: "detect_usb_ports",
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const setUsbPortStatusApi = async (
    ids,
    port_id,
    status_value, t, successfulText
) => {
    const token = await localforage.getItem("token");
    const device_ids = [ids]
    const status = status_value ? 'Enable' : 'Disable'
    try {
        let res = await axios({
            url: `${url}/api/v2/command/set-usb-port-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                port_id,
                status,
                execute_type: "set_usb_port",
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
