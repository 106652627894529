import React, { useState, useEffect, useContext, useRef } from 'react';
import useStyles from '../../styles/deviceStyle';
import { url } from '../../apis/apiSetup';
import ReactDOM from 'react-dom';
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from '../../hoc/FullWidthTableWrapper';
import { Typography } from '@material-ui/core';
import '../../css/dataTable.css';
import * as $ from 'jquery';
import 'datatables.net';
import * as localforage from 'localforage';
import SelectStatusVoltage from './SelectStatusVoltage';
import moment from 'moment';
import { detectIOStatusAPI } from '../../apis/detectIOStatusAPI';
import { IOSetUpAPI } from "../../apis/deviceControlApi";
import { SubmitBtnContext } from '../../App';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import { StyledSubmitBtn } from '../commons/FormBtns';
import { language } from '../../helpers/datatableLanguage';
import StatusChip from '../commons/StatusChip';
import DetectBtn from '../commons/DetectBtn';
import AutoReloadTableBtn from '../commons/AutoReloadTableBtn';

const SingleDeviceIOSetUpTable = (props) => {
    const mounted = useRef();
    const classes = useStyles();
    const { t } = useTranslation('device');
    const { handleClose, executeType, deviceId, successfulUpdateText, successfulDetectText } = props;
    const datetime = moment.utc().format('YYYY-MM-DD HH:mm');
    const { dispatch } = useContext(SubmitBtnContext);
    const [originalVoltages, setOriginalVoltages ] = useState([]);
    const [ tableRecordTotal, setTableRecordTotal ] = useState(0);
    const [ voltages, setVoltages ] = useState([]); // this will be send as the updated voltage array for api to update
    const [newVoltages, setNewVoltages ] = useState([]); // this is for datatable voltage update
    const [ disableUpdateBtn, setDisableUpdateBtn ] = useState(true);
    const [ disableDetectBtn, setDisableDetectBtn ] = useState(false);

    const handleUpdateBtnDisable = async() => {
        const hasVoltagesToUpdate = voltages.length > 0 ? true : false;
        setDisableUpdateBtn(!hasVoltagesToUpdate);
    }

    const table = async () => {

        const token = await localforage.getItem('token');
        $.fn.dataTable.ext.errMode = 'throw';

        $('#io-detect-table').DataTable({
            processing: true,
            serverSide: true,
            language: language(t),
            order: [[0, 'desc']],
            info: false,
            autoWidth: false,
            stateSave: false,
            destroy: true,
            ordering: true,
            searching: false,
            paging: false,
            ajax: {
                url: `${url}/api/v2/io/device-io-datatable`,
                type: 'GET',
                data: {
                    device_id: deviceId
                },
                complete: (response) => {
                    const data = response.responseJSON.data
                    setOriginalVoltages(data ? data : [])
                    setTableRecordTotal(data ? data.length : 0)
                },
                beforeSend: (request) => {
                    request.setRequestHeader('Authorization', 'Bearer ' + token );
                    request.setRequestHeader('Accept', 'application/json');
                },
                error: ()=>{
                },
            },
            columns: [
                {   data: 'name',
                    title: `${t('ioSetUp.ioPort')}`,
                    searchable: false,
                },
                {   data: 'voltage',
                    title: `${t('ioSetUp.status')}`,
                    searchable: false,
                    createdCell: (td, data) => {
                        ReactDOM.render (<StatusChip statusLabel={ data > 0 ? "enabled" : "disabled"} />, td);
                    }
                },
                {   data: 'voltage',
                    title: `${t('ioSetUp.voltage')}`,
                    searchable: false,
                    render: (data) => {
                        if(data > 0){
                            return `${data}V`
                        }
                        return `${t(`ioSetUp.disabled`)}`
                    }
                },
                {   data: 'modified_at',
                    title: `${t('ioSetUp.date')}`,
                    searchable: false,
                    render: (data) => {
                        return moment(data).local().format('YYYY-MM-DD HH:mm')
                    }
                },
                {   data: 'id',
                    title: `${t('ioSetUp.action')}`,
                    searchable: false,
                    createdCell: (td, data, rowData) => {
                        const comName = rowData.name;
                        ReactDOM.render(
                        <SelectStatusVoltage
                            formikOnChange={(e)=>handleSetVoltage(e, data, comName)}
                            currentPort={comName}
                            singleDevice={true}
                            allIOPortsArrFromAPI={rowData.io_type.json}
                        />
                        , td)
                    },
                }
            ]
        })
    };

    const handleSetVoltage = (e, votageId, comName) => {
        const voltageValue = e.target.value
        const updatedVoltage = { id: votageId, name: comName, voltage: voltageValue}
        const updatedVoltageIndex = voltages.findIndex(o => o.id === votageId)
        // check if any voltage value has been set up,
        // including the 'disable the port' which the value is '0',
        // then enable the Update button for updating,
        if(voltageValue || voltageValue === 0){
            setNewVoltages(updatedVoltageIndex < 0 ? voltages.push(updatedVoltage) : voltages.splice(updatedVoltageIndex, 1, updatedVoltage))
        } else {
            setNewVoltages(updatedVoltageIndex < 0 ? voltages.push(updatedVoltage) : voltages.splice(updatedVoltageIndex, 1))
        }
        handleUpdateBtnDisable();
    };

    const handleDetectIOStatus = async(e) => {
        if(!e.detail || e.detail === 1) {
            setDisableDetectBtn(true);
            const detectResult = await detectIOStatusAPI(deviceId, undefined, undefined, datetime, t, successfulDetectText);
            if(detectResult) handleReloadTable();
        }
    };

    const handleExecuteAction = async() => {
        dispatch({type: 'showLoading'});
        const company_id = await localforage.getItem('companyId')
        const tempPorts = []
        const tempVoltages = []
        voltages.forEach((p) => {
            tempPorts.push(p.name)
            tempVoltages.push(p.voltage)
        })
        console.log(tempPorts, tempVoltages)
        const responseStatus = await IOSetUpAPI('company', company_id, [deviceId], undefined, undefined, executeType, datetime, tempPorts, tempVoltages, t, successfulUpdateText);
        if(responseStatus) handleClose()
        dispatch({type: 'stopLoading'});
    };

    const handleReloadTable = () => {
        table();
    };

  useEffect(() => {
    if (!mounted.current) {
        table();
      mounted.current = true;
    }
  });
    return(
        <>
            <Typography variant="h5">
                {t('ioSetUp.dialogTitle')}
            </Typography>
            <Typography variant="body1" className={classes.detectTextAndBtn}>
                {t('ioSetUp.dialogDetectText')}
                <DetectBtn
                    onClick={handleDetectIOStatus}
                    disableDetectBtn={disableDetectBtn}
                    btnText={t('ioSetUp.detectBtn')}
                />
                <AutoReloadTableBtn
                    onClick={handleReloadTable}
                />
            </Typography>
            <HOCforFullWidthTable>
                <table id="io-detect-table" className="mdl-data-table dataTable">
                    <thead>
                        <tr>
                            <th className="sorting_desc">{t('ioSetUp.ioPort')}</th>
                            <th className="sorting">{t('ioSetUp.status')}</th>
                            <th className="sorting">{t('ioSetUp.voltage')}</th>
                            <th className="sorting">{t('ioSetUp.date')}</th>
                            <th className="sorting">{t('ioSetUp.action')}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
            {!tableRecordTotal ?
                ''
                :
                <div className={classes.styledSubmitBtnWrap}>
                    <StyledSubmitBtn
                        startIcon={<SendRoundedIcon />}
                        btnText={t('btn.confirmBtn')}
                        btnColor={!disableUpdateBtn ? "primary" : "secondary"}
                        type="submit"
                        onClick={!disableUpdateBtn ? handleExecuteAction : null}
                    />
                </div>
            }
        </>
    )
}

export default SingleDeviceIOSetUpTable;
