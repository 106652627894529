import React, { useEffect, useState, useContext } from "react";
import { Typography } from '@material-ui/core';
import useStyles from '../../styles/alarmNotificationStyle';
import { SubmitBtnContext } from '../../App';
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from '../../hoc/FullWidthTableWrapper';
import { AutorenewRounded } from '@material-ui/icons';
import AlarmResetHistoryDialog from "./AlarmTriggeredHistoryDialog";
import { table } from '../../helpers/triggeredDeviceListDatatable';

const TriggeredDeviceList = () => {
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { dispatch } = useContext(SubmitBtnContext);
    const [deviceId, setDeviceId] = useState();
    const [openResetDialog, setOpenResetDialog] = useState(false);
    const [ shouldDisbleResetBtn, setShouldDisbleResetBtn ] = useState(false);

    const handleReloadDataTable = () => {
        table(t, handleResetDialogOpen);
    }

    const handleResetDialogOpen = (deviceId, shouldDisableResetBtn) => {
        setOpenResetDialog(true);
        setDeviceId(deviceId);
        setShouldDisbleResetBtn(shouldDisableResetBtn);
    };
    
    const handleResetDialogClose = () => {
        setOpenResetDialog(false);
    };

    const handleResetAlarm = async() => {
        dispatch({type: 'showLoading'});
    }

    useEffect(()=>{
        table(t, handleResetDialogOpen);
    }, [t])

    return(
        <div>
            <Typography variant="h5" className={classes.tableTitle}>
                {t('table.triggeredDeviceList')}
                <AutorenewRounded 
                    onClick={handleReloadDataTable}
                    size="small" 
                    className={classes.reload_btn}
                />
            </Typography>
            <HOCforFullWidthTable>
                <table id="triggered-device-table" className="mdl-data-table dataTable no-footer dtr-inline">
                    <thead>
                        <tr>
                            <th className="sorting_desc serial_number">{t('table.serialNumber')}</th>
                            <th className="sorting owners">{t('table.owners')}</th>
                            <th className="sorting groups">{t('table.groups')}</th>
                            <th className="sorting tag">{t('table.tag')}</th>
                            <th className="sorting">{t('table.action')}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
            <AlarmResetHistoryDialog 
                deviceId={deviceId}
                shouldDisbleResetBtn={shouldDisbleResetBtn}
                handleResetAlarm={handleResetAlarm}
                handleResetDialogClose={handleResetDialogClose}
                openResetDialog={openResetDialog} />
        </div>
    )
}

export default TriggeredDeviceList;
