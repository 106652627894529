import Tooltip from "@material-ui/core/Tooltip";
import {
    currentDateInUTC,
    utcStringToLocalDatetimeMinConverter,
} from "./datetimeHandlers";
import * as dayjs from "dayjs";
import {
    blockWindowsNotifyApi,
    usbLockApi,
    lockCombinationKeyApi,
    powerApi,
} from "../apis/deviceShortcutTasksApi";
import HasPermission from "../components/commons/HasPermission";

const heartBeat = (device) => {
    if (
        device &&
        device.heartbeat_interval &&
        device.power &&
        device.power.device_power &&
        device.power.device_power.executed_at
    ) {
        const deviceDate = dayjs(device.power.device_power.executed_at).format(
            "YYYY-MM-DD HH:mm:ss"
        );
        const currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
        const currentDateForDiff = dayjs(currentDate);
        const deviceDateForDiff = dayjs(
            utcStringToLocalDatetimeMinConverter(deviceDate)
        );
        const timeDiff = currentDateForDiff.diff(
            utcStringToLocalDatetimeMinConverter(deviceDateForDiff),
            "second"
        );
        return timeDiff > device.heartbeat_interval;
    } else {
        return false;
    }
};

export const submitTask = async (
    dialogType,
    rowData,
    t,
    reloadTableWithGroupId,
    setDialog,
    setDialogType,
    setLoading
) => {
    let removeResult;
    setLoading(true);
    const successText = "dialog.successText";
    switch (dialogType) {
        case "powerOff":
            removeResult = await powerApi(
                [rowData.id],
                undefined,
                rowData.online_offline_status,
                "shutdown",
                "device_power",
                currentDateInUTC(),
                rowData.os_type,
                t,
                successText
            );
            break;

        case "powerOn":
            removeResult = await powerApi(
                [rowData.id],
                undefined,
                rowData.online_offline_status,
                "start",
                "device_power",
                currentDateInUTC(),
                rowData.os_type,
                t,
                successText
            );
            break;

        case "reboot":
            removeResult = await powerApi(
                [rowData.id],
                undefined,
                rowData.online_offline_status,
                "reboot",
                "device_power",
                currentDateInUTC(),
                rowData.os_type,
                t,
                successText
            );
            break;

        case "sleep":
            removeResult = await powerApi(
                [rowData.id],
                undefined,
                rowData.online_offline_status,
                "sleep",
                "device_power",
                currentDateInUTC(),
                rowData.os_type,
                t,
                successText
            );
            break;

        case "wakeUp":
            removeResult = await powerApi(
                [rowData.id],
                undefined,
                rowData.online_offline_status,
                "wake_up",
                "device_power",
                currentDateInUTC(),
                rowData.os_type,
                t,
                successText
            );
            break;

        case "lockCombinationKey":
            removeResult = await lockCombinationKeyApi(
                [rowData.id],
                undefined,
                "true",
                "disable_combination_key",
                currentDateInUTC(),
                t,
                successText
            );
            break;

        case "unlockCombinationKey":
            removeResult = await lockCombinationKeyApi(
                [rowData.id],
                undefined,
                "false",
                "disable_combination_key",
                currentDateInUTC(),
                t,
                successText
            );
            break;

        case "blockWindowsNotify":
            removeResult = await blockWindowsNotifyApi(
                [rowData.id],
                undefined,
                "true",
                "disable_windows_notify",
                currentDateInUTC(),
                t,
                successText
            );
            break;

        case "openWindowsNotify":
            removeResult = await blockWindowsNotifyApi(
                [rowData.id],
                undefined,
                "false",
                "disable_windows_notify",
                currentDateInUTC(),
                t,
                successText
            );
            break;

        case "lockUsbPort":
            removeResult = await usbLockApi(
                [rowData.id],
                undefined,
                "true",
                "lock_usb",
                currentDateInUTC(),
                t,
                successText
            );
            break;

        case "unlockUsbPort":
            removeResult = await usbLockApi(
                [rowData.id],
                undefined,
                "false",
                "lock_usb",
                currentDateInUTC(),
                t,
                successText
            );
            break;

        default:
            break;
    }
    if (removeResult) {
        setLoading(false);
        setDialog(false);
        reloadTableWithGroupId();
        setDialogType("");
    }
};

export const rebootArea = (data, Logo, openFunction, t, textNoty) => {
    // device online
    if (data.online_offline_status === "online") {
        // already operate before so have
        if (data.power.device_power) {
            switch (data.power.device_power.status) {
                case "completed":
                    if (data.os_type === "android") {
                        return (
                            <HasPermission permission="single_reboot">
                                <Tooltip title={t("iconArea.reboot")} placement="right">
                                    <div
                                        className="tableIcon"
                                        onClick={() => openFunction("reboot")}
                                    >
                                        <Logo.Blue />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                    } else {
                        if (data.heart_beated_at && heartBeat(data)) {
                            return (
                                <HasPermission permission="single_reboot">
                                    <Tooltip title={t(`iconArea.reboot`)} placement="right">
                                        <div
                                            className="tableIcon"
                                            onClick={() => openFunction("reboot")}
                                        >
                                            <Logo.Blue />
                                        </div>
                                    </Tooltip>
                                </HasPermission>
                            );
                        } else {
                            return (
                                <HasPermission permission="single_reboot">
                                    <Tooltip
                                        title={"[Disabled] - " + t(`iconArea.reboot`)}
                                        placement="right"
                                    >
                                        <div
                                            className="tableIcon disabled"
                                            onClick={() => textNoty(t("error.incompleted"))}
                                        >
                                            <Logo.Gray />
                                        </div>
                                    </Tooltip>
                                </HasPermission>
                            );
                        }
                    }
                case "failed":
                    return (
                        <HasPermission permission="single_reboot">
                            <Tooltip
                                title={"[Failed] - " + t("iconArea.reboot")}
                                placement="right"
                            >
                                <div
                                    className="tableIcon"
                                    onClick={() => openFunction("reboot")}
                                >
                                    <Logo.Red />
                                </div>
                            </Tooltip>
                        </HasPermission>
                    );
                // queued scheduled relayed
                default:
                    return (
                        <HasPermission permission="single_reboot">
                            <Tooltip
                                title={
                                    `[${data.power.device_power.status[0].toUpperCase() +
                                    data.power.device_power.status.slice(1)}] - ` +
                                    t("iconArea.reboot")
                                }
                                placement="right"
                            >
                                <div
                                    className="tableIcon disabled"
                                    onClick={() => textNoty(t("error.incompleted"))}
                                >
                                    <Logo.Gray />
                                </div>
                            </Tooltip>
                        </HasPermission>
                    );
            }
        }
        // no device_power cuz default value
        if (!data.power.device_power) {
            return (
                <HasPermission permission="single_reboot">
                    <Tooltip title={t("iconArea.reboot")} placement="right">
                        <div className="tableIcon" onClick={() => openFunction("reboot")}>
                            <Logo.Blue />
                        </div>
                    </Tooltip>
                </HasPermission>
            );
        }
    }
    // device not online prevent operate
    if (data.online_offline_status !== "online") {
        return (
            <HasPermission permission="single_reboot">
                <Tooltip title={t("iconArea.reboot")} placement="right">
                    <div
                        className="tableIcon disabled"
                        onClick={() => textNoty(t("error.rebootsleep"))}
                    >
                        <Logo.Gray />
                    </div>
                </Tooltip>
            </HasPermission>
        );
    }
};

export const powerArea = (
    data,
    on,
    off,
    OffLogo,
    OnLogo,
    openFunction,
    t,
    textNoty
) => {
    // online
    if (data.online_offline_status === "online") {
        // android devices
        if (data.os_type === "android") {
            // device is sleep
            if (
                data.power.device_power &&
                data.power.device_power.json &&
                JSON.parse(data.power.device_power.json).system_booting_type === "sleep"
            ) {
                switch (data.power.device_power.status) {
                    case "failed":
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip
                                    title={"[Failed] - " + t(`iconArea.${on}`)}
                                    placement="right"
                                >
                                    <div className="tableIcon" onClick={() => openFunction(on)}>
                                        <OnLogo.Red />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                    case "completed":
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip title={t(`iconArea.${on}`)} placement="right">
                                    <div className="tableIcon" onClick={() => openFunction(on)}>
                                        <OnLogo.Blue />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                    default:
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip
                                    title={
                                        `[${data.power.device_power.status[0].toUpperCase() +
                                        data.power.device_power.status.slice(1)}] - ` +
                                        t(`iconArea.${on}`)
                                    }
                                    placement="right"
                                >
                                    <div
                                        className="tableIcon disabled"
                                        onClick={() => textNoty(t("error.incompleted"))}
                                    >
                                        <OnLogo.Gray />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                }
            }
            // Device not sleep
            if (
                data.power.device_power &&
                data.power.device_power.json &&
                JSON.parse(data.power.device_power.json).system_booting_type !== "sleep"
            ) {
                return (
                    <HasPermission permission="single_power">
                        <Tooltip title={t(`iconArea.${off}`)} placement="right">
                            <div className="tableIcon" onClick={() => openFunction(off)}>
                                <OffLogo.Blue />
                            </div>
                        </Tooltip>
                    </HasPermission>
                );
            }
            // default value cuz not yet operate
            if (!data.power.device_power) {
                return (
                    <HasPermission permission="single_power">
                        <Tooltip title={t(`iconArea.${off}`)} placement="right">
                            <div className="tableIcon" onClick={() => openFunction(off)}>
                                <OffLogo.Blue />
                            </div>
                        </Tooltip>
                    </HasPermission>
                );
            }
        }
        // windows devices
        if (data.os_type === "windows") {
            // device have status
            if (data.power.device_power) {
                switch (data.power.device_power.status) {
                    case "completed":
                        if (data.heart_beated_at && heartBeat(data)) {
                            return (
                                <HasPermission permission="single_power">
                                    <Tooltip title={t(`iconArea.${off}`)} placement="right">
                                        <div
                                            className="tableIcon"
                                            onClick={() => openFunction(off)}
                                        >
                                            <OffLogo.Blue />
                                        </div>
                                    </Tooltip>
                                </HasPermission>
                            );
                        } else {
                            return (
                                <HasPermission permission="single_power">
                                    <Tooltip
                                        title={"[Disabled] - " + t(`iconArea.${off}`)}
                                        placement="right"
                                    >
                                        <div
                                            className="tableIcon disabled"
                                            onClick={() => textNoty(t("error.incompleted"))}
                                        >
                                            <OffLogo.Gray />
                                        </div>
                                    </Tooltip>
                                </HasPermission>
                            );
                        }
                    case "failed":
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip
                                    title={"[Failed] - " + t(`iconArea.${off}`)}
                                    placement="right"
                                >
                                    <div className="tableIcon" onClick={() => openFunction(off)}>
                                        <OffLogo.Red />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                    default:
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip
                                    title={
                                        `[${data.power.device_power.status[0].toUpperCase() +
                                        data.power.device_power.status.slice(1)}] - ` +
                                        t(`iconArea.${off}`)
                                    }
                                    placement="right"
                                >
                                    <div
                                        className="tableIcon disabled"
                                        onClick={() => textNoty(t("error.incompleted"))}
                                    >
                                        <OffLogo.Gray />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                }
            } else {
                // default value cuz not yet operate
                return (
                    <HasPermission permission="single_power">
                        <Tooltip title={t(`iconArea.${off}`)} placement="right">
                            <div className="tableIcon" onClick={() => openFunction(off)}>
                                <OffLogo.Blue />
                            </div>
                        </Tooltip>
                    </HasPermission>
                );
            }
        }
    }
    // device not online
    if (data.online_offline_status !== "online") {
        // android
        if (data.os_type === "android") {
            return (
                <HasPermission permission="single_power">
                    <Tooltip title={t(`iconArea.${on}`)} placement="right">
                        <div
                            className="tableIcon disabled"
                            onClick={() => textNoty(t("error.rebootsleep"))}
                        >
                            <OnLogo.Gray />
                        </div>
                    </Tooltip>
                </HasPermission>
            );
        }
        // windows
        if (data.os_type === "windows") {
            // device have status
            if (data.power.device_power) {
                switch (data.power.device_power.status) {
                    case "completed":
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip title={t(`iconArea.${on}`)} placement="right">
                                    <div className="tableIcon" onClick={() => openFunction(on)}>
                                        <OnLogo.Blue />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                    case "failed":
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip
                                    title={"[Failed] - " + t(`iconArea.${on}`)}
                                    placement="right"
                                >
                                    <div className="tableIcon" onClick={() => openFunction(on)}>
                                        <OnLogo.Red />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                    default:
                        return (
                            <HasPermission permission="single_power">
                                <Tooltip
                                    title={
                                        `[${data.power.device_power.status[0].toUpperCase() +
                                        data.power.device_power.status.slice(1)}] - ` +
                                        t(`iconArea.${on}`)
                                    }
                                    placement="right"
                                >
                                    <div
                                        className="tableIcon disabled"
                                        onClick={() => textNoty(t("error.incompleted"))}
                                    >
                                        <OnLogo.Gray />
                                    </div>
                                </Tooltip>
                            </HasPermission>
                        );
                }
            } else {
                // device no status cuz not yet operate
                return (
                    <HasPermission permission="single_power">
                        <Tooltip title={t(`iconArea.${on}`)} placement="right">
                            <div className="tableIcon" onClick={() => openFunction(on)}>
                                <OnLogo.Blue />
                            </div>
                        </Tooltip>
                    </HasPermission>
                );
            }
        }
    }
};

export const securityArea = (
    data,
    status,
    lockText,
    unlockText,
    LockLogo,
    UnlockLogo,
    openFunction,
    t,
    textNoty
) => {
    // Device don't have default value cuz not yet operate before
    if (!data) {
        return (
            <Tooltip title={t(`iconArea.${lockText}`)} placement="right">
                <div className="tableIcon" onClick={() => openFunction(lockText)}>
                    <LockLogo.Blue />
                </div>
            </Tooltip>
        );
    } else {
        // device have status
        switch (data.status) {
            case "completed":
                return (
                    <Tooltip
                        title={
                            status ? t(`iconArea.${unlockText}`) : t(`iconArea.${lockText}`)
                        }
                        placement="right"
                    >
                        <div
                            className="tableIcon"
                            onClick={() => openFunction(status ? unlockText : lockText)}
                        >
                            {status ? <UnlockLogo.Blue /> : <LockLogo.Blue />}
                        </div>
                    </Tooltip>
                );
            case "failed":
                return (
                    <Tooltip
                        title={
                            status
                                ? "[Failed] - " + t(`iconArea.${unlockText}`)
                                : "[Failed] - " + t(`iconArea.${lockText}`)
                        }
                        placement="right"
                    >
                        <div
                            className="tableIcon"
                            onClick={() => openFunction(status ? unlockText : lockText)}
                        >
                            {status ? <UnlockLogo.Red /> : <LockLogo.Red />}
                        </div>
                    </Tooltip>
                );
            default:
                return (
                    <Tooltip
                        title={
                            status
                                ? `[${data.status[0].toUpperCase() +
                                data.status.slice(1)}] - ` + t(`iconArea.${unlockText}`)
                                : `[${data.status[0].toUpperCase() +
                                data.status.slice(1)}] - ` + t(`iconArea.${lockText}`)
                        }
                        placement="right"
                    >
                        <div
                            className="tableIcon disabled"
                            onClick={() => textNoty(t("error.incompleted"))}
                        >
                            {status ? <UnlockLogo.Gray /> : <LockLogo.Gray />}
                        </div>
                    </Tooltip>
                );
        }
    }
};
