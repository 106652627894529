import React from "react";
import ReactDOM from "react-dom";
import { Typography, Box } from "@material-ui/core";
import { url } from "../apis/apiSetup";
import * as localforage from "localforage";
import "../css/dataTable.css";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-autofill";
import "datatables.net-buttons";
import "datatables.net-colreorder";
import "datatables.net-fixedcolumns";
import "datatables.net-fixedheader";
import "datatables.net-scroller";
import "datatables.net-searchpanes";
import { searchStyleRender } from "./datatableSearchStyleRender";
import StatusChip from "../components/commons/StatusChip";
import DeviceOwners from "../components/commons/DeviceOwners";
import { language } from "./datatableLanguage";
import { alarmPolicyRuleGenerator } from "./alarmPolicyRuleGenerator";
import { DeleteRounded, EditRounded, RefreshRounded } from "@material-ui/icons";
import { utcFormatToLocalDateTimeWithSecond } from "./datetimeHandlers";
import HasPermission from "../components/commons/HasPermission";

export const table = async (
    t,
    classes,
    handleEditDialogOpen,
    handleRemoveDialogOpen
) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";

    $("#deployment-table").DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: true,
        paging: true,
        pageLength: 15,
        ajax: {
            url: `${url}/api/v2/alarm/deploy/datatable`,
            type: "GET",
            dataSrc: "data",
            data: {
                company_id: companyId,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
        },
        columns: [
            {
                data: "serial_number",
                title: `${t("table.serialNumber")}`,
                searchable: true,
                render: (data) => {
                    return data;
                },
            },
            {
                data: "created_at",
                title: `${t("table.ruleDescription")} - ${t("table.status")} - ${t(
                    "table.deployedDate"
                )}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        rowData.alarm_policies_devices_maps.map((o, i) => {
                            let rule = alarmPolicyRuleGenerator(
                                o.alarm_policy.alarm_policy_option,
                                o.alarm_policy.value,
                                o.alarm_policy.operator
                            );
                            let status = o.status !== null ? o.status : "-";
                            let date = utcFormatToLocalDateTimeWithSecond(o.created_at);
                            return (
                                <Box display="flex" flexDirection="column" key={i}>
                                    <Box
                                        display="flex"
                                        flexDirection="row"
                                        alignItems="center"
                                        className={classes.ruleDescriptionItem}
                                    >
                                        {rule}
                                        <StatusChip statusLabel={status} />
                                        <Typography variant="caption" color="secondary">
                                            {date}
                                        </Typography>
                                    </Box>
                                </Box>
                            );
                        }),
                        td
                    );
                },
            },
            {
                data: "companies",
                name: "companies.name",
                title: `${t("table.owners")}`,
                searchable: true,
                orderable: false,
                createdCell: (td, data) => {
                    let companyNames = data.map((o) => o.name);
                    ReactDOM.render(<DeviceOwners owners={companyNames} />, td);
                },
            },
            {
                data: "id",
                title: `${t("table.action")}`,
                searchable: false,
                orderable: false,
                className: "action",
                createdCell: (td, data, rowData) => {
                    let allStatus = rowData.alarm_policies_devices_maps.map(
                        (o) => o.status
                    );
                    let hasFailedStatus = allStatus.includes("failed");
                    let deviceId = data;
                    let ruleIds = rowData.alarm_policies_devices_maps.map(
                        (o) => o.alarm_policy_id
                    );
                    ReactDOM.render(
                        <>
                            <HasPermission permission="alarm_edit_deployment_list">
                                <EditRounded
                                    onClick={() =>
                                        handleEditDialogOpen("update", deviceId, ruleIds)
                                    }
                                />
                            </HasPermission>
                            <HasPermission permission="alarm_delete_deployment_list">
                                <DeleteRounded
                                    onClick={() => handleRemoveDialogOpen(rowData)}
                                />
                            </HasPermission>
                            {hasFailedStatus ? (
                                <RefreshRounded
                                    onClick={() => handleEditDialogOpen("update")}
                                />
                            ) : (
                                ""
                            )}
                        </>,
                        td
                    );
                },
            },
        ],
    });
    // render Material UI Search Input
    searchStyleRender();
};
