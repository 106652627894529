import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { currentDateInUTC, scheduledDateInUTC } from '../helpers/datetimeHandlers';
import { apiSelectedDeviceIdsHandler } from '../helpers/apiSelectedIdsHandler';

export const IOSetUpAPI = async (deviceId, devices, selectedDevicesIds, values, executeType, datetime, t, successfulText) => {

    let formData = new FormData();
    const voltage = deviceId ? values : values.voltage;
    // single device
    if (deviceId) {
        formData.append('device_id', deviceId)
        voltage.forEach(voltage => {
            formData.append('io_voltage_ids[]', voltage.id)
        })
        const setIOPorts = voltage.map(voltage => {
            return { "name": voltage.name, "voltage": voltage.voltage }
        })
        formData.append('set_io_ports', JSON.stringify(setIOPorts))
    } else {
        // multiple devices
        apiSelectedDeviceIdsHandler(formData, devices, selectedDevicesIds)
        let setIOPortsArray = [];
        const setIOPorts = { "name": values.port, "voltage": voltage };
        setIOPortsArray.push(setIOPorts);
        formData.append('set_io_ports', JSON.stringify(setIOPortsArray));
    }

    formData.append('execute_type', executeType)
    formData.append('execute_datetime', datetime === 'immediately' ? currentDateInUTC() : scheduledDateInUTC(datetime))
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/command/io-set-send`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData
        })
        successHandling(t, successfulText)
        return true
    } catch (error) {
        errorHandling(error, false);
    }

}
