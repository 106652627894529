import React from 'react';
import { Box, Typography, Switch } from '@material-ui/core';

const TwoFactorSwitch = props => {

    const { checked, onChange, disabled, title, name } = props;

    return (
        <Box display="flex" flexDirection="row" alignItems="center">
            <Typography variant="body1">{title}</Typography>
            <Switch
                checked={checked}
                onChange={onChange}
                disabled={disabled}
                name={name}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
            />
        </Box>
    )
}

export default TwoFactorSwitch;