import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, Box, Typography, Button, Zoom } from '@material-ui/core';
import { MailOutlineRounded } from '@material-ui/icons';
import { useTranslation } from "../../langs/useTranslation";
import ChangePasswordForm from './ChangePasswordForm';
import localforage from 'localforage';
import { vncPwdValuesSchema, vncPwdInitialValues } from '../../config/singleDeviceTaskValuesSchema';

const ChangePasswordDialog = props => {
    
    const { t } = useTranslation('device');
    const { deviceId, hasOldPwd, open, onClose, getDeviceDetail } = props;
    const [ isPasswordSent, setIsPasswordSent ] = useState(false);
    const [ emailAddress, setEmailAddress ] = useState('');
    
    const getUserEmailAddress = async() => {
        const user = await localforage.getItem('user');
        setEmailAddress(user.email);
    }

    useEffect(() => {
        if(open) {
            setEmailAddress();
            setIsPasswordSent(false);
        }
    }, [open])
    
    useEffect(() => {
        if(isPasswordSent) getUserEmailAddress();
    }, [isPasswordSent])

    return(
        <Dialog onClose={onClose} maxWidth="sm" aria-labelledby="change-vnc-password" open={open}>
            <DialogTitle id="change-vnc-password">
            {isPasswordSent ? 
                <Box display="flex" alignItems="center" flexDirection="column">
                    <Zoom in={isPasswordSent}>
                        <Box mb="5px">
                            <MailOutlineRounded color="primary" fontSize="large" />
                        </Box>
                    </Zoom>
                    {t('tasks.vncPasswordSent')}
                </Box>
                :
                `${t('tasks.changeVncPassword')}` 
            }
            </DialogTitle>
            <DialogContent>
                {isPasswordSent ? 
                    <Box display="flex" alignItems="center" flexDirection="column" pl="5%" pr="5%">
                        <Typography variant="body2" align="center">
                            {t('tasks.newPasswordHasBeenSentToYourMail')}
                            <Box display="inline" component="span" color="#1DBBD4">{emailAddress}</Box>
                            {t('tasks.pleaseCheckYourInbox')}
                        </Typography>
                        <Button 
                            onClick={onClose} 
                            variant="contained" 
                            className="mb_16 mt_16"
                        >
                            {t('btn.okBtn')}
                        </Button>
                    </Box>
                    :
                    <ChangePasswordForm 
                        deviceId={deviceId}
                        hasOldPwd={hasOldPwd}
                        getDeviceDetail={getDeviceDetail}
                        setIsPasswordSent={setIsPasswordSent}
                        onClose={onClose}
                        valuesSchema={vncPwdValuesSchema(t, hasOldPwd)}
                        initialValues={vncPwdInitialValues(hasOldPwd)}
                    />
                }
            </DialogContent>
        </Dialog>
    )
}

export default ChangePasswordDialog;