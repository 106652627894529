import React, { useState, useEffect } from 'react';
import { Dialog, Typography, Button, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import useStyles from '../../styles/deviceStyle';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';

const DeviceOwners = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('device');
    const [ owners, setOwners ] = useState(props.owners);
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ ifHasDialog, setIfHasDialog ] = useState(false);
    const open = Boolean(anchorEl);

    const handleDialogOpen = () => {
        setAnchorEl(true);
    }

    const handleDialogClose = () => {
        setAnchorEl(null);
    };

    useEffect(()=>{
        setOwners(props.owners)
        setIfHasDialog(props.owners.length > 3 ? true : false)
    },[props.owners])

    return (
        <div className={classes.deviceOwners}>
            {owners ? 
                <>
                    {owners.map((owner, index)=>{
                        if(index < 3){
                            return(
                                <Typography variant="body2" key={index}>
                                    {owner}
                                </Typography>
                            )
                        }
                        return false;
                        })
                    }
                    {ifHasDialog ? <span className={classes.deviceOwnersBtn} onClick={handleDialogOpen}>more</span> : ''}
                </>
            :
            ''
            }   
            {ifHasDialog ?
                (<Dialog 
                    className={classes.deviceOwnersModal}
                    open={open}
                    onClose={handleDialogClose}
                >   
                    <DialogTitle>
                        {t('category.allTheOwners')}
                    </DialogTitle>
                    <DialogContent>
                        <List>
                            {owners.map((owner, index)=>{
                                return(
                                    <ListItem key={index}>
                                        <ListItemIcon>
                                            <ArrowRightRoundedIcon />
                                        </ListItemIcon>
                                        <ListItemText>
                                            <Typography variant="body2">
                                                {owner}
                                            </Typography>
                                        </ListItemText>
                                    </ListItem>
                                    )
                                })
                            }
                        </List>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleDialogClose} color="primary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>)
                :
                ''
            }
        </div>
    )
}

export default DeviceOwners;