import React, { useEffect, useRef } from "react";
import { Paper, Typography } from "@material-ui/core";
import ReactDOM from "react-dom";
import useStyles from "../../styles/dashboardStyle";
import { url } from "../../apis/apiSetup";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import "../../css/dataTable.css";
import * as localforage from "localforage";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-autofill";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-colreorder";
import "datatables.net-fixedcolumns";
import "datatables.net-fixedheader";
import "datatables.net-keytable";
import "datatables.net-responsive";
import "datatables.net-rowgroup";
import "datatables.net-rowreorder";
import "datatables.net-scroller";
import "datatables.net-searchpanes";
import "datatables.net-select";
import "jquery-datatables-checkboxes";
import "datetime-moment";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import moment from "moment";
import { searchStyleRender } from "../../helpers/datatableSearchStyleRender";
import { language } from "../../helpers/datatableLanguage";
import { oraVersionNum } from "../../helpers/vncLinkHandler";
import {
  androidOraVersion,
  windowsOraVersion
} from "../../config/oraVersionForVncLink";

const VNCAddressForMultipleUsers = (props) => {
  const mounted = useRef()
  const classes = useStyles();
  const { t } = useTranslation("dashboard");
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;

  const table = async () => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    try {

      $("#vnc-table").DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        order: [[0, "desc"]],
        filter: false,
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        responsive: {
          details: {
            renderer: $.fn.dataTable.Responsive.renderer.listHiddenNodes(),
          },
        },
        pageLength: 15,
        ordering: true,
        ajax: {
          url: `${url}/api/v2/statistic/devices-vnc-datatable`,
          type: "GET",
          dataSrc: "data",
          data: {
            company_id: companyId,
          },
          beforeSend: (request) => {
            request.setRequestHeader("Authorization", "Bearer " + token);
            request.setRequestHeader("Accept", "application/json");
          },
          error: (error) => {
            console.log(error);
          },
        },
        columns: [
          {
            data: "device",
            name: "device.serial_number",
            orderDataType: "dom-text",
            title: `${t("vncAddressForMultipleUsers.serialNumber")}`,
            render: function(data, type, row, meta) {
              if (data && type === "filter") {
                return data.serial_number;
              }
              return data.serial_number;
            },
          },
          {
            data: "expired_at",
            title: `${t("vncAddressForMultipleUsers.expiredDate")}`,
            searchable: true,
            render: (data) => {
              return moment(data)
                .local()
                .format("YYYY-MM-DD HH:mm");
            },
          },
          {
            data: "vnc_link",
            title: `${t("vncAddressForMultipleUsers.VNCAddress")}`,
            searchable: false,
            createdCell: (td, data, row) => {
              let deviceData = row?.device;
              let oraVersion = row?.device?.ora_version;
              let osType = row?.device?.os_type;
              let newOrOldVncLink = ' - '
              if (deviceData && oraVersion) {
                if (osType === "windows") newOrOldVncLink = oraVersionNum(oraVersion, osType) >=  windowsOraVersion.production ? row.vnc_link : row.vnc_server
                if (osType === "android") newOrOldVncLink = oraVersionNum(oraVersion, osType) >= androidOraVersion ? row.vnc_link : row.vnc_server
              }
              ReactDOM.render(
                <a href={newOrOldVncLink} target="_blank" rel="noopener noreferrer">
                  {newOrOldVncLink}
                </a>,
                td
              );
            },
          },
          {
            data: "created_at",
            visible: false,
            searchable: false,
          },
        ],
      });
    } catch(err){
      throw new Error(err);
    }
    searchStyleRender();
  };

  useEffect(() => {
    if (!mounted.current) {
      table();
      mounted.current = true;
    }
  })

  return (
    <Paper className={classes.VNCAddressTable}>
      <Typography
        variant={cardTitleTypographyVariant}
        className={classes.cardTitleWrapWithReloadIcon}
      >
        {t("dashboard.vncAddressForMultipleUsersTitle")}
        <span className={classes.vncSubtitle}>
          {t("dashboard.vncOnlyForNubisPro")}
        </span>
        <AutorenewRoundedIcon
          onClick={table}
          size="small"
          className={classes.reload_btn}
        />
      </Typography>
      <HOCforFullWidthTable>
        <table id="vnc-table" className="mdl-data-table dataTable"></table>
      </HOCforFullWidthTable>
    </Paper>
  );
};

export default VNCAddressForMultipleUsers;
