import React from 'react';
import { AutorenewRounded } from '@material-ui/icons';
import useStyles from '../../styles/deviceStyle';

const AutoReloadTableBtn = props => {
    const classes = useStyles();
    const { onClick } = props;

    return (
        <AutorenewRounded
            onClick={onClick}
            size="small" 
            className={classes.reload_btn}
        />
    )
}

export default AutoReloadTableBtn;