import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const updateComponentsAPI = async (deviceId, components) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/device/information/update-components`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                device_id: deviceId,
                components: components
            }
        });
        return true;
    } catch (error) {
        errorHandling(error, true);
        return false;
    }
}
