import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const getLocalFileSettingApi = async (device_id) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/local-file/setting`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                device_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const enableLocalFileApi = async (device_id, json) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    const user = await localforage.getItem("user");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/local-file/enable`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                device_id,
                user_id: user.id,
                json,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const setLocalFileLocationApi = async (
    device_id,
    files_store_location
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/local-file/store-location`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                device_id,
                files_store_location,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const backupFileLocationApi = async (
    device_id,
) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/backup-device-local-file-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                device_id,
                execute_type: "zip_file_upload",
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
