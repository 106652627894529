import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBreadcrumbs-root": {
      marginBottom: theme.spacing(3),
    },
    "& .MuiSvgIcon-root": {
      cursor: "pointer !important",
    },
    "& .MuiAppBar-root": {
      background: "#fff !important",
      marginBottom: "16px !important",
    },
    "& .MuiTab-labelIcon": {
      minHeight: "48px !important",
      padding: "0 !important",
    },
    "& .MuiTab-wrapper": {
      flexDirection: "row !important",
      "& .MuiSvgIcon-root": {
        marginBottom: "0 !important",
        marginRight: "10px !important",
      },
    },
    "& .MuiTabs-indicator": {
      background: "#1DBBD4 !important",
    },
    "& .MuiTabs-fixed": {
      borderBottom: "1px solid #eee !important",
      marginBottom: "8px !important",
    },
    "& table tbody td.editable-field .MuiSvgIcon-root": {
      marginRight: "0 !important",
    },
    "& table tbody td .MuiBadge-root .MuiSvgIcon-root": {
      marginRight: "0 !important",
    },
    "& .MuiBadge-colorError": {
      backgroundColor: "#ff9e40",
    },
  },
  pageTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  reload_btn: {
    top: 10,
    cursor: "pointer",
  },
  tableMoveTopReset: {
    paddingTop: theme.spacing(2),
    marginBottom: "0 !important",
    "& table tbody td .MuiSvgIcon-root": {
      marginRight: "8px !important",
    },
  },
  tableMoveBottomReset: {
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 20px 0 !important",
    },
  },
  tableTitle: {
    display: "inline-flex",
    alignItems: "center",
    position: "relative",
    zIndex: "4 !important",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  singleDeviceDialog: {
    "& .MuiPaper-rounded": {
      padding: "16px 0 !important",
      borderRadius: "13px !important",
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "0 !important",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 32px !important",
    },
    "& div.dataTables_wrapper": {
      paddingBottom: "80px !important",
    },
  },
  dialogTitleWithIconBtn: {
    display: "flex !important",
    alignItems: "center !important",
    position: "relative !important",
    zIndex: "999",
    marginBottom: "16px !important",
    "& .MuiSvgIcon-root": {
      cursor: "pointer !important",
      marginLeft: "10px !important",
    },
  },
  resetTextAndBtn: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0 !important",
    fontWeight: "500",
    "& .MuiButton-contained": {
      marginBottom: "0 !important",
      marginLeft: "8px !important",
      height: "30px !important",
    },
  },
  ruleDescriptionItem: {
    "&>div": {
      display: "inline-block !important",
      margin: "3px 8px",
    },
  },
  deviceCountModal: {
    "& .MuiPaper-rounded": {
      borderRadius: "13px !important",
    },
    "& .MuiListItem-root": {
      padding: "0 !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
    "& .MuiButton-textPrimary": {
      color: "#00adc7 !important",
    },
  },
}));

export default useStyles;
