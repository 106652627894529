import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateSingleDeviceVncPasswordAPI = async (t, deviceId, oldPwd, pwd, onClose, setOldPasswordIncorrect, getDeviceDetail, setIsSubmitting) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/vnc/security/update-device`,
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: {
                device_id: deviceId,
                old_password: oldPwd ? oldPwd : undefined,
                password: pwd,
            },
        })

        const successfulText = `${t('vnc.successfullyUpdateVncPassword')}`;
        successHandling(t, successfulText);
        getDeviceDetail();
        onClose();
        return true;

    } catch (error) {
        setOldPasswordIncorrect(true);
        setIsSubmitting(false);
        errorHandling(error, false, false);
    }

}
