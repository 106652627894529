export const TagsTableReducer = (state, action) => {
    switch (action.type) {
        case "SORT":
            return {
                ...state,
                order: action.payload.isAsc ? "desc" : "asc",
                orderBy: action.payload.property,
            };

        case "CHANGE_ROWS_PERPAGE":
            return {
                ...state,
                rowsPerPage: action.payload.value,
                page: 0,
            };

        case "CHANGE_PAGE":
            return {
                ...state,
                page: action.payload.newPage,
            };
        default:
            throw new Error(`不存在的 action type: ${action.type}`);
    }
};
