import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getDeviceHealthStatusAPI = async () => {
    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId')
    try {
        let response = await axios({
            url: `${url}/api/v2/statistic/devices-health-status`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                company_id: companyId,
            },
        })
        if (response.data && response.data.stat) {
            return response.data.stat;
        }
    } catch (error) {
        errorHandling(error, true);
    }

}
