import React, { useState } from "react";
import { Box, TextField, IconButton, Typography } from "@material-ui/core";
import {
  Visibility,
  VisibilityOff,
  ErrorOutlineOutlined,
} from "@material-ui/icons";
import { ValidationErrorText } from "../commons/ValidationText";
import { pwdLengthError } from "../../config/i18nText";
import includes from "lodash/includes";

export const PasswordTextField = (props) => {
  const {
    onChange,
    value,
    name,
    label,
    error,
    isValidating,
    errorText,
    onChangeError,
    onChangeErrorText,
  } = props;
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(!showPassword);

  return (
    <Box className="password-input-wrap">
      <TextField
        fullWidth
        className="mb_16"
        variant="outlined"
        label={label}
        name={name}
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        value={value}
        inputProps={{
          maxLength: 8,
        }}
        helperText={
          (isValidating && error && <ValidationErrorText text={errorText} />) ||
          (onChangeError && includes(onChangeError, name) && (
            <ValidationErrorText text={onChangeErrorText} />
          ))
        }
      />
      <IconButton onClick={handleClickShowPassword}>
        {showPassword ? <Visibility /> : <VisibilityOff />}
      </IconButton>
    </Box>
  );
};

export const PasswordLengthNotice = (props) => {
  const { t } = props;

  return (
    <Box display="flex" flexDirection="row" alignContent="center">
      <Box mr="5px">
        <ErrorOutlineOutlined fontSize="small" />
      </Box>
      <Typography variant="caption" color="secondary">
        {pwdLengthError(t)}
      </Typography>
    </Box>
  );
};
