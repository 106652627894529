import React, { useEffect } from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel, Grid, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import MuiDateTimePicker from './DateTimePicker';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';

const SelectDate = (props) => {
    const { t } = useTranslation('task');
    const { formikValue, formikName, formikOnChange, formikError, storedDatePickerValueToLocalforageName } = props;
    const classes = useStyles();

    const storedValueToLocalforage = async() => {
        await localforage.setItem(storedDatePickerValueToLocalforageName, 'immediately')
    }
    
    useEffect(()=>{
        if(formikValue === 'immediately'){
            storedValueToLocalforage()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[formikValue])

    return(
        <div className={classes.radioSelectGroup}> 
            <FormControl component="div" fullWidth={true} >
                <RadioGroup aria-label="date" name={formikName} value={formikValue} onChange={formikOnChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <FormControl>
                                <FormControlLabel value="immediately" label={`${t('task.immediately')}`} control={<Radio color="primary" />} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={4}>
                            <FormControl>
                                <FormControlLabel value="scheduling" label={`${t('task.scheduling')}`} control={<Radio color="primary" />} />
                                <MuiDateTimePicker tableDisabled={formikValue === "scheduling" ? false : true} storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </RadioGroup>
                <FormHelperText hidden={!formikError} error={!!formikError}>
                    {formikError}
                </FormHelperText>
            </FormControl>
        </div>    
    )
}

export default SelectDate;