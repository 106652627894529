import adminUtility from "./adminUtility.json";
import alarmNotification from "./alarmNotification.json";
import common from "./common.json";
import dashboard from "./dashboard.json";
import device from "./device.json";
import nubisDrive from "./nubisDrive.json";
import permission from "./permission.json";
import powerManagement from "./powerManagement.json";
import task from "./task.json";
import groups from "./groups.json";
import deviceControl from "./deviceControl.json";
import appUpdateService from "./appUpdateService.json";

export const jp = {
  adminUtility,
  alarmNotification,
  common,
  dashboard,
  device,
  nubisDrive,
  permission,
  powerManagement,
  task,
  groups,
  deviceControl,
  appUpdateService,
};
