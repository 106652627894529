// common form validation related
export const thisIsRequired = t => `${t('tasks.thisIsRequired')}`;

// VNC Security and Password related
export const pwdLengthError = t => `${t('vnc.passwordsMaximumLengthShouldBe8Characters')}`;
export const newPasswordShouldBeDifferent = t => `${t('vnc.newPasswordShouldBeDifferent')}`;
export const confirmNewPasswordShouldBeTheSame = t => `${t('vnc.confirmNewPasswordShouldBeTheSame')}`;
export const oldPasswordIncorrectText = t => `${t('vnc.oldPasswordIsInvalid')}`;
export const twoFactorTitle = t => `${t('vnc.twoFactorAuthentication')}`;
export const invalidVncPwdChar = t => `${t('vnc.vncPwdAccepts09azAZSpecialCharatersOnly')}`;
