import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getFirmwareListAPI = async (filterCondition, deviceId) => {
    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');
    const deviceData = await localforage.getItem(deviceId);
    const deviceModelId = deviceData ? deviceData.device_model_id : undefined;
    try {
        let response = await axios({
            url: `${url}/api/v2/command/firmwares`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                filter_condition: filterCondition,
                company_id: companyId,
                device_model_id: deviceModelId
            },
        });
        if (response.data && response.data.firmwares) {
            return response.data.firmwares;
        };
    } catch (error) {
        errorHandling(error, true);
    }
}
