import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';
import { useTranslation } from "../../langs/useTranslation";
import SingleDeviceIOSetUpTable from '../ioSetUp/SingleDeviceIOSetUpTable';

const IOSetUpDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('task');
    const { onClose, open, deviceId } = props;
    const executeType = "set_io_voltage";
    const successfulUpdateText = `${t('task.successfullySetUpIO')}`;
    const successfulDetectText = `${t('task.successfullyDetectPorts')}`;

    return(
        <Dialog onClose={onClose} open={open} maxWidth="lg" className={`${classes.singleDeviceDialog} ${classes.singleDeviceTableWrapDialog}`}>
            <DialogContent>
                <SingleDeviceIOSetUpTable 
                    handleClose={onClose} 
                    successfulUpdateText={successfulUpdateText} 
                    successfulDetectText={successfulDetectText}
                    executeType={executeType}
                    deviceId={deviceId} />
            </DialogContent>
        </Dialog>
    )
}

export default IOSetUpDialog;