import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from '../../hoc/FullWidthTableWrapper';

const UploadedFileTable = () => {
    const { t } = useTranslation('nubisDrive');

    return(
        <div>
            <HOCforFullWidthTable>
                <table id="uploaded-file-table" className="mdl-data-table dataTable no-footer dtr-inline">
                    <thead>
                        <tr>
                            <th className='file-name sorting'>{t('table.fileName')}</th>
                            <th className='sorting'>{t('table.type')}</th>
                            <th className='sorting'>{t('table.uploadedDate')}</th>
                            <th className='sorting'>{t('table.size')}</th>
                            <th>{t('table.action')}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
        </div>
    )
}

export default UploadedFileTable;
