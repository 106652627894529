import React, { useState, useEffect } from "react";
import {
  Checkbox,
  Typography,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";

const AutoLaunchAfterDeploy = (props) => {
  const { t } = useTranslation("task");
  const {
    checked,
    setAutoLaunch,
    name,
    file,
    selectType,
    nubisDriveFile,
  } = props;
  const [canBeAutoLaunched1, setCanBeAutoLaunched1] = useState(false);
  const [canBeAutoLaunched2, setCanBeAutoLaunched2] = useState(false);
  const fileExtensionForWindowsAndAndroid = ["apk", "msi", "exe"];

  const checkIfCanBeAutoLaunched = async () => {
    if (selectType === "file_from_nubis_drive") {
      let fileExtension = nubisDriveFile.name
        .split(".")
        .pop()
        .toLowerCase();
      let ifInclude = fileExtensionForWindowsAndAndroid.includes(fileExtension);
      if (!ifInclude) {
        setAutoLaunch(false);
      }
      setCanBeAutoLaunched2(ifInclude);
    } else {
      let fileExtension = file.name
        .split(".")
        .pop()
        .toLowerCase();
      let ifInclude = fileExtensionForWindowsAndAndroid.includes(fileExtension);
      if (!ifInclude) {
        setAutoLaunch(false);
      }
      setCanBeAutoLaunched1(ifInclude);
    }
  };

  useEffect(() => {
    if (selectType === "file_from_nubis_drive") {
      if (nubisDriveFile && nubisDriveFile.name) {
        checkIfCanBeAutoLaunched();
      }
    } else {
      if (file && file.name) {
        checkIfCanBeAutoLaunched();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nubisDriveFile, file, selectType]);

  return (
    <FormControl>
      <FormControlLabel
        control={
          selectType === "file_from_nubis_drive" ? (
            <Checkbox
              checked={nubisDriveFile && canBeAutoLaunched2 && checked}
              onChange={() => setAutoLaunch(!checked)}
              disabled={!canBeAutoLaunched2}
              name={name}
            />
          ) : (
            <Checkbox
              checked={file && canBeAutoLaunched1 && checked}
              onChange={() => setAutoLaunch(!checked)}
              disabled={!canBeAutoLaunched1}
              name={name}
            />
          )
        }
        label={`${t("task.authLaunchAfterDeployOptional")}`}
      />
      <Typography variant="caption" color="secondary">
        {t("task.authLaunchAfterDeployMemo")}
      </Typography>
    </FormControl>
  );
};

export default AutoLaunchAfterDeploy;
