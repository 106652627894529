import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { flatArrList, sortArrByName } from '../helpers/arrayHandlers';

export const getCompaniesAPI = async (id) => {

    const token = await localforage.getItem('token');
    const companyId = id || await localforage.getItem('companyId');
    try {
        let response = await axios({
            url: `${url}/api/v2/companies`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                company_id: companyId
            }
        })
        if(id){
            return response.data.companies;
        }
        const companyArr = response.data.companies
        const flattenCompanyArr = flatArrList(companyArr)
        const sortedFlattenCompanyArr = sortArrByName(flattenCompanyArr)
        return sortedFlattenCompanyArr
    } catch (error) {
        errorHandling(error, false);
    }

}
