import React, { useState, useContext } from 'react';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { SubmitBtnContext } from '../../App';
import { SendRounded } from '@material-ui/icons';
import { Button } from '@material-ui/core';
import { PasswordTextField, PasswordLengthNotice } from '../commons/PasswordTextField';
import HasPermission from '../commons/HasPermission';
import reject from 'lodash/reject';
import some from 'lodash/some';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import { updateSingleDeviceVncPasswordAPI } from '../../apis/updateSingleDeviceVncPasswordAPI';
import { sendVncPasswordAPI } from '../../apis/sendVncPasswordAPI';
import { vncPwdRegex } from '../../helpers/regex';
import { newPasswordShouldBeDifferent, confirmNewPasswordShouldBeTheSame, oldPasswordIncorrectText, invalidVncPwdChar } from '../../config/i18nText';

const ChangePasswordForm = props => {

    const { t } = useTranslation('device');
    const { dispatch } = useContext(SubmitBtnContext);
    const { deviceId, hasOldPwd, onClose, initialValues, valuesSchema, setIsPasswordSent, getDeviceDetail } = props;
    const [ isValidating, setIsValidating ] = useState(false);
    const [ onChangeErrors, setOnChangeErrors] = useState([]);
    const [ onChangeErrorText, setOnChangeErrorText] = useState(null);
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ oldPasswordIncorrect, setOldPasswordIncorrect ] = useState(false);
    const [ newPasswordSameAsOld, setNewPasswordSameAsOld ] = useState(false);
    const [ newPasswordsNotSame, setNewPasswordsNotSame ] = useState(false);

    const validatePwdText = e => {
        let pwd = e.target.value;
        let fieldName = e.target.name;
        let pwdInvalid = !vncPwdRegex.test(pwd);
        setOnChangeErrorText(invalidVncPwdChar(t));

        if(pwdInvalid){
            if(includes(onChangeErrors, fieldName)) return;
            if(!includes(onChangeErrors, fieldName)) setOnChangeErrors([...onChangeErrors,  fieldName]);
        } 

        if(!pwdInvalid && onChangeErrors)  setOnChangeErrors(onChangeErrors.filter(o => o !== fieldName));
    }

    const handleValidate = (values, handleSubmit) => {

        const isAllValuesEmpty = reject(values, '').filter(o=> o !== '').length < 1;
        if (isAllValuesEmpty || (onChangeErrors && onChangeErrors.length > 0) ) return; 
        
        setIsValidating(true);
        setIsSubmitting(false); // reset before validating
        setNewPasswordsNotSame(false); // reset before validating
        setNewPasswordSameAsOld(false); // reset before validating
        
        const isSomeValuesEmpty = some(values, isEmpty);
        if (!isSomeValuesEmpty) {
            handleSubmit();
            setIsSubmitting(true);
        }
    }

    const handleForgetPwd = async() => {
        await sendVncPasswordAPI(deviceId);
        setIsPasswordSent(true);
    }

    return(
        <Formik
            initialValues={initialValues}
            validationSchema={valuesSchema}
            onSubmit={ async(values) => {

                dispatch({type: 'showLoading'});
                const newPwd = values.newPassword;
                const confirmNewPwd = values.confirmNewPassword;
                const oldPwd = hasOldPwd && values.oldPassword;

                if (values && newPwd === confirmNewPwd && newPwd !== oldPwd) {
                    await updateSingleDeviceVncPasswordAPI(t, deviceId, oldPwd, newPwd, onClose, setOldPasswordIncorrect, getDeviceDetail, setIsSubmitting);
                } else {
                    setIsSubmitting(false);
                    setNewPasswordsNotSame(values.newPassword !== values.confirmNewPassword);
                    setNewPasswordSameAsOld(values.newPassword === values.oldPassword);
                } 

                dispatch({type: 'stopLoading'});
            }}
        >
        {({
            values, errors, handleChange, handleSubmit
        }) => (
            <Form onSubmit={handleSubmit} className="flex flex-direction-column">
                {hasOldPwd && 
                    <>
                        <PasswordTextField 
                            label="Old Password"
                            name="oldPassword"
                            onChange={e => {
                                validatePwdText(e)
                                handleChange(e)
                            }} 
                            value={values.oldPassword} 
                            error={errors.oldPassword || oldPasswordIncorrect}
                            errorText={oldPasswordIncorrect ? oldPasswordIncorrectText(t) : errors.oldPassword}
                            isValidating={isValidating}
                            onChangeError={onChangeErrors}
                            onChangeErrorText={onChangeErrorText}
                        />
                        <HasPermission permission="vnc_forgot_password">
                            <Button size="small" onClick={handleForgetPwd} className="mb_16" component="p" color="secondary">
                                {t('tasks.forgetPassword')}
                            </Button>
                        </HasPermission>
                    </>
                }
                <PasswordTextField 
                    label="New Password"
                    name="newPassword"
                     onChange={e => {
                        validatePwdText(e)
                        handleChange(e)
                    }} 
                    value={values.newPassword} 
                    error={errors.newPassword || newPasswordSameAsOld}
                    errorText={newPasswordSameAsOld ? newPasswordShouldBeDifferent(t) : errors.newPassword}
                    isValidating={isValidating}
                    onChangeError={onChangeErrors}
                    onChangeErrorText={onChangeErrorText}
                />
                <PasswordTextField 
                    label="Confirm New Password"
                    name="confirmNewPassword"
                     onChange={e => {
                        validatePwdText(e)
                        handleChange(e)
                    }} 
                    value={values.confirmNewPassword} 
                    error={errors.confirmNewPassword || newPasswordsNotSame}
                    errorText={newPasswordsNotSame ? confirmNewPasswordShouldBeTheSame(t) : errors.confirmNewPassword}
                    isValidating={isValidating}
                    onChangeError={onChangeErrors}
                    onChangeErrorText={onChangeErrorText}
                />
                <PasswordLengthNotice t={t} />
                <div className="submit-back-btn-wrap mt_16 dialog_submit_btn_wrap"> 
                    <StyledSubmitBtn 
                        startIcon={<SendRounded />} 
                        btnText={t('btn.confirmBtn')} 
                        type="button" 
                        onClick={()=> handleValidate(values, handleSubmit)}
                    />
                    <BackBtn 
                        disabled={isSubmitting}
                        onClick={onClose} 
                    />
                </div>
            </Form>)}
        </Formik>
    )
}

export default ChangePasswordForm;