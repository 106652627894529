import React, { useEffect, useState } from 'react';
import { Grid } from '@material-ui/core';
import useStyles from '../styles/dashboardStyle';
import NubisDevicesAmount from '../components/dashboard/NubisDevicesAmount';
import OperationSystemVersion from '../components/dashboard/OperationSystemVersion';
import DeviceHealthStatus from '../components/dashboard/DeviceHealthStatus';
import OfflineDays from '../components/dashboard/OfflineDays';
import OraVersionDistribution from '../components/dashboard/OraVersionDistribution';
// import TaskStatusGraph from '../components/dashboard/TaskStatusGraph'
import HardwardModels from '../components/dashboard/HardwareModels';
// import DeviceGroupsAmount from '../components/dashboard/DeviceGroupsAmount';
// import AlarmTriggerList from '../components/dashboard/AlarmTriggerList';
import VNCAddressForMultipleUsers from '../components/dashboard/VNCAddressForMultipleUsers';
import TaskFailedDatatable from '../components/dashboard/TaskFailedDatatable';
import { getAllTagsAPI } from '../apis/getAllTagsAPI';
import { BreadCrumbs } from '../components/device/BreadCrumbs';
import MapWrapper from '../components/map/MapWrapper';
import ErrorBoundary from '../helpers/errorBoundary';
import localforage from 'localforage';

const Dashboard = () => {
    const classes = useStyles()
    const cardTitleTypographyVariant = "h6"
    const cardSubtitleTypographyVariant = "subtitle2"
    const cardHighlightTypographyVariant = "h4"
    const [ defaultCompany, setDefaultCompany ] = useState('');
    // const deviceGroupAmountTypographyVariant = "h5"
    // const deviceGroupAmountCaptionTypographyVariant = "caption"

    useEffect(async () => {
        getAllTagsAPI()
        setDefaultCompany(await localforage.getItem('companyId').then(v => v))

    }, [])

    return (
        <ErrorBoundary>
            <div className={classes.root}>
                <BreadCrumbs pageName="dashboard" />
                <Grid container spacing={2} alignItems="stretch">
                    {/* NubisDevicesAmount */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <MapWrapper height={300} />
                    </Grid>
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                        <NubisDevicesAmount
                            companyId={defaultCompany}
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid>
                    {/* OperationSystemVersion */}
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                        <OperationSystemVersion
                            companyId={defaultCompany}
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid>
                    {/* OfflineDays */}
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                        <OfflineDays
                            companyId={defaultCompany}
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid>
                    {/* OraVersionDistribution */}
                    <Grid item xl={3} lg={4} md={6} sm={12} xs={12}>
                        <OraVersionDistribution
                            companyId={defaultCompany}
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid>
                    {/* TaskFailedDatatable */}
                    <Grid item xl={12} lg={8} md={12} sm={12} xs={12}>
                        <TaskFailedDatatable
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                        />
                    </Grid>
                    {/* TaskStatusGraph */}
                    {/* <Grid style={{minHeight:'400px'}} item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <TaskStatusGraph
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid> */}
                    {/* HardwardModels */}
                    <Grid item xl={4} lg={4} md={12} sm={12} xs={12}>
                        <HardwardModels
                            companyId={defaultCompany}
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid>
                    {/* DeviceGroupsAmount */}
                    {/* <Grid item xl={4} lg={3} md={6} sm={12} xs={12} className={classes.deviceGroupsAmountGrid}>
                        <DeviceGroupsAmount
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            deviceGroupAmountTypographyVariant={deviceGroupAmountTypographyVariant}
                            deviceGroupAmountCaptionTypographyVariant={deviceGroupAmountCaptionTypographyVariant}
                        />
                    </Grid> */}
                    {/* AlarmTriggerList */}
                    {/* <Grid item xl={6} lg={6} md={12} sm={12} xs={12}>
                        <AlarmTriggerList
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                        />
                    </Grid> */}
                    {/* DeviceHealthStatus */}
                    <Grid item xl={8} lg={8} md={12} sm={12} xs={12} className={classes.deviceHealthStatusGrid}>
                        <DeviceHealthStatus
                            companyId={defaultCompany}
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                            cardSubtitleTypographyVariant={cardSubtitleTypographyVariant}
                            cardHighlightTypographyVariant={cardHighlightTypographyVariant}
                        />
                    </Grid>
                    {/* VNCAddressForMultipleUsers */}
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <VNCAddressForMultipleUsers
                            cardTitleTypographyVariant={cardTitleTypographyVariant}
                        />
                    </Grid>
                </Grid>
            </div>
        </ErrorBoundary>
    )
}

export default Dashboard;
