import React, { useState, useEffect } from "react";
import { Typography, Dialog, DialogContent, Tooltip } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import StatusChip from "../commons/StatusChip";
import { utcFormatToLocalDateTimeWithSecond } from "../../helpers/datetimeHandlers";
import { statusSetHelper } from "../../helpers/statusSetHelper";
import {
  getUsbPortListApi,
  detectUsbPortApi,
  setUsbPortStatusApi,
} from "../../apis/usbConnectionsAPI";
import AntSwitch from "../deviceControl/AntSwitch";
import { disabledText } from "../../helpers/statusHelpers";
import RefreshBtn from "../commons/RefreshBtn";
import { checkHasPermission } from "../../helpers/checkHasPermission";

const UsbConnectionsDialog = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("device");
  const { onClose, open, deviceId, device_os_type } = props;
  const [clickAlready, setClickAlready] = useState(false);
  const [disableDetectBtn, setDisableDetectBtn] = useState(true);
  const [statusSet, setStatusSet] = useState({
    status: "empty",
    updated_at: "",
  });
  const [list, setList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [refreshSetUp, setRefreshSetUp] = useState(false);
  const [times, setTimes] = useState(0);
  const [setUpPort, setSetUpPort] = useState("");

  const getList = async (noRefresh) => {
    if (!noRefresh) {
      setList([]);
    }
    let data = await getUsbPortListApi(deviceId);
    if (data && data.status) {
      if (data.status === "completed" || data.status === "failed") {
        setDisableDetectBtn(false);
        setRefresh(false);
      } else {
        setDisableDetectBtn(true);
      }
      setStatusSet({
        status: data.status ? data.status : "empty",
        updated_at: data.updated_at ? data.updated_at : "",
      });
    } else {
      setDisableDetectBtn(false);
    }
    if (data && data.ports && data.ports.length > 0) {
      setList(data.ports);
    }
  };

  const statusHandler = (e) => {
    if (e.status === "normal") {
      if (e.is_enabled) {
        return "enabled";
      } else {
        return "disabled";
      }
    } else {
      return "abnormal";
    }
  };
  const getListSetUp = async () => {
    let data = await getUsbPortListApi(deviceId);
    let setUpTarget;
    if (data && data.ports && data.ports.length > 0) {
      setUpTarget = data.ports.filter((e) => e.id === setUpPort)[0];
      setList(data.ports);
    }
    if (
      setUpTarget?.task?.status === "completed" ||
      setUpTarget?.task?.status === "failed"
    ) {
      setRefreshSetUp(false);
    }
  };

  const setUpClick = async (e) => {
    setRefreshSetUp(false);
    let data = await setUsbPortStatusApi(
      e.device_id,
      e.port_id,
      !e.is_enabled,
      t,
      "dialog.successText"
    );
    if (data && data.message && data.message === "success") {
      setSetUpPort(e.id);
      setRefreshSetUp(true);
      await getListSetUp();
    }
  };
  const checkPermit = async () => {
    const isPermission = await checkHasPermission("alarm_view_alarm_policy");
    return isPermission;
  };

  useEffect(() => {
    let id = setInterval(async () => {
      setTimes(times + 1);
      await getListSetUp();
    }, 5000);
    if (times === 10) {
      setTimes(0);
      setRefreshSetUp(false);
      setSetUpPort("");
      clearInterval(id);
      return;
    }
    if (!refreshSetUp || !open || refresh) {
      setTimes(0);
      setRefreshSetUp(false);
      setSetUpPort("");
      clearInterval(id);
      return;
    }
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, refreshSetUp, open]);

  const setUpStatusArea = (row) => {
    if (
      row.task &&
      row.task.status &&
      row.task.executed_at &&
      row.status === "normal"
    ) {
      if (clickAlready && row.task.status === "completed") {
        return <TableCell align="left"></TableCell>;
      } else {
        return (
          <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
            <div>
              {disabledText(row?.task?.status, row?.task?.json?.is_enable)}
            </div>
            <div>
              {utcFormatToLocalDateTimeWithSecond(row?.task?.executed_at)}
            </div>
          </TableCell>
        );
      }
    } else {
      return <TableCell align="left"></TableCell>;
    }
  };

  const setUpArea = (row) => {
    if (row.status === "normal") {
      if (row.task) {
        if (row.task.status === "completed" || row.task.status === "failed") {
          return (
            <TableCell align="left">
              <AntSwitch
                checked={row.is_enabled}
                onChange={() => setUpClick(row)}
                color="primary"
              />
            </TableCell>
          );
        } else {
          return (
            <Tooltip
              title={`[ ${t(`usbConnections.${row.task.status}`)} ] - ${t(
                "usbConnections.sendingCommand"
              )}`}
              placement="left"
            >
              <TableCell align="left" className={classes.disabledSwitch}>
                <AntSwitch
                  checked={row.is_enabled}
                  disabled={true}
                  color="primary"
                />
              </TableCell>
            </Tooltip>
          );
        }
      } else {
        if (!disableDetectBtn) {
          return (
            <TableCell align="left">
              <AntSwitch
                checked={row.is_enabled}
                onChange={() => setUpClick(row)}
                color="primary"
              />
            </TableCell>
          );
        } else {
          return (
            <Tooltip title={t("usbConnections.detecting")} placement="right">
              <TableCell align="left" className={classes.disabledSwitch}>
                <AntSwitch
                  checked={row.is_enabled}
                  disabled={true}
                  color="primary"
                />
              </TableCell>
            </Tooltip>
          );
        }
      }
    } else {
      return (
        <Tooltip title={t("usbConnections.noSupportAction")} placement="right">
          <TableCell align="left" className={classes.disabledSwitch}>
            <AntSwitch
              checked={row.is_enabled}
              disabled={true}
              color="primary"
            />
          </TableCell>
        </Tooltip>
      );
    }
  };

  useEffect(() => {
    if (open) {
      setClickAlready(false);
      setSetUpPort("");
      getList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      className={`${classes.usbConnectionsDialog}`}
    >
      <DialogContent>
        <div className={classes.tableHeaderDialog}>
          <Typography variant="h5">{t("usbConnections.title")}</Typography>
          <div className={classes.usbDetectTextAndBtn}>
            <RefreshBtn
              getList={getList}
              detectApi={detectUsbPortApi}
              statusSet={statusSet}
              open={open}
              refresh={refresh}
              setRefresh={setRefresh}
              disableDetectBtn={disableDetectBtn}
              setDisableDetectBtn={setDisableDetectBtn}
              deviceId={deviceId}
              setClickAlready={setClickAlready}
              clickAlready={clickAlready}
            />
          </div>
        </div>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                {t("usbConnections.usbPort")}
              </TableCell>
              <TableCell align="left">{t("usbConnections.deviceId")}</TableCell>
              {device_os_type === "windows" && (
                <>
                  <TableCell align="left">
                    {t("usbConnections.status")}
                  </TableCell>
                  {checkPermit("single_usb_status") ? (
                    <>
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        {t("usbConnections.setUp")}
                      </TableCell>
                      <TableCell align="left" style={{ whiteSpace: "nowrap" }}>
                        {t("usbConnections.setUpStatus")}
                      </TableCell>
                    </>
                  ) : (
                    ""
                  )}
                </>
              )}
            </TableRow>
          </TableHead>
          {statusSetHelper(statusSet.status, "notEqualAnd") &&
            list &&
            list.length > 0 && (
              <TableBody>
                {list.map((row) => (
                  <TableRow key={row.id}>
                    <TableCell align="left">
                      {row.usb_port ? row.usb_port : "-"}
                    </TableCell>
                    <TableCell align="left">
                      {row.usb_device ? row.usb_device : "-"}
                    </TableCell>
                    {device_os_type === "windows" && (
                      <>
                        <TableCell align="left">
                          {<StatusChip statusLabel={statusHandler(row)} />}
                        </TableCell>
                        {checkPermit("single_usb_status") ? (
                          <>
                            {setUpArea(row)}
                            {setUpStatusArea(row)}
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            )}
        </Table>
        {statusSetHelper(statusSet.status, "equalOr") ||
        (list && !list.length > 0) ? (
          <div className={classes.nodata}>{t("usbConnections.noData")}</div>
        ) : (
          ""
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UsbConnectionsDialog;
