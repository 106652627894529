import { useContext } from "react";
import { LanguageContext } from "./LanguageContext";

export const useTranslation = (f) => {
    const languageContext = useContext(LanguageContext);

    const t = (text) => {
        // To prevent error about not found translate file so give it conditional expressions
        return languageContext?.dictionary[f]
            ? text
                .split(".")
                // To prevent error about not found translate text so give it conditional expressions
                .reduce((o, i) => o && o[i], languageContext.dictionary[f]) || text
            : text;
    };

    return { t };
};
