import React, { useEffect, useRef, useState } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import { useParams } from "react-router-dom";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { Line } from "react-chartjs-2";
import { dataSet, options } from "../../helpers/chartDataSet";
import { getRealTimeData } from "../../helpers/getRealTimeData";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { dateInNormal } from "../../helpers/datetimeHandlers";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DeviceDetailSingleGraph = (props) => {
  const mounted = useRef();
  const { t } = useTranslation("device");
  const classes = useStyles();
  const { device_id } = useParams();
  const [valueArr, setValueArr] = useStateOnMounted([]);
  const [labelArr, setLabelArr] = useStateOnMounted([]);
  const [date, setDate] = useStateOnMounted("");
  const [loading, setLoading] = useState(false);
  const [unit, setUnit] = useStateOnMounted();
  const { graphType, mins } = props;

  const getData = async () => {
    setLoading(true);
    let data = await getRealTimeData(device_id, graphType, 7, mins);
    if (data) {
      setValueArr(data.valueArr);
      setLabelArr(data.labelArr);
      setDate(dateInNormal(new Date()));
      const dataUnit = data.unit[0];
      if (dataUnit === "&#8451;") {
        const unicode = dataUnit.match(/\d+/);
        setUnit(String.fromCharCode(unicode));
      } else {
        setUnit(dataUnit);
      }
      setLoading(false);
    }
  };

  const data = {
    labels: labelArr,
    datasets: [dataSet(valueArr)],
  };
  
  useEffect(() => {
    getData();
  }, [mins]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={`${classes.marginBottomReset} ${classes.graphWrap}`}>
          <Typography
            variant="caption"
            align="right"
            className={classes.graphDate}
          >
            {t("deviceInformation.date")}
            {date}
          </Typography>
          <Typography
            variant="caption"
            align="right"
            className={classes.graphUnit}
          >
            {unit}
          </Typography>
          {loading ? <SmallerLoadingIndicator/> : <Line data={data} options={options} />}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DeviceDetailSingleGraph;
