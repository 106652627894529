import React, { useState, useEffect, useCallback } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import useStyles from "../styles/groupsStyle";
import { useTranslation } from "../langs/useTranslation";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import LoadingIndicator from "../components/commons/LoadingIndicator";
import HOCforFullWidthTable from "../hoc/FullWidthTableWrapper";
import textNoty from "../helpers/textNoty";
import {
    getGroupListApi,
    deleteDeviceFromGroupApi,
    assignToGroupsApi,
} from "../apis/groupsApi";
import EditIcon from "@material-ui/icons/Edit";
import * as localforage from "localforage";
import * as $ from "jquery";
import {
    table,
    reloadTableWithGroupId,
} from "../helpers/groupDeviceListDatatable";
import AutorenewRounded from "@material-ui/icons/AutorenewRounded";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleDatatableSelectWithRadio } from "../helpers/handleDatatableSelectWithRadio";
import DeleteDialog from "../components/groups/DeleteDialog";
import AddDialog from "../components/groups/AddDialog";
import HasPermission from "../components/commons/HasPermission";
import GroupUpdateDialog from "../components/groups/GroupUpdateDialog";
import DescriptionIcon from "@material-ui/icons/Description";
import ImportDevicesDialog from "../components/groups/ImportDevicesDialog";
import { deviceModifyAPI } from "../apis/deviceModifyAPI"
import WarnDialog from "../components/groups/WarnDialog";

const GroupsDevices = () => {
    const { t } = useTranslation("groups");
    const [loadingFirst, setLoadingFirst] = useState(true);
    const [loading, setLoading] = useState(true);
    const [noGroup, setNoGroup] = useState(false);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [OpenAddDialog, setOpenAddDialog] = useState(false);
    const [OpenImportDialog, setOpenImportDialog] = useState(false);
    const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [OpenGroupUpdateDialog, setOpenGroupUpdateDialog] = useState(false);
    const [groupList, setGroupList] = useState([]);
    const [createGroupName, setCreateGroupName] = useState("");
    const [selectGroup, setSelectGroup] = useState("");
    const classes = useStyles();
    const [deleteDeviceIds, setDeleteDeviceIds] = useState([]);
    const [taskSchedule, setTaskSchedule] = useState([]);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);

    let groupTable = $("#device-in-group-table").DataTable();
    groupTable.on("click", $("#device-in-group-table input"), async function () {
        handleDatatableSelectWithRadio();
    });
    const getGroup = async () => {
        let data = await getGroupListApi();
        if (data && data.groups && data.groups.length > 0) {
            setGroupList(data.groups);
            setSelectGroup(data.groups[0]);
            table(t, data.groups[0].id);
            setLoading(false);
            setLoadingFirst(false);
            setNoGroup(false);
        }
        if (data && data.groups && data.groups.length === 0) {
            setGroupList([]);
            setSelectGroup({});
            setNoGroup(true);
            setLoading(false);
            setLoadingFirst(false);
        }
    };

    const groupHandler = async (e) => {
        setSelectGroup(e);
        reloadTableWithGroupId(t, e.id);
    };

    const handleReloadDataTable = () => {
        reloadTableWithGroupId(t, selectGroup && selectGroup.id);
    };

    const fisrtGetApi = useCallback(async () => {
        getGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteHandler = async () => {
        let deviceIdsArr = await localforage.getItem("dtDeviceIds");
        if (!deviceIdsArr || (deviceIdsArr && deviceIdsArr.length === 0)) {
            textNoty(t("error.noSelect"));
            return;
        }
        setDeleteDeviceIds(deviceIdsArr);
        let res = await deviceModifyAPI('group', undefined, [selectGroup.id])
        if (res) {
            setTaskSchedule(res)
            setOpenDeleteDialog(true);
        }
    };
    const confirmDelete = async () => {
        setLoadingDialog(true);
        await deleteDeviceFromGroupApi(
            deleteDeviceIds,
            selectGroup.id,
            t,
            t("dialog.successDelete")
        ).then((res) => {
            if (res && res.status && res.status === "success") {
                handleReloadDataTable();
                setOpenDeleteDialog(false);
            }
            setLoadingDialog(false);
        });
    };
    const showWarnHandler = async () => {
        let res = await deviceModifyAPI('group', [selectGroup.id], undefined)
        if (res) {
            setTaskSchedule(res)
            setOpenWarnDialog(true);
        }
    }
    const confirmAdd = async () => {
        let deviceIdsAddArr = await localforage.getItem("addDtDeviceIds");
        if (!deviceIdsAddArr || (deviceIdsAddArr && deviceIdsAddArr.length === 0)) {
            textNoty(t("error.noSelect"));
            return;
        }
        setLoadingDialog(true);
        await assignToGroupsApi(
            deviceIdsAddArr,
            selectGroup.id,
            t,
            t("dialog.successAdd")
        ).then((res) => {
            if (res && res.status && res.status === "success") {
                handleReloadDataTable();
                setOpenAddDialog(false);
                setOpenWarnDialog(false);
            }
            setLoadingDialog(false);
        });
    };

    const addHandler = () => {
        setOpenAddDialog(true);
    };

    const closeUpdateDialog = () => {
        setCreateGroupName("");
        setOpenGroupUpdateDialog(false);
    };

    const importHandler = async () => {
        let res = await deviceModifyAPI('group', [selectGroup.id], undefined)
        if (res) {
            setTaskSchedule(res)
            setOpenImportDialog(true);
        }
    };

    useEffect(() => {
        fisrtGetApi();
    }, [fisrtGetApi]);

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="groups" />
            <div className={classes.titleWithSelect}>
                <Typography variant="h4">{t("common.groups")}</Typography>
            </div>
            <div className="mt-4">
                <Grid container spacing={4}>
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        md={3}
                        lg={2}
                        xl={2}
                        className={classes.groupBtnsWrapper}
                    >
                        <Paper className={classes.groupList}>
                            {loading ? (
                                <div className={classes.loadingGroup}>
                                    <LoadingIndicator />
                                </div>
                            ) : (
                                <div>
                                    <div className={classes.titleWithEditIcon}>
                                        <Typography variant="body1">
                                            {t("common.groupBtnListTitle")}
                                        </Typography>
                                        <HasPermission permission="groups_edit">
                                            <EditIcon
                                                className={classes.editIcon}
                                                onClick={() => setOpenGroupUpdateDialog(true)}
                                            />
                                        </HasPermission>
                                    </div>
                                    <div className={classes.groupSelect}>
                                        {groupList &&
                                            groupList.length > 0 &&
                                            groupList.map((e) => (
                                                <Typography
                                                    onClick={() => groupHandler(e)}
                                                    variant="body1"
                                                    key={e.id}
                                                    className={classes.groupItem}
                                                    style={{
                                                        color:
                                                            selectGroup && e.id === selectGroup.id
                                                                ? "#1DBBD4"
                                                                : "#6d6d6d",
                                                    }}
                                                >
                                                    {e.name}
                                                </Typography>
                                            ))}
                                        {groupList && groupList.length === 0 && (
                                            <Typography variant="body1" color="secondary">
                                                {t("common.noData")}
                                            </Typography>
                                        )}
                                    </div>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={8} md={9} lg={10} xl={10}>
                        <Paper className={classes.deviceInGroup}>
                            {noGroup && (
                                <Typography variant="body1" color="secondary">
                                    {t("common.noData")}
                                </Typography>
                            )}
                            <div
                                style={{
                                    visibility: noGroup || loadingFirst ? "hidden" : "visible",
                                }}
                            >
                                <div className={classes.tableTitle}>
                                    <AutorenewRounded
                                        onClick={() => !noGroup && handleReloadDataTable()}
                                        size="small"
                                        className={classes.icon_btn}
                                    />
                                    <HasPermission permission="assign_devices_to_groups">
                                        <div
                                            className={classes.iconWText}
                                            onClick={() => !noGroup && addHandler()}
                                        >
                                            <AddIcon size="small" className={classes.icon_btn} />
                                            <Typography variant="body1" className={classes.iconText}>
                                                {t("common.addDevice")}
                                            </Typography>
                                        </div>
                                    </HasPermission>
                                    <HasPermission permission="remove_devices_from_groups">
                                        <div
                                            className={classes.iconWText}
                                            onClick={() => !noGroup && deleteHandler()}
                                        >
                                            <DeleteIcon size="small" className={classes.icon_btn} />
                                            <Typography variant="body1" className={classes.iconText}>
                                                {t("common.deleteDevice")}
                                            </Typography>
                                        </div>
                                    </HasPermission>
                                    <HasPermission permission="assign_devices_to_groups">
                                        <div
                                            className={classes.iconWText}
                                            onClick={() => !noGroup && importHandler()}
                                        >
                                            <DescriptionIcon
                                                size="small"
                                                className={classes.icon_btn}
                                            />
                                            <Typography variant="body1" className={classes.iconText}>
                                                {t("common.importDevice")}
                                            </Typography>
                                        </div>
                                    </HasPermission>
                                </div>
                                <HOCforFullWidthTable>
                                    <table
                                        id="device-in-group-table"
                                        className="mdl-data-table dataTable no-footer dtr-inline"
                                    >
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>{t("table.serialNumber")}</th>
                                                <th>{t("table.OS")}</th>
                                                <th>{t("table.companies")}</th>
                                                <th>{t("table.groups")}</th>
                                                <th>{t("table.tags")}</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </HOCforFullWidthTable>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
            <ImportDevicesDialog
                groupId={selectGroup?.id || ""}
                open={OpenImportDialog}
                setOpen={setOpenImportDialog}
                handleReloadDataTable={handleReloadDataTable}
                taskSchedule={taskSchedule}
                t={t}
            />
            <DeleteDialog
                open={OpenDeleteDialog}
                loading={loadingDialog}
                taskSchedule={taskSchedule}
                onConfirm={confirmDelete}
                onClose={() => setOpenDeleteDialog(false)}
                content={t("dialog.deleteDeviceContent")}
                header={t("dialog.deleteDevice")}
            />
            <GroupUpdateDialog
                createGroupName={createGroupName}
                setCreateGroupName={setCreateGroupName}
                open={OpenGroupUpdateDialog}
                list={groupList}
                setList={setGroupList}
                getGroup={getGroup}
                onClose={closeUpdateDialog}
            />
            <AddDialog
                open={OpenAddDialog}
                onConfirm={showWarnHandler}
                taskSchedule={taskSchedule}
                selectGroup={selectGroup}
                onClose={() => setOpenAddDialog(false)}
            />
            <WarnDialog
                open={openWarnDialog}
                loading={loadingDialog}
                taskSchedule={taskSchedule}
                onConfirm={confirmAdd}
                onClose={() => setOpenWarnDialog(false)}
                content={t('dialog.confirmAdd')}
                header={t("dialog.add")}
                taskType="add"
            />
        </div>
    );
};

export default GroupsDevices;
