import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";

export const getGroupListApi = async () => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getDeviceListApi = async (group_id, search) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/devices`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                group_id,
                search,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const createGroupApi = async (name, t, successfulText) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                name,
                company_id,
            },
        });
        successHandling(t, successfulText);
        return res;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const importDevicesAPI = async (
    group_id,
    spreadsheet,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();
    formData.append("group_id", group_id);
    formData.append("spreadsheet", spreadsheet);

    try {
        let res = await axios({
            url: `${url}/api/v2/groups/upload/devices`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return res;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const updateGroupApi = async (id, name, t, successfulText) => {
    const company_id = await localforage.getItem("companyId");
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups/update`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                id,
                name,
                company_id,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const deleteGroupApi = async (id, t, successfulText) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups/delete`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                id,
                company_id,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const deleteDeviceFromGroupApi = async (
    device_ids,
    group_id,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/remove-from-group`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                group_id,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getUnbindDeviceListApi = async (nubis_group_id, search) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/devices`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                nubis_group_id,
                search,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const assignToGroupsApi = async (
    device_ids,
    group_id,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/assign-to-group`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_ids,
                group_id,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
