import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Divider,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import { importDevicesAPI } from "../../apis/groupsApi";
import ExcelFileUpload from "../commons/ExcelFileUpload";
import { GetAppOutlined } from "@material-ui/icons";
import DescriptionIcon from "@material-ui/icons/Description";
import TaskSchdule from "../groups/TaskSchdule"

const ImportDevicesDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("groups");
    const [loading, setLoading] = useState(false);
    const [file, setFile] = useState(null);
    const [importResult, setImportResult] = useState(null);
    const { open, setOpen, groupId, handleReloadDataTable, taskSchedule } = props;

    const onClose = () => {
        setOpen(false);
    };

    const onConfirm = async () => {
        if (!file) return;
        setLoading(true);
        let data = await importDevicesAPI(
            groupId,
            file,
            t,
            t("common.importSuccessfully")
        );
        if (data?.data) {
            setImportResult(data.data);
            setFile(null);
            setLoading(false);
            handleReloadDataTable();
        } else {
            setLoading(false);
        }
    };
    const totalNum = () => {
        if (
            importResult &&
            (importResult.success_assigned_to_group_devices ||
                importResult.success_assigned_to_group_devices === 0) &&
            (importResult.failed_assigned_to_group_devices ||
                importResult.failed_assigned_to_group_devices === 0) &&
            (importResult.existed_assigned_to_group_devices ||
                importResult.existed_assigned_to_group_devices === 0)
        ) {
            return (
                importResult.success_assigned_to_group_devices +
                importResult.failed_assigned_to_group_devices
            );
        } else {
            return "Data Error";
        }
    };
    useEffect(() => {
        if (open) {
            setImportResult(null);
            setLoading(false);
            setFile(null);
        }
    }, [open]);

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            onClose={onClose}
            open={open}
        >
            {!importResult && (
                <DialogTitle id="importDeviceDialog">
                    {t("common.importDevice")}
                </DialogTitle>
            )}
            <DialogContent>
                {importResult ? (
                    <div className={classes.importResult}>
                        <div className="resultIcon">
                            <DescriptionIcon />
                        </div>
                        <div className="resultTitle">{t("common.importResult")}</div>
                        <div className="resultItems">
                            <div className="resultItem">
                                <div>{t("common.successAssignedNum")}</div>
                                <div className="bold">
                                    {(importResult?.success_assigned_to_group_devices ||
                                        importResult?.success_assigned_to_group_devices === 0) &&
                                        (importResult?.existed_assigned_to_group_devices ||
                                            importResult?.existed_assigned_to_group_devices === 0)
                                        ? importResult?.success_assigned_to_group_devices -
                                        importResult?.existed_assigned_to_group_devices
                                        : 0}
                                </div>
                            </div>
                            <div className="resultItem">
                                <div>{t("common.failedAssignedNum")}</div>
                                <div
                                    className={
                                        importResult?.failed_assigned_to_group_devices > 0
                                            ? "errorColor bold"
                                            : "bold"
                                    }
                                >
                                    {importResult?.failed_assigned_to_group_devices || 0}
                                </div>
                            </div>
                            <div className="resultItem">
                                <div>{t("common.existedAssignedNum")}</div>
                                <div className="bold">
                                    {importResult?.existed_assigned_to_group_devices || 0}
                                </div>
                            </div>
                            <Divider />
                            <div className="resultItem">
                                <div>{t("common.totalAssignedNum")}</div>
                                <div className="bold">{totalNum()}</div>
                            </div>
                        </div>
                        {importResult?.failed_assigned_to_group_devices_log_download_link && (
                            <Button
                                href={
                                    importResult?.failed_assigned_to_group_devices_log_download_link
                                }
                                variant="text"
                                color="secondary"
                                style={{ marginBottom: 0, padding: "0 10px" }}
                                startIcon={<GetAppOutlined color="primary" />}
                            >
                                {t("common.downloadImportResult")}
                            </Button>
                        )}
                    </div>
                ) : (
                    <ExcelFileUpload
                        excelFile={file}
                        disabled={loading}
                        setExcelFile={setFile}
                    />
                )}
                <TaskSchdule open={open} taskSchedule={taskSchedule} type='add' />
            </DialogContent>

            <DialogActions className={classes.noMarginBtn}>
                {!importResult && (
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading || !file}
                        btnText={t("dialog.confirmBtn")}
                        onClick={onConfirm}
                    />
                )}
                <BackBtn
                    onClick={onClose}
                    btnText={importResult ? t("dialog.close") : t("dialog.cancel")}
                />
            </DialogActions>
        </Dialog>
    );
};

export default ImportDevicesDialog;
