import axios from 'axios';
import * as localforage from 'localforage';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const createAlarmPolicyAPI = async (rule, note, emails, t, successfulText) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');

    try {
        await axios({
            url: `${url}/api/v2/alarm/policy/create`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                company_id: companyId,
                alarm_policy_option_id: rule.id,
                operator: rule.operator,
                value: rule.value,
                note: note,
                emails: emails
            }
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
