import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Select,
  MenuItem,
  FormControl,
  TextField,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import FileUpload from "../device/FileUpload";
import LinearWithValueLabel from "../commons/LinearProgress";
import { createAppApi } from "../../apis/appListApi";
import { s3upload } from "../../helpers/s3upload";
import BackArrow from "../../components/commons/BackArrow";

const AddDialog = (props) => {
  const classes = useStyles();
  const [name, setName] = useState("");
  const [appVersion, setAppVersion] = useState("");
  const [description, setDescription] = useState("");
  const [installScript, setInstallScript] = useState("");
  const [uninstallScript, setUninstallScript] = useState("");
  const [osVersion, setOsVersion] = useState("windows");
  const [file, setFile] = useState({});
  const [chooseOs, setChooseOs] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [disabledUpload, setDisabledUpload] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);
  const [returnValue, setReturnValue] = useState({});
  const [errorMessage, setErrorMessage] = useState();

  const { open, setOpenAddDialog, loading, setLoading, reloadTable } = props;
  const { t } = useTranslation("appUpdateService");

  const clear = () => {
    setIsUploading(false);
    setDisabledUpload(false);
    setPercentCompleted(0);
    setLoading(false);
    setName("");
    setFile({});
    setInstallScript("");
    setUninstallScript("");
    setAppVersion("");
    setDescription("");
    setErrorMessage();
    setReturnValue({});
    setOsVersion("windows");
    setChooseOs(false);
  };
  const onClose = () => {
    setOpenAddDialog(false);
    clear();
  };
  const backHandler = () => {
    clear();
  };
  
  const handleUploadFile = async () => {
    setIsUploading(true);
    if (file) {
      setDisabledUpload(true);
      let successfulText = `${t("noty.successfullyUploadFile")}`;
      await s3upload(
        document.getElementById("fileUpload"),
        "",
        t,
        successfulText,
        percentCompleted,
        setPercentCompleted,
        undefined,
        undefined,
        undefined,
        true,
        setReturnValue,
        "app-update-services",
        false,
        osVersion === "android" ? true : false
      );
    } else {
      setIsUploading(false);
    }
  };
  const confirmChooseOs = () => {
    setChooseOs(true);
  };

  const confirmAdd = async () => {
    if (!returnValue.filePath) {
      setErrorMessage(t("error.noFile"));
      return;
    }
    if (!name) {
      setErrorMessage(t("error.noName"));
      return;
    }
    if (!appVersion) {
      setErrorMessage(t("error.noAppVersion"));
      return;
    }
    if (osVersion !== "android") {
      if (!installScript) {
        setErrorMessage(t("error.noInstallScript"));
        return;
      }
      if (!uninstallScript) {
        setErrorMessage(t("error.noUnInstallScript"));
        return;
      }
    }
    setErrorMessage();
    const successText = "noty.successCreate";
    setLoading(true);
    let data = await createAppApi(
      osVersion,
      name,
      appVersion,
      returnValue.filePath,
      returnValue.md5,
      description,
      installScript,
      uninstallScript,
      t,
      successText,
      setLoading
    );
    if (data) {
      if (data.status === "success") {
        onClose();
        reloadTable();
      } else {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      maxWidth="lg"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle>
        <div className={classes.boldHeader}>{t("dialog.addHeader")}</div>
      </DialogTitle>
      <>
        <DialogContent>
          {!chooseOs ? (
            <div className={classes.textfieldArea}>
              <Typography variant="body1" className="textLabel required">
                {t("common.osVersion")}
              </Typography>
              <div className="selectArea">
                <Select
                  className="lableSelect"
                  onChange={(e) => setOsVersion(e.target.value)}
                  value={osVersion}
                  MenuProps={{ disableScrollLock: true }}
                >
                  <MenuItem key="windows" value="windows">
                    Windows
                  </MenuItem>
                  <MenuItem key="android" value="android">
                    Android
                  </MenuItem>
                </Select>
              </div>
            </div>
          ) : (
            <>
              <BackArrow click={backHandler} />
              <div className={classes.uploadArea}>
                <FileUpload
                  uploadFileType={"file"}
                  setFile={setFile}
                  ifNubisDrive={true}
                  changeHanlder={handleUploadFile}
                  tableDisabled={disabledUpload}
                  availableStorage="unlimited"
                />
                {isUploading && (
                  <LinearWithValueLabel percentCompleted={percentCompleted} />
                )}
              </div>
              <div className={classes.errorText}>{errorMessage}</div>
              <FormControl fullWidth={true}>
                <div className={classes.textfieldArea}>
                  <Typography variant="body1" className="textLabel required">
                    {t("common.appName")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("dialog.appNamePlaceholder")}
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="lableInput"
                  />
                </div>
                <div className={classes.textfieldArea}>
                  <Typography variant="body1" className="textLabel required">
                    {t("common.appVersion")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    placeholder={t("dialog.appVersionPlaceholder")}
                    value={appVersion}
                    onChange={(e) => setAppVersion(e.target.value)}
                    className="lableInput"
                  />
                </div>
                <div className={classes.textfieldArea}>
                  <Typography variant="body1" className="textLabel">
                    {t("common.description")}
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    placeholder={t("dialog.descriptionPlaceholder")}
                    onChange={(e) => setDescription(e.target.value)}
                    className="lableInputMultiline"
                  />
                </div>
                {osVersion !== "android" && (
                  <>
                    <div className={classes.textfieldArea}>
                      <Typography
                        variant="body1"
                        className="textLabel required"
                      >
                        {t("common.installScript")}
                      </Typography>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={4}
                        value={installScript}
                        placeholder={t("dialog.installScriptPlaceholder")}
                        onChange={(e) => setInstallScript(e.target.value)}
                        className="lableInputMultiline"
                      />
                    </div>
                    <div className={classes.textfieldArea}>
                      <Typography
                        variant="body1"
                        className="textLabel required"
                      >
                        {t("common.uninstallScript")}
                      </Typography>
                      <TextField
                        variant="outlined"
                        multiline
                        rows={4}
                        value={uninstallScript}
                        placeholder={t("dialog.uninstallScriptPlaceholder")}
                        onChange={(e) => setUninstallScript(e.target.value)}
                        className="lableInputMultiline"
                      />
                    </div>
                  </>
                )}
              </FormControl>
            </>
          )}
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            btnColor="primary"
            disabled={loading || (isUploading && percentCompleted !== 100)}
            type="button"
            btnText={chooseOs ? t("dialog.confirmBtn") : t("dialog.next")}
            onClick={chooseOs ? () => confirmAdd() : () => confirmChooseOs()}
          />
          <BackBtn onClick={() => onClose()} btnText={t("dialog.cancel")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default AddDialog;
