import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateFolderNameAPI = async (storedValue, value, id, parentId, setStoredValue, setValue, t, successfulText) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/drive/file/rename-folder`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                id: id,
                name: value,
                parent_id: parentId
            }
        });

        successHandling(t, successfulText);
        setStoredValue(value);

    } catch (error) {
        setValue(storedValue);
        errorHandling(error, false);
    }
}
