import React, { useState, useEffect } from "react";
import { useTranslation } from "../../langs/useTranslation";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import textNoty from "../../helpers/textNoty";
import HasPermission from "./HasPermission";
import {
    Checkbox,
    TextField,
    ClickAwayListener,
    IconButton,
    Grid,
} from "@material-ui/core";
import useStyles from "../../styles/commonStyle";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { tagBindDeviceAPI } from "../../apis/tagBindDeviceAPI";
import DeviceTags from "./DeviceTags";
import { deviceModifyAPI } from "../../apis/deviceModifyAPI"
import WarnDialog from "../groups/WarnDialog";

const EditableIconForDatatable = (props) => {
    const classes = useStyles();
    const { rowData, disabled, allTags } = props;
    const { t } = useTranslation("common");
    const [isFieldDisabled, setIsFieldDisabled] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const [rowTags, setRowTags] = useState([]);
    const [rowId, setRowId] = useState("");
    const [selectTags, setSelectTags] = useState([]);
    const [loading, setLoading] = useState(false);
    const [taskSchedule, setTaskSchedule] = useState([]);
    const [openWarnDialog, setOpenWarnDialog] = useState(false);
    const [defaultTab, setDefaultTab] = useState("add");
    // eslint-disable-next-line no-unused-vars
    const open = Boolean(anchorEl);

    const handleEdit = () => {
        setIsFieldDisabled(false);
    };

    const onConfirm = async () => {
        setLoading(true);
        let res = await tagBindDeviceAPI(selectTags, rowId);
        if (res?.message === "success") {
            textNoty(t("task.successfullyUpdateTag"), "success");
            setRowTags(selectTags);
        } else {
            setSelectTags(rowTags);
        }
        setLoading(false);
        setOpenWarnDialog(false)
        setIsFieldDisabled(true);
    };

    const handleClickAway = (e) => {
        setAnchorEl(false);
    };

    const onCancel = () => {
        setSelectTags(rowTags);
        setIsFieldDisabled(true);
    };

    const showWarnHandler = async () => {
        let removeTags = []
        let addTags = []
        let map = {}
        setTaskSchedule([])
        //  rowTags [1,2,4] selectTags [3,4]  map { 1:0, 2:0, 3:1, 4:-2 }
        //                                          0被消失  1新增  -2沒變
        if (selectTags !== rowTags) {
            rowTags.forEach(e => {
                map[e.id] = 0
            })
            selectTags.forEach(e => {
                if (e.id in map) {
                    map[e.id] = -2
                } else {
                    map[e.id] = 1
                }
            })
            Object.entries(map).forEach(([key, value]) => {
                if (value === 0) {
                    removeTags.push(key)
                }
                if (value === 1) {
                    addTags.push(key)
                }
            });
        }
        if (!removeTags.length && !addTags.length) {
            setOpenWarnDialog(true);
            return
        }
        // setDefaultTab
        if (!addTags.length && removeTags.length) {
            setDefaultTab('remove')
        }
        if (addTags.length && !removeTags.length) {
            setDefaultTab('add')
        }
        let res = await deviceModifyAPI(
            'tag',
            addTags.length > 0 ? addTags : undefined,
            removeTags.length > 0 ? removeTags : undefined
        )
        if (res) {
            setTaskSchedule(res)
            setOpenWarnDialog(true);
        }
    }
    useEffect(() => {
        setSelectTags(rowData?.tags);
        setRowTags(rowData?.tags);
        setRowId(rowData?.id);
    }, [rowData]);

    return (<>
        <Grid container direction="row" alignItems="center">
            <Grid item xs={9}>
                {!disabled && isFieldDisabled ? (
                    <DeviceTags tags={rowTags} />
                ) : (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Autocomplete
                            id="editTag"
                            multiple
                            className={classes.autoSelect}
                            disableCloseOnSelect
                            limitTags={1}
                            renderTags={(values) => (
                                <div className="tagsArea">
                                    <span className="tagInner">
                                        {values.map((value) => value.name)[0]}
                                    </span>
                                    <span className="tagPlus">
                                        {values?.length > 1 && `+ ${values.length - 1}`}
                                    </span>
                                </div>
                            )}
                            disabled={loading}
                            onChange={(event, newValue, reason) => {
                                if (reason === "select-option") {
                                    setSelectTags(newValue);
                                } else if (reason === "remove-option") {
                                    setSelectTags(newValue);
                                } else if (reason === "clear") {
                                    setSelectTags([]);
                                }
                            }}
                            onOpen={() => {
                                setAnchorEl(true);
                            }}
                            onClose={(e, reason) => {
                                if (reason === "escape") {
                                    setAnchorEl(false);
                                }
                            }}
                            value={selectTags}
                            options={allTags}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(option, { selected }) => (
                                <React.Fragment>
                                    <Checkbox
                                        icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                        checkedIcon={<CheckBoxIcon fontSize="small" />}
                                        style={{ marginRight: 8 }}
                                        checked={selected}
                                    />
                                    {option.name}
                                </React.Fragment>
                            )}
                            getOptionLabel={(option) => option.name || ""}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    placeholder={"Select"}
                                />
                            )}
                        />
                    </ClickAwayListener>
                )}
            </Grid>
            <HasPermission permission="device_list_tag_edit">
                {!disabled &&
                    (isFieldDisabled ? (
                        <Grid item xs={3}>
                            <IconButton aria-label="edit" size="small" onClick={handleEdit}>
                                <EditRoundedIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    ) : (
                        <Grid item xs={3}>
                            <IconButton aria-label="edit" size="small" onClick={() => showWarnHandler()}>
                                <CheckRoundedIcon fontSize="small" />
                            </IconButton>
                            <IconButton aria-label="cancel" size="small" onClick={onCancel}>
                                <CloseRoundedIcon fontSize="small" />
                            </IconButton>
                        </Grid>
                    ))}
            </HasPermission>
        </Grid>
        <WarnDialog
            open={openWarnDialog}
            loading={loading}
            type="both"
            defaultTab={defaultTab}
            taskSchedule={taskSchedule}
            onConfirm={onConfirm}
            onClose={() => setOpenWarnDialog(false)}
            content={t('common.confirmToChangeTag')}
            header={t("common.changeTag")}
        />
    </>
    );
};

export default EditableIconForDatatable;
