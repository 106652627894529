import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const detectIOStatusAPI = async (deviceId, deviceIds, spreadsheet, datetime, t, successfulText) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/command/io-status-detect-send`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
            data: {
                device_id: deviceId,
                device_ids: deviceIds,
                spreadsheet: spreadsheet,
                execute_type: 'io_status_detect',
                execute_datetime: datetime,
            }
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, true);
    }
}
