import React, { forwardRef, useEffect, useState, useRef } from 'react';
import { Select, MenuItem, FormControl, Grid, TextField, Typography } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { NumberRequiredText, RequiredText } from '../commons/ValidationText';
import { degreeRegex, operatorDeterminator } from '../../helpers/regex';

const SelectAlarmPolicy = forwardRef((props, ref) => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { storedRuleValueToLocalforageName, policyToBeUpdated, ruleProvided, triggerValueProvided } = props;
    const [ alarmPolicies, setAlarmPolicies ] = useState([]);
    const [ unit, setUnit ] = useState('%');
    const [ rule, setRule ] = useState();
    const [ relation, setRelation ] = useState('<');
    const [ triggerValue, setTriggerValue ] = useState('');
    const [ hasRule, setHasRule ] = useState(ruleProvided);
    const [ hasTriggerValue, setHasTriggerValue ] = useState(true);
    const [ triggerValueValid, setTriggerValueValid ] = useState(triggerValueProvided);

    const setUpAlarmPolicySelection = async() => {
        
        // set up the dropdown menuItems
        const alarmPoliciesObjArray = await localforage.getItem('alarmPolicies');
        const alarmPoliciesSelectorArray =  alarmPoliciesObjArray.map( o => {
            return {id: o.id, name: o.name, unit: o.unit};
        })
        setAlarmPolicies(alarmPoliciesSelectorArray); 
        
        // for 'UPDATE POLICY'
        // set the existing value of each field
        if(policyToBeUpdated) {
            let ruleId = policyToBeUpdated.alarm_policy_option_id;
            let ruleOperator = operatorDeterminator(policyToBeUpdated.operator);
            let triggerValue = policyToBeUpdated.value;
            let ruleUnit = policyToBeUpdated.alarm_policy_option.unit;
            setRule(ruleId);
            setRelation(ruleOperator);
            setTriggerValue(triggerValue);
            setUnit(ruleUnit === '&#8451;' || ruleUnit === '&amp;#8451;' ? degreeRegex : ruleUnit);
            storedValueToLocalforage(ruleId, ruleOperator, triggerValue, ruleUnit);
        }
    }

    const storedValueToLocalforage = async(rule, relation, triggerValue, unit) => {
        await localforage.setItem(storedRuleValueToLocalforageName, {id: rule, operator: relation, value: triggerValue, unit: unit});
    }

    const handleChange = async(e) => {
        
        let itemName = e.target.name;
        let value = e.target.value;
        let ruleUnit = e._targetInst.stateNode.dataset.unit;
        
        switch (itemName) {
            case 'rule':
                setRule(value);
                storedValueToLocalforage(value, relation, triggerValue, unit);
                setUnit(ruleUnit === '&#8451;' || ruleUnit === '&amp;#8451;' ? degreeRegex : ruleUnit);

                if(value !== ''){
                    setHasRule(true);
                } else {
                    await localforage.removeItem(storedRuleValueToLocalforageName);
                }

                break;
            case 'relation':
                setRelation(value);
                storedValueToLocalforage(rule, value, triggerValue, unit);
                break;
            case 'triggerValue':
                setTriggerValue(value);
                storedValueToLocalforage(rule, relation, value, unit);
                setTriggerValueValid(!!Number.isInteger(Number(value)));
                setHasTriggerValue(!!value)
                break;
            default:
               return;
          }
    }

    useEffect(()=>{ 
        if (!mounted.current) {
            setUpAlarmPolicySelection();
        }
        mounted.current = true;
    })

    useEffect(()=>{
        setHasRule(ruleProvided);
    },[ruleProvided])

    useEffect(()=>{
        setHasTriggerValue(triggerValueProvided);
    },[triggerValueProvided])
    
    return(
        <Grid container spacing={2} className={classes.ruleSelectGroup}>
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormControl fullWidth={true}>
                    <Select
                        name="rule"
                        ref={ref}
                        value={rule ? rule : ''}
                        onChange={handleChange}
                        label="Rule"
                        variant="outlined"
                        defaultValue=""
                        displayEmpty={true}
                        MenuProps={{ disableScrollLock: true }}
                    >
                        <MenuItem value="">{t('modal.selectRule')}</MenuItem>
                        {alarmPolicies && alarmPolicies.map( o => {
                            return (
                                <MenuItem 
                                    value={o.id} 
                                    key={o.id} 
                                    data-unit={o.unit}
                                >
                                    {o.name}
                                </MenuItem>
                            )

                        })}
                    </Select>
                </FormControl>
                { hasRule === false ? <RequiredText /> : '' }
            </Grid>
            <Grid item xs={12} sm={12} md={2} lg={2}>
                <FormControl fullWidth={true}>
                    <Select
                        name="relation"
                        ref={ref}
                        value={relation}
                        onChange={handleChange}
                        label="Relation"
                        variant="outlined"
                        MenuProps={{ disableScrollLock: true }}
                    >
                        <MenuItem value="<">{'<'}</MenuItem>
                        <MenuItem value=">">{'>'}</MenuItem>
                        <MenuItem value="<=">{'<='}</MenuItem>
                        <MenuItem value=">=">{'>='}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <FormControl fullWidth={true}>
                    <TextField
                        name="triggerValue"
                        variant="outlined"
                        placeholder={t('modal.enterTriggerValue')}
                        value={triggerValue}
                        ref={ref}
                        onChange={handleChange}
                        className={classes.textFieldOutlined}
                    />
                    <Typography className={classes.ruleValueUnit}>{unit}</Typography>
                </FormControl>
                { !triggerValueValid ? <NumberRequiredText /> : ''}
                { !hasTriggerValue ? <RequiredText /> : '' }
            </Grid>
        </Grid>
    )
})

export default SelectAlarmPolicy;