import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const createFirmwareAPI = async (file, note, type, companyData, modelData, t, successfulText, { setPercentCompleted }) => {

    let formData = new FormData();

    formData.append('note', note);
    companyData.forEach(company => {
        formData.append('company_ids[]', company.id);
    })
    modelData.forEach(model => {
        formData.append('device_model_ids[]', model.id);
    })
    formData.append('file', file);
    formData.append('peripheral_type_id', type)

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/admin-utility/firmware/create`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData,
            onUploadProgress: (progressEvent) => {
                let percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
                setPercentCompleted(percentCompleted)
            },
        })
        successHandling(t, successfulText)
        return true
    } catch (error) {
        errorHandling(error, false);
    }

}
