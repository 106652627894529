import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const removeUtilityAPI = async (id, t, successText) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/admin-utility/utility/delete`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                id: id,
            }
        })
        successHandling(t, successText);
        return true
    } catch (error) {
        errorHandling(error, true);
        return false
    }

}
