import Noty from 'noty';
import "../../node_modules/noty/lib/noty.css";
import "../../node_modules/noty/lib/themes/relax.css";

export const successHandling = (t, successfulText) => {
    try{
        new Noty({
            theme: 'relax',
            type: 'success',
            closeWith: ['click', 'button'],
            layout: 'topRight',
            timeout: process.env.REACT_APP_NOTY_TIMEOUT,
            text: `${t(successfulText)}`,
        }).show();
    } catch(err){
        console.error(err);
    }
    //window.history.back()
}
