import React from 'react';
import '../css/redirecting.css';
import LoadingIndicator from '../components/commons/LoadingIndicator';

const Loading = () => {

    return (
        <div className="root">
            <LoadingIndicator />
        </div>
    )
}

export default Loading;
