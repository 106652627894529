import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiBreadcrumbs-root": {
      marginBottom: theme.spacing(3),
    },
  },
  pageTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  searchInput: {
    position: "relative",
    "& .MuiFormControl-root": {
      width: "100% !important",
    },
    "& .MuiFormLabel-root": {
      left: 4,
    },
    "& input": {
      padding: "15px 20px 20px",
    },
    "& .searchIcon": {
      position: "absolute",
      top: 15,
      right: 0,
    },
  },
  deviceListWrapper:{
    paddingRight:'0 !important',
  },
  groupBtnsWrapper: {
    padding: 32,
    paddingRight:'0 !important',
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiPaper-root": {
      width: "100%",
      padding: "0 !important",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "0 !important",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0 !important",
      },
    },
    "& .MuiButton-root": {
      color: "#888 !important",
      textDecoration: "none !important",
      width: "100% !important",
      height: "initial !important",
      lineHeight: 1.3,
      padding: "16px 0 !important",
      marginBottom: "0 !important",
      wordBreak: "break-word !important",
      borderRadius: "0 !important",
      transition: theme.transitions.create(["color"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      "& .MuiButton-label": {
        textAlign: "left",
        justifyContent: "flex-start",
        textTransform: "initial",
      },
    },
    "& .MuiButton-textPrimary": {
      color: "#1DBBD4 !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-contained": {
        width: "auto",
      },
      "& .MuiButton-text": {
        display: "flex",
      },
      "& .MuiPaper-root": {
        marginBottom: 0,
      },
    },
  },
  titleWithEditIcon: {
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "6px 16px 0px !important",
    "& p": {
      display: "flex",
      "& svg": {
        marginLeft: 8,
      },
    },
  },
  groupNameBtns: {
    width: "100%",
    borderRadius: "0 !important",
    padding: "10px 10% !important",
  },
  listBtn: {
    marginBottom: "0 !important",
    cursor: "pointer",
    position: "relative",
    zIndex: 3,
  },
  reload_btn: {
    top: 10,
    zIndex: 9,
    cursor: "pointer",
  },
  detailTitle: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  marginRight_16: {
    marginRight: `${theme.spacing(2)}px !important`,
  },
  deviceGroupManagmentBtnList: {
    paddingTop: "0 !important",
    paddingBottom: "28px !important",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
    },
    "&>.MuiListItem-root": {
      borderBottom: "1px solid #eee",
      padding: theme.spacing(2),
      cursor: "pointer !important",
      "&:hover": {
        background: "none",
      },
      [theme.breakpoints.down("xs")]: {
        width: "50%",
      },
      [theme.breakpoints.down("md")]: {
        padding: "8px 0px !important",
      },
      [theme.breakpoints.down("lg")]: {
        padding: "12px 14px !important",
      },
    },
    "& .MuiListItem-root.Mui-disabled": {
      opacity: "0.6 !important",
      "& .MuiSvgIcon-root": {
        fill: "#999 !important",
      },
      "& .MuiTypography-root": {
        color: "#999 !important",
      },
    },
    "& .MuiListItemIcon-root": {
      minWidth: 46,
      "& svg": {
        height: 20,
        display: "block",
        margin: "0 auto",
      },
      "& img": {
        height: 15,
        display: "block",
        margin: "0 auto",
      },
      [theme.breakpoints.down("lg")]: {
        minWidth: 26,
        marginRight: 7,
        "& svg": {
          height: 20,
        },
        "& img": {
          height: 15,
        },
      },
    },
    "& .MuiTypography-root": {
      color: "rgb(4, 183, 210)",
      // fontSize: '0.9em !important',
      [theme.breakpoints.down("lg")]: {
        fontSize: "0.95em !important",
      },
    },
    "& .MuiSvgIcon-root": {
      fill: "rgb(4, 183, 210)",
    },
    "& .MuiTouchRipple-root": {
      display: "none",
    },
  },
  wideModal: {
    "& .MuiDialogTitle-root": {
      textAlign: "center",
    },
    "& .MuiDialog-paper": {
      width: "60% !important",
      minWidth: "300px !important",
      maxWidth: "840px !important",
    },
    "& .MuiDialogContent-root": {
      overflowY: "initial !important",
      padding: "8px !important",
    },
    "& .MuiList-root": {
      display: "flex !important",
      alignItems: "center !important",
      flexWrap: "wrap !important",
    },
    "& .MuiButtonBase-root": {
      width: "33.333333% !important",
      height: "60px !important",
      [theme.breakpoints.down("md")]: {
        width: "50% !important",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100% !important",
      },
    },
  },
  VncMultipleUsersLinkBtn: {
    maxWidth: "600px !important",
    display: "flex",
    alignItems: "center",
    minHeight: 40,
    height: "auto",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(2),
    "& .MuiTypography-colorSecondary": {
      paddingLeft: "8px !important",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "initial !important",
      alignItems: "baseline",
      flexDirection: "column",
      height: "auto",
      "& .MuiButton-label": {
        display: "flex !important",
        flexDirection: "column !important",
      },
    },
    "& a:hover": {
      textDecoration: "none",
    },
  },
  VncMultipleUsersLinkBtn_Icons: {
    display: "flex !important",
    color: "#06b7d2",
    alignItems: "center",
  },
  singleDeviceTaskBtnListWrap: {
    paddingBottom: "0 !important",
  },
  singleDeviceTaskBtnList: {
    paddingBottom: "0 !important",
  },
  svgIconInBtn: {
    marginRight: theme.spacing(1),
  },
  spanInBtn: {
    marginRight: theme.spacing(1),
  },
  marginBottomReset: {
    marginBottom: "2px !important", // 2px for the shadow
  },
  deviceDetail_BasicData: {
    display: "flex !important",
    flexDirection: "row !important",
    padding: "25px 0 15px",
    alignItems: "flex-start !important",
    justifyContent: "flex-start !important",
    "& .MuiListItem-root": {
      textAlign: "center",
    },
    "& .MuiDivider-root": {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    "& .MuiTypography-alignCenter": {
      fontSize: "0.875rem",
    },
  },
  deviceDetail_InformationBtnList: {
    display: "flex !important",
    flexDirection: "row !important",
    "& .MuiButtonBase-root": {
      marginBottom: "0 !important",
      width: "100% !important",
      [theme.breakpoints.down("md")]: {
        maxWidth: "140px !important",
      },
    },
  },
  tableMoveTopReset: {
    paddingTop: theme.spacing(2),
  },
  tableMoveBottomReset: {
    position: "relative",
    paddingBottom: 0,
    [theme.breakpoints.down("sm")]: {
      padding: "12px 20px 0 !important",
    },
  },
  operationTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deviceDetailGraphs: {
    position: "relative !important",
  },
  getTimeSelect: {
    display: "flex !important",
    margin: "0 10px 5px",
    flexDirection: "row !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    "& .MuiSelect-selectMenu": {
      display: "flex !important",
      alignItems: "center !important",
      justifyContent: "space-between !important",
      height: "10px !important",
      minHeight: "0 !important",
      padding: "5px 25px !important",
      border: "none !important",
      borderRadius: "24px !important",
      boxShadow:
        "0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined:focus": {
      border: "none !important",
    },
    "& .MuiSelect-outlined.MuiSelect-outlined:hover": {
      border: "none !important",
    },
  },
  minSelect: {
    margin: "0 10px",
  },
  tableTitle: {
    display: "flex",
    alignItems: "center",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  modal: {
    "& .MuiDialog-paper": {
      maxWidth: "100vw !important",
    },
    "& .MuiDialogTitle-root": {
      paddingTop: "0px !important",
      paddingBottom: "12px !important",
      borderBottom: "1px solid #eee !important",
      "& .MuiTypography-h6": {
        fontSize: "1.2rem !important",
      },
    },
    "& .MuiBox-root": {
      padding: "0 !important",
    },
    "& .noDataTextBox": {
      padding: "24px !important",
    },
    "& .MuiAppBar-root": {
      boxShadow: "none !important",
      background: "transparent !important",
      borderBottom: "1px solid #ddd !important",
    },
    "& .MuiTableRow-root th": {
      fontWeight: "bold !important",
      minWidth: "100px !important",
    },
    "& .MuiTableRow-root.name": {
      borderTop: "2px solid #4444 !important",
    },
  },
  softwareNameCell: {
    width: "40% !important",
    paddingRight: "16px !important",
  },
  deviceInfoCateSubtitle: {
    marginBottom: "16px !important",
  },
  graphWrap: {
    position: "relative",
  },
  graphicTable: {
    marginTop: "32px !important",
  },
  graphDate: {
    margin: "8px 24px 8px 0 !important",
    display: "block !important",
    color: "#999",
  },
  graphUnit: {
    top: "20px",
    left: "22px",
    position: "absolute",
  },
  singleDeviceDialog: {
    "& .MuiPaper-rounded": {
      padding: "16px 0 !important",
      borderRadius: "13px !important",
    },
    "& .MuiDialogTitle-root": {
      paddingBottom: "0 !important",
    },
    "& .MuiDialogContent-root": {
      padding: "8px 32px !important",
    },
    "& div.dataTables_wrapper": {
      paddingBottom: "30px !important",
    },
    "& .HOCforFullWidthTable-table-100": {
      paddingBottom: "50px !important",
    },
    "& .MuiButton-containedSecondary": {
      boxShadow: "none !important",
      color: "#aaa !important",
    },
    "& .MuiDialogActions-root": {
      justifyContent: "center !important",
      "& .MuiButton-textPrimary": {
        color: "#00adc7 !important",
      },
    },
  },
  disabledSwitch: {
    "& .MuiSwitch-track ": {
      backgroundColor: "#aaa !important",
      borderColor: "#aaa !important",
      cursor: "not-allowed",
    },
  },
  failedSwitch: {
    "& .MuiSwitch-track ": {
      backgroundColor: "#ff9e40 !important",
      borderColor: "#ff9e40 !important",
    },
  },
  detectTextAndBtn: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "0 !important",
    fontWeight: "500",
    "& .MuiButton-contained": {
      marginBottom: "0 !important",
      marginLeft: "0px !important",
      height: "30px !important",
      marginRight: "10px !important",
    },
  },
  usbDetectTextAndBtn: {
    color: "#9e9e9e",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "20px 0 10px",
    fontWeight: "500",
    "& .MuiButton-contained": {
      marginBottom: "0 !important",
      marginLeft: "0px !important",
      height: "30px !important",
      marginRight: "10px !important",
    },
    "& .detect-left": {
      display: "flex",
      alignItems: "center",
      zIndex: "99999999999",
    },
    "& .detect-right": {
      display: "flex",
      alignItems: "center",
    },
  },
  taskConfirmBtnWrap: {
    justifyContent: "center !important",
    "&>div": {
      marginBottom: "0 !important",
    },
    "& .MuiButton-contained": {
      boxShadow: "none",
      marginBottom: "0 !important",
    },
    "& .MuiButton-containedPrimary": {
      width: "100% !important",
    },
  },
  wholePageLoading: {
    position: "fixed !important",
    width: "100vw !important",
    height: "100vh  !important",
    top: "0 !important",
    left: "0 !important",
    background: "#fff !important",
    zIndex: "10000 !important",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    flexDirection: "column !important",
  },
  centerButtonWrap: {
    display:'flex',
    justifyContent: "center !important",
    paddingTop: "24px !important",
    "&>button": {
      marginBottom: "5px !important",
      width: "140px !important",
    },
    "& .MuiButton-containedSecondary": {
      boxShadow: "none !important",
      marginBottom: "0 !important",
    },
  },
  deviceOwnersBtn: {
    padding: "0 !important",
    height: "10px",
    lineHeight: "10px",
    minWidth: "initial",
    fontSize: "10px",
    borderRadius: "4px",
    color: "#999 !important",
    textDecoration: "underline !important",
    cursor: "pointer",
  },
  deviceOwnersModal: {
    "& .MuiPaper-rounded": {
      borderRadius: "13px !important",
    },
    "& .MuiListItem-root": {
      padding: "0 !important",
    },
    "& .MuiListItemIcon-root": {
      minWidth: "30px !important",
    },
    "& .MuiButton-textPrimary": {
      color: "#00adc7 !important",
    },
  },
  ownersCenter: {
    textAlign: "center !important",
  },
  offlineGraphNote: {
    position: "absolute !important",
    width: "150px !important",
    height: "20px !important",
    top: "50% !important",
    left: "50% !important",
    marginLeft: "-75px !important",
    marginTop: "-10px !important",
    lineHeight: "20px !important",
    color: "#ddd !important",
    textAlign: "center !important",
  },
  dialogTitleWithIconBtn: {
    display: "flex !important",
    alignItems: "center !important",
    position: "relative !important",
    zIndex: "999",
    marginBottom: "16px !important",
    "& .MuiSvgIcon-root": {
      cursor: "pointer !important",
      marginLeft: "10px !important",
    },
  },
  styledSubmitBtnWrap: {
    margin: "0 auto !important",
    width: "150px !important",
    "&>div": {
      marginBottom: "0 !important",
    },
  },
  singleDeviceTableWrapDialog: {
    "& .MuiDialog-paper": {
      marginBottom: "0 !important",
    },
    "& .full-width-table-wrap.MuiDialogContent-root": {
      padding: "0 0 48px !important",
    },
  },
  deviceDetail_title: {
    display: "flex",
    justifyContent: "space-between",
    fontWeight: 600,
    alignItems: "center",
  },
  warnOrNotText: {
    fontWeight: 600,
    [theme.breakpoints.down("lg")]: {
      fontSize: "24px",
    },
  },
  warn: {
    color: "#f98746",
  },
  notWarn: {
    color: "#4bc0c0",
  },
  storageLine: {
    position: "relative",
    marginTop: "5px",
    width: "100%",
    height: "3px",
    borderRadius: "10px",
    background: "#f1f1f1",
  },
  storageLineColor: {
    position: "absolute",
    borderRadius: "10px",
    height: "3px",
    left: 0,
    top: 0,
  },
  more: {
    color: "#999",
    fontSize: "12px",
    cursor: "pointer",
    fontWeight: 500,
    textDecoration: "underline",
  },
  expandIcon: {
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "pointer",
  },
  deviceDetail_DateSet: {
    display: "flex",
  },
  deviceDetail_text: {
    color: "#999",
    width: "120px",
  },
  deviceDetail_value: {
    fontWeight: "600 !important",
    "& .MuiTypography-root": {
      fontWeight: "600 !important",
    },
  },
  onlineOfflineText: {
    margin: "10px 0",
    fontWeight: 600,
    [theme.breakpoints.down("lg")]: {
      fontSize: "22px",
    },
  },
  online: {
    color: "#04b7d2",
  },
  offline: {
    color: "#9e9e9e",
  },
  tabContent: {
    marginTop: "20px",
  },
  operationArea: {
    width: "100%",
    display: "flex",
    margin: "20px 0",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
  operationAreaFirstLine: {
    width: "35%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  operationAreaSecondLine: {
    width: "65%",
    display: "flex",
    [theme.breakpoints.down("md")]: {
      width: "100%",
    },
  },
  operationSm: {
    padding: "10px 5px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "10px",
    flexBasis: "33%",
  },
  operationMd: {
    padding: "10px 5px 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginLeft: "0px",
    flexBasis: "45%",
  },
  operationLg: {
    padding: "10px 5px 7px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "10px",
    flexBasis: "55%",
  },
  cpuTemperatureArea: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "10px 10px 0",
  },
  cpuTemperatureOuter: {
    position: "relative",
    borderWidth: "1px",
    borderStyle: "dashed",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "23%",
    aspectRatio: "1 / 1",
  },
  warnTemperature: {
    borderColor: "#f98746",
    color: "#f98746",
    [theme.breakpoints.down("lg")]: {
      fontSize:'14px !important'
    },
    [theme.breakpoints.down("md")]: {
      fontSize:'12px !important'
    },
  },
  normalTemperature: {
    borderColor: "#4bc0c0",
    color: "#4bc0c0",
    [theme.breakpoints.down("lg")]: {
      fontSize:'14px !important'
    },
    [theme.breakpoints.down("md")]: {
      fontSize:'12px !important'
    },
  },
  noMoreCpu: {
    borderColor: "9e9e9e",
    color: "#9e9e9e",
  },
  graphTitleWithSelect: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "15px 15px 5px",
  },
  screenshotPaper: {
    height: "270px",
    marginTop: "20px",
    padding: "0",
    [theme.breakpoints.down("md")]: {
      height: "350px",
    },
  },
  screenshotNoImg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    height: "100%",
    "& .failedText": {
      color: "#aaa",
      marginBottom: "10px",
    },
    "& .smaller-loading-indicator": {
      alignItems: "center",
      display: "flex",
    },
  },
  iconButtonGroup: {
    position: "absolute",
    top: "20px",
    right: "20px",
    width: "calc( 100% - 20px )",
    "& button": {
      marginRight: "5px !important",
      zIndex: 20000,
    },
  },
  screenshotTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "& .screenshotTitleLeft": {
      display: "flex",
      flexDirection: "column",
    },
    "& .screenshotTitleRight": {
      display: "flex",
      alignItems: "center",
    },
  },
  screenshoImg: {
    height: "100%",
    position: "relative",
    "& .screenshotDialogInner": {
      backgroundColor: "rgba(255,255,255,0.9)",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-around",
      height: "40px",
      width: "40%",
      position: "absolute",
      top: "0",
      bottom: "0",
      right: "0",
      left: "0",
      margin: "auto",
      borderRadius: "50px",
      opacity: "0",
      transition: "0.3s",
      "& svg": {
        fontSize: "30px",
        cursor: "pointer",
        color: "#707070",
        "&:hover": {
          color: "#000",
        },
      },
    },
    "&:hover .screenshotDialogInner": {
      opacity: "1",
    },
    "& img": {
      width: "100%",
      height: "100%",
    },
  },
  screenshotIcon: {
    color: "#04b7d2",
    border: "1px solid #04b7d2",
    borderRadius: "50px",
    padding: "6px",
    width: "160px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    "&.disabled": {
      cursor: "not-allow",
      border: "1px solid #aaa",
      color: "#aaa",
    },
  },
  screenshotIconText: {
    marginLeft: "10px",
  },
  uploadDatetime: {
    margin: "10px 0 30px",
  },
  usbConnectionsDialog: {
    "& .dataTables_filter": {
      transform: "translateY(-70px)",
    },
    "& .MuiPaper-root": {
      width: "50%",
      padding: "0",
      [theme.breakpoints.down("md")]: {
        width: "70%",
      },
      [theme.breakpoints.down("sm")]: {
        width: "90%",
      },
    },
    "& .MuiDialogContent-root": {
      padding: "0",
    },
  },
  tableHeaderDialog: {
    padding: "30px 30px 20px",
    borderBottom: "1px solid #ebebeb",
    "& p": {
      margin: "20px 0",
    },
  },
  nodata: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    background: "#f1f1f1",
  },
}));

export default useStyles;
