export const windowsDeviceSoftwareApplicationInfoMap = [
    'name',
    'size',
    'installed_date',
]

export const windowsDeviceSoftwareUpdateInfoMap = [
    'description',
    'hotfix_id',
    'installed_on',
]

export const androidDeviceSoftwareInfoMap = [
    'name',
    'size',
    'version',
    'install_date',
]
