import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import textNoty from "../helpers/textNoty";
import { successHandling } from "../helpers/successHandling";

export const getGroupListApi = async () => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                group_category_name: "power",
            },
        });
        return res.data.groups;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const getEventListApi = async (
    type,
    company_id,
    group_id,
    tag_id,
    start_date,
    end_date
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/schedule/event`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                type,
                company_id: type === "company" ? company_id : undefined,
                group_id: type === "group" ? group_id : undefined,
                tag_id: type === "tag" ? tag_id : undefined,
                start_date,
                end_date,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getEvenApi = async (id) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/schedule/event/edit`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const createEventApi = async (
    group_id,
    company_id,
    tag_id,
    selectType,
    task_type,
    schedule_type,
    cycle_type,
    dailyTimes,
    weeklyTimes,
    weeklySelect,
    end_type,
    times,
    start_datetime,
    end_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    let cycle_value;
    if (schedule_type === "daily") {
        cycle_value = cycle_type === "every" ? dailyTimes : "workdays";
    } else {
        cycle_value = weeklyTimes;
    }
    let cycle_days = [];
    Object.entries(weeklySelect).forEach(([key, value]) => {
        if (value === true) {
            cycle_days.push(key);
        }
    });
    try {
        let res = await axios({
            url: `${url}/api/v2/schedule/event/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                type: selectType,
                company_id: selectType === "company" ? company_id : undefined,
                group_id: selectType === "group" ? group_id : undefined,
                tag_id: selectType === "tag" ? tag_id : undefined,
                task_type,
                schedule_type,
                cycle_type: schedule_type === "weekly" ? "weekly_days" : cycle_type,
                cycle_value,
                cycle_days: schedule_type === "weekly" ? cycle_days : undefined,
                end_type,
                run_times: end_type === "times" ? times : 1,
                start_datetime,
                end_datetime,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        if (error.response.data.message.message === "failed") {
            textNoty(t("dialog.noDeviceOS"));
            return;
        } else {
            errorHandling(error, false);
        }
    }
};

export const editEventApi = async (
    id,
    selectType,
    company_id,
    group_id,
    tag_id,
    task_type,
    schedule_type,
    cycle_type,
    dailyTimes,
    weeklyTimes,
    weeklySelect,
    end_type,
    times,
    start_datetime,
    end_datetime,
    t,
    successfulText,
    setDialogLoading
) => {
    const token = await localforage.getItem("token");
    let cycle_value;
    if (schedule_type === "daily") {
        cycle_value = cycle_type === "every" ? dailyTimes : "workdays";
    } else {
        cycle_value = weeklyTimes;
    }
    let cycle_days = [];
    Object.entries(weeklySelect).forEach(([key, value]) => {
        if (value === true) {
            cycle_days.push(key);
        }
    });
    try {
        let res = await axios({
            url: `${url}/api/v2/schedule/event/update`,
            method: "PUT",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                id,
                type: selectType,
                company_id: selectType === "company" ? company_id : undefined,
                group_id: selectType === "group" ? group_id : undefined,
                tag_id: selectType === "tag" ? tag_id : undefined,
                task_type,
                schedule_type,
                cycle_type: schedule_type === "weekly" ? "weekly_days" : cycle_type,
                cycle_value,
                cycle_days: schedule_type === "weekly" ? cycle_days : undefined,
                end_type,
                run_times: end_type === "times" ? times : 1,
                start_datetime,
                end_datetime,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        setDialogLoading(false);
        if (error.response.data.message.message === "failed") {
            textNoty(t("dialog.noDeviceOS"));
            return;
        } else {
            errorHandling(error, false);
        }
    }
};

export const deleteEventApi = async (id, t, successfulText) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/schedule/event/delete`,
            method: "DELETE",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                id,
            },
        });
        successHandling(t, successfulText);
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
