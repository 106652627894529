import { en } from "./en/en";
import { es } from "./es/es";
import { fr } from "./fr/fr";
import { jp } from "./jp/jp";
import { kr } from "./kr/kr";
import { ru } from "./ru/ru";
import { zh_cn } from "./zh_cn/zh_cn";
import { zh_tw } from "./zh_tw/zh_tw";

export const dictionaryList = { en, es, fr, jp, kr, ru, zh_cn, zh_tw };

export const languageOptions = {
    en: "EＮ",
    zh_tw: "繁",
    zh_cn: "简",
    kr: "한",
    fr: "FR",
    ru: "RU",
    es: "ES",
    jp: "JP",
};
