import React, { useState, useEffect } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableContainer,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Checkbox,
} from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import useStyles from "../../styles/dialogStyle";
import AutorenewRounded from "@material-ui/icons/AutorenewRounded";
import { scheduledDateInLocal } from "../../helpers/datetimeHandlers";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import textNoty from "../../helpers/textNoty";
import ShowMoreDialog from "./ShowMoreDialog";
import OSType from "../commons/OSType";
import AddNewVersion from "./AddNewVersion";
import DeleteDialog from "./DeleteDialog";
import { deleteVersionApi, setVersionApi } from "../../apis/appListApi";
import PublishDialog from "./PublishDialog";
import HasPermission from "../commons/HasPermission";
import { checkHasPermission } from "../../helpers/checkHasPermission";

const VersionManagement = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    loading,
    appVersions,
    getVersionApi,
    managementData,
    reloadTable,
    overSize,
  } = props;
  const { t } = useTranslation("appUpdateService");
  const [checked, setChecked] = useState([]);
  const [showMoreDialog, setShowMoreDialog] = useState(false);
  const [publishData, setPublishData] = useState({});
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showPublishDialog, setShowPublishDialog] = useState(false);
  const [showUnpublishDialog, setShowUnpublishDialog] = useState(false);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [showMoreData, setShowMoreData] = useState([]);
  const [selectIds, setSelectedIds] = useState([]);
  const [noGroup, setNoGroup] = useState(false);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };
  const refresh = () => {
    setChecked([]);
    getVersionApi(managementData.id);
  };
  const showMore = (e) => {
    setShowMoreData(e);
    setShowMoreDialog(true);
  };
  const showAdd = () => {
    setShowAddDialog(true);
  };
  const removeHandler = () => {
    if (checked && !checked.length) {
      textNoty(t("error.noSelectVersion"));
      return;
    }
    setOpenDeleteDialog(true);
  };
  const confirmDelete = async () => {
    setLoadingDialog(true);
    await deleteVersionApi(
      managementData.id,
      checked,
      t,
      t("noty.successDelete")
    ).then((res) => {
      if (res && res.status && res.status === "success") {
        refresh();
        setOpenDeleteDialog(false);
      }
      setLoadingDialog(false);
    });
  };
  const groupArray = (obj) => {
    const companyArr = obj.groups;
    if (companyArr && companyArr.length > 0) {
      if (companyArr.length <= 3) {
        return (
          <div className={classes.groupsArray}>
            {companyArr.map((e) => (
              <div className="groupItem" key={e.id}>
                {e.name}
              </div>
            ))}
          </div>
        );
      } else {
        let newArray = companyArr.slice(0, 3);
        return (
          <div className={classes.groupsArray}>
            {newArray.map((e) => (
              <div className="groupItem" key={e.id}>
                {e.name}
              </div>
            ))}
            <div className="more" onClick={() => showMore(companyArr)}>
              More
            </div>
          </div>
        );
      }
    } else {
      return "-";
    }
  };
  const unpublishHandler = async (e) => {
    const check = await checkHasPermission(
      "app_update_service_unpublish_version"
    );
    if (!check) {
      textNoty(t("error.noPermission"));
      return;
    }
    setPublishData(e);
    setShowUnpublishDialog(true);
  };
  const publishHandler = async (e) => {
    const check = await checkHasPermission(
      "app_update_service_publish_version"
    );
    if (!check) {
      textNoty(t("error.noPermission"));
      return;
    }
    setPublishData(e);
    setShowPublishDialog(true);
  };
  const confirmPublish = async () => {
    let groups = [];
    if (!selectIds.length > 0) {
      textNoty(t("error.noSelectGroup"));
      return;
    }
    setLoadingDialog(true);
    if (selectIds && selectIds.length > 0) {
      groups = selectIds.map((e) => e.id);
    }
    let data = await setVersionApi(
      publishData.id,
      true,
      groups,
      t,
      "dialog.successPublish"
    );
    if (data && data.status === "success") {
      let groupsUnpublish = [];
      let unpublishData = appVersions.filter((e) => e.is_publish);
      if (unpublishData && unpublishData.length > 0) {
        groupsUnpublish = unpublishData[0].groups.map((e) => e.id);
        let unpublish = await setVersionApi(
          unpublishData[0].id,
          false,
          groupsUnpublish
        );
        if (unpublish && unpublish.status === "success") {
          refresh();
          reloadTable();
          setShowPublishDialog(false);
          setLoadingDialog(false);
        }
      } else {
        refresh();
        reloadTable();
        setShowPublishDialog(false);
        setLoadingDialog(false);
      }
    }
  };
  const confirmUnpublish = async () => {
    setLoadingDialog(true);
    let groups = [];
    if (publishData.groups && publishData.groups.length > 0) {
      groups = publishData.groups.map((e) => e.id);
    }
    let data = await setVersionApi(
      publishData.id,
      false,
      groups,
      t,
      "dialog.successUnpublish"
    );
    if (data && data.status === "success") {
      refresh();
      reloadTable();
      setShowUnpublishDialog(false);
      setLoadingDialog(false);
    }
  };

  useEffect(() => {
    if (open) {
      setChecked([]);
    }
  }, [open]);
  return (
    <>
      <Dialog
        maxWidth="lg"
        className={`${classes.dialog} ${classes.noPaddingDialog}`}
        open={open}
        onClose={onClose}
        aria-labelledby="add-event-dialog"
        aria-describedby="add-event-dialog"
      >
        <DialogTitle id="add-edit-alarm-notification">
          {t("common.appVersionManagement")}
        </DialogTitle>
        <>
          <DialogContent>
            {loading ? (
              <SmallerLoadingIndicator />
            ) : (
              <div>
                <div className="nameArea">
                  <div>{managementData && managementData.name}</div>
                  <div className="ostype">
                    {managementData && managementData.os_type && (
                      <OSType os={managementData.os_type} />
                    )}
                  </div>
                </div>
                <div className="table-title-withIcon">
                  <AutorenewRounded
                    size="small"
                    className="icon-btn"
                    onClick={() => refresh()}
                  />
                  <HasPermission permission="app_update_service_add_version">
                    {overSize ? (
                      <Tooltip
                        title={t("common.overSizeContact")}
                        placement="top"
                      >
                        <div className={classes.iconWTextDisabled}>
                          <div className="icon-with-text disabled">
                            <AddIcon size="small" className="icon-btn" />
                            <Typography variant="body1" className="icon-text">
                              {t("common.addVersion")}
                            </Typography>
                          </div>
                        </div>
                      </Tooltip>
                    ) : (
                      <div className="icon-with-text" onClick={() => showAdd()}>
                        <AddIcon size="small" className="icon-btn" />
                        <Typography variant="body1" className="icon-text">
                          {t("common.addVersion")}
                        </Typography>
                      </div>
                    )}
                  </HasPermission>
                  <HasPermission permission="app_update_service_delete_version">
                    <div
                      className="icon-with-text"
                      onClick={() => removeHandler()}
                    >
                      <DeleteIcon size="small" className="icon-btn" />
                      <Typography variant="body1" className="icon-text">
                        {t("common.deleteVersion")}
                      </Typography>
                    </div>
                  </HasPermission>
                </div>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <HasPermission permission="app_update_service_delete_version">
                          <TableCell component="th" scope="row"></TableCell>
                        </HasPermission>
                        <TableCell component="th" scope="row">
                          {t("common.version")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t("common.uploadedDatetime")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t("common.description")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t("common.publishedGroups")}
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {t("common.action")}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    {appVersions && appVersions.length ? (
                      <TableBody>
                        {appVersions.map((obj, ArrayIndex) => (
                          <TableRow key={ArrayIndex}>
                            <HasPermission permission="app_update_service_delete_version">
                              <TableCell padding="checkbox">
                                {obj.is_publish ? (
                                  <Tooltip
                                    title={t("noty.needToUnpublishVersion")}
                                    placement="top"
                                  >
                                    <div className="disabledCheckboxOuter">
                                      <div className="disabledCheckbox"></div>
                                    </div>
                                  </Tooltip>
                                ) : (
                                  <Checkbox
                                    onClick={handleToggle(obj.id)}
                                    inputProps={{ "aria-labelledby": obj.id }}
                                    checked={checked.indexOf(obj.id) !== -1}
                                  />
                                )}
                              </TableCell>
                            </HasPermission>
                            <TableCell>{obj.version || "-"}</TableCell>
                            <TableCell>
                              {obj.updated_at
                                ? scheduledDateInLocal(obj.updated_at)
                                : "-"}
                            </TableCell>
                            <TableCell>{obj.description || "-"}</TableCell>
                            <TableCell>{groupArray(obj)}</TableCell>
                            <TableCell>
                              <div>
                                {obj.is_publish === false ? (
                                  <div
                                    className="publishBtn"
                                    onClick={() => publishHandler(obj)}
                                  >
                                    {t("common.publish")}
                                  </div>
                                ) : (
                                  <div
                                    className="unpublishBtn"
                                    onClick={() => unpublishHandler(obj)}
                                  >
                                    {t("common.Unpublish")}
                                  </div>
                                )}
                              </div>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    ) : null}
                  </Table>
                </TableContainer>
                {appVersions && !appVersions.length > 0 && (
                  <div className="noData">{t("table.noData")}</div>
                )}
                <div className="tableBottom">
                  <Typography variant="body2" color="secondary">
                    {appVersions && appVersions.length} {t("table.recordsUnit")}
                  </Typography>
                </div>
              </div>
            )}
          </DialogContent>
        </>
      </Dialog>
      <ShowMoreDialog
        open={showMoreDialog}
        onClose={() => setShowMoreDialog(false)}
        groupsArray={showMoreData}
      />
      <AddNewVersion
        open={showAddDialog}
        setOpenAddDialog={setShowAddDialog}
        loading={loadingDialog}
        setLoading={setLoadingDialog}
        reloadTable={() => getVersionApi(managementData.id)}
        managementData={managementData}
      />
      <DeleteDialog
        open={OpenDeleteDialog}
        deleteHeader={t("dialog.deleteHeaderVersion")}
        deleteContent={t("dialog.deleteContentVersion")}
        loading={loadingDialog}
        onConfirm={confirmDelete}
        onClose={() => setOpenDeleteDialog(false)}
      />
      <DeleteDialog
        open={showUnpublishDialog}
        deleteHeader={t("dialog.unpublishHeader")}
        deleteContent={t("dialog.unpublishContent")}
        loading={loadingDialog}
        onConfirm={confirmUnpublish}
        onClose={() => setShowUnpublishDialog(false)}
      />
      <PublishDialog
        open={showPublishDialog}
        loading={loadingDialog}
        publishData={publishData}
        noGroup={noGroup}
        setNoGroup={setNoGroup}
        managementData={managementData}
        onConfirm={confirmPublish}
        selectIds={selectIds}
        setSelectedIds={setSelectedIds}
        onClose={() => setShowPublishDialog(false)}
      />
    </>
  );
};

export default VersionManagement;
