import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from "localforage";
import FileUpload from "../device/FileUpload";
import ConfirmAndCancelBtn from "../commons/ConfirmAndCancelBtn";
import LinearWithValueLabel from "../commons/LinearProgress";
import RequiredText from "../commons/RequiredText";
import { s3upload } from "../../helpers/s3upload";

// Manage localforage names based on the task name
const taskName = "fileBrower";
const storedFileToLocalforageName = `${taskName}UplodedFile`;

const AddOrEditFileForm = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("nubisDrive");
  const {
    onClose,
    open,
    isUploading,
    setIsUploading,
    availableStorage,
    getAvailableStorageData,
    parentId,
    setRefreshCapacity,
    reloadTable,
  } = props;
  const [storedFile, setStoredFile] = useState();
  const [disabled, setDisabled] = useState(false);
  const [validated, setValidated] = useState(false);
  const [percentCompleted, setPercentCompleted] = useState(0);

  const handleUploadFile = () => {
    setDisabled(true);
    setIsUploading(true);
    if (storedFile) {
      let successfulText = `${t("dialog.successfullyUploadFile")}`;
      s3upload(
        document.getElementById("fileUpload"),
        parentId,
        t,
        successfulText,
        percentCompleted,
        setPercentCompleted,
        onClose,
        setRefreshCapacity,
        reloadTable,
        true,
        false,
        false,
        true,
        false,
        false,
        setDisabled
      );
    } else {
      setDisabled(false);
      setValidated(true);
      setIsUploading(false);
    }

    getAvailableStorageData();
  };

  const resetAllStoredValueInLocalforage = async () => {
    await localforage.removeItem(storedFileToLocalforageName);
  };

  useEffect(() => {
    resetAllStoredValueInLocalforage();
    getAvailableStorageData();
    setIsUploading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <>
      <Grid container spacing={4}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          className={classes.uploadFileWrap}
        >
          <FileUpload
            uploadFileType={"file"}
            setFile={setStoredFile}
            ifNubisDrive={true}
            tableDisabled={disabled}
            availableStorage={availableStorage}
            storedFileToLocalforageName={storedFileToLocalforageName}
          />
          {validated && !storedFile ? <RequiredText /> : ""}
          {isUploading && (
            <LinearWithValueLabel percentCompleted={percentCompleted} />
          )}
        </Grid>
      </Grid>
      <ConfirmAndCancelBtn
        hideLoadingIndicator={true}
        styledSubmitBtnOnClick={handleUploadFile}
        cancelBtnDisabled={disabled}
        cancelBtnOnClick={onClose}
      />
    </>
  );
};

export default AddOrEditFileForm;
