import React, { useEffect, useState, useCallback } from "react";
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
} from "@material-ui/core";
import { checkHasPermission } from "../../helpers/checkHasPermission";

const TableHeaders = (props) => {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onSorting,
    headCells,
    deletePermission,
  } = props;
  const [hasCheckbox, setHasCheckbox] = useState(false);

  const createSortHandler = (property) => (event) => {
    onSorting(event, property);
  };

  const checkDeletePermission = useCallback(async () => {
    const result = await checkHasPermission(deletePermission);
    if (result) {
      setHasCheckbox(result);
    }
  }, [deletePermission]);

  useEffect(() => {
    checkDeletePermission();
  }, [checkDeletePermission]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {hasCheckbox && (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
            />
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeaders;
