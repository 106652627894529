import { createTheme } from '@material-ui/core/styles';

// Custom Mui Theme for our project
const theme = createTheme({
    palette: {
        primary: {
            main: '#1DBBD4'
        },
        secondary: {
            main: '#999'
        },
        error: {
            main: '#ff9e40'
        },
    },
    shape: {
        borderRadius: 13,
    },
    overrides: {
        MuiAppBar: {
            root: {
                boxSizing: 'border-box',
            }
        },
        MuiMenu: {
            paper: {
                maxHeight: '100%',
            },
        },
        MuiButton: {
            root: {
                borderRadius: '24px !important',
                border: 0,
                maxWidth: 360,
                height: 40,
                boxShadow: 'none  !important',
                marginBottom: 48,
                zIndex: 9,
                transition: '0.3s !important',
            },
            contained: {
                background: 'linear-gradient(45deg, #04b7d2 30%, #0ebed8 90%) !important',
                boxShadow: 'none !important',
                color: 'white !important',
                '&&:hover': {
                    background: 'linear-gradient(45deg, #02abc3 30%, #07b4c7 90%) !important',
                },
                '&.Mui-disabled': {
                    background: 'linear-gradient(45deg, #eee 30%, #eee 90%) !important',
                    color: '#aaa !important'
                }
            },
            containedPrimary: {
                background: 'linear-gradient(45deg, #04b7d2 30%, #0ebed8 90%) !important',
                color: '#fff !important',
                '&&:hover': {
                    background: 'linear-gradient(45deg, #02abc3 30%, #07b4c7 90%) !important',
                },
            },
            containedSecondary: {
                background: '#cdcdcd !important',
                boxShadow: '0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)',
                color: '#fff !important',
                '&&:hover': {
                    background: '#bcbcbc !important',
                },
            },
            text: {
            },
            outlined: {
                border: '1px solid #04b7d2',
                boxShadow: 'none',
            },
            textPrimary: {
                width: 0,
                padding: 0,
                marginBottom: 0,
                height: 'auto',
                minWidth: 40,
                boxShadow: 'none',
                background: 'none',
                '&&:hover': {
                    background: 'none',
                }
            },
            outlinedPrimary: {
                backgroundColor: '#0ebdd742',
                '&&:hover': {
                    backgroundColor: '#0ebdd742',
                },
            },
            outlinedSecondary: {
                backgroundColor: '#fff',
                color: '#04b7d2',
                border: '1px solid #0ebdd742',
                '&&:hover': {
                    border: '1px solid #0ebdd7',
                },
            },
        },
        MuiFab: {
            root: {
                borderRadius: 24,
                border: 0,
                height: 48,
                marginBottom: 48,
                boxShadow: 'none',
                background: 'linear-gradient(45deg, #04b7d2 30%, #0ebed8 90%)',
                color: 'white',
                zIndex: 9,
                '&&:hover': {
                    background: 'linear-gradient(45deg, #00b4ce 30%, #08bcd0 90%)',
                },
            },
        },
        MuiPaper: {
            rounded: {
                padding: 16,
                marginBottom: 24,
                overflow: 'hidden',
            },
        },
        MuiPopover: {
            paper: {
                padding: 0
            },
        },
        MuiTableBody: {
            root: {
                '& tr:nth-child(2n-1)': {
                    background: '#f6f6f6',
                },
            }
        },
        MuiSelect: {
            filled: {
                '&&:hover': {
                    border: 'none',
                },
                '&&:focus': {
                    border: 'none',
                },
            },
            select: {
                '&&:hover': {
                    border: 'none',
                },
                '&&:focus': {
                    background: '#fff',
                    borderRadius: 24,
                    border: 'none',
                },
            },
            selectMenu: {
                padding: '10px 50px 10px 20px',
                borderRadius: 24,
                background: '#fff',
            },
            outlined: {
                border: '1px solid #04b7d2',
                '&&:hover': {
                    border: '1px solid #00b4ce',
                },
                '&&:focus': {
                    border: '1px solid #00b4ce',
                },
            },
        },
        MuiInput: {
            root: {
                overflow: 'hidden',
            },
        },
        MuiOutlinedInput: {
            root: {
                borderRadius: 24,
                border: 0,
            },
            notchedOutline: {
                border: 0,
            },
        },
        MuiStepIcon: {
            text: {
                fill: '#fff',
            },
        },
    },
})

export default theme;
