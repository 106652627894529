import React, { useContext, useState, useEffect } from 'react';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Divider } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn } from '../commons/FormBtns';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import { SubmitBtnContext } from '../../App';
import { removeFileAPI } from '../../apis/removeFileAPI';
import { removeFolderAPI } from '../../apis/removeFolderAPI';
import { utcFormatToLocalDateTimeWithSecond } from '../../helpers/datetimeHandlers';
import { transformedFormatBytes } from '../../helpers/formatBytesTransformer';

const RemoveFileDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('nubisDrive');
    const { onClose, open, data, setRefreshCapacity, reloadTable } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    const [ disabled, setDisabled ] = useState(false);
    const [ type, setType ] = useState(data?.type);

    const handleRemoveUtility = async() => {
        dispatch({type: 'showLoading'});
        setDisabled(true);
        if(type === 'folder'){
            let successText = `${t('dialog.successfullyRemoveFolder')}`;
            await removeFolderAPI(data.id, data.name, t, successText, setRefreshCapacity, onClose, reloadTable);
        } else {
            let successText = `${t('dialog.successfullyRemoveFile')}`;
            await removeFileAPI(data.id, data.s3_path, t, successText, setRefreshCapacity, onClose, reloadTable);
        }
        dispatch({type: 'stopLoading'});
        setDisabled(false);
    };

    useEffect(()=>{
        setType(data?.type);
        setDisabled(false);
        dispatch({type: 'stopLoading'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[data])

    return(
        <Dialog
            maxWidth='sm'
            className={classes.dialog}
            open={open}
            onClose={disabled ? null : onClose}
            aria-labelledby="add-edit-fw-utility"
            aria-describedby="add-edit-fw-utility-confirmation"
        >
            <DialogTitle id="add-edit-fw-utility">
                {type === 'folder' ? `${t('dialog.removeFolder')}` : `${t('dialog.removeFile')}`}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {type === 'folder' ? `${t('dialog.removeFolderDescription')}` : `${t('dialog.removeFileDescription')}`}
                </DialogContentText>
                <Divider />
                <Typography variant="caption">
                    {type === 'folder' ? `${t('dialog.folderName')}` : `${t('table.fileName')}`}
                </Typography>
                <Typography variant="body2" color="primary">
                    {data ? data.name : '-'}
                </Typography>
                <Typography variant="caption">
                    {t('table.uploadedDate')}
                </Typography>
                <Typography variant="body2" color="primary">
                    {data ? utcFormatToLocalDateTimeWithSecond(data.uploaded_at) : '-'}
                </Typography>
                {type !== 'folder' && 
                    <>
                        <Typography variant="caption">
                            {t('table.size')}
                        </Typography>
                        <Typography variant="body2" color="primary">
                            {data ? transformedFormatBytes(data.size) : '-'}
                        </Typography>
                    </>
                }
            </DialogContent>
            <DialogActions>
                <StyledSubmitBtn 
                    btnColor="primary" 
                    type="button" 
                    onClick={handleRemoveUtility} 
                    startIcon={<DeleteRounded />} 
                    btnText={t('dialog.removeBtn')} />
                <Button 
                    onClick={onClose} 
                    color="secondary" 
                    variant="contained"
                    disabled={disabled}
                    type="button">
                    {t('dialog.cancelBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveFileDialog;