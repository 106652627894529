import React from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
} from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../../components/commons/FormBtns";

const ConfirmDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const { open, onClose, onConfirm, loading, type } = props;

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            onClose={onClose}
            open={open}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t(`common.${type}`)}
            </DialogTitle>
            <>
                <DialogContent>{t('dialog.content')}</DialogContent>
                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading}
                        btnText={t("dialog.confirmBtn")}
                        onClick={() => onConfirm()}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default ConfirmDialog;
