import React, { useState } from "react";
import { Paper, Grid, Button } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import HardwareInfo from "./DeviceHardwareInfo";
import SoftwareInfo from "./DeviceSoftwareInfo";
import NetworkInfo from "./DeviceNetworkInfo";
import ComponentsInfo from "./DeviceComponentsInfo";
import { useTranslation } from "../../langs/useTranslation";
import SystemInfo from "./DeviceSystemInfo";

const DeviceDetailInformation = (props) => {
  const { t } = useTranslation("device");
  const classes = useStyles();
  const [open, setOpen] = useState("system");
  const { network, hardware, system } = props;
  const tabHandler = () => {
    switch (open) {
      case "system":
        return <SystemInfo system={system} />;
      case "hardware":
        return <HardwareInfo hardware={hardware} />;
      case "software":
        return <SoftwareInfo open={open} />;
      case "network":
        return <NetworkInfo network={network} />;
      case "componentsInfo":
        return <ComponentsInfo />;
      default:
        break;
    }
  };
  return (
    <div>
      <Paper>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          spacing={2}
          className={classes.deviceDetail_InformationBtnList}
        >
          <Grid item xs>
            <Button
              onClick={() => setOpen("system")}
              variant="outlined"
              color="secondary"
              className={classes.listBtn}
            >
              {t("deviceInformation.systemBtn")}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => setOpen("hardware")}
              variant="outlined"
              color="secondary"
              className={classes.listBtn}
            >
              {t("deviceInformation.hardwareBtn")}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => setOpen("software")}
              variant="outlined"
              color="secondary"
              className={classes.listBtn}
            >
              {t("deviceInformation.softwareBtn")}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => setOpen("network")}
              variant="outlined"
              color="secondary"
              className={classes.listBtn}
            >
              {t("deviceInformation.networkBtn")}
            </Button>
          </Grid>
          <Grid item xs>
            <Button
              onClick={() => setOpen("componentsInfo")}
              variant="outlined"
              color="secondary"
              className={classes.listBtn}
            >
              {t("deviceInformation.components")}
            </Button>
          </Grid>
        </Grid>
        <div className={classes.tabContent}>{tabHandler()}</div>
      </Paper>
    </div>
  );
};

export default DeviceDetailInformation;
