import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiButton-root': {
            boxShadow: 'none',
            marginBottom: theme.spacing(2),
        },
    },
    deviceExcelFileUploadMainInput: {
        background:'#fff',
        marginTop: theme.spacing(1),
        textAlign: 'left',
        display: 'flex',
        width: '80%',
        '& .MuiButton-label': {
            justifyContent: 'space-between',
            padding: `0 ${theme.spacing(2)}px`,
        },
    },
    hiddenInput: {
        display: 'none',
    },
    showFileUploadStatus: {
        display: 'flex',
        alignItems: 'center',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        '& .MuiSvgIcon-root': {
            marginRight: '3px',
        },
    },
    
}));

export default useStyles;