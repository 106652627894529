import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { AutorenewRounded } from '@material-ui/icons';
import { useTranslation } from "../../langs/useTranslation";
import { table } from '../../helpers/alarmPolicyDeviceDatatable';
import useStyles from '../../styles/alarmNotificationStyle';

const AlarmPolicyDeviceTable = props => {
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { id, open } = props;

    useEffect(() => {
        if (open) table(id, t);
    }, [id, open, t]);

    return (
        <div className="datatable-with-title-searchbar-wrap">
            <Typography variant="h5" className={classes.tableTitle}>
                {t('modal.devices')}
                <AutorenewRounded
                    onClick={() => table(id, t)}
                    size="small"
                    className={classes.reload_btn}
                />
            </Typography>
            <table id="alarm-policy-deploy-table" className="mdl-data-table dataTable">
                <thead>
                    <tr>
                        <th className="sorting">{t('table.serialNumber')}</th>
                        <th className="sorting">{t('table.onlineStatus')}</th>
                        <th className="sorting">{t('table.status')}</th>
                        <th className="sorting">{t('table.action')}</th>
                    </tr>
                </thead>
            </table>
        </div>
    )
}

export default AlarmPolicyDeviceTable;
