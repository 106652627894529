import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";
import {
    currentDateInUTC,
    scheduledDateInUTC,
} from "../helpers/datetimeHandlers";

export const getGroupsApi = async () => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/groups`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                group_category_name: "device_control",
            },
        });
        return res.data.groups;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getDeviceWithTypeApi = async (
    page,
    record,
    group_id,
    company_id,
    tag_id
) => {
    const token = await localforage.getItem("token");
    try {
        let res = await axios({
            url: `${url}/api/v2/devices/get-data-with-type`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id,
                group_id,
                page,
                record,
                tag_id,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const blockWindowsNotifyApi = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    status,
    execute_type,
    execute_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");

    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("status", status.toString());
    formData.append("execute_type", execute_type);
    formData.append("execute_datetime", execute_datetime);
    formData.append("previous_status", !status);

    try {
        await axios({
            url: `${url}/api/v2/command/disable-windows-notify-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};
export const usbLockApi = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    is_usb_lock,
    execute_type,
    execute_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");

    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("is_usb_lock", is_usb_lock.toString());
    formData.append("execute_type", execute_type);
    formData.append("execute_datetime", execute_datetime);
    formData.append("previous_status", !is_usb_lock);

    try {
        await axios({
            url: `${url}/api/v2/command/usb-lock-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const lockCombinationKeyApi = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    status,
    execute_type,
    execute_datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");

    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("status", status.toString());
    formData.append("execute_type", execute_type);
    formData.append("execute_datetime", execute_datetime);
    formData.append("previous_status", !status);

    try {
        await axios({
            url: `${url}/api/v2/command/combination-key-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const volumeApi = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    status,
    execute_type,
    adjust_number,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");

    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("execute_type", execute_type);
    formData.append("adjust_number", adjust_number);
    formData.append("status", status.toString());

    try {
        await axios({
            url: `${url}/api/v2/command/volume-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const brightnessApi = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    status,
    execute_type,
    adjust_number,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("execute_type", execute_type);
    formData.append("adjust_number", adjust_number);
    formData.append("status", status.toString());

    try {
        await axios({
            url: `${url}/api/v2/command/brightness-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const getScreenshotsApi = async (identity_code, device_id) => {
    const token = await localforage.getItem("token");
    const user = await localforage.getItem("user");
    try {
        let res = await axios({
            url: `${url}/api/v2/device/screenshots`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                user_id: user.id,
                device_id,
                identity_code,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const screenshotsSendApi = async (
    type,
    selectCompany,
    group_id,
    tagId,
    device_ids
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();

    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("type", type);
    formData.append("execute_type", "screenshot");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/monitor-screenshot-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        return res.data;
    } catch (error) {
        errorHandling(error);
    }
};

export const restartSystemAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    execute_type,
    datetime,
    command,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");

    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("execute_type", execute_type);
    formData.append(
        "execute_datetime",
        datetime === "immediately"
            ? currentDateInUTC()
            : scheduledDateInUTC(datetime)
    );
    formData.append("close_application_command", command);

    try {
        await axios({
            url: `${url}/api/v2/command/restart-system-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const kioskModeAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    executeType,
    enableKioskMode,
    enableApplicationAndroid,
    enableApplicationWindows,
    checkedWindows,
    checkedAndroid,
    datetime,
    enableApplication,
    command,
    t,
    successfulText,
    kioskModeSwitchOff
) => {
    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids && device_ids.length) {
        if (device_ids?.length > 0)
            device_ids.map((e) => formData.append("device_ids[]", e?.id || e));
    }
    formData.append("execute_type", executeType);
    formData.append("enable_kiosk_mode", enableKioskMode);
    if(enableApplication){
        formData.append("enable_application", enableApplication);
    }
    if (!kioskModeSwitchOff) {
        if (checkedWindows) {
            formData.append("enable_application_windows", enableApplicationWindows);
        }
        if (checkedAndroid) {
            formData.append("enable_application_android", enableApplicationAndroid);
        }
    }
    formData.append(
        "execute_datetime",
        datetime === "immediately"
            ? currentDateInUTC()
            : scheduledDateInUTC(datetime)
    );
    formData.append("close_application_command", command);

    const token = await localforage.getItem("token");

    try {
        let res = await axios({
            url: `${url}/api/v2/command/kiosk-mode-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
};
export const oraUpdateAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    executeType,
    datetime,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();

    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("execute_type", executeType);
    formData.append(
        "execute_datetime",
        datetime === "immediately"
            ? currentDateInUTC()
            : scheduledDateInUTC(datetime)
    );

    try {
        await axios({
            url: `${url}/api/v2/command/ora-update-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const rotationAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    executeType,
    datetime,
    orientation,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();

    formData.append("type", type);

    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id || e));
    formData.append("execute_type", executeType);
    formData.append(
        "execute_datetime",
        datetime === "immediately"
            ? currentDateInUTC()
            : scheduledDateInUTC(datetime)
    );
    formData.append("rotation_type", orientation);

    try {
        await axios({
            url: `${url}/api/v2/command/screen-rotation-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }
};

export const vncSettingAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    IsTwoFactorEnable,
    pwd,
    t,
    successfulText
) => {
    const token = await localforage.getItem("token");

    let formData = new FormData();
    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("password", pwd);
    formData.append("is_two_factor_enable", IsTwoFactorEnable);

    try {
        await axios({
            url: `${url}/api/v2/vnc/security/update-devices`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });

        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const IOSetUpAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    executeType,
    datetime,
    port,
    voltage,
    t,
    successfulText
) => {
    let formData = new FormData();
    let setIOPorts = []
    if(port.length > 0 && voltage.length > 0){
        port.forEach((port, i) => {
           setIOPorts.push({name: port, voltage: voltage[i]})
        });
    } else {
        setIOPorts = [{ name: port, voltage: voltage }];
    }

    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id || e));
    formData.append("set_io_ports", JSON.stringify(setIOPorts));
    formData.append("execute_type", executeType);
    formData.append(
        "execute_datetime",
        datetime === "immediately"
            ? currentDateInUTC()
            : scheduledDateInUTC(datetime)
    );
    const token = await localforage.getItem("token");
    try {
        await axios({
            url: `${url}/api/v2/command/io-set-send`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const deployFileAPI = async (
    type,
    selectCompany,
    device_ids,
    group_id,
    tagId,
    executeType,
    file,
    driveStorageId,
    autoLaunch1,
    autoLaunch2,
    datetime,
    location,
    command,
    selectFileType,
    t,
    successfulText,
    values
) => {
    const token = await localforage.getItem("token");
    let formData = new FormData();

    formData.append("type", type);
    if (type === "company") formData.append("company_id", selectCompany);
    if (type === "group") formData.append("group_id", group_id);
    if (type === "tag") formData.append("tag_id", tagId);
    if (device_ids?.length > 0)
        device_ids.map((e) => formData.append("device_ids[]", e?.id));
    formData.append("execute_type", executeType);
    formData.append(
        "execute_datetime",
        datetime === "immediately"
            ? currentDateInUTC()
            : scheduledDateInUTC(datetime)
    );
    formData.append("file_store_location", location);
    if (selectFileType === "file_from_nubis_drive") {
        formData.append("should_launch_file", autoLaunch2);
        if (autoLaunch2) {
            formData.append("auto_launch_command", command);
        }
        formData.append("drive_storage_id", driveStorageId);
    } else {
        formData.append("should_launch_file", autoLaunch1);
        if (autoLaunch1) {
            formData.append("auto_launch_command", command);
        }
        formData.append("s3_path", file.filePath);
        formData.append("file_md5", file.md5);
    }

    try {
        await axios({
            url: `${url}/api/v2/command/deploy-file`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: formData,
        });
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }
};
