import React from 'react';
import ReactDOM from 'react-dom';
import './css/variable.css';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { LOCALFORAGE_INITIATE } from './initiates/initiate';
//import { worker } from './mocks/browser'
import ErrorBoundary from './helpers/errorBoundary';

/* if (process.env.NODE_ENV === 'development') {
    worker.start()
  } */

ReactDOM.render(
    <ErrorBoundary>
        <App />
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();

//Any packages that required configure and/or set something required initiate, please write the initiate code in here
LOCALFORAGE_INITIATE();









