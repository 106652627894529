import React, { useState, useEffect, useContext, useRef } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../styles/deviceTaskStyle';
import { useTranslation } from "../langs/useTranslation";
import SingleForm from '../components/fileDeployment/SingleForm';
import { useParams } from 'react-router-dom';
import Form from '../components/fileDeployment/Form';
import { SubmitBtnContext } from '../App';
import { BreadCrumbs } from '../components/device/BreadCrumbs';
import * as localforage from 'localforage';

const FileDeployment = () => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('task');
    const { device_id, device_os_type } = useParams();
    const [isSingleDeviceTask, setIsSingleDeviceTask] = useState(false);
    const executeType = "deploy_file"
    const successfulText = 'task.successfullyDeployFile';
    const { dispatch } = useContext(SubmitBtnContext);

    // Manage localforage names based on the task name (no need for i18n)
    const taskName = "deployFile";
    const storedDeviceValueToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedFileValueToLocalforageName = `${taskName}FileSelected`;
    const storedDatePickerValueToLocalforageName = `${taskName}Date`;
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;

    const checkIsSingleDeviceTask = () => {
        setIsSingleDeviceTask(!!device_id);
    };

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedDeviceValueToLocalforageName);
        await localforage.removeItem(storedFileValueToLocalforageName);
        await localforage.removeItem(storedDatePickerValueToLocalforageName);
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    };


    useEffect(() => {
        if (!mounted.current) {
            checkIsSingleDeviceTask();
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })
    return (
        <div className={classes.root}>
            <BreadCrumbs showDeviceList={true} deviceId={device_id} deviceOsType={device_os_type} pageName="fileDeploymentName" />
            <Typography variant="h4" className={classes.pageTitle}>{t('task.fileDeploymentName')}</Typography>
            {isSingleDeviceTask ?
                <SingleForm
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                    storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                    executeType={executeType}
                    dispatch={dispatch}
                    devices=""
                    deviceId={device_id}
                    successfulText={successfulText} />
                :
                <Form
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                    storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                    storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                    storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                    storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                    executeType={executeType}
                    dispatch={dispatch}
                    deviceId=""
                    successfulText={successfulText} />
            }
        </div>
    )
}

export default FileDeployment;
