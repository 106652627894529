import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  pageTitle: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  tableTitle: {
    display: "inline-flex !important",
    alignItems: "center !important",
    position: "relative",
    zIndex: "4 !important",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  iconWText: {
    margin: "0 3px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "&.disabled": {
      color: "#aaa",
    },
  },
  iconText: {
    margin: "2px 0 0 5px",
    fontWeight: 600,
  },
  listBtn: {
    marginBottom: "0 !important",
    cursor: "pointer",
    position: "relative",
    zIndex: 3,
  }
}));

export default useStyles;
