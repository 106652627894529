import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";

const DeleteDialog = (props) => {
  const classes = useStyles();
  const {
    open,
    onClose,
    onConfirm,
    loading,
    dotColor,
    deleteData,
    timeInLocal,
  } = props;
  const { t } = useTranslation("powerManagement");
  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle id="add-edit-alarm-notification">
        <div className={classes.eventOuter}>
          <div
            className={classes.dot}
            style={{
              marginRight: "5px",
              background: deleteData && dotColor(deleteData.title),
            }}
          ></div>
          <div className={classes.eventTime}>
            {timeInLocal(deleteData && deleteData.borderColor)}{" "}
            {deleteData && t(`common.${deleteData.title}`)}
          </div>
        </div>
      </DialogTitle>
      <>
        <DialogContent>
          <Typography style={{ marginTop: "7px" }}>
            {t("dialog.confirmDelete")}
          </Typography>
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            disabled={loading}
            btnColor="primary"
            type="button"
            btnText={t("dialog.confirmBtn")}
            onClick={() => onConfirm()}
          />
          <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default DeleteDialog;
