import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    fullWidthPaper: {
        padding: '24px 40px !important',
        marginBottom: theme.spacing(3),
    },
    labelH6: {
        '& .MuiFormControlLabel-label':{
            fontSize: '1.25rem',
            fontWeight: 500,
            lineHeight: 1.6,
            letterSpacing: '0.0075em',
        },
    },
    inputField: {
        width: '100%',
        minWidth: 200,
        marginTop: 8,
        marginBottom: 8,
    },
}));

export default useStyles;