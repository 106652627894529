import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";
import { successHandling } from "../helpers/successHandling";
import TextNoty from "../helpers/textNoty";

export const uploadFileToNubisDriveAPI = async (
    dataFromS3,
    file,
    md5Value,
    parentId,
    t,
    successfulText,
    onClose,
    setRefreshCapacity,
    reloadTable,
    setReturnValue,
    setDisabled
) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");

    try {
        await axios({
            url: `${url}/api/v2/drive/file/create`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                company_id: companyId,
                name: file.name,
                extension: file.name.split(".").pop(),
                mime_type: file.type,
                size: file.size,
                s3_path: dataFromS3.Key,
                md5: md5Value,
                parent_id: parentId,
            },
        });
        if (!setReturnValue) {
            successHandling(t, successfulText);
        }
        setRefreshCapacity(true);
        if (setDisabled) {
            setDisabled(false);
        }
        reloadTable();
        onClose(); // close the upload dialog
        return true;
    } catch (error) {
        if (error.response?.data?.errors?.size[0] === "storage_space_exceeded") {
            TextNoty(t("dialog.storageSpaceExceeded"));
            if (setDisabled) {
                setDisabled(false);
            }
            return;
        }
        errorHandling(error, false);
    }
};
