import React, { useState, useEffect } from "react";
import { TextField, IconButton, Grid, Box } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import CheckRoundedIcon from "@material-ui/icons/CheckRounded";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { syncTagAPI } from "../../apis/syncTagAPI";
import { updateFileNameAPI } from "../../apis/updateFileNameAPI";
import { updateFolderNameAPI } from "../../apis/updateFolderNameAPI";
import { fileNameWithoutExtensionGenerator } from "../../helpers/fileNameWithoutExtensionGenerator";
import { addNewLinkToBreadcrumbs } from "../../helpers/handleNubisDrivePageUpdate";
import { preventDoubleClickFunction } from "../../helpers/preventDoubleClickFunction";
import ClickableFullSizedBox from "./ClickableFullSizedBox";
import HasPermission from "./HasPermission";

const EditableFieldForDatatable = (props) => {
  const {
    rename,
    inputValue,
    id,
    extension,
    ifFolder,
    disabled,
    parentId,
    setParentId,
    localforageKeyName,
    setLinkArray /* file browser: for updating breadcrumbs after renamed */,
  } = props;
  const { t } = useTranslation("common");
  const [isFieldDisabled, setIsFieldDisabled] = useState(true);
  const [value, setValue] = useState(inputValue);
  const [storedValue, setStoredValue] = useState(inputValue);

  const handleTextEditabled = () => {
    setIsFieldDisabled(false);
    // only edit the file name without extension
    if (rename === "fileName") {
      let fileNameWithoutExtension = fileNameWithoutExtensionGenerator(
        value,
        extension
      );
      setValue(fileNameWithoutExtension);
    }
  };

  const handleTextSaved = async () => {
    setIsFieldDisabled(true);
    if(!value){
      return
    }
    if (value !== storedValue && rename !== "fileName") {
      let successfulText = `${t("task.successfullyUpdateTag")}`;
      await syncTagAPI(
        storedValue,
        value,
        id,
        setStoredValue,
        setValue,
        t,
        successfulText
      );
    }

    if (value && rename === "fileName") {
      let fileNameWithoutExtension = fileNameWithoutExtensionGenerator(
        inputValue,
        extension
      );

      if (value !== fileNameWithoutExtension) {
        let successfulText = ifFolder
          ? `${t("task.successfullyUpdateFolderName")}`
          : `${t("task.successfullyUpdateFileName")}`;

        if (ifFolder) {
          setValue(value);
          await updateFolderNameAPI(
            storedValue,
            value,
            id,
            parentId,
            setStoredValue,
            setValue,
            t,
            successfulText
          );
        }

        if (!ifFolder) {
          // add the extension back for api updating
          let valueWithExtension = value + "." + extension;
          let storedValueWithExtension = storedValue + "." + extension;
          setValue(valueWithExtension);
          await updateFileNameAPI(
            storedValueWithExtension,
            valueWithExtension,
            id,
            setStoredValue,
            setValue,
            t,
            successfulText
          );
        }
      } else {
        setValue(inputValue);
      }
    }
  };

  const handleTextEditabledCancel = () => {
    setValue(storedValue);
    setIsFieldDisabled(true);
  };

  const handleValueChanged = (event) => {
    setValue(event.target.value);
  };

  const handleEditableFolderFieldClick = async (e) => {
    e.persist();
    preventDoubleClickFunction(
      e,
      addNewLinkToBreadcrumbs(
        value,
        id,
        parentId,
        setParentId,
        setLinkArray,
        "file-browser",
        localforageKeyName
      )
    );
  };

  useEffect(() => {
    setValue(inputValue);
    setStoredValue(inputValue);
  }, [inputValue]);

  return (
    <Grid container direction="row" alignItems="center">
      <Grid item xs={9}>
        <Box position="relative">
          {isFieldDisabled && ifFolder && (
            // Q: why add Box for onClick event?
            // A: onClick is not working with TextField on FireFox
            <ClickableFullSizedBox
              onClick={(e) => handleEditableFolderFieldClick(e)}
            />
          )}
          <TextField
            id={id}
            value={value}
            disabled={isFieldDisabled}
            onChange={handleValueChanged}
          />
        </Box>
      </Grid>
      <HasPermission permission="nubis_drive_file_broswer_change_file_name">
        {!disabled &&
          (isFieldDisabled ? (
            <Grid item xs={3}>
              <IconButton
                aria-label="edit"
                size="small"
                onClick={handleTextEditabled}
              >
                <EditRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
          ) : (
            <Grid item xs={3}>
              <IconButton
                aria-label="edit"
                size="small"
                onClick={handleTextSaved}
              >
                <CheckRoundedIcon fontSize="small" />
              </IconButton>
              <IconButton
                aria-label="cancel"
                size="small"
                onClick={handleTextEditabledCancel}
              >
                <CloseRoundedIcon fontSize="small" />
              </IconButton>
            </Grid>
          ))}
      </HasPermission>
    </Grid>
  );
};

export default EditableFieldForDatatable;
