import React from 'react';
import ReactDOM from 'react-dom';
import { url } from '../apis/apiSetup';
import * as localforage from 'localforage';
import * as $ from 'jquery';
import '../css/dataTable.css';
import 'datatables.net';
import 'datetime-moment';
import { language } from './datatableLanguage';
import { Checkbox } from '@material-ui/core';
import { CheckRounded } from '@material-ui/icons';
import { DefaultCheckedCheckBox } from '../components/commons/CheckBoxes';
import { handleDeviceCheckStatusChange, storeDeviceAndSetSelectedDeviceIds } from './taskDatatableHandlers';
import { searchStyleRender } from './datatableSearchStyleRender';
import { Typography } from '@material-ui/core';

export const vncTable = async (t, filterDevicesBy, selectedGroups, selectedTags, localforageKeyName, setSelectedIds) => {
    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');
    const selectedGroupIds = filterDevicesBy === 'filter_by_group' && selectedGroups ? selectedGroups.map(o => o.id) : undefined;
    const selectedTagIds = filterDevicesBy === 'filter_by_tag' && selectedTags ? selectedTags.map(o => o.id) : undefined;
    $.fn.dataTable.ext.errMode = 'throw';

    $('#vnc-setting-device-table').DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[0, 'asc']],
        filter: false,
        deferRender: true,
        info: true,
        autoWidth: false,
        stateSave: false,
        searching: true,
        destroy: true,
        scrollCollapse: true,
        scrollY: '40vh',
        paging: false,
        ordering: true,
        ajax: {
            url: `${url}/api/v2/devices/device-list-command`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                company_id: companyId,
                company_group_ids: selectedGroupIds,
                tag_ids: selectedTagIds,
            },
            beforeSend: request => {
                request.setRequestHeader('Authorization', 'Bearer ' + token);
                request.setRequestHeader('Accept', 'application/json');
            },
            complete: async (response) => {
                $('#vnc-setting-device-table').DataTable().rows({ page: 'all' }).select();
                if (response.responseText) {
                    const jsonResponseText = JSON.parse(response.responseText);
                    let data = jsonResponseText.data;
                    let availableDeviceIds = data && data.filter(o => o.nubis_version === 'nubis_pro').map(o => o.id);
                    await storeDeviceAndSetSelectedDeviceIds(availableDeviceIds, localforageKeyName, setSelectedIds);
                }
            },
        },
        select: {
            style: 'multi',
        },
        columns: [
            {
                data: 'id',
                orderable: false,
                searchable: false,
                defaultContent: '',
                className: 'select-checkbox',
                createdCell: (td, data, rowData) => {
                    if (rowData.nubis_version === 'nubis_pro') {
                        ReactDOM.render(
                            <DefaultCheckedCheckBox
                                data={data}
                                setSelectedIds={setSelectedIds}
                                localforageKeyName={localforageKeyName}
                                handleDeviceCheckStatusChange={handleDeviceCheckStatusChange}
                            />
                            , td);
                    } else {
                        ReactDOM.render(<Checkbox value={data} disabled />, td)
                    }
                }
            },
            {
                data: 'serial_number',
                title: `${t('task.serialNumber')}`,
                className: 'serial-number',
                render: data => data
            },
            {
                data: 'nubis_version',
                title: `${t('task.nubisVersion')}`,
                className: 'serial-number',
                createdCell: (td, data) => {
                    if (data) {
                        ReactDOM.render(
                            <Typography
                                variant="body2"
                                color={data === 'nubis_free' ? "secondary" : "textPrimary"}
                            >
                                {t(`task.${data}`)}
                            </Typography>
                            , td);
                    } else {
                        ReactDOM.render('-', td);
                    }

                }
            },
            {
                data: 'is_vnc_password_set',
                title: `${t('task.isVnvPwdSet')}`,
                createdCell: (td, data, rowData) => {
                    const isNubisPro = rowData.nubis_version === 'nubis_pro';

                    if (data) {
                        ReactDOM.render(<CheckRounded color={isNubisPro ? "primary" : "secondary"} />, td);
                    } else {
                        ReactDOM.render(<Typography color="secondary">-</Typography>, td);
                    }
                }
            },
            {
                data: 'vnc_2fa_status',
                title: `${t('task.twoFactor')}`,
                createdCell: (td, data, rowData) => {
                    const isNubisPro = rowData.nubis_version === 'nubis_pro';

                    if (data) {
                        ReactDOM.render(<CheckRounded color={isNubisPro ? "primary" : "secondary"} />, td);
                    } else {
                        ReactDOM.render(<Typography color="secondary">-</Typography>, td);
                    }
                }
            },
        ]
    })
    searchStyleRender();
}
