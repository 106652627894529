import React from "react";
import ReactDOM from "react-dom";
import { Box } from "@material-ui/core";
import { url } from "../apis/apiSetup";
import "../css/dataTable.css";
import * as $ from "jquery";
import "datatables.net";
import * as localforage from "localforage";
import { language } from "./datatableLanguage";
import { GetAppRounded } from "@material-ui/icons";
import { utcStringToLocalDatetimeConverter } from "../helpers/datetimeHandlers";
import { getDownloadLinkAPI } from "../apis/getDownloadLinkAPI";
import { errorHandling } from "./errorHandling";
import { transformedFormatBytes } from "./formatBytesTransformer";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { fileTypeIconDeterminator } from "./fileTypeIconDeterminator";
import HasPermission from "../components/commons/HasPermission";

export const table = async (t, parentId, linkArray, handleFolderClick) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";

    $("#shared-file-table").DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[2, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: true,
        paging: true,
        ajax: {
            url: `${url}/api/v2/drive/share/datatable`,
            type: "GET",
            data: {
                company_id: companyId,
                parent_id: parentId,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                errorHandling(error, true);
            },
        },
        columns: [
            {
                data: "drive_storage",
                name: "driveStorage.name",
                title: `${t("table.fileName")}`,
                className: "file-name",
                createdCell: (td, data, rowData) => {
                    let ifFolder = rowData.drive_storage.type === "folder";
                    let ifScreenshotFolder = ifFolder && data.name === "screenshot";

                    if (ifFolder) {
                        ReactDOM.render(
                            <Box
                                className="pointer"
                                display="flex"
                                alignItems="center"
                                onClick={(e) => handleFolderClick(e, data.name, data.id)}
                            >
                                {fileTypeIconDeterminator(
                                    `${ifScreenshotFolder ? "screenshotFolder" : "folder"}`
                                )}
                                {data.name}
                            </Box>,
                            td
                        );
                    } else {
                        ReactDOM.render(
                            <Box display="flex" alignItems="center">
                                {fileTypeIconDeterminator(data.extension)}
                                {data.name}
                            </Box>,
                            td
                        );
                    }
                },
            },
            {
                data: "drive_storage",
                name: "driveStorage.extension",
                title: `${t("table.type")}`,
                render: (data) => {
                    return data.extension ? data.extension : `${t("table.folder")}`;
                },
            },
            {
                data: "shared_at",
                title: `${t("table.sharedDate")}`,
                render: (data) => {
                    return utcStringToLocalDatetimeConverter(data);
                },
            },
            {
                data: "drive_storage",
                name: "driveStorage.size",
                title: `${t("table.size")}`,
                render: (data) => {
                    return data.type === "folder"
                        ? "-"
                        : transformedFormatBytes(data.size);
                },
            },
            {
                data: "share_by_company",
                name: "shareByCompany.name",
                title: `${t("table.sharedBy")}`,
                render: (data) => {
                    return data.name;
                },
            },
            {
                data: "drive_storage",
                title: `${t("table.action")}`,
                className: "action",
                orderable: false,
                createdCell: (td, data) => {
                    const id = data.id;
                    ReactDOM.render(
                        <HasPermission permission="nubis_drive_shared_with_me_download_file">
                            <GetAppRounded onClick={() => getDownloadLinkAPI(id)} />
                        </HasPermission>,
                        td
                    );
                },
            },
        ],
    });
    searchStyleRender();
};
