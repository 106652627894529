import React from 'react';
import { Box } from '@material-ui/core';

export const a11yProps = (index) => {
    return {
        id: `full-width-tab-${index}`,
        'aria-controls': `full-width-tabpanel-${index}`,
    };
}

export const TabPanel = (props) => {
    const { boxSpacing, children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={boxSpacing}>
                    {children}
                </Box>
            )}
        </div>
    );
}
