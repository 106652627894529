import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import ShareFileForm from './ShareFileForm';

// Manage localforage names based on the task name
const taskName = "shareFile";
const localforageKeyName = `${taskName}Companies`;

const ShareFileDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('nubisDrive');
    const { onClose, open, data, reloadTable } = props;
    
    return(
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="share-file"
            aria-describedby="share-file-confirmation"
        >
            <DialogTitle id="share-file">
            {t('dialog.shareFileToCompanies')}
            </DialogTitle>
            <DialogContent className='uploadFileOnlyDialog'>
                <ShareFileForm 
                    data={data}
                    driveStorageId={data && data.id}
                    open={open}
                    onClose={onClose} 
                    reloadTable={reloadTable}
                    localforageKeyName={localforageKeyName}
                />
            </DialogContent>
        </Dialog>
    )
}

export default ShareFileDialog;