import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    progressNotice: {
        display: 'flex',
        alignItems: 'center',
        textAlign: 'left',
        '& .MuiSvgIcon-root': {
            marginRight: '5px !important'
        }
    },
}));

export default useStyles;