import React, {
  useEffect,
  useState,
  useContext,
  useRef,
  useCallback,
} from "react";
import { Typography, Grid, Paper } from "@material-ui/core";
import useStyles from "../styles/deviceStyle";
import DeviceGroupManagementBtnList from "../components/device/DeviceGroupManagementBtnList";
import VncMultipleUsersLinkBtn from "../components/device/VncMultipleUsersLinkBtn";
import DeviceDetailBasicData from "../components/device/DeviceDetailBasicData";
import DeviceDetailGraphs from "../components/device/DeviceDetailGraphs";
import DeviceDetailTaskHistory from "../components/device/DeviceDetailTaskHistory";
import { useTranslation } from "../langs/useTranslation";
import {
  getSystemInfoAPI,
  getHardwareInfoAPI,
  getNetworkInfoAPI,
} from "../apis/getDeviceInfoAPI";
import { useParams } from "react-router-dom";
import * as dayjs from "dayjs";
import * as localforage from "localforage";
import LoadingIndicator from "../components/commons/LoadingIndicator";
import VncFailedDialog from "../components/device/VncFailedDialog";
import { getDeviceDetailAPI } from "../apis/getDeviceDetailAPI";
import useStateOnMounted from "../helpers/useStateOnMounted";
import { timeDiff } from "../helpers/datetimeHandlers";
import {
  setUpVncLinkAndExpiration,
  callVncAPI,
  vncConnectionFailed,
} from "../helpers/vncLinkHandler";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import { SubmitBtnContext } from "../App";
import DeviceBtnSets from "../components/device/DeviceBtnSets";
import ScreenShot from "../components/device/ScreenShot";
import { getDeviceTaskAPI } from "../apis/getDeviceTaskAPI";

export const VNCContext = React.createContext({});

const DeviceDetail = () => {
  const mounted = useRef();
  const { t } = useTranslation("device");
  const classes = useStyles();
  const { device_id } = useParams();
  const { dispatch } = useContext(SubmitBtnContext);
  const [VNCLink, setVNCLink] = useStateOnMounted("");
  const [deviceData, setDeviceData] = useStateOnMounted();
  const [hardware, setHardware] = useStateOnMounted("");
  const [network, setNetwork] = useStateOnMounted("");
  const [system, setSystem] = useStateOnMounted("");
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [VNCExpiredDate, setVNCExpiredDate] = useStateOnMounted();
  const [openDeviceBtnSets, setOpenDeviceBtnSets] = useState(false);
  const [nubisVersion, setNubisVersion] = useStateOnMounted("");
  const [onlineStatus, setOnlineStatus] = useState("");
  const [dialogContent, setDialogContent] = useState("");
  const [companies, setCompanies] = useStateOnMounted([]);
  const [tagValue, setTagValue] = useStateOnMounted("");
  const [serialNumber, setSerialNumber] = useStateOnMounted("");
  const [hasVncFromAPI, setHasVncFromAPI] = useStateOnMounted(false);
  const [openResendDialog, setOpenResendDialog] = useState(false);
  const [taskId, setTaskId] = useState();
  const [taskName, setTaskName] = useState();
  const [isVNCLoading, setIsVNCLoading] = useState(false);

  const getDeviceDetail = async () => {
    const data = await getDeviceDetailAPI(device_id);
    if (data) {
      setDeviceData(data.device);
      await localforage.setItem(`${device_id}`, data.device);
      let deviceCompanies = data.device.companies.map(
        (companyObj) => companyObj.name
      );
      let deviceTagsArr = data.device.tags;
      if (deviceTagsArr.length > 0) setTagValue(deviceTagsArr[0].name);
      setCompanies(deviceCompanies);
      setNubisVersion(data.device.nubis_version);
      setOnlineStatus(data.device.online_offline_status);
      setSerialNumber(data.device.serial_number);
      setHasVncFromAPI(data.device.remote_assistance_request);
    }
  };

  const handleSetVNC = async () => {
    setIsLoading(true);
    setIsVNCLoading(true);
    if (
      (nubisVersion === "nubis_free" || nubisVersion === "nubis_pro") &&
      onlineStatus === "online"
    ) {
      setIsLoading(false);
      callVncAPI(
        device_id,
        deviceData,
        nubisVersion,
        setIsLoading,
        setVNCLink,
        setVNCExpiredDate,
        setDialogContent,
        handleVNCFailedDialogOpen,
        t,
        'deviceDetail',
        setIsVNCLoading
      );
    }
    if (onlineStatus === "offline" || !nubisVersion) {
      let failedText = `${t("tasks.vncDeviceInvalidToConnectVNC")}`;
      vncConnectionFailed(
        setIsLoading,
        handleVNCFailedDialogOpen,
        setDialogContent,
        failedText
      );
    }
  };

  const checkIfVNCExistAlready = useCallback(async () => {
    // check if remote vnc link exsits
    let detailData = await localforage.getItem(device_id);
    let ifVNCFromAPIExists = detailData
      ? detailData.remote_assistance_request
      : false;
    let vncExpiredDateStringFromAPI =
      ifVNCFromAPIExists && ifVNCFromAPIExists.expired_at_format;


      // check if local vnc link exsits
    let localforageVNC = await localforage.getItem(`${device_id}VNC`);
    console.table(  { detailData, localforageVNC } );

    if(localforageVNC){

        if (detailData) {
            if(localforageVNC.isLoading === false){
                let vncExpiredDateStringFormLocal = localforageVNC.data.vnc.expired_at;
                let showVncFromAPI = timeDiff(
                  vncExpiredDateStringFromAPI,
                  vncExpiredDateStringFormLocal,
                  false
                );
                if (showVncFromAPI) {
                    console.log(
                        "VNC is Loaded, VNC from API is newer"
                    )
                  setUpVncLinkAndExpiration(
                    vncExpiredDateStringFromAPI,
                    ifVNCFromAPIExists,
                    setVNCLink,
                    setVNCExpiredDate,
                    detailData
                  );
                }

                if (!showVncFromAPI) {
                    console.log(
                        "VNC is Loaded From local storage."
                    )
                  let timeValid = setUpVncLinkAndExpiration(
                    vncExpiredDateStringFormLocal,
                    localforageVNC.data.vnc.server,
                    setVNCLink,
                    setVNCExpiredDate,
                    detailData
                  );
                  if (!timeValid) {
                    console.log("Time is not Valid");
                    await localforage.removeItem(`${device_id}VNC`);
                  }
                }
            } else {
                setIsVNCLoading(true);
                checkVNCStatus(localforageVNC.taskId, localforageVNC.data);
                console.log("Somebody have opened VNC on this device, connection is loading")
            }

          /* if (!ifVNCFromAPIExists && localforageVNC) {
            console.log("Never got here")
            let timeValid = setUpVncLinkAndExpiration(
              vncExpiredDateStringFormLocal,
              localforageVNC.data.vnc.server,
              setVNCLink,
              setVNCExpiredDate,
              detailData
            );
            if (!timeValid) {
              await localforage.removeItem(`${device_id}VNC`);
            }
          } */
        }
     } else {
        console.log("No Completed VNC set up.");
    }
  }, [device_id, isVNCLoading/* , setVNCExpiredDate, setVNCLink */]);

  const checkVNCStatus = async (taskId, taskData) => {
    console.log(taskId)
    if(!isVNCLoading){
        return;
    }

    const taskStatus = await getDeviceTaskAPI(taskId);

    if (taskStatus !== "completed" && taskStatus !== "failed") {
        console.log('retry: ', device_id)
        setTimeout(() => checkVNCStatus(taskId, taskData), 3000);
    }

    if (taskStatus === "completed") {
        setIsVNCLoading(false);
        await localforage.setItem(`${device_id}VNC`,  {data: taskData, isLoading: false});
        const vncLink = taskData.vnc.server;
        const vncExpiredDateString = taskData.vnc.expired_at;
        setUpVncLinkAndExpiration(
            vncExpiredDateString,
            vncLink,
            setVNCLink,
            setVNCExpiredDate
        );
        let popUp = window.open(vncLink);
        if (popUp == null || typeof popUp == "undefined") {
            alert("Please disable your pop-up blocker and try again.");
        } else {
            popUp.focus();
        }
    }

    if (taskStatus === "failed") {
        await localforage.removeItem(`${device_id}VNC`);
        let failedText = `${t("tasks.vncConnectionFailed")}`;
        vncConnectionFailed(
            setIsLoading,
            handleVNCFailedDialogOpen,
            setDialogContent,
            failedText
        );
    }
};

  const handleVNCFailedDialogOpen = () => {
    setOpen(true);
  };

  const handleVNCFailedDialogClose = async () => {
    await localforage.removeItem(`${device_id}VNC`);
    setOpen(false);
    setVNCLink();
    setVNCExpiredDate();
  };

  const handleClickVNCUserLink = (e) => {
    //check if the link is expired
    //since the state 'VNCExpiredDate' using here is local time zone,
    //current date should be the local time zone
    const currentDate = dayjs().format("YYYY-MM-DD HH:mm:ss");
    const expiredDateForDiff = dayjs(VNCExpiredDate);
    const currentDateForDiff = dayjs(currentDate);
    const timeDiff = expiredDateForDiff.diff(currentDateForDiff);
    if (timeDiff > 0) {
      setVNCLink(VNCLink);
    } else {
      e.preventDefault();
      handleVNCFailedDialogOpen();
      setDialogContent(`${t("tasks.vncConnectionFailed")}`);
    }
  };

  const hardwareDataFetch = async () => {
    let data = await getHardwareInfoAPI(device_id);
    setHardware(data);
  };

  const networkDataFetch = async () => {
    let data = await getNetworkInfoAPI(device_id);
    setNetwork(data);
  };

  const systemDataFetch = async () => {
    let data = await getSystemInfoAPI(device_id);
    setSystem(data);
  };

  const handleResendDialogOpen = (task_id, task_name) => {
    setOpenResendDialog(true);
    setTaskId(task_id);
    setTaskName(task_name);
    dispatch({ type: "stopLoading" });
  };

  const handleResendDialogClose = () => setOpenResendDialog(false);

  // store four device info into localforage
  useEffect(() => {
    if (!mounted.current) {
      getDeviceDetail();
      hardwareDataFetch();
      networkDataFetch();
      systemDataFetch();
      mounted.current = true;
    }
  });

  useEffect(() => {
    checkIfVNCExistAlready();
  }, [checkIfVNCExistAlready, isVNCLoading]);

  return (
    <div className={classes.root}>
      <BreadCrumbs
        showDeviceList={true}
        pageName={serialNumber}
        noTranslate={true}
      />
      <Grid container spacing={3}>
        {/* Side btn */}
        <Grid
          item
          xs={12}
          sm={2}
          md={2}
          lg={2}
          xl={2}
          className={classes.groupBtnsWrapper}
        >
          <Paper className={classes.singleDeviceTaskBtnListWrap}>
            <DeviceGroupManagementBtnList
              handleResendDialogOpen={handleResendDialogOpen}
              getDeviceDetail={getDeviceDetail}
              deviceData={deviceData}
              onClickSetVNC={handleSetVNC}
              onlineStatus={onlineStatus}
              VNCLink={VNCLink}
              nubisVersion={nubisVersion}
              serialNumber={serialNumber}
            />
          </Paper>
        </Grid>
        {/* Main detail */}
        <Grid item xs={12} sm={10} md={10} lg={10} xl={10}>
          {nubisVersion === "nubis_pro" ? (
            <VncMultipleUsersLinkBtn
              VNCLink={VNCLink}
              VNCExpiredDate={VNCExpiredDate}
              isVNCLoading={isVNCLoading}
              handleClickVNCUserLink={handleClickVNCUserLink}
            />
          ) : (
            ""
          )}
          {/* Information */}
          <DeviceDetailBasicData
            system={system}
            deviceData={deviceData}
            serialNumber={serialNumber}
            openMore={() => setOpenDeviceBtnSets(true)}
            companies={companies}
            tagValue={tagValue}
            nubisVersion={nubisVersion}
            onlineStatus={onlineStatus}
          />
          {/* click more btn dialog */}
          <DeviceBtnSets
            open={openDeviceBtnSets}
            setOpen={setOpenDeviceBtnSets}
            hardware={hardware}
            system={system}
            network={network}
          />
          {/* operation */}
          <DeviceDetailGraphs
            getDeviceDetail={getDeviceDetail}
            hardware={hardware}
            onlineStatus={onlineStatus}
            deviceData={deviceData}
          />
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={4}>
              {/* screenshot */}
              <ScreenShot t={t} device_id={device_id} deviceData={deviceData} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={7} xl={8}>
              {/* task history */}
              <Paper
                className={`${classes.tableMoveTopReset} ${classes.tableMoveBottomReset}`}
              >
                    <DeviceDetailTaskHistory
                        deviceData={deviceData}
                        setOpenResendDialog={setOpenResendDialog}
                        dispatch={dispatch}
                        openResendDialog={openResendDialog}
                        taskId={taskId}
                        taskName={taskName}
                        device_id={device_id}
                        handleResendDialogClose={handleResendDialogClose}
                        handleResendDialogOpen={handleResendDialogOpen}
                        nubisVersion={nubisVersion}
                        setOnlineStatus={setOnlineStatus}
                    />
              </Paper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <VncFailedDialog
        open={open}
        onClose={handleVNCFailedDialogClose}
        dialogContent={dialogContent}
      />
      {isLoading ? (
        <div className={classes.wholePageLoading}>
          <Typography variant="h6" color="secondary">
            {t("tasks.connectingToDevice")}
          </Typography>
          <Typography variant="caption" color="secondary">
            {t("tasks.noticeThePopoutWindowBlocker")}
          </Typography>
          <Typography variant="caption" color="secondary">
            {t("tasks.waitVncConnectionTime")}
          </Typography>
          <LoadingIndicator />
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default DeviceDetail;
