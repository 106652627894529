import React, { useState, useEffect } from "react";
import useStyles from "../../styles/deviceStyle";
import { Paper } from "@material-ui/core";
import ScreenshotIcon from "@material-ui/icons/Transform";
import { Typography } from "@material-ui/core";
import {
  screenshotsSendApi,
  getScreenshotsApi,
} from "../../apis/deviceDetailAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import ZoomInIcon from "@material-ui/icons/ZoomIn";
import GetAppIcon from "@material-ui/icons/GetApp";
import Lightbox from "react-awesome-lightbox";
import { scheduledDateInLocal } from "../../helpers/datetimeHandlers";
import { utcFormatToLocalDateTimeWithSecond } from "../../helpers/datetimeHandlers";
import { checkHasPermission } from "../../helpers/checkHasPermission";
import textNoty from "../../helpers/textNoty";

const ScreenShot = (props) => {
  const { t, device_id, deviceData } = props;
  const [screenshotData, setScreenData] = useState({});
  const [getFailed, setGetFailed] = useState(false);
  const [downLoadLoading, setDownLoadLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const [viewer, setViewer] = useState(false);
  const [viewerTitle, setViewerTitle] = useState("");
  const [viewerUrl, setViewerUrl] = useState("");
  const [taskId, setTaskId] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [times, setTimes] = useState(0);

  useEffect(() => {
    let id = setInterval(async () => {
      setTimes(times + 1);
      await getImageList();
    }, 3000);
    if (times === 10) {
      setTimes(0);
      setLoading(false);
      setGetFailed(true);
      setRefresh(false);
      clearInterval(id);
      return;
    }
    if (!refresh) {
      setTimes(0);
      setRefresh(false);
      clearInterval(id);
      return;
    }
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, refresh]);

  const viewerHandler = (data) => {
    setViewer(true);
    setViewerTitle(deviceData?.serial_number);
    setViewerUrl(data?.viewerUrl);
  };

  const closeViewer = () => {
    setViewer(false);
  };

  const getImageList = async () => {
    let data = await getScreenshotsApi(taskId);
    if (data && data.screenshots) {
      setScreenData(data.screenshots);
      if (data.screenshots.viewerUrl) {
        setRefresh(false);
        setLoading(false);
      }
    }
  };

  const sendCommandScreenshot = async () => {
    const check = await checkHasPermission("single_screenshot");
    if (!check) {
      textNoty(t("error.noPermission"));
      return;
    }
    setTaskId("");
    setGetFailed(false);
    setLoading(true);
    setScreenData({});
    let data = await screenshotsSendApi(device_id);
    if (data && data.message === "success" && data.task_id) {
      setTaskId(data.task_id);
      setRefresh(true);
    } else {
      setGetFailed(true);
      setLoading(false);
    }
  };

  const download = async (data) => {
    if (downLoadLoading) {
      return;
    }
    if (data?.viewerUrl) {
      setDownLoadLoading(true);
      let dateTime = utcFormatToLocalDateTimeWithSecond(new Date());
      let response = await fetch(data.viewerUrl);
      let blob = await response.blob();
      const aLink = document.createElement("a");
      aLink.download = `${deviceData?.serial_number}-${dateTime}.png`;
      aLink.href = URL.createObjectURL(blob);
      document.body.appendChild(aLink);
      aLink.click();
      document.body.removeChild(aLink);
      setDownLoadLoading(false);
    }
  };

  return (
    <div>
      <div className={classes.screenshotTitle}>
        <div className="screenshotTitleLeft">
          <Typography variant="h5" className={classes.detailTitle}>
            {t("tasks.screenshot")}
          </Typography>
          <Typography
            variant="body2"
            color="secondary"
            className={classes.uploadDatetime}
          >
            {t("deviceDetail.uploadDatetime")}
            {screenshotData &&
            screenshotData.updated_at &&
            screenshotData.viewerUrl
              ? scheduledDateInLocal(screenshotData.updated_at)
              : " - "}
          </Typography>
        </div>
        <div className="screenshotTitleRight">
          {screenshotData && screenshotData.viewerUrl && (
            <div
              className={classes.screenshotIcon}
              onClick={() => sendCommandScreenshot()}
            >
              <ScreenshotIcon />
              <Typography
                variant="body1"
                className={classes.screenshotIconText}
              >
                {t("tasks.screenshot")}
              </Typography>
            </div>
          )}
        </div>
      </div>
      <Paper className={classes.screenshotPaper}>
        {screenshotData && screenshotData.viewerUrl ? (
          <div className={classes.screenshoImg}>
            <img src={screenshotData.viewerUrl} alt="pic" />
            <div className="screenshotDialogInner">
              <ZoomInIcon onClick={() => viewerHandler(screenshotData)} />
              <GetAppIcon onClick={() => download(screenshotData)} />
            </div>
          </div>
        ) : (
          <div className={classes.screenshotNoImg}>
            {loading ? (
              <SmallerLoadingIndicator />
            ) : (
              <>
                <div className="failedText">
                  {getFailed && t("deviceDetail.failedTryAgain")}
                </div>
                {deviceData &&
                deviceData.online_offline_status &&
                deviceData.online_offline_status === "online" ? (
                  <div
                    className={`${classes.screenshotIcon}`}
                    onClick={() => sendCommandScreenshot()}
                  >
                    <ScreenshotIcon />
                    <Typography
                      variant="body1"
                      className={classes.screenshotIconText}
                    >
                      {t("tasks.screenshot")}
                    </Typography>
                  </div>
                ) : (
                  <div className={`${classes.screenshotIcon} disabled`}>
                    <ScreenshotIcon />
                    <Typography
                      variant="body1"
                      className={classes.screenshotIconText}
                    >
                      {t("tasks.screenshot")}
                    </Typography>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </Paper>
      {viewer && (
        <Lightbox image={viewerUrl} title={viewerTitle} onClose={closeViewer} />
      )}
    </div>
  );
};

export default ScreenShot;
