import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import AddOrEditFWUtilityForm from './AddOrEditFWUtilityForm';
import { filterdArr } from '../../helpers/arrayHandlers';

const AddOrEditFWUtilityDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('adminUtility');
    const { onClose, open, fwData } = props;
    const [fwId, setFwId] = useState();
    const [modelIdArr, setModelIdArr] = useState([]);
    const [companyIdArr, setCompanyIdArr] = useState([]);
    const [filePath, setFilePath] = useState();
    const [fwTypeId, setFwTypeId] = useState();
    const [createUtility, setCreateUtility] = useState(false);

    useEffect(() => {
        // update FW utility
        if (fwData && fwData.firmware_company_model_maps) {
            const dataArray = fwData.firmware_company_model_maps;
            const fwId = fwData.id;
            const fwPath = fwData.file;
            const fwTypeId = fwData.peripheral_type.id;
            setFwId(fwId);
            setFilePath(fwPath);
            setFwTypeId(fwTypeId);
            const allModelIds = dataArray.map(o => {
                if (o.device_model) return o.device_model.id;
                return false;
            });
            const uniqueModelIdsArr = filterdArr(allModelIds);
            setModelIdArr(uniqueModelIdsArr);
            const allCompanyIds = dataArray.map(o => {
                if (o.company) return o.company.id;
                return false;
            });
            const uniqueCompanyIdsArr = filterdArr(allCompanyIds);
            setCompanyIdArr(uniqueCompanyIdsArr);
            setCreateUtility(false);
        }
        // create FW utility
        if (!fwData) {
            setModelIdArr([]);
            setCompanyIdArr([]);
            setCreateUtility(true);
        }
    }, [fwData]);

    return (
        <Dialog
            maxWidth="lg"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-fw-utility"
            aria-describedby="add-edit-fw-utility-confirmation"
        >
            <DialogTitle id="add-edit-fw-utility">
                {fwData ?
                    `${t('dialog.editFWUtility')}`
                    :
                    `${t('dialog.addFWUtility')}`
                }
            </DialogTitle>
            <DialogContent>
                <AddOrEditFWUtilityForm
                    open={open}
                    modelIdArr={modelIdArr}
                    companyIdArr={companyIdArr}
                    onClose={onClose}
                    fwId={createUtility ? undefined : fwId}
                    fwTypeId={createUtility ? undefined : fwTypeId}
                    filePath={createUtility ? undefined : filePath}
                    createUtility={createUtility} />
            </DialogContent>
        </Dialog>
    )
}

export default AddOrEditFWUtilityDialog;
