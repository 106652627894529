import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const setVNCAPI = async (
    deviceData,
    nubisVersion,
    newVnc = true,
    type
) => {
    const token = await localforage.getItem("token");
    const apiUrl = newVnc
        ? `${url}/api/v2/command/obtain-vnc-new`
        : `${url}/api/v2/command/obtain-vnc`;

    try {
        let response = await axios({
            url: apiUrl,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: {
                device_id: deviceData?.id,
                nubis_version: nubisVersion,
            },
        });

        const task_id = response.data.task.id;
        return { task_id: task_id, task_data: response.data };
    } catch (error) {
        if (type === 'deviceDetail') {
            window.location.assign(
                `/device-list/device-detail/${deviceData?.id}/${deviceData?.os_type}`
            );
        } else {
            window.location.assign('/device-control')
        }
        errorHandling(error, false);
    }
};
