import React from "react";
import windowsIcon from "../../assets/images/windows.svg";
import androidIcon from "../../assets/images/android.svg";
import Tooltip from "@material-ui/core/Tooltip";

const OSType = (props) => {
  const { os } = props;

  return (
    <div className="os_area">
      {os === "windows" ? (
        <Tooltip title="Windows" placement="right">
          <img width="20" height="20" src={windowsIcon} alt="windowsIcon" />
        </Tooltip>
      ) : (
        <Tooltip title="Android" placement="right">
          <img width="20" height="20" src={androidIcon} alt="androidIcon" />
        </Tooltip>
      )}
    </div>
  );
};

export default OSType;
