import React, { useState, useEffect, useRef } from "react";
import useStyles from "../styles/deviceControlStyle";
import { useTranslation } from "../langs/useTranslation";
import { Typography, Grid, Divider } from "@material-ui/core";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import SelectDevice from "../components/deviceControl/SelectDevice";
import DeviceControlSet from "../components/deviceControl/DeviceControlSet";
import DeviceControWithSwitch from "../components/deviceControl/DeviceControWithSwitch";
import SmallerLoadingIndicator from "../components/commons/SmallerLoadingIndicator";
import * as localforage from "localforage";
import { getDeviceWithTypeApi, getGroupsApi } from "../apis/deviceControlApi";
import { getAllTagsAPI } from "../apis/getAllTagsAPI";
import HasPermission from "../components/commons/HasPermission";
import { deviceControlIcon } from "../helpers/deviceControlIcon";
import ScreenshotDialog from "../components/deviceControl/ScreenshotDialog";
import RestartSystem from "../components/deviceControl/RestartSystem";
import KioskMode from "../components/deviceControl/KioskMode";
import OraUpdate from "../components/deviceControl/OraUpdate";
import Rotation from "../components/deviceControl/Rotation";
import VncSetting from "../components/deviceControl/VncSetting";
import DeployFile from "../components/deviceControl/DeployFile";
import IoSetUp from "../components/deviceControl/IoSetUp";
import VncFailedDialog from "../components/device/VncFailedDialog";
import useStateOnMounted from "../helpers/useStateOnMounted";
import TextNoty from "../helpers/textNoty";
import reject from "lodash/reject";
import { ContactSupportOutlined } from "@material-ui/icons";

const DeviceControl = () => {
    const mounted = useRef();
    const { t } = useTranslation("deviceControl");
    const classes = useStyles();
    const {
        lockUsbPortGray,
        lockCombinationKeyGray,
        blockWindowsNotifyGray,
        brightnessGray,
        volumeGray,
        transformGray,
        lockUsbPortBlack,
        lockCombinationKeyBlack,
        blockWindowsNotifyBlack,
        brightnessBlack,
        volumeBlack,
        transformBlack,
        remoteDesktopBlack,
        remoteDesktopGray,
        deployFileGray,
        deployFileBlack,
        updateOraBlack,
        updateOraGray,
        rotationBlack,
        rotationGray,
        kioskModeBlack,
        kioskModeGray,
        restartSystemBlack,
        restartSystemGray,
        ioSetBlack,
        ioSetGray,
    } = deviceControlIcon;

    const [groupId, setGroupId] = useState("");
    const [checkAll, setCheckAll] = useState(false);
    const [tagId, setTagId] = useState("");
    const [loading, setLoading] = useState(false);
    const [noGroup, setNoGroup] = useState(true);
    const [noTag, setNoTag] = useState(true);
    const [selectDevices, setSelectDevices] = useState([]);
    const [selectDevice, setSelectDevice] = useState();
    const [checkedUsbDrives, setCheckedUsbDrives] = useState(false);
    const [checkedFunctionKeys, setCheckedFunctionKey] = useState(false);
    const [checkedWindowsNotify, setCheckedWindowsNotify] = useState(false);
    const [selectType, setSelectType] = useState("company");
    const [checkedBrightness, setCheckedBrightness] = useState(false);
    const [checkedVolumes, setCheckedVolumes] = useState(false);
    // const [checkedWatchdog, setCheckedWatchdog] = useState(false);
    const [sliderBrightness, setSliderBrightness] = useState(0);
    const [sliderVolumes, setSliderVolumes] = useState(0);
    const [devices, setDevices] = useState([]);
    const [groups, setGroups] = useState([]);
    const [numType, setNumType] = useState("single");
    const [selectCompany, setSelectCompany] = useState("");
    const [selectCompanyName, setSelectCompanyName] = useState("");
    const [showDialog, setShowDialog] = useState(false);
    const [dialogContent, setDialogContent] = useState("");
    const [openScreenshot, setOpenScreenshot] = useState(false);
    const [openVNC, setOpenVNC] = useState(false);
    const [showRestartSystem, setShowRestartSystem] = useState(false);
    const [showKioskMode, setShowKioskMode] = useState(false);
    const [showOraUpdate, setShowOraUpdate] = useState(false);
    const [showRotation, setShowRotation] = useState(false);
    const [showVncSetting, setShowVncSetting] = useState(false);
    const [showDeloyFile, setShowDeloyFile] = useState(false);
    const [showIoSetUp, setShowIoSetUp] = useState(false);
    const pageHandler = React.useMemo((newPage) => {
        let pagesCalled = {1: false}
        return {
            isCalled: (page) => {
                console.log(`page: ${page}, called: ${pagesCalled[page]}`)
                return pagesCalled[page];
            },
            callPage: (page) => {
                pagesCalled[page] = true;
            },
            reset: () => {
                pagesCalled = {}
            },
            addPage: (page) => {
                pagesCalled[page] = false;
            }
        }
    }, [])
    const [pages, setPages] = useState(pageHandler.page);
    const [open, setOpen] = useState(false);
    const [allTags, setAllTags] = useState([]);
    const record = 5;
    // eslint-disable-next-line no-unused-vars
    const [VNCLink, setVNCLink] = useStateOnMounted("");
    // eslint-disable-next-line no-unused-vars
    const [VNCExpiredDate, setVNCExpiredDate] = useStateOnMounted();
    const attributes = {
        selectDevices,
        selectType,
        selectCompany,
        selectDevice,
        groupId,
        devices,
        numType,
        tagId,
        checkAll,
    };

    const deviceControlWithSwitch = [
        {
            permission: "multiple_remote_desktop",
            type: "remoteDesktop",
            imgSrc: { Black: remoteDesktopBlack, Gray: remoteDesktopGray },
            notAllowGroup: true,
            showDialog: showDialog,
            setVNCLink: setVNCLink,
            setVNCExpiredDate: setVNCExpiredDate,
            setDialogContent: setDialogContent,
            handleVNCFailedDialogOpen: () => setOpenVNC(true),
        },
        {
            permission: "multiple_lock_usb_drives",
            type: "lockUsbDrives",
            imgSrc: { Black: lockUsbPortBlack, Gray: lockUsbPortGray },
            notAllowAndroid: true,
            checked: checkedUsbDrives,
            setChecked: setCheckedUsbDrives,
        },
        {
            permission: "multiple_lock_function_keys",
            type: "lockFunctionKey",
            imgSrc: { Black: lockCombinationKeyBlack, Gray: lockCombinationKeyGray },
            notAllowAndroid: true,
            checked: checkedFunctionKeys,
            setChecked: setCheckedFunctionKey,
        },
        {
            permission: "multiple_block_windows_notify",
            type: "blockWindowsNotify",
            imgSrc: { Black: blockWindowsNotifyBlack, Gray: blockWindowsNotifyGray },
            notAllowAndroid: true,
            checked: checkedWindowsNotify,
            setChecked: setCheckedWindowsNotify,
        },
        {
            permission: "multiple_brightness_control",
            type: "brightness",
            imgSrc: { Black: brightnessBlack, Gray: brightnessGray },
            slider: sliderBrightness,
            setSlider: setSliderBrightness,
            checked: checkedBrightness,
            setChecked: setCheckedBrightness,
        },
        {
            permission: "multiple_volume_control",
            type: "volumes",
            imgSrc: { Black: volumeBlack, Gray: volumeGray },
            slider: sliderVolumes,
            setSlider: setSliderVolumes,
            checked: checkedVolumes,
            setChecked: setCheckedVolumes,
        },
    ];

    const deviceControlSets = [
        {
            permission: "multiple_deploy_file",
            type: "deployFile",
            imgSrc: { Black: deployFileBlack, Gray: deployFileGray },
            clickHandler: () => clickHandler("deployFile"),
        },
        {
            permission: "multiple_io_set_up",
            type: "ioSetUp",
            imgSrc: { Black: ioSetBlack, Gray: ioSetGray },
            clickHandler: () => clickHandler("ioSetUp"),
        },
        {
            permission: "multiple_rotation",
            type: "rotation",
            imgSrc: { Black: rotationBlack, Gray: rotationGray },
            clickHandler: () => clickHandler("rotation"),
        },
        {
            permission: "multiple_update_ora",
            type: "oraUpdate",
            imgSrc: { Black: updateOraBlack, Gray: updateOraGray },
            clickHandler: () => clickHandler("oraUpdate"),
        },
        {
            permission: "multiple_kiosk_mode",
            type: "kioskMode",
            imgSrc: { Black: kioskModeBlack, Gray: kioskModeGray },
            clickHandler: () => clickHandler("kioskMode"),
        },
        {
            permission: "multiple_restart_system",
            type: "restartSystem",
            imgSrc: { Black: restartSystemBlack, Gray: restartSystemGray },
            clickHandler: () => clickHandler("restartSystem"),
        },
        {
            permission: "multiple_screenshot",
            type: "screenshot",
            imgSrc: { Black: transformBlack, Gray: transformGray },
            clickHandler: () => clickHandler("screenshot"),
        },
    ];

    const clear = (company = false) => {
        setCheckedVolumes(false);
        setCheckedBrightness(false);
        setCheckedWindowsNotify(false);
        setCheckedFunctionKey(false);
        setCheckedUsbDrives(false);
        setSelectDevices([]);
        setSliderBrightness(0);
        setSliderVolumes(0);
        if (!company) {
            setSelectCompany("");
            setSelectCompanyName("");
        }
    };

    const clickHandler = async (type) => {
        setOpen(false);
        // 單機
        if (numType === "single") {
            // 沒選
            if (!selectDevice) {
                TextNoty(t("noty.noSelectDevice"));
                return;
            }
        }
        // 多機
        else {
            // 沒選
            if (selectDevices.length === 0) {
                TextNoty(t("noty.noSelectDevice"));
                return;
            }
            // 不允許多機
            if (
                selectDevices.length > 1 &&
                (type === "remoteTerminal" || type === "remoteDesktop")
            ) {
                TextNoty(t("noty.forSingleDevice"));
                return;
            }
        }
        switch (type) {
            case "deployFile":
                setShowDeloyFile(true);
                break;
            case "ioSetUp":
                setShowIoSetUp(true);
                break;
            case "vncSetting":
                setShowVncSetting(true);
                break;
            case "screenshot":
                setOpenScreenshot(true);
                break;
            case "restartSystem":
                setShowRestartSystem(true);
                break;
            case "kioskMode":
                setShowKioskMode(true);
                break;
            case "rotation":
                setShowRotation(true);
                break;
            case "oraUpdate":
                setShowOraUpdate(true);
                break;
            default:
                setShowDialog(true);
                break;
        }
    };

    const getDevices = async (page, id, company, reset = false, tagId) => {
        if(pageHandler.isCalled(page)){
            setLoading(false)
        } else {
            const response = await getDeviceWithTypeApi(
                page,
                record,
                id,
                company,
                tagId
            );
            pageHandler.callPage(page);
            const data = response?.devices;
            if (data) {
                let allOptions;
                if (reset) {
                    setPages(1);
                    pageHandler.reset();
                    setDevices([]);
                    allOptions = reject([].concat(data), ["id", "loading"]);
                } else {
                    allOptions = reject(devices.concat(data), ["id", "loading"]);
                }
                if (!response.should_scroll) {
                    if (devices.some((e) => e.id === "nomore")) {
                        setDevices([...allOptions]);
                        setLoading(false);
                        return;
                    } else {
                        const p = page + 1;
                        pageHandler.addPage(p)
                        setPages(p);
                        setDevices([
                            ...allOptions,
                            { id: "nomore", serial_number: "No More Options", disabled: true },
                        ]);
                        setLoading(false);
                    }
                } else {
                    const p = page + 1;
                    pageHandler.addPage(p)
                    setPages(p);
                    setDevices([
                        ...allOptions,
                        { id: "loading", serial_number: "Loading...", disabled: true },
                    ]);
                    setLoading(false);
                }
            } else {
                setDevices([]);
                setLoading(false);
            }
        }
    };

    const handleVNCFailedDialogClose = async () => {
        if (selectDevices && selectDevices.length > 0) {
            await localforage.removeItem(`${selectDevices[0]}VNC`);
        }
        setOpenVNC(false);
        setVNCLink();
        setVNCExpiredDate();
    };

    const getGroups = async () => {
        setLoading(true);
        const response = await getGroupsApi();
        if (response && response.length > 0) {
            setGroups(response);
            setGroupId(response[0].id);
            setNoGroup(false);
            setLoading(false);
        } else {
            setNoGroup(true);
            setLoading(false);
        }
    };

    const getAllTags = async () => {
        setLoading(true);
        let res = await getAllTagsAPI(true);
        if (res?.tags?.length > 0) {
            setAllTags(res.tags);
            setTagId(res.tags[0].id);
            setNoTag(false);
        } else {
            setNoTag(true);
            setLoading(false);
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            getGroups();
            getAllTags();
            mounted.current = true;
        }
    });

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="deviceControl" />
            <div className={classes.haveSubTitle}>
                <Typography variant="h4" className={classes.pageTitle}>
                    {t("common.pageTitle")}
                </Typography>
                {/* Subtitle */}
                <div className="flex_column">
                    <div className="pageSubTitle">{t("common.subtitle1")}</div>
                    <div className="pageSubTitle">
                        <span>{t("common.subtitleTop2")}</span>
                        <span style={{ fontWeight: "bold" }}>
                            {t("common.subtitleCenter2")}
                        </span>
                        <span>{t("common.subtitleBottom2")}</span>
                    </div>
                    <div className="pageSubTitle">
                        <span>{t("common.subtitleTop3")}</span>
                        <span style={{ fontWeight: "bold" }}>
                            {t("common.subtitleCenter3")}
                        </span>
                        <span>{t("common.subtitleBottom3")}</span>
                    </div>
                </div>
            </div>
            {loading ? (
                <SmallerLoadingIndicator />
            ) : (
                <>
                    {/* Select Device Methods */}
                    <SelectDevice
                        selectDevices={selectDevices}
                        setSelectDevices={setSelectDevices}
                        groupId={groupId}
                        setGroupId={setGroupId}
                        tagId={tagId}
                        setTagId={setTagId}
                        clear={clear}
                        setSelectType={setSelectType}
                        checkAll={checkAll}
                        setCheckAll={setCheckAll}
                        selectCompanyName={selectCompanyName}
                        setSelectCompanyName={setSelectCompanyName}
                        setSelectDevice={setSelectDevice}
                        selectDevice={selectDevice}
                        selectType={selectType}
                        selectCompany={selectCompany}
                        setSelectCompany={setSelectCompany}
                        getDevices={getDevices}
                        groups={groups}
                        noGroup={noGroup}
                        noTag={noTag}
                        setDevices={setDevices}
                        allTags={allTags}
                        pages={pages}
                        setOpen={setOpen}
                        open={open}
                        setPages={setPages}
                        devices={devices}
                        numType={numType}
                        setNumType={setNumType}
                    />
                </>
            )}
            <Divider className={classes.divider} />
            {/* Device Control Sets loop */}
            <Grid container spacing={4} className={classes.deviceControlMain}>
                {deviceControlSets.map((e) => (
                    <HasPermission permission={e.permission} key={e.permission}>
                        <DeviceControlSet
                            type={e.type}
                            imgSrc={e.imgSrc}
                            notAllowAndroid={e.notAllowAndroid}
                            notAllowGroup={e.notAllowGroup}
                            clickHandler={e.clickHandler}
                            {...attributes}
                        />
                    </HasPermission>
                ))}
                {deviceControlWithSwitch.map((e) => (
                    <HasPermission permission={e.permission} key={e.permission}>
                        <DeviceControWithSwitch
                            type={e.type}
                            imgSrc={e.imgSrc}
                            slider={e.slider}
                            setSlider={e.setSlider}
                            checked={e.checked}
                            setChecked={e.setChecked}
                            setOpen={setOpen}
                            notAllowAndroid={e.notAllowAndroid}
                            notAllowGroup={e.notAllowGroup}
                            setVNCLink={e.setVNCLink}
                            setVNCExpiredDate={e.setVNCExpiredDate}
                            setDialogContent={e.setDialogContent}
                            handleVNCFailedDialogOpen={e.handleVNCFailedDialogOpen}
                            {...attributes}
                        />
                    </HasPermission>
                ))}
            </Grid>
            <VncFailedDialog
                open={openVNC}
                onClose={handleVNCFailedDialogClose}
                dialogContent={dialogContent}
            />
            <ScreenshotDialog
                numType={numType}
                open={openScreenshot}
                selectType={selectType}
                onClose={() => setOpenScreenshot(false)}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <VncSetting
                numType={numType}
                open={showVncSetting}
                setOpen={setShowVncSetting}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <IoSetUp
                numType={numType}
                open={showIoSetUp}
                setOpen={setShowIoSetUp}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <DeployFile
                numType={numType}
                open={showDeloyFile}
                setOpen={setShowDeloyFile}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <Rotation
                numType={numType}
                open={showRotation}
                setOpen={setShowRotation}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <OraUpdate
                numType={numType}
                open={showOraUpdate}
                setOpen={setShowOraUpdate}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <RestartSystem
                numType={numType}
                open={showRestartSystem}
                setOpen={setShowRestartSystem}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
            <KioskMode
                numType={numType}
                open={showKioskMode}
                setOpen={setShowKioskMode}
                selectType={selectType}
                selectDevices={selectDevices}
                selectDevice={selectDevice}
                groupId={groupId}
                tagId={tagId}
                checkAll={checkAll}
                selectCompany={selectCompany}
            />
        </div>
    );
};

export default DeviceControl;
