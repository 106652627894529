import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import { Typography, Paper, Grid } from "@material-ui/core";
import useStyles from "../../styles/deviceControlStyle";
import AntSwitch from "./AntSwitch";
import ConfirmDialog from "./ConfirmDialog";
import TextNoty from "../../helpers/textNoty";
import Slider from "@material-ui/core/Slider";
import { currentDateInUTC } from "../../helpers/datetimeHandlers";
import LoadingIndicator from "../commons/LoadingIndicator";
import {
    blockWindowsNotifyApi,
    usbLockApi,
    lockCombinationKeyApi,
    volumeApi,
    brightnessApi,
} from "../../apis/deviceControlApi";
import { callVncAPI } from "../../helpers/vncLinkHandler";

const DeviceControlSet = (props) => {
    const [showDialog, setShowDialog] = useState(false);
    const [oldSlider, setOldSlider] = useState(0);
    const [action, setAction] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const classes = useStyles();
    const {
        selectType,
        type,
        selectDevices,
        selectDevice,
        notAllowGroup,
        checked,
        setChecked,
        imgSrc,
        slider,
        setSlider,
        groupId,
        tagId,
        selectCompany,
        notAllowAndroid,
        numType,
        setVNCLink,
        setVNCExpiredDate,
        setDialogContent,
        handleVNCFailedDialogOpen,
        setOpen,
        checkAll
    } = props;
    const { t } = useTranslation("deviceControl");

    const sliderHandler = (event, newValue) => {
        setSlider(newValue);
    };

    const clickHandler = async () => {
        setOpen(false);
        // 單機
        if (numType === "single") {
            // android不行
            let androidValid;
            if (notAllowAndroid) {
                if (selectDevice) {
                    androidValid = selectDevice.os_type === "android";
                }
            }
            if (androidValid) {
                TextNoty(t("noty.noSupportAndroid"));
                return;
            }
            // 沒選
            if (!selectDevice) {
                TextNoty(t("noty.noSelectDevice"));
                return;
            }
        }
        // 多機
        else {
            // android不行
            let androidValid;
            if (notAllowAndroid) {
                if (selectDevices.length > 0) {
                    androidValid = !!selectDevices.find((a) => a.os_type === "android");
                }
            }
            if (androidValid) {
                TextNoty(t("noty.noSupportAndroid"));
                return;
            }
            // 沒選
            if (selectDevices.length === 0) {
                TextNoty(t("noty.noSelectDevice"));
                return;
            }
            // 不允許多機
            if (
                selectDevices.length > 1 &&
                (type === "remoteTerminal" || type === "remoteDesktop")
            ) {
                TextNoty(t("noty.forSingleDevice"));
                return;
            }
        }
        setShowDialog(true);
    };

    const blurHandler = (a, b) => {
        setAction("slider");
        setShowDialog(true);
    };

    const onCancel = () => {
        setShowDialog(false);
        if (action === "slider") {
            setSlider(oldSlider);
        }
        setAction("");
    };

    const onConfirm = async () => {
        const successText = "dialog.successText";
        if (action === "slider") {
            if (slider) {
                switch (type) {
                    case "volumes":
                        await volumeApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            true,
                            "volume",
                            slider,
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(true);
                                setOldSlider(slider);
                            } else {
                                setSlider(oldSlider);
                            }
                        });
                        break;
                    case "brightness":
                        await brightnessApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            true,
                            "brightness",
                            slider,
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(true);
                                setOldSlider(slider);
                            } else {
                                setSlider(oldSlider);
                            }
                        });
                        break;
                    default:
                        break;
                }
            } else if (slider === 0) {
                switch (type) {
                    case "volumes":
                        await volumeApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            false,
                            "volume",
                            slider,
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(false);
                                setOldSlider(slider);
                            } else {
                                setSlider(oldSlider);
                            }
                        });
                        break;
                    case "brightness":
                        await brightnessApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            false,
                            "brightness",
                            slider,
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(false);
                                setOldSlider(slider);
                            } else {
                                setSlider(oldSlider);
                            }
                        });
                        break;
                    default:
                        break;
                }
            }
            setAction("");
        } else {
            if (setSlider) {
                if (checked === false) {
                    switch (type) {
                        case "volumes":
                            await volumeApi(
                                selectType,
                                selectCompany,
                                checkAll
                                    ? undefined
                                    : numType === "single"
                                        ? [selectDevice]
                                        : selectDevices,
                                groupId,
                                tagId,
                                !checked,
                                "volume",
                                30,
                                t,
                                successText
                            ).then((res) => {
                                if (res) {
                                    setSlider(30);
                                    setOldSlider(30);
                                    setChecked(true);
                                }
                            });
                            break;

                        case "brightness":
                            await brightnessApi(
                                selectType,
                                selectCompany,
                                checkAll
                                    ? undefined
                                    : numType === "single"
                                        ? [selectDevice]
                                        : selectDevices,
                                groupId,
                                tagId,
                                !checked,
                                "brightness",
                                30,
                                t,
                                successText
                            ).then((res) => {
                                if (res) {
                                    setSlider(30);
                                    setOldSlider(30);
                                    setChecked(true);
                                }
                            });
                            break;
                        default:
                            break;
                    }
                } else {
                    switch (type) {
                        case "volumes":
                            await volumeApi(
                                selectType,
                                selectCompany,
                                checkAll
                                    ? undefined
                                    : numType === "single"
                                        ? [selectDevice]
                                        : selectDevices,
                                groupId,
                                tagId,
                                !checked,
                                "volume",
                                0,
                                t,
                                successText
                            ).then((res) => {
                                if (res) {
                                    setSlider(0);
                                    setOldSlider(0);
                                    setChecked(false);
                                }
                            });
                            break;
                        case "brightness":
                            await brightnessApi(
                                selectType,
                                selectCompany,
                                checkAll
                                    ? undefined
                                    : numType === "single"
                                        ? [selectDevice]
                                        : selectDevices,
                                groupId,
                                tagId,
                                !checked,
                                "brightness",
                                0,
                                t,
                                successText
                            ).then((res) => {
                                if (res) {
                                    setSlider(0);
                                    setOldSlider(0);
                                    setChecked(false);
                                }
                            });
                            break;

                        default:
                            break;
                    }
                }
            } else {
                switch (type) {
                    case "remoteDesktop":
                        await callVncAPI(
                            numType === "single" ? selectDevice.id : selectDevices[0]?.id,
                            numType === "single" ? selectDevice : selectDevices[0],
                            numType === "single"
                                ? selectDevice.nubis_version
                                : selectDevices[0]?.nubis_version,
                            setIsLoading,
                            setVNCLink,
                            setVNCExpiredDate,
                            setDialogContent,
                            handleVNCFailedDialogOpen,
                            t,
                            "deviceControl"
                        );
                        break;
                    case "lockUsbDrives":
                        await usbLockApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            !checked,
                            "lock_usb",
                            currentDateInUTC(),
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(!checked);
                            }
                        });
                        break;
                    case "lockFunctionKey":
                        await lockCombinationKeyApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            !checked,
                            "disable_combination_key",
                            currentDateInUTC(),
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(!checked);
                            }
                        });
                        break;
                    case "blockWindowsNotify":
                        await blockWindowsNotifyApi(
                            selectType,
                            selectCompany,
                            checkAll
                                ? undefined
                                : numType === "single"
                                    ? [selectDevice]
                                    : selectDevices,
                            groupId,
                            tagId,
                            !checked,
                            "disable_windows_notify",
                            currentDateInUTC(),
                            t,
                            successText
                        ).then((res) => {
                            if (res) {
                                setChecked(!checked);
                            }
                        });
                        break;
                    default:
                        break;
                }
            }
            setAction("");
        }
        setShowDialog(false);
    };
    const selectValidation = () => {
        if (numType === "single") {
            let androidValid;
            if (notAllowAndroid) {
                if (selectDevice) {
                    androidValid = selectDevice.os_type === "android";
                }
            }
            return androidValid || !selectDevice;
        } else {
            let androidValid;
            if (notAllowAndroid) {
                if (selectDevices.length > 0) {
                    androidValid = !!selectDevices.find((a) => a.os_type === "android");
                }
            }
            return (
                androidValid ||
                selectDevices.length === 0 ||
                (notAllowGroup && selectDevices.length > 1)
            );
        }
    };
    return (
        <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={4}
            className={classes.deviceControlSet}
        >
            <Paper
                onClick={() => clickHandler()}
                className={`${classes.controlPaper} ${selectValidation() ? classes.notAllow : ""
                    }`}
            >
                <div className={classes.controlTop}>
                    <div className={classes.controlLeft}>
                        {selectValidation() ? (
                            <imgSrc.Gray className={classes.icon} />
                        ) : (
                            <imgSrc.Black className={classes.icon} />
                        )}
                        <div className={classes.controlTextSet}>
                            <Typography variant="body1">{t(`common.${type}`)}</Typography>
                            {notAllowGroup && (
                                <Typography variant="body2" color="secondary">
                                    {t("common.onlyForSingle")}
                                </Typography>
                            )}
                            {notAllowAndroid && (
                                <Typography variant="body2" color="secondary">
                                    {t("common.onlyForWindows")}
                                </Typography>
                            )}
                        </div>
                    </div>
                    {setChecked && (
                        <AntSwitch
                            disabled={selectValidation()}
                            checked={checked}
                            onChange={clickHandler}
                        />
                    )}
                </div>
                {slider === 0 || slider ? (
                    <div className={classes.slider}>
                        <Slider
                            disabled={selectValidation()}
                            value={slider}
                            onChange={sliderHandler}
                            onChangeCommitted={blurHandler}
                        />
                        <Typography
                            variant="body2"
                            color={selectValidation() ? "secondary" : "primary"}
                            className={classes.sliderText}
                        >
                            {slider}
                        </Typography>
                    </div>
                ) : (
                    ""
                )}
            </Paper>
            <ConfirmDialog
                onClose={() => onCancel()}
                onConfirm={onConfirm}
                open={showDialog}
                type={type}
                setOpen={setShowDialog}
            />
            {isLoading ? (
                <div className={classes.wholePageLoading}>
                    <Typography variant="h6" color="secondary">
                        {t("tasks.connectingToDevice")}
                    </Typography>
                    <Typography variant="caption" color="secondary">
                        {t("tasks.noticeThePopoutWindowBlocker")}
                    </Typography>
                    <Typography variant="caption" color="secondary">
                        {t("tasks.waitVncConnectionTime")}
                    </Typography>
                    <LoadingIndicator />
                </div>
            ) : (
                ""
            )}
        </Grid>
    );
};

export default DeviceControlSet;
