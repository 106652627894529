import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { sortArrByName } from '../helpers/arrayHandlers';

export const getDeviceModelsAPI = async () => {

    const token = await localforage.getItem('token');
    try {
        let response = await axios({
            url: `${url}/api/v2/admin-utility/device-models`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
        })
        const allModelNames = response.data.device_models
        const sortedModelNameArr = sortArrByName(allModelNames)
        return sortedModelNameArr
    } catch (error) {
        errorHandling(error, false);
    }

}
