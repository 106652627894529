import { ReactComponent as LockUsbPortGray } from "../assets/images/taskShortcut/lock_usb_port_gray.svg";
import { ReactComponent as UnlockUsbPortGray } from "../assets/images/taskShortcut/unlock_usb_port_gray.svg";
import { ReactComponent as LockCombinationKeyGray } from "../assets/images/taskShortcut/lock_combination_key_gray.svg";
import { ReactComponent as UnlockCombinationKeyGray } from "../assets/images/taskShortcut/unlock_combination_key_gray.svg";
import { ReactComponent as BlockWindowsNotifyGray } from "../assets/images/taskShortcut/block_Windows_notify_gray.svg";
import { ReactComponent as EnableWindowsNotifyGray } from "../assets/images/taskShortcut/enable_Windows_notify_gray.svg";
import { ReactComponent as PowerOnGray } from "../assets/images/taskShortcut/power_on_gray.svg";
import { ReactComponent as PowerOffGray } from "../assets/images/taskShortcut/power_off_gray.svg";
import { ReactComponent as RebootGray } from "../assets/images/taskShortcut/reboot_gray.svg";
import { ReactComponent as WakeGray } from "../assets/images/taskShortcut/wake_gray.svg";
import { ReactComponent as SleepGray } from "../assets/images/taskShortcut/sleep_gray.svg";
import { ReactComponent as LockUsbPortBlue } from "../assets/images/taskShortcut/lock_usb_port_blue.svg";
import { ReactComponent as UnlockUsbPortBlue } from "../assets/images/taskShortcut/unlock_usb_port_blue.svg";
import { ReactComponent as LockCombinationKeyBlue } from "../assets/images/taskShortcut/lock_combination_key_blue.svg";
import { ReactComponent as UnlockCombinationKeyBlue } from "../assets/images/taskShortcut/unlock_combination_key_blue.svg";
import { ReactComponent as BlockWindowsNotifyBlue } from "../assets/images/taskShortcut/block_Windows_notify_blue.svg";
import { ReactComponent as EnableWindowsNotifyBlue } from "../assets/images/taskShortcut/enable_Windows_notify_blue.svg";
import { ReactComponent as PowerOnBlue } from "../assets/images/taskShortcut/power_on_blue.svg";
import { ReactComponent as PowerOffBlue } from "../assets/images/taskShortcut/power_off_blue.svg";
import { ReactComponent as RebootBlue } from "../assets/images/taskShortcut/reboot_blue.svg";
import { ReactComponent as WakeBlue } from "../assets/images/taskShortcut/wake_blue.svg";
import { ReactComponent as SleepBlue } from "../assets/images/taskShortcut/sleep_blue.svg";
import { ReactComponent as LockUsbPortRed } from "../assets/images/taskShortcut/lock_usb_port_red.svg";
import { ReactComponent as UnlockUsbPortRed } from "../assets/images/taskShortcut/unlock_usb_port_red.svg";
import { ReactComponent as LockCombinationKeyRed } from "../assets/images/taskShortcut/lock_combination_key_red.svg";
import { ReactComponent as UnlockCombinationKeyRed } from "../assets/images/taskShortcut/unlock_combination_key_red.svg";
import { ReactComponent as BlockWindowsNotifyRed } from "../assets/images/taskShortcut/block_Windows_notify_red.svg";
import { ReactComponent as EnableWindowsNotifyRed } from "../assets/images/taskShortcut/enable_Windows_notify_red.svg";
import { ReactComponent as PowerOnRed } from "../assets/images/taskShortcut/power_on_red.svg";
import { ReactComponent as PowerOffRed } from "../assets/images/taskShortcut/power_off_red.svg";
import { ReactComponent as RebootRed } from "../assets/images/taskShortcut/reboot_red.svg";
import { ReactComponent as WakeRed } from "../assets/images/taskShortcut/wake_red.svg";
import { ReactComponent as SleepRed } from "../assets/images/taskShortcut/sleep_red.svg";

export const taskShortIcons = {
    LockUsbPortGray,
    UnlockUsbPortGray,
    LockCombinationKeyGray,
    UnlockCombinationKeyGray,
    BlockWindowsNotifyGray,
    EnableWindowsNotifyGray,
    PowerOnGray,
    PowerOffGray,
    RebootGray,
    WakeGray,
    SleepGray,
    LockUsbPortBlue,
    UnlockUsbPortBlue,
    LockCombinationKeyBlue,
    UnlockCombinationKeyBlue,
    BlockWindowsNotifyBlue,
    EnableWindowsNotifyBlue,
    PowerOnBlue,
    PowerOffBlue,
    RebootBlue,
    WakeBlue,
    SleepBlue,
    LockUsbPortRed,
    UnlockUsbPortRed,
    LockCombinationKeyRed,
    UnlockCombinationKeyRed,
    BlockWindowsNotifyRed,
    EnableWindowsNotifyRed,
    PowerOnRed,
    PowerOffRed,
    RebootRed,
    WakeRed,
    SleepRed,
};
