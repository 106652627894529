import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@material-ui/core";
import useStyles from "../../styles/alarmNotificationStyle";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import { AddBoxRounded, AutorenewRounded } from "@material-ui/icons";
import AddOrEditDeploymentDialog from "./AddOrEditDeploymentDialog";
import RemoveDeploymentDialog from "./RemoveDeploymentDialog";
import { table } from "../../helpers/alarmDeploymentDatatable";
import HasPermission from "../commons/HasPermission";

const DeploymentList = () => {
    const mounted = useRef();
    const classes = useStyles();
    const { t } = useTranslation("alarmNotification");
    const [editDialigOpen, setEditDialogOpen] = useState(false);
    const [removeDialigOpen, setRemoveDialogOpen] = useState(false);
    const [createOrUpdate, setCreateOrUpdate] = useState();
    const [removedPolicyData, setRemovedPolicyData] = useState();
    const [updatedDeviceData, setUpdatedDeviceData] = useState({
        deviceId: "",
        ruleIds: [],
    });

    const handleReloadDataTable = () => {
        table(t, classes, handleEditDialogOpen, handleRemoveDialogOpen);
    };

    const handleEditDialogOpen = (createOrUpdate, deviceId, ruleIds) => {
        setCreateOrUpdate(createOrUpdate);
        setEditDialogOpen(true);
        setUpdatedDeviceData({ deviceId: deviceId, ruleIds: ruleIds });
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        table(t, classes, handleEditDialogOpen, handleRemoveDialogOpen);
    };

    const handleRemoveDialogOpen = (removedPolicyData) => {
        setRemoveDialogOpen(true);
        setRemovedPolicyData(removedPolicyData);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
        table(t, classes, handleEditDialogOpen, handleRemoveDialogOpen);
    };

    useEffect(() => {
        if (!mounted.current) {
            table(t, classes, handleEditDialogOpen, handleRemoveDialogOpen);
            mounted.current = true;
        }
    });

    return (
        <div>
            <Typography variant="h5" className={classes.tableTitle}>
                {t("table.deploymentList")}
                <AutorenewRounded
                    onClick={handleReloadDataTable}
                    size="small"
                    className={classes.reload_btn}
                />
                <HasPermission permission="alarm_create_deployment_list">
                    <AddBoxRounded
                        onClick={() => handleEditDialogOpen("create")}
                        size="small"
                        className={classes.listBtn}
                    />
                </HasPermission>
            </Typography>
            <HOCforFullWidthTable>
                <table
                    id="deployment-table"
                    className="mdl-data-table dataTable no-footer dtr-inline"
                >
                    <thead>
                        <tr>
                            <th className="sorting_desc">{t("table.serialNumber")}</th>
                            <th className="sorting">
                                {t("table.ruleDescription")} - {t("table.status")} -{" "}
                                {t("table.deployedDate")}
                            </th>
                            <th className="sorting">{t("table.owners")}</th>
                            <th className="sorting">{t("table.action")}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
            <RemoveDeploymentDialog
                open={removeDialigOpen}
                onClose={handleRemoveDialogClose}
                removedPolicyData={removedPolicyData}
            />
            <AddOrEditDeploymentDialog
                updatedDeviceData={updatedDeviceData}
                open={editDialigOpen}
                onClose={handleEditDialogClose}
                createOrUpdate={createOrUpdate}
            />
        </div>
    );
};

export default DeploymentList;
