export const taskResendMap = newVnc => {
    return (
        [
            { taskName: "deploy_file", resendTaskName: "redeploy-file" },
            { taskName: "restart_system", resendTaskName: "restart-system-resend" },
            { taskName: "kiosk_mode", resendTaskName: "kiosk-mode-resend" },
            { taskName: "ora_update", resendTaskName: "ora-update-resend" },
            { taskName: "screen_rotation", resendTaskName: "screen-rotation-resend" },
            { taskName: "screenshot", resendTaskName: "screenshot-resend" },
            { taskName: "io_status_detect", resendTaskName: "io-status-detect-resend" },
            { taskName: "set_io_voltage", resendTaskName: "io-set-resend" },
            { taskName: "vnc_connect", resendTaskName: newVnc ? "reobtain-vnc-new" : "reobtain-vnc" },
            { taskName: "bios_update", resendTaskName: "bios-firmware-update-resend" },
            { taskName: "firmware_update", resendTaskName: "bios-firmware-update-resend" },
            { taskName: "device_peripheral_detect", resendTaskName: "device-peripheral-detect-resend" },
            { taskName: "device_peripheral_update", resendTaskName: "device-peripheral-update-resend" }
        ]
    )
}
