import React, { useEffect, useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from "localforage";
import { Dropdown } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { getDriveStorageFilesAPI } from "../../apis/getDriveStorageFilesAPI";
import reject from "lodash/reject";

const SelectFileFromNubisDrive = (props) => {
  const { t } = useTranslation("task");
  const {
    tableDisabled,
    storedFileToLocalforageName,
    setFile,
    setDriveStorageId,
  } = props;
  const [page, setPage] = useState(1);
  const [options, setOptions] = useState([]);
  const record = 20;

  const addOptions = () => {
    setTimeout(() => {
      setPage(page + 1);
    }, 500);
  };

  const handleChange = async (e, { value }) => {
    // Prevents React from resetting its properties:
    // ref: https://reactjs.org/docs/legacy-event-pooling.html
    e.persist();

    if (e) {
      setFile({ id: value, name: e.target.innerText });
      setDriveStorageId(value);
      await localforage.setItem(storedFileToLocalforageName, value);
    }
  };

  const handleScroll = () => {
    let element = document.querySelector(".visible.menu.transition");

    if (element) {
      const scrollTop = element.scrollTop;
      const elementHeight = element.clientHeight;
      const heightOfList = element.scrollHeight;
      const ifReachListBottom =
        heightOfList - Math.ceil(scrollTop) <= elementHeight;
      if (ifReachListBottom) {
        addOptions();
      }
    }
  };

  const getOptions = async () => {
    const response = await getDriveStorageFilesAPI("name", "asc", page, record);
    const files = response.files;
    if (files.length > 0) {
      const allOptions = reject(options.concat(files), ["id", "loading"]);
      setOptions([
        ...allOptions,
        response.should_scroll
          ? { id: "loading", name: "Loading...", disabled: true }
          : { id: "nomore", name: "No More Options", disabled: true },
      ]);
    }
  };

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  return (
    <div className="needDefault">
      <Dropdown
        placeholder={t("task.selectFileDefaultValue")}
        clearable
        fluid
        search
        selection
        lazyLoad
        disabled={tableDisabled}
        onChange={handleChange}
        onScroll={handleScroll}
        options={options.map((o, i) => ({
          key: i,
          value: o.id,
          text: o.name,
          disabled: o.disabled,
        }))}
      />
    </div>
  );
};

export default SelectFileFromNubisDrive;
