import React from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel, Grid, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import { useTranslation } from "../../langs/useTranslation";

const SelectDate = (props) => {
    const { t } = useTranslation('task');
    const classes = useStyles();

    return(
        <div className={classes.radioSelectGroup}> 
            <FormControl component="div" fullWidth={true} >
                <RadioGroup aria-label="date" name={props.formikName} value={props.formikValue} onChange={props.formikOnChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <FormControl>
                                <FormControlLabel value="on" label={`${t('task.modeOn')}`} control={<Radio color="primary" />} />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={4}>
                            <FormControl>
                                <FormControlLabel value="off" label={`${t('task.modeOff')}`} control={<Radio color="primary" />} />
                            </FormControl>
                        </Grid>
                    </Grid>
                </RadioGroup>
                <FormHelperText hidden={!props.formikError} error={!!props.formikError}>
                    {props.formikError}
                </FormHelperText>
            </FormControl>
        </div>    
    )
}

export default SelectDate;