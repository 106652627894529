import React from 'react';
import { Button, Radio, RadioGroup, FormControl, FormControlLabel, Grid, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import FileUpload from './FileUpload';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useTranslation } from "../../langs/useTranslation";
import ExcelTemplate from '../../assets/excel/template.xlsx'
import FilterSelectionForTask from './FilterSelectionForTask';

const SelectDevices = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('task')
    const { 
            taskType, // only used when vnc setting 'vncSecuritySetting'
            formikName, 
            formikValue, 
            formikOnChange, 
            isSubmitting, 
            formikError, 
            port, // required if relationName = 'ioVoltages'
            relationName, // options: 'ioVoltages', 'deviceModel', 'alarmDeployment'
            deviceModelIds, // required if relationName = 'deviceModel'
            storedGroupIdsAndTagIds, // if true: need two props below, 'storedGroupIdsToLocalforageName' and 'storedTagIdsToLocalforageName'
            storedGroupIdsToLocalforageName, // required if storedGroupIdsAndTagIds = 'true'
            storedTagIdsToLocalforageName,  // required if storedGroupIdsAndTagIds = 'true'
            storedDeviceValueToLocalforageName, // localforage keyname of stored file
            storedDeviceIdsToLocalforageName, // localforage keyname of stored device Ids
            setSelectedIds // updating state of selectedIds and decide if showing 'required' text after datatable rendered completely or file uploaded
        } = props;

    return(
        <div className={classes.radioSelectGroup}> 
            <FormControl component="div" fullWidth={true} required>
                <RadioGroup aria-label="device" name={formikName} value={formikValue} onChange={formikOnChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl>
                                <FormControlLabel value="filter_by_group" label={`${t('task.filterByGroup')}`} control={<Radio color="primary" />} />
                                <FilterSelectionForTask 
                                    taskType={taskType}
                                    filterDevicesBy="filter_by_group"
                                    deviceModelIds={deviceModelIds}
                                    tableDisabled={formikValue === "filter_by_group" ? false : true} 
                                    localforageKeyName={storedGroupIdsAndTagIds ? storedGroupIdsToLocalforageName : storedDeviceValueToLocalforageName} 
                                    formikSumbitted={isSubmitting ? true : false}
                                    storedDeviceIdsToLocalforageName={!taskType ? storedDeviceIdsToLocalforageName : storedDeviceValueToLocalforageName}
                                    relationName={relationName} 
                                    port={port}
                                    setSelectedIds={setSelectedIds}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl>
                                <FormControlLabel value="filter_by_tag" label={`${t('task.filterByTag')}`} control={<Radio color="primary" />} />
                                <FilterSelectionForTask 
                                    taskType={taskType}
                                    filterDevicesBy="filter_by_tag"
                                    deviceModelIds={deviceModelIds}
                                    tableDisabled={formikValue === "filter_by_tag" ? false : true}  
                                    localforageKeyName={storedGroupIdsAndTagIds ? storedTagIdsToLocalforageName : storedDeviceValueToLocalforageName}
                                    formikSumbitted={isSubmitting ? true : false}
                                    storedDeviceIdsToLocalforageName={!taskType ? storedDeviceIdsToLocalforageName : storedDeviceValueToLocalforageName}
                                    relationName={relationName} 
                                    port={port}
                                    setSelectedIds={setSelectedIds}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl>
                                <FormControlLabel value="excel_import" label={`${t('task.excelImport')}`} control={<Radio color="primary" />} />
                                <FileUpload 
                                    setSelectedIds={setSelectedIds}
                                    tableDisabled={formikValue === "excel_import" ? false : true}  
                                    storedFileToLocalforageName={storedDeviceValueToLocalforageName}
                                />
                                <Button 
                                    variant="text" 
                                    color="secondary" 
                                    href={ExcelTemplate}  
                                    className={classes.downloadTemplateBtn} 
                                    startIcon={<GetAppRoundedIcon />}
                                >
                                {t('task.downloadTemplate')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </RadioGroup>
                <FormHelperText hidden={!formikError} error={!!formikError}>
                    {formikError}
                </FormHelperText>
            </FormControl>
        </div>    
    )
}

export default SelectDevices;