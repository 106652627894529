import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import Loading from '../containers/Loading';
import { AuthContext } from '../auths/Auth';
import CommonLayout from '../layouts/private/CommonLayout';
import localforage from 'localforage';

const PrivateRoute = ({ component: Component, ...props }) => {

    // Declare the useContext() so this component will have the same context as AuthContext.Provider
    const { isAuthenticated, isLoading, checkAuth, isLoginFailed } = useContext(AuthContext)
    const [isPermitted, setIsPermitted] = useState(false);
    const permissionName = props.permissionName;

    const checkIsPermitted = async (permissionName) => {
        // check if the user is permitted
        const userPermissionsArray = await localforage.getItem('userPermissions');
        if (permissionName !== 'No Permission' && permissionName !== 'Home' && userPermissionsArray && userPermissionsArray.includes(permissionName)) {
            setIsPermitted(true)
            return true
        }
        if (permissionName === 'No Permission' || permissionName === 'Home') {
            setIsPermitted(true)
            return true
        }
        setIsPermitted(false)
        return false
    }

    useEffect(() => {
        checkAuth('PrivateRoute')
        console.log(permissionName)
        checkIsPermitted(permissionName)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuthenticated])

    return (
        <Route {...props}
            render={props => (
                !isLoading
                    ?
                    (
                        isAuthenticated
                            ?
                            (isPermitted ?
                                <CommonLayout>
                                    <Component  {...props} />
                                </CommonLayout>
                                :
                                <Redirect to='/no-permission' />
                            )
                            : isLoginFailed
                            ? <Redirect to='/login-error'/>
                                : <Redirect to='/login' />
                    )
                    :
                    <Loading />

            )}
            name={props.permissionName}
        />
    )
}

export default PrivateRoute;
