import React, { useState, useEffect, useRef } from 'react';
import { TextField, Grid, Button, Paper, Typography, Box } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import * as localforage from 'localforage';
import { AddRounded, ClearRounded } from '@material-ui/icons';
import { EmailInvalidText, EmailAlreadyExistText, RequiredText } from '../commons/ValidationText';
import { checkIfEmailValid } from '../../helpers/regex';

const ManageEmailForAlarmNotification = props => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { storedEmailToLocalforageName, policyToBeUpdated, emailValueProvided } = props;
    const [email, setEmail] = useState('');
    const [emailList, setEmailList] = useState([]);
    const [emailValid, setEmailValid] = useState(true);
    const [ifAddEmailToList, setIfAddEmailToList] = useState(true);
    const [hasEmail, setHasEmail] = useState(emailValueProvided);

    const showExisitingEmailList = () => {
        setEmailList(policyToBeUpdated.emails);
        storedValueToLocalforage(policyToBeUpdated.emails);
    }

    const handleUpdateEmailTextField = (e) => {
        setEmail(e.target.value);
    }

    const storedValueToLocalforage = async (emailList) => {
        await localforage.setItem(storedEmailToLocalforageName, emailList);
    }

    const handleAddEmailToList = () => {

        // check if email exists already
        const ifAddEmailToList = !emailList.includes(email);
        setIfAddEmailToList(ifAddEmailToList);

        // check if email is valid
        const ifEmailValid = checkIfEmailValid(email);
        setEmailValid(ifEmailValid);

        // if email is valid and needs to be added into list
        if (email && ifAddEmailToList && ifEmailValid) {
            emailList.push(email);
            setEmailList(emailList);
            storedValueToLocalforage(emailList);
            setEmail('');
            setHasEmail(true);
        }
    }

    const handleRemoveEmailFromList = async (emailToBeRemoved) => {

        let existingEmailList = await localforage.getItem(storedEmailToLocalforageName);
        let indexOfItemToBeRemoved = existingEmailList.indexOf(emailToBeRemoved);

        existingEmailList.splice(indexOfItemToBeRemoved, 1);
        setEmailList(existingEmailList);

        // determine if the stored email list should be updated or removed from localforage
        if (existingEmailList.length > 0) {
            storedValueToLocalforage(existingEmailList);
        } else {
            await localforage.removeItem(storedEmailToLocalforageName);
            setHasEmail(false);
        }

    }
    useEffect(() => {
        if (!mounted.current) {
            if (policyToBeUpdated) {
                showExisitingEmailList();
            }
            mounted.current = true;
        }
    })

    useEffect(() => {
        setHasEmail(emailValueProvided);
    }, [emailValueProvided])


    return (
        <Grid container spacing={2} className={classes.emailContainer}>
            <Grid item xs={12} sm={12} md={7} lg={7}>
                <TextField
                    id="email-for-notification"
                    fullWidth
                    value={email}
                    onChange={(e) => handleUpdateEmailTextField(e)}
                    placeholder={t('modal.pleaseEnterEmailForNotification')}
                    InputProps={{
                        endAdornment:
                            <Button
                                variant="text"
                                onClick={handleAddEmailToList}
                                startIcon={<AddRounded color="primary" />}
                                className={classes.btnInTextField}>
                                {t('modal.addEmail')}
                            </Button>
                    }}
                />
                {!emailValid ? <EmailInvalidText /> : ''}
                {!ifAddEmailToList ? <EmailAlreadyExistText /> : ''}
                {hasEmail === false ? <RequiredText /> : ''}
            </Grid>
            <Grid item xs={12} sm={12} md={5} lg={5}>
                <Paper variant="outlined" className={`${classes.marginBottomReset} ${classes.emailListPaper}`}>
                    <Typography variant="caption" color="secondary" display="block">{t('modal.emailList')}</Typography>
                    {emailList.map((o, index) => {
                        return (
                            <Box display="flex" justifyContent="space-between" key={index}>
                                <Typography variant="body2" display="block">{o}</Typography>
                                <ClearRounded color="disabled" onClick={() => handleRemoveEmailFromList(o)} fontSize="small" />
                            </Box>
                        )
                    })}
                </Paper>
            </Grid>
        </Grid>
    )
}

export default ManageEmailForAlarmNotification;
