import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const getAllTagsAPI = async (should_return = false) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let response = await axios({
            url: `${url}/api/v2/tag/all`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id: company_id,
            },
        });
        if (should_return) {
            return response.data
        } else {
            const allTagsData = response.data.tags;
            await localforage.setItem("allTagsData", allTagsData);
        }
    } catch (error) {
        errorHandling(error, false);
    }
};
