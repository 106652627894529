import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, useTheme } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { scheduledDateInLocal } from '../../helpers/datetimeHandlers';
import TaskStatusBar from '../commons/TaskStatusBar';

const DeviceControlLogDetailTable = (props) => {
    const { rows, page, setPage, rowsPerPage } = props;
    const [orderBy, setOrderBy] = useState('created_at');
    const [order, setOrder] = useState('desc');
    const { t } = useTranslation('deviceControl');

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const headCells = [
        {
            id: "serial_number",
            label: t("table.serialNumber")
        },
        {
            id: "created_at",
            label: t("table.createdAt")
        },
        {
            id: "status",
            label: t("table.status")
        },
        {
            id: "response",
            label: t("table.message")
        },
    ]

    const TableHeaders = (props) => {
        const { order, orderBy, onSorting } = props;
        const createSortHandler = (property) => (event) => {
            onSorting(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }


    return (
        <>
            <TableContainer>
                <Table>
                    <TableHeaders
                        onSorting={handleRequestSort}
                    />
                    <TableBody>
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.device.serial_number}>
                                            <TableCell> {row.device.serial_number} </TableCell>
                                            <TableCell> {scheduledDateInLocal(new Date(row["created_at"]))} </TableCell>
                                            <TableCell>
                                                <TaskStatusBar
                                                    status={row['status']}
                                                    isOffline={row.device?.online_offline_status !== 'online'}
                                                />
                                            </TableCell>
                                            <TableCell> {row.response === '' ? <i>{t("table.noMessage")}</i> : row.response} </TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </>
    )
}

export default DeviceControlLogDetailTable;
