import React from 'react';
import { Button } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn } from './FormBtns';
import SendRoundedIcon from '@material-ui/icons/SendRounded';

const ConfirmAndCancelBtn = props => {
    const { t } = useTranslation('common');
    const { styledSubmitBtnOnClick, cancelBtnOnClick, cancelBtnDisabled, hideLoadingIndicator } = props; 

    const handleConfirmsBtnClick = e => {
        if(!e.detail || e.detail === 1) {
            styledSubmitBtnOnClick();
        } 
        
        return false; 
    }
    
    return(
        <div className="MuiDialogActions-root">
            <StyledSubmitBtn 
                btnColor="primary" 
                type={hideLoadingIndicator ? "button" : "submit"}
                onClick={styledSubmitBtnOnClick ? handleConfirmsBtnClick : null} 
                startIcon={<SendRoundedIcon />} 
                btnText={t('btn.confirmBtn')} 
                disabled={hideLoadingIndicator ? cancelBtnDisabled : false}
            />
            <Button 
                onClick={cancelBtnOnClick} 
                color="secondary" 
                variant="contained"
                type="button"
                disabled={cancelBtnDisabled}
            >
                {t('btn.backBtn')}
            </Button>
        </div>
    )
}

export default ConfirmAndCancelBtn;