import React, { useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel } from '@material-ui/core';
import DescriptionIcon from '@material-ui/icons/Description';
import { useTranslation } from "../../langs/useTranslation";
import { scheduledDateInLocal } from '../../helpers/datetimeHandlers';

const DeviceControlLogTable = (props) => {
    const { rows, page, setPage, rowsPerPage, onOpenChild } = props;
    const [orderBy, setOrderBy] = useState('created_at');
    const [order, setOrder] = useState('desc');
    const { t } = useTranslation("deviceControl");

    function descendingComparator(a, b, orderBy) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }

    function getComparator(order, orderBy) {
        return order === 'desc'
            ? (a, b) => descendingComparator(a, b, orderBy)
            : (a, b) => -descendingComparator(a, b, orderBy);
    }

    function stableSort(array, comparator) {
        const stabilizedThis = array.map((el, index) => [el, index]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) return order;
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    }

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const headCells = [
        {
            id: "task_group_id",
            label: t("table.taskGroupId")
        },
        {
            id: "created_at",
            label: t("table.createdAt")
        },
        {
            id: "task_type",
            label: t("table.type")
        },
        {
            id: "total_devices",
            label: t("table.successAndTotal")
        },
        {
            id: "details",
            label: t("table.details")
        }
    ]

    const TableHeaders = (props) => {
        const { order, orderBy, onSorting } = props;
        const createSortHandler = (property) => (event) => {
            onSorting(event, property);
        };

        return (
            <TableHead>
                <TableRow>
                    {headCells.map((headCell) => (
                        <TableCell
                            key={headCell.id}
                            align={headCell.numeric ? 'right' : 'left'}
                            padding={headCell.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === headCell.id ? order : false}
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : 'asc'}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                            </TableSortLabel>
                        </TableCell>
                    ))}
                </TableRow>
            </TableHead>
        )
    }


    return (
        <>
            <TableContainer>
                <Table>
                    <TableHeaders
                        onSorting={handleRequestSort}
                    />
                    <TableBody>
                        {
                            stableSort(rows, getComparator(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row) => {
                                    return (
                                        <TableRow key={row.id}>
                                            <TableCell> {row.id} </TableCell>
                                            <TableCell> {scheduledDateInLocal(new Date(row["created_at"]))} </TableCell>
                                            <TableCell> {row["task_type"].toUpperCase()} </TableCell>
                                            <TableCell>
                                                <div style={
                                                    {
                                                        backgroundColor: (row["success_devices"] < row["total_devices"]) ? '#ff4038' : '#57cc5b',
                                                        borderRadius: '0.5em 0.5em 0.5em 0.5em',
                                                        color: 'white',
                                                        width: '60%',
                                                        textAlign: 'center',
                                                        fontWeight: "bold",
                                                        fontFamily: "Helvetica"
                                                    }}>
                                                    {`${row["success_devices"]} / ${row["total_devices"]}`}
                                                </div>
                                            </TableCell>
                                            <TableCell onClick={() => onOpenChild(row.id)}>
                                                <DescriptionIcon />
                                            </TableCell>
                                        </TableRow>
                                    )
                                })
                        }
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
            />
        </>
    )
}

export default DeviceControlLogTable;
