import React from "react";
import { DateTimePicker } from "@material-ui/pickers";
import "../../css/datePickerThemeReset.css";
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";

const SelectDatePicker = (props) => {
    const { t } = useTranslation("task");
    const { disabled, selectDate, selectDateHandler } = props;
    return (
        <div className="dateTimePicker-wrapper">
            <DateTimePicker
                ampm={false}
                disabled={disabled}
                variant="inline"
                label={`${t("task.selectADate")}`}
                value={selectDate}
                disablePast
                onChange={selectDateHandler}
                format="YYYY/MM/DD HH:mm"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton disabled={disabled}>
                                <AlarmIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
            />
        </div>
    );
};

export default SelectDatePicker;
