import React from 'react';
import '../../css/redirecting.css';

const LoadingIndicator = () => {
    return(
        <div className="loading-wrapper">
            <div className="loadingio-spinner-ellipsis-0e9htm6lpb7">
                <div className="ldio-tk8dyrglbc">
                    <div></div><div></div><div></div><div></div><div></div>
                </div>
            </div>
        </div>
    )
}

export default LoadingIndicator;