import { useEffect, useState, useRef } from 'react';

/**
 * Like React's [useState](https://reactjs.org/docs/hooks-reference.html#usestate)
 * but it makes sure the component that uses this hook is mounted when updating state
 *
 * @see https://reactjs.org/docs/hooks-reference.html#usestate
 * @export
 * @param {any} initialValue
 * @returns {[any, Diapatch<any>]} an array of 2 items
 * the first is the current state, the second is a function that enables
 * updating the state if the component is not mounted
 */
export default function useStateOnMounted(initialValue) {
    const isComponentMounted = useComponentOnMounted();
    const [state, setState] = useState(initialValue);
    function newSetState(value) {
        if (isComponentMounted.current) {
            setState(value);
        }
    }
    return [state, newSetState]
}

let useComponentOnMounted = () => {
    // https://reactjs.org/docs/hooks-reference.html#useref
    const isMounted = useRef(false);
    useEffect(() => {
        isMounted.current = true;

        return () => isMounted.current = false;
    }, []);

    return isMounted;
};
