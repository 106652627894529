import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Typography,
    Divider,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AutoLaunchAfterDeploy from "../fileDeployment/AutoLaunchAfterDeploy";
import { deployFileAPI } from "../../apis/deviceControlApi";
import {
    getAutocompleteApi,
    createAutocompleteApi,
} from "../../apis/autocompleteAPI";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import textNoty from "../../helpers/textNoty";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import SelectDate from "./SelectDatePicker";
import moment from "moment";
import FileUpload from "../device/FileUpload";
import { s3upload } from "../../helpers/s3upload";
import { bytesConverter } from "../../helpers/formatBytesTransformer";
import LinearWithValueLabel from "../commons/LinearProgress";
import SelectFileFromNubisDrive from "./SelectFileFromNubisDrive";

const DeployFile = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const [selectDateType, setSelectDateType] = useState("immediately");
    const [selectDate, setSelectDate] = useState(new Date());
    const [command, setCommand] = useState("");
    const [fileStoreLocation, setFileStoreLocation] = useState("");
    const [loading, setLoading] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [percentCompleted, setPercentCompleted] = useState(0);
    const [fileLocationAutoData, setFileLocationAutoData] = useState([]);
    const [commandAutoData, setCommandAutoData] = useState([]);
    const [file, setFile] = useState({});
    const [nubisDriveFile, setNubisDriveFile] = useState({});
    const [selectFileType, setSelectFileType] = useState("file_from_local_pc");
    const [returnValue, setReturnValue] = useState({});
    const [autoLaunch1, setAutoLaunch1] = useState(false);
    const [autoLaunch2, setAutoLaunch2] = useState(false);
    const [disabledUpload, setDisabledUpload] = useState(false);
    const [driveStorageId, setDriveStorageId] = useState(false);
    const {
        open,
        setOpen,
        selectType,
        selectDevices,
        selectDevice,
        numType,
        groupId,
        tagId,
        selectCompany,
        checkAll
    } = props;

    const onClose = () => {
        setOpen(false);
    };

    const onConfirm = async () => {
        if (selectFileType === "file_from_nubis_drive") {
            if (!driveStorageId) {
                textNoty(t("common.pleaseSelectFile"));
                return;
            }
            setFile(false);
        } else {
            if (!file.name) {
                textNoty(t("common.pleaseUploadFile"));
                return;
            }
            if (percentCompleted !== 100) {
                textNoty(t("common.pleaseWaitForUploadCompleted"));
                return;
            }
            setDriveStorageId(false);
        }
        setLoading(true);
        const successText = "dialog.successText";
        await deployFileAPI(
            selectType,
            selectCompany,
            checkAll
                ? undefined
                : numType === "single"
                    ? [selectDevice]
                    : selectDevices,
            groupId,
            tagId,
            "deploy_file",
            returnValue,
            driveStorageId,
            autoLaunch1,
            autoLaunch2,
            selectDate,
            fileStoreLocation,
            command,
            selectFileType,
            t,
            successText
        ).then(async (res) => {
            if (res) {
                setLoading(false);
                setOpen(false);
                if (command) {
                    await createAutocompleteApi("deployfile_command", command);
                }
                if (fileStoreLocation) {
                    await createAutocompleteApi(
                        "deployfile_file_store_location",
                        fileStoreLocation
                    );
                }
            } else {
                setLoading(false);
            }
        });
    };

    const selectDateHandler = async (date) => {
        const dateValue = date._d;
        const dateMomentInUTC = moment(dateValue);
        setSelectDate(dateMomentInUTC);
    };

    const selectDateTypeHandler = (e) => {
        setSelectDateType(e.target.value);
    };

    const handleUploadFile = async () => {
        setIsUploading(true);
        if (file) {
            setDisabledUpload(true);
            let successfulText = `${t("noty.successfullyUploadFile")}`;
            await s3upload(
                document.getElementById("deployFileUpload"),
                "",
                t,
                successfulText,
                percentCompleted,
                setPercentCompleted,
                undefined,
                undefined,
                undefined,
                true,
                setReturnValue,
                "ora/deploy-files",
                true,
                false,
                true
            );
        } else {
            setIsUploading(false);
        }
    };

    const deployTypeHandler = (e) => {
        setSelectFileType(e.target.value);
    };

    const getAutoComplete = async () => {
        let commandData = await getAutocompleteApi("deployfile_command", "");
        let fileLocationData = await getAutocompleteApi(
            "deployfile_file_store_location",
            ""
        );

        if (commandData?.commands) {
            setCommandAutoData(commandData.commands);
        }
        if (fileLocationData?.commands) {
            setFileLocationAutoData(fileLocationData.commands);
        }
    };

    useEffect(() => {
        if (open) {
            getAutoComplete();
            setSelectDateType("immediately");
            setSelectDate(new Date());
            setCommand("");
            setFileStoreLocation("");
            setIsUploading(false);
            setPercentCompleted(0);
            setFile({});
            setNubisDriveFile({});
            setSelectFileType("file_from_local_pc");
            setReturnValue({});
            setAutoLaunch1(false);
            setAutoLaunch2(false);
            setDisabledUpload(false);
            setDriveStorageId(false);
        }
    }, [open]);

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            onClose={onClose}
            open={open}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t("common.deployFile")}
            </DialogTitle>
            <>
                <DialogContent>
                    <div className={classes.deviceControlDialogOuter}>
                        <div className="deviceControlDialogBlock">
                            <div className="title">1. {t("common.selectDate")}</div>
                            <div className="selectdateArea">
                                <FormControl component="div" fullWidth={true}>
                                    <RadioGroup
                                        aria-label="date"
                                        value={selectDateType}
                                        onChange={selectDateTypeHandler}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="immediately"
                                                        label={`${t("common.immediateText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="scheduling"
                                                        label={`${t("common.schedulingText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                    <SelectDate
                                                        selectDateHandler={selectDateHandler}
                                                        selectDate={selectDate}
                                                        disabled={
                                                            selectDateType !== "scheduling" ? true : false
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        <div className="deviceControlDialogBlock">
                            <div className="title">2. {t("common.selectAFile")}</div>
                            <div className={`${classes.radioSelectGroup}`}>
                                <FormControl component="div" fullWidth={true} required>
                                    <RadioGroup
                                        aria-label="files"
                                        name={"file"}
                                        value={selectFileType}
                                        onChange={deployTypeHandler}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="file_from_local_pc"
                                                        label={`${t("common.fileFromLocalPC")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                    <FileUpload
                                                        uploadFileType={"file"}
                                                        setFile={setFile}
                                                        id={"deployFileUpload"}
                                                        ifNubisDrive={true}
                                                        changeHanlder={handleUploadFile}
                                                        tableDisabled={
                                                            selectFileType !== "file_from_local_pc" ||
                                                                disabledUpload
                                                                ? true
                                                                : false
                                                        }
                                                        availableStorage={bytesConverter(1024, "MB")}
                                                    />
                                                    {isUploading && (
                                                        <LinearWithValueLabel
                                                            percentCompleted={percentCompleted}
                                                        />
                                                    )}
                                                    <Typography variant="caption" color="textSecondary">
                                                        {t("common.maximumFileSize")}
                                                    </Typography>
                                                </FormControl>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={12}
                                                sm={12}
                                                md={6}
                                                lg={6}
                                                className={classes.dropdownLazyOuter}
                                            >
                                                <FormControl className={classes.dropdownLazy}>
                                                    <FormControlLabel
                                                        value="file_from_nubis_drive"
                                                        label={`${t("common.fileFromNubisDrive")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                    <SelectFileFromNubisDrive
                                                        open={open}
                                                        driveStorageId={driveStorageId}
                                                        setDriveStorageId={setDriveStorageId}
                                                        setNubisDriveFile={setNubisDriveFile}
                                                        tableDisabled={
                                                            selectFileType === "file_from_nubis_drive"
                                                                ? false
                                                                : true
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                                <Divider className="margin-less" />
                                {selectFileType === "file_from_nubis_drive" ? (
                                    <AutoLaunchAfterDeploy
                                        checked={autoLaunch2}
                                        selectType="file_from_nubis_drive"
                                        nubisDriveFile={nubisDriveFile}
                                        file={file}
                                        name="autoLaunch2"
                                        setAutoLaunch={setAutoLaunch2}
                                    />
                                ) : (
                                    <AutoLaunchAfterDeploy
                                        checked={autoLaunch1}
                                        selectType="file_from_local_pc"
                                        nubisDriveFile={nubisDriveFile}
                                        file={file}
                                        name="autoLaunch1"
                                        setAutoLaunch={setAutoLaunch1}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="deviceControlDialogBlock">
                            <div className="title">
                                3. {t("common.typeCommandForFileDeployment")}{" "}
                                <span style={{ fontWeight: 600 }}>
                                    ({t("common.optionText")})
                                </span>
                            </div>
                            <Autocomplete
                                id="command"
                                freeSolo
                                disabled={
                                    selectFileType === "file_from_nubis_drive"
                                        ? !autoLaunch2
                                        : !autoLaunch1
                                }
                                options={commandAutoData.map((option) => option.content)}
                                onChange={(event, newValue) => {
                                    setCommand(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        value={command}
                                        onChange={(e) => setCommand(e.target.value)}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div className="deviceControlDialogBlock">
                            <div className="title">
                                4. {t("common.fileStoredLocation")}{" "}
                                <span style={{ fontWeight: 600 }}>
                                    ({t("common.optionText")})
                                </span>
                            </div>
                            <Autocomplete
                                id="fileStoreLocation"
                                freeSolo
                                options={fileLocationAutoData.map((option) => option.content)}
                                onChange={(event, newValue) => {
                                    setFileStoreLocation(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        value={fileStoreLocation}
                                        onChange={(e) => setFileStoreLocation(e.target.value)}
                                        placeholder={"C:\\users\\public\\downloads"}
                                        variant="outlined"
                                    />
                                )}
                            />
                            <div className="mb-1" />
                            <Typography variant="caption" color="secondary">
                                {t("common.fileStoredLocationDefaultExample")}
                            </Typography>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading}
                        btnText={t("dialog.confirmBtn")}
                        onClick={onConfirm}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default DeployFile;
