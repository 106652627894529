import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import clsx from "clsx";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import useStyles from "../../styles/drawerStyle";
import NubisLogo from "../../assets/images/nubis_logo.svg";
import FecLogo from "../../assets/images/fec_logo.png";
import DrawerList from "./DrawerList";
import AppbarRightSection from "./AppbarRightSection";
import ReleaseVersionText from "./ReleaseVersionText";

const ResponsiveDrawer = () => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [fixedOpen, setFixedOpen] = useState(true);
  const fecLink = process.env.REACT_APP_UNAUTH_REDIRECT_URL;

  const handleDrawerToggle = () => {
    setFixedOpen(!fixedOpen);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open || fixedOpen,
        })}
      >
        <Toolbar>
          <div className={classes.toolbar}>
            <IconButton
              aria-label="open drawer"
              onClick={handleDrawerToggle}
              edge="start"
              className={classes.menuButton}
            >
              <MenuIcon />
            </IconButton>
            <a href={fecLink} className={classes.goToFecLogoLink}>
              <ChevronLeftIcon />
              <img src={FecLogo} alt="fec logo" />
              <Typography>Platform</Typography>
            </a>
          </div>
          <AppbarRightSection />
        </Toolbar>
      </AppBar>
      <Drawer
        onMouseOver={handleDrawerOpen}
        onMouseLeave={handleDrawerClose}
        PaperProps={{ elevation: 10 }}
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open || fixedOpen,
          [classes.drawerClose]: !open && !fixedOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open || fixedOpen,
            [classes.drawerClose]: !open && !fixedOpen,
          }),
        }}
      >
        <NavLink to="/dashboard">
          <div className={classes.siteLogoLink}>
            <img
              src={NubisLogo}
              alt="nubis logo"
              className={classes.siteLogoLink_img}
            />
            <Typography
              variant="caption"
              color="secondary"
              className={classes.siteLogoLink_text}
            >
              Nubis
              <ReleaseVersionText />
            </Typography>
          </div>
        </NavLink>
        <Divider />
        <DrawerList isListExpanded={open || fixedOpen} />
        {!fixedOpen && <ReleaseVersionText rwd={true} />}
      </Drawer>
    </div>
  );
};

export default ResponsiveDrawer;
