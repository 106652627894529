import React, { useState, useContext, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Divider } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn } from '../commons/FormBtns';
import { SubmitBtnContext } from '../../App';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import { removeAlarmPolicyAPI } from '../../apis/removeAlarmPolicyAPI';
import { degreeRegex, operatorDeterminator } from '../../helpers/regex';

const RemoveAlarmPolicyDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { onClose, open, policyIdToBeRemoved, policyToBeUpdated } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    const [ fullRuleName, setFullRuleName ] = useState('');
    const [ emails, setEmails ] = useState('-');
    const [ note, setNote ] = useState('-');

    const handleRemoveAlarmPolicy = async() => {
        dispatch({type: 'showLoading'});
        let successText = `${t('modal.successfullyDeleteAlarmPolicy')}`;
        const result = await removeAlarmPolicyAPI(policyIdToBeRemoved, t, successText);
        if(result){
            onClose();
        }
        dispatch({type: 'stopLoading'});
    }
    
    const setUpFullRuleName = (policyToBeUpdated) => {
        let operatorEntity = operatorDeterminator(policyToBeUpdated.operator);
        let unit = policyToBeUpdated.alarm_policy_option.unit === '&amp;#8451;' ? degreeRegex : policyToBeUpdated.alarm_policy_option.unit;
        let rule = `${policyToBeUpdated.alarm_policy_option.name + ' ' + operatorEntity + ' ' + policyToBeUpdated.value + unit}`
        setFullRuleName(rule);
        setEmails(policyToBeUpdated.emails.join(', '));
        setNote(policyToBeUpdated.note ? policyToBeUpdated.note : '-');
    }

    useEffect(() => {
        if(policyToBeUpdated){
            setUpFullRuleName(policyToBeUpdated);
        }
    }, [policyToBeUpdated])

    return(
        <Dialog
            maxWidth='sm'
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-edit-alarm-policy"
            aria-describedby="add-edit-alarm-policy-confirmation"
        >
            <DialogTitle id="add-edit-alarm-policy">
                {t('modal.deleteAlarmPolicy')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('modal.deleteAlarmPolicyDescription')}
                </DialogContentText>
                <Divider />
                <Typography variant="caption">{t('table.ruleDescription')}</Typography>
                <Typography variant="body2" color="primary">{fullRuleName}</Typography>
                <Typography variant="caption">{t('table.email')}</Typography>
                <Typography variant="body2" color="primary">{emails}</Typography>
                <Typography variant="caption">{t('table.note')}</Typography>
                <Typography variant="body2" color="primary">{note}</Typography>
            </DialogContent>
            <DialogActions>
                <StyledSubmitBtn 
                    btnColor="primary" 
                    type="button" 
                    onClick={handleRemoveAlarmPolicy} 
                    startIcon={<DeleteRounded />} 
                    btnText={t('modal.removeBtn')} />
                <Button 
                    onClick={onClose} 
                    color="secondary" 
                    variant="contained"
                    type="button">
                    {t('modal.cancelBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveAlarmPolicyDialog;