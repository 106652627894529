import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import "../css/dataTable.css";
import * as $ from "jquery";
import "datatables.net";
import moment from "moment";
import * as localforage from "localforage";
import { language } from "./datatableLanguage";
import StatusChip from "../components/commons/StatusChip";

export const table = async (t, deviceId, handleUpdatePeripherals) => {
    const token = await localforage.getItem("token");
    $.fn.dataTable.ext.errMode = "throw";

    $("#peripherals-table").DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        searching: false,
        filter: false,
        deferRender: true,
        info: false,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        pageLength: 15,
        ordering: false,
        ajax: {
            url: `${url}/api/v2/device/peripherals`,
            type: "GET",
            dataSrc: "data",
            data: {
                device_id: deviceId
            },
            beforeSend: request => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: () => { }
        },
        columns: [
            {
                data: "firmware",
                name: "firmware.peripheralType.name",
                title: `${t("peripherals.peripheralsType")}`,
                render: data => {
                    return data.peripheral_type.name;
                }
            },
            {
                data: "status",
                title: `${t("peripherals.status")}`,
                createdCell: (td, data) => {
                    ReactDOM.render(
                        <StatusChip
                            statusLabel={
                                data && data === "updated_success" ? "updated_completed" : data
                            }
                        />,
                        td
                    );
                }
            },
            {
                data: "firmeware_version",
                name: "name",
                title: `${t("peripherals.fwVersion")}`,
                render: data => {
                    return data;
                }
            },
            {
                data: "last_updated_at",
                title: `${t("peripherals.lastDate")}`,
                render: data => {
                    return moment(data)
                        .local()
                        .format("YYYY-MM-DD HH:mm");
                }
            },
            {
                data: "status",
                title: `${t("peripherals.action")}`,
                searchable: false,
                className: "resend-btn-td",
                createdCell: (td, data, rowData) => {
                    let statusCanBeUpdated = [
                        "connected",
                        "updated_success",
                        "updated_failed"
                    ];
                    if (statusCanBeUpdated.includes(data)) {
                        ReactDOM.render(
                            <span onClick={e => handleUpdatePeripherals(rowData, e)}>
                                UPDATE
                            </span>,
                            td
                        );
                    } else {
                        ReactDOM.render("", td);
                    }
                }
            }
        ]
    });
};
