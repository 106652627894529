import React, { useEffect, useContext, useRef } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../styles/deviceTaskStyle';
import { useTranslation } from "../langs/useTranslation";
import Form from '../components/vnc/Form';
import { SubmitBtnContext } from '../App';
import { BreadCrumbs } from '../components/device/BreadCrumbs';
import * as localforage from 'localforage';

const VNCSetting = () => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('task');
    const { dispatch } = useContext(SubmitBtnContext);
    const executeType = "vnc_setting"

    // Manage localforage names based on the task name (no need for i18n)
    const taskName = "vncSetting";
    const storedDeviceValueToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedFileValueToLocalforageName = `${taskName}FileSelected`;
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedDeviceValueToLocalforageName);
        await localforage.removeItem(storedFileValueToLocalforageName);
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    }


    useEffect(() => {
        if (!mounted.current) {
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })
    return (
        <div className={classes.root}>
            <BreadCrumbs showDeviceList={true} pageName="vncSetting" />
            <Typography variant="h4" className={classes.pageTitle}>{t('task.vncSetting')}</Typography>
            <Form
                resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                storedFileValueToLocalforageName={storedFileValueToLocalforageName}
                storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                executeType={executeType}
                dispatch={dispatch}
                deviceId=""
            />
        </div>
    )
}

export default VNCSetting;
