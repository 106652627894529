import React, { useState, useEffect, useContext, useRef } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../styles/deviceTaskStyle';
import { useTranslation } from "../langs/useTranslation";
import SingleForm from '../components/rotation/SingleForm';
import { useParams } from 'react-router-dom';
import Form from '../components/rotation/Form';
import { SubmitBtnContext } from '../App';
import { BreadCrumbs } from '../components/device/BreadCrumbs';
import * as localforage from 'localforage';

const Rotation = () => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('task');
    const { device_id, device_os_type } = useParams();
    const { dispatch } = useContext(SubmitBtnContext);
    const [isSingleDeviceTask, setIsSingleDeviceTask] = useState(false);
    const executeType = "screen_rotation"
    const successfulText = 'task.successfullyRotation';

    // Manage localforage names based on the task name (no need for i18n)
    const taskName = "rotation";
    const storedDeviceValueToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedDatePickerValueToLocalforageName = `${taskName}Date`;

    const checkIsSingleDeviceTask = () => {
        setIsSingleDeviceTask(!!device_id);
    };

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedDeviceValueToLocalforageName);
        await localforage.removeItem(storedDatePickerValueToLocalforageName);
        dispatch({ type: 'stopLoading' });
    }


    useEffect(() => {
        if (!mounted.current) {
            checkIsSingleDeviceTask();
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })
    return (
        <div className={classes.root}>
            <BreadCrumbs showDeviceList={true} deviceId={device_id} deviceOsType={device_os_type} pageName="rotationName" />
            <Typography variant="h4" className={classes.pageTitle}>{t('task.rotationName')}</Typography>
            {isSingleDeviceTask ?
                <SingleForm
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                    executeType={executeType}
                    dispatch={dispatch}
                    devices=""
                    deviceId={device_id}
                    successfulText={successfulText} />
                :
                <Form
                    resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                    storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                    executeType={executeType}
                    dispatch={dispatch}
                    deviceId=""
                    successfulText={successfulText} />
            }
        </div>
    )
}

export default Rotation;
