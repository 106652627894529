import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Grid,
    TextField,
    Checkbox,
} from "@material-ui/core";
import { kioskModeAPI } from "../../apis/deviceControlApi";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import SelectDate from "./SelectDatePicker";
import {
    getAutocompleteApi,
    createAutocompleteApi,
} from "../../apis/autocompleteAPI";
import moment from "moment";
import Autocomplete from "@material-ui/lab/Autocomplete";

const KioskMode = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const [selectDateType, setSelectDateType] = useState("immediately");
    const [switchValue, setSwitchValue] = useState("true");
    const [checkedWindows, setCheckedWindows] = useState(false);
    const [checkedAndroid, setCheckedAndroid] = useState(false);
    const [selectDate, setSelectDate] = useState(new Date());
    const [command, setCommand] = useState("");
    const [enableApplicationAndroid, setEnableApplicationAndroid] = useState("");
    const [enableApplicationWindows, setEnableApplicationWindows] = useState("");
    const [loading, setLoading] = useState(false);
    const [windowsAutoData, setWindowsAutoData] = useState([]);
    const [androidAutoData, setAndroidAutoData] = useState([]);
    const [commandAutoData, setCommandAutoData] = useState([]);
    const [kioskModeSwitchOff, setKioskModeSwitchOff] = useState(false);
    const {
        open,
        setOpen,
        selectType,
        selectDevices,
        selectDevice,
        numType,
        groupId,
        tagId,
        selectCompany,
        checkAll
    } = props;

    const onClose = () => {
        setOpen(false);
        setKioskModeSwitchOff(false);
    };

    const onConfirm = async () => {
        setLoading(true);
        const successText = "dialog.successText";
        await kioskModeAPI(
            selectType,
            selectCompany,
            checkAll
                ? undefined
                : numType === "single"
                    ? [selectDevice]
                    : selectDevices,
            groupId,
            tagId,
            "kiosk_mode",
            switchValue === "true",
            enableApplicationAndroid,
            enableApplicationWindows,
            checkedWindows,
            checkedAndroid,
            selectDate,
            null,
            command,
            t,
            successText,
            kioskModeSwitchOff
        ).then(async (res) => {
            console.log(res)
            if (res) {
                setLoading(false);
                setOpen(false);
                setKioskModeSwitchOff(false);
                if (command) {
                    await createAutocompleteApi("kioskmode_command", command);
                }
                if (checkedAndroid && enableApplicationAndroid) {
                    await createAutocompleteApi(
                        "kioskmode_android_startup",
                        enableApplicationAndroid
                    );
                }
                if (checkedWindows && enableApplicationWindows) {
                    await createAutocompleteApi(
                        "kioskmode_windows_startup",
                        enableApplicationWindows
                    );
                }
            }
        });
    };

    const selectDateHandler = async (date) => {
        const dateValue = date._d;
        const dateMomentInUTC = moment(dateValue);
        setSelectDate(dateMomentInUTC);
    };

    const selectDateTypeHandler = (e) => {
        setSelectDateType(e.target.value);
    };

    const switchHandler = (e) => {
        setSwitchValue(e.target.value);
    };

    const getAutoComplete = async () => {
        let commandData = await getAutocompleteApi("kioskmode_command", "");
        let windowsData = await getAutocompleteApi("kioskmode_windows_startup", "");
        let androidData = await getAutocompleteApi("kioskmode_android_startup", "");
        if (commandData?.commands) {
            setCommandAutoData(commandData.commands);
        }
        if (windowsData?.commands) {
            setWindowsAutoData(windowsData.commands);
        }
        if (androidData?.commands) {
            setAndroidAutoData(androidData.commands);
        }
    };
    useEffect(() => {
        if (open) {
            getAutoComplete();
            setCommand("");
            setEnableApplicationAndroid("");
            setEnableApplicationWindows("");
            setCheckedAndroid(false);
            setCheckedWindows(false);
            setSwitchValue("true");
            setSelectDateType("immediately");
            setSelectDate(new Date());
        }
    }, [open]);

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            onClose={onClose}
            open={open}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t("common.kioskMode")}
            </DialogTitle>
            <>
                <DialogContent>
                    <div className={classes.deviceControlDialogOuter}>
                        <div className="deviceControlDialogBlock">
                            <div className="title">1. {t("common.selectDate")}</div>
                            <div className="selectdateArea">
                                <FormControl component="div" fullWidth={true}>
                                    <RadioGroup
                                        aria-label="date"
                                        value={selectDateType}
                                        onChange={selectDateTypeHandler}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="immediately"
                                                        label={`${t("common.immediateText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="scheduling"
                                                        label={`${t("common.schedulingText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                    <SelectDate
                                                        selectDateHandler={selectDateHandler}
                                                        selectDate={selectDate}
                                                        disabled={
                                                            selectDateType !== "scheduling" ? true : false
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>

                        <div className="deviceControlDialogBlock">
                            <div className="title">2. {t("common.kioskModeSwitch")}</div>

                            <div className="selectdateArea">
                                <FormControl component="div" fullWidth={true}>
                                    <RadioGroup
                                        aria-label="date"
                                        value={switchValue}
                                        onChange={switchHandler}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value={"true"}
                                                        label={`${t("common.onSwitch")}`}
                                                        control={<Radio color="primary" />}
                                                        onChange={() => {
                                                            setKioskModeSwitchOff(false);
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value={"false"}
                                                        label={`${t("common.offSwitch")}`}
                                                        control={<Radio color="primary" />}
                                                        onChange={() => {
                                                            setKioskModeSwitchOff(true);
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="deviceControlDialogBlock">
                            <div className="title">
                                3. {t("common.typeCommandForClosingYourApplication")}{" "}
                                <span style={{ fontWeight: 600 }}>
                                    ({t("common.optionText")})
                                </span>
                            </div>

                            <Autocomplete
                                id="command"
                                freeSolo
                                options={commandAutoData.map((option) => option.content)}
                                onChange={(event, newValue) => {
                                    setCommand(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder={t("common.kioskModePlaceholder")}
                                        value={command}
                                        onChange={(e) => setCommand(e.target.value)}
                                        variant="outlined"
                                    />
                                )}
                            />
                        </div>
                        <div
                            className="deviceControlDialogBlock"
                            hidden={kioskModeSwitchOff}
                        >
                            <div className="title">
                                4. {t("common.enableApplication")}{" "}
                                <span style={{ fontWeight: 600 }}>
                                    ({t("common.optionText")})
                                </span>
                            </div>

                            <div className="selectdateArea">
                                <FormControl component="div" fullWidth={true}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControlLabel
                                                className={classes.selectAllCheckbox}
                                                control={
                                                    <Checkbox
                                                        id="select-windows"
                                                        checked={checkedWindows}
                                                        disabled={loading}
                                                        onChange={() => setCheckedWindows(!checkedWindows)}
                                                        name="windows"
                                                    />
                                                }
                                                label={t("common.windowsDevices")}
                                            />
                                            <Autocomplete
                                                id="windowsSetup"
                                                freeSolo
                                                disabled={loading || !checkedWindows}
                                                options={windowsAutoData.map(
                                                    (option) => option.content
                                                )}
                                                onChange={(event, newValue) => {
                                                    setEnableApplicationWindows(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t(
                                                            "common.kioskModePlaceholderWindows"
                                                        )}
                                                        value={enableApplicationWindows}
                                                        onChange={(e) =>
                                                            setEnableApplicationWindows(e.target.value)
                                                        }
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12}>
                                            <FormControlLabel
                                                className={classes.selectAllCheckbox}
                                                control={
                                                    <Checkbox
                                                        id="select-android"
                                                        checked={checkedAndroid}
                                                        disabled={loading}
                                                        onChange={() => setCheckedAndroid(!checkedAndroid)}
                                                        name="android"
                                                    />
                                                }
                                                label={t("common.androidDevices")}
                                            />

                                            <Autocomplete
                                                id="androidSetup"
                                                freeSolo
                                                disabled={loading || !checkedAndroid}
                                                options={androidAutoData.map(
                                                    (option) => option.content
                                                )}
                                                onChange={(event, newValue) => {
                                                    setEnableApplicationAndroid(newValue);
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        placeholder={t(
                                                            "common.kioskModePlaceholderAndroid"
                                                        )}
                                                        value={enableApplicationAndroid}
                                                        onChange={(e) =>
                                                            setEnableApplicationAndroid(e.target.value)
                                                        }
                                                        variant="outlined"
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    </Grid>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading}
                        btnText={t("dialog.confirmBtn")}
                        onClick={onConfirm}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default KioskMode;
