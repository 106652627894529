import React , { createContext, useEffect, useRef, useState } from 'react';
import GoogleMap from './GoogleMap';
import { Loader } from '@googlemaps/js-api-loader';
import LoadingIndicator from '../commons/LoadingIndicator';
import { getDevicesCoordinatesAPI } from '../../apis/getDeviceCoordinatesAPI';
import useStyles from './mapStyle';
import { BasicInfoWindow, OnlineOfflineInfoWindow } from './Infowindow';
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import { IconButton } from '@material-ui/core';

const defaultSetting = {
    center: { lat:25.033964, lng: 121.564468 },//Taipei 101
    zoom: 12,
}

export const MapContext = createContext(null);

function MapWrapper({
    height=300,
    options=defaultSetting
}){
    const _API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
    const mapProviderOptions = {
        provider: "Google",
        apiKey: { apiKey: _API_KEY }
    }
    const mapLoadStatus = {
        "SUCCESS": 1,
        "LOADING": 2,
        "FAILURE": 3
    }
    const classes = useStyles();
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    const [group, setGroups] = useState([]);
    const [bounds, setBounds] = useState(null)
    const [status, setStatus] = useState(mapLoadStatus["LOADING"]);
    const [coordinates, setCoordinates] = useState([]);
    const [isDataLoaded, setIsDataLoaded] = useState(false);

    const getCoordinates = async () => {
        const coordinates = await getDevicesCoordinatesAPI();
        if(!coordinates){
            setCoordinates([])
        } else {
            setCoordinates(coordinates);
        }
        setIsDataLoaded(true);
    }

    const loadMapSDK = async (provider, options) => {
        console.log(`Load Map from ${options.provider}`);
        const loader = new provider(options.apiKey);
        try {

            await loader.load();
            setStatus(mapLoadStatus["SUCCESS"]);
        
        } catch (err) {

            console.error(err);
            setStatus(mapLoadStatus["FAILURE"]);

        }
    }

    const handleReload = () => {
        setIsDataLoaded(false);
    }

    const LoadingWrapper = () => (
            <div style={{height: height, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white'}}>
                <LoadingIndicator />
            </div>
        )
    
    useEffect(() => {
        /**
         * Get devices coordinates from API
        */
        if(!isDataLoaded){
            getCoordinates();
        }
    }, [isDataLoaded]);

    useEffect(() => {
        /**
         * Load Map SDK, only load once.
         */
        loadMapSDK(Loader, mapProviderOptions)
    }, [])

    useEffect(() => {
        /**
         * Run Map Side effect: add widget, add marker, set infowindow, register some global interaction
         */
        const markerList=[];
        const groupList=[];

        if(coordinates.length > 0 && map && isDataLoaded){
            
            /**
             * Clear map object's Listeners
             * Is necessary if map or data will hot reload
             * Need check
             */
            /* markers.forEach( m => {
                m.clearListener();
            })
            infoWindows.forEach( iw => {
                iw.clearListener();
            }) */


            const bounds = new window.google.maps.LatLngBounds();
            const latlngMap = new Map();
            
            coordinates.forEach( (d, idx) => {
                /**
                 * Make a map using coordinate for the key 
                 */
                const coordinateString = `${d.lat}/${d.lon}`;
                const inMapItem = latlngMap.get(coordinateString);

                if(inMapItem)
                {
                    latlngMap.set(
                        coordinateString,
                        [
                            ...inMapItem,
                            d
                        ]
                    )
                }
                else
                {
                    latlngMap.set(
                        coordinateString,
                        [
                            d
                        ]
                    )
                }
            });
                
            for(let [groupName, items] of latlngMap){
                let [lat, lng] = groupName.split('/').map(_ => parseFloat(_));
                if( Number.isNaN(lat) || Number.isNaN(lng) )
                {
                    console.group('Invalid coordinates Devices');
                    for(let item of items)
                    {
                        console.log(item["serial_number"]);
                    }
                    console.groupEnd('Invalid coordinates Devices');
                    continue;
                }

                const marker = new window.google.maps.Marker({
                    map: map,
                    position: {lat, lng}
                })
                
                markerList.push(marker);
                //const iw = {infoWindow, isOpen: false};
                groupList.push(items);
                bounds.extend({lat, lng});
            }

            const infoWindow = {
                instance: new window.google.maps.InfoWindow(),
                isOpen: false
            }
            markerList.forEach( (marker, idx) => {

                marker.addListener('click', () => {
                    infoWindow.instance.setContent(OnlineOfflineInfoWindow(groupList[idx], classes.tabInfoWindow))
                    if(infoWindow.isOpen)
                    {
                        if(!infoWindow.instance.getPosition().equals(marker.getPosition()))
                        {
                            infoWindow.instance.open({
                                map: map,
                                anchor: marker,
                                shouldFocus: false,
                            }); 
                        }
                        else
                        {
                            infoWindow.instance.close()
                            infoWindow.isOpen = false;
                            map.panTo(bounds.getCenter());
                        }

                    }
                    else
                    {
                        infoWindow.instance.open({
                            map: map,
                            anchor: marker,
                            shouldFocus: false,
                        });
                        infoWindow.isOpen = true;
                    }
                })
                infoWindow.instance.addListener('closeclick', () => {
                    infoWindow.isOpen = false;
                    map.panTo(bounds.getCenter());
                })
            })

            setBounds(bounds);
            setMarkers(markerList);
            setGroups(groupList);
            map.fitBounds(bounds);
            if(markerList.length === 1)
            {
                let boundsListener = map.addListener('bounds_changed', function(event) {
                    if (this.getZoom()){
                        this.setZoom(12);
                    }
                    boundsListener.remove();
                });
            }
            map.setCenter(bounds.getCenter());
        }

    }, [isDataLoaded, coordinates, status, map]);

    if(status === mapLoadStatus["LOADING"] || !isDataLoaded)
    {
        return <LoadingWrapper />
    } 
    else if (status === mapLoadStatus["SUCCESS"])
    {
        return (
            <MapContext.Provider value={{map, setMap}}>
                <div style={{position: 'relative'}}>
                    <IconButton 
                        aria-label="Reload data" 
                        onClick={handleReload}
                        style={{
                            backgroundColor: "white",
                            position: 'absolute',
                            top: '10px',
                            left: '10px',
                            zIndex: '9999'
                        }}
                    >
                        <AutorenewRoundedIcon/>
                    </IconButton>
                    <GoogleMap 
                        center={options.center}
                        zoom={options.zoom}
                        height={height}
                    />
                </div>
            </MapContext.Provider>
        )
    }
    else {
        return <div>Map error, show some FallBack UI</div>
    }
}

export default MapWrapper;