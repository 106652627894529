import React, { useContext, useState, useEffect } from "react";
import {
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Badge,
} from "@material-ui/core";
import AccountCircle from "@material-ui/icons/AccountCircle";
import LangSelect from "./LangSelect";
import { AuthContext } from "../../auths/Auth";
import useStyles from "../../styles/appBarRightStyle";
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext } from "../../App";
import * as localforage from "localforage";
import SearchBar from "./SearchBar";
import HelpOutline from "@material-ui/icons/HelpOutline";
import { qaUrl } from "../../config/optionsOnAppBar";

export const ModalContext = React.createContext();
const AppbarRightSection = () => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const [anchorEl, setAnchorEl] = useState(null); // User menu pop out position
  const [modalOpen, setModalOpen] = useState(false);
  const [username, setUsername] = useState("");
  const { dispatch } = useContext(SubmitBtnContext);
  const { logout } = useContext(AuthContext);
  const isMenuOpen = Boolean(anchorEl);

  const getUsername = async () => {
    let user = await localforage.getItem("user");
    setUsername(user.name);
  };

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    logout();
  };

  const handleModalClose = () => {
    setModalOpen(false);
    dispatch({ type: "stopLoading" });
  };

  const renderUserMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      keepMounted
      className={`${classes.menuList} ${classes.initialHeightPopOver}`}
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleLogout}>{t("appbar.logoutBtn")}</MenuItem>
    </Menu>
  );

  useEffect(() => {
    getUsername();
  }, []);

  return (
    <ModalContext.Provider value={{ setAnchorEl, modalOpen, handleModalClose }}>
      <div className={classes.grow}>
        <Toolbar>
          <div className={classes.rightSection}>
            <SearchBar />
            <LangSelect />
            <a
              href={qaUrl}
              target="_blank"
              rel="noreferrer"
              className={classes.qaLink}
            >
              <IconButton aria-label="Q&A" color="inherit">
                <Badge color="error" overlap="rectangular">
                  <HelpOutline />
                </Badge>
              </IconButton>
            </a>
            {/* <IconButton
                aria-label="Notifications"
                color="inherit"
                onClick={handleNotificationMenuOpen}
              >
                <Badge variant="dot" color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton> */}
            <IconButton
              edge="end"
              aria-label="User"
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
              id="user-appbar-button"
            >
              <AccountCircle />
              <Typography variant="caption" className={classes.usernameText}>
                {username}
              </Typography>
            </IconButton>
          </div>
        </Toolbar>
        {renderUserMenu}
      </div>
    </ModalContext.Provider>
  );
};

export default AppbarRightSection;
