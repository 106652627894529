import React, { useState, useEffect, useCallback } from "react";
import { Typography, Paper } from "@material-ui/core";
import useStyles from "../styles/powerManagerStyle";
import { useTranslation } from "../langs/useTranslation";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import CancelIcon from "../assets/images/delete.png";
import dayjs from "dayjs";
import Tooltip from "@material-ui/core/Tooltip";
import { RefreshRounded } from "@material-ui/icons";
import { numRegex } from "../helpers/regex";
import localforage from "localforage";
import SelectType from "../components/commons/SelectType";
import {
    dateInLocal,
    dateInUTC,
    timeInLocal,
    timeInNormal,
    scheduledDateInUTC,
} from "../helpers/datetimeHandlers";
import AddEventDialog from "../components/powerManagement/AddEventDialog";
import {
    dotColor,
    switchTitle,
    switchBackTitle,
} from "../helpers/powerManagerHelper";
import moment from "moment";
import {
    getEventListApi,
    createEventApi,
    editEventApi,
    deleteEventApi,
    getEvenApi,
} from "../apis/powerManagerApi";
import { getCompanyTreeApi } from "../apis/getCompanyTreeApi";
import { getGroupsApi } from "../apis/deviceControlApi";
import { getAllTagsAPI } from "../apis/getAllTagsAPI";
import textNoty from "../helpers/textNoty";
import DeleteDialog from "../components/powerManagement/DeleteDialog";
import HasPermission from "../components/commons/HasPermission";

const PowerManagement = () => {
    const { t } = useTranslation("powerManagement");
    const classes = useStyles();
    const [start, setStart] = useState("");
    const [end, setEnd] = useState("");
    const [loadingAll, setLoadingAll] = useState(false);
    const [dialogLoading, setDialogLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [addPermission, seAddPermission] = useState(false);
    const [editPermission, seEditPermission] = useState(false);
    const [events, setEvents] = useState([]);

    const [addDialigOpen, setAddDialogOpen] = useState(false);
    const [time, setTime] = useState(new Date());
    const [task, setTask] = useState("start");
    const [startDate, setStartDate] = useState(new Date());
    const [times, setTimes] = useState(1);
    const [loopType, setLoopType] = useState("daily");
    const [dailyType, setDailyType] = useState("every");
    const [dailyTimes, setDailyTimes] = useState(1);
    const [weeklyTimes, setWeeklyTimes] = useState(1);
    const [weeklySelect, setWeeklySelect] = useState({
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false,
    });
    const [endType, setEndType] = useState("datetime");
    const [endDate, setEndDate] = useState(new Date());
    const [selectType, setSelectType] = useState("company");
    const [selectCompanyName, setSelectCompanyName] = useState("");
    const [selectCompany, setSelectCompany] = useState("");
    const [allTags, setAllTags] = useState([]);
    const [groupId, setGroupId] = useState("");
    const [tagId, setTagId] = useState("");
    const [groups, setGroups] = useState([]);

    const [editData, setEditData] = useState();
    const [noGroup, setNoGroup] = useState(true);
    const [noTag, setNoTag] = useState(true);

    const [deleteDialigOpen, setDeleteDialogOpen] = useState(false);
    const [deleteData, setDeleteData] = useState({});

    const checkHasPermission = async () => {
        // get the user role permission
        const userPermissions = await localforage.getItem("userPermissions");
        if (userPermissions) {
            const isPermission0 = userPermissions.includes(
                "power_management_add_task"
            );
            const isPermission2 = userPermissions.includes(
                "power_management_update_task"
            );
            seAddPermission(!!isPermission0);
            seEditPermission(!!isPermission2);
        }
    };
    // get EventApi
    const getEventsHandler = useCallback(async () => {
        if (
            (selectType === "company" && selectCompany) ||
            (selectType === "group" && groupId) ||
            (selectType === "tag" && tagId)
        ) {
            await getEventListApi(
                selectType,
                selectCompany,
                groupId,
                tagId,
                dateInUTC(start),
                dateInUTC(end)
            ).then((res) => {
                if (res.eventsCalendars && res.eventsCalendars.length > 0) {
                    const oldArray = res.eventsCalendars;
                    const newArray = oldArray.map(
                        ({
                            id,
                            start_datetime,
                            end_datetime,
                            groups_id,
                            task_type,
                            task_schedules_id,
                        }) => ({
                            id: id,
                            borderColor: end_datetime,
                            backgroundColor: task_schedules_id,
                            textColor: groups_id,
                            title: switchTitle(task_type),
                            start: dateInLocal(start_datetime),
                            end: dateInLocal(dayjs(end_datetime).add(1, "days")),
                            time: timeInNormal(start_datetime),
                        })
                    );
                    setEvents(newArray);
                    setLoadingAll(false);
                    setLoading(false);
                } else {
                    setLoadingAll(false);
                    setLoading(false);
                }
            });
        }
    }, [end, groupId, selectCompany, selectType, start, tagId]);

    //for first render get api
    const getGropListAndEvents = useCallback(() => {
        setEvents([]);
        setLoading(true);
        getEventsHandler();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getEventsHandler]);

    useEffect(() => {
        if (start && end) {
            getGropListAndEvents();
        }
    }, [end, getGropListAndEvents, start]);

    const refresh = () => {
        setLoading(true);
        setEvents([]);
        getEventsHandler();
    };

    const refreshTool = {
        text: <RefreshRounded onClick={() => refresh()} />,
    };

    const disiableTool = {
        text: (
            <div className="fc-toolbar-chunk fc-disabled">
                <div className="fc-button-group">
                    <div disabled={true} className="fc-prev-button fc-button">
                        <span className="fc-icon fc-icon-chevron-left"></span>
                    </div>
                    <div
                        disabled={true}
                        className="fc-next-button fc-button fc-button-primary"
                    >
                        <span className="fc-icon fc-icon-chevron-right"></span>
                    </div>
                </div>
                <div
                    disabled={true}
                    className="fc-today-button fc-button fc-button-primary"
                >
                    {t("common.backToday")}
                </div>
                <div
                    disabled={true}
                    className="fc-button fc-refreshTool-button-disabled"
                >
                    <RefreshRounded />
                </div>
            </div>
        ),
    };

    // render event style
    const renderEventContent = (eventInfo) => {
        return (
            <>
                <div className="event-outer" key={eventInfo.id}>
                    <div
                        className={classes.dot}
                        style={{ background: dotColor(eventInfo.event.title) }}
                    ></div>
                    <div className={classes.eventTime}>
                        {timeInLocal(eventInfo.event.borderColor)}
                    </div>
                    <div>{t(`common.${eventInfo.event.title}`)}</div>

                    <HasPermission permission="power_management_delete_task">
                        <Tooltip title={t("dialog.delete")} placement="right">
                            <div className="fc-cancel">
                                <img
                                    src={CancelIcon}
                                    alt="delete"
                                    id="Delete"
                                    onClick={(event) => deleteHandler(eventInfo, event)}
                                />
                            </div>
                        </Tooltip>
                    </HasPermission>
                </div>
            </>
        );
    };

    // change month event
    const changeMonth = (dateInfo) => {
        setEnd(dateInfo.endStr);
        setStart(dateInfo.startStr);
    };

    // delete event
    const deleteHandler = (eventInfo, e) => {
        if (loading) {
            return;
        }
        if (e) {
            e.preventDefault();
            e.stopPropagation();
            setDeleteData(eventInfo.event);
            setDeleteDialogOpen(true);
        } else {
            setDeleteData(editData.event);
            setDeleteDialogOpen(true);
        }
    };

    const deleteConfirm = async () => {
        setDialogLoading(true);
        await deleteEventApi(deleteData.backgroundColor, t, t("noty.delete"))
            .then(async (response) => {
                if (response && response.status && response.status === "success") {
                    setEvents([]);
                    await getEventsHandler().then((res) => {
                        setDeleteDialogOpen(false);
                        setAddDialogOpen(false);
                    });
                }
            })
            .finally((err) => {
                setDeleteDialogOpen(false);
                setDialogLoading(false);
            });
    };

    // select date to show add dialog
    const handleDateSelect = (selectInfo) => {
        if (!addPermission) {
            return;
        }
        if (loading) {
            return;
        }
        setEditData();
        const currentDay = dayjs().format("YYYY-MM-DD");
        const start = dayjs(selectInfo.startStr).format("YYYY-MM-DD");
        if (dayjs(start).isBefore(currentDay)) {
            textNoty(t("dialog.minDateMessage"));
            return;
        }
        setLoopType("daily");
        setTimes(1);
        setEndType("datetime");
        setDailyType("every");
        setDailyTimes(1);
        setWeeklySelect({
            mon: false,
            tue: false,
            wed: false,
            thu: false,
            fri: false,
            sat: false,
            sun: false,
        });
        setWeeklyTimes(1);
        setTime(new Date());
        setStartDate(selectInfo.startStr);
        setEndDate(moment(selectInfo.endStr).subtract(1, "days"));
        setAddDialogOpen(true);
        setTask("start");
    };

    // add events
    const onConfirmAdd = async () => {
        const currentDay = dayjs().format("YYYY-MM-DD");
        const start = dayjs(startDate).format("YYYY-MM-DD");
        const end = dayjs(endDate).format("YYYY-MM-DD");
        const startDayAddOne = dayjs(start).add(1, "days");
        // loop type daily and every days no value
        if (
            loopType === "daily" &&
            dailyType === "every" &&
            (!dailyTimes || dailyTimes < 1 || !numRegex.test(dailyTimes))
        ) {
            textNoty(t("dialog.loopTypeTimeAtLeastOneDay"));
            return;
        }

        if (loopType === "weekly") {
            // loop type weekly and every weeks no value
            if (!weeklyTimes || weeklyTimes < 1 || !numRegex.test(weeklyTimes)) {
                textNoty(t("dialog.loopTypeTimeAtLeastOneWeek"));
                return;
            }
            // loop type weekly and no select day
            if (!Object.values(weeklySelect).includes(true)) {
                textNoty(t("dialog.loopTypeTimeAtLeastSelectADay"));
                return;
            }
            // End date equal start
            if (endType === "datetime" && dayjs(end).isBefore(startDayAddOne)) {
                textNoty(t("dialog.oneDayLater"));
                return;
            }
        }
        // end date less than start date
        if (endType === "datetime" && dayjs(end).isBefore(start)) {
            textNoty(t("dialog.endLessThanStart"));
            return;
        }
        // no times
        if (endType === "times" && (!times || times < 1 || !numRegex.test(times))) {
            textNoty(t("dialog.noTimes"));
            return;
        }
        // start date less than current date
        if (dayjs(start).isBefore(currentDay)) {
            textNoty(t("dialog.minDateMessage"));
            return;
        }
        setDialogLoading(true);
        await createEventApi(
            groupId,
            selectCompany,
            tagId,
            selectType,
            task,
            loopType,
            dailyType,
            dailyTimes,
            weeklyTimes,
            weeklySelect,
            endType,
            times,
            `${scheduledDateInUTC(dayjs(start + " " + timeInNormal(time)))}`,
            `${scheduledDateInUTC(dayjs(end + " " + timeInNormal(time)))}`,
            t,
            t("noty.create")
        )
            .then(async (response) => {
                setEvents([]);
                await getEventsHandler().then((res) => {
                    setAddDialogOpen(false);
                });
            })
            .finally((err) => {
                setDialogLoading(false);
            });
    };
    //edit events
    const handleEventClick = async (clickInfo) => {
        if (!editPermission) {
            return;
        }
        const { event, jsEvent } = clickInfo;
        if (jsEvent.target.id === "Delete" || loading) {
            return;
        }
        setEditData();
        let res = await getEvenApi(event.backgroundColor);
        let data = res?.events;
        if (data) {
            setStartDate(dateInLocal(data?.start_datetime || new Date()));
            setEndDate(dateInLocal(data?.end_datetime || new Date()));
            setEditData(clickInfo);
            setTask(switchBackTitle(event.title));
            setTime(data?.end_datetime || new Date());
            setLoopType(data?.schedule_type || "daily");
            setEndType(data?.json?.end_type || "datetime");
            setTimes(data?.json?.run_times || 1);
            if (data?.type === "daily") {
                setDailyTimes(data?.json?.cycle_value || 1);
                setWeeklyTimes(1);
                setWeeklySelect({
                    mon: false,
                    tue: false,
                    wed: false,
                    thu: false,
                    fri: false,
                    sat: false,
                    sun: false,
                });
                setDailyType(data?.json?.cycle_type || "every");
            } else {
                if (data?.json?.cycle_days) {
                    let result = {
                        mon: false,
                        tue: false,
                        wed: false,
                        thu: false,
                        fri: false,
                        sat: false,
                        sun: false,
                    };
                    data.json.cycle_days.forEach((e) => (result[e] = true));
                    setWeeklySelect(result);
                } else {
                    setWeeklySelect({
                        mon: false,
                        tue: false,
                        wed: false,
                        thu: false,
                        fri: false,
                        sat: false,
                        sun: false,
                    });
                }
                setDailyType("every");
                setDailyTimes(1);

                setWeeklyTimes(data?.json?.cycle_value || 1);
            }
            setAddDialogOpen(true);
        }
    };

    const onConfirmEdit = async () => {
        const start = dayjs(startDate).format("YYYY-MM-DD");
        const end = dayjs(endDate).format("YYYY-MM-DD");
        const startDayAddOne = dayjs(start).add(1, "days");

        // loop type daily and every days no value
        if (
            loopType === "daily" &&
            dailyType === "every" &&
            (!dailyTimes || dailyTimes < 1 || !numRegex.test(dailyTimes))
        ) {
            textNoty(t("dialog.loopTypeTimeAtLeastOneDay"));
            return;
        }

        if (loopType === "weekly") {
            // loop type weekly and every weeks no value
            if (!weeklyTimes || weeklyTimes < 1 || !numRegex.test(weeklyTimes)) {
                textNoty(t("dialog.loopTypeTimeAtLeastOneWeek"));
                return;
            }
            // loop type weekly and no select day
            if (!Object.values(weeklySelect).includes(true)) {
                textNoty(t("dialog.loopTypeTimeAtLeastSelectADay"));
                return;
            }
            // End date equal start
            if (endType === "datetime" && dayjs(end).isBefore(startDayAddOne)) {
                textNoty(t("dialog.oneDayLater"));
                return;
            }
        }
        // end date less than start date
        if (endType === "datetime" && dayjs(end).isBefore(start)) {
            textNoty(t("dialog.endLessThanStart"));
            return;
        }
        // no times
        if (endType === "times" && (!times || times < 1 || !numRegex.test(times))) {
            textNoty(t("dialog.noTimes"));
            return;
        }
        setDialogLoading(true);
        await editEventApi(
            editData.event.backgroundColor,
            selectType,
            selectCompany,
            groupId,
            tagId,
            task,
            loopType,
            dailyType,
            dailyTimes,
            weeklyTimes,
            weeklySelect,
            endType,
            times,
            `${scheduledDateInUTC(dayjs(start + " " + timeInNormal(time)))}`,
            `${scheduledDateInUTC(dayjs(end + " " + timeInNormal(time)))}`,
            t,
            t("noty.create"),
            setDialogLoading
        ).then(async (response) => {
            if (response) {
                setEvents([]);
                getEventsHandler().then((res) => {
                    textNoty(t("noty.update"), "success");
                    setAddDialogOpen(false);
                    setDialogLoading(false);
                    setEditData();
                });
            }
        });
    };

    const closeAddOrEdit = () => {
        setAddDialogOpen(false);
        setEditData();
    };

    const getDefaultCompanyId = async () => {
        setLoading(true);
        const companyIdFirst = await localforage.getItem("companyId");
        let data = await getCompanyTreeApi();
        if (data?.length > 0) {
            setSelectCompany(companyIdFirst);
            setSelectCompanyName(data[0]?.title);
            setLoading(false);
        }
    };

    const getAllTags = async () => {
        let res = await getAllTagsAPI(true);
        if (res?.tags?.length > 0) {
            setAllTags(res.tags);
            setTagId(res.tags[0].id);
            setNoTag(false);
        } else {
            setNoTag(true);
        }
    };

    const getGroups = async () => {
        const response = await getGroupsApi();
        if (response && response.length > 0) {
            setGroups(response);
            setGroupId(response[0].id);
            setNoGroup(false);
        } else {
            setNoGroup(true);
        }
    };
    useEffect(() => {
        setLoadingAll(true);
        checkHasPermission();
        getDefaultCompanyId();
        getGroups();
        getAllTags();
    }, []);

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="powerManagement" />
            <Typography variant="h4" className={classes.pageTitle}>
                {t("common.pageTitle")}
            </Typography>
            <SelectType
                groupId={groupId}
                setGroupId={setGroupId}
                setSelectType={setSelectType}
                selectType={selectType}
                setSelectCompanyName={setSelectCompanyName}
                selectCompanyName={selectCompanyName}
                setSelectCompany={setSelectCompany}
                selectCompany={selectCompany}
                groups={groups}
                noGroup={noGroup}
                noTag={noTag}
                allTags={allTags}
                tagId={tagId}
                setTagId={setTagId}
            />
            <Typography variant="body1" color="error" className={classes.noGroupText}>
                {t("noty.forPro")}
            </Typography>
            <Paper className={classes.paperNopadding}>
                <div className={loading || loadingAll ? classes.disabledCalendar : ""}>
                    <FullCalendar
                        initialView="dayGridMonth"
                        defaultAllDay={true}
                        events={events}
                        nextDayThreshold="00:00:00"
                        plugins={[dayGridPlugin, interactionPlugin]}
                        select={handleDateSelect}
                        selectMirror={true}
                        editable={false}
                        droppable={false}
                        eventStartEditable={false}
                        selectable={!loading || !loadingAll}
                        eventOrder={["time"]}
                        dayMaxEvents={true}
                        contentHeight={850}
                        eventContent={renderEventContent}
                        eventClick={handleEventClick}
                        // eventChange={eventChange}
                        customButtons={{ disiableTool, refreshTool }}
                        datesSet={(dateInfo) => {
                            changeMonth(dateInfo);
                        }}
                        headerToolbar={{
                            left: loading ? "disiableTool" : "prev,next today refreshTool",
                            center: "title",
                            right: "",
                        }}
                        buttonText={{
                            today: t("common.backToday"),
                        }}
                    />
                </div>
            </Paper>
            <AddEventDialog
                loading={dialogLoading}
                setTime={setTime}
                time={time}
                groupList={groups}
                task={task}
                setTask={setTask}
                onConfirmEdit={onConfirmEdit}
                onConfirmAdd={onConfirmAdd}
                setStartDate={setStartDate}
                startDate={startDate}
                setEndDate={setEndDate}
                endDate={endDate}
                open={addDialigOpen}
                times={times}
                setTimes={setTimes}
                setEndType={setEndType}
                endType={endType}
                dotColor={dotColor}
                timeInLocal={timeInLocal}
                setLoopType={setLoopType}
                loopType={loopType}
                dailyType={dailyType}
                setDailyType={setDailyType}
                dailyTimes={dailyTimes}
                editData={editData}
                setDailyTimes={setDailyTimes}
                weeklyTimes={weeklyTimes}
                setWeeklyTimes={setWeeklyTimes}
                weeklySelect={weeklySelect}
                setWeeklySelect={setWeeklySelect}
                onClose={() => closeAddOrEdit(false)}
                deleteHandler={deleteHandler}
            />
            <DeleteDialog
                open={deleteDialigOpen}
                timeInLocal={timeInLocal}
                deleteData={deleteData}
                dotColor={dotColor}
                loading={dialogLoading}
                onConfirm={deleteConfirm}
                onClose={() => setDeleteDialogOpen(false)}
            />
        </div>
    );
};

export default PowerManagement;
