import React, { useState, useEffect, useCallback } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Paper,
    TextField,
    Divider,
    DialogActions,
    Button,
} from "@material-ui/core";
import { Tree } from "antd";
import "antd/dist/antd.css";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { Search } from "@material-ui/icons";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { StyledSubmitBtn } from "../../components/commons/FormBtns";
import { grab } from "../../helpers/grab";
import { useBoundingRect } from "../../helpers/useBoundingRect";
import { useHistory, useRouteMatch } from "react-router-dom";

const CompanyTreeDialog = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const {
        open,
        onClose,
        selectCompany,
        setSelectCompany,
        setSelectCompanyName,
        selectCompanyName,
        setSearchValue,
        searchValue,
        tempData,
        setTempData,
        setDevices,
        dataLoader,
        caller
    } = props;
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [loading, setLoading] = useState(true);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [treeData, setTreeData] = useState([]);
    const [filteredTreeData, setFilteredTreeData] = useState([]);
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [timer, setTimer] = useState(null);

    const [ bbox, treeOuterRef ] = useBoundingRect();
    const history = useHistory();
    const { path, url } = useRouteMatch();
    const expKeys = new Set();
    const filterTreeBySearch = (data) => {
        let tree = [];
        if (data) {
            tree = data.map((item) => {
                const titleLowerCase = item.title.toLowerCase();
                const itemTitle = item.title;
                const num = item.num;
                const index = titleLowerCase.indexOf(searchValue.toLowerCase());
                const beforeStr = itemTitle.substr(0, index);
                const afterStr = itemTitle.substr(index + searchValue.length);
                const valueStr = itemTitle.substr(index, searchValue.length);

                let title = "";
                let childrenResult = filterTreeBySearch(item.children)

                if (index > -1) {
                    if (searchValue !== '' && item.parent_id) {
                        expKeys.add(item.parent_id);
                    }
                    title = (
                        <span className="tree-outer">
                            {beforeStr}
                            <span className="site-tree-search-value">{valueStr}</span>
                            {afterStr} <span className="tree-num">({num})</span>
                        </span>
                    );
                    return {
                        title,
                        key: item.key,
                        children: childrenResult,
                        name: itemTitle,
                    };
                } else if (childrenResult.length > 0) {
                    if (searchValue !== '' && item.parent_id) {
                        expKeys.add(item.parent_id);
                    }
                    title = (
                        <span className="tree-outer">
                            {item.title} <span className="tree-num">({num})</span>
                        </span>
                    );
                    return {
                        title,
                        key: item.key,
                        children: childrenResult,
                        name: itemTitle,
                    };
                } else {
                    return null;
                }
            }).filter(item => item ?? false);

            return tree;

        } else {
            return [];
        }
    };

    const onExpand = (expandedKeys) => {
        setExpandedKeys(expandedKeys);
        setAutoExpandParent(false);
    };

    const onChange = (e) => {
        const { value } = e.target;
        setSearchValue(value);
    };

    const getTreeData = async () => {
        setLoading(true);
        let data = await dataLoader();
        if (data) {
            setTreeData(data);
            setExpandedKeys([data[0]?.key]);
            setLoading(false);
            grab("treeOuter");
        }
    };

    const onSelect = (selectedKeys, e) => {
        const company = selectedKeys[0];
        if (!company) {
            setTempData({});
        } else {
            setTempData({ company: company, name: e.node.name });
        }
    };

    const onComfirm = () => {
        setLoadingBtn(true);
        if (setDevices) {
            setDevices([]);
        }
        if (tempData.company) {
            setSelectCompanyName(tempData.name);
            setSelectCompany(tempData.company);
            if(caller === "device-monitor"){
                history.push(`${path}?company=${tempData.company}`)
            }
        }
        onClose(false, tempData);
    };

    const closeHandler = () => {
        setTempData({});
        onClose(true);
    };

    useEffect(() => {
        if (open) {
            setLoadingBtn(false);
            if (selectCompany && selectCompanyName) {
                setTempData({ company: selectCompany, name: selectCompanyName });
            }
            getTreeData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    useEffect(() => {
        clearTimeout(timer);
        setTimer(setTimeout( () => {
            setFilteredTreeData(filterTreeBySearch(treeData))
            setExpandedKeys(Array.from(expKeys));
        }, 200));
    }, [searchValue]);

    //First-show company tree
    useEffect(() => {
        setFilteredTreeData(filterTreeBySearch(treeData));
        setExpandedKeys(treeData.map( d => d.key ));
    }, [treeData])

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            open={open}
            onClose={closeHandler}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t(`common.selectCompany`)}
            </DialogTitle>
            <>
                <DialogContent className={classes.hasPaper}>
                    <Paper>
                        <div className={classes.titleWithEditIcon}>
                            <div className={classes.searchInput}>
                                <TextField
                                    label="Search"
                                    variant="outlined"
                                    onChange={onChange}
                                />
                                <Search className="searchIcon" color="action" />
                            </div>
                        </div>
                        <Divider style={{ marginBottom: "15px" }} />
                        {
                            loading ?
                                (
                                    <SmallerLoadingIndicator />
                                )
                                :
                                (filteredTreeData.length > 0) ?
                                    (
                                        <div className={classes.treeOuter} id="treeOuter" ref={treeOuterRef}>
                                            <Tree
                                                defaultExpandAll={true}
                                                showIcon={false}
                                                onExpand={onExpand}
                                                defaultSelectedKeys={[selectCompany]}
                                                treeData={filteredTreeData}
                                                expandedKeys={expandedKeys}
                                                autoExpandParent={autoExpandParent}
                                                onSelect={onSelect}
                                                showLine={{ showLeafIcon: false }}
                                                height={bbox.height ? bbox.height : 270}
                                            />
                                        </div>
                                    )
                                    :
                                    <div style={{ textAlign: 'center' }}>
                                        <p>No matched result.</p>
                                    </div>
                        }
                        <div className="mb-3" />
                    </Paper>
                </DialogContent>
                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading || !tempData.company || loadingBtn}
                        btnText={t("dialog.confirmBtn")}
                        onClick={() => onComfirm()}
                    />
                    <Button
                        onClick={closeHandler}
                        color="secondary"
                        type="button"
                        variant="contained"
                    >
                        {t("dialog.cancel")}
                    </Button>
                </DialogActions>
            </>
        </Dialog>
    );
};

export default CompanyTreeDialog;
