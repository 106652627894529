import React, { useEffect } from "react";
import useStyles from "../../styles/alarmNotificationStyle";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import { Typography, Button } from "@material-ui/core";
import { AutorenewRounded, RotateLeftRounded } from "@material-ui/icons";
import { table } from "../../helpers/triggeredHistoryDatatable";
import { resetTriggeredAlarmsAPI } from "../../apis/resetTriggeredAlarmsAPI";
import HasPermission from "../commons/HasPermission";

const AlarmTriggeredHistoryTable = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("alarmNotification");
    const { deviceId, shouldDisbleResetBtn } = props;

    const handleResetAlarm = async (e) => {
        if (!e.detail || e.detail === 1) {
            const successfulText = `${t("modal.successfullyResetTriggeredAlarms")}`;
            const updateResult = await resetTriggeredAlarmsAPI(
                deviceId,
                t,
                successfulText
            );
            if (updateResult) table(deviceId, t);
        }

        return false;
    };

    const handleReloadTable = (e) => {
        if (!e.detail || e.detail === 1) {
            table(deviceId, t);
        }

        return false;
    };

    useEffect(() => {
        table(deviceId, t);
    }, [deviceId, t]);

    return (
        <>
            <div className={classes.dialogTitleWithIconBtn}>
                <Typography variant="h5">{t("modal.alarmTriggeredHistory")}</Typography>
                <AutorenewRounded
                    onClick={handleReloadTable}
                    size="small"
                    className={classes.reload_btn}
                />
            </div>
            <HasPermission permission="alarm_notification_triggered_device_list">
                <Typography variant="body1" className={classes.resetTextAndBtn}>
                    {t("modal.resetTriggeredAlarm")}
                    <Button
                        variant="contained"
                        onClick={handleResetAlarm}
                        startIcon={<RotateLeftRounded />}
                        disabled={shouldDisbleResetBtn}
                        color={!shouldDisbleResetBtn ? "default" : "secondary"}
                    >
                        {t("btn.reset")}
                    </Button>
                </Typography>
            </HasPermission>
            <HOCforFullWidthTable>
                <table
                    id="triggered-history-table"
                    className="mdl-data-table dataTable"
                >
                    <thead>
                        <tr>
                            <th className="sorting">{t("table.ruleDescription")}</th>
                            <th className="sorting">{t("table.date")}</th>
                            <th className="sorting">{t("table.resetStatus")}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
        </>
    );
};

export default AlarmTriggeredHistoryTable;
