import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import { language } from "./datatableLanguage";
import * as localforage from "localforage";
import * as $ from "jquery";
import "../css/dataTable.css";
import "datatables.net";
import "datetime-moment";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { datatableErrorHandling } from "./datatableErrorHandling";
import StatusChip from "../components/commons/StatusChip";
import OSType from "../components/commons/OSType";
import EditableIconForDatatable from "../components/commons/EditableIconForDatatable";
import DeviceFunctionBlock from "../components/device/DeviceFunctionBlock";
import HasPermission from "../components/commons/HasPermission";
import DeviceOwners from "../components/commons/DeviceOwners";

export const reloadTableWithGroupId = (
    t,
    query,
    companyId,
    searchValueForURL,
    history,
    allTags,
    groupId,
    tagId,
    selectType
) => {
    table(
        t,
        query,
        companyId,
        searchValueForURL ? searchValueForURL : undefined,
        history,
        allTags,
        groupId,
        tagId,
        selectType
    );
};
export const table = async (
    t,
    query,
    companyId,
    searchValueForURL,
    history,
    allTags,
    groupId,
    tagId,
    selectType
) => {
    const company_id = await localforage.getItem("companyId");
    const token = await localforage.getItem("token");
    $.fn.dataTable.ext.errMode = "throw";
    $("#device-list-table").DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        pageLength: 25,
        ordering: true,
        ajax: {
            url: `${url}/api/v2/devices`,
            type: "GET",
            data: {
                company_id: selectType === "company" ? companyId : company_id,
                group_id: selectType === "group" ? groupId : undefined,
                tag_id: selectType === "tag" ? tagId : undefined,
                searching: searchValueForURL,
                nubis_version: query.get("nubis_version")
                    ? query.get("nubis_version")
                    : undefined,
                device_status: query.get("online_status")
                    ? query.get("online_status")
                    : undefined,
                os_type: query.get("os_type") ? query.get("os_type") : undefined,
                ora_version: query.get("ora_version")
                    ? query.get("ora_version")
                    : undefined,
                devices_offline_days: query.get("devices_offline_days")
                    ? query.get("devices_offline_days")
                    : undefined,
                task_type: query.get("task_type") ? query.get("task_type") : undefined,
                task_status: query.get("task_status")
                    ? query.get("task_status")
                    : undefined,
                realtime_type: query.get("realtime_type")
                    ? query.get("realtime_type")
                    : undefined,
                realtime_range: query.get("realtime_range")
                    ? query.get("realtime_range")
                    : undefined,
                device_model: query.get("device_model")
                    ? query.get("device_model")
                    : undefined,
                device_group: query.get("device_group")
                    ? query.get("device_group")
                    : undefined,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                datatableErrorHandling(
                    error.responseJSON ? error.responseJSON.message : ""
                );
            },
        },
        columns: [
            {
                data: "online_offline_status",
                className: "status",
                title: `${t("category.status")}`,
                searchable: false,
                createdCell: (td, data) => {
                    ReactDOM.render(<StatusChip statusLabel={data} />, td);
                },
            },
            {
                data: "os_type",
                className: "os_type",
                title: `${t("category.os_type")}`,
                searchable: false,
                createdCell: (td, data) => {
                    ReactDOM.render(<OSType os={data} />, td);
                },
            },
            {
                data: "serial_number",
                className: "serial_number",
                title: `${t("category.serialNumber")}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <div
                            /* onClick={() =>
                                history.push(
                                    `/device-list/device-detail/${rowData.id}/${rowData.os_type}`
                                )
                            } */
                            className="main-datatable-info"
                        >
                            <a href={`/device-list/device-detail/${rowData.id}/${rowData.os_type}`}>{data}</a>
                        </div>,
                        td
                    );
                },
            },
            {
                data: "ora_version",
                className: "ora_version",
                title: `${t("category.oraVersion")}`,
                render: (data) => {
                    return data ? data : "-";
                },
            },
            {
                data: "companies",
                name: "",
                className: "owners datatable-data-width-100",
                title: `${t("category.owners")}`,
                searchable: true,
                orderable: true,
                createdCell: (td, data, rowData) => {
                    const companyArr = JSON.parse(rowData.companies);
                    let companyName = companyArr.map((item, index) => {
                        return (
                            <span className="owner_name" key={index}>
                                {item.name}
                            </span>
                        );
                    });
                    ReactDOM.render(
                        companyArr && companyArr.length > 0 ? (
                            <DeviceOwners owners={companyName} />
                        ) : (
                            "-"
                        ),
                        td
                    );
                },
            },
            {
                data: "os_type",
                className: "power",
                title: `${t("category.power")}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <DeviceFunctionBlock
                            reloadTableWithGroupId={() =>
                                reloadTableWithGroupId(
                                    t,
                                    query,
                                    companyId,
                                    undefined,
                                    history,
                                    allTags,
                                    groupId,
                                    tagId,
                                    selectType
                                )
                            }
                            rowData={rowData}
                            os_type={data === "windows" ? "windows" : "android"}
                            block="power"
                            t={t}
                        />,
                        td
                    );
                },
            },
            {
                data: "os_type",
                className: "security",
                title: `${t("category.security")}`,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <HasPermission permission="single_security">
                            <DeviceFunctionBlock
                                reloadTableWithGroupId={() =>
                                    reloadTableWithGroupId(
                                        t,
                                        query,
                                        companyId,
                                        undefined,
                                        history,
                                        allTags,
                                        groupId,
                                        tagId,
                                        selectType
                                    )
                                }
                                rowData={rowData}
                                os_type={data === "windows" ? "windows" : "android"}
                                block="security"
                                t={t}
                            />
                        </HasPermission>,
                        td
                    );
                },
            },
            {
                data: "tags",
                name: "",
                title: `${t("category.tags")}`,
                className: "editable-field",
                orderable: false,
                //orderDataType: "dom-text",
                type: "string",
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <EditableIconForDatatable rowData={rowData} allTags={allTags} />,
                        td
                    );
                },
            },
        ],
    });
    searchStyleRender();
};
