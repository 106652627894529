import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateSingleDeviceVncTwoFactorAPI = async (t, deviceId, IsTwoFactorEnable, onClose) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/vnc/security/update-device-2fa`,
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Authorization': 'Bearer ' + token
            },
            data: {
                device_id: deviceId,
                is_two_factor_enable: IsTwoFactorEnable,
            },
        })

        const successfulText = IsTwoFactorEnable ? `${t('tasks.successfullyTurnOnTwoFactor')}` : `${t('tasks.successfullyTurnOffTwoFactor')}`;
        successHandling(t, successfulText);
        onClose();
        return true;

    } catch (error) {
        errorHandling(error, false);
    }

}
