import dayjs from "dayjs";
import duration from 'dayjs/plugin/duration';
import utc from 'dayjs/plugin/utc';

/**
 *
 * @param {string} type
 * @param {Array<RealTimeData<T>>} data
 * Usage:
 *  take timeseries data and filter by specific period.
 * Periods:
 *  0.5hr => 30 * 1 step per min
 *  1hr => 30 * 1 step per 2mins
 *  6hrs => 6 * 2 * 1 step per 30mins
 *  12hrs => 12 * 2 * 1 step per 30mins
 *  24hrs => 24 * 1 step per 60mins
 */

dayjs.extend(duration);
dayjs.extend(utc);

const periodConfigs = {
    30: {
        text: '0.5hr',
        step: 30,
        duration: dayjs.duration(1, 'minute')
    },
    60: {
        text: '1hr',
        step: 30,
        duration: dayjs.duration(2, 'minutes')
    },
    360: {
        text: '6hrs',
        step: 12,
        duration: dayjs.duration(30, 'minutes')
    },
    720: {
        text: '12hrs',
        step: 24,
        duration: dayjs.duration(30, 'minutes')
    },
    1440: {
        text: '24hrs',
        step: 24,
        duration: dayjs.duration(60, 'minutes')
    },
}
const dateFormat = 'YYYY-MM-DD HH:mm';

export const timestampCherryPick = (type, data) => {
    if (!data) {
        return 0;
    }
    const period = periodConfigs[type];
    const filteredData = [];
    let current = null;

    current = dayjs(data[0]['created_at'], dateFormat);
    for (let d of data) {
        if (d['created_at'] === current.format(dateFormat)) {
            filteredData.push(d);
            current = current.subtract(period.duration);
        }
    }

    return filteredData;
}

export const cpuTemperatureTimestampCherryPick = (type, data) => {
    if (!data) {
        return 0;
    }

    const normalize = (w) => dayjs(w.replace(/\.0{6}/, '')).utc().format(dateFormat);

    const period = periodConfigs[type];
    const filteredData = {
        label: [],
        dataSets: data.dataSets.map(ds => (
            {
                label: ds.label,
                data: []
            }
        ))
    }
    let current = null;

    current = dayjs(normalize(data.label[data.label.length - 1]), dateFormat);
    for (let idx = data.label.length - 1; idx >= 0; idx--) {
        if (normalize(data.label[idx]) === current.format(dateFormat)) {
            filteredData.label.unshift(data.label[idx]);
            filteredData.dataSets.forEach((ds, coreIdx) => {
                ds.data.unshift(data.dataSets[coreIdx].data[idx]);
            })
            current = current.subtract(period.duration);
        }
    }

    return filteredData;
}
