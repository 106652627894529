export let imageExtensionArray = ['svg', 'jpg', 'jpeg', 'png', 'bmp', 'gif', 'tiff', 'tif', 'raw', 'ico'];
export let videoExtensionArray = ['webm', 'mpg', 'mp2', 'mpeg', 'mpe', 'mpv', 'ogg', 'mp4', 'm4p', 'm4v', 'avi', 'wmv', 'mov', 'qt', 'flv', 'swf', 'avchd', 'mkv'];
export let excelExtensionArray = ['xlsx', 'xls', 'xlsb', 'csv', 'xlsm'];
export let pdfExtensionArray = ['pdf'];
export let applicationExtensionArray = ['exe', 'apk', 'appx', 'paf', 'xap', 'msi'];
export let compressedFileExtensionArray = ['zip', 'rar', '7z', 'jar', 'gz', 'lz', 'pyk', 'ezip', 'ecab', 'cab', 'egg', 'dmg'];
export let wordExtensionArray = ['doc', 'docm', 'docx', 'dot', 'dotx', 'dotm', 'docb', 'pages', 'txt'];
export let webFileExtensionArray = ['html', 'css', 'js', 'ts', 'scss', 'sass', 'xml', 'java', 'php', 'json'];
export let designFileExtensionArray = ['ai', 'psd', 'eps', 'skecth'];
export let supportWindowsAutoLaunchFileType = ['msi', 'exe'];
export let supportAndroidAutoLaunchFileType = ['apk'];
