import React, { useState, forwardRef, useEffect, useRef } from 'react';
import { Typography, Paper, Grid, FormControl } from '@material-ui/core';
import useStyles from '../../styles/deviceTaskStyle';
import SelectDevices from '../device/SelectDevices';
import SelectPort from './SelectPort';
import SelectStatusVoltage from './SelectStatusVoltage';
import SelectDate from '../device/SelectDate';
import { useTranslation } from "../../langs/useTranslation";
import { Formik, Form } from 'formik';
import CommandConfirmDialog from '../commons/CommandConfirmDialog';
import SendRoundedIcon from '@material-ui/icons/SendRounded';
import * as localforage from 'localforage';
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { IOSetUpAPI } from '../../apis/IOSetUpAPI';
import 'datetime-moment';
import DeviceDatatable from '../device/DeviceDatatableForCommand';
import { table } from '../../helpers/taskDeviceTable';
import { handleClose, handleHasResponseStatus } from '../../helpers/commandConfirmationDialogAction';
import { GroupOrTagRequiredText, ExcelFileRequiredMsg, RequiredText, DateTimeComparisonText } from '../commons/ValidationText';
import { checkIfGroupsOrTagsSelected, getSelectedDevicesByTableOrExcel, taskExcuteDateTime } from '../../helpers/formValidationForMultiDeviceTask';
import { ioValuesSchema, ioInitialValues } from '../../config/multiDeviceTaskValuesSchema';

const IOSetUpForm = forwardRef((props, ref) => {
    const mounted = useRef()
    const classes = useStyles();
    const { executeType, deviceId, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName, storedDatePickerValueToLocalforageName, storedPortValueToLocalforageName, storedDeviceIdsToLocalforageName,storedDeviceValueToLocalforageName, successfulText, dispatch, resetAllStoredValueInLocalforage } = props;
    const { t } = useTranslation('task');
    const [open, setOpen] = useState(false);
    const [values, setValues] = useState({});
    const [selectedIds, setSelectedIds] = useState(true);
    const [datetime, setDatetime] = useState(true);
    const [filterDevicesBy, setFilterDevicesBy] = useState('');
    const [port, setPort] = useState();
    const [selectedGroupIds, setSelectedGroupIds] = useState([]);
    const [selectedTagIds, setSelectedTagIds] = useState([]);
    const [isExecuting, setIsExecuting ] = useState(false);
    const [devices, setDevices] = useState();
    const relationName = 'ioVoltages';
    
    const handleExecuteAction = async() => {
        setIsExecuting(true);
        
        const responseStatus = await IOSetUpAPI(deviceId, devices, selectedIds, values, executeType, datetime, t, successfulText);
        if(responseStatus) handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        
        dispatch({type: 'stopLoading'});
    };
    
    
    const updateDeviceEligibility = async(io_port) => {
        await localforage.setItem(storedPortValueToLocalforageName, io_port);
        // update table when port selected ,and device is not selected by being imported
        if(filterDevicesBy !== 'excel_import'){
            const selectedGroupIds = await localforage.getItem(storedGroupIdsToLocalforageName)
            const selectedTagIds = await localforage.getItem(storedTagIdsToLocalforageName)
            if(selectedGroupIds) setSelectedGroupIds(selectedGroupIds)
            if(selectedTagIds) setSelectedTagIds(selectedTagIds)
            const port = io_port;
            table(t, filterDevicesBy, selectedGroupIds, selectedTagIds, relationName, port, undefined, storedDeviceIdsToLocalforageName)
        }
    }


    useEffect(()=>{
        if (!mounted.current) {
            resetAllStoredValueInLocalforage();
            mounted.current = true;
        }
    })
    return(
        <Formik
            initialValues={ioInitialValues}
            validationSchema={ioValuesSchema}
            onSubmit={ async(values) => {

                dispatch({type: 'showLoading'});
                await checkIfGroupsOrTagsSelected(setSelectedGroupIds, setSelectedTagIds, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName);
                
                // setup the data from values for task execution
                let selectedDeviceIds = await getSelectedDevicesByTableOrExcel(values.devices, storedDeviceIdsToLocalforageName, storedDeviceValueToLocalforageName);
                let datetime = await taskExcuteDateTime(values.date, storedDatePickerValueToLocalforageName);
                setValues(values);
                setDevices(values.devices);
                setDatetime(datetime);
                setSelectedIds(selectedDeviceIds);
                setFilterDevicesBy(values.devices);

                if( selectedDeviceIds && datetime ){
                    setOpen(true);
                } else {
                    dispatch({type: 'stopLoading'}); 
                }
            }}
        >
        {({
            values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting
        }) => (
            <Form onSubmit={handleSubmit}>
                <FormControl fullWidth={true} ref={ref}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Paper className={classes.fullWidthPaper}>
                                <Typography variant="h6">{t('task.step')}1. {t('task.selectDevices')}</Typography>
                                <SelectDevices 
                                    storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                                    storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName} 
                                    storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                                    storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                                    storedGroupIdsAndTagIds={true}
                                    filterDevicesBy={filterDevicesBy}
                                    formikName="devices" 
                                    isSubmitting={isSubmitting}
                                    formikOnChange={(e) => {
                                        setFilterDevicesBy(e.target.value)
                                        return handleChange(e)}} 
                                    formikValue={values.devices} 
                                    formikError={(errors.devices && touched.devices && errors.devices)}
                                    relationName={relationName} 
                                    selectedGroupIds={selectedGroupIds}
                                    selectedTagIds={selectedTagIds}
                                    port={port}
                                />
                                {(filterDevicesBy !== 'excel_import') && !selectedGroupIds && !selectedTagIds ? <GroupOrTagRequiredText /> : ''}
                                { filterDevicesBy === 'excel_import' && !selectedIds ? <ExcelFileRequiredMsg /> : ''}
                            </Paper>
                            <Paper className={classes.fullWidthPaper}>
                                <Typography variant="h6">{t('task.step')}2. {t('task.selectAPort')}</Typography>
                                <div className={classes.radioSelectGroup}> 
                                <FormControl component="div" fullWidth={true} required>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className={classes.oneDropDown}>
                                            <SelectPort 
                                                formikName="port" 
                                                formikOnChange={(e) => {
                                                    setPort(e.target.value)
                                                    updateDeviceEligibility(e.target.value)
                                                    return handleChange(e)}
                                                }
                                                formikOnBlur={handleBlur}
                                                formikValue={values.port} 
                                                formikError={(errors.port && touched.port && errors.port)}
                                            />
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={8} lg={6} xl={4}>
                                            {filterDevicesBy !== 'excel_import' &&
                                                <>
                                                    <DeviceDatatable
                                                        selectedGroupIds={selectedGroupIds}
                                                        selectedTagIds={selectedTagIds}
                                                        relationName={relationName} 
                                                        filterDevicesBy={filterDevicesBy} 
                                                        storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName} 
                                                    />
                                                    {!selectedIds ? <RequiredText /> : '' }
                                                </>
                                            }
                                        </Grid>
                                        </Grid>
                                    </FormControl>
                                </div>
                            </Paper>
                            <Paper className={classes.fullWidthPaper}>
                                <Typography variant="h6">{t('task.step')}3. {t('task.selectStatusOrVoltage')}</Typography>
                                <SelectStatusVoltage 
                                    formikName="voltage" 
                                    formikOnChange={handleChange}
                                    formikOnBlur={handleBlur}
                                    formikValue={values.voltage} 
                                    formikError={(errors.voltage && touched.voltage && errors.voltage)}
                                    currentPort={port}
                                    singleDevice={false}
                                />
                            </Paper>
                            <Paper className={classes.fullWidthPaper}>
                                <Typography variant="h6">{t('task.step')}4. {t('task.selectDate')}</Typography>
                                <SelectDate 
                                    storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName} 
                                    formikName="date" 
                                    formikOnChange={handleChange} 
                                    formikValue={values.date} 
                                    formikError={(errors.date && touched.date && errors.date)}
                                />
                                {!datetime ? <DateTimeComparisonText /> : ''}
                            </Paper>
                            <div className="submit-back-btn-wrap"> 
                                <StyledSubmitBtn startIcon={<SendRoundedIcon />} btnText={t('btn.confirmBtn')} type="submit" />
                                <BackBtn />
                                <CommandConfirmDialog
                                    isExecuting={isExecuting}
                                    okBtnOnClick={handleExecuteAction}
                                    backBtnOnClick={() => handleClose(setOpen, dispatch, setIsExecuting)}
                                    open={open}
                                    dialogTitle={t('task.ioSetUpName')}
                                    dialogContent={t('modal.ioSetUpDescription')}
                                    dialogContentImportant={t('modal.ioSetUpDescriptionImportant')}
                                />
                            </div>
                        </Grid>
                    </Grid>
                </FormControl>
            </Form>
        )}
        </Formik>
    )
})

export default IOSetUpForm;