import React, { useState, useEffect } from "react";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
// // import CropRoundedIcon from "@material-ui/icons/CropRounded";
import ListItemLink from "../commons/ListItemLink";
// import PowerSettingsNewRoundedIcon from "@material-ui/icons/PowerSettingsNewRounded";
import ImportantDevicesRoundedIcon from "@material-ui/icons/ImportantDevicesRounded";
import SyncAltRoundedIcon from "@material-ui/icons/SyncAltRounded";
// import FileDeploymentIcon from "../../assets/images/filedeployment-icon.svg";
import ScreenRotationRoundedIcon from "@material-ui/icons/ScreenRotationRounded";
// import ORALogo from "../../assets/images/ora-logo.svg";
// import EventNoteIcon from "@material-ui/icons/EventNote";
import { useParams } from "react-router-dom";
import { useTranslation } from "../../langs/useTranslation";
import PeripheralsDialog from "../peripherals/PeripheralsDialog";
import IOSetUpDialog from "../ioSetUp/IOSetUpDialog";
import UsbConnectionsDialog from "../usbConnections/UsbConnectionsDialog";
import HasPermission from "../commons/HasPermission";
import { table } from "../../helpers/deviceTaskHistoryDatatable";
import SingleDeviceVncMenu from "../vnc/SingleDeviceVncMenu";
import UsbIcon from "@material-ui/icons/Usb";
import TwoMonitor from "../2ndMonitor/TwoMonitor";
import AppRunningStatus from "../appRunningStatus/AppRunningStatus";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import AppsIcon from "@material-ui/icons/Apps";
import OraSendLogDialog from "./OraSendLogDialog";
import ImageAspectRatioIcon from "@material-ui/icons/ImageAspectRatio";
import BackupIcon from "@material-ui/icons/Backup";
import LocalFileUpload from "../localFileUpload/LocalFileUpload";
import EventLog from "../eventLog/EventLog";

const DeviceGroupManagementBtnList = (props) => {
  const classes = useStyles();
  const { device_id, device_os_type } = useParams();
  const {
    deviceData,
    onClickSetVNC,
    serialNumber,
    // VNCLink, nubisVersion, onlineStatus,
    handleResendDialogOpen,
    getDeviceDetail,
  } = props;
  const { t } = useTranslation("device");
  const [peripheralOpen, setPeripheralOpen] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [ioSetUpOpen, setIOSetUpOpen] = useState(false);
  const [eventLogOpen, setEventLogOpen] = useState(false);
  const [usbConnectionsOpen, setUsbConnectionsOpen] = useState(false);
  const [localFileUpload, setLocalFileUploadOpen] = useState(false);
  const [twoMonitorOpen, settwoMonitorOpen] = useState(false);
  const [appRunningStatusOpen, setAppRunningStatusOpen] = useState(false);
  const [oranSendLogOpen, setOranSendLogOpen] = useState(false);

  // const handlePeripheralClickOpen = () => {
  //     setPeripheralOpen(true);
  // };

  const handlePeripheralClose = () => {
    setPeripheralOpen(false);
  };

  const handleIOSetUpClickOpen = () => {
    setIOSetUpOpen(true);
  };

  const handleUsbConnectionsClickOpen = () => {
    setUsbConnectionsOpen(true);
  };
  const handleIOSetUpClose = () => {
    setIOSetUpOpen(false);
  };
  const handleUsbConnectionsClose = () => {
    setUsbConnectionsOpen(false);
  };
  const handle2ndMonitorClickOpen = () => {
    settwoMonitorOpen(true);
  };
  const handle2ndMonitorClickClose = () => {
    settwoMonitorOpen(false);
  };
  const handleAppRunningStatusClickOpen = () => {
    setAppRunningStatusOpen(true);
  };
  const handleAppRunningStatusClickClose = () => {
    setAppRunningStatusOpen(false);
  };
  const handleOraSendLogClickOpen = () => {
    setOranSendLogOpen(true);
  };
  const handleOraSendLogClickClose = () => {
    setOranSendLogOpen(false);
  };
  const handleLocalFileUploadClickOpen = () => {
    setLocalFileUploadOpen(true);
  };
  // const handleEventLogClickOpen = () => {
  //   setEventLogOpen(true);
  // };
  useEffect(() => {
    if (ioSetUpOpen === false) table(t, device_id, handleResendDialogOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ioSetUpOpen]);

  useEffect(() => {
    if (peripheralOpen === false) table(t, device_id, handleResendDialogOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [peripheralOpen]);

  return (
    <List
      className={`${classes.deviceGroupManagmentBtnList} ${classes.singleDeviceTaskBtnList}`}
    >
      <HasPermission permission="single_vnc">
        {/* set a new VNC link regardless of Nubis pro or free */}
        {/* { (onlineStatus === 'offline') || (nubisVersion === 'nubis_pro') || (nubisVersion === 'nubis_free' && !VNCLink) || (!nubisVersion) ?
                (  */}
        <SingleDeviceVncMenu
          vncIConOnClick={onClickSetVNC}
          deviceId={device_id}
          deviceData={deviceData}
          getDeviceDetail={getDeviceDetail}
        />
        {/* )
                :
                (
                    <ListItem component="a" href={VNCLink} target="_blank">
                        <ListItemIcon><img src={VNCIcon} alt="vnc icon" /></ListItemIcon>
                        <ListItemText>{t('tasks.vnc')}</ListItemText>
                    </ListItem>
                )
            }   */}
      </HasPermission>
      {/* <HasPermission permission="single_deploy_file">
        <ListItemLink to={`/deploy-file/${device_id}/${device_os_type}`}>
          <ListItemIcon>
            <img src={FileDeploymentIcon} alt="file deployment icon" />
          </ListItemIcon>
          <ListItemText>{t("tasks.deploy_file")}</ListItemText>
        </ListItemLink>
      </HasPermission>
      <HasPermission permission="single_restart_system">
        <ListItemLink to={`/restart-system/${device_id}/${device_os_type}`}>
          <ListItemIcon>
            <PowerSettingsNewRoundedIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.restart_system")}</ListItemText>
        </ListItemLink>
      </HasPermission> */}
      {/* {device_os_type === 'android' ?
                ''
                :
                <HasPermission permission="single_bios_update" >
                    <ListItemLink to={`/bios-update/${device_id}/${device_os_type}`}>
                        <ListItemIcon><SystemUpdateAltRoundedIcon /></ListItemIcon>
                        <ListItemText>{t('tasks.bios_update')}</ListItemText>
                    </ListItemLink>
                </HasPermission>
            } */}
      <HasPermission permission="single_kiosk_mode">
        <ListItemLink to={`/kiosk-mode/${device_id}/${device_os_type}`}>
          <ListItemIcon>
            <ImportantDevicesRoundedIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.kiosk_mode")}</ListItemText>
        </ListItemLink>
      </HasPermission>
      {/* <HasPermission permission="single_update_ora">
        <ListItemLink to={`/ora-update/${device_id}/${device_os_type}`}>
          <ListItemIcon>
            <img src={ORALogo} alt="ora logo" />
          </ListItemIcon>
          <ListItemText>{t("tasks.update_ora")}</ListItemText>
        </ListItemLink>
      </HasPermission> */}
      {/* <HasPermission permission="single_screenshot">
                <ListItemLink to={`/screenshot/${device_id}/${device_os_type}`}>
                    <ListItemIcon><CropRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.screenshot')}</ListItemText>
                </ListItemLink>
            </HasPermission> */}
      <HasPermission permission="single_io_set_up">
        <ListItem onClick={handleIOSetUpClickOpen}>
          <ListItemIcon>
            <SyncAltRoundedIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.io_setup")}</ListItemText>
        </ListItem>
      </HasPermission>
      <HasPermission permission="single_rotation">
        <ListItemLink to={`/rotation/${device_id}/${device_os_type}`}>
          <ListItemIcon>
            <ScreenRotationRoundedIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.rotation")}</ListItemText>
        </ListItemLink>
      </HasPermission>
      <HasPermission permission="single_usb_connections">
        <ListItem onClick={handleUsbConnectionsClickOpen}>
          <ListItemIcon>
            <UsbIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.useConnections")}</ListItemText>
        </ListItem>
      </HasPermission>
      <HasPermission permission="single_display_information">
        <ListItem onClick={handle2ndMonitorClickOpen}>
          <ListItemIcon>
            <DesktopWindowsIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.2ndMonitor")}</ListItemText>
        </ListItem>
      </HasPermission>
      <HasPermission permission="single_app_running_status">
        <ListItem onClick={handleAppRunningStatusClickOpen}>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.appRunningStatus")}</ListItemText>
        </ListItem>
      </HasPermission>
      {/* <HasPermission permission="single_erma_history">
                <ListItemLink to={`/erma-history/${device_id}/${device_os_type}`} disabled>
                    <ListItemIcon><HistoryRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.erma_history')}</ListItemText>
                </ListItemLink>
            </HasPermission>
      {/* <HasPermission permission="single_peripherals">
                <ListItem  button onClick={handlePeripheralClickOpen} disabled={device_os_type === 'android' ? true : false}>
                    <ListItemIcon><DeviceHubRoundedIcon /></ListItemIcon>
                    <ListItemText>{t('tasks.peripherals')}</ListItemText>
                </ListItem>
            </HasPermission> */}
      <HasPermission permission="ora_logs">
        <ListItem onClick={handleOraSendLogClickOpen}>
          <ListItemIcon>
            <ImageAspectRatioIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.upload_logs")}</ListItemText>
        </ListItem>
      </HasPermission>
      <HasPermission permission="single_local_file_upload">
        <ListItem onClick={handleLocalFileUploadClickOpen}>
          <ListItemIcon>
            <BackupIcon />
          </ListItemIcon>
          <ListItemText>{t("tasks.zip_file_upload")}</ListItemText>
        </ListItem>
      </HasPermission>
      {/* <ListItem onClick={handleEventLogClickOpen}>
        <ListItemIcon>
          <EventNoteIcon />
        </ListItemIcon>
        <ListItemText>{t("tasks.event_log")}</ListItemText>
      </ListItem> */}
      <PeripheralsDialog
        open={peripheralOpen}
        onClose={handlePeripheralClose}
        deviceId={device_id}
        serialNumber={serialNumber}
      />
      <IOSetUpDialog
        open={ioSetUpOpen}
        onClose={handleIOSetUpClose}
        deviceId={device_id}
      />
      <UsbConnectionsDialog
        open={usbConnectionsOpen}
        onClose={handleUsbConnectionsClose}
        deviceId={device_id}
        device_os_type={device_os_type}
      />
      <TwoMonitor
        open={twoMonitorOpen}
        onClose={handle2ndMonitorClickClose}
        deviceId={device_id}
      />
      <AppRunningStatus
        open={appRunningStatusOpen}
        onClose={handleAppRunningStatusClickClose}
        deviceId={device_id}
        device_os_type={device_os_type}
      />
      <OraSendLogDialog
        open={oranSendLogOpen}
        onClose={handleOraSendLogClickClose}
        deviceId={device_id}
        loading={dialogLoading}
        setLoading={setDialogLoading}
      />
      <LocalFileUpload
        open={localFileUpload}
        onClose={setLocalFileUploadOpen}
        deviceId={device_id}
        device_os_type={device_os_type}
        loading={dialogLoading}
        setLoading={setDialogLoading}
      />
      <EventLog
        open={eventLogOpen}
        onClose={setEventLogOpen}
        deviceId={device_id}
        device_os_type={device_os_type}
        loading={dialogLoading}
        setLoading={setDialogLoading}
      />
    </List>
  );
};

export default DeviceGroupManagementBtnList;
