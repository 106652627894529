import React, { useState, useContext, useEffect, useRef } from 'react';
import { Typography, Grid, FormControl } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext } from '../../App';
import ConfirmAndCancelBtn from '../commons/ConfirmAndCancelBtn';
import * as localforage from 'localforage';
import SelectAlarmPolicy from './SelectAlarmPolicy';
import ManageEmailForAlarmNotification from './ManageEmailForAlarmNotification';
import NoteTextfield from '../commons/NoteTextfield';
import ConfirmDialog from '../commons/ConfirmDialog';
import { createAlarmPolicyAPI } from '../../apis/createAlarmPolicyAPI';
import { updateAlarmPolicyAPI } from '../../apis/updateAlarmPolicyAPI';
import { operatorDeterminator } from '../../helpers/regex';
import isEqual from 'lodash/isEqual';

const AddOrEditAlarmPolicyForm = (props) => {
    const mounted = useRef()
    const { t } = useTranslation('alarmNotification');
    const { onClose, storedRuleValueToLocalforageName, storedEmailToLocalforageName, storedOriginalEmailsToLocalforageName, createOrUpdate, policyToBeUpdated } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [rule, setRule] = useState(true);
    const [ruleValid, setRuleValid] = useState(true);
    const [triggerValueProvided, setTriggerValueProvided] = useState(true);
    const [emails, setEmails] = useState(true);
    const [originalNote] = useState(policyToBeUpdated ? policyToBeUpdated.note : '');
    const [note, setNote] = useState(policyToBeUpdated ? policyToBeUpdated.note : '');
    const [originalRule, setOrginalRule] = useState();
    const [originalEmails] = useState();
    const [sendCommand, setCommand] = useState(false);
    const [isExecuting, setIsExecuting] = useState(false);

    const storedExistingValueToLocalforage = async () => {
        let existingRuleId = policyToBeUpdated.alarm_policy_option_id;
        let existingRelation = operatorDeterminator(policyToBeUpdated.operator);
        let existingValue = policyToBeUpdated.value;
        let existingUnit = policyToBeUpdated.alarm_policy_option.unit;
        let originalRule = { id: existingRuleId, operator: existingRelation, value: existingValue, unit: existingUnit };
        await localforage.setItem(storedRuleValueToLocalforageName, { id: existingRuleId, operator: existingRelation, value: existingValue, unit: existingUnit });
        await localforage.setItem(storedOriginalEmailsToLocalforageName, policyToBeUpdated.emails);
        setOrginalRule(originalRule);
    }

    const handleNoteChange = (e) => {
        setNote(e.target.value);
    }

    const handleConfirmDialogOpen = async () => {

        dispatch({ type: 'showLoading' });

        const rule = await localforage.getItem(storedRuleValueToLocalforageName);
        const emails = await localforage.getItem(storedEmailToLocalforageName);
        const originalEmails = await localforage.getItem(storedOriginalEmailsToLocalforageName);

        setNote(note);
        setRule(rule ? rule : false);
        setEmails(emails ? emails : false);

        if (!rule) {
            setRuleValid(false);
            setTriggerValueProvided(false);
        }

        // check if each value rule needed is provided
        if (rule) {
            let ruleHasId = rule.id ? true : false;
            let ruleHasOperator = rule.operator ? true : false;
            let ruleHasValue = rule.value ? true : false;
            setRuleValid(ruleHasId && ruleHasOperator ? true : false);
            setTriggerValueProvided(ruleHasValue ? true : false);

            let sendCommand = !isEqual(originalRule, rule); // if rule and original rule are not equal, then send command
            let emailsUpdated = !isEqual(originalEmails, emails);
            setCommand(sendCommand);

            // no data is updated, then close the dialog
            if (!sendCommand && !emailsUpdated && (originalNote === note)) {
                onClose();
                return;
            }

            if (ruleHasId && ruleHasOperator && ruleHasValue && emails) {
                setConfirmDialogOpen(true);
                return;
            }
        }

        dispatch({ type: 'stopLoading' });
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
        dispatch({ type: 'stopLoading' });
    };

    const handleExecuteAction = async () => {
        setIsExecuting(true);
        let responseStatus = false;
        dispatch({ type: 'showLoading' });

        if (createOrUpdate === 'create') {
            let successfulText = `${t('modal.successfullyCreateAlarmPolicy')}`;
            responseStatus = await createAlarmPolicyAPI(rule, note, emails, t, successfulText);
        }

        if (createOrUpdate === 'update') {
            let successfulText = `${t('modal.successfullyUpdateAlarmPolicy')}`;
            responseStatus = await updateAlarmPolicyAPI(policyToBeUpdated.id, rule, note, emails, sendCommand, t, successfulText);
        }

        if (responseStatus) {
            handleConfirmDialogClose();
            onClose();
        }

        dispatch({ type: 'stopLoading' });
    }

    useEffect(() => {
        if (!mounted.current) {
            dispatch({ type: 'stopLoading' });
            if (policyToBeUpdated) {
                storedExistingValueToLocalforage();
            }
            mounted.current = true;
        }
    })

    return (
        <FormControl fullWidth={true}>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Typography variant="body2">
                        {t('modal.createOrUpdateAlarmPolicyDescription')}
                    </Typography>
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SelectAlarmPolicy
                        policyToBeUpdated={policyToBeUpdated}
                        storedRuleValueToLocalforageName={storedRuleValueToLocalforageName}
                        ruleProvided={ruleValid}
                        triggerValueProvided={triggerValueProvided}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <ManageEmailForAlarmNotification
                        policyToBeUpdated={policyToBeUpdated}
                        storedEmailToLocalforageName={storedEmailToLocalforageName}
                        emailValueProvided={originalEmails}
                    />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <NoteTextfield
                        defaultValue={policyToBeUpdated ? policyToBeUpdated.note : ''}
                        onChange={(e) => handleNoteChange(e)}
                        fullWidth={true}
                    />
                </Grid>
            </Grid>
            <ConfirmAndCancelBtn
                styledSubmitBtnOnClick={handleConfirmDialogOpen}
                cancelBtnOnClick={onClose} />
            <ConfirmDialog
                isExecuting={isExecuting}
                okBtnOnClick={handleExecuteAction}
                backBtnOnClick={handleConfirmDialogClose}
                onClose={handleConfirmDialogClose}
                open={confirmDialogOpen}
                dialogTitle={createOrUpdate === 'create' ? `${t('modal.confirmCreateAlarmPolicy')}` : `${t('modal.confirmUpdateAlarmPolicy')}`}
                dialogContent={createOrUpdate === 'create' ? `${t('modal.confirmCreateAlarmPolicyDescription')}` : `${t('modal.confirmUpdateAlarmPolicyDescription')}`}
            />
        </FormControl>
    )
}

export default AddOrEditAlarmPolicyForm;
