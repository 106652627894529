import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Typography } from "@material-ui/core";
import useStyles from "../../styles/adminUtilityStyle";
import { url } from "../../apis/apiSetup";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import "../../css/dataTable.css";
import * as localforage from "localforage";
import * as $ from "jquery";
import "datatables.net";
import "datatables.net-autofill";
import "datatables.net-buttons";
import "datatables.net-fixedheader";
import "datatables.net-keytable";
import "datatables.net-rowreorder";
import "datatables.net-scroller";
import "datatables.net-searchpanes";
import {
    AddBoxRounded,
    AutorenewRounded,
    DeleteRounded,
    EditRounded,
} from "@material-ui/icons";
import UtilityManagementDialog from "./UtilityManagementDialog";
import RemoveUtilityManagementDialog from "./RemoveUtilityManagementDialog";
import { searchStyleRender } from "../../helpers/datatableSearchStyleRender";
import DeviceOwners from "../commons/DeviceOwners";
import MultipleLineForDataTableColumn from "../commons/MultipleLineForDataTableColumn";
import { language } from "../../helpers/datatableLanguage";

const UtilityManagementList = () => {
    const mounted = useRef();
    const classes = useStyles();
    const { t } = useTranslation("adminUtility");
    const [editDialigOpen, setEditDialogOpen] = useState(false);
    const [removeDialigOpen, setRemoveDialogOpen] = useState(false);
    const [fwData, setFwData] = useState();

    const handleReloadDataTable = () => {
        table();
    };

    const table = async () => {
        const token = await localforage.getItem("token");
        $("#utility-management-table").DataTable({
            processing: true,
            serverSide: true,
            language: language(t),
            searching: true,
            filter: false,
            deferRender: true,
            info: true,
            autoWidth: false,
            stateSave: false,
            destroy: true,
            pageLength: 15,
            ordering: false,
            ajax: {
                url: `${url}/api/v2/admin-utility/utility/dataTable`,
                type: "GET",
                dataSrc: "data",
                beforeSend: (request) => {
                    request.setRequestHeader("Authorization", "Bearer " + token);
                    request.setRequestHeader("Accept", "application/json");
                },
                error: (error) => { },
            },
            columns: [
                {
                    data: "utility_company_model_firmware_maps",
                    title: `${t("table.modelName")}`,
                    name: "utilityCompanyModelFirmwareMaps.deviceModel.name",
                    searchable: true,
                    render: (data) => {
                        const modelNames = data
                            .map((o) => {
                                return o.device_model ? o.device_model.name : "";
                            })
                            .filter((o) => o);
                        const uniqueModelNames = [...new Set(modelNames)];
                        return uniqueModelNames.length > 0
                            ? uniqueModelNames.join(", ")
                            : "-";
                    },
                },
                {
                    data: "utility_company_model_firmware_maps",
                    name: "utilityCompanyModelFirmwareMaps.firmware.name",
                    title: `${t("table.utility")}`,
                    searchable: true,
                    createdCell: (td, data) => {
                        const fileNames = data
                            .map((o) => {
                                return o.firmware ? o.firmware.name : "";
                            })
                            .filter((o) => o);
                        const uniqueFileNames = [...new Set(fileNames)];
                        ReactDOM.render(
                            <MultipleLineForDataTableColumn
                                datatableData={uniqueFileNames}
                            />,
                            td
                        );
                    },
                },
                {
                    data: "utility_company_model_firmware_maps",
                    title: `${t("table.company")}`,
                    name: "utilityCompanyModelFirmwareMaps.company.name",
                    searchable: true,
                    createdCell: (td, data) => {
                        let companyNames = data
                            .map((o) => {
                                return o.company ? o.company.name : "";
                            })
                            .filter((o) => o);
                        const uniqueCompanyNames = [...new Set(companyNames)];
                        ReactDOM.render(<DeviceOwners owners={uniqueCompanyNames} />, td);
                    },
                },
                {
                    data: "note",
                    title: `${t("table.note")}`,
                    searchable: true,
                    render: (data) => {
                        return data ? data : "-";
                    },
                },
                {
                    data: "id",
                    title: `${t("table.action")}`,
                    className: "action",
                    searchable: false,
                    createdCell: (td, data, rowData) => {
                        ReactDOM.render(
                            <>
                                <EditRounded onClick={() => handleEditDialogOpen(rowData)} />
                                <DeleteRounded
                                    onClick={() => handleRemoveDialogOpen(rowData)}
                                />
                            </>,
                            td
                        );
                    },
                },
            ],
        });
        // render Material UI Search Input
        searchStyleRender();
    };

    const handleCreateDialogOpen = () => {
        setFwData();
        setEditDialogOpen(true);
    };

    const handleEditDialogOpen = (data) => {
        setFwData(data);
        setEditDialogOpen(true);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        table();
    };

    const handleRemoveDialogOpen = (data) => {
        setFwData(data);
        setRemoveDialogOpen(true);
    };

    const handleRemoveDialogClose = (data) => {
        setFwData(data);
        setRemoveDialogOpen(false);
        table();
    };

    useEffect(() => {
        if (!mounted.current) {
            table();
            mounted.current = true;
        }
    });

    return (
        <div>
            <Typography variant="h5" className={classes.tableTitle}>
                {t("table.utilityManagementTitle")}
                <AutorenewRounded
                    onClick={handleReloadDataTable}
                    size="small"
                    className={classes.reload_btn}
                />
                <AddBoxRounded
                    onClick={handleCreateDialogOpen}
                    size="small"
                    className={classes.listBtn}
                />
            </Typography>
            <HOCforFullWidthTable>
                <table
                    id="utility-management-table"
                    className="mdl-data-table dataTable no-footer dtr-inline"
                >
                    <thead>
                        <tr>
                            <th>{t("table.modelName")}</th>
                            <th>{t("table.utilities")}</th>
                            <th>{t("table.company")}</th>
                            <th>{t("table.note")}</th>
                            <th>{t("table.action")}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
            <UtilityManagementDialog
                open={editDialigOpen}
                onClose={handleEditDialogClose}
                fwData={fwData}
            />
            <RemoveUtilityManagementDialog
                open={removeDialigOpen}
                onClose={handleRemoveDialogClose}
                fwData={fwData}
            />
        </div>
    );
};

export default UtilityManagementList;
