import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiBreadcrumbs-root': {
            marginBottom: theme.spacing(3),
        },
        '& .MuiSvgIcon-root': {
            cursor: 'pointer'
        },
        '& table tbody td.file-name .MuiInputBase-input.Mui-disabled': {
            color: '#666 !important',
            fontWeight: 'bold !important'
        }
    },
    pageTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    tableMoveTopReset: {
        paddingTop: theme.spacing(2),
        marginBottom: '0 !important',
        '& table tbody td.action .MuiSvgIcon-root': {
            marginRight: '8px !important',
        }
    },
    tableMoveBottomReset: {
        paddingBottom: 0,
        [theme.breakpoints.down('sm')]: {
            padding: '12px 20px 0 !important',
        },
    },
    storagePercentageLinearProgressWrap: {
        position: 'relative !important',
        width: '30% !important',
        minWidth: '300px !important',
        '& .MuiLinearProgress-root': {
            height: '4px !important',
            top: '15px',
            backgroundColor: '#ddd !important',
        },
        '& .MuiTypography-caption': {
            position: 'absolute !important',
            zIndex: '2 !important',
            top: '-6px !important',
            color: '#777',
        }
    },fullLinear:{
        '& .MuiLinearProgress-bar':{
            background:'#ff9e40'
        }
    }
}));

export default useStyles;