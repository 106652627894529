import React, { useEffect } from 'react';
import '../css/redirecting.css';
import { Typography } from '@material-ui/core';
import { useTranslation } from "../langs/useTranslation";
import { authLogin } from '../apis/loginAPI';
import LoadingIndicator from '../components/commons/LoadingIndicator';
import NubisLogo from '../assets/images/nubis_logo.svg';

const Redirecting = () => {
    const { t } = useTranslation('common');

    useEffect(() => {
        authLogin()
    }, [])
    return (
        <div className="root">
            <div className="siteLogoLink_img" >
                <img src={NubisLogo} alt="nubis logo" />
                Nubis
            </div>
            <LoadingIndicator />
            <Typography variant="h5" color="secondary">{t('redirecting.text')}</Typography>
        </div>
    )
}

export default Redirecting;
