import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const ResendTaskAPI = async (resendTaskName, data, t, dispatch, setOpenResendDialog) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/command/${resendTaskName}`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: data
        })
        let successfulText = `resendTask.${resendTaskName}`
        successHandling(t, successfulText);
        dispatch({ type: 'stopLoading' });
        setOpenResendDialog(false);
        return true
    } catch (error) {
        errorHandling(error, false);
    }
    return
}
