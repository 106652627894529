import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../styles/deviceTaskStyle';
import { useTranslation } from "../langs/useTranslation";
import Form from '../components/ioSetUp/Form';
import { SubmitBtnContext } from '../App';
import { BreadCrumbs } from '../components/device/BreadCrumbs';
import * as localforage from 'localforage';

const IOSetUp = () => {
    const classes = useStyles();
    const { t } = useTranslation('task');
    const executeType = "set_io_voltage";
    const successfulText = 'task.successfullySetUpIO';
    const { dispatch } = useContext(SubmitBtnContext);

    // Manage localforage names based on the task name
    const taskName = "ioSetUp";
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;
    const storedDatePickerValueToLocalforageName = `${taskName}Date`;
    const storedPortValueToLocalforageName = `${taskName}PortValue`;
    const storedDeviceIdsToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedDeviceValueToLocalforageName = `${taskName}SelectedDeviceExcelFile`;

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        await localforage.removeItem(storedDatePickerValueToLocalforageName);
        await localforage.removeItem(storedPortValueToLocalforageName);
        await localforage.removeItem(storedDeviceIdsToLocalforageName);
        dispatch({ type: 'stopLoading' });
    }

    return (
        <div className={classes.root}>
            <BreadCrumbs showDeviceList={true} pageName="ioSetUpName" />
            <Typography variant="h4" className={classes.pageTitle}>{t('task.ioSetUpName')}</Typography>
            <Form
                storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                storedDatePickerValueToLocalforageName={storedDatePickerValueToLocalforageName}
                storedPortValueToLocalforageName={storedPortValueToLocalforageName}
                storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                storedDeviceValueToLocalforageName={storedDeviceValueToLocalforageName}
                resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
                executeType={executeType}
                dispatch={dispatch}
                successfulText={successfulText}
                deviceId="" />
        </div>
    )
}

export default IOSetUp;
