import React from 'react';
import { Checkbox } from '@material-ui/core';

export const DefaultCheckedCheckBox = (props) => {
    const { data, localforageKeyName, handleDeviceCheckStatusChange, setSelectedIds } = props;
    return (
        <Checkbox 
            key={data}
            value={data}
            defaultChecked
            onChange={(e)=>{
                const checkedStatus = e.target.checked
                handleDeviceCheckStatusChange(data, checkedStatus, localforageKeyName, setSelectedIds)
            }} 
        />
    )
}

export const UncheckedCheckBox = (props) => {
    const { data, localforageKeyName, handleDeviceCheckStatusChange, setSelectedIds } = props;
    return (
        <Checkbox 
            key={data}
            value={data}
            disabled
            onChange={(e)=>{
                const checkedStatus = e.target.checked
                handleDeviceCheckStatusChange(data, checkedStatus, localforageKeyName, setSelectedIds)
            }} 
        />
    )
}