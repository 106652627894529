import React, { useEffect } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
    Dialog,
    DialogContent,
    DialogActions,
    Typography,
    DialogTitle,
} from "@material-ui/core";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import useStyles from "../../styles/dialogStyle";
import AutorenewRounded from "@material-ui/icons/AutorenewRounded";
import {
    tableAdd,
    reloadTableWithGroupIdAdd,
} from "../../helpers/groupAddDeviceListDatatable";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";

const AddDialog = (props) => {
    const classes = useStyles();
    const { open, onClose, onConfirm, loading, selectGroup } = props;
    const { t } = useTranslation("groups");

    const handleReloadDataTable = () => {
        reloadTableWithGroupIdAdd(t, selectGroup && selectGroup.id);
    };

    useEffect(() => {
        if (open) {
            tableAdd(t, selectGroup.id);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog
            maxWidth="lg"
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <>
                <DialogTitle>
                    <div className={classes.tableTitle}>
                        <Typography variant="h6">
                            {t("dialog.add")}
                            {selectGroup && selectGroup.name && selectGroup.name}
                        </Typography>
                        <AutorenewRounded
                            onClick={handleReloadDataTable}
                            size="small"
                            className={classes.icon_btn}
                        />
                    </div>
                </DialogTitle>
                <DialogContent>
                    <HOCforFullWidthTable>
                        <table
                            id="unbind-devices-table"
                            className="mdl-data-table dataTable no-footer dtr-inline"
                        >
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>{t("table.serialNumber")}</th>
                                    <th>{t("table.os")}</th>
                                    <th>{t("table.companies")}</th>
                                    <th>{t("table.groups")}</th>
                                    <th>{t("table.tags")}</th>
                                </tr>
                            </thead>
                        </table>
                    </HOCforFullWidthTable>
                </DialogContent>

                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        disabled={loading}
                        type="button"
                        btnText={t("dialog.confirmBtn")}
                        onClick={() => onConfirm()}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default AddDialog;
