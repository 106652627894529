import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
} from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import getDevicesControlLogAPI from '../../apis/getDevicesControlLogAPI';
import getDeviceControlLogDetailAPI from '../../apis/getDeviceControlLogDetailAPI';
import LoadingIndicator from '../commons/LoadingIndicator';
import DeviceControlLogDetailTable from './DeviceControlLogDetailTable';
import DeviceControlLogTable from './DeviceControlLogTable';
import ArrowBack from '@material-ui/icons/ArrowBack';

const DeviceControlLogDialog = ({
    open,
    setOpen,
    onClose
}) => {

    const { t } = useTranslation("deviceControl");
    const [logs, setLogs] = useState(null);
    const [detailsOpen, setDetailsOpen] = useState(false);
    const [detailId, setDetailId] = useState('');
    const [details, setDetails] = useState(null);
    const [detailPage, setDetailPage] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const getLogs = async () => {
        const logs = await getDevicesControlLogAPI(page, rowsPerPage);
        setLogs(logs);
    }

    const handleOpenChild = (id) => {
        setDetailsOpen(true);
        setDetailId(id);
    }

    const getDetails = async () => {
        const detail = await getDeviceControlLogDetailAPI(detailId);
        setDetails(detail);
    }

    useEffect(() => {
        //console.log("Page update: " + page);
        getLogs();
    }, [/* page */open]);

    useEffect(() => {
        if (detailsOpen) {
            getDetails();
        }
    }, [detailsOpen]);

    useEffect(() => {
        if (!open) {
            setDetailsOpen(false);
            setPage(0);
        }
    }, [open])

    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth='md'
            fullWidth={true}
            hidden={!open}
        >
            <DialogTitle style={{ padding: "5px", marginLeft: "15px", marginTop: "15px" }}>
                {detailsOpen ?
                    <Button
                        variant="text"
                        startIcon={<ArrowBack />}
                        style={{ margin: '5px' }}
                        onClick={() => {
                            setDetailsOpen(false);
                            setDetails(null);
                        }}
                    >
                        {t("common.goBack")}
                    </Button>
                    :
                    t("common.deviceControlLog")
                }
            </DialogTitle>
            <DialogContent hidden={open ? detailsOpen : true}>
                {
                    logs
                        ?
                        <DeviceControlLogTable
                            rows={logs}
                            page={page}
                            setPage={setPage}
                            rowsPerPage={rowsPerPage}
                            onOpenChild={handleOpenChild}
                        />
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LoadingIndicator />
                        </div>
                }
            </DialogContent>
            <DialogContent hidden={!detailsOpen}>
                {
                    details
                        ?
                        <DeviceControlLogDetailTable
                            rows={details}
                            page={detailPage}
                            setPage={setDetailPage}
                            rowsPerPage={rowsPerPage}
                        />
                        :
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <LoadingIndicator />
                        </div>
                }
            </DialogContent>
        </Dialog>
    )
}

export default DeviceControlLogDialog;
