import React from 'react';
import { Typography, FormControl, TextField } from '@material-ui/core';
import useStyles from '../../styles/fileDeploymentStyle';
import { useTranslation } from "../../langs/useTranslation";

const CommandTextField = (props) => {
    const { t } = useTranslation('task')
    const classes = useStyles();
    const { CommandTextFieldPlaceHolder, formikOnChange, formikOnBlur, formikValue, formikName } = props;
   
    return(
        <FormControl>
            <Typography variant="h6">{t('task.typeCommandForClosingYourApplicationOptional')}</Typography>
            <TextField placeholder={CommandTextFieldPlaceHolder} onChange={formikOnChange} onBlur={formikOnBlur} value={formikValue} name={formikName} className={classes.inputField}></TextField>
        </FormControl>
    )
}

export default CommandTextField;