import React, { useState, useEffect } from "react";
import { Typography, Paper, Tabs, Tab } from "@material-ui/core";
import useStyles from "../styles/alarmNotificationStyle";
import { useTranslation } from "../langs/useTranslation";
import AlarmPolicy from "../components/alarmNotification/AlarmPolicy";
import DeploymentList from "../components/alarmNotification/DeploymentList";
import {
    AlarmRounded,
    DevicesRounded,
    ViewListRounded,
} from "@material-ui/icons";
import { checkHasPermission } from "../helpers/checkHasPermission";
import TriggeredDeviceList from "../components/alarmNotification/TriggeredDeviceList";
import { a11yProps, TabPanel } from "../helpers/tabPanelHandler";
import { getAllAlarmPoliciesAPI } from "../apis/getAllAlarmPoliciesAPI";
import localforage from "localforage";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import HasPermission from "../components/commons/HasPermission";

const AlarmNotification = () => {
    const classes = useStyles();
    const { t } = useTranslation("alarmNotification");
    const [value, setValue] = useState(0);
    const [tab0, seTab0] = useState(false);
    const [tab1, seTab1] = useState(false);
    const [tab2, seTab2] = useState(false);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const getAlarmPolicies = async () => {
        const alarmPoliciesArray = await getAllAlarmPoliciesAPI();
        await localforage.setItem("alarmPolicies", alarmPoliciesArray);
    };

    useEffect(() => {
        getAlarmPolicies();
        checkPermission();
    }, []);

    const checkPermission = async () => {
        // get the user role permission
        const isPermission0 = await checkHasPermission("alarm_view_alarm_policy");
        const isPermission1 = await checkHasPermission(
            "alarm_notification_deployment_list"
        );
        const isPermission2 = await checkHasPermission(
            "alarm_view_triggered_device_list"
        );
        seTab0(isPermission0);
        seTab1(isPermission1);
        seTab2(isPermission2);
    };
    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="alarmNotification" />
            <Typography variant="h4" className={classes.pageTitle}>
                {t("alarmNotification.pageTitle")}
            </Typography>
            <Paper
                className={`${classes.tableMoveTopReset} ${classes.tableMoveBottomReset}`}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    {tab0 && (
                        <Tab
                            icon={<AlarmRounded />}
                            label={t("table.alarmPolicy")}
                            {...a11yProps(0)}
                        />
                    )}
                    {tab1 && (
                        <Tab
                            icon={<ViewListRounded />}
                            label={t("table.deploymentList")}
                            {...a11yProps(1)}
                        />
                    )}
                    {tab2 && (
                        <Tab
                            icon={<DevicesRounded />}
                            label={t("table.triggeredDeviceList")}
                            {...a11yProps(2)}
                        />
                    )}
                </Tabs>
                <TabPanel value={value} index={0} boxSpacing={2}>
                    <HasPermission permission="alarm_view_alarm_policy">
                        <AlarmPolicy />
                    </HasPermission>
                </TabPanel>
                <TabPanel value={value} index={1} boxSpacing={2}>
                    <HasPermission permission="alarm_notification_deployment_list">
                        <DeploymentList />
                    </HasPermission>
                </TabPanel>
                <TabPanel value={value} index={2} boxSpacing={2}>
                    <HasPermission permission="alarm_view_triggered_device_list">
                        <TriggeredDeviceList />
                    </HasPermission>
                </TabPanel>
            </Paper>
        </div>
    );
};

export default AlarmNotification;
