import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const removeFileAPI = async (id, s3Path, t, successText, setRefreshCapacity, onClose, reloadTable) => {
    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/drive/file/delete`,
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                id: id,
                s3_path: s3Path
            }
        })
        successHandling(t, successText);
        setRefreshCapacity(true);
        onClose();
        reloadTable();
        return true;
    } catch (error) {
        errorHandling(error, false);
        return false;
    }

}
