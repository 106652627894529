import React, { useEffect, useRef } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dashboardStyle";
import * as $ from "jquery";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import { getHardwareModelsAPI } from "../../apis/getHardwareModelsAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { chartColors } from "../../helpers/commonHelper";
import { NoDataText } from "../commons/ValidationText";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { useHistory } from "react-router-dom";

const HardwardModels = (props) => {
  const { companyId } = props;
  const mounted = useRef();
  const chartRef = useRef(null);
  let history = useHistory();
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
  const cardSubtitleTypographyVariant = props.cardSubtitleTypographyVariant;
  const [datasetsData, setDatasetsData] = useStateOnMounted([]);
  const [labels, setLabels] = useStateOnMounted([]);
  const [reload, setReload] = useStateOnMounted(true);

  const data = {
    labels: labels,
    datasets: [
      {
        data: datasetsData,
        backgroundColor: chartColors,
        hoverBackgroundColor: chartColors,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    onHover: function(e, item) {
      $(".pieChart canvas").css("cursor", item[0] ? "pointer" : "default");
    },
  };

  const clickHandler = (event) => {
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);
    if (element) {
      const itemLabel = labels[element[0]?.index];
      history.push(`/device-list?company=${companyId}&device_model=${itemLabel}`);
    }
  };

  const clickLable = (e) => {
    history.push(`/device-list?company=${companyId}&device_model=${e}`);
  };

  const getHardwareModels = async () => {
    setReload(true);
    const data = await getHardwareModelsAPI();
    let isDataEmpty = data ? data.data.length === 0 : true;

    if (!isDataEmpty) {
      setDatasetsData(data.data);
      setLabels(data.labels);
    } else {
      setDatasetsData(false);
    }
    setReload(false);
  };

  useEffect(() => {
    if (!mounted.current) {
      getHardwareModels();
      mounted.current = true;
    }
  });

  return (
    <Paper style={{ justifyContent: "flex-start" }}>
      <Typography
        variant={cardTitleTypographyVariant}
        className={classes.cardTitleWrapWithReloadIcon}
      >
        {t("dashboard.hardwareModelsTitle")}
        <AutorenewRoundedIcon
          onClick={getHardwareModels}
          size="small"
          className={classes.reload_btn}
        />
      </Typography>
      {!reload ? (
        datasetsData ? (
          <>
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              style={{ margin: "auto" }}
            >
              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                <div className="pieChart">
                  <Pie
                    ref={chartRef}
                    data={data}
                    options={options}
                    onClick={clickHandler}
                  />
                </div>
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Grid
                  container
                  spacing={2}
                  alignItems="stretch"
                  className={`${classes.clientDistrubutionTabs} ${classes.scrollModelLabelsBig}`}
                >
                  {labels.map((label, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        key={index}
                        className="pointer_label"
                        onClick={() => clickLable(label)}
                      >
                        <Paper
                          elevation={0}
                          className={`${classes.hardwareModelTitleBox} ${classes.heightInitial}`}
                        >
                          <Typography variant={cardSubtitleTypographyVariant}>
                            <span
                              style={{
                                backgroundColor: `${chartColors[index]}`,
                              }}
                              className="colorCube"
                            ></span>
                            {label}
                          </Typography>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <NoDataText />
        )
      ) : (
        <SmallerLoadingIndicator />
      )}
    </Paper>
  );
};

export default HardwardModels;
