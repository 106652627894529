import React, { useState, useEffect } from "react";
import { DateTimePicker } from "@material-ui/pickers";
import '../../css/datePickerThemeReset.css';
import AlarmIcon from "@material-ui/icons/AddAlarm";
import { IconButton, InputAdornment } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import moment from 'moment';
import * as localforage from 'localforage';

const MuiDateTimePicker = (props) => {
  const [selectedDate, handleDateChange] = useState();
  const { t } = useTranslation('task');
  const { tableDisabled, storedDatePickerValueToLocalforageName } = props;

  const storedValueToLocalforage = async() => {
    const dateValue  = selectedDate._d;
    const dateMomentInUTC = moment(dateValue).utc();
    const dateValueInUTC = moment(dateMomentInUTC).format('YYYY-MM-DD HH:mm');
    await localforage.setItem(storedDatePickerValueToLocalforageName, dateValueInUTC);
  }

  useEffect(()=>{
    if(tableDisabled === true){
      const storedValueToLocalforageInitial = async() => {
        await localforage.setItem(storedDatePickerValueToLocalforageName, undefined)
      }
      storedValueToLocalforageInitial()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[tableDisabled])

  useEffect(()=>{
    //only store value when the input is not distabled
      if(selectedDate && tableDisabled !== true){
          storedValueToLocalforage()
      }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[selectedDate])

  return (
      <div className="dateTimePicker-wrapper">
          <DateTimePicker
            ampm={false}
            disabled={tableDisabled}
            variant="inline"
            label={`${t('task.selectADate')}`}
            value={selectedDate}
            disablePast
            onChange={handleDateChange}
            format="YYYY/MM/DD HH:mm"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={tableDisabled}>
                    <AlarmIcon />
                  </IconButton>
                </InputAdornment>
              )
            }}
          />
      </div>
  );
}

export default MuiDateTimePicker;