import React from "react";
import { Typography } from "@material-ui/core";
import useStyles from "../../styles/drawerStyle";
import { releaseVersion } from "../../config/releaseVersion";

const ReleaseVersionText = (props) => {
  const { rwd } = props;
  const classes = useStyles();

  return rwd ? (
    <Typography variant="caption" className={`${classes.releaseVersionTextRwd} releaseVersionTextRwd`}>
      <div>v{releaseVersion}</div>
    </Typography>
  ) : (
    <>
      <Typography variant="caption" className={classes.releaseVersionTextV}>
        v
      </Typography>
      <Typography variant="caption" className={classes.releaseVersionText}>
        {releaseVersion}
      </Typography>
    </>
  );
};

export default ReleaseVersionText;
