import React, { useEffect, useState } from 'react';
import { useTranslation } from "../../langs/useTranslation";
import '../../css/dataTable.css';
import HOCforFullWidthTable from '../../hoc/FullWidthTableWrapper';
import { table } from '../../helpers/taskDeviceTable';
import { vncTable } from '../../helpers/vncSecuritySettingDeviceTable';
import { deployFileDeviceTable } from '../../helpers/deployFileDeviceTable';

const DeviceDatatable = (props) => {
    const { t } = useTranslation('task');
    const { 
            taskType, // determine which table is going to render
            selectedGroupIds, selectedTagIds, 
            filterDevicesBy, setSelectedIds,
            storedDeviceValueToLocalforageName, 
            deviceModelIds, relationName, port, 
            storedFileValueToLocalforageName // only for deploy file task
        } = props;

    const  [ tableId, setTableId ] = useState("device-eligibility-table");

    const handleTableId = taskType => {
        
        switch (taskType) {
            case "deploy_file": 
                setTableId("deploy-file-device-table");
                break;
            case "vnc_setting": 
                setTableId("vnc-setting-device-table");
                break;
            default: 
                setTableId("device-eligibility-table");
                break;
        }
    }

    const loadTable = () => {
        
        if (taskType === "deploy_file") {
            deployFileDeviceTable(t, filterDevicesBy, selectedGroupIds, selectedTagIds, storedDeviceValueToLocalforageName, storedFileValueToLocalforageName);
        } 
        if (taskType === "vnc_setting") { 
            vncTable(t, filterDevicesBy, selectedGroupIds, selectedTagIds, storedDeviceValueToLocalforageName, setSelectedIds);
        }
        if (!taskType) {
            table(t, filterDevicesBy, selectedGroupIds, selectedTagIds, relationName, port, deviceModelIds, storedDeviceValueToLocalforageName, setSelectedIds);
        }
    }

    useEffect(()=>{
        handleTableId(taskType);
    },[taskType])

    useEffect(()=>{
        loadTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [port, deviceModelIds]);

    return(
        <div className="task-device-table-full-width">
            <HOCforFullWidthTable>
                <table id={tableId} className="mdl-data-table dataTable device-command-datatable"></table>
            </HOCforFullWidthTable>
        </div> 
    )
}

export default DeviceDatatable;