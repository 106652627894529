import React, { useState, useEffect } from "react";
import { Typography, Dialog, DialogContent } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import { table } from "../../helpers/appRunningStatusDatatable";
import {
  detectAppRunningApi,
  stopAppRunningApi,
  getAppRunningListApi,
} from "../../apis/appRunningStatusAPI";
import { kbToMb } from "../../helpers/formatBytesTransformer";
import StopAppDialog from "./StopAppDialog";
import RefreshBtn from "../commons/RefreshBtn";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import { checkHasPermission } from "../../helpers/checkHasPermission";
import textNoty from "../../helpers/textNoty";

const AppRunningStatus = (props) => {
  const { onClose, open, deviceId, device_os_type } = props;
  const classes = useStyles();
  const { t } = useTranslation("device");
  const [disableDetectBtn, setDisableDetectBtn] = useState(true);
  const [loading, setLoading] = useState(false);
  const [clickAlready, setClickAlready] = useState(false);
  const [showStopDialog, setShowStopDialog] = useState(false);
  const [dialogLoading, setDialogLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [stopDetail, setStopDetail] = useState({});
  const [statusSet, setStatusSet] = useState({
    status: "empty",
    updated_at: "",
  });
  const [refreshSetUp, setRefreshSetUp] = useState(false);
  const [times, setTimes] = useState(0);
  const [setUpApp, setSetUpApp] = useState("");

  useEffect(() => {
    let id = setInterval(async () => {
      setTimes(times + 1);
      await getListSetUp();
    }, 5000);
    if (times === 10) {
      setTimes(0);
      setRefreshSetUp(false);
      setSetUpApp("");
      clearInterval(id);
      return;
    }
    if (!refreshSetUp || !open || refresh) {
      setTimes(0);
      setRefreshSetUp(false);
      setSetUpApp("");
      clearInterval(id);
      return;
    }
    return () => clearInterval(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times, refreshSetUp, open]);

  const getListSetUp = async () => {
    let setUpTarget;
    let data = await getAppRunningListApi(deviceId);
    if (data && data.data) {
      setUpTarget = data.data.filter((e) => e.id === setUpApp)[0];
    }
    if (
      setUpTarget?.task?.status === "completed" ||
      setUpTarget?.task?.status === "failed"
    ) {
      reloadTable(
        {
          cpu: data.cpu ? data.cpu : 0,
          ram:
            device_os_type === "android"
              ? `${data.ram || data.ram === 0 ? kbToMb(data.ram) : 0} MB`
              : `${data.ram || data.ram === 0 ? data.ram : 0} %`,
        },
        false
      );
      setRefreshSetUp(false);
    }
  };

  const getListStatus = async () => {
    let data = await getAppRunningListApi(deviceId);
    if (data && data.status) {
      if (data.status === "completed" || data.status === "failed") {
        reloadTable(
          {
            cpu: data.cpu ? data.cpu : 0,
            ram:
              device_os_type === "android"
                ? `${data.ram || data.ram === 0 ? kbToMb(data.ram) : 0} MB`
                : `${data.ram || data.ram === 0 ? data.ram : 0} %`,
          },
          false
        );
        setRefresh(false);
      } else {
        reloadTable(
          {
            cpu: data.cpu ? data.cpu : 0,
            ram:
              device_os_type === "android"
                ? `${data.ram || data.ram === 0 ? kbToMb(data.ram) : 0} MB`
                : `${data.ram || data.ram === 0 ? data.ram : 0} %`,
          },
          true
        );
      }
      setStatusSet({
        status: data.status ? data.status : "empty",
        updated_at: data.updated_at ? data.updated_at : "",
      });
    } else {
      reloadTable(
        {
          cpu: 0,
          ram: "0 %",
        },
        false
      );
    }
  };

  const reloadTable = (headerData, status) => {
    setDisableDetectBtn(status);
    table(t, deviceId, headerData, stopAppHandler, setLoading, status);
  };


  const stopAppHandler = async (e) => {
    const check = await checkHasPermission("single_app_running_status_stop_app");
    if (!check) {
      textNoty(t("error.noPermission"));
      return
    }
    setSetUpApp(e.id);
    setStopDetail(e);
    setShowStopDialog(true);
  };

  const confirmStop = async () => {
    setDialogLoading(true);
    let data = await stopAppRunningApi(
      deviceId,
      setUpApp,
      device_os_type === "windows"
        ? stopDetail.pid?.toString()
        : stopDetail.name,
      t,
      "appRunningStatus.successStop"
    );
    if (data && data.message) {
      setRefreshSetUp(true);
      setDialogLoading(false);
      getListStatus();
      setShowStopDialog(false);
    } else {
      setDialogLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      setLoading(true);
      setClickAlready(false);
      getListStatus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      className={`${classes.usbConnectionsDialog}`}
    >
      <DialogContent>
        <div className={classes.tableHeaderDialog}>
          <Typography variant="h5">{t("appRunningStatus.title")}</Typography>
          <div className={classes.usbDetectTextAndBtn}>
            <RefreshBtn
              detectApi={detectAppRunningApi}
              getList={getListStatus}
              statusSet={statusSet}
              open={open}
              refresh={refresh}
              setRefresh={setRefresh}
              disableDetectBtn={disableDetectBtn}
              setDisableDetectBtn={setDisableDetectBtn}
              deviceId={deviceId}
              setClickAlready={setClickAlready}
              clickAlready={clickAlready}
              icon={<RotateLeftIcon />}
            />
          </div>
        </div>
        <HOCforFullWidthTable noMargin={true} loading={loading}>
          <table id="app-runnung-list-table" className="mdl-data-table">
            <thead>
              <tr>
                <th className="appName">{t("appRunningStatus.appName")}</th>
                <th className="cpuloading">
                  {t("appRunningStatus.cpu_usage")}
                </th>
                <th className="ramLoading">
                  {t("appRunningStatus.ram_usage")}
                </th>
                <th className="stopApplication">
                  {t("appRunningStatus.stopApplication")}
                </th>
              </tr>
            </thead>
          </table>
        </HOCforFullWidthTable>
      </DialogContent>
      <StopAppDialog
        open={showStopDialog}
        onClose={() => setShowStopDialog(false)}
        loading={dialogLoading}
        stopDetail={stopDetail}
        onConfirm={confirmStop}
      />
    </Dialog>
  );
};

export default AppRunningStatus;
