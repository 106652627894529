import React, { useEffect, useState } from "react";
import { Paper, Grid, Typography } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import { useParams } from "react-router-dom";
import { Line } from "react-chartjs-2";
import { dataSet, cpuTempOptions } from "../../helpers/chartDataSet";
import { getRealTimeDataAPI } from "../../apis/getRealTimeDataAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { chartColors } from "./../../helpers/commonHelper";
import moment from "moment";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { cpuTemperatureTimestampCherryPick } from './../../helpers/timestampCherryPick';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const DeviceCpuTemperature = (props) => {
  const { t } = useTranslation("device");
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const { device_id } = useParams();
  const [labelArr, setLabelArr] = useStateOnMounted([]);
  const [datasets, setDatasets] = useStateOnMounted([]);
  const [date, setDate] = useStateOnMounted("");
  const [unit, setUnit] = useStateOnMounted();
  const {  graphType, mins } = props;

  const getData = async () => {
    setLoading(true);

    let data = await getRealTimeDataAPI(device_id, graphType, mins, 1);
    /* ==== Ad-hoc Solution for datapoint reduce.(this should process at backend) ===== */
    data = cpuTemperatureTimestampCherryPick(mins, data);
    /* ========== */
    if (data) {
      const unicode = "&#8451;".match(/\d+/);
      let labels =
        data &&
        data.label.map((item, index) =>
          moment(item)
            .local()
            .format("HH:mm")
        );
      let datasets = data && data.dataSets;
      let newDatasets = datasets.map((item, index) => {
        let color = chartColors[index];
        return dataSet(item.data, item.label, color, color, color, color);
      });

      setLabelArr(labels);
      setDatasets(newDatasets);
      setUnit(String.fromCharCode(unicode));
      setDate(
        moment(data.label[0])
          .local()
          .format("YYYY-MM-DD")
      );
      setLoading(false);
    }
  };

  const data = {
    labels: labelArr,
    datasets: datasets,
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mins]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={`${classes.marginBottomReset} ${classes.graphWrap}`}>
          <Typography
            variant="caption"
            align="right"
            className={classes.graphDate}
          >
            {t("deviceInformation.date")}
            {date}
          </Typography>
          <Typography
            variant="caption"
            align="right"
            className={classes.graphUnit}
          >
            {unit}
          </Typography>
          {props.onlineStatus === "offline" ? (
            <Typography variant="caption" className={classes.offlineGraphNote}>
              Device is offline
            </Typography>
          ) : (
            ""
          )}
          {loading ? (
            <SmallerLoadingIndicator />
          ) : (
            <Line data={data} options={cpuTempOptions} />
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default DeviceCpuTemperature;
