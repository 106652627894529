import React, { useEffect, useRef } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dashboardStyle";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import { getDeviceOfflineDaysAPI } from "../../apis/getDeviceOfflineDays";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import * as $ from "jquery";
import { NoDataText } from "../commons/ValidationText";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { useHistory } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);

const OfflineDays = (props) => {
  const { companyId } = props;
  let history = useHistory();
  const chartRef = useRef(null);
  const { t } = useTranslation("dashboard");
  const classes = useStyles();
  const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
  const cardSubtitleTypographyVariant = props.cardSubtitleTypographyVariant;
  const graphBlue = "#4b9ed9";
  const graphGreen = "#1DBBD4";
  const graphOrange = "#ff9e40";
  const graphYellow = "#f9c93d";
  const [deviceData, setDeviceData] = useStateOnMounted({});
  const [reload, setReload] = useStateOnMounted(true);

  const options = {
    plugins: {
      legend: {
        display: false,
      },
    },
    onHover: function(e, item) {
      $(".pieChart canvas").css("cursor", item[0] ? "pointer" : "default");
    },
  };

  const clickHandler = (event) => {
    let result;
    const { current: chart } = chartRef;
    const element = getElementAtEvent(chart, event);
    if (element) {
      const itemLabel = deviceData?.labels[element[0]?.index];
      if (itemLabel) {
        switch (itemLabel) {
          case "> 30 days":
            result = "greater_then_and_equal_to_30_days";
            break;
          case "7 - 30 days":
            result = "greater_than_7_days_and_less_than_equal_to_30_days";
            break;
          case "1 - 7 days":
            result = "greater_than_1_days_and_less_than_equal_to_7_days";
            break;
          case "< 1 day":
            result = "less_then_1_day";
            break;
          default:
            break;
        }
        history.push(`/device-list?company=${companyId}&devices_offline_days=${result}`);
      }
    }
  };

  const clickLable = (e) => {
    let result;
    switch (e) {
      case "> 30 days":
        result = "greater_then_and_equal_to_30_days";
        break;
      case "7 - 30 days":
        result = "greater_than_7_days_and_less_than_equal_to_30_days";
        break;
      case "1 - 7 days":
        result = "greater_than_1_days_and_less_than_equal_to_7_days";
        break;
      case "< 1 day":
        result = "less_then_1_day";
        break;
      default:
        break;
    }
    history.push(`/device-list?company=${companyId}&devices_offline_days=${result}`);
  };
  const getData = async () => {
    setReload(true);
    const data = await getDeviceOfflineDaysAPI();
    let isDataEmpty;
    if (data) {
      isDataEmpty = data && Object.values(data).every((value) => value === 0);
    }
    if (!isDataEmpty) {
      let formatData = {
        labels: ["< 1 day", "1 - 7 days", "7 - 30 days", "> 30 days"],
        datasets: [
          {
            data: [
              data?.less_then_1_day,
              data?.greater_than_1_days_and_less_than_equal_to_7_days,
              data?.greater_than_7_days_and_less_than_equal_to_30_days,
              data?.greater_then_and_equal_to_30_days,
            ],
            backgroundColor: [graphBlue, graphGreen, graphOrange, graphYellow],
            hoverBackgroundColor: [
              graphBlue,
              graphGreen,
              graphOrange,
              graphYellow,
            ],
          },
        ],
      };
      setDeviceData(formatData);
    } else {
      setDeviceData(false);
    }
    setReload(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper style={{ justifyContent: "flex-start" }}>
      <Typography
        variant={cardTitleTypographyVariant}
        className={classes.cardTitleWrapWithReloadIcon}
      >
        {t("dashboard.offlineDeviceStatus")}{" "}
        <AutorenewRoundedIcon
          onClick={getData}
          size="small"
          className={classes.reload_btn}
        />
      </Typography>
      {!reload ? (
        deviceData ? (
          <>
            <Grid
              container
              spacing={2}
              alignItems="stretch"
              style={{ margin: "auto" }}
            >
              <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
                <div className="pieChart">
                  <Pie
                    ref={chartRef}
                    data={deviceData}
                    options={options}
                    onClick={clickHandler}
                  />
                </div>
              </Grid>
              <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
                <Grid
                  container
                  style={{ marginTop: "20px" }}
                  spacing={2}
                  alignItems="stretch"
                  className={classes.hardwareModelLabels}
                >
                  {deviceData.labels.map((label, index) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                        lg={12}
                        xl={12}
                        className="pointer_label"
                        onClick={() => clickLable(label)}
                        key={label}
                        style={{ margin: "auto" }}
                      >
                        <Paper
                          elevation={0}
                          className={`${classes.hardwareModelTitleBox} ${classes.heightInitial} hover`}
                        >
                          <div className="flex_align_center">
                            <Typography variant={cardSubtitleTypographyVariant}>
                              <span
                                style={{
                                  backgroundColor: `${deviceData?.datasets[0]?.backgroundColor[index]}`,
                                }}
                                className="colorCube"
                              ></span>
                              {label}
                            </Typography>
                            <Typography variant="h6">
                              <div className="ml-1">
                                {deviceData?.datasets[0]?.data[index]}
                              </div>
                            </Typography>
                          </div>
                        </Paper>
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </>
        ) : (
          <NoDataText />
        )
      ) : (
        <SmallerLoadingIndicator />
      )}
    </Paper>
  );
};

export default OfflineDays;
