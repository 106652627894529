import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(4),
        paddingTop: theme.spacing(12),
        [theme.breakpoints.down('md')]: {
            padding: theme.spacing(3),
            paddingTop: theme.spacing(12),
            width: 'calc( 100% - 82px )',
        }, 
        '& h4': {
            [theme.breakpoints.down('md')]: {
                fontSize: 24,
            },  
        }   
    },
}));

export default useStyles;