import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from '../../hoc/FullWidthTableWrapper';

const SharedFileTable = () => {
    const { t } = useTranslation('nubisDrive');

    return(
        <HOCforFullWidthTable>
            <table id="shared-file-table" className="mdl-data-table dataTable no-footer dtr-inline">
                <thead>
                    <tr>
                        <th className='file-name sorting'>{t('table.fileName')}</th>
                        <th className='sorting'>{t('table.type')}</th>
                        <th className='sorting'>{t('table.sharedDate')}</th>
                        <th className='sorting'>{t('table.size')}</th>
                        <th className='sorting'>{t('table.sharedBy')}</th>
                        <th>{t('table.action')}</th>
                    </tr>
                </thead>
            </table>
        </HOCforFullWidthTable>
    )
}

export default SharedFileTable;
