import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { apiSelectedDeviceIdsHandler } from '../helpers/apiSelectedIdsHandler';
import { handleHasResponseStatus } from '../helpers/commandConfirmationDialogAction'

export const updateDevicesVncSecurityAPI = async (t, filterDevicesBy, deviceIds, IsTwoFactorEnable, pwd, setOpen, resetAllStoredValueInLocalforage) => {

    const token = await localforage.getItem('token');

    let formData = new FormData();
    formData.append('password', pwd);
    formData.append('is_two_factor_enable', IsTwoFactorEnable);
    apiSelectedDeviceIdsHandler(formData, filterDevicesBy, deviceIds);

    try {
        await axios({
            url: `${url}/api/v2/vnc/security/update-devices`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData
        })

        const successfulText = `${t('task.successfullyUpdateVNCSecuritySetting')}`;
        successHandling(t, successfulText);
        handleHasResponseStatus(setOpen, resetAllStoredValueInLocalforage);
        return true;

    } catch (error) {

        setOpen(false);
        errorHandling(error, false);

    }

}
