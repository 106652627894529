import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateUtilityAPI = async (fwId, utilityIds, companyIds, modelIds, note, t, successfulText) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/admin-utility/utility/update`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token,
            },
            data: {
                id: fwId,
                note: note,
                company_ids: companyIds,
                device_model_ids: modelIds,
                firmware_ids: utilityIds
            }
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
