import React from "react";
import { Typography, LinearProgress, Box } from "@material-ui/core";
import useStyles from "../../styles/fileBrowserStyle";
import { useTranslation } from "../../langs/useTranslation";

const YourCapacity = (props) => {
  const classes = useStyles();
  const { barValue, usageValue, storageValue, capacity } = props;
  const { t } = useTranslation("nubisDrive");

  const ifHaveStorage = () => {
    if (storageValue === "unlimited") return false;
    return capacity?.total - capacity?.used <= 0;
  };

  return (
    <>
      <Box display="flex" alignItems="center" m={1}>
        <Box minWidth="110px" mr={1}>
          <Typography variant="subtitle1">
            {t("fileBrowser.yourCapacity")}
          </Typography>
        </Box>
        <Box
          width="100%"
          className={classes.storagePercentageLinearProgressWrap}
        >
          <Typography variant="caption">
            {usageValue} {t("fileBrowser.of")}{" "}
            {storageValue === "unlimited"
              ? `${t("fileBrowser.unlimitedStorage")}`
              : storageValue}{" "}
            {t("fileBrowser.used")}
          </Typography>
          {!ifHaveStorage() ? (
            <LinearProgress variant="determinate" value={barValue} />
          ) : (
            <LinearProgress className={classes.fullLinear} variant="determinate" value={100} />
          )}
        </Box>
      </Box>
    </>
  );
};

export default YourCapacity;
