import React from 'react';
import { Dialog, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';
import PeripheralsTable from '../peripherals/PeripheralsTable';

const PeripheralsDialog = (props) => {
    const classes = useStyles();
    const { onClose, open, deviceId, serialNumber } = props;

    return(
        <Dialog onClose={onClose} open={open} maxWidth="lg" className={`${classes.singleDeviceDialog} ${classes.singleDeviceTableWrapDialog}`}>
            <DialogContent>
                <PeripheralsTable
                    deviceId={deviceId}
                    serialNumber={serialNumber} />
            </DialogContent>
        </Dialog>
    )
}

export default PeripheralsDialog;