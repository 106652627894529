import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';

const MultipleLineForDataTableColumn = (props) => {
    const { datatableData } = props;
    const classes = useStyles();
    const [ data, setData ] = useState(datatableData);
   
    useEffect(()=>{
        setData(datatableData)
    },[datatableData])

    return (
        <div className={classes.deviceOwners}>
            {data ? 
                <>
                    {data.map((o, index)=>{
                        return(
                            <Typography variant="body2" key={index}>
                                {o}
                            </Typography>
                            )
                        })
                    }
                </>
            :
            ''
            }   
        </div>
    )
}

export default MultipleLineForDataTableColumn;