import React from 'react';
import { Radio, RadioGroup, FormControl, FormControlLabel, Grid, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import { useTranslation } from "../../langs/useTranslation";
import rotationOriginalImg from '../../assets/images/rotate-original.svg';
import rotationUpsideDownImg from '../../assets/images/rotate-upsidedown.svg';
import rotationRightImg from '../../assets/images/rotate-right.svg';
import rotationLeftImg from '../../assets/images/rotate-left.svg';

const SelectOrientation = (props) => {
    const { t } = useTranslation('task');
    const classes = useStyles();

    return(
        <div className={classes.radioSelectGroup}> 
            <FormControl component="div" fullWidth={true}>
                <RadioGroup aria-label="orientation" name={props.formikName} value={props.formikValue} onChange={props.formikOnChange}>
                    <Grid container spacing={2} className={classes.orientationSelect}>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormControl>
                                <FormControlLabel value="landscape" 
                                control={<Radio color="primary" />} 
                                label={
                                    <>
                                        <p className={classes.labelText}>{t('task.rotationOriginal')}</p>
                                        <img src={rotationOriginalImg} alt={t('task.rotationOriginal')} />
                                    </>
                                    } 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormControl>
                                <FormControlLabel value="upside_down" 
                                control={<Radio color="primary" />} 
                                label={
                                    <>
                                        <p className={classes.labelText}>{t('task.rotationUpsideDown')}</p>
                                        <img src={rotationUpsideDownImg} alt={t('task.rotationUpsideDown')} />
                                    </>
                                    } 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormControl>
                                <FormControlLabel value="right" 
                                control={<Radio color="primary" />} 
                                label={
                                    <>
                                        <p className={classes.labelText}>{t('task.rotationRight')}</p>
                                        <img src={rotationRightImg} alt={t('task.rotationRight')} />
                                    </>
                                    } 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={3}>
                            <FormControl>
                                <FormControlLabel value="left" 
                                    control={<Radio color="primary" />} 
                                    label={
                                        <>
                                            <p className={classes.labelText}>{t('task.rotationLeft')}</p>
                                            <img src={rotationLeftImg} alt={t('task.rotationLeft')} />
                                        </>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </RadioGroup>
                <FormHelperText hidden={!props.formikError} error={!!props.formikError}>
                    {props.formikError}
                </FormHelperText>
            </FormControl>
        </div>    
    )
}

export default SelectOrientation;