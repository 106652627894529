import * as localforage from "localforage";
import axios from "axios";
import { url } from "./apiSetup";
import { errorHandling } from "../helpers/errorHandling";

export const getAutocompleteApi = async (type, command) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/get-autocomplete`,
            method: "GET",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            params: {
                company_id,
                type,
                command,
                record: 50,
            },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};

export const createAutocompleteApi = async (type, command) => {
    const token = await localforage.getItem("token");
    const company_id = await localforage.getItem("companyId");
    try {
        let res = await axios({
            url: `${url}/api/v2/command/create-autocomplete`,
            method: "POST",
            headers: {
                Accept: "application/json",
                Authorization: "Bearer " + token,
            },
            data: { company_id, type, command },
        });
        return res.data;
    } catch (error) {
        errorHandling(error, false);
    }
};
