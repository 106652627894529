import axios from 'axios';
import * as localforage from 'localforage';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';

export const updateAlarmPolicyDeploymentAPI = async (deviceId, addNewAlarmPolicyIds, deleteExistAlarmPolicyIds, t, successfulText) => {

    const token = await localforage.getItem('token');

    try {
        await axios({
            url: `${url}/api/v2/alarm/deploy/update-device-alarm-policies`,
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                device_id: deviceId,
                add_new_alarm_policy_ids: addNewAlarmPolicyIds ? addNewAlarmPolicyIds : null,
                delete_exist_alarm_policy_ids: deleteExistAlarmPolicyIds ? deleteExistAlarmPolicyIds : null
            }
        })
        successHandling(t, successfulText);
        return true;
    } catch (error) {
        errorHandling(error, false);
    }

}
