import React from "react";
import { Button } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";

const CommandOkAndBackBtn = (props) => {
  const { t } = useTranslation("common");
  const { okBtnOnClick, backBtnOnClick, isExecuting } = props;

  const handleOkBtnClick = (e) => {
    if (!e.detail || e.detail === 1) {
      okBtnOnClick();
    }

    return false;
  };

  return (
    <div className="MuiDialogActions-root">
      <Button
        onClick={handleOkBtnClick}
        color="primary"
        type="button"
        variant="text"
        disabled={isExecuting}
        style={{ marginRight: "20px" }}
      >
        <span style={{ whiteSpace: "nowrap" }}>{t("btn.okBtn")}</span>
      </Button>
      <Button
        onClick={backBtnOnClick}
        color="secondary"
        type="button"
        variant="text"
        disabled={isExecuting}
      >
        {t("btn.backBtn")}
      </Button>
    </div>
  );
};

export default CommandOkAndBackBtn;
