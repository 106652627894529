import {
    timeDiff,
    utcStringToLocalDatetimeConverter,
} from "./datetimeHandlers";
import * as localforage from "localforage";
import { getDeviceTaskAPI } from "../apis/getDeviceTaskAPI";
import { setVNCAPI } from "../apis/setVNCAPI";
import {
    androidOraVersion,
    windowsOraVersion,
} from "../config/oraVersionForVncLink";

const env = process.env.REACT_APP_ENV;

export const oraVersionNum = (versionString, osType) => {
    if (osType === "android") {
        if (env === "develop" || env === "staging") {
            // only ora version of 'dev' and 'staging' from android are '2.x.x-env'
            return parseInt(
                versionString
                    .split("-")[0]
                    .split(".")
                    .join("")
            );
        }
    }

    if (osType === "windows" || (osType === "android" && env === "production"))
        return parseInt(versionString.split(".").join(""));
};

export const handleNewOrOldVnc = (deviceData) => {
    const osType = deviceData?.os_type;
    const oraVersion = deviceData?.ora_version;

    const ifGetNewVnc = (oraVersion, versionForNewVnc) =>
        oraVersion >= versionForNewVnc;

    if (osType === "android") {
        return ifGetNewVnc(oraVersionNum(oraVersion, osType), androidOraVersion);
    }

    if (osType === "windows")
        return ifGetNewVnc(
            oraVersionNum(oraVersion, osType),
            windowsOraVersion[env]
        );

    return false;
};

export const callVncAPI = async (
    device_id,
    deviceData,
    nubisVersion,
    setIsLoading,
    setVNCLink,
    setVNCExpiredDate,
    setDialogContent,
    handleVNCFailedDialogOpen,
    t,
    type = "deviceDetail",
    setIsVNCLoading
) => {
    setIsLoading(true);
    const newVnc = handleNewOrOldVnc(deviceData);
    const taskResponse = await setVNCAPI(deviceData, nubisVersion, newVnc, type);
    console.log('callVNCAPI', taskResponse);
    let MAX_COUNT = 50;
    let taskStatus;
    await localforage.setItem(`${device_id}VNC`,  {data: taskResponse.task_data, isLoading: true, taskId: taskResponse.task_id});
    const startGetTaskStatus = async () => {
        taskStatus = await getDeviceTaskAPI(taskResponse.task_id);

        if (taskStatus !== "completed" && taskStatus !== "failed") {
            console.log('retry: ', device_id)
/*             if(MAX_COUNT > 0){
                MAX_COUNT--;
                setTimeout(startGetTaskStatus, 3000);
            } else {

                setIsLoading(false);
            } */
            setTimeout(startGetTaskStatus, 1500);
        }

        if (taskStatus === "completed") {
            setIsLoading(false);
            setIsVNCLoading(false);
            await localforage.setItem(`${device_id}VNC`,  {data: taskResponse.task_data, isLoading: false, taskId: taskResponse.task_id});
            const vncLink = taskResponse.task_data.vnc.server;
            const vncExpiredDateString = taskResponse.task_data.vnc.expired_at;
            setUpVncLinkAndExpiration(
                vncExpiredDateString,
                vncLink,
                setVNCLink,
                setVNCExpiredDate
            );
            let popUp = window.open(vncLink);
            if (popUp == null || typeof popUp == "undefined") {
                alert("Please disable your pop-up blocker and try again.");
            } else {
                popUp.focus();
            }
        }

        if (taskStatus === "failed") {
            await localforage.removeItem(`${device_id}VNC`);
            let failedText = `${t("tasks.vncConnectionFailed")}`;
            vncConnectionFailed(
                setIsLoading,
                handleVNCFailedDialogOpen,
                setDialogContent,
                failedText
            );
        }
    };

    setTimeout(startGetTaskStatus, 3000);
};

export const setUpVncLinkAndExpiration = (
    expiredAtString,
    link,
    setVNCLink,
    setVNCExpiredDate,
    data
) => {
    let timeValid = timeDiff(expiredAtString, undefined, true);
    if (timeValid) {
        if (handleNewOrOldVnc(data)) {
            setVNCLink(link);
        } else {
            setVNCLink(link);
        }
        setVNCExpiredDate(utcStringToLocalDatetimeConverter(expiredAtString));
        return true;
    } else {
        return false;
    }
};

export const vncConnectionFailed = (
    setIsLoading,
    handleVNCFailedDialogOpen,
    setDialogContent,
    failedText
) => {
    setIsLoading(false);
    handleVNCFailedDialogOpen();
    setDialogContent(failedText);
};
