import AWS from "aws-sdk";
import * as localforage from "localforage";
import { uploadFileToNubisDriveAPI } from "../apis/uploadFileToNubisDriveAPI";
import { errorHandling } from "./errorHandling";
import { Md5 } from "ts-md5/dist/md5";

export const s3upload = async (
    dom,
    parentId,
    t,
    successfulText,
    percentCompleted,
    setPercentCompleted,
    onClose,
    setRefreshCapacity,
    reloadTable,
    needMd5 = false, // if need md5
    setReturnValue, // if need get return value about s3path and md5
    filePath, // if need customized filePath
    needCompanyId = true, // if s3path need companyId
    changeName = false, // if need to change name with timestamp
    disableCreate = false, // if need to disable call create api
    setDisabled
) => {
    // AWS Docs
    // https://docs.aws.amazon.com/AWSJavaScriptSDK/latest/AWS/S3.html
    let files = dom.files;
    let bucketRegion = process.env.REACT_APP_BUCKET_REGION;
    let bucketName = process.env.REACT_APP_BUCKET_NAME;
    let IdentityPoolId = process.env.REACT_APP_IDENTITY_POOL_ID;
    let enableS3Accelerate = JSON.parse(process.env.REACT_APP_AWS_S3_ACCELERATE);

    // init aws setting
    AWS.config.update({
        region: bucketRegion,
        credentials: new AWS.CognitoIdentityCredentials({
            IdentityPoolId: IdentityPoolId,
        }),
        httpOptions: {
            timeout: 100000000 * 60 * 1000,
        },
    });

    let s3 = new AWS.S3(enableS3Accelerate);

    if (files) {
        let file = files[0];
        if (changeName) {
            Object.defineProperty(file, "name", {
                writable: true,
                value: `${file.name.replace(
                    /\.[^/.]+$/,
                    ""
                )}-${+new Date()}.${file.name.split(".").pop()}`,
            });
        }
        let fileName = file.name;
        let partSize = 10 * 1024 * 1024; // 10mb per part
        let queueSize = 10; // concurrent queue
        let companyId = await localforage.getItem("companyId");
        let userData = await localforage.getItem("user");
        if (filePath) {
            filePath = `${process.env.REACT_APP_NAME.toLowerCase()}/${filePath}/${needCompanyId ? `${companyId}-${userData.company.name}/` : ""
                }${fileName}`;
        } else {
            filePath = `${process.env.REACT_APP_NAME.toLowerCase()}/${companyId}-${userData.company.name
                }/${fileName}`;
        }
        let md5;
        if (needMd5) {
            function getMD5HashFromFile(file) {
                md5 = new Md5().appendByteArray(file).end();
                return md5;
            }

            const reader = new FileReader();
            reader.onload = (event) => {
                const binary = event.target.result; // this will get an 'arraybuffer'
                getMD5HashFromFile(Buffer.from(binary)); // Buffer.from returns a buffer for 'md5'
            };

            reader.readAsArrayBuffer(dom.files[0]);
        }

        s3.upload(
            {
                Bucket: bucketName,
                Key: filePath,
                Body: file,
            },
            {
                partSize: partSize,
                queueSize: queueSize,
            },
            function (error, data) {
                if (error) {
                    errorHandling(error, false);
                    // throw exception to stop continue executing
                    throw new awsS3Exception(error);
                }

                /**
                 * {
                 *  Bucket: "fec-s3"
                 *  ETag: 845013041f0bdac19d3c3ac173e5dd38-317",
                 *  Key: "my-first-bucket-path/macOS Mojave 10.14 Install.dmg", // need this one for create file API's s3_path KEY
                 *  Location: "https://fec-s3.s3-accelerate.amazonaws.com/my-first-bucket-path/macOS+Mojave+10.14+Install.dmg"
                 * }
                 * */
                if (!disableCreate) {
                    uploadFileToNubisDriveAPI(
                        data,
                        file,
                        md5,
                        parentId,
                        t,
                        successfulText,
                        onClose,
                        setRefreshCapacity,
                        reloadTable,
                        setReturnValue,
                        setDisabled
                    );
                }

                if (setReturnValue) {
                    setReturnValue({ md5, filePath });
                }
            }
        ).on("httpUploadProgress", function (progress) {
            let uploaded = parseInt((progress.loaded * 100) / progress.total);
            if (uploaded > percentCompleted) {
                setPercentCompleted(uploaded);
            }
        });
    }
};

export const awsS3Exception = (error) => {
    errorHandling(error, false);
};
