import React from "react";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import useStyles from "../../styles/dialogStyle";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@material-ui/core";

const TasksDialog = (props) => {
  const { dialog, dialogType, submitHandler, close, t, loading } = props;
  const classes = useStyles();
  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={dialog}
      aria-labelledby="device-function"
      aria-describedby="device-function"
    >
      <DialogTitle id="device-function">
        {t(`dialog.text.${dialogType}`)}
      </DialogTitle>
      {dialogType === "blockWindowsNotify" ||
      dialogType === "openWindowsNotify" ? (
        <DialogContent>
          <div style={{ color: "#FF9E40", fontWeight: 600 }}>
            {t("dialog.text.restartWarn")}
          </div>
        </DialogContent>
      ) : (
        ""
      )}
      <DialogActions>
        <StyledSubmitBtn
          btnColor="primary"
          type="button"
          disabled={loading}
          onClick={submitHandler}
          btnText={t("dialog.confirmBtn")}
        />
        <BackBtn onClick={close} />
      </DialogActions>
    </Dialog>
  );
};

export default TasksDialog;
