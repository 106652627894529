import React, { useEffect, useState, useRef } from "react";
import { Typography } from "@material-ui/core";
import useStyles from "../../styles/alarmNotificationStyle";
import { useTranslation } from "../../langs/useTranslation";
import HOCforFullWidthTable from "../../hoc/FullWidthTableWrapper";
import * as localforage from "localforage";
import { AddBoxRounded, AutorenewRounded } from "@material-ui/icons";
import AddOrEditAlarmPolicyDialog from "./AddOrEditAlarmPolicyDialog";
import RemoveAlarmPolicyDialog from "./RemoveAlarmPolicyDialog";
import { table } from "../../helpers/alarmPolicyDatatable";
import HasPermission from "../commons/HasPermission";

const AlarmPolicy = () => {
    const mounted = useRef();
    const classes = useStyles();
    const { t } = useTranslation("alarmNotification");
    const [editDialigOpen, setEditDialogOpen] = useState(false);
    const [createOrUpdate, setCreateOrUpdate] = useState();
    const [removeDialigOpen, setRemoveDialogOpen] = useState(false);
    const [policyToBeUpdated, setPolicyToBeUpdated] = useState();
    const [policyIdToBeRemoved, setPolicyIdToBeRemoved] = useState();

    // Manage localforage names based on the task name
    const taskName = "alarmPolicy";
    const storedGroupIdsToLocalforageName = `${taskName}SelectedGroupIds`;
    const storedTagIdsToLocalforageName = `${taskName}SelectedTagIds`;
    const storedRuleValueToLocalforageName = `${taskName}RuleValue`;
    const storedEmailToLocalforageName = `${taskName}Email`;
    const storedDeviceIdsToLocalforageName = `${taskName}SelectedDeviceIds`;
    const storedOriginalEmailsToLocalforageName = `${taskName}OriginalEmails`;

    const resetAllStoredValueInLocalforage = async () => {
        await localforage.removeItem(storedGroupIdsToLocalforageName);
        await localforage.removeItem(storedTagIdsToLocalforageName);
        await localforage.removeItem(storedDeviceIdsToLocalforageName);
        await localforage.removeItem(storedRuleValueToLocalforageName);
        await localforage.removeItem(storedEmailToLocalforageName);
        await localforage.removeItem(storedOriginalEmailsToLocalforageName);
    };

    const handleReloadDataTable = (e) => {
        if (!e.detail || e.detail === 1) {
            table(t, handleEditDialogOpen, handleRemoveDialogOpen);
        }

        return false;
    };

    const handleEditDialogOpen = (createOrUpdate, policyToBeUpdated) => {
        setCreateOrUpdate(createOrUpdate);
        setEditDialogOpen(true);
        setPolicyToBeUpdated(policyToBeUpdated ? policyToBeUpdated : undefined);
    };

    const handleEditDialogClose = () => {
        setEditDialogOpen(false);
        resetAllStoredValueInLocalforage();
        table(t, handleEditDialogOpen, handleRemoveDialogOpen);
    };

    const handleRemoveDialogOpen = (id, policyToBeUpdated) => {
        setRemoveDialogOpen(true);
        setPolicyIdToBeRemoved(id);
        setPolicyToBeUpdated(policyToBeUpdated);
    };

    const handleRemoveDialogClose = () => {
        setRemoveDialogOpen(false);
        resetAllStoredValueInLocalforage();
        table(t, handleEditDialogOpen, handleRemoveDialogOpen);
    };

    useEffect(() => {
        if (!mounted.current) {
            table(t, handleEditDialogOpen, handleRemoveDialogOpen);
            mounted.current = true;
        }
    });

    return (
        <div>
            <Typography variant="h5" className={classes.tableTitle}>
                {t("table.alarmPolicy")}
                <AutorenewRounded
                    onClick={handleReloadDataTable}
                    size="small"
                    className={classes.reload_btn}
                />
                <HasPermission permission="alarm_create_alarm_policy">
                    <AddBoxRounded
                        onClick={() => handleEditDialogOpen("create")}
                        size="small"
                        className={classes.listBtn}
                    />
                </HasPermission>
            </Typography>
            <HOCforFullWidthTable>
                <table
                    id="alarm-table"
                    className="mdl-data-table dataTable no-footer dtr-inline"
                >
                    <thead>
                        <tr>
                            <th className="sorting_desc">{t("table.ruleDescription")}</th>
                            <th className="sorting">{t("table.email")}</th>
                            <th className="sorting">{t("table.status")}</th>
                            <th className="sorting">{t("table.devices")}</th>
                            <th className="sorting">{t("table.note")}</th>
                            <th className="sorting">{t("table.action")}</th>
                        </tr>
                    </thead>
                </table>
            </HOCforFullWidthTable>
            <RemoveAlarmPolicyDialog
                open={removeDialigOpen}
                policyToBeUpdated={policyToBeUpdated}
                policyIdToBeRemoved={policyIdToBeRemoved}
                onClose={handleRemoveDialogClose}
            />
            <AddOrEditAlarmPolicyDialog
                open={editDialigOpen}
                onClose={handleEditDialogClose}
                createOrUpdate={createOrUpdate}
                policyToBeUpdated={policyToBeUpdated}
                storedRuleValueToLocalforageName={storedRuleValueToLocalforageName}
                storedEmailToLocalforageName={storedEmailToLocalforageName}
                storedOriginalEmailsToLocalforageName={
                    storedOriginalEmailsToLocalforageName
                }
                resetAllStoredValueInLocalforage={resetAllStoredValueInLocalforage}
            />
        </div>
    );
};

export default AlarmPolicy;
