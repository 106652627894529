import React, { useState, useContext, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn, BackBtn } from '../commons/FormBtns';
import { SubmitBtnContext } from '../../App';
import { SendRounded } from '@material-ui/icons';
import TwoFactorSwitch from './TwoFactorSwitch';
import { updateSingleDeviceVncTwoFactorAPI } from '../../apis/updateSingleDeviceVncTwoFactorAPI';
import { twoFactorTitle } from '../../config/i18nText';

const SecuritySettingDialog = props => {

    const { t } = useTranslation('device');
    const { dispatch } = useContext(SubmitBtnContext);
    const { deviceId, vncSecurityChecked, open, onClose } = props;
    const [ isSubmitting, setIsSubmitting ] = useState(false);
    const [ twoFactor, setTwoFactor ] = useState(vncSecurityChecked);

    const handleChange = e => setTwoFactor(e.target.checked);

    const handleSubmit = async() => {
        dispatch({type: 'showLoading'});
        setIsSubmitting(true);
        await updateSingleDeviceVncTwoFactorAPI(t, deviceId, twoFactor, onClose, setIsSubmitting);
    }
    
    useEffect(() => {
        setIsSubmitting(false);
        dispatch({type: 'stopLoading'});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    useEffect(() => {
        setTwoFactor(vncSecurityChecked);
    }, [vncSecurityChecked])

    return(
        <Dialog onClose={onClose} maxWidth="sm" aria-labelledby="vnc-security-setting" open={open}>
            <DialogTitle id="vnc-security-setting">
                {t('tasks.vncSecuritySetting')}
            </DialogTitle>
            <DialogContent>
                <TwoFactorSwitch 
                    title={twoFactorTitle(t)}
                    checked={twoFactor}
                    onChange={handleChange}
                    disabled={isSubmitting}
                    name="twoFactor"
                />
                <div className="submit-back-btn-wrap mt_16 dialog_submit_btn_wrap"> 
                    <StyledSubmitBtn 
                        startIcon={<SendRounded />} 
                        btnText={t('btn.confirmBtn')} 
                        type="button" 
                        onClick={handleSubmit}
                    />
                    <BackBtn 
                        disabled={isSubmitting}
                        onClick={onClose} 
                    />
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default SecuritySettingDialog;