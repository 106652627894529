import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';

const useBarStyles = makeStyles({
    root: {
      height: 8,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: '#dcdbdb'
    },
    bar: {
      height: 8,
      borderRadius: 5,
      width: props => `${props.value}%`,
      backgroundColor: props => props.color
    },
})

const _STATUS = {
    //Tableau color scheme
    "queued": {
        name: "Queued",
        value: 50,
        color: '#f28e2c'
    },
    "processing": {
        name: "Processing",
        value: 50,
        color: '#4e79a7'
    },
    "retrying": {
        name: "Retrying",
        value: 50,
        color: '#af7aa1'
    },
    "pending": {
        name: "Pending",
        value: 50,
        color: '#edc949'
    },
    "completed": {
        name: "Completed",
        value: 100,
        color: '#59a14f'
    },
    "failed": {
        name: "Failed",
        value: 100,
        color: '#e15759'
    },
    "scheduled": {
        name: "Scheduled",
        value: 25,
        color: '#76b7b2'
    },
    "offline": {
        name: "Offline",
        value: 100,
        color: '#bab0ab'
    }
}

const ProgressBar = (props) => {
    const classes = useBarStyles(props);
    return (
        <div className={`${classes.root} ${classes.colorPrimary}`}>
            <div className={classes.bar}></div>
        </div>
    )
}

const useStyles = makeStyles({
    message: {
        '& .MuiPopover-paper': {
            padding: '8px',
            color: 'white',
            fontWeight: 'bold',
            backgroundColor: '#04b7d2'
        }
    }
})

function TaskStatusBar(props){
    const { status, message, isOffline } = props;
    const [ anchorEl, setAnchorEl ] = useState(null);
    const [ ifHasPopover, setIfHasPopover ] = useState(false);
    const classes = useStyles();
    let statusBody = {}

    const togglePopover = (e) => {
        setAnchorEl(anchorEl ? null : e.currentTarget);
    }

    useEffect(()=>{
        setIfHasPopover(message ? true : false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ message ])

    if(status !== 'completed' && status !== 'failed'){
        if(isOffline){
            statusBody = _STATUS['offline'];
        } else if(!(status in _STATUS)){
            statusBody = _STATUS['scheduled'];
        } else {
            statusBody = _STATUS[status];
        }
    } else {
        statusBody = _STATUS[status];
    }

    return (
        <>
            <div
                style={{cursor: ifHasPopover ? 'pointer' : 'default'}}
                onClick={ifHasPopover ? togglePopover : undefined}
                //onMouseLeave={handleMouseLeave}
            >
                <span>{statusBody.name}</span>
                <ProgressBar value={statusBody.value} color={statusBody.color}/>

            </div>
            {
                ifHasPopover ?
                    <Popover
                        open={Boolean(anchorEl)}
                        onClose={togglePopover}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                        className={classes.message}
                        >
                        <div className={classes.message}>
                            {message}
                        </div>
                    </Popover>
                :
                ''
            }
        </>
    )
}

export default TaskStatusBar;
