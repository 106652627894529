import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getDeviceOSTypeAPI = async () => {
    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');
    try {
        let response = await axios({
            url: `${url}/api/v2/statistic/devices-os-type`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            params: {
                company_id: companyId,
            }
        })
        const deviceOSType = response.data.stat
        return deviceOSType
    } catch (error) {
        errorHandling(error, true);
    }

}
