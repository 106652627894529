import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { getAllTagsAPI } from './getAllTagsAPI';

export const syncTagAPI = async (storedValue, value, deviceId, setStoredValue, setValue, t, successfulText) => {

    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');

    try {
        await axios({
            url: `${url}/api/v2/devices/sync-tag`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: {
                exist_tag_name: storedValue,
                new_tag_name: value,
                device_id: deviceId,
                company_id: companyId
            }
        });
        successHandling(t, successfulText);
        setStoredValue(value);
        await getAllTagsAPI(); // get the updated tags and update the localforage tag data

    } catch (error) {
        setValue(storedValue);
        errorHandling(error, false);
    }
}
