import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';
import { successHandling } from '../helpers/successHandling';
import { currentDateInUTC, scheduledDateInUTC } from '../helpers/datetimeHandlers';
import { apiSelectedGroupsHandler } from '../helpers/apiSelectedIdsHandler';

export const oraUpdateAPI = async (device_id, devices, selectedGroups, executeType, datetime, t, successfulText) => {

    let formData = new FormData();
    formData.append('device_id', device_id); // single device
    apiSelectedGroupsHandler(formData, devices, selectedGroups) // multiple devices
    formData.append('execute_type', executeType);
    formData.append('execute_datetime', datetime === 'immediately' ? currentDateInUTC() : scheduledDateInUTC(datetime));

    const token = await localforage.getItem('token');
    try {
        await axios({
            url: `${url}/api/v2/command/ora-update-send`,
            method: 'POST',
            headers: {
                Accept: 'application/json',
                Authorization: 'Bearer ' + token
            },
            data: formData
        })
        successHandling(t, successfulText)
        return true
    } catch (error) {
        errorHandling(error, false);
    }

}
