import React from 'react';
import { Typography, Button } from '@material-ui/core';
import useStyles from '../styles/permissionStyle';
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import { useTranslation } from "../langs/useTranslation";
import { NavLink } from 'react-router-dom';

const NoPermission = () => {
    const { t } = useTranslation('permission')
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <main className={classes.content}>
                <div className={classes.noPermissionTitleSection}>
                    <Typography variant="h4">{t('permission.oops')}</Typography>
                    <Typography variant="h6">{t('permission.needPermissionText')}</Typography>
                </div>
                <NavLink to="/">
                    <Button startIcon={<HomeRoundedIcon />}>
                        {t('btn.backToHome')}
                    </Button>
                </NavLink>
            </main>
        </div>
    )
}

export default NoPermission;
