import React from 'react';
import ReactDOM from 'react-dom';
import { Box } from '@material-ui/core';
import { url } from '../apis/apiSetup';
import '../css/dataTable.css';
import * as $ from 'jquery';
import 'datatables.net';
import 'datatables.net-autofill';
import 'datatables.net-buttons';
import 'datatables.net-fixedcolumns';
import 'datatables.net-fixedheader';
import 'datatables.net-keytable';
import 'datatables.net-rowgroup';
import 'datatables.net-searchpanes';
import * as localforage from 'localforage';
import { language } from './datatableLanguage';
import StatusChip from '../components/commons/StatusChip';
import TriggeredValueChip from '../components/commons/TriggeredValueChip';
import { utcFormatToLocalDateTimeWithSecond } from '../helpers/datetimeHandlers';
import { alarmPolicyRuleGeneratorForHistory } from '../helpers/alarmPolicyRuleGenerator';

export const table = async (deviceId, t) => {

    const token = await localforage.getItem('token');
    $.fn.dataTable.ext.errMode = 'throw';

    $('#triggered-history-table').DataTable({
        processing: true,
        serverSide: true,
        language: language(t),
        order: [[2, 'desc']],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        ordering: true,
        searching: false,
        paging: true,
        ajax: {
            url: `${url}/api/v2/alarm/trigger/alarm-trigger-history-datatable`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                alarm_triggered_device_id: deviceId
            },
            beforeSend: request => {
                request.setRequestHeader('Authorization', 'Bearer ' + token);
                request.setRequestHeader('Accept', 'application/json');
            },
        },
        columns: [
            {
                data: 'id',
                title: `${t('table.ruleDescription')}`,
                render: (data, type, rowData) => {
                    let name = rowData.alarm_policy_option_name;
                    let value = rowData.alarm_policy_value;
                    let operatorFromAPI = rowData.alarm_policy_operator;
                    let unit = rowData.alarm_policy_unit;
                    return alarmPolicyRuleGeneratorForHistory(name, value, operatorFromAPI, unit);
                }
            },
            {
                data: 'triggered_at',
                title: `${t('table.triggeredDate')} - ${t('table.triggeredValue')}`,
                createdCell: (td, data, rowData) => {
                    let triggeredValue = rowData.value;
                    ReactDOM.render(
                        <Box display="flex" flexDirection="row" alignItems="center">
                            <Box mr={1}>{utcFormatToLocalDateTimeWithSecond(data)}</Box> -
                            <Box mr={1} ml={1}><TriggeredValueChip statusLabel={triggeredValue} /></Box>
                        </Box>
                        , td);
                }
            },
            {
                data: 'status',
                title: `${t('table.resetStatus')}`,
                className: 'reset-status-td',
                createdCell: (td, data) => {
                    ReactDOM.render(<StatusChip statusLabel={data} />, td);
                }
            },
        ]
    })
}
