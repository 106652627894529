import React from 'react';
import useStyles from '../../styles/linearProgressStyle';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { useTranslation } from "../../langs/useTranslation";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';

const LinearProgressWithLabel = props => {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value,
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

const LinearWithValueLabel = props => {
  const classes = useStyles();
  const { t } = useTranslation('adminUtility')
  return (
    <div className={classes.root}>
        <LinearProgressWithLabel value={props.percentCompleted} />
        <Typography variant="caption" align="center" className={classes.progressNotice}><ErrorOutlineRoundedIcon color="action" fontSize="small" />{t('dialog.pleaseDontCloseTheDialogWhenUploadingFile')}</Typography>
    </div>
  );
}

export default LinearWithValueLabel;