import React, { useState, useContext, forwardRef } from 'react';
import { Typography, Grid, FormControl } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { SubmitBtnContext } from '../../App';
import ConfirmAndCancelBtn from '../commons/ConfirmAndCancelBtn';
import * as localforage from 'localforage';
import { Formik, Form } from 'formik';
import SelectDevices from '../device/SelectDevices';
import DeviceDatatable from '../device/DeviceDatatableForCommand';
import { RequiredText, ExcelFileRequiredMsg } from '../commons/ValidationText';
import ConfirmDialog from '../commons/ConfirmDialog';
import RuleAndEmailSelection from './RuleAndEmailSelection';
import { createAlarmPolicyDeploymentAPI } from '../../apis/createAlarmPolicyDeploymentAPI';
import { updateAlarmPolicyDeploymentAPI } from '../../apis/updateAlarmPolicyDeploymentAPI';
import { alarmDeploymentValuesSchema, alarmDeploymentInitialValues } from '../../config/multiDeviceTaskValuesSchema';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

const AddOrEditDeploymentForm = forwardRef((props, ref) => {
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { onClose, updatedDeviceData, storedOriginalDeployedRulesAndEmails, storedDeviceIdsToLocalforageName, storedRuleWithEmailsToLocalforageName, storedFileToLocalforageName, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName, createOrUpdate, relationName } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    const [selectedRulesAndEmails, setSelectedRulesAndEmails] = useState(true);
    const [selectedIds, setSelectedIds] = useState(true);
    const [filterDevicesBy, setFilterDevicesBy] = useState('');
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [rulesAndEmails, setRulesAndEmails] = useState();
    const [addNewAlarmPolicyIds, setAddNewAlarmPolicyIds] = useState();
    const [deleteExistAlarmPolicyIds, setDeleteExistAlarmPolicyIds] = useState();
    const [isExecuting, setIsExecuting] = useState(false);

    const handleConfirmDialogOpen = () => {
        dispatch({ type: 'showLoading' });
        setConfirmDialogOpen(true);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
        dispatch({ type: 'stopLoading' });
    };

    const setSelectedIdsFromLocalforage = async () => {
        if (filterDevicesBy === 'excel_import') {
            let deviceIds = await localforage.getItem(storedFileToLocalforageName);
            setSelectedIds(deviceIds ? deviceIds : false);
            return deviceIds ? deviceIds : false;
        } else {
            let deviceIds = await localforage.getItem(storedDeviceIdsToLocalforageName);
            setSelectedIds(deviceIds && deviceIds.length > 0 ? deviceIds : false);
            return deviceIds && deviceIds.length > 0 ? deviceIds : false;
        }
    }

    const handleExecuteAction = async () => {

        setIsExecuting(true);

        let responseStatus = false;
        dispatch({ type: 'showLoading' });

        if (createOrUpdate === 'create') {
            let successfulText = `${t('modal.successfullyCreateAlarmPolicyDeployment')}`;
            responseStatus = await createAlarmPolicyDeploymentAPI(filterDevicesBy, selectedIds, rulesAndEmails, t, successfulText);
        }

        if (createOrUpdate === 'update') {
            let successfulText = `${t('modal.successfullyUpdateAlarmPolicyDeployment')}`;
            responseStatus = await updateAlarmPolicyDeploymentAPI(updatedDeviceData.deviceId, addNewAlarmPolicyIds, deleteExistAlarmPolicyIds, t, successfulText);
        }

        if (responseStatus) {
            handleConfirmDialogClose();
            onClose();
        }
    }

    return (
        <Formik
            initialValues={alarmDeploymentInitialValues}
            validationSchema={alarmDeploymentValuesSchema}
            onSubmit={async () => {

                dispatch({ type: 'showLoading' });

                let deviceIds = await setSelectedIdsFromLocalforage();
                setSelectedIds(deviceIds);

                let newDeployedRulesAndEmails = await localforage.getItem(storedRuleWithEmailsToLocalforageName);

                if (createOrUpdate === 'update') {

                    let originalDeployedRulesAndEmails = await localforage.getItem(storedOriginalDeployedRulesAndEmails);
                    let intersectedRulesAndEmails = differenceWith(originalDeployedRulesAndEmails, newDeployedRulesAndEmails, isEqual); // the rules and emails that stay the same
                    let addNewAlarmPolicyIds = intersectedRulesAndEmails ? intersectedRulesAndEmails.filter(o => !o.checked).map(o => o.id) : false;
                    let deleteExistAlarmPolicyIds = intersectedRulesAndEmails ? intersectedRulesAndEmails.filter(o => o.checked).map(o => o.id) : false;

                    // if rules and emails are updated
                    // then excute the update action
                    if (intersectedRulesAndEmails.length > 0) {
                        setAddNewAlarmPolicyIds(addNewAlarmPolicyIds ? addNewAlarmPolicyIds : false);
                        setDeleteExistAlarmPolicyIds(deleteExistAlarmPolicyIds ? deleteExistAlarmPolicyIds : false);
                        handleConfirmDialogOpen();
                    } else {
                        onClose();
                    }
                }

                if (createOrUpdate === 'create') {
                    setSelectedRulesAndEmails(newDeployedRulesAndEmails ? newDeployedRulesAndEmails : false);

                    if (newDeployedRulesAndEmails && deviceIds) {
                        setRulesAndEmails(newDeployedRulesAndEmails.map(o => o.id));
                        handleConfirmDialogOpen();
                    } else {
                        dispatch({ type: 'stopLoading' });
                    }
                }

            }}
        >
            {({
                values, errors, touched, handleChange, handleSubmit,
                isSubmitting,
            }) => (
                <Form onSubmit={handleSubmit}>
                    <FormControl fullWidth={true} ref={ref}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.emailSelection}>
                                <RuleAndEmailSelection
                                    updatedDeviceData={updatedDeviceData}
                                    createOrUpdate={createOrUpdate}
                                    localforageKeyName={storedRuleWithEmailsToLocalforageName}
                                    selectedRulesAndEmailsProvided={selectedRulesAndEmails}
                                    storedRuleWithEmailsToLocalforageName={storedRuleWithEmailsToLocalforageName}
                                    storedOriginalDeployedRulesAndEmails={storedOriginalDeployedRulesAndEmails}
                                />
                            </Grid>
                            {createOrUpdate === 'create' &&
                                <>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <Typography variant="subtitle1">
                                            {t('modal.selectDevices')}
                                        </Typography>
                                        <SelectDevices
                                            storedDeviceIdsToLocalforageName={storedDeviceIdsToLocalforageName}
                                            storedDeviceValueToLocalforageName={storedFileToLocalforageName}
                                            storedGroupIdsToLocalforageName={storedGroupIdsToLocalforageName}
                                            storedTagIdsToLocalforageName={storedTagIdsToLocalforageName}
                                            filterDevicesBy={filterDevicesBy}
                                            setSelectedIds={setSelectedIds}
                                            storedGroupIdsAndTagIds={true}
                                            formikName="filter"
                                            isSubmitting={isSubmitting}
                                            relationName={relationName}
                                            formikOnChange={(e) => {
                                                setFilterDevicesBy(e.target.value);
                                                return handleChange(e)
                                            }}
                                            formikValue={values.filter}
                                            formikError={(errors.filter && touched.filter && errors.filter)}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.modalDeviceTableGrid}>
                                        {filterDevicesBy !== 'excel_import' &&
                                            <>
                                                <DeviceDatatable
                                                    relationName={relationName}
                                                    filterDevicesBy={filterDevicesBy}
                                                    setSelectedIds={setSelectedIds}
                                                    storedDeviceValueToLocalforageName={storedDeviceIdsToLocalforageName}
                                                />
                                                {!selectedIds && <RequiredText />}
                                            </>
                                        }
                                        {filterDevicesBy === 'excel_import' && !selectedIds ? <ExcelFileRequiredMsg /> : ''}
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <ConfirmAndCancelBtn
                            cancelBtnDisabled={isExecuting}
                            cancelBtnOnClick={onClose} />
                        <ConfirmDialog
                            isExecuting={isExecuting}
                            okBtnOnClick={handleExecuteAction}
                            backBtnOnClick={handleConfirmDialogClose}
                            onClose={handleConfirmDialogClose}
                            open={confirmDialogOpen}
                            dialogTitle={createOrUpdate === 'create' ? `${t('modal.confirmCreateDeployment')}` : `${t('modal.confirmUpdateDeployment')}`}
                            dialogContent={t(`modal.confirmCreateOrUpdateDeploymentDescription`)}
                        />
                    </FormControl>
                </Form>
            )}
        </Formik>
    )
})

export default AddOrEditDeploymentForm;
