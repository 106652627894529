import React, { useState, useEffect, useRef } from 'react';
import { FormControl, MenuItem, TextField, FormHelperText } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import { useTranslation } from "../../langs/useTranslation";
import { getFirmwareListAPI } from '../../apis/getFirmwareListAPI';

const SelectBIOSFileFromNubisDrive = (props) => {
    const mounted = useRef()
    const classes = useStyles();
    const { deviceId, formikValue, formikName, formikOnChange, formikOnBlur, formikError, filterCondition } = props;
    const { t } = useTranslation('task');
    const [fwList, setFwList] = useState([]);

    //get F/W List
    const getFirmwareList = async() => {
        const fwList = await getFirmwareListAPI(filterCondition, deviceId)
        if(fwList){
            const fwData = fwList.map((o) => {
                const deviceModelIds = o.firmware_company_model_maps.map( o => {
                    return o.device_model_id;
                });
                const uniqueDeviceModelIds = [...new Set(deviceModelIds)];
                return {name: o.name, id: o.id, device_info: {device_model_id: uniqueDeviceModelIds, md5: o.md5, file_path: o.file, file_name: o.name}};
            })
            setFwList(fwData);
        };
    };
    

    useEffect(()=>{
        if (!mounted.current) {
            getFirmwareList();
            mounted.current = true;
        }
    }) 
    return(
        <FormControl>
            <TextField 
            variant="outlined"
            name={formikName}
            className={classes.fileSelectDropDown}
            select
            SelectProps={{
                displayEmpty: true
            }}
            value={formikValue} 
            onChange={formikOnChange}
            onBlur={formikOnBlur}
            >
                <MenuItem value="">{t('task.selectFileDefaultValue')}</MenuItem>
                {fwList.map((fw, index)=>{
                    return  <MenuItem key={index} value={fw.device_info}>{fw.name}</MenuItem>
                })}
            </TextField>
            <FormHelperText hidden={!formikError} error={!!formikError}>
                {formikError}
            </FormHelperText>
        </FormControl>
    )
}

export default SelectBIOSFileFromNubisDrive;