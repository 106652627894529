
import { ReactComponent as lockUsbPortGray } from "../assets/images/deviceControl/lock_usb_port_gray.svg";
import { ReactComponent as lockCombinationKeyGray } from "../assets/images/deviceControl/lock_combination_key_gray.svg"
import { ReactComponent as blockWindowsNotifyGray } from "../assets/images/deviceControl/block_Windows_notify_gray.svg"
import { ReactComponent as brightnessGray } from "../assets/images/deviceControl/brightness_gray.svg"
import { ReactComponent as volumeGray } from "../assets/images/deviceControl/volume_gray.svg"
import { ReactComponent as transformGray } from "../assets/images/deviceControl/transform_gray.svg"
import { ReactComponent as remoteDesktopGray } from "../assets/images/deviceControl/remoteDesktop_gray.svg"
import { ReactComponent as watchdogGray } from "../assets/images/deviceControl/watchdog_gray.svg"
import { ReactComponent as terminalGray } from "../assets/images/deviceControl/terminal_gray.svg"
import { ReactComponent as deployFileGray } from "../assets/images/deviceControl/deploy_file_gray.svg"
import { ReactComponent as updateOraGray } from "../assets/images/deviceControl/update_ora_gray.svg"
import { ReactComponent as rotationGray } from "../assets/images/deviceControl/rotation_gray.svg"
import { ReactComponent as ioSetGray } from "../assets/images/deviceControl/io_set_gray.svg"
import { ReactComponent as restartSystemGray } from "../assets/images/deviceControl/restart_system_gray.svg"
import { ReactComponent as kioskModeGray } from "../assets/images/deviceControl/kiosk_mode_gray.svg"
import { ReactComponent as lockUsbPortBlack } from "../assets/images/deviceControl/lock_usb_port_black.svg";
import { ReactComponent as lockCombinationKeyBlack } from "../assets/images/deviceControl/lock_combination_key_black.svg"
import { ReactComponent as blockWindowsNotifyBlack } from "../assets/images/deviceControl/block_Windows_notify_black.svg"
import { ReactComponent as brightnessBlack } from "../assets/images/deviceControl/brightness_black.svg"
import { ReactComponent as volumeBlack } from "../assets/images/deviceControl/volume_black.svg"
import { ReactComponent as transformBlack } from "../assets/images/deviceControl/transform_black.svg"
import { ReactComponent as remoteDesktopBlack } from "../assets/images/deviceControl/remoteDesktop_black.svg"
import { ReactComponent as watchdogBlack } from "../assets/images/deviceControl/watchdog_black.svg"
import { ReactComponent as terminalBlack } from "../assets/images/deviceControl/terminal_black.svg"
import { ReactComponent as deployFileBlack } from "../assets/images/deviceControl/deploy_file_black.svg"
import { ReactComponent as updateOraBlack } from "../assets/images/deviceControl/update_ora_black.svg"
import { ReactComponent as rotationBlack } from "../assets/images/deviceControl/rotation_black.svg"
import { ReactComponent as ioSetBlack } from "../assets/images/deviceControl/io_set_black.svg"
import { ReactComponent as restartSystemBlack } from "../assets/images/deviceControl/restart_system_black.svg"
import { ReactComponent as kioskModeBlack } from "../assets/images/deviceControl/kiosk_mode_black.svg"

export const deviceControlIcon = {
    lockUsbPortGray,
    lockCombinationKeyGray,
    blockWindowsNotifyGray,
    brightnessGray,
    volumeGray,
    transformGray,
    remoteDesktopGray,
    watchdogGray,
    terminalGray,
    deployFileGray,
    updateOraGray,
    rotationGray,
    kioskModeGray,
    restartSystemGray,
    ioSetGray,
    lockUsbPortBlack,
    lockCombinationKeyBlack,
    blockWindowsNotifyBlack,
    brightnessBlack,
    volumeBlack,
    transformBlack,
    remoteDesktopBlack,
    watchdogBlack,
    terminalBlack,
    deployFileBlack,
    updateOraBlack,
    rotationBlack,
    kioskModeBlack,
    restartSystemBlack,
    ioSetBlack,
};
