import React from 'react';
import { Box } from '@material-ui/core';

const ClickableFullSizedBox = props => {

    const { onClick } = props;

    // Q: why add Box for onClick event? 
    // A: onClick is not working with TextField on FireFox
    // so if a clicked textfield is needed, use this component before TextField component, not wrapper it
    
    return(
        <Box 
            position="absolute"
            height="100%"
            width="100%"
            zIndex="999"
            className="pointer"
            onClick={onClick}
        />
    )
}

export default ClickableFullSizedBox;