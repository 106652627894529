import React from "react";
import ReactDOM from "react-dom";
import { url } from "../apis/apiSetup";
import { language } from "./datatableLanguage";
import { Checkbox } from "@material-ui/core";
import * as localforage from "localforage";
import * as $ from "jquery";
import "../css/dataTable.css";
import "datatables.net";
import "datetime-moment";
import { searchStyleRender } from "./datatableSearchStyleRender";
import { datatableErrorHandling } from "./datatableErrorHandling";
import OSType from "../components/commons/OSType";
import { handleDatatableSelectWithRadioAdd } from "./handleDatatableSelectWithRadio";
import DeviceTags from "../components/commons/DeviceTags";

export const reloadTableWithGroupIdAdd = (t, groupId, searchValueForURL) => {
    tableAdd(
        t,
        groupId ? groupId : undefined,
        searchValueForURL ? searchValueForURL : undefined
    );
};

export const tableAdd = async (t, groupId, searchValueForURL) => {
    const token = await localforage.getItem("token");
    const companyId = await localforage.getItem("companyId");
    $.fn.dataTable.ext.errMode = "throw";
    $("#unbind-devices-table").DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        searching: true,
        order: [[0, "desc"]],
        info: true,
        autoWidth: false,
        stateSave: false,
        destroy: true,
        pageLength: 25,
        ordering: true,
        select: {
            style: "multi",
        },
        ajax: {
            url: `${url}/api/v2/devices`,
            type: "GET",
            data: {
                type: "group",
                company_id: companyId,
                devices_not_in_group_id: groupId,
                searching: searchValueForURL,
            },
            beforeSend: (request) => {
                request.setRequestHeader("Authorization", "Bearer " + token);
                request.setRequestHeader("Accept", "application/json");
            },
            error: (error) => {
                datatableErrorHandling(
                    error.responseJSON ? error.responseJSON.message : ""
                );
            },
            complete: async (data) => {
                await localforage.removeItem("addDtDeviceIds");
                $("#unbind-devices-table input[type=checkbox]").each(function () {
                    $(this).prop("checked", false);
                });

                let groupTableAdd = $("#unbind-devices-table").DataTable();
                groupTableAdd.on(
                    "click",
                    $("#device-in-group-table input"),
                    async function (e) {
                        handleDatatableSelectWithRadioAdd();
                    }
                );
            },
        },
        columnDefs: [
            {
                targets: 0,
                searchable: false,
                checkboxes: {
                    selectRow: true,
                },
            },
        ],
        columns: [
            {
                data: "id",
                name: "id",
                searchable: false,
                createdCell: (td, data) => {
                    ReactDOM.render(<Checkbox value={data} />, td);
                },
            },
            {
                data: "serial_number",
                className: "group_serial_number_add",
                title: `${t("table.serialNumber")}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <div className="group-datatable-info">{data}</div>,
                        td
                    );
                },
            },
            {
                data: "os_type",
                className: "group_os_type_add",
                title: `${t("table.OS")}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(<OSType os={data} />, td);
                },
            },
            {
                data: "companies",
                className: "group_companies",
                title: `${t("table.companies")}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(
                        <DeviceTags tags={(data && JSON.parse(data)) || []} />,
                        td
                    );
                },
            },
            {
                data: "groups",
                className: "group_groups",
                title: `${t("table.groups")}`,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(<DeviceTags tags={data} />, td);
                },
            },
            {
                data: "tags",
                className: "group_tags",
                title: `${t("table.tags")}`,
                orderable: false,
                searchable: true,
                createdCell: (td, data, rowData) => {
                    ReactDOM.render(<DeviceTags tags={data} />, td);
                },
            },
        ],
    });
    searchStyleRender();
};
