import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box,
    Typography,
    Switch,
} from "@material-ui/core";
import { oraUpdateAPI } from "../../apis/deviceControlApi";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import { PasswordTextField } from "../commons/PasswordTextField";

const VncSetting = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const [loading, setLoading] = useState(false);
    const [twoFactor, setTwoFactor] = useState(false);
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const {
        open,
        setOpen,
        selectType,
        selectDevices,
        selectDevice,
        numType,
        groupId,
        tagId,
        selectCompany,
        checkAll
    } = props;

    const onClose = () => {
        setOpen(false);
    };

    const handleTwoFactorChange = () => setTwoFactor(!twoFactor);
    const onConfirm = async () => {
        setLoading(true);
        const successText = "dialog.successText";
        await oraUpdateAPI(
            selectType,
            selectCompany,
            checkAll
                ? undefined
                : numType === "single"
                    ? [selectDevice]
                    : selectDevices,
            groupId,
            tagId,
            "ora_update",
            t,
            successText
        ).then((res) => {
            if (res) {
                setLoading(false);
                setOpen(false);
            }
        });
    };

    useEffect(() => {
        setTwoFactor(false);
    }, [open]);

    return (
        <Dialog
            maxWidth="lg"
            className={classes.dialog}
            onClose={onClose}
            open={open}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t("common.vncSetting")}
            </DialogTitle>
            <>
                <DialogContent>
                    <div className={classes.deviceControlDialogOuter}>
                        <div className="deviceControlDialogBlock">
                            <div className="title">
                                1. {t("common.setNewPasswordForDevices")}
                            </div>
                            <div className="selectdateArea">
                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Box className="flex">
                                        <Box mt={2} mr={2}>
                                            <Box width="250px">
                                                <PasswordTextField
                                                    placeholder={t("common.newPasswordText")}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    value={password}
                                                    name="newPassword"
                                                    className={classes.inputField}
                                                    label={t("common.newPasswordText")}
                                                />
                                            </Box>
                                        </Box>
                                        <Box mt={2}>
                                            <Box width="250px">
                                                <PasswordTextField
                                                    placeholder={t("common.confirmNewPassword")}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    value={confirmPassword}
                                                    name="confirmNewPassword"
                                                    className={classes.inputField}
                                                    label={t("common.confirmNewPassword")}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>

                                <Box display="flex" flexDirection="row" alignItems="center">
                                    <Typography variant="body1">
                                        {t("common.twoFactorAuthentication")}
                                    </Typography>
                                    <Switch
                                        checked={twoFactor}
                                        onChange={handleTwoFactorChange}
                                        name="twoFactor"
                                        color="primary"
                                        inputProps={{ "aria-label": "primary checkbox" }}
                                    />
                                </Box>
                            </div>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading}
                        btnText={t("dialog.confirmBtn")}
                        onClick={onConfirm}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default VncSetting;
