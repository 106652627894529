import React, { useEffect, useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  FormControl,
  Typography,
  MenuItem,
  Select,
  Button,
} from "@material-ui/core";
import CompanyTreeDialog from "../deviceControl/CompanyTreeDialog";
import useStyles from "../../styles/deviceControlStyle";
import BusinessIcon from "@material-ui/icons/Business";
import { getCompanyTreeApi } from "../../apis/getCompanyTreeApi";
import { useHistory, useRouteMatch } from "react-router-dom";

const SelectType = (props) => {
  const {
    groupId,
    setGroupId,
    setSelectType,
    selectType,
    setSelectCompanyName,
    selectCompanyName,
    setSelectCompany,
    selectCompany,
    groups,
    noGroup,
    noTag,
    allTags,
    tagId,
    setTagId,
    caller
  } = props;
  const classes = useStyles();
  const { t } = useTranslation("deviceControl");
  const [loading, setLoading] = useState(false);
  const [showTree, setShowTree] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [tempData, setTempData] = useState({});
  const history = useHistory();
  const { path, url } = useRouteMatch();

  const TypeHandler = () => {
    switch (selectType) {
      case "company":
        return (
          <>
            <div className={classes.selectType}>
              <Typography className={classes.selectText} variant="body1">
                {t("common.selectCompany")}
              </Typography>
              <div className={classes.uploadArea}>
                <Button
                  variant="outlined"
                  component="label"
                  className={classes.selectCompanyBtn}
                  onClick={handleSelectCompany}
                >
                  { selectCompanyName
                    ? selectCompanyName
                    : `${t("common.selectCompany")}`}
                  <BusinessIcon />
                </Button>
              </div>
            </div>
          </>
        );
      case "group":
        return (
          <>
            <div className={classes.selectType}>
              <Typography
                className={`${classes.selectText} ${classes.selectGroupText}`}
                variant="body1"
              >
                {t("common.selectDeviceGroup")}
              </Typography>

              <FormControl>
                <Select
                  variant="outlined"
                  value={groupId}
                  onChange={handleChangeGroups}
                  disabled={loading}
                  placeholder={t("common.selectDeviceGroup")}
                  className={classes.select}
                  MenuProps={{
                    disableScrollLock: true,
                    classes: { paper: classes.menuPaper },
                  }}
                >
                  {groups?.length > 0 ? (
                    groups.map((e) => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled>No options</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </>
        );
      case "tag":
        return (
          <>
            <div className={classes.selectType}>
              <Typography
                className={`${classes.selectText} ${classes.selectGroupText}`}
                variant="body1"
              >
                {t("common.selectDeviceTag")}
              </Typography>

              <FormControl>
                <Select
                  variant="outlined"
                  value={tagId}
                  onChange={handleChangeTags}
                  disabled={loading}
                  placeholder={t("common.selectDeviceTag")}
                  className={classes.select}
                  MenuProps={{
                    disableScrollLock: true,
                    classes: { paper: classes.menuPaper },
                  }}
                >
                  {allTags?.length > 0 ? (
                    allTags.map((e) => {
                      return (
                        <MenuItem key={e.id} value={e.id}>
                          {e.name}
                        </MenuItem>
                      );
                    })
                  ) : (
                    <MenuItem disabled>No options</MenuItem>
                  )}
                </Select>
              </FormControl>
            </div>
          </>
        );
      default:
        break;
    }
  };

  const handleChangeTags = async (e) => {
    setLoading(true);
    setTagId(e.target.value);
    if(caller === "device-monitor"){
        history.push(`${path}?tag=${e.target.value}`)
    }
    setLoading(false);
    e.persist();
  };

  const handleChangeGroups = async (e) => {
    setLoading(true);
    setGroupId(e.target.value);
    if(caller === "device-monitor"){
        history.push(`${path}?group=${e.target.value}`)
    }
    setLoading(false);
    e.persist();
  };

  const handleChangeType = async (e) => {
    setLoading(true);
    setSelectType(e.target.value);
    setLoading(false);
  };

  const handleSelectCompany = () => {
    setShowTree(true);
  };

  const onCloseCompany = async (close = false, tempData) => {
    if (!close) {
      setLoading(true);
      setLoading(false);
    }
    setSearchValue("");
    setShowTree(false);
  };

  return (
    <>
      <div>
        <div className={classes.selectTypeArea}>
          <div className={classes.selectType}>
            <Typography className={classes.selectText} variant="body1">
              {t("common.selectType")}
            </Typography>

            <FormControl>
              <Select
                variant="outlined"
                value={selectType}
                onChange={handleChangeType}
                disabled={loading}
                placeholder={t("common.selectType")}
                className={classes.selectSm}
                MenuProps={{
                  disableScrollLock: true,
                }}
              >
                <MenuItem value="company">Companies</MenuItem>
                <MenuItem value="group">Groups</MenuItem>
                <MenuItem value="tag">Tags</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TypeHandler />
        </div>
        {selectType === "group" && noGroup && (
          <Typography
            variant="body2"
            color="error"
            className={classes.noGroupText}
            style={{ marginTop: "15px" }}
          >
            {t("noty.noGroup")}
          </Typography>
        )}
        {selectType === "tag" && noTag && (
          <Typography
            variant="body2"
            color="error"
            className={classes.noGroupText}
            style={{ marginTop: "15px" }}
          >
            {t("noty.noTag")}
          </Typography>
        )}
      </div>
      <CompanyTreeDialog
        open={showTree}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        tempData={tempData}
        setTempData={setTempData}
        onClose={onCloseCompany}
        setSelectCompany={setSelectCompany}
        selectCompany={selectCompany}
        selectCompanyName={selectCompanyName}
        setSelectCompanyName={setSelectCompanyName}
        dataLoader={getCompanyTreeApi}
        caller={caller}
      />
    </>
  );
};

export default SelectType;
