import React from 'react';
import { Typography } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';
import GroupAddRoundedIcon from '@material-ui/icons/GroupAddRounded';
import { useTranslation } from "../../langs/useTranslation";

const VncMultipleUsersLinkBtn = (props) => {
    const { t } = useTranslation('device')
    const { VNCLink, handleClickVNCUserLink, VNCExpiredDate, isVNCLoading } = props;
    const classes = useStyles();

    return(
        ( VNCLink  ?
            (<div className={classes.VncMultipleUsersLinkBtn}>
                <div className={classes.VncMultipleUsersLinkBtn_Icons}>
                    <span className={classes.spanInBtn}>VNC</span>
                    <GroupAddRoundedIcon className={`${classes.marginRight_16} ${classes.svgIconInBtn}`} />
                </div>
                <a rel="noopener noreferrer" onClick={handleClickVNCUserLink} href={VNCLink} target="_blank">
                    <Typography variant="button">{t('deviceDetail.MultipleUsersLink')}</Typography>
                </a>
                <Typography variant="caption" color="secondary"> ( ~ {VNCExpiredDate})</Typography>
            </div>)
            : isVNCLoading ?
                <Typography variant="caption" color="secondary"> VNC is Loading... </Typography>
                :
                ''
        )
    )
}

export default VncMultipleUsersLinkBtn;
