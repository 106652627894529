import React from 'react';
import { Button, Radio, RadioGroup, FormControl, FormControlLabel, Grid } from '@material-ui/core';
import useStyles from '../../styles/deviceManagementFormStyle';
import FileUpload from '../device/FileUpload';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useTranslation } from "../../langs/useTranslation";
import ExcelTemplate from '../../assets/excel/template.xlsx'
import FilterSelectionForTask from '../device/FilterSelectionForTask';

const SelectDevices = (props) => {
    const classes = useStyles();
    const { t } = useTranslation('task')
    const { taskType, file, formikName, formikValue, formikOnChange, isSubmitting, storedDeviceValueToLocalforageName, storedGroupIdsToLocalforageName, storedTagIdsToLocalforageName, setSelectedIds, isAutoLaunch } = props;

    return(
        <div className={classes.radioSelectGroup}> 
            <FormControl component="div" fullWidth={true} required>
                <RadioGroup aria-label="device" name={formikName} value={formikValue} onChange={formikOnChange}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl>
                                <FormControlLabel value="filter_by_group" label={`${t('task.filterByGroup')}`} control={<Radio color="primary" />} />
                                <FilterSelectionForTask 
                                    taskType={taskType}
                                    isAutoLaunch={isAutoLaunch}
                                    setSelectedIds={setSelectedIds}
                                    file={file}
                                    filterDevicesBy="filter_by_group"
                                    tableDisabled={formikValue === "filter_by_group" ? false : true} 
                                    localforageKeyName={storedGroupIdsToLocalforageName} 
                                    isSubmitting={isSubmitting}
                                    storedDeviceIdsToLocalforageName={storedDeviceValueToLocalforageName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl>
                                <FormControlLabel value="filter_by_tag" label={`${t('task.filterByTag')}`} control={<Radio color="primary" />} />
                                <FilterSelectionForTask 
                                    taskType={taskType}
                                    isAutoLaunch={isAutoLaunch}
                                    setSelectedIds={setSelectedIds}
                                    file={file}
                                    filterDevicesBy="filter_by_tag"
                                    tableDisabled={formikValue === "filter_by_tag" ? false : true}  
                                    localforageKeyName={storedTagIdsToLocalforageName}
                                    isSubmitting={isSubmitting}
                                    storedDeviceIdsToLocalforageName={storedDeviceValueToLocalforageName}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12} md={4} lg={4}>
                            <FormControl>
                                <FormControlLabel value="excel_import" label={`${t('task.excelImport')}`} control={<Radio color="primary" />} />
                                <FileUpload tableDisabled={formikValue === "excel_import" ? false : true}  storedFileToLocalforageName={storedDeviceValueToLocalforageName}/>
                                <Button variant="text" color="secondary" href={ExcelTemplate}  className={classes.downloadTemplateBtn} startIcon={<GetAppRoundedIcon />}>
                                {t('task.downloadTemplate')}
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </FormControl>
        </div>    
    )
}

export default SelectDevices;