import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    pageTitle: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
    },
    fullWidthPaper: {
        padding: '24px 40px !important',
        marginBottom: theme.spacing(3),
        '& .MuiTypography-h6': {
            marginBottom: '8px !important',
        },
    },
    inputField: {
        width: '100%',
        minWidth: 200,
        maxWidth: 800,
        marginTop: 8,
        marginBottom: 8,
    },
    dialog: {
        '& .MuiDialogActions-root': {
            justifyContent: 'center',
            '&>div': {
                marginBottom: '0 !important',    
                '& .MuiButton-containedPrimary': {
                    '&:hover': {
                        background: 'linear-gradient(45deg, #00b4ce 30%, #08bcd0 90%)',
                        boxShadow: 'none !important'
                    }
                }
            },
            '& .MuiButton-root': {
                marginBottom: 0,
                padding: '6px 8px',
                height: '40px !important',
                // width: 'auto !important',
                '&:hover': {
                    background: '#f4f4f4',
                },
            },
        },
    },
    dialogTaskExecuting: {
        '& .circular-wrapper': {
            display: 'flex !important',
            alignItems: 'center !important',
            justifyContent: 'center !important',
            position: 'absolute !important',
            top: '0',
            width: '100%',
            height: '100%',
            left: '0',
            background: 'rgb(134, 134,134, 0.6)',
            zIndex: '9999',
            '& .MuiCircularProgress-colorPrimary': {
                color: '#fff !important'
            }
        }
    }
}));

export default useStyles;