import React, { useState, useEffect, useContext, useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Typography, Divider, Box } from '@material-ui/core';
import useStyles from '../../styles/dialogStyle';
import { useTranslation } from "../../langs/useTranslation";
import { StyledSubmitBtn } from '../commons/FormBtns';
import { SubmitBtnContext } from '../../App';
import DeleteRounded from '@material-ui/icons/DeleteRounded';
import { alarmPolicyRuleGenerator } from '../../helpers/alarmPolicyRuleGenerator';
import { utcFormatToLocalDateTimeWithSecond } from '../../helpers/datetimeHandlers';
import { removeAlarmDeploymentAPI } from '../../apis/removeAlarmDeploymentAPI';

const RemoveAlarmPolicyDialog = (props) => {
    const mounted = useRef()
    const classes = useStyles();
    const { t } = useTranslation('alarmNotification');
    const { onClose, open, removedPolicyData } = props;
    const { dispatch } = useContext(SubmitBtnContext);
    const [ serialNumber, setSerialNumber ] = useState();
    const [ rules, setRules ] = useState();

    const handleRemoveDeployment = async() => {
        dispatch({type: 'showLoading'});
        let deviceId = removedPolicyData.id;
        let successText = `${t('modal.successfullyDeleteAlarmPolicyDeployment')}`;
        const result = await removeAlarmDeploymentAPI(deviceId, t, successText);
        if(result){
            onClose();
        }
        dispatch({type: 'stopLoading'});
    }

    const handleShowPolicyData = () => {
        if(removedPolicyData){
            let rules = removedPolicyData.alarm_policies_devices_maps.map( o => {
                return {
                    rule: alarmPolicyRuleGenerator(o.alarm_policy.alarm_policy_option, o.alarm_policy.value, o.alarm_policy.operator), 
                    date: utcFormatToLocalDateTimeWithSecond(o.created_at)
                }
            })
            setRules(rules);
            setSerialNumber(removedPolicyData.serial_number);
        }
    }

    useEffect(() => {
        if (!mounted.current) {
            if(open === true){
                handleShowPolicyData();
            }
            mounted.current = true;
        }
    })
    
    return(
        <Dialog
            maxWidth='sm'
            className={classes.dialog}
            open={open}
            onClose={onClose}
            aria-labelledby="remove-deployment"
            aria-describedby="remove-deployment-confirmation"
        >
            <DialogTitle id="remove-deployment">
                {t('modal.deleteDeployment')}
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t('modal.deleteDeploymentDescription')}
                </DialogContentText>
                <Divider />
                <Typography variant="caption">{t('table.serialNumber')}</Typography>
                <Typography variant="body2" color="primary">{serialNumber}</Typography>
                <Typography variant="caption">{t('table.ruleDescription')}</Typography>
                {rules && rules.map((o, index) => {
                    return(
                        <Box display="flex" key={index}>
                            <Box mr={1}><Typography variant="body2" color="primary">{o.rule}</Typography></Box>
                            <Box><Typography variant="caption" color="secondary">{o.date}</Typography></Box>
                        </Box>
                        )
                    })
                }
                <Typography variant="caption">{t('table.owners')}</Typography>
                <Typography variant="body2" color="primary">Firich Enterprises Co., Ltd., FEC USA, AKAM, FEC GERMAN</Typography>
            </DialogContent>
            <DialogActions>
                <StyledSubmitBtn 
                    btnColor="primary" 
                    type="button" 
                    onClick={handleRemoveDeployment} 
                    startIcon={<DeleteRounded />} 
                    btnText={t('modal.removeBtn')} />
                <Button 
                    onClick={onClose} 
                    color="secondary" 
                    type="button">
                    {t('modal.cancelBtn')}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default RemoveAlarmPolicyDialog;