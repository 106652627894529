import React, { useEffect, useState, useMemo } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Grid, Typography, Paper } from "@material-ui/core";
import useStyles from "../../styles/deviceStyle";
import { useTranslation } from "../../langs/useTranslation";
import DeviceListTable from "./DeviceListTable";
import { table } from "../../helpers/deviceListDatatable";
import SelectType from "../commons/SelectType";
import * as localforage from "localforage";
import { getGroupsApi } from "../../apis/deviceControlApi";
import { getAllTagsAPI } from "../../apis/getAllTagsAPI";
import { getCompanyTreeApi } from "../../apis/getCompanyTreeApi";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { getCompaniesAPI } from "../../apis/getCompaniesAPI";

const DeviceListTableSection = (props) => {
    const { type, id } = props.params;
    const history = useHistory();
    const classes = useStyles();
    const [noGroup, setNoGroup] = useState(true);
    const [noTag, setNoTag] = useState(true);
    const [loading, setLoading] = useState(false);
    const [groupId, setGroupId] = useState((type === 'group') ? id : "");
    const [tagId, setTagId] = useState((type === 'tag') ? id : "");
    const [groups, setGroups] = useState([]);
    const [selectType, setSelectType] = useState(type ? type : "company");
    const [selectCompanyName, setSelectCompanyName] = useState("");
    const [selectCompany, setSelectCompany] = useState((type === 'company') ? id : "");
    const [allTags, setAllTags] = useState([]);
    const { t } = useTranslation("device");
    let location = useLocation();

    const query = useMemo(() => new URLSearchParams(location.search), [
        location.search,
    ]);

    const getDefaultCompanyId = async () => {
        setLoading(true);
        const companyIdFirst = await localforage.getItem("companyId");
        let data = await getCompanyTreeApi();
        if (data?.length > 0) {
            setSelectCompany(companyIdFirst);
            setSelectCompanyName(data[0]?.title);
            setLoading(false);
        }
    };

    const reloadTableWithGroupId = (t, query, companyId) => {
        if (
            (selectType === "company" && selectCompany) ||
            (selectType === "group" && groupId) ||
            (selectType === "tag" && tagId)
        ) {
            let searchValueForURL = query.get("search");
            table(
                t,
                query,
                companyId,
                searchValueForURL,
                history,
                allTags,
                groupId,
                tagId,
                selectType
            );
        }
    };

    const getCompanyName = async () => {
        const data = await getCompaniesAPI(id);
        if(data?.length > 0){
            setSelectCompanyName(data[0].name);
        }
    }

    const getAllTags = async () => {
        let res = await getAllTagsAPI(true);
        if (res?.tags?.length > 0) {
            setAllTags(res.tags);
            //setTagId(res.tags[0].id);
            setNoTag(false);
        } else {
            setNoTag(true);
        }
    };

    const getGroups = async () => {
        const response = await getGroupsApi();
        if (response && response.length > 0) {
            setGroups(response);
            //setGroupId(response[0].id);
            setNoGroup(false);
        } else {
            setNoGroup(true);
        }
    };

    useEffect(() => {
        //getDefaultCompanyId();
        if(type){
            getCompanyName();
        }
        getGroups();
        getAllTags();
    }, []);


    useEffect(() => {
        switch(type){
            case "company":
                setSelectType(type);
                setSelectCompany(id);
                //setSelectCompanyName(companys?.filter(c => c.id === id)[0].title)
                break;
            case "tag":
                setSelectType(type);
                setTagId(id);
                break;
            case "group":
                setSelectType(type);
                setGroupId(id);
                break;
            default:
                setSelectType("company");
                //getDefaultCompanyId();
                getAllTags();
                getGroups();
        }
    }, [type, id])

    useEffect(() => {
        let searchValueForURL = query.get("search");
        if (
            (selectType === "company" && selectCompany) ||
            (selectType === "group" && groupId) ||
            (selectType === "tag" && tagId)
        ) {
            table(
                t,
                query,
                selectCompany,
                searchValueForURL,
                history,
                allTags,
                groupId,
                tagId,
                selectType
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectCompany, groupId, tagId, selectType]);

    return (
        <>
            <Typography variant="h4" className={classes.pageTitle}>
                {t("deviceList.pageTitle")}
            </Typography>
            <Grid container spacing={4}>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.deviceListWrapper}
                >
                    {loading ? (
                        <div className="mt-5">
                            <SmallerLoadingIndicator />
                        </div>
                    ) : (
                        <SelectType
                            groupId={groupId}
                            setGroupId={setGroupId}
                            setSelectType={setSelectType}
                            selectType={selectType}
                            setSelectCompanyName={setSelectCompanyName}
                            selectCompanyName={selectCompanyName}
                            setSelectCompany={setSelectCompany}
                            selectCompany={selectCompany}
                            groups={groups}
                            noGroup={noGroup}
                            noTag={noTag}
                            allTags={allTags}
                            tagId={tagId}
                            setTagId={setTagId}
                            caller={"device-monitor"}
                        />
                    )}
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    xl={12}
                    className={classes.deviceListWrapper}
                    style={{ opacity: loading ? 0 : 1 }}
                >
                    { type ?
                        <DeviceListTable
                            query={query}
                            selectKey={selectCompany}
                            reloadTableWithGroupId={reloadTableWithGroupId}
                        />
                    :
                        <Paper style={{
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            { "Please Select a Type" }
                        </Paper>
                    }
                </Grid>
            </Grid>
        </>
    );
};

export default DeviceListTableSection;
