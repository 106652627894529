import React, { useState, useEffect, useCallback, useReducer } from "react";
import {
    Table,
    TableBody,
    Paper,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    Typography,
    Checkbox,
    TextField,
} from "@material-ui/core";
import { useTranslation } from "../langs/useTranslation";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import {
    getTagsApi,
    createTagApi,
    updateTagApi,
    deleteTagApi,
} from "../apis/tagAPI";
import useStyles from "../styles/groupsStyle";
import LoadingIndicator from "../components/commons/LoadingIndicator";
import AutorenewRounded from "@material-ui/icons/AutorenewRounded";
import HasPermission from "../components/commons/HasPermission";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import CreatePopover from "../components/table/CreatePopover";
import TableHeaders from "../components/table/TableHeaders";
import TickIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import TextNoty from "../helpers/textNoty";
import DeleteDialog from "../components/groups/DeleteDialog";
import NoResult from "../components/table/NoResult";
import { TagsTableReducer } from "../reducers/TagsTableReducer";
import { deviceModifyAPI } from "../apis/deviceModifyAPI"

const Tags = () => {
    const [tableData, setTableData] = useState({});
    const [loading, setLoading] = useState(false);
    const [anchorEl, setaAnchorEl] = useState(null);
    const [OpenAddDialog, setOpenAddDialog] = useState(false);
    const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [selected, setSelected] = useState([]);
    const [taskSchedule, setTaskSchedule] = useState([]);
    const [editData, setEditData] = useState({});
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [editName, setEditName] = useState("");
    const { t } = useTranslation("groups");
    const classes = useStyles();

    const [tableVariables, dispatch] = useReducer(TagsTableReducer, {
        orderBy: "name",
        order: "asc",
        page: 0,
        rowsPerPage: 10,
        search: "",
    });

    const getTags = useCallback(async () => {
        const { orderBy, order, page, rowsPerPage, search } = tableVariables;

        setLoading(true);
        let data = await getTagsApi(search, orderBy, order, page + 1, rowsPerPage);
        if (data && data.tags_table) {
            setTableData(data.tags_table);
            setLoading(false);
        }
    }, [tableVariables]);

    const handleRequestSort = (event, property) => {
        if (property === "edit") return;
        const isAsc =
            tableVariables.orderBy === property && tableVariables.order === "asc";
        dispatch({ type: "SORT", payload: { property: property, isAsc: isAsc } });
    };

    const handleChangeRowsPerPage = async (event) => {
        dispatch({
            type: "CHANGE_ROWS_PERPAGE",
            payload: { value: parseInt(event.target.value, 10) },
        });
        setLoading(true);
    };

    const handleChangePage = (event, newPage) => {
        dispatch({
            type: "CHANGE_PAGE",
            payload: { newPage: newPage },
        });
    };

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = tableData?.data?.map((n) => n.id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const isSelected = (id) => selected.indexOf(id) !== -1;

    const deleteHandler = async () => {
        if (!selected || (selected && selected.length === 0)) {
            TextNoty(t("error.noSelect"));
            return;
        }
        let res = await deviceModifyAPI('tag', undefined, selected)
        if (res) {
            setTaskSchedule(res)
            setOpenDeleteDialog(true);
        }
    };

    const confirmDelete = async () => {
        setLoadingDialog(true);
        await deleteTagApi(selected, t, t("dialog.successDelete")).then((res) => {
            if (res?.message === "success") {
                getTags();
                setOpenDeleteDialog(false);
                setSelected([]);
            }
            setLoadingDialog(false);
        });
    };

    const editHandler = (e) => {
        setEditData(e);
        setEditData(e);
        setEditName(e.name);
    };

    const confirmEdit = async () => {
        if (!editName) {
            TextNoty(t("error.noName"));
            return;
        }
        setLoadingEdit(true);
        await updateTagApi(editData.id, editName, t, t("noty.update"))
            .then((res) => {
                getTags();
                setEditData({});
                setEditName("");
            })
            .finally(() => {
                setLoadingEdit(false);
            });
    };

    const cancelEdit = () => {
        if (!loadingEdit) {
            setEditData({});
            setEditName("");
        }
    };

    const addHandler = (e) => {
        setaAnchorEl(e.currentTarget);
        setOpenAddDialog(true);
    };

    useEffect(() => {
        getTags();
    }, [getTags]);

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="tags" />
            <div className={classes.titleWithSelect}>
                <Typography variant="h4">{t("common.tagList")}</Typography>
            </div>
            <Paper className={classes.categoriesPaper}>
                {loading ? (
                    <div className={classes.loadingCategory}>
                        <LoadingIndicator />
                    </div>
                ) : (
                    <>
                        <div className={classes.tableTitleTag}>
                            <AutorenewRounded
                                onClick={getTags}
                                size="small"
                                className={classes.icon_btn}
                            />
                            <HasPermission permission="tag_create">
                                <div className={classes.iconWText} onClick={addHandler}>
                                    <AddIcon size="small" className={classes.icon_btn} />
                                    <Typography variant="body1" className={classes.iconText}>
                                        {t("common.addTag")}
                                    </Typography>
                                </div>
                            </HasPermission>
                            <HasPermission permission="tag_delete">
                                <div className={classes.iconWText} onClick={deleteHandler}>
                                    <DeleteIcon size="small" className={classes.icon_btn} />
                                    <Typography variant="body1" className={classes.iconText}>
                                        {t("common.removeTag")}
                                    </Typography>
                                </div>
                            </HasPermission>
                        </div>
                        <TableContainer>
                            {tableData?.data?.length > 0 ? (
                                <Table>
                                    <TableHeaders
                                        onSorting={handleRequestSort}
                                        onSelectAllClick={handleSelectAllClick}
                                        order={tableVariables.order}
                                        orderBy={tableVariables.orderBy}
                                        deletePermission={"tag_delete"}
                                        numSelected={selected.length}
                                        rowCount={tableData?.data?.length || 0}
                                        headCells={[
                                            {
                                                id: "name",
                                                label: t("common.tagName"),
                                            },
                                            {
                                                id: "edit",
                                                label: t("common.action"),
                                            },
                                        ]}
                                    />
                                    <TableBody>
                                        {tableData.data.map((row, index) => {
                                            const isItemSelected = isSelected(row.id);
                                            const labelId = `enhanced-table-checkbox-${index}`;
                                            return (
                                                <TableRow
                                                    key={row.id}
                                                    role="checkbox"
                                                    selected={isItemSelected}
                                                >
                                                    <TableCell padding="checkbox">
                                                        <HasPermission permission="tag_delete">
                                                            <Checkbox
                                                                onClick={(event) => handleClick(event, row.id)}
                                                                checked={isItemSelected}
                                                                inputProps={{ "aria-labelledby": labelId }}
                                                            />
                                                        </HasPermission>
                                                    </TableCell>
                                                    <TableCell>
                                                        {editData.id === row.id ? (
                                                            <TextField
                                                                placeholder={t("dialog.groupName")}
                                                                onChange={(e) => setEditName(e.target.value)}
                                                                value={editName}
                                                            />
                                                        ) : (
                                                            row.name
                                                        )}
                                                    </TableCell>
                                                    <TableCell>
                                                        {editData.id === row.id ? (
                                                            <div className={classes.editIconArea}>
                                                                <TickIcon
                                                                    style={{
                                                                        color: "#1DBBD4",
                                                                        margin: "0 10px 0 0",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => confirmEdit()}
                                                                />
                                                                <CloseIcon
                                                                    style={{
                                                                        color: "#999999",
                                                                        cursor: "pointer",
                                                                    }}
                                                                    onClick={() => cancelEdit()}
                                                                />
                                                            </div>
                                                        ) : (
                                                            <div className="flex">
                                                                <HasPermission permission="tag_update">
                                                                    <EditIcon
                                                                        onClick={() => editHandler(row)}
                                                                        className="pointer mr-2"
                                                                    />
                                                                </HasPermission>
                                                            </div>
                                                        )}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            ) : (
                                <NoResult />
                            )}
                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 20, 50]}
                            component="div"
                            count={tableData?.total || 0}
                            rowsPerPage={tableVariables.rowsPerPage}
                            page={tableVariables.page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                )}
                <CreatePopover
                    anchorEl={anchorEl}
                    open={OpenAddDialog}
                    onClose={() => setOpenAddDialog(false)}
                    getHandler={getTags}
                    createHandler={createTagApi}
                    list={tableData?.data || []}
                />
                <DeleteDialog
                    open={OpenDeleteDialog}
                    loading={loadingDialog}
                    taskSchedule={taskSchedule}
                    onConfirm={confirmDelete}
                    onClose={() => setOpenDeleteDialog(false)}
                    content={t("dialog.removeTags")}
                    header={t("dialog.RemoveTagsHeader")}
                />
            </Paper>
        </div>
    );
};

export default Tags;
