import React from 'react';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import useStyles from '../../styles/deviceStyle';
import MultipleTaskBtnList from './MultipleTaskBtnList';

const MultipleTaskBtnListDialog = props => {
    const classes = useStyles();
    const { onClose, open } = props;

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="multiple-task"
            aria-describedby="multiple-task-description"
            maxWidth='lg'
            className={classes.wideModal}
        >
            <DialogTitle id="multiple-task-title">Multiple Tasks</DialogTitle>
            <DialogContent>
                <MultipleTaskBtnList />
            </DialogContent>
        </Dialog>
    )
}

export default MultipleTaskBtnListDialog;