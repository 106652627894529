import * as localforage from 'localforage';
import axios from 'axios';
import { url } from './apiSetup';
import { errorHandling } from '../helpers/errorHandling';

export const getIOPortsAPI = async () => {
    const token = await localforage.getItem('token');
    try {
        let response = await axios({
            url: `${url}/api/v2/io/ports`,
            method: 'GET',
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${token}`
            },
        })
        if (response.data && response.data.io_ports) {
            return response.data.io_ports;
        }
    } catch (error) {
        errorHandling(error, true);
    }
}
