import React, { useState } from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
    FormControl,
    Typography,
    MenuItem,
    Select,
    Button,
    Radio,
    RadioGroup,
    Grid,
    FormControlLabel,
    Checkbox,
    TextField,
    ClickAwayListener,
    Box,
    Popper,
} from "@material-ui/core";
import TocIcon from "@material-ui/icons/Toc";
import CompanyTreeDialog from "./CompanyTreeDialog";
import DeviceControlLogDialog from "./deviceControlLogDialog";
import useStyles from "../../styles/deviceControlStyle";
import BusinessIcon from "@material-ui/icons/Business";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { getCompanyTreeApi } from "../../apis/getCompanyTreeApi";

const SelectDevice = (props) => {
    const {
        selectDevices,
        setSelectDevices,
        groupId,
        setGroupId,
        setSelectType,
        selectType,
        clear,
        devices,
        getDevices,
        setDevices,
        setSelectCompanyName,
        selectCompanyName,
        setSelectCompany,
        selectCompany,
        groups,
        noGroup,
        noTag,
        allTags,
        tagId,
        setTagId,
        numType,
        setNumType,
        pages,
        setPages,
        setSelectDevice,
        selectDevice,
        open,
        setOpen,
        setCheckAll,
        checkAll,
    } = props;
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const [loading, setLoading] = useState(false);
    const [showTree, setShowTree] = useState(false);
    const [showLog, setShowLog] = useState(false);
    const [searchValue, setSearchValue] = useState("");
    const [tempData, setTempData] = useState({});
    const [currentDevicePage, setCurrentDevicePage] = useState(1)
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;

    const TypeHandler = () => {
        switch (selectType) {
            case "company":
                return (
                    <>
                        <div className={classes.selectDevice}>
                            <Typography
                                className={`${classes.selectText} ${classes.selectGroupText}`}
                                variant="body1"
                            >
                                {t("common.selectCompany")}
                            </Typography>
                            <div className={classes.uploadArea}>
                                <Button
                                    variant="outlined"
                                    component="label"
                                    className={classes.selectCompanyBtn}
                                    onClick={handleSelectCompany}
                                >
                                    {selectCompanyName
                                        ? selectCompanyName
                                        : `${t("common.selectCompany")}`}
                                    <BusinessIcon />
                                </Button>
                            </div>
                        </div>
                        <SelectDeviceSet />
                        <Button
                            variant="contained"
                            className={classes.deviceControlLogTemplateBtn}
                            startIcon={<TocIcon />}
                            onClick={onDeviceControlLogOpen}
                        >
                            {t("common.deviceControlLog")}
                        </Button>
                    </>
                );
            case "group":
                return (
                    <>
                        <div className={classes.selectDevice}>
                            <Typography
                                className={`${classes.selectText} ${classes.selectGroupText}`}
                                variant="body1"
                            >
                                {t("common.selectDeviceGroup")}
                            </Typography>

                            <FormControl>
                                <Select
                                    variant="outlined"
                                    value={groupId}
                                    onChange={handleChangeGroups}
                                    disabled={loading}
                                    placeholder={t("common.selectDeviceGroup")}
                                    className={classes.select}
                                    MenuProps={{
                                        disableScrollLock: true,
                                        classes: { paper: classes.menuPaper },
                                    }}
                                >
                                    {groups?.length > 0 ? (
                                        groups.map((e) => {
                                            return (
                                                <MenuItem key={e.id} value={e.id}>
                                                    {e.name}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem disabled>No options</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <SelectDeviceSet />
                        <Button
                            variant="contained"
                            className={classes.deviceControlLogTemplateBtn}
                            startIcon={<TocIcon />}
                            onClick={onDeviceControlLogOpen}
                        >
                            {t("common.deviceControlLog")}
                        </Button>
                    </>
                );
            case "tag":
                return (
                    <>
                        <div className={classes.selectDevice}>
                            <Typography
                                className={`${classes.selectText} ${classes.selectGroupText}`}
                                variant="body1"
                            >
                                {t("common.selectDeviceTag")}
                            </Typography>

                            <FormControl>
                                <Select
                                    variant="outlined"
                                    value={tagId}
                                    onChange={handleChangeTags}
                                    disabled={loading}
                                    placeholder={t("common.selectDeviceTag")}
                                    className={classes.select}
                                    MenuProps={{
                                        disableScrollLock: true,
                                        classes: { paper: classes.menuPaper },
                                    }}
                                >
                                    {allTags?.length > 0 ? (
                                        allTags.map((e) => {
                                            return (
                                                <MenuItem key={e.id} value={e.id}>
                                                    {e.name}
                                                </MenuItem>
                                            );
                                        })
                                    ) : (
                                        <MenuItem disabled>No options</MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                        </div>
                        <SelectDeviceSet />
                        <Button
                            variant="contained"
                            className={classes.deviceControlLogTemplateBtn}
                            startIcon={<TocIcon />}
                            onClick={onDeviceControlLogOpen}
                        >
                            {t("common.deviceControlLog")}
                        </Button>
                    </>
                );
            default:
                break;
        }
    };

    const SelectDeviceSet = () => {
        return (
            <>
                <div className={classes.selectDevice}>
                    <FormControl component="div" className="noTitle">
                        <RadioGroup
                            aria-label="num"
                            name="num"
                            value={numType}
                            onChange={numTypeChange}
                        >
                            <Grid container spacing={0}>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <FormControl
                                        disabled={
                                            loading || (selectType === "company" && !selectCompany)
                                        }
                                    >
                                        <FormControlLabel
                                            value="single"
                                            label={`${t("common.singleText")}`}
                                            control={<Radio color="primary" />}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid item xs={6} sm={6} md={6} lg={6}>
                                    <FormControl
                                        disabled={
                                            loading || (selectType === "company" && !selectCompany)
                                        }
                                    >
                                        <FormControlLabel
                                            value="multiple"
                                            label={`${t("common.multipleText")}`}
                                            control={<Radio color="primary" />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </RadioGroup>
                    </FormControl>
                </div>
                <div className={classes.selectDevice}>
                    <Typography
                        className={`${classes.selectText} ${classes.selectGroupText}`}
                        variant="body1"
                    >
                        {t("common.selectOnlineDevices")}
                    </Typography>
                    <div className={`${classes.dropdown}`}>
                        {numType === "single" ? (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Autocomplete
                                    id="single"
                                    openOnFocus={true}
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={(e, reason) => {
                                        if (reason === "escape") {
                                            setOpen(false);
                                        }
                                    }}
                                    disabled={
                                        loading || (selectType === "company" && !selectCompany)
                                    }
                                    onChange={(event, newValue) => {
                                        clear(true);
                                        setOpen(false);
                                        setSelectDevice(newValue);
                                    }}
                                    ListboxProps={{
                                        onTouchEnd: (e) => {
                                            e.persist();
                                            setSelectDevice(e.target.value);
                                        },
                                        onScroll: (event) => {
                                            const listboxNode = event.currentTarget;
                                            if (
                                                listboxNode.scrollTop + listboxNode.clientHeight + 5 >=
                                                listboxNode.scrollHeight
                                            ) {
                                                addDevices();
                                            }
                                        },
                                    }}
                                    getOptionDisabled={(option) => option.disabled}
                                    value={selectDevice}
                                    className={classes.autoComplete}
                                    options={devices}
                                    getOptionLabel={(option) =>
                                        `${option.serial_number} ${versionHandler(
                                            option.nubis_version
                                        )}` || ""
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={t("common.selectOnlineDevices")}
                                        />
                                    )}
                                />
                            </ClickAwayListener>
                        ) : (
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <Autocomplete
                                    id="multiple"
                                    multiple
                                    disableCloseOnSelect
                                    limitTags={1}
                                    renderTags={(values) =>
                                        checkAll ? (
                                            <div className="tagsArea">
                                                <span className="mr-1 ml-1">Select All</span>
                                            </div>
                                        ) : (
                                            <div className="tagsArea">
                                                <span className="mr-1 ml-1">
                                                    {values.map((value) => value.serial_number)[0]}
                                                </span>
                                                <span className="mr-1">
                                                    {values.length > 1 && `+ ${values.length - 1}`}
                                                </span>
                                            </div>
                                        )
                                    }
                                    disabled={
                                        loading || (selectType === "company" && !selectCompany)
                                    }
                                    onChange={(event, newValue, reason) => {
                                        clear(true);
                                        if (reason === "select-option") {
                                            setSelectDevices(newValue);
                                        } else if (reason === "remove-option") {
                                            setCheckAll(false);
                                            setSelectDevices(newValue);
                                        } else if (reason === "clear") {
                                            setSelectDevices([]);
                                            setCheckAll(false);
                                        }
                                    }}
                                    open={open}
                                    onOpen={() => {
                                        setOpen(true);
                                    }}
                                    onClose={(e, reason) => {
                                        if (reason === "escape") {
                                            setOpen(false);
                                        }
                                    }}
                                    ListboxProps={{
                                        onScroll: (event) => {
                                            const listboxNode = event.currentTarget;
                                            if (
                                                listboxNode.scrollTop + listboxNode.clientHeight + 5 >=
                                                listboxNode.scrollHeight
                                            ) {
                                                addDevices();
                                            }
                                        },
                                    }}
                                    value={selectDevices}
                                    className={classes.autoComplete}
                                    options={devices.filter(
                                        (e) => e.nubis_version === "nubis_pro"
                                    )}
                                    getOptionDisabled={(option) => option.disabled}
                                    renderOption={(option, { selected }) => (
                                        <React.Fragment>
                                            {!option.disabled && (
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                            )}
                                            {`${option.serial_number} ${versionHandler(
                                                option.nubis_version
                                            )}` || ""}
                                        </React.Fragment>
                                    )}
                                    getOptionLabel={(option) => option.serial_number || ""}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            variant="outlined"
                                            placeholder={t("common.selectOnlineDevices")}
                                        />
                                    )}
                                    PopperComponent={(param) => (
                                        <Popper {...param}>
                                            {devices?.filter((e) => e.nubis_version === "nubis_pro")
                                                .length > 0 && (
                                                    <Box
                                                        onClick={() => checkAllChange(!checkAll)}
                                                        className="selectAll"
                                                    >
                                                        <Checkbox
                                                            checked={checkAll}
                                                            onChange={() => checkAllChange(!checkAll)}
                                                            id="check-all"
                                                            onMouseDown={(e) => e.preventDefault()}
                                                        />
                                                        <span className="allText">Select All</span>
                                                    </Box>
                                                )}
                                            {!checkAll && <Box id="multipleSelect" {...param} />}
                                        </Popper>
                                    )}
                                />
                            </ClickAwayListener>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const addDevices = () => {
        if (devices.some((e) => e.id === "nomore")) {
            return;
        }
        setCheckAll(false);
        setTimeout(() => {
            if (selectType === "company") {
                getDevices(pages, undefined, tempData.company, false, undefined);
            }
            if (selectType === "group") {
                getDevices(pages, groupId, undefined, false, undefined);
            }
            if (selectType === "tag") {
                getDevices(pages, undefined, undefined, false, tagId);
            }
        }, 100);
    };

    const numTypeChange = (e) => {
        clear(true);
        setCheckAll(false);
        setSelectDevice();
        setSelectDevices([]);
        setNumType(e.target.value);
    };

    const handleClickAway = (e) => {
        setOpen(false);
    };

    const checkAllChange = (e) => {
        clear(true);
        setCheckAll(e);
        if (e) {
            let result = devices.filter(
                (e) =>
                    e.id !== "nomore" &&
                    e.id !== "loading" &&
                    e.nubis_version === "nubis_pro"
            );
            setSelectDevices(result);
        } else {
            setSelectDevices([]);
        }
    };

    const handleChangeTags = async (e) => {
        setLoading(true);
        setCheckAll(false);
        clear();
        setTagId(e.target.value);
        setDevices([]);
        setPages(1);
        setSelectDevices([]);
        setSelectDevice();
        await getDevices(1, undefined, undefined, true, e.target.value);
        setLoading(false);
        e.persist();
    };

    const handleChangeGroups = async (e) => {
        setLoading(true);
        setCheckAll(false);
        clear();
        setGroupId(e.target.value);
        setDevices([]);
        setPages(1);
        setSelectDevices([]);
        setSelectDevice();
        await getDevices(1, e.target.value, undefined, true, undefined);
        setLoading(false);
        e.persist();
    };

    const versionHandler = (version) => {
        if (!version) {
            return "";
        }
        if (version === "nubis_pro") {
            return "( Nubis Pro )";
        } else {
            return "( Nubis Free )";
        }
    };

    const handleChangeType = async (e) => {
        setLoading(true);
        setCheckAll(false);
        setSelectType(e.target.value);
        clear();
        setPages(1);
        setDevices([]);
        setSelectDevice();
        setSelectDevices([]);
        if (e.target.value === "group") {
            await getDevices(1, groupId, undefined, true, undefined);
        }
        if (e.target.value === "tag") {
            await getDevices(1, undefined, undefined, true, tagId);
        }
        setLoading(false);
    };

    const handleSelectCompany = () => {
        setOpen(false);
        setShowTree(true);
    };

    const onCloseCompany = async (close = false, tempData) => {
        if (!close) {
            setLoading(true);
            clear(true);
            setPages(1);
            setSelectDevices([]);
            setSelectDevice();
            setCheckAll(false);
            await getDevices(1, undefined, tempData.company, true, undefined);
            setLoading(false);
        }
        setSearchValue("");
        setShowTree(false);
    };

    /**
     * Device Control Log Related function
     * Button click handler=> onDeviceControlLogOpen
     * get logs => async getLogs
     * open dialog => set dialog.open = true
     */
    const onDeviceControlLogOpen = () => {
        setOpen(false);
        setShowLog(true);
    };

    const handleCloseLog = () => {
        setShowLog(false);
    };
    return (
        <>
            <div>
                <div className={classes.selectDeviceArea}>
                    <div className={classes.selectDevice}>
                        <Typography className={classes.selectText} variant="body1">
                            {t("common.selectType")}
                        </Typography>

                        <FormControl>
                            <Select
                                variant="outlined"
                                value={selectType}
                                onChange={handleChangeType}
                                disabled={loading}
                                placeholder={t("common.selectType")}
                                className={classes.selectSm}
                                MenuProps={{ disableScrollLock: true }}
                            >
                                <MenuItem value="company">Companies</MenuItem>
                                <MenuItem value="group">Groups</MenuItem>
                                <MenuItem value="tag">Tags</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                    <TypeHandler />
                </div>
                {selectType === "group" && noGroup && (
                    <Typography
                        variant="body2"
                        color="error"
                        className={classes.noGroupText}
                    >
                        {t("noty.noGroup")}
                    </Typography>
                )}
                {selectType === "tag" && noTag && (
                    <Typography
                        variant="body2"
                        color="error"
                        className={classes.noGroupText}
                    >
                        {t("noty.noTag")}
                    </Typography>
                )}
            </div>
            <CompanyTreeDialog
                open={showTree}
                setSearchValue={setSearchValue}
                searchValue={searchValue}
                tempData={tempData}
                setTempData={setTempData}
                onClose={onCloseCompany}
                setDevices={setDevices}
                setSelectCompany={setSelectCompany}
                selectCompany={selectCompany}
                selectCompanyName={selectCompanyName}
                setSelectCompanyName={setSelectCompanyName}
                dataLoader={getCompanyTreeApi}
            />
            <DeviceControlLogDialog open={showLog} onClose={handleCloseLog} />
        </>
    );
};

export default SelectDevice;
