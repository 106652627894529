import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  fontWeight: {
    fontWeight: 600,
  },
  line: {
    backgroundColor: "#6d6d6d",
    margin: "30px 0",
  },
  titleWithSelect: {
    display: "flex",
    alignItems: "center",
  },
  nodata:{
    display:'flex',
    justifyContent:'center'
  },
  selectDropDown: {
    marginLeft: "20px",
    minWidth: "220px",
    "& .MuiSelect-outlined": {
      border: "1px solid #6d6d6d",
    },
  },
  listTitleSet: {
    display: "flex",
    alignItems: "center",
  },
  listTitle1: {
    display: "flex",
    alignItems: "center",
    flexBasis: "25%",
  },
  listTitle2: {
    display: "flex",
    alignItems: "center",
    flexBasis: "75%",
  },
  paperMain: {
    minHeight: "60vh",
    display: "flex",
    padding: "0",
    margin: "15px 0",
  },
  loadingGroup: {
    flexBasis: "100%",
    padding: "0 20px",
    "& .ldio-tk8dyrglbc ": {
      transform: "translateZ(0) scale(0.6)",
    },
  },
  loadingCategory: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "300px",
    "& .ldio-tk8dyrglbc ": {
      transform: "translateZ(0) scale(0.6)",
    },
  },
  editIcon: {
    marginLeft: "10px",
    cursor: "pointer",
  },
  paperOuter: {
    display: "flex",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  groupList: {
    display: "flex",
    flexDirection: "column",
  },
  groupSelect: {
    padding: "15px 20px !important",
  },
  titleWithEditIcon: {
    display: "flex",
    fontWeight: "bold",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "16px 16px 8px !important",
    "& p": {
      display: "flex",
      "& svg": {
        marginLeft: 8,
      },
    },
  },
  groupBtnsWrapper: {
    padding: 32,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& .MuiPaper-root": {
      width: "100%",
      padding: "0 !important",
      [theme.breakpoints.down("lg")]: {
        marginBottom: "0 !important",
      },
      [theme.breakpoints.down("sm")]: {
        marginBottom: "0 !important",
      },
    },
    "& .MuiButton-root": {
      color: "#888 !important",
      textDecoration: "none !important",
      width: "100% !important",
      height: "initial !important",
      lineHeight: 1.3,
      padding: "16px 0 !important",
      marginBottom: "0 !important",
      wordBreak: "break-word !important",
      borderRadius: "0 !important",
      transition: theme.transitions.create(["color"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      "& .MuiButton-label": {
        textAlign: "left",
        justifyContent: "flex-start",
        textTransform: "initial",
      },
    },
    "& .MuiButton-textPrimary": {
      color: "#1DBBD4 !important",
    },
    [theme.breakpoints.down("sm")]: {
      "& .MuiButton-contained": {
        width: "auto",
      },
      "& .MuiButton-text": {
        display: "flex",
      },
      "& .MuiPaper-root": {
        marginBottom: 0,
      },
    },
  },
  deviceInGroup: {
    borderRadius: "0 13px 13px 0",
  },
  groupItem: {
    margin: "5px 0",
    cursor: "pointer",
    padding: "3px 0 10px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  tableTitle: {
    display: "inline-flex !important",
    alignItems: "center !important",
    position: "relative",
    zIndex: "4 !important",
    "& svg": {
      marginBottom: "0 !important",
      top: "0 !important",
      marginLeft: "10px !important",
    },
  },
  tableTitleTag: {
    display: "inline-flex !important",
    alignItems: "center !important",
    padding: "20px 5px",
    "& svg": {
      marginLeft: "10px !important",
    },
  },
  icon_btn: {
    cursor: "pointer",
  },
  iconWText: {
    margin: "0 3px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },
  iconText: {
    margin: "2px 0 0 5px",
    fontWeight: 600,
  },
  categoriesPaper: {
    marginTop: "20px",
    padding: "20px 0",
  },
  listOuter: {
    display: "flex",
    flexDirection: "column",
    padding: "20px 0 10px",
  },
  listItem: {
    borderTop: "1px solid #e0e0e0",
    "&:nth-child(odd)": {
      backgroundColor: "#f3f3f3",
    },
    "&:last-child": {
      borderBottom: "1px solid #e0e0e0",
    },
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  listHeader: {
    margin: "10px 0 5px 92px !important",
  },
  listIcon: {
    marginLeft: "20px",
  },
  bottom: {
    marginLeft: "37px",
    marginTop: "20px",
  },
}));

export default useStyles;
