import * as localforage from 'localforage';

export const handleFilterChange = async (event, chipItem, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, t, filterDevicesBy) => {
    // clarify if the event target is checkbox or chip
    const checkBox = event.target;
    const itemId = chipItem ? chipItem.id : checkBox.id;
    const itemName = chipItem ? chipItem.name : checkBox.name;

    // update the groups array checked status
    const changedGroupIndex = groups.map((o) => { return o.id }).indexOf(itemId);
    const changedGroup = chipItem ? { name: itemName, id: chipItem.id, checked: false } : { name: itemName, id: checkBox.id, checked: checkBox.checked };
    groups.splice(changedGroupIndex, 1, changedGroup);
    setGroups([...groups]);

    // update selected group status
    const selectedGroupIndex = selectedGroups.map((o) => { return o.id }).indexOf(itemId);
    if (selectedGroupIndex > -1) {
        selectedGroups.splice(selectedGroupIndex, 1);
        setSelectedGroups(selectedGroups);
    } else {
        let selectedGroupObj = {};
        selectedGroupObj['name'] = itemName;
        selectedGroupObj['id'] = itemId;
        selectedGroups.push(selectedGroupObj);
        setSelectedGroups(selectedGroups);
    }

    // stored selected groups and devices to Localforage
    if (selectedGroups.length > 0) await localforage.setItem(localforageKeyName, selectedGroups);

    // remove item from Localforage if the array is empty
    if (selectedGroups.length < 1) await localforage.removeItem(localforageKeyName);

    // update device list table if selected group or tag is changed
    const selectedGroupsForTable = filterDevicesBy === 'filter_by_group' ? selectedGroups : undefined;
    const selectedTagsForTable = filterDevicesBy === 'filter_by_tag' ? selectedGroups : undefined;

    return { Groups: selectedGroupsForTable, Tags: selectedTagsForTable };

}
