import React, { useState } from "react";
import { Button, Typography } from "@material-ui/core";
import TemplateFile from "../../assets/excel/template.xlsx";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/deviceExcelFileUploadStyle";
import { GetAppOutlined } from "@material-ui/icons";
import { FileNameText } from "./ValidationText";

const ExcelFileUpload = (props) => {
  const classes = useStyles();
  const { t } = useTranslation("common");
  const { setExcelFile, excelFile, disabled } = props;
  const [fileUploadError, setFileUploadError] = useState(false);
  const [fileName, setFileName] = useState("");
  const excelExtension = ["xlsx", "xls", "xlsb", "csv", "xlsm"];

  const handleFileUpload = (e) => {
    if (e?.target?.files[0]?.name) {
      const fileName = e.target.files[0].name;
      if (e.target.files && e.target.files[0]) {
        const fileExtension = fileName.split(".").pop();
        if (excelExtension.includes(fileExtension)) {
          setFileUploadError(false);
          setFileName(fileName);
          setExcelFile(e.target.files[0]);
        } else {
          setFileUploadError(true);
          setExcelFile(null);
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <div className="flex_align_center mb-2 mt-2">
        <Button
          variant="outlined"
          component="label"
          disabled={disabled}
          className={classes.deviceExcelFileUploadMainInput}
          style={{ margin: 0,width:'60%' }}
        >
          {fileName ? (
            <FileNameText fileName={fileName} />
          ) : (
            `${t("common.uploadFile")}`
          )}
          <input
            type="file"
            id="fileInput"
            onChange={handleFileUpload}
            className={classes.hiddenInput}
          />
        </Button>
        <Button
          href={TemplateFile}
          variant="text"
          color="secondary"
          style={{ margin: '0 0 0 10px', padding: '10px',justifyContent:'flex-start' }}
          startIcon={<GetAppOutlined color="primary" />}
        >
          {t("common.downloadTemplateBtn")}
        </Button>
      </div>
      <div className="flex_column">
        <Typography variant="caption" color="secondary">
          {t("common.uploadExcelFileMemo")}
        </Typography>
        {fileUploadError && (
          <Typography variant="caption" color="error">
            {t(`common.uploadExcelFileErrorMsg`)}
          </Typography>
        )}
        {excelFile === false && (
          <Typography variant="caption" color="error">
            {t(`common.selectedTargetRequired`)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ExcelFileUpload;
