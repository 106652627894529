import localforage from 'localforage';
// definition: 'parentId' is the previous parent id;
// 'id' is clicked folder's id, which is the new parent id

export const storeLinkArray = async (localforageKeyname, linkArray) => {
    await localforage.setItem(localforageKeyname, linkArray);
}

export const removeClickedLinkFromBreadcrumbs = async (e, i, parentId, setParentId, setLinkArray, rootFolderLinkName, localforageKeyname) => {

    e.persist();

    let id = e.target?.id;
    let name = e.target?.innerHTML;
    let storedLinkArray = await localforage.getItem(localforageKeyname);
    let newLinkArray = storedLinkArray.slice(0, i + 1);
    setParentId(id);
    updateWindowLocationHistory(parentId, id, name, rootFolderLinkName, newLinkArray, setLinkArray, localforageKeyname);

}

export const updateBreadcrumbsWhenBackButtonClicked = async (setLinkArray, parentId, id, setParentId, rootFolderLinkName, localforageKeyname) => {

    let storedLinkArray = await localforage.getItem(localforageKeyname);
    let isOnlyRootFolderInLinkArray = storedLinkArray.length === 1;

    if (id && !isOnlyRootFolderInLinkArray) {

        let newLinkArray = storedLinkArray.slice(0, storedLinkArray.length - 1);
        let previousLink = storedLinkArray[storedLinkArray.length - 2];
        setParentId(previousLink.parent_id);
        updateWindowLocationHistory(parentId, previousLink.parent_id, previousLink.name, rootFolderLinkName, newLinkArray, setLinkArray, localforageKeyname);

    }

    if (!id || isOnlyRootFolderInLinkArray) {

        setParentId('');
        let newLinkArray = [storedLinkArray[0]];
        updateWindowLocationHistory(parentId, null, '', rootFolderLinkName, newLinkArray, setLinkArray, localforageKeyname);

    }

}

export const addNewLinkToBreadcrumbs = async (name, id, parentId, setParentId, setLinkArray, rootFolderLinkName, localforageKeyname) => {

    setParentId(id);
    let storedLinkArray = await localforage.getItem(localforageKeyname);
    let i = storedLinkArray.findIndex(o => o.parent_id === id);

    if (i === -1) {
        let newLinkArray = [...storedLinkArray, { name: name, parent_id: id }];
        updateWindowLocationHistory(parentId, id, name, rootFolderLinkName, newLinkArray, setLinkArray, localforageKeyname);
    }

}

export const updateWindowLocationHistory = (parentId, id, name, rootFolderLinkName, newLinkArray, setLinkArray, localforageKeyname) => {

    let pathname = new URL(window.location.href).pathname;

    const state = { id };
    const title = '';

    storeLinkArray(localforageKeyname, newLinkArray);
    setLinkArray(newLinkArray);

    if (id) {

        // replace existing id parameter on url
        // it occurs when 'folder belongs to another folder but root folder' is clicked
        if (parentId) {
            let newPathname = `/${pathname.split('/').slice(1, 3).join('/')}/${id}/${name}`;
            window.history.pushState(state, title, newPathname);
        }

        // for the first click from datatable which no id on url parameter
        // so just append id after the pathname
        // it occurs when 'folders in root folder' is clicked
        if (!parentId) {
            window.history.pushState(state, title, `${pathname}/${id}/${name}`);
        }
    }

    // direct to the root folder which id is 'null',
    // i.e. 'file browser' or 'shared with me'
    if (!id) {
        window.history.pushState(state, title, `/nubis-drive/${rootFolderLinkName}`);
    }

}
