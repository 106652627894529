import React from "react";
import { useTranslation } from "../../langs/useTranslation";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from "@material-ui/core";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import { getOraLogAPI } from "../../apis/getOraLogAPI";

const OraSendLogDialog = (props) => {
  const classes = useStyles();
  const { open, onClose, loading, setLoading, deviceId } = props;
  const { t } = useTranslation("device");

  const onConfirm = async () => {
    setLoading(true);
    let data = await getOraLogAPI(deviceId, t, "oraSendLog.success");
    if (data && data.message === "success") {
      onClose();
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <Dialog
      maxWidth="md"
      className={classes.dialog}
      open={open}
      aria-labelledby="add-event-dialog"
      aria-describedby="add-event-dialog"
    >
      <DialogTitle>
        {t("oraSendLog.title")}
      </DialogTitle>
      <>
        <DialogContent>
          <Typography variant="body1">{t("oraSendLog.content")}</Typography>
        </DialogContent>
        <DialogActions className={classes.noMarginBtn}>
          <StyledSubmitBtn
            btnColor="primary"
            disabled={loading}
            type="button"
            btnText={t("dialog.confirmBtn")}
            onClick={() => onConfirm()}
          />
          <BackBtn onClick={onClose} btnText={t("dialog.cancelBtn")} />
        </DialogActions>
      </>
    </Dialog>
  );
};

export default OraSendLogDialog;
