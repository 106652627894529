import React, { useEffect, useState } from 'react'
import LazyLoad from 'react-lazyload';
import { FormControlLabel, Checkbox, Chip, Typography } from '@material-ui/core';
import useStyles from '../../styles/multipleSelectStyle'
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { handelSelectionChange } from '../../helpers/handleSelectionWithSelectAllChange';
import LoadingIndicator from './LoadingIndicator';
import { combinedCurrentAndAPIArrWithCheckBoxStatus } from '../../helpers/arrayHandlers';
import useStateOnMounted from '../../helpers/useStateOnMounted';
import SelectAllCheckbox from './SelectAllCheckbox';
import { NoDataText, NoItemSelectedText } from '../commons/ValidationText';
import { Scrollbars } from 'react-custom-scrollbars';

const SelectionWithSelectAllCheckbox = (props) => {
    const classes = useStyles();
    const { tableDisabled, localforageKeyName, getDataFromAPI, currentArr, hasSelectAllOption, driveStorageId } = props;
    const [ selectedGroups, setSelectedGroups ] = useStateOnMounted([]);
    const [ groups, setGroups ] = useStateOnMounted([]);
    const [ checked, setChecked ] = useStateOnMounted(false);
    const [ isSelectAllDisabled, setIsSelectAllDisabled ] = useStateOnMounted(true);
    const [ loading, setLoading ] = useState(true);

    const renderTrack = ({ style, ...props }) => {
        const trackStyle = {
            position: 'relative',
            top: '33px',
            height: '3px',
            borderRadius: '1px'
        };
        return (
            <div
                className="track"
                style={{...trackStyle }}
                {...props}/>
        );
    }

    
    const handleSettingGroupListArr = async() => {
        
        let allDataArrayFromAPI
        let allIdsAndNamesArray;
        let combinedCurrentAndAPIArr;

        if(!driveStorageId){
            allDataArrayFromAPI = await getDataFromAPI();
            allIdsAndNamesArray = allDataArrayFromAPI.map( o => { return {id: o.id, name: o.name} });
            combinedCurrentAndAPIArr = combinedCurrentAndAPIArrWithCheckBoxStatus(allIdsAndNamesArray, currentArr);
        }

        if(driveStorageId){
            allDataArrayFromAPI = await getDataFromAPI(driveStorageId);
            allIdsAndNamesArray = allDataArrayFromAPI.map( o => { return {id: o.id, name: o.name} });
            let checkedIdArray = allDataArrayFromAPI.filter( o => o.checked ).map( o => o.id);
            combinedCurrentAndAPIArr = combinedCurrentAndAPIArrWithCheckBoxStatus(allIdsAndNamesArray, checkedIdArray);
        }
        
        setGroups(combinedCurrentAndAPIArr);
        setIsSelectAllDisabled(false);
        setLoading(false);
    }
    
    const handleChange = (event, chipItem) => {
        handelSelectionChange(event, chipItem, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, setChecked);
    }

    const handleSelectAllCompany = (event) => {
        handelSelectionChange(event, undefined, groups, setGroups, selectedGroups, setSelectedGroups, localforageKeyName, setChecked);
    }

    useEffect(()=>{
        handleSettingGroupListArr()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[currentArr, getDataFromAPI])

    return(
    <>
        {hasSelectAllOption && 
            <SelectAllCheckbox
                checked={checked}
                disabled={isSelectAllDisabled}
                handleChange={(e)=>handleSelectAllCompany(e)}  
            />
        }
        <div className={classes.multipleSelect}>
                {selectedGroups.length > 0 ?
                    <Scrollbars
                        thumbMinSize={10}
                        style={{ height: 38 }}
                        renderTrackHorizontal={renderTrack}
                        autoHide
                        autoHideTimeout={1000}
                        autoHideDuration={200}
                        className="chip-scroll-area"
                    >
                        {selectedGroups.map(item => (
                            <Chip
                                key={item.id}
                                className={classes.chip}
                                label={item.name}
                                icon={<CloseRoundedIcon />}
                                onClick={(e)=>handleChange(e, item)}
                                disabled={tableDisabled}
                            />)
                        )}
                    </Scrollbars>
                :
                <NoItemSelectedText />
                }
            <div className={classes.lazyLoadWrap}>
                {loading ?
                    <div className={classes.loadingIndicatorWrapper}>
                        <LoadingIndicator />
                    </div>
                    :
                    (groups.length > 0 ? 
                        groups.map((o) => {
                            return (
                                <LazyLoad key={o.id} once={o.once} height={30} overflow throttle={10} placeholder={<Typography className={classes.loading} variant="caption">loading...</Typography>}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox 
                                            id={o.id} 
                                            checked={o.checked} 
                                            onChange={(e)=>handleChange(e)} 
                                            name={o.name} 
                                            />
                                        }
                                        label={o.name}
                                        disabled={tableDisabled}
                                    />
                                </LazyLoad>
                            )
                        })
                            :
                            <NoDataText />    
                        )
                }
            </div>    
        </div>
    </>
    )
}

export default SelectionWithSelectAllCheckbox;

