import React, { useEffect, useRef } from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dashboardStyle";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import { getDeviceHealthStatusAPI } from "../../apis/getDeviceHealthStatusAPI";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import * as $ from "jquery";
import { NoDataText } from "../commons/ValidationText";
import useStateOnMounted from "../../helpers/useStateOnMounted";
import { useHistory } from "react-router-dom";
ChartJS.register(ArcElement, Tooltip, Legend);

const DeviceHealthStatus = (props) => {
    const { companyId } = props;
    const mounted = useRef();
    const chartRefCpu = useRef(null);
    const chartRefRam = useRef(null);
    const chartRefStorage = useRef(null);
    let history = useHistory();
    const { t } = useTranslation("dashboard");
    const classes = useStyles();
    const cardTitleTypographyVariant = props.cardTitleTypographyVariant;
    const cardSubtitleTypographyVariant = props.cardSubtitleTypographyVariant;
    const graphGreen = "#1DBBD4";
    const graphYellow = "#f3d067";
    const graphError = "#ec2121";
    const [cpuUsageDataset, setCpuUsageDataset] = useStateOnMounted([]);
    const [ramUsageDataset, setRamUsageDataset] = useStateOnMounted([]);
    const [storageUsageDataset, setStorageUsageDataset] = useStateOnMounted([]);
    const [reload, setReload] = useStateOnMounted(true);
    const dataColorArr = [graphGreen, graphYellow, graphError];
    const lessThan50 = "_less_than_and_equal_to_50";
    const between50And80 = "_between_50_and_80";
    const greaterThan80 = "_greater_than_80";
    const cpu = "cpu_usage";
    const ram = "ram_usage";
    const storage = "storage_usage";
    const labels = [
        `${t(`healthStatus.${lessThan50}`)}`,
        `${t(`healthStatus.${between50And80}`)}`,
        `${t(`healthStatus.${greaterThan80}`)}`,
    ];
    const labelsForUrlParams = [lessThan50, between50And80, greaterThan80];

    const cpuUsageData = {
        labels: labels,
        datasets: [
            {
                data: cpuUsageDataset,
                backgroundColor: dataColorArr,
                hoverBackgroundColor: dataColorArr,
            },
        ],
    };

    const ramUsageData = {
        labels: labels,
        datasets: [
            {
                data: ramUsageDataset,
                backgroundColor: dataColorArr,
                hoverBackgroundColor: dataColorArr,
            },
        ],
    };

    const storageUsageData = {
        labels: labels,
        datasets: [
            {
                data: storageUsageDataset,
                backgroundColor: dataColorArr,
                hoverBackgroundColor: dataColorArr,
            },
        ],
    };

    const options = {
        plugins: {
            legend: {
                display: false,
            },
        },
        maintainAspectRatio: false,
        onHover: function (e, item) {
            $(".pieChart canvas").css("cursor", item[0] ? "pointer" : "default");
        },
    };

    const clickHandler = (event, type) => {
        let chart;
        switch (type) {
            case 'cpu':
                chart = chartRefCpu.current;
                break;
            case 'ram':
                chart = chartRefRam.current;
                break;
            case 'storage':
                chart = chartRefStorage.current;
                break;
            default:
                break;
        }
        const element = getElementAtEvent(chart, event);
        if (element) {
            const itemLabel = labelsForUrlParams[element[0]?.index];
            history.push(`/device-list?company=${companyId}&realtime_type=${event.target.id + itemLabel}`);
        }
    };

    const getDeviceHealthStatus = async () => {
        setReload(true);

        const data = await getDeviceHealthStatusAPI();
        let isDataEmpty;
        if (data) {
            isDataEmpty = data && Object.values(data).every((value) => value === 0);
        }

        if (!isDataEmpty && data) {
            setCpuUsageDataset([
                data[`${cpu + lessThan50}`],
                data[`${cpu + between50And80}`],
                data[`${cpu + greaterThan80}`],
            ]);
            setRamUsageDataset([
                data[`${ram + lessThan50}`],
                data[`${ram + between50And80}`],
                data[`${ram + greaterThan80}`],
            ]);
            setStorageUsageDataset([
                data[`${storage + lessThan50}`],
                data[`${storage + between50And80}`],
                data[`${storage + greaterThan80}`],
            ]);
        } else {
            setCpuUsageDataset(false);
            setRamUsageDataset(false);
            setStorageUsageDataset(false);
        }
        setReload(false);
    };

    useEffect(() => {
        if (!mounted.current) {
            getDeviceHealthStatus();
            mounted.current = true;
        }
    });

    return (
        <Paper>
            <Typography
                variant={cardTitleTypographyVariant}
                className={classes.cardTitleWrapWithReloadIcon}
            >
                {t("dashboard.deviceHealthStatusTitle")}
                <AutorenewRoundedIcon
                    onClick={getDeviceHealthStatus}
                    size="small"
                    className={classes.reload_btn}
                />
            </Typography>
            {!reload ? (
                <Grid
                    container
                    spacing={2}
                    alignItems="stretch"
                    className={classes.deviceHealthStatusWrap}
                >
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant={cardSubtitleTypographyVariant} align="center">
                            {t(`healthStatus.${cpu}`)}
                        </Typography>
                        <div className="pieChart">
                            {cpuUsageDataset ? (
                                <Pie
                                    ref={chartRefCpu}
                                    id={cpu}
                                    data={cpuUsageData}
                                    options={options}
                                    onClick={(e) => clickHandler(e, 'cpu')}
                                />
                            ) : (
                                <NoDataText />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant={cardSubtitleTypographyVariant} align="center">
                            {t(`healthStatus.${ram}`)}
                        </Typography>
                        <div className="pieChart">
                            {ramUsageDataset ? (
                                <Pie
                                    ref={chartRefRam}
                                    id={ram}
                                    data={ramUsageData}
                                    options={options}
                                    onClick={(e) => clickHandler(e, 'ram')}
                                />
                            ) : (
                                <NoDataText />
                            )}
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                        <Typography variant={cardSubtitleTypographyVariant} align="center">
                            {t(`healthStatus.${storage}`)}
                        </Typography>
                        <div className="pieChart">
                            {storageUsageDataset ? (
                                <Pie
                                    ref={chartRefStorage}
                                    id={storage}
                                    data={storageUsageData}
                                    options={options}
                                    onClick={(e) => clickHandler(e, 'storage')}
                                />
                            ) : (
                                <NoDataText />
                            )}
                        </div>
                    </Grid>
                </Grid>
            ) : (
                <SmallerLoadingIndicator />
            )}

            {!reload ? (
                <div className={classes.deviceHealthStatusLabels}>
                    <Paper
                        elevation={0}
                        className={`${classes.hardwareModelTitleBox} ${classes.heightInitial}`}
                    >
                        <Typography variant={cardSubtitleTypographyVariant}>
                            <span className="graphGreen colorCube"></span>
                            {t(`healthStatus.${lessThan50}`)}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={0}
                        className={`${classes.hardwareModelTitleBox} ${classes.heightInitial}`}
                    >
                        <Typography variant={cardSubtitleTypographyVariant}>
                            <span className="graphYellow colorCube"></span>
                            {t(`healthStatus.${between50And80}`)}
                        </Typography>
                    </Paper>
                    <Paper
                        elevation={0}
                        className={`${classes.hardwareModelTitleBox} ${classes.heightInitial}`}
                    >
                        <Typography variant={cardSubtitleTypographyVariant}>
                            <span className="graphError colorCube"></span>
                            {t(`healthStatus.${greaterThan80}`)}
                        </Typography>
                    </Paper>
                </div>
            ) : (
                ""
            )}
        </Paper>
    );
};

export default DeviceHealthStatus;
