import { degreeRegex, operatorDeterminator } from './regex';

export let ruleName = alarmPolicyOption => alarmPolicyOption.name;
export let ruleValue = value => value;
export let ruleUnit = alarmPolicyOption => alarmPolicyOption.unit === '&amp;#8451;' || alarmPolicyOption.unit === '&#8451;' ? degreeRegex : alarmPolicyOption.unit;
export let ruleUnitForHistory = alarmPolicyOptionUnit => alarmPolicyOptionUnit === '&amp;#8451;' || alarmPolicyOptionUnit === '&#8451;' ? degreeRegex : alarmPolicyOptionUnit;

export let alarmPolicyRuleGenerator = (alarmPolicyOption, value, operatorFromAPI) => {
    return `${ruleName(alarmPolicyOption) + ' ' + operatorDeterminator(operatorFromAPI) + ' ' + ruleValue(value) + ruleUnit(alarmPolicyOption)}`
}

export let alarmPolicyRuleGeneratorForHistory = (alarmPolicyOptionName, value, operatorFromAPI, alarmPolicyOptionUnit) => {
    return `${alarmPolicyOptionName + ' ' + operatorDeterminator(operatorFromAPI) + ' ' + value + ruleUnitForHistory(alarmPolicyOptionUnit)}`
}
