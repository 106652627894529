import React, { useState, useEffect, useRef } from "react";
import useStyles from "../styles/appUpdateServiceStyle";
import { useTranslation } from "../langs/useTranslation";
import { Paper, Typography } from "@material-ui/core";
import { BreadCrumbs } from "../components/device/BreadCrumbs";
import AutorenewRoundedIcon from "@material-ui/icons/AutorenewRounded";
import HOCforFullWidthTable from "../hoc/FullWidthTableWrapper";
import { table } from "../helpers/appListDatatable";
import { Tooltip } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { handleDatatableSelectWithRadio } from "../helpers/handleDatatableSelectWithRadio";
import * as localforage from "localforage";
import textNoty from "../helpers/textNoty";
import * as $ from "jquery";
import { nubisDriveCapacityAPI } from "../apis/nubisDriveCapacityAPI";
import DeleteDialog from "../components/appList/DeleteDialog";
import AddDialog from "../components/appList/AddDialog";
import { deleteAppApi, appVersionDetailApi } from "../apis/appListApi";
import VersionManagement from "../components/appList/VersionManagement";
import HasPermission from "../components/commons/HasPermission";

const AppUpdateService = () => {
    const mounted = useRef();
    const classes = useStyles();
    let appListTable;
    const { t } = useTranslation("appUpdateService");
    const [deleteIds, setDeleteIds] = useState([]);
    const [appVersions, setAppVersions] = useState([]);
    const [loadingDialog, setLoadingDialog] = useState(false);
    const [overSize, setOverSize] = useState(true);
    const [OpenAddDialog, setOpenAddDialog] = useState(false);
    const [managementData, setManagementData] = useState(false);
    const [OpenDeleteDialog, setOpenDeleteDialog] = useState(false);
    const [OpenManagementDialog, setOpenManagementDialog] = useState(false);

    const addHandler = () => {
        setOpenAddDialog(true);
    };

    const deleteHandler = async () => {
        let appListIdsArr = await localforage.getItem("dtDeviceIds");
        if (!appListIdsArr || (appListIdsArr && appListIdsArr.length === 0)) {
            textNoty(t("error.noSelectApp"));
            return;
        }
        setDeleteIds(appListIdsArr);
        setOpenDeleteDialog(true);
    };

    const getVersionApi = async (id) => {
        setLoadingDialog(true);
        let data = await appVersionDetailApi(id);
        if (data && data.application_versions) {
            setAppVersions(data.application_versions);
            setLoadingDialog(false);
        }
    };

    const managementHandler = async (e) => {
        setLoadingDialog(true);
        setOpenManagementDialog(true);
        setManagementData(e);
        getVersionApi(e.id);
    };

    const confirmDelete = async () => {
        setLoadingDialog(true);
        await deleteAppApi(deleteIds, t, t("noty.successDelete")).then((res) => {
            if (res && res.status && res.status === "success") {
                reloadTable();
                setOpenDeleteDialog(false);
            }
            setLoadingDialog(false);
        });
    };

    const reloadTable = () => {
        table(t, managementHandler);
        appListTable = $("#app-list-table").DataTable();
        appListTable.on("click", $("#app-list-table input"), async () => {
            handleDatatableSelectWithRadio();
        });
    };

    const getnubisDriveCapacity = async () => {
        let data = await nubisDriveCapacityAPI();
        let result;
        if (data) {
            let userData = await localforage.getItem("user");
            if (userData) {
                let isParentCompanyNull = userData.company.parent_company_id === null;
                if (isParentCompanyNull) {
                    result = false;
                } else {
                    result = data.total - data.used <= 0;
                }
            }
            setOverSize(result);
        }
    };

    useEffect(() => {
        if (!mounted.current) {
            reloadTable();
            getnubisDriveCapacity();
            mounted.current = true;
        }
    });

    return (
        <div className={classes.root}>
            <BreadCrumbs pageName="appUpdateService" />

            <Typography variant="h4" className={classes.pageTitle}>
                {t("common.pageTitle")}
            </Typography>
            <Typography variant="body1" color="error" className={classes.noGroupText}>
                {t("noty.forPro")}
            </Typography>
            <Paper className={classes.tableMoveBottomReset}>
                <div className={classes.tableTitle}>
                    <AutorenewRoundedIcon
                        size="small"
                        onClick={reloadTable}
                        className={`${classes.listBtn} ${classes.reload_btn}`}
                    />
                    <HasPermission permission="app_update_service_add_app">
                        {overSize ? (
                            <Tooltip title={t("common.overSizeContact")} placement="top">
                                <div className={`${classes.iconWText} disabled`}>
                                    <AddIcon size="small" className={classes.icon_btn} />
                                    <Typography variant="body1" className={classes.iconText}>
                                        {t("common.addApp")}
                                    </Typography>
                                </div>
                            </Tooltip>
                        ) : (
                            <div className={classes.iconWText} onClick={() => addHandler()}>
                                <AddIcon size="small" className={classes.icon_btn} />
                                <Typography variant="body1" className={classes.iconText}>
                                    {t("common.addApp")}
                                </Typography>
                            </div>
                        )}
                    </HasPermission>
                    <HasPermission permission="app_update_service_delete_app">
                        <div className={classes.iconWText} onClick={() => deleteHandler()}>
                            <DeleteIcon size="small" className={classes.icon_btn} />
                            <Typography variant="body1" className={classes.iconText}>
                                {t("common.deleteApp")}
                            </Typography>
                        </div>
                    </HasPermission>
                </div>
                <HOCforFullWidthTable>
                    <table
                        id="app-list-table"
                        className="mdl-data-table dataTable no-footer dtr-inline"
                        style={{ marginTop: "60px" }}
                    >
                        <thead>
                            <tr>
                                <th></th>
                                <th className="appName">{t("common.appName")}</th>
                                <th className="publishVersion">{t("common.publishVersion")}</th>
                                <th className="uploadedDatetime">
                                    {t("common.uploadedDatetime")}
                                </th>
                                <th className="appDetail">{t("common.appDetail")}</th>
                            </tr>
                        </thead>
                    </table>
                </HOCforFullWidthTable>
            </Paper>
            <DeleteDialog
                open={OpenDeleteDialog}
                loading={loadingDialog}
                deleteHeader={t("dialog.deleteHeaderApp")}
                deleteContent={t("dialog.deleteContentApp")}
                onConfirm={confirmDelete}
                onClose={() => setOpenDeleteDialog(false)}
            />
            <AddDialog
                setLoading={setLoadingDialog}
                reloadTable={reloadTable}
                open={OpenAddDialog}
                setOpenAddDialog={setOpenAddDialog}
                loading={loadingDialog}
            />
            <VersionManagement
                overSize={overSize}
                open={OpenManagementDialog}
                loading={loadingDialog}
                appVersions={appVersions}
                getVersionApi={getVersionApi}
                reloadTable={reloadTable}
                managementData={managementData}
                onClose={() => setOpenManagementDialog(false)}
            />
        </div>
    );
};

export default AppUpdateService;
