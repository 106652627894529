import React from 'react';
import { Checkbox } from '@material-ui/core';
import ReactDOM from 'react-dom';
import { url } from '../apis/apiSetup';
import * as localforage from 'localforage';
import '../css/dataTable.css';
import * as $ from 'jquery';
import 'datatables.net';
import 'datetime-moment';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import ClearRoundedIcon from '@material-ui/icons/ClearRounded';
import { DefaultCheckedCheckBox, UncheckedCheckBox } from '../components/commons/CheckBoxes';
import { language } from './datatableLanguage';
import StatusChip from '../components/commons/StatusChip';
import { handleDeviceCheckStatusChange, storeDeviceAndSetSelectedDeviceIds } from './taskDatatableHandlers';

export const table = async (t, filterDevicesBy, selectedGroups, selectedTags, relationName, port, deviceModelIds, localforageKeyName, setSelectedIds) => {
    const token = await localforage.getItem('token');
    const companyId = await localforage.getItem('companyId');
    const selectedGroupIds = filterDevicesBy === 'filter_by_group' && selectedGroups ? selectedGroups.map(o => o.id) : undefined;
    const selectedTagIds = filterDevicesBy === 'filter_by_tag' && selectedTags ? selectedTags.map(o => o.id) : undefined;
    let finalRelationName = deviceModelIds && deviceModelIds.length > 0 ? relationName : (port ? relationName : undefined);
    $.fn.dataTable.ext.errMode = 'throw';

    $('#device-eligibility-table').DataTable({
        serverSide: true,
        processing: true,
        language: language(t),
        order: [[0, 'asc']],
        filter: false,
        deferRender: true,
        info: true,
        autoWidth: false,
        stateSave: false,
        searching: false,
        destroy: true,
        scrollCollapse: true,
        scrollY: '40vh',
        paging: false,
        ordering: true,
        ajax: {
            url: `${url}/api/v2/devices/device-list-command`,
            type: 'GET',
            dataSrc: 'data',
            data: {
                company_id: companyId,
                company_group_ids: selectedGroupIds,
                tag_ids: selectedTagIds,
                relation_name: finalRelationName,
                io_port: port,
                device_model_ids: deviceModelIds ? deviceModelIds : undefined,
            },
            beforeSend: (request) => {
                request.setRequestHeader('Authorization', 'Bearer ' + token);
                request.setRequestHeader('Accept', 'application/json');
            },
            complete: async (response) => {
                $('#device-eligibility-table').DataTable().rows({ page: 'all' }).select();
                if (response.responseText) {
                    const jsonResponseText = JSON.parse(response.responseText);
                    let data = jsonResponseText.data;
                    if (finalRelationName === 'ioVoltages') {
                        const allDeviceIdsArr = data ? data.filter(o => {
                            const voltageNames = Object.values(o.io_voltages.map(o => o.name))
                            if (voltageNames.includes(port)) {
                                return o.id;
                            }
                            return false;
                        }) : [];
                        await localforage.setItem(localforageKeyName, allDeviceIdsArr.map(o => o.id));
                    }
                    if (finalRelationName === 'deviceModel') {
                        const allDeviceIdsArr = data ? data.filter(o => o.device_model) : [];
                        await localforage.setItem(localforageKeyName, allDeviceIdsArr.map(o => o.id));
                    }
                    if (relationName === 'alarmDeployment') {
                        let availableDeviceIds = data && data.filter(o => o.online_offline_status === 'online').map(o => o.id);
                        await storeDeviceAndSetSelectedDeviceIds(availableDeviceIds, localforageKeyName, setSelectedIds);
                    }
                }
            },
        },
        select: {
            style: 'multi',
        },
        columns: [
            {
                data: 'id',
                orderable: false,
                searchable: false,
                defaultContent: '',
                className: 'select-checkbox',
                createdCell: (td, data, rowData) => {

                    const uncheckedCheckBox = <UncheckedCheckBox data={data} setSelectedIds={setSelectedIds} localforageKeyName={localforageKeyName} handleDeviceCheckStatusChange={handleDeviceCheckStatusChange} />;
                    const defaultCheckedCheckBox = <DefaultCheckedCheckBox data={data} setSelectedIds={setSelectedIds} localforageKeyName={localforageKeyName} handleDeviceCheckStatusChange={handleDeviceCheckStatusChange} />;

                    if (!finalRelationName) ReactDOM.render(<Checkbox value={data} disabled />, td);
                    if (finalRelationName === 'ioVoltages') {
                        ioVoltagesCheckbox(td, rowData, port, defaultCheckedCheckBox, uncheckedCheckBox);
                    };
                    if (finalRelationName === 'deviceModel') {
                        deviceModelCheckbox(td, rowData, defaultCheckedCheckBox, uncheckedCheckBox);
                    };
                    if (relationName === 'alarmDeployment') {
                        alarmDeploymentCheckbox(td, rowData, defaultCheckedCheckBox, uncheckedCheckBox);
                    }
                }
            },
            {
                data: 'serial_number',
                title: `${t('task.serialNumber')}`,
                render: (data) => {
                    return data;
                },
            },
            {
                data: 'serial_number',
                title: relationName === 'alarmDeployment' ? `${t('task.status')}` : `${t('task.eligibility')}`,
                createdCell: (td, data, rowData) => {

                    const checkedIcon = <CheckRoundedIcon color="primary" />;
                    const clearIcon = <ClearRoundedIcon color="secondary" />;

                    if (!finalRelationName) ReactDOM.render('-', td);
                    if (finalRelationName === 'ioVoltages') {
                        ioVoltagesEligibility(td, rowData, port, checkedIcon, clearIcon);
                    };
                    if (finalRelationName === 'deviceModel') {
                        deviceModelEligibitlity(td, rowData, checkedIcon, clearIcon);
                    };
                    if (relationName === 'alarmDeployment') {
                        alarmDeploymentEligibitlity(td, rowData);
                    }
                },
            },
        ]
    })
}

// table CHECKBOX for relation name 'ioVoltages'
const ioVoltagesCheckbox = (td, rowData, port, defaultCheckedCheckBox, uncheckedCheckBox) => {
    const ioVoltages = rowData.io_voltages;
    const nubisVersion = rowData.nubis_version;
    let hasVoltage = ioVoltages.filter(o => o.name === port)
    if (ioVoltages && ioVoltages.length > 0 && hasVoltage && nubisVersion === 'nubis_pro') {
        ReactDOM.render(defaultCheckedCheckBox, td);
    } else {
        ReactDOM.render(uncheckedCheckBox, td);
    }
}

// table ELIGIBILITY for relation name 'ioVoltages'
const ioVoltagesEligibility = (td, rowData, port, checkedIcon, clearIcon) => {
    const ioVoltages = rowData.io_voltages;
    const nubisVersion = rowData.nubis_version;
    if (ioVoltages && ioVoltages.length > 0 && nubisVersion === 'nubis_pro') {
        ioVoltages.map(o => {
            if (o.name === port) {
                ReactDOM.render(checkedIcon, td);
            }
            return false;
        })
    } else {
        ReactDOM.render(clearIcon, td);
    }
}

// table ELIGIBILITY for relation name 'deviceModel'
const deviceModelEligibitlity = (td, rowData, checkedIcon, clearIcon) => {
    const deviceModels = rowData.device_model;
    const nubisVersion = rowData.nubis_version;
    ReactDOM.render(deviceModels && nubisVersion === 'nubis_pro' ? checkedIcon : clearIcon, td);
}

// table CHECKBOX for relation name 'deviceModel'
const deviceModelCheckbox = (td, rowData, defaultCheckedCheckBox, uncheckedCheckBox) => {
    const deviceModels = rowData.device_model;
    const nubisVersion = rowData.nubis_version;
    ReactDOM.render(deviceModels && nubisVersion === 'nubis_pro' ? defaultCheckedCheckBox : uncheckedCheckBox, td)
}

// table ELIGIBILITY for relation name 'alarmDeployment'
const alarmDeploymentEligibitlity = (td, rowData) => {
    let onlineOfflineStatus = rowData.online_offline_status;
    ReactDOM.render(<StatusChip statusLabel={onlineOfflineStatus} />, td);
}

// table CHECKBOX for relation name 'alarmDeployment'
const alarmDeploymentCheckbox = (td, rowData, defaultCheckedCheckBox, uncheckedCheckBox) => {
    let onlineOfflineStatus = rowData.online_offline_status;
    ReactDOM.render(onlineOfflineStatus === 'online' ? defaultCheckedCheckBox : uncheckedCheckBox, td)
}
