export const dataSet = (
    valueArr,
    label = null,
    borderColor = "#04b7d2",
    pointBorderColor = "#04b7d2",
    pointHoverBackgroundColor = "#04b7d2",
    pointHoverBorderColor = "#04b7d2"
) => {
    return {
        label: label,
        fill: false,
        lineTension: 0.4,
        backgroundColor: "none",
        borderColor: borderColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: pointBorderColor,
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: pointHoverBackgroundColor,
        pointHoverBorderColor: pointHoverBorderColor,
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: valueArr,
    };
};
export const dataSetConnection = (
    valueArr,
    label = null,
    borderColor = "#04b7d2",
    pointBorderColor = "#04b7d2",
    pointHoverBackgroundColor = "#04b7d2",
    pointHoverBorderColor = "#04b7d2"
) => {
    return {
        stepped: true,
        label: label,
        fill: false,
        lineTension: 0,
        backgroundColor: "none",
        borderColor: borderColor,
        borderCapStyle: "butt",
        borderDash: [],
        borderDashOffset: 0.0,
        borderJoinStyle: "miter",
        pointBorderColor: pointBorderColor,
        pointBackgroundColor: "#fff",
        pointBorderWidth: 1,
        pointHoverRadius: 5,
        pointHoverBackgroundColor: pointHoverBackgroundColor,
        pointHoverBorderColor: pointHoverBorderColor,
        pointHoverBorderWidth: 1,
        pointRadius: 1,
        pointHitRadius: 10,
        data: valueArr,
    };
};

export const options = {
    animation: {
        duration: 0
    },
    responsive: true,
    scales: {
        y: {
            suggestedMin: 0,
            suggestedMax: 100,
        },
        x: {},
    },
    plugins: {
        legend: {
            display: false,
        },
    },
};

export const cpuTempOptions = {
    animation: {
        duration: 0
    },
    responsive: true,
    scales: {
        y: {
            suggestedMin: 0,
            suggestedMax: 50,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
};
export const optionsConnection = {
    animation: {
        duration: 0
    },
    responsive: true,
    scales: {
        y: {
            type: "category",
            labels: ["Online", "Offline"],
            offset: true,
            position: "left",
            stackWeight: 1,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
    },
};
