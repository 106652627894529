import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Radio,
    RadioGroup,
    FormControl,
    FormControlLabel,
    Select,
    Grid,
    MenuItem,
} from "@material-ui/core";
import { IOSetUpAPI } from "../../apis/deviceControlApi";
import { useTranslation } from "../../langs/useTranslation";
import useStyles from "../../styles/dialogStyle";
import { StyledSubmitBtn, BackBtn } from "../commons/FormBtns";
import SelectDate from "./SelectDatePicker";
import SmallerLoadingIndicator from "../commons/SmallerLoadingIndicator";
import { getIOPortsAPI } from "../../apis/getIOPortsAPI";
import { sortArrByName } from "../../helpers/arrayHandlers";
import moment from "moment";

const IoSetUp = (props) => {
    const classes = useStyles();
    const { t } = useTranslation("deviceControl");
    const [selectDateType, setSelectDateType] = useState("immediately");
    const [selectDate, setSelectDate] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [loadingVoltage, setLoadingVoltage] = useState(false);
    const [loadingBtn, setLoadingBtn] = useState(false);
    const [selectPort, setSelectPort] = useState("");
    const [voltage, setVoltage] = useState("");
    const [allIOPorts, setAllIOPorts] = useState([]);
    const [IOVoltageArr, setIOVoltageArr] = useState([]);

    const {
        open,
        setOpen,
        selectType,
        selectDevices,
        selectDevice,
        numType,
        groupId,
        tagId,
        selectCompany,
        checkAll
    } = props;

    const onClose = () => {
        setOpen(false);
    };

    const onConfirm = async () => {
        setLoadingBtn(true);
        const successText = "dialog.successText";
        await IOSetUpAPI(
            selectType,
            selectCompany,
            checkAll
                ? undefined
                : numType === "single"
                    ? [selectDevice]
                    : selectDevices,
            groupId,
            tagId,
            "set_io_voltage",
            selectDate,
            selectPort,
            voltage,
            t,
            successText
        ).then((res) => {
            if (res) {
                setLoadingBtn(false);
                setOpen(false);
            }
        });
    };

    const selectDateHandler = async (date) => {
        const dateValue = date._d;
        const dateMomentInUTC = moment(dateValue);
        setSelectDate(dateMomentInUTC);
    };

    const selectDateTypeHandler = (e) => {
        setSelectDateType(e.target.value);
    };
    const portHandler = (e) => {
        setSelectPort(e.target.value);
        getIOVoltagesValue(e.target.value);
        setLoadingVoltage(true);
    };

    //get I/O port values
    const getIOPortsValue = async () => {
        const allIOportsArr = await getIOPortsAPI();
        if (allIOportsArr && allIOportsArr.length) {
            const allIOPorts = allIOportsArr.map((port) => port.name);
            const sortedIOPortNames = sortArrByName(allIOPorts);
            setAllIOPorts(sortedIOPortNames);
            setSelectPort(sortedIOPortNames[0]);
            getIOVoltagesValue(sortedIOPortNames[0]);
        }
    };

    // for multi-device task
    // get voltage values for current port from API
    const getIOVoltagesValue = async (port) => {
        const allIOportsArr = await getIOPortsAPI();
        if (allIOportsArr) {
            handleIOVoltageArrForSelector(allIOportsArr, port);
        }
    };

    // for multi-device task
    // set up the voltage selection menu from all IO ports array, 'allIOportsArr'
    const handleIOVoltageArrForSelector = (allIOportsArr, port) => {
        const currentIOPortsArr = allIOportsArr.filter((e) => e.name === port);
        if (currentIOPortsArr && currentIOPortsArr.length) {
            const currentVoltageJsonArr = currentIOPortsArr[0]
                ? currentIOPortsArr[0].json
                : [];
            setIOVoltageArr(currentVoltageJsonArr);
            setVoltage(currentVoltageJsonArr[0]);
            setLoading(false);
            setLoadingVoltage(false);
        }
    };

    useEffect(() => {
        if (open) {
            setLoading(true);
            setSelectDateType("immediately");
            setSelectDate(new Date());
            setAllIOPorts([]);
            setIOVoltageArr([]);
            setSelectPort("");
            setVoltage("");
            getIOPortsValue();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <Dialog
            maxWidth="md"
            className={classes.dialog}
            onClose={onClose}
            open={open}
            aria-labelledby="add-event-dialog"
            aria-describedby="add-event-dialog"
        >
            <DialogTitle id="add-edit-alarm-notification">
                {t("common.ioSetUp")}
            </DialogTitle>
            <>
                <DialogContent>
                    <div className={classes.deviceControlDialogOuter}>
                        <div className="deviceControlDialogBlock">
                            <div className="title">1. {t("common.selectDate")}</div>
                            <div className="selectdateArea">
                                <FormControl component="div" fullWidth={true}>
                                    <RadioGroup
                                        aria-label="date"
                                        value={selectDateType}
                                        onChange={selectDateTypeHandler}
                                    >
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="immediately"
                                                        label={`${t("common.immediateText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12} sm={12} md={6} lg={6}>
                                                <FormControl>
                                                    <FormControlLabel
                                                        value="scheduling"
                                                        label={`${t("common.schedulingText")}`}
                                                        control={<Radio color="primary" />}
                                                    />
                                                    <SelectDate
                                                        selectDateHandler={selectDateHandler}
                                                        selectDate={selectDate}
                                                        disabled={
                                                            selectDateType !== "scheduling" ? true : false
                                                        }
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </RadioGroup>
                                </FormControl>
                            </div>
                        </div>
                        <div className="deviceControlDialogBlock">
                            <div className="title">2. {t("common.selectAPort")}</div>
                            <FormControl>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className={classes.oneDropDown}
                                    >
                                        {loading ? (
                                            <SmallerLoadingIndicator />
                                        ) : (
                                            <Select
                                                variant="outlined"
                                                value={selectPort}
                                                onChange={portHandler}
                                                displayEmpty
                                                className={classes.fileSelectDropDown}
                                                name="port"
                                                MenuProps={{ disableScrollLock: true }}
                                            >
                                                {allIOPorts.map((port, index) => {
                                                    return (
                                                        <MenuItem key={index} value={port}>
                                                            {t(`ioPortsType.${port}`)}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        )}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </div>
                        <div className="deviceControlDialogBlock">
                            <div className="title">
                                3. {t("common.selectStatusOrVoltage")}
                            </div>

                            <FormControl>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        sm={12}
                                        md={12}
                                        lg={12}
                                        xl={12}
                                        className={classes.oneDropDown}
                                    >
                                        {loading || loadingVoltage ? (
                                            <SmallerLoadingIndicator />
                                        ) : (
                                            <Select
                                                variant="outlined"
                                                value={voltage}
                                                onChange={(e) => setVoltage(e.target.value)}
                                                displayEmpty
                                                className={classes.fileSelectDropDown}
                                                name="voltage"
                                                MenuProps={{ disableScrollLock: true }}
                                            >
                                                {IOVoltageArr.map((voltage, index) => {
                                                    if (voltage !== "")
                                                        return (
                                                            <MenuItem key={index} value={voltage}>
                                                                {t(`ioVoltages.${voltage}`)}
                                                            </MenuItem>
                                                        );
                                                    return false;
                                                })}
                                            </Select>
                                        )}
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </div>
                    </div>
                </DialogContent>

                <DialogActions className={classes.noMarginBtn}>
                    <StyledSubmitBtn
                        btnColor="primary"
                        type="button"
                        disabled={loading || loadingBtn}
                        btnText={t("dialog.confirmBtn")}
                        onClick={onConfirm}
                    />
                    <BackBtn onClick={onClose} btnText={t("dialog.cancel")} />
                </DialogActions>
            </>
        </Dialog>
    );
};

export default IoSetUp;
