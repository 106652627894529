import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from "../../langs/useTranslation";

const RequiredText = () => {
    const { t } = useTranslation('adminUtility')

    return(
        <Typography variant="caption" color="error" display="block">
            {t('dialog.thisIsRequired')}
        </Typography>
    )
}

export default RequiredText;